import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { uniq } from 'lodash';

import {
    useCreatePlayerMutation,
    useDeletePlayerMutation,
    useGetPlayersQuery,
    useUpdatePlayerMutation,
} from '../../api/players';
import { useGetTeamQuery } from '../../api/teams';
import { useGetStaffDetailsQuery } from '../../api/staff';

import usePermission from '../../hooks/usePermission';
import { Mixpanel } from '../../util/mixpanel';
import { ToastContext } from '../../contexts/ToastContext';

import PlayersView from './PlayersView';
import DocumentHead from '../../components/DocumentHead';
import { confirmDialog } from 'primereact/confirmdialog';
import { ToastMessage } from 'primereact/toast';

import { Route } from '../../types/route';
import { Roles } from '../../types/roles';
import { Player, PlayerStatus } from '../../types/team';
import { Permissions } from '../../types/permissions';
import { BaseEntityType } from '../../types/common';

interface Props {
    roles: Roles;
    route: Route;
}

const PlayersContainer = (props: Props) => {
    const { teamID, organisationID } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const activeTab = searchParams.get('tab');

    const toast = useContext(ToastContext);

    const [focusedUser, setFocusedUser] = useState<Player | null>(null);
    const [isImporting, setIsImporting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isArchiving, setIsArchiving] = useState(false);
    const [cursor, setCursor] = useState('');

    const { checkPermission } = usePermission(organisationID || teamID || '');

    const { data: teamData, isLoading: loadingTeam } = useGetTeamQuery(
        { teamID: teamID || '' },
        {
            skip: !teamID,
        }
    );

    const {
        data,
        isLoading: loadingPlayers,
        isError,
        isFetching,
    } = useGetPlayersQuery(
        {
            teamID: teamID ? teamID : '',
            status: activeTab === 'Archived' ? 'Archived' : 'Active',
            cursor: cursor,
        },
        {
            skip: !teamID,
        }
    );

    const { data: staffData, isLoading: loadingStaff } =
        useGetStaffDetailsQuery(
            {
                entityType: BaseEntityType.teams,
                entityID: teamID ? teamID : '',
                cursor: cursor,
            },
            {
                skip: !teamID,
            }
        );

    const [createPlayer] = useCreatePlayerMutation();
    const [updatePlayer] = useUpdatePlayerMutation();
    const [deletePlayer] = useDeletePlayerMutation();

    useEffect(() => {
        if (searchParams.get('id')) {
            const player = data?.data.find(
                (p) => p.playerID === searchParams.get('id')
            );

            if (player) {
                setFocusedUser(player);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Handle redirection to "upcoming" for specific URLs with /events
        if (!activeTab || !['Active', 'Archived'].includes(activeTab)) {
            navigate(
                {
                    search: '?tab=Active',
                },
                {
                    replace: true,
                }
            );
        }
    }, [navigate, activeTab]);

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const handleShowInvite = (isCreate: boolean) => {
        if (searchParams.get('id')) {
            setSearchParams((params) => {
                params.delete('id');
                return params;
            });
        }
        if (isCreate) {
            setFocusedUser(null);
        }
        setSearchParams((params) => {
            params.set('create', 'add');
            return params;
        });

        Mixpanel.track('Open Player Create');
    };

    const handleCloseInvite = () => {
        if (searchParams.get('create')) {
            setSearchParams((params) => {
                params.delete('create');
                return params;
            });
        }
    };

    const handleImport = async ({ validData, all }: any) => {
        let successCount = 0;
        let errors: string[] = [];
        let details: string[] = [];

        setIsImporting(true);

        // Show initial summary
        if (toast && toast.current) {
            toast.current.show({
                summary: `Importing ${all.length} players`,
                sticky: true,
            });
        }

        const delay = (ms = 50) =>
            new Promise((resolve) => setTimeout(resolve, ms));

        Promise.allSettled(
            validData.map(async (data: any, index: number) => {
                return delay(index * 300).then(async () => {
                    return createPlayer({
                        teamID,
                        ...data,
                    }).then((response) => {
                        if ('data' in response) {
                            successCount++;
                        } else if (
                            'data' in response.error &&
                            response.error.data &&
                            typeof response.error.data === 'object'
                        ) {
                            const errorDetails = (
                                response.error.data as { details?: string }
                            ).details;
                            if (errorDetails) {
                                details.push(errorDetails.toString());
                            }
                        } else if ('error' in response) {
                            // @ts-ignore
                            errors.push(response.error.data.error.toString());
                        }
                    });
                });
            })
        ).then(() => {
            setIsImporting(false);

            const failedCount = validData.length - successCount;
            const uniqDetails = uniq(details);

            if (toast && toast.current) {
                toast.current.replace({
                    severity:
                        successCount === 0
                            ? 'error'
                            : successCount === validData.length
                            ? 'success'
                            : undefined,
                    summary: `${successCount}/${all.length} Imported`,
                    detail:
                        failedCount > 0
                            ? `${failedCount} failed due to the following reasons: ${
                                  uniqDetails.length > 0 &&
                                  uniqDetails.some((detail) =>
                                      detail.includes('Invalid date format')
                                  )
                                      ? 'Invalid date format'
                                      : uniqDetails.length > 0
                                      ? uniqDetails.toString()
                                      : validData.length !== all.length
                                      ? 'Invalid data'
                                      : 'Unknown'
                              }`
                            : '',
                    sticky: true,
                });
            }
        });
    };

    const handleArchiveUser = (player: Player) => {
        closeDrawer();

        confirmDialog({
            message: `Are you sure you want to archive ${player.firstName} ${player.lastName}?`,
            header: 'Archive Player',
            accept: () => {
                archiveUser(player.playerID);
            },
        });
    };

    const handleDeleteUser = (player: Player) => {
        closeDrawer();

        confirmDialog({
            message: `Are you sure you want to delete ${player.firstName} ${player.lastName}?`,
            header: 'Delete Player',
            accept: () => {
                deleteUser(player.playerID);
            },
        });
    };

    const archiveUser = async (playerID: string) => {
        if (playerID) {
            try {
                setIsArchiving(true);

                const response = await updatePlayer({
                    playerID,
                    teamID,
                    status: PlayerStatus.Archived,
                });

                // Check if response contains an error
                if (response && 'error' in response) {
                    throw response.error;
                }

                // Show success toast message
                showToast({
                    severity: 'success',
                    detail: 'Successfully archived player.',
                });
            } catch (err: unknown) {
                console.error('Archive player error:', err);

                let errorMessage =
                    'There was an error archiving the player. Please try again.';

                if (typeof err === 'object' && err !== null) {
                    if ('data' in err) {
                        const fetchError = err as FetchBaseQueryError;
                        errorMessage =
                            fetchError.data &&
                            typeof fetchError.data === 'object'
                                ? (fetchError.data as { error?: string })
                                      ?.error || 'An unknown error occurred.'
                                : 'An unknown error occurred.';
                    } else if ('message' in err) {
                        errorMessage = String((err as Error).message);
                    }
                }

                // Show error toast message
                showToast({
                    severity: 'error',
                    detail: errorMessage,
                    summary: 'Error',
                });
            } finally {
                setIsArchiving(false);
            }
        }
    };

    const deleteUser = async (playerID: string) => {
        if (playerID && teamID) {
            try {
                setIsDeleting(true);

                const response = await deletePlayer({
                    teamID,
                    playerID,
                });

                // Check if response contains an error
                if (response && 'error' in response) {
                    throw response.error;
                }

                // Show success toast message
                showToast({
                    severity: 'success',
                    detail: 'Successfully deleted player.',
                });
            } catch (err: unknown) {
                console.error('Delete player error:', err);

                let errorMessage =
                    'There was an error deleting the player. Please try again.';

                if (typeof err === 'object' && err !== null) {
                    if ('data' in err) {
                        const fetchError = err as FetchBaseQueryError;
                        errorMessage =
                            fetchError.data &&
                            typeof fetchError.data === 'object'
                                ? (fetchError.data as { error?: string })
                                      ?.error || 'An unknown error occurred.'
                                : 'An unknown error occurred.';
                    } else if ('message' in err) {
                        errorMessage = String((err as Error).message);
                    }
                }

                // Show error toast message
                showToast({
                    severity: 'error',
                    detail: errorMessage,
                    summary: 'Error',
                });
            } finally {
                setIsDeleting(false);
            }
        }
    };

    const handleClickUser = (player: Player) => {
        setFocusedUser(player);

        if (searchParams.get('create')) {
            setSearchParams((params) => {
                params.delete('create');
                return params;
            });
        }
        setSearchParams((params) => {
            params.set('id', player.playerID);
            return params;
        });
    };

    const closeDrawer = () => {
        if (searchParams.get('id')) {
            setSearchParams((params) => {
                params.delete('id');
                return params;
            });
        } else {
            setSearchParams((params) => {
                params.delete('create');
                return params;
            });
        }
    };

    const handleFocusUser = (player: Player) => {
        setFocusedUser(player);
    };

    const handleLoadMore = () => {
        if (data?.lastEvaluatedKey && teamID) {
            const { lastEvaluatedKey } = data;
            if (lastEvaluatedKey && lastEvaluatedKey.cursor) {
                setCursor(lastEvaluatedKey.cursor);
            }
        }
    };

    const handleTabChange = () => {
        setCursor('');
    };

    const permissions = {
        canCreate: checkPermission([
            Permissions.POST_PLAYERS_USERS,
            Permissions.POST_PLAYERS,
            Permissions.MANAGE_PLAYERS_USERS,
            Permissions.MANAGE_PLAYERS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canDelete: checkPermission([
            Permissions.DELETE_PLAYERS_USERS,
            Permissions.DELETE_PLAYERS,
            Permissions.MANAGE_PLAYERS_USERS,
            Permissions.MANAGE_PLAYERS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canEdit: checkPermission([
            Permissions.PUT_PLAYERS,
            Permissions.MANAGE_PLAYERS_USERS,
            Permissions.MANAGE_PLAYERS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canView: checkPermission([
            Permissions.GET_PLAYERS,
            Permissions.MANAGE_PLAYERS_USERS,
            Permissions.MANAGE_PLAYERS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
    };

    return (
        <>
            <DocumentHead
                title="Players - Rookie Me Hub"
                description="Players management page"
            />
            <PlayersView
                data={data && data.data ? data.data : []}
                staffData={staffData?.data || []}
                isError={isError}
                isFetching={isFetching}
                isLoading={loadingTeam || loadingPlayers || loadingStaff}
                isDeleting={isDeleting}
                isArchiving={isArchiving}
                isImporting={isImporting}
                focusedUser={focusedUser}
                onLoadMore={handleLoadMore}
                onCloseInviteDialog={handleCloseInvite}
                onShowInviteDialog={handleShowInvite}
                onSelectUser={handleClickUser}
                onArchiveUser={handleArchiveUser}
                onDeleteUser={handleDeleteUser}
                onImport={handleImport}
                onFocusUser={handleFocusUser}
                onCloseDrawer={closeDrawer}
                onTabChange={handleTabChange}
                roles={props.roles}
                showPagination={!!data?.lastEvaluatedKey?.cursor}
                permissions={permissions}
                team={teamData?.data}
            />
        </>
    );
};

export default PlayersContainer;
