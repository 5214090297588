import { useState, useEffect, useRef, useMemo, useContext } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import {
    generatePath,
    Link,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';

import {
    TeamMenuItems,
    AccountMenuItems,
    OrganisationMenuItems,
    AssociationMenuItems,
    routes,
    ASSOCIATION_PARAM,
    ORGANISATION_PARAM,
    TEAM_PARAM,
} from '../routes/routes';

import { findMatchedRoutes, getEntityFromParam } from '../util/helper';

import { LayoutContext } from '../contexts/LayoutContext';

import logoBrandmark from '../assets/images/logos/rm-hub-logomark.svg';
import logoWordmark from '../assets/images/logos/rm-hub-wordmark.svg';

import { Image } from 'primereact/image';
import { Menu } from 'primereact/menu';

import Icon from '../components/Icon';
import EntitySwitcher from '../components/EntitySwitcher';
import RookieButton from '../components/RookieButton';
import ListItem from '../components/ListItem';
import EntityName from '../components/EntityName';

import { PathMatchWithRoute, Route } from '../types/route';

const menus = {
    associations: AssociationMenuItems,
    organisations: OrganisationMenuItems,
    teams: TeamMenuItems,
    users: AccountMenuItems,
};

const AppSideBar = () => {
    // Route Hooks
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    // State Hooks
    const [mode, setMode] = useState('push');

    // Context Hooks
    const {
        onAnchorSidebarToggle,
        layoutState: { pinSidebar },
    } = useContext(LayoutContext);

    // Ref Hooks
    const nodeRef = useRef(null);
    const prevMatches = useRef<PathMatchWithRoute[]>();

    // Helpers
    const matches = useMemo<PathMatchWithRoute[]>(
        () => findMatchedRoutes(routes, location.pathname),
        [location]
    );

    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);

    const entityRoutes = useMemo(() => {
        let filteredMatches = matches.filter((match) => {
            const paths = match.pattern.path.split('/');
            const lastPath = paths[paths.length - 1];
            const param = lastPath.startsWith(':') ? lastPath.slice(1) : null;

            return (
                param &&
                [ASSOCIATION_PARAM, ORGANISATION_PARAM, TEAM_PARAM].includes(
                    param
                )
            );
        });

        return filteredMatches.sort(
            (a: PathMatchWithRoute, b: PathMatchWithRoute) =>
                b.pathname.length - a.pathname.length
        );
    }, [matches]);

    const currEntity = entityRoutes[0];
    const prevEntity = entityRoutes[1];

    // Effect Hooks
    useEffect(() => {
        if (
            prevMatches.current &&
            prevMatches.current.length !== matches.length
        ) {
            setMode(
                prevMatches.current.length < matches.length ? 'push' : 'pop'
            );
        }
    }, [matches, prevMatches]);

    useEffect(() => {
        prevMatches.current = matches;
    }, [matches]);

    const menuItems = useMemo(() => {
        const menu = activeEntity?.entityType
            ? menus[activeEntity?.entityType]
            : [];

        return menu.map((items: Route) => {
            const { icon, path, title } = items;
            let fullPath = path;

            if (
                params.associationID &&
                params.organisationID &&
                params.teamID
            ) {
                fullPath = `/a/:associationID/o/:organisationID${path}`;
            } else if (params.associationID && params.organisationID) {
                fullPath = `/a/:associationID${path}`;
            } else if (params.organisationID && params.teamID) {
                fullPath = `/o/:organisationID${path}`;
            }

            const toPath = generatePath(fullPath, params);

            return {
                className: toPath === location.pathname ? 'p-highlight' : '',
                template: (
                    <div className="p-menuitem-content">
                        <Link className="p-menuitem-link" to={toPath}>
                            {icon && (
                                <Icon name={icon} className="p-menuitem-icon" />
                            )}
                            <span className="p-menuitem-text">{title}</span>
                            {items.badge && <items.badge />}
                        </Link>
                    </div>
                ),
            };
        });
    }, [activeEntity?.entityType, location, params]);

    return (
        <div className={`sidebar sidebar-${mode}`}>
            <div className="sidebar-header">
                <Link className="app-logo" to="/">
                    <Image
                        className="app-logo-img app-logo-brandmark"
                        src={logoBrandmark}
                        height="40px"
                        alt="Rookie Me Hub"
                    />
                    <Image
                        className="app-logo-img app-logo-wordmark"
                        src={logoWordmark}
                        height="40px"
                        alt="Rookie Me Hub"
                    />
                </Link>
                <EntitySwitcher />
            </div>
            <div className="sidebar-body">
                <SwitchTransition>
                    <CSSTransition
                        key={currEntity?.pathname || 'account'}
                        classNames="panel-stack"
                        timeout={400}
                        nodeRef={nodeRef}
                    >
                        <div ref={nodeRef}>
                            {prevEntity && (
                                <div className="sidebar-subheader">
                                    <ListItem
                                        compact
                                        disablePadding
                                        start={
                                            <RookieButton
                                                onClick={() =>
                                                    navigate(
                                                        prevEntity.pathname
                                                    )
                                                }
                                                icon="arrow_back"
                                                text
                                            />
                                        }
                                        title={
                                            activeEntity && (
                                                <EntityName
                                                    entityType={
                                                        activeEntity.entityType
                                                    }
                                                    entityID={
                                                        activeEntity.entityID
                                                    }
                                                />
                                            )
                                        }
                                    />
                                </div>
                            )}
                            {menuItems && <Menu model={menuItems} />}
                        </div>
                    </CSSTransition>
                </SwitchTransition>
            </div>
            <div className="sidebar-footer">
                <RookieButton
                    tooltip={pinSidebar ? 'Collapse Menu' : 'Expand Menu'}
                    tooltipOptions={{
                        showDelay: 500,
                    }}
                    className="sidebar-toggle"
                    text
                    onClick={onAnchorSidebarToggle}
                    icon={pinSidebar ? 'keyboard_tab_rtl' : 'keyboard_tab'}
                />
            </div>
        </div>
    );
};

export default AppSideBar;
