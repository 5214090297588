import { useContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import { ToastContext } from '../../contexts/ToastContext';

import { StaffTabProps } from './StaffView';
import ErrorDisplay from '../../components/ErrorDisplay';
import ListItem from '../../components/ListItem';
import RookieButton from '../../components/RookieButton';
import { Avatar } from 'primereact/avatar';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';

import { UserDetails } from '../../types/user';
import { ERROR_TYPES } from '../../types/common';

const StaffRequestView = (props: StaffTabProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const toast = useContext(ToastContext);

    const {
        staff: { data: staffData, isError, isFetching, isLoading },
        entity,
        permissions,
        isUpdatingUser,
        onAcceptRequest,
        onDeleteStaff,
        onLoadMore,
    } = props;

    const titleTemplate = (row: UserDetails) => {
        return (
            <ListItem
                title={row.userDetails.name}
                start={
                    <Avatar
                        image={row.userDetails.picture}
                        label={
                            row.userDetails.picture
                                ? undefined
                                : row.userDetails.name
                        }
                    />
                }
                compact
            />
        );
    };

    const columnSchema: ColumnProps[] = [
        {
            body: isLoading ? <Skeleton /> : titleTemplate,
            field: 'given_name',
            header: 'Name',
        },
        {
            body: isLoading ? (
                <Skeleton />
            ) : (
                (row) =>
                    row.createdAt &&
                    format(new Date(row.createdAt), 'dd/MM/yyyy p')
            ),
            field: 'createdAt',
            header: 'Request Sent',
        },
        {
            align: 'right',
            body: isLoading ? (
                <Skeleton />
            ) : (
                (row) => {
                    return (
                        <div
                            className="p-button-group"
                            style={{ justifyContent: 'flex-end' }}
                        >
                            {permissions.canEdit && (
                                <RookieButton
                                    label="Accept"
                                    onClick={() => onAcceptRequest(row)}
                                    severity="success"
                                />
                            )}
                            {permissions.canDelete && (
                                <RookieButton
                                    label="Delete"
                                    onClick={() => onDeleteStaff(row)}
                                    severity="secondary"
                                />
                            )}
                        </div>
                    );
                }
            ),
        },
    ];

    const columns = columnSchema.map((col: ColumnProps) => {
        return <Column key={col.field} {...col} />;
    });

    const copyJoinCode = (code: string) => {
        navigator.clipboard.writeText(code);

        // Show toast
        toast?.current?.show({
            summary: 'Team code copied',
            detail: code,
        });
    };

    const teamJoinCode =
        entity &&
        'teamJoinMode' in entity &&
        entity.teamJoinMode !== 'Disabled' &&
        entity.teamJoinCode;

    const emptyMessage = (
        <ErrorDisplay
            actions={[
                {
                    label: teamJoinCode
                        ? teamJoinCode
                        : 'Enable Team Join Code',
                    tooltip: !!teamJoinCode ? 'Copy code to clipboard' : '',
                    tooltipOptions: {
                        showDelay: 500,
                    },
                    icon: teamJoinCode ? 'content_copy' : '',
                    severity: 'secondary',
                    onClick: () => {
                        if (teamJoinCode) {
                            copyJoinCode(teamJoinCode);
                        } else {
                            const settingsUrl = location.pathname.replace(
                                'staff',
                                'settings'
                            );
                            navigate(settingsUrl);
                        }
                    },
                },
            ]}
            alignment="middle"
            errorType={ERROR_TYPES.inboxEmpty}
            hasReturn={false}
            proportion="compact"
            title={isError ? 'An error has occurred' : `No Requests`}
            desc={
                isError
                    ? 'Refresh to try the request again'
                    : `Team Join Code requests will appear here. ${
                          teamJoinCode
                              ? "Copy and share your team's code below"
                              : 'Your Team Join Code is currently disabled. Go to your teams settings to enable it.'
                      }`
            }
        />
    );

    const footerTemplate = () => {
        return (
            <RookieButton
                onClick={onLoadMore}
                severity="secondary"
                label="Load more"
                icon="pending"
            />
        );
    };

    const showPagination = !!staffData?.lastEvaluatedKey?.cursor;

    const staff = useMemo(() => {
        if (!staffData?.data) return [];

        return staffData.data.filter((staff) =>
            staff.roles.some((role) =>
                [
                    'teamsPending',
                    'organisationsPending',
                    'associationsPending',
                ].includes(role.roleID)
            )
        );
    }, [staffData]);

    return (
        <DataTable
            value={isLoading ? Array(3).fill(0) : staff}
            loading={isFetching && !isLoading}
            footer={!isFetching && showPagination ? footerTemplate : null}
            emptyMessage={emptyMessage}
            rowClassName={(row) =>
                !!isUpdatingUser && isUpdatingUser === row.userID
                    ? 'is-loading'
                    : ''
            }
        >
            {isLoading ? columns : staff.length > 0 ? columns : null}
        </DataTable>
    );
};

export default StaffRequestView;
