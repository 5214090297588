import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
    useCreateAssociationSeasonTeamMutation,
    useDeleteAssociationSeasonTeamMutation,
    useGetAssociationSeasonTeamsQuery,
} from '../../api/seasons';
import { useContext, useState } from 'react';
import { confirmDialog } from 'primereact/confirmdialog';
import SeasonTeamsView from './SeasonTeamsView';
import { SeasonTeam } from '../../types/seasons';
import { ToastContext } from '../../contexts/ToastContext';
import { Team } from '../../types/team';

const SeasonTeamsContainer = () => {
    // Route Hooks
    const { associationID, seasonID } = useParams();

    // Global Toast
    const toast = useContext(ToastContext);

    // State Hooks
    const [cursor, setCursor] = useState<string>('');
    const [selectedTeam, setSelectedSeason] = useState<SeasonTeam | null>(null);
    const [showCreateDialog, setShowCreateDialog] = useState<boolean>(false);

    // API Hooks
    const [deleteSeasonTeam] = useDeleteAssociationSeasonTeamMutation();
    const [createSeasonTeam] = useCreateAssociationSeasonTeamMutation();

    const teamData = useGetAssociationSeasonTeamsQuery(
        {
            associationID: associationID || '',
            seasonID: seasonID || '',
            cursor,
            expand: 'participatingTeam',
        },
        {
            skip: !associationID,
        }
    );

    const handleDelete = () => {
        const teamName = selectedTeam?.teamDetails.teamName;
        confirmDialog({
            message: `Are you sure you want to remove ${
                selectedTeam ? teamName : 'this team'
            }?`,
            header: 'Remove Confirmation',
            acceptClassName: 'p-button-danger',
            accept: () =>
                associationID &&
                selectedTeam &&
                deleteSeasonTeam({
                    associationID,
                    seasonID: seasonID || '',
                    teamID: selectedTeam.teamID,
                }).then((response) => {
                    if (toast && toast.current) {
                        toast.current.show({
                            summary: `Successfully removed ${
                                teamName ? teamName : 'team'
                            } from season.`,
                        });
                    }
                }),
        });
    };

    const handleSetFocus = (team: SeasonTeam) => {
        setSelectedSeason(team);
    };

    const handleLoadMore = () => {
        if (teamData?.data?.lastEvaluatedKey?.cursor) {
            setCursor(teamData?.data?.lastEvaluatedKey.cursor);
        }
    };

    const handleAddTeamToSeason = (team: Team) => {
        setShowCreateDialog(false);

        createSeasonTeam({
            associationID: associationID || '',
            seasonID: seasonID || '',
            teamID: team.teamID,
        })
            .then((response) => {
                if (toast && toast.current) {
                    toast.current.show({
                        severity: 'success',
                        summary: `Successfully Added ${team.teamName} to season.`,
                    });
                }
            })
            .catch((err) => {
                console.warn(err);
            });
    };

    const handleCreate = () => {
        setSelectedSeason(null);
        setShowCreateDialog(true);
    };

    const handleCloseCreateDialog = () => {
        setShowCreateDialog(false);
    };

    return (
        <SeasonTeamsView
            teamData={teamData}
            onCreate={handleCreate}
            onDelete={handleDelete}
            onLoadMore={handleLoadMore}
            setFocus={handleSetFocus}
            showPagination={!isEmpty(cursor)}
            showCreateDialog={showCreateDialog}
            onCloseCreateDialog={handleCloseCreateDialog}
            addTeamToSeason={handleAddTeamToSeason}
        />
    );
};

export default SeasonTeamsContainer;
