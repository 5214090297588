import { SelectButton } from 'primereact/selectbutton';
import { Slider } from 'primereact/slider';
import { PlayerAlertModes } from '../../../types/game';

interface Props {
    mode: PlayerAlertModes;
    target: number;
    onModeChange: (mode: PlayerAlertModes) => void;
    onTargetChange: (target: number) => void;
}

const PlayerAlertSettings = (props: Props) => {
    return (
        <div style={{ width: '400px' }}>
            <h4 style={{ marginBottom: '20px' }}>Set Target</h4>
            <SelectButton
                style={{ marginBottom: '20px' }}
                value={props.mode}
                options={[
                    { label: 'None', value: 'None' },
                    {
                        label: 'Minimum',
                        value: 'MinimumGameTime',
                    },
                ]}
                onChange={(e) => props.onModeChange(e.value)}
            />
            {props.mode !== 'None' && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <label style={{ flex: 'none', margin: '0 8px' }}>20%</label>
                    <Slider
                        style={{ flex: '1' }}
                        value={props.target * 100}
                        onChange={(e) =>
                            props.onTargetChange(Number(e.value) / 100)
                        }
                        min={20}
                    />
                    <label style={{ flex: 'none', margin: '0 8px' }}>
                        100%
                    </label>
                </div>
            )}
        </div>
    );
};

export default PlayerAlertSettings;
