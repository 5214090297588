import { useRef } from 'react';
import { isEmpty } from 'lodash';

import playLogo from '../../../assets/images/logos/rm-play-logo.svg';

import PageContainer from '../../../layout/PageContainer';
import PageHeader from '../../../layout/PageHeader';
import { Chart } from 'primereact/chart';
import { Toolbar } from 'primereact/toolbar';
import { Image } from 'primereact/image';
import { Dropdown } from 'primereact/dropdown';
import { SelectItem } from 'primereact/selectitem';
import ErrorDisplay from '../../../components/ErrorDisplay';
import FormGroup from '../../../components/FormGroup';
import Loader from '../../../components/Loader';
import EventSelect from '../components/EventSelect';
import SeasonSelect from '../components/SeasonSelect';

import { ERROR_TYPES } from '../../../types/common';
import { Team } from '../../../types/team';
import { ReportDataFilters } from '../../../types/reports';

interface Props {
    chartData: any;
    chartOptions: any;
    filters: ReportDataFilters;
    isLoading: boolean;
    options: Record<string, SelectItem[]>;
    season: string;
    team?: Team;
    onDownloadChartImage: (chart: Chart) => void;
    onFilterChange: (key: string, value: any) => void;
    onSeasonChange: (value: string) => void;
}

const TeamStatDensityReportView = ({
    chartData,
    chartOptions,
    filters,
    isLoading,
    options,
    season,
    team,
    onSeasonChange,
    onFilterChange,
    onDownloadChartImage,
}: Props) => {
    const chartRef = useRef<Chart>(null);

    const leftToolbar = (
        <div className="p-button-group">
            <>
                <FormGroup label="Season">
                    <SeasonSelect
                        onChange={(e) => onSeasonChange(e.value)}
                        value={season}
                        placeholder="Select Season"
                        teamID={team?.teamID}
                        initialOptions={
                            team?.defaultSeasonDetails && [
                                team.defaultSeasonDetails,
                            ]
                        }
                    />
                </FormGroup>
                <FormGroup label="Event">
                    <EventSelect
                        key={season}
                        onChange={(e) => onFilterChange('event', e.value)}
                        value={filters.event}
                        placeholder="Entire Season"
                        teamID={team?.teamID}
                        reportType="Game"
                        seasonID={season}
                        maxSelectedLabels={1}
                        selectAllLabel="Select All"
                        selectedItemsLabel={`${filters.event?.length} Selected`}
                        multiselect={true}
                    />
                </FormGroup>
                <FormGroup label="Period">
                    <Dropdown
                        value={filters.period}
                        options={[
                            { label: 'All', value: 0 },
                            ...options.periods,
                        ]}
                        onChange={(e) => onFilterChange('period', e.value)}
                    />
                </FormGroup>

                {options.stats.length > 0 && (
                    <FormGroup label="Stat">
                        <Dropdown
                            onChange={(e) => onFilterChange('stat', e.value)}
                            value={filters.stat}
                            options={options.stats}
                        />
                    </FormGroup>
                )}
            </>
        </div>
    );

    const renderEmpty = () => {
        let msg;

        if (
            (!filters.event || filters.event.length === 0) &&
            (!filters.period || filters.period !== 0)
        ) {
            msg = 'There are no complete matches for this team.';
        } else {
            msg = 'There is no data.';
        }
        const errorType = filters.event
            ? ERROR_TYPES.somethingsWrong
            : ERROR_TYPES.notFound;

        return (
            <ErrorDisplay
                alignment="middle"
                title="No Data Found"
                desc={msg}
                errorType={errorType}
                hasReturn={false}
                proportion="compact"
            />
        );
    };

    const tableFooter = (
        <div className="report-disclaimer">
            <span>Report generated by</span>{' '}
            <Image height="24px" src={playLogo} alt="Rookie Me Play" />
        </div>
    );

    return (
        <PageContainer>
            <PageHeader
                title="Stat Density"
                beaconArticle="6723f138d0576266aafb792a"
                actions={[
                    {
                        onClick: () =>
                            chartRef.current &&
                            onDownloadChartImage(chartRef.current),
                        label: 'Export',
                        severity: 'secondary',
                        icon: 'download',
                    },
                ]}
            />
            <Toolbar className="report-toolbar" start={leftToolbar} />

            {isLoading ? (
                <Loader />
            ) : isEmpty(chartData) ? (
                renderEmpty()
            ) : (
                <div style={{ maxHeight: 'auto' }}>
                    <Chart
                        ref={chartRef}
                        type="line"
                        data={chartData}
                        options={chartOptions}
                    />
                    {tableFooter}
                </div>
            )}
        </PageContainer>
    );
};

export default TeamStatDensityReportView;
