import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { uniq, get, sortBy, isObject } from 'lodash';

// Hooks for fetching data from APIs.
import { useLazyGetPlayersQuery } from '../../../api/players';
import { useGetSportPositionsQuery } from '../../../api/sports';
import { useLazyGetTeamSeasonPlusMinusReportQuery } from '../../../api/reports';
import { useGetTeamQuery } from '../../../api/teams';

// Components for rendering and types
import { DataTable } from 'primereact/datatable';
import { SelectItem } from 'primereact/selectitem';

// Helpers and utility functions.
import { defaultReportState } from '../constants';
import { calculateTotals, formatValue } from '../helpers';
import { config } from '../reportConfig';
import { periodSuffix } from '../../../util/helper';
import { Mixpanel } from '../../../util/mixpanel';

// Types
import {
    ReportColumn,
    ReportDataFilters,
    ReportDataTypes,
    ReportDataViews,
    ReportState,
    ReportType,
    StatTypes,
    TimeStat,
} from '../../../types/reports';
import { Player } from '../../../types/team';
import TeamPlayerPlusMinusReportView from './TeamPlayerPlusMinusReportView';

// Data options for different displays (Total or Average).
const dataTypeOptions = [
    {
        label: ReportDataTypes.Total,
        value: ReportDataTypes.Total,
    },
    {
        label: ReportDataTypes.Average,
        value: ReportDataTypes.Average,
    },
];

const TeamPlayerPlusMinusReportContainer = () => {
    // Retrieve the team ID from the route.
    const { teamID } = useParams();

    // Search Params for preloading event, season and category filters.
    const [searchParams] = useSearchParams();
    const eventParam = searchParams.get('event');
    const seasonParam = searchParams.get('season');
    const categoryParam = searchParams.get('category');

    // State Hooks
    const [category, setCategory] = useState<string>(categoryParam || '');
    const [season, setSeason] = useState(seasonParam || '');
    const [hideEmptyColumns, setHideEmptyColumns] = useState<boolean>(true);
    const [emptyColumns, setEmptyColumns] = useState<string[]>([]);
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
    const [columnOrder, setColumnOrder] = useState<string[]>([]);
    const [reportData, setReportData] = useState<{
        [seasonID: string]: ReportState<any>;
    }>({});

    const [filters, setFilters] = useState<ReportDataFilters>({
        event: eventParam ? [eventParam] : [],
        period: 0,
    });
    const [views, setViews] = useState<ReportDataViews>({
        dataType: ReportDataTypes.Total,
    });
    const [loadingPlayers, setLoadingPlayers] = useState(false);
    const [players, setPlayers] = useState<{ [playerID: string]: Player }>({});

    const [isFetchingArchived, setIsFetchingArchived] = useState(false);
    const [hasFetchedArchived, setHasFetchedArchived] = useState(false);

    // API
    const [requestReport] = useLazyGetTeamSeasonPlusMinusReportQuery();
    const [fetchPlayers] = useLazyGetPlayersQuery();

    const teamData = useGetTeamQuery({
        teamID: teamID || '',
        expand: 'defaultSeasonDetails',
    });

    const team = useMemo(() => teamData.data?.data, [teamData]);

    const reportSeasonData = useMemo(
        () => reportData[season]?.data || [],
        [reportData, season]
    );

    const sportPositions = useGetSportPositionsQuery(
        { sportID: team?.entitySportID ?? '' },
        { skip: !team }
    );

    // Cache busting ref
    const timestampRef = useRef(Date.now()).current;

    // Local Storage helpers
    const storageKey = useMemo(
        () => `${ReportType.gamePlusMinus}-${teamID}`,
        [teamID]
    );

    // Save report settings to local storage
    const saveValue = (key: string, value: any) => {
        const ls = localStorage.getItem(storageKey);
        const parsed = ls ? JSON.parse(ls) : {};

        parsed[key] = value;

        localStorage.setItem(storageKey, JSON.stringify(parsed));
    };

    // Load report settings from local storage
    const loadValue = (key: string) => {
        const ls = localStorage.getItem(storageKey);
        const parsed = ls && JSON.parse(ls);

        return parsed && parsed[key];
    };

    // Handle changes to report filters
    const handleFilterChange = (key: string, value: string) => {
        setFilters((state) => ({
            ...state,
            [key]: value,
        }));
    };

    const calculateGamesPlayed = useCallback(
        (playerID: string) => {
            const hasEventFilter = filters.event && filters.event.length > 0;

            return reportSeasonData.filter(
                (o: any) =>
                    (!hasEventFilter &&
                        o.playerID === playerID &&
                        o.period === 1) ||
                    (hasEventFilter &&
                        filters.event?.includes(o.eventID) &&
                        o.playerID === playerID &&
                        o.period === 1)
            ).length;
        },
        [reportSeasonData, filters.event]
    );

    // Dynamically generate report columns based on fetched data and filters
    const columns: ReportColumn[] = useMemo(() => {
        if (!reportSeasonData) {
            return [];
        }

        let cols: ReportColumn[] = [
            config.playerNumber,
            config.playerName,
            config.playerGroup,
        ];

        cols.push({
            id: 'gamesPlayed',
            name: 'Games Played',
            shortName: 'Games',
            persist: true,
            sortable: true,
            field: 'gamesPlayed',
        });

        cols = cols.concat([
            {
                id: 'periodPlusMinus',
                description: `Plus minus whilst playing in the forward zone.`,
                field: 'periodPlusMinus',
                name: 'Total Plus Minus',
                shortName: '+/-',
                category: 'General',
                type: StatTypes.Count,
            },
            {
                id: 'Forward',
                description: `Plus minus whilst playing in the forward zone.`,
                field: 'Forward',
                name: 'Forward Zone',
                shortName: 'FWD',
                category: 'Zone',
                type: StatTypes.Count,
            },
            {
                id: 'Midfield',
                description: `Plus minus whilst playing in the mid zone.`,
                field: 'Midfield',
                name: 'Mid Zone',
                shortName: 'MID',
                category: 'Zone',
                type: StatTypes.Count,
            },
            {
                id: 'Back',
                description: `Plus minus whilst playing in the defensive zone.`,
                field: 'Back',
                name: 'Defensive Zone',
                shortName: 'DEF',
                category: 'Zone',
                type: StatTypes.Count,
            },
        ]);

        // Get all unique report keys
        const reportKeys = [
            ...Array.from(
                new Set(
                    reportSeasonData
                        .map((data: any) => Object.keys(data))
                        .flat(1)
                )
            ),
        ];

        const sortedPositions = sortBy(
            sportPositions.data?.data,
            'positionSortOrder'
        );

        // Add Positional Stats
        sortedPositions.forEach((pos) => {
            if (reportKeys.includes(pos.positionID)) {
                cols.push({
                    id: pos.positionID,
                    description: `Plus minus whilst playing in the ${pos.positionName} position.`,
                    field: pos.positionID,
                    name: pos.positionName,
                    shortName: pos.positionShortName,
                    category: 'Positions',
                    type: StatTypes.Count,
                });
            }
        });

        return cols;
    }, [reportSeasonData, sportPositions]);

    // Function to auto-paginate players
    const loadPlayers = useCallback(
        async (status: 'Active' | 'Archived') => {
            if (!teamID) return;

            let cursor = '';
            let isFetching = true;
            const fetchedPlayers: { [playerID: string]: Player } = {}; // Store players in object format

            while (isFetching) {
                try {
                    const response = await fetchPlayers({
                        cursor,
                        status,
                        teamID,
                    }).unwrap();

                    if (response?.data) {
                        response.data.forEach((player: any) => {
                            fetchedPlayers[player.playerID] = player;
                        });

                        cursor = response?.lastEvaluatedKey?.cursor || '';
                        isFetching = !!cursor;
                    } else {
                        isFetching = false;
                    }
                } catch (error) {
                    console.error(`Error fetching ${status} players`, error);
                    isFetching = false;
                }
            }

            return fetchedPlayers;
        },
        [teamID, fetchPlayers]
    );

    // Fetch active players
    useEffect(() => {
        if (!teamID) return;

        setLoadingPlayers(true);

        loadPlayers('Active')
            .then((players) => players && setPlayers(players))
            .finally(() => setLoadingPlayers(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamID]);

    // Fetch Archived Players if required
    useEffect(() => {
        if (!reportSeasonData || hasFetchedArchived || isFetchingArchived)
            return;

        const hasMissingPlayers = reportSeasonData.some(
            (stat: any) => !players[stat.playerID]
        );

        if (hasMissingPlayers) {
            setIsFetchingArchived(true);

            loadPlayers('Archived')
                .then((archivedPlayers) => {
                    archivedPlayers &&
                        setPlayers((prev) => ({ ...prev, ...archivedPlayers })); // Merge archived players
                })
                .finally(() => {
                    setIsFetchingArchived(false);
                    setHasFetchedArchived(true);
                });
        }
    }, [
        reportSeasonData,
        players,
        loadPlayers,
        hasFetchedArchived,
        isFetchingArchived,
    ]);

    // Handle fetching the report data when filters change or on mount
    useEffect(() => {
        if (reportSeasonData) {
            const emptyCols = columns
                .filter((col) => {
                    if (col.persist) return false;
                    return (
                        reportSeasonData.filter((row: TimeStat) => {
                            const value = col.field && get(row, col.field);

                            return value || value > 0;
                        }).length <= 0
                    );
                })
                .map((col) => col.id);

            setEmptyColumns(emptyCols);
        }
    }, [columns, reportSeasonData]);

    useEffect(() => {
        const lsHiddenColumns = loadValue('hiddenColumns');
        const lsColumnOrder = loadValue('columnOrder');

        setHiddenColumns(() => {
            if (category === '') {
                return lsHiddenColumns ? lsHiddenColumns : [];
            }
            return columns
                .filter((col) => {
                    const inCategory = col.category === category;

                    return (
                        !col.persist &&
                        (hideEmptyColumns
                            ? emptyColumns.includes(col.id) || !inCategory
                            : !inCategory)
                    );
                })
                .map((col) => col.id);
        });

        setColumnOrder(
            category === '' && lsColumnOrder
                ? lsColumnOrder
                : columns.map((col) => col.id)
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category, columns, emptyColumns, hideEmptyColumns]);

    const handleViewChange = (key: string, value: any) => {
        setViews((state) => ({
            ...state,
            [key]: value,
        }));
    };

    // Set the season to the first available season
    useEffect(() => {
        if (!season && team) {
            setSeason(team.defaultSeasonID);
        }
    }, [team, season]);

    // Set data type to totals when filtering by a single event
    useEffect(() => {
        if (
            filters.event?.length === 1 &&
            views.dataType === ReportDataTypes.Average
        ) {
            handleViewChange('dataType', ReportDataTypes.Total);
        }
    }, [filters.event, views.dataType]);

    const exportCSV = (dataTable: DataTable<any>) => {
        dataTable && dataTable.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: 'Team Player Plus Minus Report',
        });
    };

    const categoryOptions = useMemo(() => {
        let options: SelectItem[] = [
            {
                label: 'Custom',
                value: '',
            },
        ];

        let availableCategories = uniq(
            columns.filter((col) => col.category).map((col) => col.category)
        );

        availableCategories.forEach((cat) => {
            options.push({
                label: cat,
                value: cat,
            });
        });

        return options;
    }, [columns]);

    const periodOptions = useMemo(() => {
        if (!reportSeasonData) {
            return [];
        }
        // Get total periods for the individual game
        const totalPeriods = reportSeasonData.reduce(
            (maxPeriod: number, item: TimeStat) => {
                return item.period > maxPeriod ? item.period : maxPeriod;
            },
            0
        );

        return Array.from({ length: totalPeriods }).map((u, i) => {
            const period = i + 1;
            const label = `${period}${periodSuffix(Number(period))}`;
            return {
                label: label,
                value: period,
            };
        });
    }, [reportSeasonData]);

    const handleColumnOrderChange = (col: ReportColumn[]) => {
        const newState = col.map((c) => c.id);
        setColumnOrder(newState);
        saveValue('columnOrder', newState);
    };

    const handleHiddenColumnChange = (value: string) => {
        setHiddenColumns((prevState) => {
            const newState = prevState.includes(value)
                ? prevState.filter((col) => col !== value)
                : [...prevState, value];

            saveValue('hiddenColumns', newState);

            return newState;
        });
    };

    useEffect(() => {
        if (teamID && season && !reportData[season]) {
            const fetchReport = async () => {
                try {
                    // Set report loading in state
                    setReportData((prev) => ({
                        ...prev,
                        [season]: {
                            ...defaultReportState,
                            isLoading: true,
                            isUninitialized: false,
                        },
                    }));

                    //fetch report url
                    const requestReportData = await requestReport({
                        seasonID: season,
                        teamID,
                        sessionID: timestampRef,
                    }).unwrap();

                    const reportUrl = requestReportData?.data?.objectURL;

                    if (reportUrl) {
                        // Fetch report data
                        const response = await fetch(reportUrl);

                        if (!response.ok) {
                            throw new Error('Failed to load report');
                        }

                        const report = await response.json();

                        // Set report in state
                        setReportData((prev) => ({
                            ...prev,
                            [season]: {
                                ...prev[season],
                                isLoading: false,
                                data: report,
                            },
                        }));
                    } else {
                        throw new Error('Invalid report URL or request failed');
                    }
                } catch (error) {
                    // Set report error in state
                    setReportData((prev) => ({
                        ...prev,
                        [season]: {
                            ...prev[season],
                            isLoading: false,
                            isError: true,
                            error:
                                isObject(error) && 'message' in error
                                    ? error.message
                                    : error,
                        },
                    }));
                }
            };

            fetchReport();
        }
    }, [teamID, season, reportData, requestReport, timestampRef]);

    const resetFilters = () => {
        setFilters({
            event: [],
            period: 0,
        });
    };

    const handleSeasonChange = (seasonID: string) => {
        setSeason(seasonID);
        resetFilters();
    };

    // Helper for filtering
    const filterData = (data: any, filters: ReportDataFilters) => {
        return data.filter((item: TimeStat) => {
            if (item.period === 'total') return false;

            const hasEventFilter = filters.event && filters.event.length > 0;
            const hasPeriodFilter = filters.period && filters.period > 0;

            if (hasEventFilter && !filters.event?.includes(item.eventID)) {
                return false;
            }
            if (hasPeriodFilter && filters.period !== Number(item.period)) {
                return false;
            }

            return true;
        });
    };

    const tableData = useMemo(() => {
        let newData = reportSeasonData;

        if (!newData) return [];

        // Filter data
        newData = filterData(newData, filters);

        // Calculate totals
        newData = calculateTotals(newData, 'playerID');

        // Format data
        return newData.map((data: any) => {
            const uniqueKeys = uniq([
                ...Object.keys(data),
                ...columns.map((col) => col.id),
            ]);

            // Inject player details into report data
            const player = players[data.playerID];

            // Calculate games played
            const gamesPlayed = calculateGamesPlayed(data.playerID);

            const formattedValues = uniqueKeys.reduce(
                (acc, key) => {
                    const column = columns.find((col) => col.id === key);

                    if (column?.format) {
                        acc[key] = column.format(data, {
                            field: key,
                            column,
                            views,
                            data: reportSeasonData,
                        });
                    } else {
                        acc[key] = formatValue(
                            data[key],
                            column?.type,
                            views.dataType === ReportDataTypes.Average,
                            gamesPlayed
                        );
                    }

                    return acc;
                },
                { ...data }
            );

            return {
                ...formattedValues,
                player,
                gamesPlayed,
            };
        });
    }, [
        columns,
        reportSeasonData,
        views,
        players,
        filters,
        calculateGamesPlayed,
    ]);

    const visibleColumns = useMemo((): ReportColumn[] => {
        return sortBy(
            columns.filter((col) => {
                const isHidden = hiddenColumns.includes(col.id);
                const hideEmpty =
                    hideEmptyColumns && emptyColumns.includes(col.id);

                return !isHidden && !hideEmpty;
            }),
            (col) => columnOrder.indexOf(col.id)
        );
    }, [columns, emptyColumns, columnOrder, hiddenColumns, hideEmptyColumns]);

    const customisableColumns = useMemo(() => {
        return sortBy(
            columns.filter((col) => {
                return (
                    !(hideEmptyColumns && emptyColumns.includes(col.id)) &&
                    !col.persist
                );
            }),
            (col) => columnOrder.indexOf(col.id)
        );
    }, [columns, emptyColumns, columnOrder, hideEmptyColumns]);

    const isLoading =
        sportPositions.isLoading ||
        teamData.isLoading ||
        reportData[season]?.isLoading ||
        loadingPlayers ||
        isFetchingArchived;

    return (
        <TeamPlayerPlusMinusReportView
            category={category}
            columns={visibleColumns}
            customisableColumns={customisableColumns}
            data={tableData}
            emptyColumns={emptyColumns}
            filters={filters}
            hideEmptyColumns={hideEmptyColumns}
            hiddenColumns={hiddenColumns}
            loading={isLoading}
            options={{
                categories: categoryOptions,
                dataType: dataTypeOptions,
                periods: periodOptions,
            }}
            season={season}
            team={team}
            views={views}
            onCategoryChange={setCategory}
            onColumnsChange={handleColumnOrderChange}
            onExport={exportCSV}
            onFilterChange={handleFilterChange}
            onHiddenColumnChange={handleHiddenColumnChange}
            onHideEmptyColumns={setHideEmptyColumns}
            onSeasonChange={handleSeasonChange}
            onViewsChange={handleViewChange}
        />
    );
};

export default TeamPlayerPlusMinusReportContainer;
