import { useEffect, useState } from 'react';

import { STATIC_ENDPOINT_SPORTS } from '../../util/constants';

import { Dropdown } from 'primereact/dropdown';
import CardButton from '../../components/CardButton';
import ErrorDisplay from '../../components/ErrorDisplay';
import SportIcon from '../../components/SportIcon';

import { Sport } from '../../types/sports';

interface Props {
    appearance: 'card' | 'dropdown';
    onClick?: (sport: Sport) => void;
    selected?: string | null;
    unavailableSportIDs?: string[];
}

const SportSelector = ({
    appearance,
    onClick,
    selected,
    unavailableSportIDs,
}: Props) => {
    const [sports, setSports] = useState<Sport[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch(STATIC_ENDPOINT_SPORTS)
            .then((response) => response.json())
            .then((json) => setSports(json))
            .catch((error) => console.error('Error fetching sports:', error))
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (appearance === 'card') {
        if (sports.length <= 0) {
            return <ErrorDisplay title="No sports found" hasReturn={false} />;
        }

        return (
            <div className="card-button-group">
                {sports.map((sport) => {
                    const disabled = unavailableSportIDs?.includes(
                        sport.sportID
                    );
                    return (
                        <CardButton
                            align="center"
                            onClick={() =>
                                onClick && !disabled && onClick(sport)
                            }
                            label={sport.sportName}
                            icon={
                                <SportIcon
                                    sportID={sport.sportID}
                                    style={{
                                        width: '64px',
                                        height: '64px',
                                    }}
                                />
                            }
                            disabled={disabled}
                        />
                    );
                })}
            </div>
        );
    } else {
        const selectSportObj = sports.find(
            (sport) => sport.sportID === selected
        );

        return (
            <Dropdown
                options={sports.map((sport) => ({
                    label: sport.sportName,
                    id: sport.sportID,
                    data: sport,
                    disabled: unavailableSportIDs?.includes(sport.sportID),
                }))}
                value={
                    selectSportObj && {
                        label: selectSportObj.sportName,
                        id: selectSportObj.sportID,
                        data: selectSportObj,
                        disabled: unavailableSportIDs?.includes(
                            selectSportObj.sportID
                        ),
                    }
                }
                onChange={(e) => onClick && onClick(e.value.data)}
                placeholder="Select Sport"
                itemTemplate={(props) => (
                    <div className="p-dropdown-value">
                        {props?.data?.sportID && (
                            <SportIcon
                                sportID={props.data.sportID}
                                size="small"
                            />
                        )}
                        {props?.label}
                    </div>
                )}
                valueTemplate={(props) => {
                    return (
                        <div className="p-dropdown-value">
                            {props?.data?.sportID && (
                                <SportIcon
                                    sportID={props.data.sportID}
                                    size="small"
                                />
                            )}
                            {props?.label}
                        </div>
                    );
                }}
            />
        );
    }
};

SportSelector.defaultProps = {
    appearance: 'dropdown',
};

export default SportSelector;
