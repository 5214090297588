import { createApi } from '@reduxjs/toolkit/query/react';
import { generatePath } from 'react-router-dom';

import { apiEndpoints } from './apiEndpoints';
import { baseQueryWithReauth } from '../util/baseQuery';

import {
    Sports,
    Sport,
    EntityCategoryResponse,
    EntityCategoriesResponse,
    SportPositions,
} from '../types/sports';

export const sportsApi = createApi({
    reducerPath: 'sportsApi',
    baseQuery: (args, api, extraOptions) =>
        baseQueryWithReauth(
            args,
            api,
            extraOptions,
            process.env.REACT_APP_API_URL
        ),
    endpoints: (builder) => ({
        getSports: builder.query<Sports, void>({
            query: () => ({
                url: apiEndpoints.getSports.url,
                method: apiEndpoints.getSports.method,
            }),
        }),
        getSport: builder.query<Sport, { sportID: string }>({
            query: ({ sportID }) => ({
                url: generatePath(apiEndpoints.getSport.url, { sportID }),
                method: apiEndpoints.getSport.method,
            }),
        }),
        getSportPositions: builder.query<SportPositions, { sportID: string }>({
            query: ({ sportID }) => ({
                url: generatePath(apiEndpoints.getSportPositions.url, {
                    sportID,
                }),
                method: apiEndpoints.getSportPositions.method,
            }),
        }),
        getEntityCategories: builder.query<
            EntityCategoriesResponse,
            { sportID: string }
        >({
            query: ({ sportID }) => ({
                url: generatePath(apiEndpoints.getEntityCategories.url, {
                    sportID,
                }),
                method: apiEndpoints.getEntityCategories.method,
            }),
        }),
        getEntityCategory: builder.query<
            EntityCategoryResponse,
            { sportID: string; entityCategoryID: string }
        >({
            query: ({ sportID, entityCategoryID }) => ({
                url: generatePath(apiEndpoints.getEntityCategory.url, {
                    sportID,
                    entityCategoryID,
                }),
                method: apiEndpoints.getEntityCategory.method,
            }),
        }),
    }),
});

export const {
    useGetSportsQuery,
    useGetSportQuery,
    useGetSportPositionsQuery,
    useGetEntityCategoriesQuery,
    useGetEntityCategoryQuery,
} = sportsApi;
