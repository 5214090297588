import { useMemo } from 'react';
import {
    generatePath,
    useNavigate,
    useParams,
    useLocation,
    Outlet,
} from 'react-router-dom';

import { getFirstChars } from '../../util/helper';

// Components
import BreadCrumbs from '../../components/BreadCrumbs';
import EntityAvatar from '../../components/EntityAvatar';
import RookieButton from '../../components/RookieButton';
import PageContainer from '../../layout/PageContainer';
import { Avatar } from 'primereact/avatar';
import { Sidebar } from 'primereact/sidebar';
import { Skeleton } from 'primereact/skeleton';
import { TabMenu } from 'primereact/tabmenu';

import EventForm from './EventForm';
import EventInviteForm from './EventInviteForm';

// Types
import { BaseEntityType } from '../../types/common';
import { UseQueryResult } from '../../types/api';
import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import {
    EventResponse,
    EventStatus,
    EventType,
    InvitedUser,
} from '../../types/event';
import { TeamResponse } from '../../types/team';
import { GameRolesResponse } from '../../types/game';
import usePermission from '../../hooks/usePermission';
import PageHeader from '../../layout/PageHeader';
import SeasonSettings from '../seasons/SeasonSettings';

interface Props {
    roles: Roles;
    route: Route;
    eventData: UseQueryResult<EventResponse>;
    teamData: UseQueryResult<TeamResponse>;
    gameRolesData: UseQueryResult<GameRolesResponse>;
    onCloseEventDialog: () => void;
    onCloseInviteDialog: () => void;
    onCloseSettingsDialog: () => void;
    onEditEvent: () => void;
    onInviteUser: () => void;
    onDeleteInvite: (userID: string) => void;
    onInviteSuccess?: (invite: InvitedUser) => void;
    onInviteError?: (err: any) => void;
    onShowSettingsDialog: () => void;
    showEventDialog: boolean;
    showInviteDialog: boolean;
    showSettingsDialog: boolean;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const EventsSingleView = (props: Props) => {
    const {
        eventData,
        teamData,
        route,
        showEventDialog,
        showInviteDialog,
        showSettingsDialog,
        onCloseEventDialog,
        onCloseInviteDialog,
        onCloseSettingsDialog,
        onInviteUser,
        onInviteError,
        onInviteSuccess,
        onShowSettingsDialog,
        permissions,
    } = props;

    // Route hooks
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const event = eventData?.data?.data;
    const gameSummaryData = event?.gameDetails?.gameSummaryReport;
    const gameSummary = gameSummaryData?.gameSummary;

    const team = teamData?.data?.data;
    const { isAdmin } = usePermission(team?.teamID);

    const opponentName = event?.game?.opponentTeam?.teamName;
    const showReportsTab =
        event && ['Complete', 'Archived'].includes(event.eventStatus);

    const tabs = useMemo(() => {
        let arr = [
            {
                id: 'details',
                label: 'Details',
                data: {
                    path: generatePath(`${route.path}/details`, params),
                },
            },
            {
                id: 'invitees',
                label: 'Invitees',
                data: {
                    path: generatePath(`${route.path}/invitees`, params),
                },
            },
        ];

        if (event?.eventType === EventType.Game) {
            arr.push({
                id: 'lineup',
                label: 'Line-up',
                data: {
                    path: generatePath(`${route.path}/lineup`, params),
                },
            });
            if (showReportsTab) {
                arr.push({
                    id: 'reports',
                    label: 'Reports',
                    data: {
                        path: generatePath(`${route.path}/reports`, params),
                    },
                });
                if (isAdmin) {
                    arr.push({
                        id: 'reports',
                        label: 'Voting',
                        data: {
                            path: generatePath(`${route.path}/voting`, params),
                        },
                    });
                }
            }
        }

        return arr;
    }, [params, route, showReportsTab, isAdmin, event]);

    const renderTeamAvatar = () => {
        return team && !teamData.isLoading ? (
            <EntityAvatar
                entityID={team && team.teamID ? team.teamID : ''}
                entityType={BaseEntityType.teams}
                entityName={team && team.teamName ? team.teamName : ''}
                avatarProps={{ size: 'xlarge' }}
            />
        ) : (
            <Skeleton size="4rem" />
        );
    };

    const renderOppositionAvatar = () => {
        return opponentName ? (
            <Avatar
                label={getFirstChars(opponentName, 2).toUpperCase()}
                size="xlarge"
            />
        ) : (
            <Skeleton size="4rem" />
        );
    };

    const renderGameHeader = () => {
        return (
            <div className="game-header">
                <div className="game-header_team">
                    <div className="game-header_team-details">
                        {eventData.isLoading || !event ? (
                            <Skeleton size="4rem" />
                        ) : event?.game?.isHomeTeam ? (
                            renderTeamAvatar()
                        ) : (
                            renderOppositionAvatar()
                        )}

                        <div className="game-header_team-name">
                            {eventData.isLoading || !event ? (
                                <Skeleton width="10rem" />
                            ) : event.game?.isHomeTeam ? (
                                team?.teamName
                            ) : (
                                opponentName
                            )}
                        </div>
                    </div>
                    <div className="game-header_score">
                        <span>
                            {eventData.isLoading ? (
                                <Skeleton width="3rem" />
                            ) : event?.gameDetails?.gameSummaryReport
                                  ?.sportID === 'aus_football_au' &&
                              gameSummary?.teamScore ? (
                                event?.game?.isHomeTeam ? (
                                    `${gameSummary?.teamGoals}. ${gameSummary?.teamBehinds}. ${gameSummary?.teamScore}`
                                ) : (
                                    `${gameSummary?.oppositionGoals}. ${gameSummary?.oppositionBehinds}. ${gameSummary?.oppositionScore}`
                                )
                            ) : event?.game?.isHomeTeam ? (
                                gameSummary?.teamScore
                            ) : (
                                gameSummary?.oppositionScore
                            )}
                        </span>
                    </div>
                </div>
                <div className="game-header_details">
                    {eventData.isLoading || !event ? (
                        <Skeleton width="3rem" />
                    ) : (
                        event.eventName
                    )}
                </div>
                <div className="game-header_team game-header_team--opponent">
                    <div className="game-header_score">
                        <span>
                            {eventData.isLoading ? (
                                <Skeleton width="3rem" />
                            ) : event?.gameDetails?.gameSummaryReport
                                  ?.sportID === 'aus_football_au' &&
                              gameSummary?.teamScore ? (
                                event?.game?.isHomeTeam ? (
                                    `${gameSummary?.oppositionGoals}. ${gameSummary?.oppositionBehinds}. ${gameSummary?.oppositionScore}`
                                ) : (
                                    `${gameSummary?.teamGoals}. ${gameSummary?.teamBehinds}. ${gameSummary?.teamScore}`
                                )
                            ) : event?.game?.isHomeTeam ? (
                                gameSummary?.oppositionScore
                            ) : (
                                gameSummary?.teamScore
                            )}
                        </span>
                    </div>
                    <div className="game-header_team-details">
                        <div className="game-header_team-name">
                            {eventData.isLoading || !event ? (
                                <Skeleton width="10rem" />
                            ) : event?.game?.isHomeTeam ? (
                                opponentName
                            ) : (
                                team?.teamName
                            )}
                        </div>
                        {eventData.isLoading || !event ? (
                            <Skeleton size="4rem" />
                        ) : event?.game?.isHomeTeam ? (
                            renderOppositionAvatar()
                        ) : (
                            renderTeamAvatar()
                        )}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <PageContainer>
                {event?.eventType === EventType.Game ? (
                    <>
                        <BreadCrumbs />
                        {renderGameHeader()}
                    </>
                ) : (
                    <PageHeader title={event?.eventName || ''} />
                )}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: 'inset 0 -2px 0 var(--border-color-light)',
                        borderTop: '1px solid var(--border-color-light)',
                    }}
                >
                    <TabMenu
                        model={tabs}
                        activeIndex={tabs.findIndex((tab) =>
                            location.pathname.includes(tab.data.path)
                        )}
                        onTabChange={(e) =>
                            e.value.data.path && navigate(e.value.data.path)
                        }
                    />

                    <div className="p-button-group">
                        {event?.eventType === EventType.Game && (
                            <RookieButton
                                severity="secondary"
                                icon="settings"
                                label="Game Settings"
                                onClick={() => onShowSettingsDialog()}
                            />
                        )}
                        {event &&
                            [EventStatus.Published, EventStatus.Live].includes(
                                event.eventStatus
                            ) &&
                            permissions.canCreate && (
                                <RookieButton
                                    icon="send"
                                    label="Invite"
                                    onClick={onInviteUser}
                                />
                            )}
                    </div>
                </div>
                <Outlet context={props} />
            </PageContainer>
            <Sidebar
                header="Edit Event"
                onHide={onCloseEventDialog}
                visible={showEventDialog}
                position="right"
            >
                <EventForm
                    eventID={eventData?.data?.data?.eventID}
                    onSubmit={() => console.log('SUBMIT')}
                />
            </Sidebar>
            {event?.eventType === EventType.Game && (
                <Sidebar
                    header="Game Settings"
                    onHide={onCloseSettingsDialog}
                    visible={showSettingsDialog}
                    position="right"
                >
                    <SeasonSettings
                        gameID={event?.eventID}
                        teamID={teamData.data?.data.teamID}
                        initialValues={event?.gameDetails?.settings}
                        onSuccess={onCloseSettingsDialog}
                        readOnly={
                            !event ||
                            [EventStatus.Complete, EventStatus.Live].includes(
                                event.eventStatus
                            ) ||
                            !permissions.canEdit
                        }
                    />
                </Sidebar>
            )}
            <Sidebar
                header="Invite"
                onHide={onCloseInviteDialog}
                visible={showInviteDialog}
                position="right"
            >
                <EventInviteForm
                    eventID={event?.eventID}
                    onSuccess={onInviteSuccess}
                    onError={onInviteError}
                />
            </Sidebar>
        </>
    );
};
export default EventsSingleView;
