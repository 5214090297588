import {
    BaseQueryApi,
    FetchArgs,
    fetchBaseQuery,
} from '@reduxjs/toolkit/dist/query';

import { USE_CUSTOM_TOKEN, CUSTOM_TOKEN_ID } from '../util/constants';

import { sec } from '../util/security';

const createBaseQuery = async (baseUrl: string | undefined) =>
    fetchBaseQuery({
        baseUrl,
        prepareHeaders: async (headers) => {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const sessionID = urlParams.get('session_id');
                const useCustomToken =
                    localStorage.getItem(USE_CUSTOM_TOKEN) === 'true';
                const customToken = localStorage.getItem(CUSTOM_TOKEN_ID);

                let token: string | null = null;

                if (sessionID) {
                    // Temporary JWT Token from search param
                    token = sessionID;
                } else if (useCustomToken && customToken) {
                    // Persistent JWT Token from localStorage
                    token = customToken;
                } else {
                    // Auth0 Token
                    const { id_token } = await sec.getAccessTokenSilently()({
                        detailedResponse: true,
                    });

                    token = id_token || null;
                }

                // Set Authorization header if a token is found
                if (token) {
                    headers.set('Authorization', `Bearer ${token}`);
                }
            } catch (error) {
                console.error('Error preparing headers:', error);
            }

            return headers;
        },
    });

export const baseQueryWithReauth = async (
    args: string | FetchArgs,
    api: BaseQueryApi,
    extraOptions: {},
    baseUrl: string | undefined
) => {
    const baseQuery = await createBaseQuery(baseUrl); // Create a base query with the dynamic baseUrl
    let result = await baseQuery(args, api, extraOptions);

    // If the API returns a 205 status, refresh the token
    if (result.meta?.response?.status === 205) {
        try {
            sec.getAccessTokenSilently()({
                ignoreCache: true,
            });
        } catch (error) {
            console.warn('Token refresh failed:', error);
        }
    }

    return result;
};
