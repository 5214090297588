import { useRef } from 'react';

import ReportTable from '../components/ReportTable';
import SeasonSelect from '../components/SeasonSelect';
import PageContainer from '../../../layout/PageContainer';
import PageHeader from '../../../layout/PageHeader';
import FormGroup from '../../../components/FormGroup';
import List from '../../../components/List';
import ListItem from '../../../components/ListItem';
import RookieButton from '../../../components/RookieButton';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { InputSwitch } from 'primereact/inputswitch';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Row } from 'primereact/row';
import { SelectButton } from 'primereact/selectbutton';
import { SelectItem } from 'primereact/selectitem';
import { Toolbar } from 'primereact/toolbar';

import {
    GameSummary,
    ReportState,
    ReportColumn,
    ReportDataViews,
} from '../../../types/reports';
import { Team } from '../../../types/team';
import { RookieProduct } from '../../../types/licences';

interface Props {
    aggregateData: any;
    columns: ReportColumn[];
    data: any;
    hiddenColumns: string[];
    hideEmptyColumns: boolean;
    hideIntFGTScore: boolean;
    options: Record<string, SelectItem[]>;
    season: string;
    reportData: ReportState<GameSummary[]>;
    team?: Team;
    views: ReportDataViews;
    onExport: (dataTable: DataTable<any>) => void;
    onHideEmptyColumns: (hide: boolean) => void;
    onHideIntFGTScore: (hide: boolean) => void;
    onSeasonChange: (value: string) => void;
    onViewsChange: (key: string, value: any) => void;
}

const TeamGameSummaryReportView = (props: Props) => {
    const dataTable = useRef<DataTable<any>>(null);
    const columnEdit = useRef<OverlayPanel>(null);

    const {
        aggregateData,
        columns,
        data,
        hiddenColumns,
        hideEmptyColumns,
        hideIntFGTScore,
        options,
        reportData,
        season,
        team,
        views,
        onExport,
        onSeasonChange,
        onViewsChange,
        onHideEmptyColumns,
        onHideIntFGTScore,
    } = props;

    const leftToolbar = (
        <FormGroup label="Season">
            <SeasonSelect
                onChange={(e) => onSeasonChange(e.value)}
                value={season}
                placeholder="Select Season"
                teamID={team?.teamID}
                initialOptions={
                    team?.defaultSeasonDetails && [team.defaultSeasonDetails]
                }
            />
        </FormGroup>
    );

    const rightToolbar = (
        <div className="p-button-group">
            <SelectButton
                value={views.dataType}
                onChange={(e) => onViewsChange('dataType', e.value)}
                options={options.dataType}
                allowEmpty={false}
                pt={{
                    button: {
                        className: 'p-button-sm',
                    },
                }}
            />
            <RookieButton
                label="Customise"
                onClick={(e) => columnEdit?.current?.toggle(e)}
                size="small"
                outlined
                severity="secondary"
                icon="tune"
            />
        </div>
    );

    let footerColumnGroup = (
        <ColumnGroup>
            <Row>
                <Column
                    footer={
                        views.dataType === 'Total' ? 'Totals:' : 'Averages:'
                    }
                    colSpan={6 - (hideIntFGTScore ? 2 : 0)}
                    footerStyle={{ textAlign: 'right' }}
                />
                {hideEmptyColumns &&
                aggregateData.wins === 0 &&
                aggregateData.losses === 0 &&
                aggregateData.draws === 0 ? null : (
                    <Column
                        footer={`W: ${aggregateData.wins} / L: ${aggregateData.losses} / D: ${aggregateData.draws}`}
                        align="center"
                    />
                )}
                {hideEmptyColumns &&
                aggregateData.teamScoringAccuracy === '0%' ? null : (
                    <Column
                        footer={aggregateData.teamScoringAccuracy}
                        align="center"
                    />
                )}
                {hideEmptyColumns && aggregateData.teamScore === 0 ? null : (
                    <Column footer={aggregateData.teamScore} align="center" />
                )}
                {hideEmptyColumns &&
                aggregateData.oppositionScore === 0 ? null : (
                    <Column
                        footer={aggregateData.oppositionScore}
                        align="center"
                    />
                )}
                {hideEmptyColumns && aggregateData.scoreMargin === 0 ? null : (
                    <Column footer={aggregateData.scoreMargin} align="center" />
                )}
                {hideEmptyColumns &&
                aggregateData.rotationCount === 0 ? null : (
                    <Column
                        footer={aggregateData.rotationCount}
                        align="center"
                    />
                )}
            </Row>
        </ColumnGroup>
    );

    return (
        <PageContainer>
            <PageHeader
                title="Summary Report"
                actions={[
                    {
                        onClick: () =>
                            dataTable.current && onExport(dataTable.current),
                        label: 'Export',
                        severity: 'secondary',
                        icon: 'download',
                    },
                ]}
            />
            <Toolbar
                className="report-toolbar"
                start={leftToolbar}
                end={rightToolbar}
            />
            <ReportTable
                ref={dataTable}
                data={data}
                columns={columns.filter(
                    (col) => !hiddenColumns.includes(col.id)
                )}
                isLoading={reportData?.isLoading || reportData?.isUninitialized}
                product={RookieProduct.GameDay}
                dataTableProps={{
                    sortField: 'event.startDateTime',
                    sortOrder: 1,
                    exportFilename: `${team?.teamName} - Game Summary Report`,
                    footerColumnGroup: footerColumnGroup,
                }}
            />
            <OverlayPanel ref={columnEdit} className="column-popover">
                <List>
                    <ListItem
                        title={
                            <label
                                htmlFor="hideIntFgt"
                                style={{
                                    fontSize: 'inherit',
                                    fontWeight: 'inherit',
                                }}
                            >
                                Hide Integrity and FGT Scores
                            </label>
                        }
                        end={
                            <InputSwitch
                                inputId="hideIntFgt"
                                checked={hideIntFGTScore}
                                onChange={(e) => onHideIntFGTScore(e.value)}
                            />
                        }
                    />
                    <ListItem
                        title={
                            <label
                                htmlFor="hideEmpty"
                                style={{
                                    fontSize: 'inherit',
                                    fontWeight: 'inherit',
                                }}
                            >
                                Hide Empty Columns
                            </label>
                        }
                        end={
                            <InputSwitch
                                inputId="hideEmpty"
                                checked={hideEmptyColumns}
                                onChange={(e) => onHideEmptyColumns(e.value)}
                            />
                        }
                    />
                </List>
            </OverlayPanel>
        </PageContainer>
    );
};

export default TeamGameSummaryReportView;
