import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import OrganisationLicenceSingleView from './OrganisationLicenceSingleView.tsx';
import { useGetEntityQuery } from '../../api/core';

import {
    useAssignLicenceMutation,
    useGetLicenceGroupLicencesQuery,
    useGetLicenceGroupQuery,
    useGetLicenceTypesQuery,
    useUnAssignLicenceMutation,
} from '../../api/licences';

import { BaseEntityType } from '../../types/common';
import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { User } from '../../types/user';
import { Team } from '../../types/team';
import usePermission from '../../hooks/usePermission';
import { Permissions } from '../../types/permissions';
import { getEntityFromParam } from '../../util/helper';
import { LicenceGroupInfo, LicenceType } from '../../types/licences.js';

interface Props {
    roles: Roles;
    route: Route;
    user: User;
}

const OrganisationLicenceSingleContainer = (props: Props) => {
    const { user } = props;
    const params = useParams();
    const navigate = useNavigate();
    const entity = getEntityFromParam(params);

    const [showAssignModal, setShowAssignModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const { checkPermission } = usePermission(params.organisationID || '');

    const entityData = useGetEntityQuery(
        {
            entityType: entity?.entityType as BaseEntityType,
            entityID: entity?.entityID ?? '',
        },
        { skip: !entity }
    );

    const { data: licencesRaw, isLoading } = useGetLicenceGroupLicencesQuery(
        {
            entityType: BaseEntityType.organisations,
            entityID: params.organisationID ?? '',
            licenceGroupID: params.licenceGroupID ?? '',
        },
        {
            skip: !params.organisationID || !params.licenceGroupID,
        }
    );

    const { data: licenceGroupRaw } = useGetLicenceGroupQuery(
        {
            entityType: BaseEntityType.organisations,
            entityID: params.organisationID ?? '',
            licenceGroupID: params.licenceGroupID ?? '',
        },
        {
            skip: !params.organisationID || !params.licenceGroupID,
        }
    );

    const { data: licenceTypes } = useGetLicenceTypesQuery(
        {
            sportID: entityData?.data?.data?.entitySportID ?? '',
        },
        { skip: !entityData?.data?.data }
    );

    const [assignLicence] = useAssignLicenceMutation();
    const [unassignLicence] = useUnAssignLicenceMutation();

    const handleAssignLicence = (groupID: string, teamsIDList: Team[]) => {
        if (params.organisationID && params.licenceGroupID) {
            assignLicence({
                entityType: BaseEntityType.organisations,
                entityID: params.organisationID,
                teamsIDList: teamsIDList.map((team) => team.teamID),
                licenceGroupID: params.licenceGroupID,
            });
        }
    };

    const handleUnassignLicence = (licenceID: string) => {
        if (params.organisationID) {
            unassignLicence({
                entityType: BaseEntityType.organisations,
                entityID: params.organisationID,
                licenceID,
            });
        }
    };

    const handleManageBilling = (subscriptionID: string) => {
        if (subscriptionID) {
            navigate(`/u/${user.userID}/subscriptions/${subscriptionID}`);
        }
    };

    const licenceGroup: LicenceGroupInfo | null = useMemo(() => {
        if (!licenceGroupRaw?.data) {
            return null;
        }

        const licenceType: LicenceType | undefined = licenceTypes?.data?.find(
            (type) => licenceGroupRaw.data.licenceTypeID === type.licenceTypeID
        );

        const lg = licenceGroupRaw.data;

        const price = licenceType?.product?.prices.find(
            (p) => p.priceID === lg.priceID
        );

        return {
            id: lg.licenceGroupID,
            priceID: price?.priceID || '',
            productTitle: '',
            licenceTitle: '',
            tier: price && price.tier,
            price: (price && price.unitAmount && price.unitAmount / 100) || 0,
            currency: 'AUD',
            createdAt: lg.createdAt,
            lastEdited: lg.lastEditedAt,
            billingPeriod: price && price.recurring?.interval,
            renewsAt: lg.subscription
                ? new Date(lg.subscription.current_period_end * 1000)
                : null,
            trialActive:
                lg.subscription && lg.subscription.trial_end
                    ? new Date().getTime() - lg.subscription.trial_end * 1000 <
                      0
                    : false,
            trialEndsAt:
                lg.subscription && lg.subscription.trial_end
                    ? new Date(lg.subscription.trial_end * 1000)
                    : null,
            qtyAvailable: lg.quantity - lg.totalAssigned,
            qtyAssigned: lg.totalAssigned,
            qtyTotal: lg.quantity,
            status: lg.status,
            groupID: lg.subscriptionID || lg.invoiceID || 'unknown',
            description: '',
            isOwner: lg.createdBy === user.userID || false, // hard-code this for now,
            owner: lg.createdBy,
            features: [],
            entityID: lg.entityID,
            entitySportID: lg.entitySportID,
            entityType: lg.entityType,
            subscriptionID: lg.subscriptionID,
            transactionID: lg.transactionID,
            rookieProduct: lg.rookieProduct,
        };
    }, [licenceGroupRaw, licenceTypes, user]);

    const permissions = {
        canCreate: checkPermission([
            Permissions.POST_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canDelete: checkPermission([
            Permissions.DELETE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canEdit: checkPermission([
            Permissions.PUT_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canView: checkPermission([
            Permissions.GET_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
    };

    return (
        <OrganisationLicenceSingleView
            licences={licencesRaw?.data || []}
            licenceTypes={licenceTypes?.data || []}
            licenceGroup={licenceGroup}
            onAssignLicence={handleAssignLicence}
            onUnassignLicence={handleUnassignLicence}
            showAssignModal={showAssignModal}
            showEditModal={showEditModal}
            onShowEditModal={() => setShowEditModal(true)}
            onCloseEditModal={() => setShowEditModal(false)}
            onShowAssignModal={() => setShowAssignModal(true)}
            onCloseAssignModal={() => setShowAssignModal(false)}
            onManageBilling={handleManageBilling}
            isLoading={isLoading}
            permissions={permissions}
            user={user}
        />
    );
};

export default OrganisationLicenceSingleContainer;
