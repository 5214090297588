import { useRef } from 'react';

import { renderBenchRating } from '../components/DataTableCells';
import ReportTable from '../components/ReportTable';
import SeasonSelect from '../components/SeasonSelect';
import PageContainer from '../../../layout/PageContainer';
import PageHeader from '../../../layout/PageHeader';
import FormGroup from '../../../components/FormGroup';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Row } from 'primereact/row';
import { SelectButton } from 'primereact/selectbutton';
import { SelectItem } from 'primereact/selectitem';
import { Toolbar } from 'primereact/toolbar';

import {
    ReportColumn,
    ReportDataTypes,
    ReportDataViews,
    ReportState,
} from '../../../types/reports';
import { Team } from '../../../types/team';
import { RookieProduct } from '../../../types/licences';

interface Props {
    columns: ReportColumn[];
    countGameThreshold: number;
    countPeriodThreshold: number;
    data: any;
    loading: boolean;
    options: Record<string, SelectItem[]>;
    reportData: ReportState<any>;
    season: string;
    team?: Team;
    totalAvgGameStarts: number;
    totalAvgPeriodStarts: number;
    views: ReportDataViews;
    onExport: (dataTable: DataTable<any>) => void;
    onSeasonChange: (value: string) => void;
    onViewsChange: (key: string, value: any) => void;
}

const TeamBenchStartsView = ({
    columns,
    countGameThreshold,
    countPeriodThreshold,
    data,
    loading,
    options,
    reportData,
    season,
    team,
    totalAvgGameStarts,
    totalAvgPeriodStarts,
    views,
    onExport,
    onSeasonChange,
    onViewsChange,
}: Props) => {
    const dataTable = useRef<DataTable<any>>(null);

    const leftToolbar = (
        <>
            <FormGroup label="Season">
                <SeasonSelect
                    onChange={(e) => onSeasonChange(e.value)}
                    value={season}
                    placeholder="Select Season"
                    teamID={team?.teamID}
                    initialOptions={
                        team?.defaultSeasonDetails && [
                            team.defaultSeasonDetails,
                        ]
                    }
                />
            </FormGroup>
        </>
    );

    const rightToolbar = (
        <div className="p-button-group">
            <SelectButton
                value={views.dataType}
                onChange={(e) => onViewsChange('dataType', e.value)}
                options={options.dataType}
                allowEmpty={false}
            />
        </div>
    );

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column
                    footer="Bench Spread Ratings:"
                    colSpan={4}
                    footerStyle={{ textAlign: 'right' }}
                    style={{ backgroundColor: 'white' }}
                />
                {views.dataType !== ReportDataTypes.Percentage ? (
                    <Column
                        footer={`Select '% PER'`}
                        align="center"
                        style={{ backgroundColor: 'white' }}
                    />
                ) : (
                    <Column
                        footer={`${countGameThreshold} / 100`}
                        align="center"
                        style={{ backgroundColor: 'white' }}
                    />
                )}
                {views.dataType !== ReportDataTypes.Percentage ? (
                    <Column
                        footer={`Select '% PER`}
                        align="center"
                        style={{ backgroundColor: 'white' }}
                    />
                ) : (
                    <Column
                        footer={`${countPeriodThreshold} / 100`}
                        align="center"
                        style={{ backgroundColor: 'white' }}
                    />
                )}
            </Row>
        </ColumnGroup>
    );

    const cols = [
        ...columns,
        {
            id: 'games',
            name: 'Games',
            shortName: 'Games',
            field: 'gamesPlayed',
        },
        {
            id: 'startGamesBench',
            name: 'Start Games on Bench',
            shortName: 'SGB',
            field: 'startGamesBench',
            body: (data: any) =>
                views.dataType === ReportDataTypes.Percentage
                    ? renderBenchRating(
                          data.startGamesBench,
                          totalAvgGameStarts
                      )
                    : data.startGamesBench,
        },
        {
            id: 'startPeriodBench',
            name: 'Start Periods on Bench',
            shortName: 'SPG',
            field: 'startPeriodBench',
            body: (data: any) =>
                views.dataType === ReportDataTypes.Percentage
                    ? renderBenchRating(
                          data.startPeriodBench,
                          totalAvgPeriodStarts
                      )
                    : data.startPeriodBench,
        },
    ];

    return (
        <PageContainer>
            <PageHeader
                title="Bench Starts Report"
                actions={[
                    {
                        onClick: () =>
                            dataTable.current && onExport(dataTable.current),
                        label: 'Export',
                        severity: 'secondary',
                        icon: 'download',
                    },
                ]}
            />
            <Toolbar
                className="report-toolbar"
                start={leftToolbar}
                end={rightToolbar}
            />
            <ReportTable
                ref={dataTable}
                data={data}
                columns={cols}
                pinnedColumns={['playerNumber', 'playerName']}
                isLoading={loading || reportData.isLoading}
                product={RookieProduct.GameDay}
                dataTableProps={{
                    sortField: 'player.uniformNumber',
                    sortOrder: 1,
                    footerColumnGroup: footerGroup,
                    exportFilename: `${team?.teamName} - Bench Starts Report`,
                }}
            />
        </PageContainer>
    );
};

export default TeamBenchStartsView;
