import { MouseEventHandler, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { sortBy, startCase } from 'lodash';

import { generateUrlFromRole, getNiceEntityType } from '../../util/helper';

import PageHeader from '../../layout/PageHeader';
import PageContainer from '../../layout/PageContainer';
import EntityAvatar from '../../components/EntityAvatar';
import ErrorDisplay from '../../components/ErrorDisplay';
import ListItem from '../../components/ListItem';
import CardButton from '../../components/CardButton';
import Icon from '../../components/Icon';
import Loader from '../../components/Loader';
import RookieButton from '../../components/RookieButton';

import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import { Toolbar } from 'primereact/toolbar';
import { DataView } from 'primereact/dataview';
import { SplitButton } from 'primereact/splitbutton';

import AssociationForm from '../associations/AssociationForm';
import OrganisationForm from '../organisations/OrganisationForm';
import TeamForm from '../teams/TeamForm';

import { Me } from '../../types/user';
import { Role } from '../../types/roles';
import {
    BaseEntityType,
    ERROR_TYPES,
    FormSubmitCallback,
} from '../../types/common';

interface Props {
    data: Role[];
    entityTypes: BaseEntityType[];
    filter: BaseEntityType[];
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
    search: string;
    showCreateModal?: BaseEntityType;
    showPagination?: boolean;
    user: Me;
    onCloseCreateModal: () => void;
    onSubmitComplete: FormSubmitCallback;
    onFilterChange: (value: BaseEntityType) => void;
    onJoinClick: () => void;
    onLoadMore?: () => void;
    onSearchChange: (search: string) => void;
    onShowCreateModal: (entityType: BaseEntityType) => void;
}

const GRID_LAYOUT_LIMIT = 12;

const EntityListingView = (props: Props) => {
    const {
        data,
        entityTypes,
        filter,
        isError,
        isFetching,
        isLoading,
        search,
        showCreateModal,
        showPagination,
        user,
        onCloseCreateModal,
        onJoinClick,
        onFilterChange,
        onLoadMore,
        onSearchChange,
        onShowCreateModal,
        onSubmitComplete,
    } = props;

    const entities = useMemo(() => {
        const filtered = data?.filter((ent) => {
            const hasFilter = filter.length > 0;
            const hasSearch = search.length > 0;

            const matchesFilter = !hasFilter || filter.includes(ent.entityType);
            const matchesSearch =
                !hasSearch ||
                ent.entityName.toLowerCase().includes(search.toLowerCase());

            return matchesFilter && matchesSearch;
        });

        return sortBy(filtered, (ent) => ent.entityName);
    }, [data, filter, search]);

    const renderEntityCaption = (entity: Role) => {
        const entityType = getNiceEntityType(entity.entityType);
        const roles = entity.roles
            .map((role) =>
                role.roleName.replace(startCase(entity.entityType), '')
            )
            .join(', ');

        return `${entityType} · ${roles}`;
    };

    const splitButtonOptions = useMemo(() => {
        let options = [
            {
                key: 'team-join',
                label: `Join Team`,
                command: onJoinClick,
            },
        ];

        if (user.admin) {
            options = [
                ...options,
                {
                    key: 'organistion',
                    label: `Create Organisation`,
                    command: () =>
                        onShowCreateModal(BaseEntityType.organisations),
                },
                {
                    key: 'association',
                    label: `Create Association`,
                    command: () =>
                        onShowCreateModal(BaseEntityType.associations),
                },
            ];
        }

        return options;
    }, [user, onShowCreateModal, onJoinClick]);

    const footer = !isFetching && showPagination && (
        <RookieButton
            onClick={onLoadMore}
            severity="secondary"
            label="Load more"
            loading={isFetching}
            disabled={isFetching}
            outlined
        />
    );

    const listItem = (entity: Role) => {
        const entityUrl = generateUrlFromRole(entity);

        return (
            <ListItem
                component={Link}
                to={entityUrl}
                start={
                    <EntityAvatar
                        entityID={entity.entityID}
                        entityName={entity.entityName}
                        entityType={entity.entityType}
                        avatarProps={{
                            size: 'large',
                        }}
                    />
                }
                title={entity.entityName}
                caption={renderEntityCaption(entity)}
                relaxed
                divider
            />
        );
    };

    const gridItem = (entity: Role) => {
        const entityUrl = generateUrlFromRole(entity);

        return (
            <Link
                key={entity.entityID}
                to={entityUrl}
                className="list-grid-item"
            >
                <EntityAvatar
                    entityID={entity.entityID}
                    entityName={entity.entityName}
                    entityType={entity.entityType}
                />
                <p className="list-grid-item-title">{entity.entityName}</p>
                <p className="list-grid-item-caption">
                    {renderEntityCaption(entity)}
                </p>
            </Link>
        );
    };

    const header = (
        <Toolbar
            start={
                <div className="p-button-group">
                    {entityTypes.map((entity) => {
                        const isSelected = filter.includes(entity);

                        return (
                            <RookieButton
                                key={entity}
                                label={entity}
                                severity={isSelected ? undefined : 'secondary'}
                                onClick={() => onFilterChange(entity)}
                                outlined={!isSelected}
                                size="small"
                            />
                        );
                    })}
                </div>
            }
            center={
                user.admin && (
                    <InputText
                        value={search}
                        onChange={(e) => onSearchChange(e.target.value)}
                        placeholder="Search"
                        style={{ width: '140px' }}
                    />
                )
            }
            end={
                <>
                    <SplitButton
                        icon="add"
                        label="Create Team"
                        onClick={() => onShowCreateModal(BaseEntityType.teams)}
                        dropdownIcon={<Icon name="arrow_drop_down" />}
                        model={splitButtonOptions}
                        size="small"
                    />
                </>
            }
        />
    );

    const renderCreateForm = () => {
        switch (showCreateModal) {
            case BaseEntityType.associations:
                return (
                    <AssociationForm
                        onSubmitComplete={(
                            status,
                            action,
                            responseData,
                            errorData
                        ) =>
                            onSubmitComplete(
                                status,
                                action,
                                responseData,
                                errorData,
                                BaseEntityType.associations
                            )
                        }
                        onCancel={onCloseCreateModal}
                    />
                );
            case BaseEntityType.organisations:
                return (
                    <OrganisationForm
                        onSubmitComplete={(
                            status,
                            action,
                            responseData,
                            errorData
                        ) =>
                            onSubmitComplete(
                                status,
                                action,
                                responseData,
                                errorData,
                                BaseEntityType.organisations
                            )
                        }
                        onCancel={onCloseCreateModal}
                    />
                );
            case BaseEntityType.teams:
                return (
                    <TeamForm
                        userID={user.userID}
                        onCancel={onCloseCreateModal}
                        onSubmitComplete={(
                            status,
                            action,
                            responseData,
                            errorData
                        ) =>
                            onSubmitComplete(
                                status,
                                action,
                                responseData,
                                errorData,
                                BaseEntityType.teams
                            )
                        }
                    />
                );
            default:
                return null;
        }
    };

    const renderCardButton = (
        label: string,
        iconName: string,
        onClick: MouseEventHandler
    ) => (
        <CardButton
            align="center"
            onClick={onClick}
            label={label}
            icon={
                <Icon
                    name={iconName}
                    style={{
                        fontSize: '64px',
                        color: '#ff6700',
                    }}
                />
            }
        />
    );

    if (isLoading) {
        return <Loader size="fullscreen" />;
    }

    if (isError) {
        return (
            <ErrorDisplay
                actions={[
                    {
                        onClick: () => window.location.reload(),
                        icon: 'refresh',
                        label: 'Retry',
                    },
                ]}
                desc={`We encountered an error while retrieving your entities. Please try again.`}
                errorType={ERROR_TYPES.notFound}
                hasReturn={false}
                title="No Roles Available"
            />
        );
    }

    return (
        <>
            <PageContainer
                size={data.length > 0 && data.length <= 12 ? 'large' : 'narrow'}
            >
                <PageHeader
                    title={`Welcome, ${user.firstName}`}
                    align="center"
                    showBreadcrumbs={false}
                />
                {data.length > GRID_LAYOUT_LIMIT ? (
                    <div className="entities-widget">
                        <DataView
                            value={isLoading ? Array(5).fill(0) : entities}
                            itemTemplate={listItem}
                            header={header}
                            footer={footer}
                            loading={isLoading}
                            emptyMessage={`No available ${
                                filter.length > 0
                                    ? filter.join(' and ')
                                    : 'entities'
                            }`}
                        />
                    </div>
                ) : data.length === 0 ? (
                    <div className="card-button-group entity-button-group">
                        {renderCardButton('Create Team', 'add', () =>
                            onShowCreateModal(BaseEntityType.teams)
                        )}
                        {renderCardButton(
                            'Join Team',
                            'group_add',
                            onJoinClick
                        )}
                        {user.admin &&
                            renderCardButton(
                                'Create Organisation',
                                'store',
                                () =>
                                    onShowCreateModal(
                                        BaseEntityType.organisations
                                    )
                            )}
                        {user.admin &&
                            renderCardButton(
                                'Create Association',
                                'domain',
                                () =>
                                    onShowCreateModal(
                                        BaseEntityType.associations
                                    )
                            )}
                    </div>
                ) : (
                    <>
                        <div className="entity-selector">
                            <div className="entity-selector-inner">
                                {entities.map(gridItem)}
                            </div>
                        </div>
                        <div className="p-button-group p-button-group-center">
                            <RookieButton
                                label="Join Team"
                                severity="secondary"
                                onClick={onJoinClick}
                                outlined
                            />
                            {user.admin ? (
                                <SplitButton
                                    icon="add"
                                    label="Create Team"
                                    onClick={() =>
                                        onShowCreateModal(BaseEntityType.teams)
                                    }
                                    dropdownIcon={
                                        <Icon name="arrow_drop_down" />
                                    }
                                    model={splitButtonOptions}
                                    severity="secondary"
                                    outlined
                                />
                            ) : (
                                <RookieButton
                                    label="Create Team"
                                    severity="secondary"
                                    onClick={() =>
                                        onShowCreateModal(BaseEntityType.teams)
                                    }
                                    outlined
                                />
                            )}
                        </div>
                    </>
                )}
            </PageContainer>
            <Sidebar
                header={`Create ${showCreateModal || ''}`}
                onHide={onCloseCreateModal}
                visible={!!showCreateModal}
                position="right"
                style={{ width: '500px' }}
            >
                {renderCreateForm()}
            </Sidebar>
        </>
    );
};

export default EntityListingView;
