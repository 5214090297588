import {
    Fragment,
    forwardRef,
    useContext,
    useImperativeHandle,
    useRef,
} from 'react';
import {
    Link,
    generatePath,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';

import { LayoutContext } from '../contexts/LayoutContext';

import EntitySwitcher from '../components/EntitySwitcher';
import Icon from '../components/Icon';
import ListItem from '../components/ListItem';
import Notifications from '../components/Notifications';
import RookieButton from '../components/RookieButton';

import { Image } from 'primereact/image';
import { Avatar } from 'primereact/avatar';
import { Menubar } from 'primereact/menubar';
import { OverlayPanel } from 'primereact/overlaypanel';

import { UserMenuItems } from '../routes/routes';

import { ThemeMode, TopBarRef } from '../types/layout';

//import ProductGrid from '../components/ProductGrid';

import logoBrandmark from '../assets/images/logos/rm-hub-logomark.svg';
import logoWordmark from '../assets/images/logos/rm-hub-wordmark.svg';
import { Me } from '../types/user';
import { InputSwitch } from 'primereact/inputswitch';
import List from '../components/List';
import ListDivider from '../components/ListDivider';

interface Props {
    user?: Me;
}

const AppTopbar = forwardRef<TopBarRef, Props>((props, ref) => {
    const { user } = props;
    const { onMenuToggle, layoutConfig, setLayoutConfig } =
        useContext(LayoutContext);

    // Route Hooks
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    // Ref Hooks
    const menuRef = useRef<OverlayPanel>(null);
    const menubuttonRef = useRef(null);
    const notificationsRef = useRef<OverlayPanel>(null);
    //const productsRef = useRef<OverlayPanel>(null);

    useImperativeHandle(ref, () => ({
        menubutton: menubuttonRef.current,
    }));

    /**
     * @desc renders profile menu items; user chip, profile switcher and profile menu items
     * @returns Object
     */
    const renderMenu = () => {
        //const textScales = Object.values(ThemeScale);
        //const currentScaleIndex = textScales.indexOf(layoutConfig.scale);

        return (
            <List>
                {user && (
                    <ListItem
                        disableGutters
                        start={
                            <Avatar
                                image={user.picture}
                                label={
                                    user.firstName ? user.firstName : user.email
                                }
                            />
                        }
                        title={
                            user.firstName && user.lastName
                                ? user.firstName + ' ' + user.lastName
                                : user.email
                        }
                        caption={
                            user.firstName && user.lastName ? user.email : ''
                        }
                        compact
                    />
                )}

                <ListItem
                    title="Dark Mode"
                    compact={true}
                    disableGutters
                    component="button"
                    onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setLayoutConfig((prev) => ({
                            ...prev,
                            theme:
                                prev.theme === ThemeMode.Light
                                    ? ThemeMode.Dark
                                    : ThemeMode.Light,
                        }));
                    }}
                    end={
                        <InputSwitch
                            checked={layoutConfig.theme === ThemeMode.Dark}
                            style={{ verticalAlign: 'top' }}
                        />
                    }
                />
                {/*
                <ListItem
                    title="Text Scale"
                    compact={true}
                    disableGutters
                    end={
                        <div className="p-button-grou">
                            <RookieButton
                                disabled={!textScales[currentScaleIndex - 1]}
                                icon="text_decrease"
                                text
                                size="small"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setLayoutConfig((prev) => ({
                                        ...prev,
                                        scale: textScales[
                                            currentScaleIndex - 1
                                        ],
                                    }));
                                }}
                            />
                            <RookieButton
                                disabled={!textScales[currentScaleIndex + 1]}
                                icon="text_increase"
                                text
                                size="small"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    setLayoutConfig((prev) => ({
                                        ...prev,
                                        scale: textScales[
                                            currentScaleIndex + 1
                                        ],
                                    }));
                                }}
                            />
                        </div>
                    }
                />
                */}

                <ListDivider />

                {user &&
                    UserMenuItems.map((item, index) => {
                        return (
                            <Fragment key={`user-menu-item-${item.id}`}>
                                {item.id === 'logout' && <ListDivider />}

                                <ListItem
                                    component="button"
                                    title={item.title}
                                    disableGutters
                                    start={
                                        item.icon && <Icon name={item.icon} />
                                    }
                                    compact={true}
                                    className={
                                        item.path === location.pathname
                                            ? 'p-menuitem-active'
                                            : ''
                                    }
                                    onClick={() => {
                                        if (menuRef.current) {
                                            menuRef.current.hide();
                                        }

                                        navigate(
                                            generatePath(item.path, {
                                                userID: user.userID,
                                                ...params,
                                            })
                                        );
                                    }}
                                />
                            </Fragment>
                        );
                    })}
            </List>
        );
    };

    /**
     * @desc sets objects to be displayed in right side area of TopBar
     * @returns JSX
     */

    const menuEnd = (
        <div className="topbar-menu">
            {/*
            <RookieButton
                plain={true}
                text={true}
                icon="apps"
                onClick={(e) =>
                    productsRef.current && productsRef.current.toggle(e)
                }
                aria-controls="products-menu"
                aria-haspopup
            />
            */}
            {user && (
                <>
                    <RookieButton
                        text={true}
                        plain={true}
                        icon="notifications"
                        onClick={(e) => notificationsRef?.current?.toggle(e)}
                        aria-controls="notifications-menu"
                        aria-haspopup
                    />
                    <RookieButton
                        text={true}
                        plain={true}
                        onClick={(e) =>
                            menuRef.current && menuRef.current.toggle(e)
                        }
                        aria-controls="user-menu"
                        aria-haspopup
                    >
                        <Avatar
                            label={
                                user?.firstName
                                    ? user?.firstName?.substring(0, 1)
                                    : user?.email.substring(0, 1)
                            }
                            shape="circle"
                            image={user?.picture}
                        />
                    </RookieButton>
                </>
            )}
        </div>
    );

    const menuStart = (
        <>
            {!params.teamID &&
            !params.organisationID &&
            !params.associationID ? (
                <Link className="app-logo" to="/">
                    <Image
                        className="app-logo-img app-logo-brandmark"
                        src={logoBrandmark}
                        height="40px"
                        alt="Rookie Me Hub"
                    />
                    <Image
                        className="app-logo-img app-logo-wordmark"
                        src={logoWordmark}
                        height="40px"
                        alt="Rookie Me Hub"
                    />
                </Link>
            ) : (
                <div className="entity-menu">
                    <RookieButton
                        plain
                        text
                        icon="menu"
                        onClick={onMenuToggle}
                        ref={menubuttonRef}
                    />
                    <EntitySwitcher />
                </div>
            )}
        </>
    );

    return (
        <>
            <div className="layout-topbar">
                <Menubar start={menuStart} end={menuEnd} model={[]} />
            </div>
            <OverlayPanel id="user-menu" ref={menuRef}>
                {renderMenu()}
            </OverlayPanel>
            <OverlayPanel id="notifications-menu" ref={notificationsRef}>
                <Notifications />
            </OverlayPanel>
            {/*
            <OverlayPanel id="apps-menu" ref={productsRef}>
                <ProductGrid />
            </OverlayPanel>
            */}
        </>
    );
});

export default AppTopbar;
