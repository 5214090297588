import { useContext } from 'react';
import { Formik } from 'formik';
import { pick } from 'lodash';

import {
    useCreateMessageSettingsMutation,
    useUpdateMessageSettingsMutation,
} from '../../api/messages';

import { ToastContext } from '../../contexts/ToastContext';

import { InputSwitch } from 'primereact/inputswitch';
import { ProgressBar } from 'primereact/progressbar';
import { ToastMessage } from 'primereact/toast';

import FormGroup from '../../components/FormGroup';
import FormActions from '../../components/FormActions';
import ListItem from '../../components/ListItem';
import List from '../../components/List';
import RookieButton from '../../components/RookieButton';

import { Mixpanel } from '../../util/mixpanel';

import {
    ChannelsEnabledOptions,
    MessageSettings,
    MessageSettingsFormData,
} from '../../types/messages';
import { BaseEntityType } from '../../types/common';

interface Props {
    messageSettings?: MessageSettings;
    entityType: BaseEntityType;
    entityID: string;
    onSuccess?: () => void;
    onError?: () => void;
}

const availableFields: Array<keyof MessageSettingsFormData> = [
    'enabled',
    'moderators',
    'channelsEnabled',
];

const MessageSettingsForm = (props: Props) => {
    const { messageSettings, entityID, entityType } = props;

    const isCreate = !messageSettings;

    const useUpsertMessageSettings = isCreate
        ? useCreateMessageSettingsMutation
        : useUpdateMessageSettingsMutation;

    const [upsertMessageSettings] = useUpsertMessageSettings();

    const toast = useContext(ToastContext);

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const handleSubmit = (data: MessageSettingsFormData) => {
        if (entityID) {
            upsertMessageSettings({
                entityID,
                entityType,
                ...data,
            })
                .then((response) => {
                    if ('error' in response) {
                        if (props.onError) {
                            props.onError();
                        }
                        // @ts-ignore
                        throw new Error(response.error.data.message);
                    }

                    showToast({
                        severity: 'success',
                        summary: 'Success',
                        detail: `Updated Settings, please allow up to 30 seconds for changes to reflect.`,
                    });

                    if (props.onSuccess) {
                        props.onSuccess();
                    }

                    Mixpanel.track(
                        `${isCreate ? 'Create' : 'Update'} Message Settings`
                    );
                })
                .catch((err) => {
                    showToast({
                        severity: 'error',
                        summary: 'Error',
                        detail: `Could not update settings.`,
                    });

                    if (props.onError) {
                        props.onError();
                    }
                });
        }
    };

    const defaultProps: MessageSettingsFormData = {
        enabled: true,
        moderators: [],
        channelsEnabled: {
            groups: true,
            players: false,
            guardians: false,
            staff: true,
            everybody: true,
        },
    };

    const initialValues: MessageSettingsFormData = {
        ...pick(defaultProps, availableFields),
        ...(pick(messageSettings, availableFields) as MessageSettingsFormData),
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >
            {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
                return (
                    <form className="chat-settings" onSubmit={handleSubmit}>
                        {!isCreate && (
                            <List>
                                <ListItem
                                    title="Enable Messaging"
                                    disablePadding
                                    onClick={() =>
                                        setFieldValue(
                                            'enabled',
                                            !values.enabled
                                        )
                                    }
                                    end={
                                        <InputSwitch
                                            id="enabled"
                                            name="enabled"
                                            checked={!!values.enabled}
                                            required
                                        />
                                    }
                                />
                            </List>
                        )}

                        {values.enabled && (
                            <FormGroup
                                label={'Enabled Channels'}
                                htmlFor={'channelsEnabled'}
                                key={'channelsEnabled'}
                            >
                                <List>
                                    {Object.entries(ChannelsEnabledOptions).map(
                                        ([label, key]) => {
                                            const id = `channelsEnabled.${key}`;

                                            const isSelected: boolean = !!(
                                                values.channelsEnabled &&
                                                values.channelsEnabled[key]
                                            );

                                            return (
                                                <ListItem
                                                    disablePadding
                                                    key={id + 'Input'}
                                                    title={label}
                                                    onClick={() =>
                                                        setFieldValue(
                                                            'channelsEnabled',
                                                            {
                                                                ...values.channelsEnabled,
                                                                [key]: !isSelected,
                                                            }
                                                        )
                                                    }
                                                    end={
                                                        <InputSwitch
                                                            id={id}
                                                            name={label}
                                                            checked={isSelected}
                                                            required
                                                        />
                                                    }
                                                />
                                            );
                                        }
                                    )}
                                </List>
                            </FormGroup>
                        )}

                        {/* @todo - chuck in a multiselect user selection here for `moderators` */}
                        <FormActions
                            start={
                                <RookieButton
                                    disabled={isSubmitting}
                                    type="submit"
                                    label={
                                        isCreate ? 'Start Messaging' : 'Save'
                                    }
                                />
                            }
                        />
                        {isSubmitting && (
                            <div className="form-overlay">
                                <ProgressBar mode="indeterminate" />
                            </div>
                        )}
                    </form>
                );
            }}
        </Formik>
    );
};

export default MessageSettingsForm;
