import { useMemo, useRef } from 'react';
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { sortBy } from 'lodash';

import TeamForm from './TeamForm';

import ErrorDisplay from '../../components/ErrorDisplay';
import Icon from '../../components/Icon';
import RookieButton from '../../components/RookieButton';
import PageHeader from '../../layout/PageHeader';
import PageContainer from '../../layout/PageContainer';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Sidebar } from 'primereact/sidebar';
import { Skeleton } from 'primereact/skeleton';
import { SplitButton } from 'primereact/splitbutton';
import { Toast } from 'primereact/toast';

import { ERROR_TYPES } from '../../types/common';

import { Mixpanel } from '../../util/mixpanel';
import { TabMenu } from 'primereact/tabmenu';
import ListItem from '../../components/ListItem';
import EntityPreview from '../../components/EntityPreview';
import TeamsImport from './TeamsImport';

const TeamListingView = (props) => {
    const {
        isError,
        isFetching,
        isLoading,
        isArchiving,
        onArchiveSelected,
        onGenerateCode,
        permissions,
    } = props;

    const navigate = useNavigate();
    const { organisationID } = useParams();
    const location = useLocation();

    const dataTable = useRef(null);
    const teamCodeToast = useRef(null);
    const formResponseToast = useRef();

    const [searchParams, setSearchParams] = useSearchParams('');
    const activeTab = searchParams.get('tab');

    const openCreationPortal = () => {
        if (!searchParams.get('form')) {
            setSearchParams((params) => {
                params.set('form', 'create');
                return params;
            });
            Mixpanel.track(`Open Team Create`);
        }
    };

    const closeCreationPortal = () => {
        if (searchParams.get('form')) {
            setSearchParams((params) => {
                params.delete('form');
                return params;
            });
        }
    };

    const handleCreateTeamSuccess = (response) => {
        formResponseToast.current?.show({
            severity: 'success',
            summary: 'Success!',
            detail: `Your team has been successfully created.`,
        });

        closeCreationPortal();
    };

    const handleCreateTeamError = (response) => {
        formResponseToast.current?.show({
            severity: 'error',
            summary: 'Error',
            detail: `There was an error creating your team. Please try again.`,
        });
    };

    const handleCodeClick = (code) => {
        // Copy the code
        navigator.clipboard.writeText(code);

        // Show toast
        teamCodeToast.current.show({
            summary: 'Team code copied',
            detail: code,
            severity: 'info',
        });
    };

    const exportCSV = () => {
        dataTable.current && dataTable.current.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: 'Teams',
        });
    };

    const columns = useMemo(() => {
        const joinCodeTemplate = (data, row) => {
            return data.teamJoinMode !== 'Disabled' ? (
                <SplitButton
                    model={[
                        {
                            command: () => onGenerateCode('Disabled', data),
                            label: 'Disable code',
                        },
                    ]}
                    icon={<Icon name="content_copy" />}
                    label={data.teamJoinCode}
                    onClick={() => handleCodeClick(data.teamJoinCode)}
                    className="p-button-rounded p-button-secondary"
                />
            ) : (
                <RookieButton
                    label="Generate Code"
                    severity="secondary"
                    rounded
                    onClick={() => onGenerateCode('Enabled', data)}
                />
            );
        };

        const cols = [
            {
                body: isLoading ? (
                    <Skeleton />
                ) : (
                    (row) => (
                        <ListItem
                            title={row.teamName}
                            start={
                                <EntityPreview
                                    design={row.design}
                                    style={{
                                        width: '32px',
                                        height: '32px',
                                        borderRadius: '4px',
                                    }}
                                />
                            }
                        />
                    )
                ),
                field: 'teamName',
                header: 'Name',
                sortable: true,
            },
            {
                body: isLoading ? <Skeleton /> : null,
                field: 'age',
                header: 'Age',
                sortable: true,
            },
            {
                body: isLoading ? <Skeleton /> : null,
                field: 'gender',
                header: 'Gender',
                sortable: true,
            },
            {
                body: isLoading ? <Skeleton /> : null,
                field: 'grade',
                header: 'Grade',
                sortable: true,
            },
            {
                body: isLoading ? (
                    <Skeleton />
                ) : (
                    (data) => format(parseISO(data.lastEdited), 'dd/MM/yyyy')
                ),
                field: 'lastEdited',
                header: 'Last Edited',
                sortable: true,
            },
            {
                body: isLoading ? <Skeleton /> : joinCodeTemplate,
                field: 'teamJoinCode',
                header: 'Join Code',
                sortable: false,
                hidden: activeTab === 'Archived',
            },
        ];

        if (permissions.canEdit) {
            cols.unshift({
                selectionMode: 'multiple',
                headerStyle: { width: '3rem' },
                hidden: activeTab === 'Archived',
            });
        }
        return cols;
    }, [isLoading, onGenerateCode, activeTab, permissions]);

    const mappedColumns = columns.map((col, i) => (
        <Column key={`col-${i}`} {...col} />
    ));

    const headerActions = [
        {
            key: 'export',
            onClick: () => exportCSV(),
            label: 'Export CSV',
            severity: 'secondary',
        },
    ];

    if (permissions.canCreate) {
        headerActions.push({
            key: 'import',
            component: (
                <SplitButton
                    label="Import Teams"
                    onClick={() => props.onSetShowImportModal(true)}
                    menuStyle={{ whiteSpace: 'nowrap' }}
                    model={[
                        {
                            label: 'Download Template',
                            icon: 'pi pi-download',
                            command: () => {
                                const templateUrl =
                                    'https://cdn.home.rookieme.com/assets/hub/team_import_template.xlsx';

                                window.open(templateUrl, '_blank');
                            },
                        },
                    ]}
                    dropdownIcon="pi pi-chevron-down"
                    className="p-button-secondary"
                />
            ),
        });
        headerActions.push({
            key: 'create',
            onClick: () => openCreationPortal(),
            icon: 'add',
            label: 'Create New',
        });
    }

    const tableEmptyMessage = (
        <ErrorDisplay
            actions={
                isError && [
                    {
                        onClick: () => navigate(0), // refresh
                        icon: 'refresh',
                        label: 'Retry',
                    },
                ]
            }
            alignment="middle"
            desc={
                isError
                    ? 'Refresh to try the request again'
                    : 'Create your first team to get started.'
            }
            errorType={ERROR_TYPES.notFound}
            hasReturn={false}
            proportion="compact"
            title={isError ? 'No data returned' : `No Teams found`}
        />
    );

    // empty array to populate rows for skeleton loading components
    const blankRows = Array(5);

    const footerTemplate = () => {
        return (
            <RookieButton
                onClick={props.onLoadMore}
                severity="secondary"
                label="Load more"
                icon="pending"
            />
        );
    };

    const tabs = [
        {
            id: 'Active',
            label: 'Active',
        },
        {
            id: 'Archived',
            label: 'Archived',
        },
    ];

    const activeTabIndex = tabs.findIndex((tab) => activeTab === tab.id);

    const teamData = useMemo(() => {
        return sortBy(props.data, 'teamName');
    }, [props.data]);

    return (
        <PageContainer>
            <PageHeader title="Teams" actions={headerActions} />
            <TabMenu
                model={tabs}
                activeIndex={activeTabIndex}
                onTabChange={(e) => {
                    if (props.onTabChange) {
                        props.onTabChange();
                    }

                    setSearchParams({ tab: tabs[e.index].id });
                }}
            />

            {props.isLoading ? (
                <div>Loading...</div>
            ) : (
                <DataTable
                    ref={dataTable}
                    value={isLoading ? blankRows : teamData}
                    loading={(isArchiving || isFetching) && !isLoading}
                    footer={
                        <>
                            {!isFetching &&
                                props.showPagination &&
                                footerTemplate}
                        </>
                    }
                    header={
                        permissions.canEdit &&
                        activeTab !== 'Archived' && (
                            <RookieButton
                                label="Archive"
                                onClick={onArchiveSelected}
                                severity="secondary"
                                disabled={
                                    activeTab === 'Archived' ||
                                    props.selected.length <= 0
                                }
                            />
                        )
                    }
                    emptyMessage={tableEmptyMessage}
                    columnResizeMode="expand"
                    resizableColumns
                    exportFilename="teams-export"
                    onRowClick={(e) => {
                        if (organisationID) {
                            navigate(
                                `${location.pathname.replace('teams', 't')}/${
                                    e.data.teamID
                                }/events?tab=${
                                    activeTab === 'Archived'
                                        ? 'archived'
                                        : 'past'
                                }`
                            );
                        }
                    }}
                    selectAll
                    selectionMode="checkbox"
                    selection={props.selected}
                    onSelectionChange={(e) => props.onSetSelected(e.value)}
                >
                    {isLoading || props.data.length > 0 ? mappedColumns : null}
                </DataTable>
            )}
            <Sidebar
                header="Create Team"
                onHide={() => closeCreationPortal()}
                visible={searchParams.has('form')}
                position="right"
                style={{ width: '500px' }}
            >
                <TeamForm
                    organisationID={organisationID}
                    onSuccess={handleCreateTeamSuccess}
                    onError={handleCreateTeamError}
                    onCancel={() => closeCreationPortal()}
                    secondaryAction={
                        <RookieButton
                            severity="secondary"
                            label="Cancel"
                            onClick={() => closeCreationPortal()}
                        />
                    }
                />
            </Sidebar>

            <TeamsImport
                show={props.showImportModal}
                onHide={() => props.onSetShowImportModal(false)}
                onComplete={() => props.onSetShowImportModal(false)}
            />

            <Toast ref={formResponseToast} />
            <Toast ref={teamCodeToast} />
        </PageContainer>
    );
};

export default TeamListingView;
