import { useNavigate, useParams } from 'react-router-dom';

import { useGetAssociationOrganisationsQuery } from '../../api/associations';

import DocumentHead from '../../components/DocumentHead';
import OrganisationListingView from './OrganisationListingView';
import { useContext, useState } from 'react';
import { ToastContext } from '../../contexts/ToastContext';
import { FormSubmitCallback } from '../../types/common';

const OrganisationListingContainer = () => {
    const navigate = useNavigate();
    const { organisationID, associationID } = useParams();

    const toast = useContext(ToastContext);

    const [cursor, setCursor] = useState('');
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);

    const { data, isLoading, isFetching, isError } =
        useGetAssociationOrganisationsQuery({
            organisationID,
            associationID,
            cursor,
        });

    const handleImportClick = () => {
        const route = organisationID
            ? `/o/${organisationID}/organisations/import`
            : '/organisations/import';

        navigate(route);
    };

    const handleLoadMore = () => {
        if (data?.lastEvaluatedKey.cursor) {
            setCursor(data.lastEvaluatedKey.cursor);
        }
    };

    const handleSubmitComplete: FormSubmitCallback = (
        status,
        action,
        response,
        error
    ) => {
        if (status === 'success' && response) {
            toast?.current?.show({
                severity: 'success',
                summary: 'Success',
                detail:
                    action === 'create'
                        ? `The organisation has been created successfully.`
                        : `The organisation has been updated successfully.`,
            });
        } else if (status === 'error' && error) {
            toast?.current?.show({
                severity: 'warn',
                summary: 'Error',
                detail: error.message,
            });
        } else {
            toast?.current?.show({
                severity: 'warn',
                summary: 'Error',
                detail: 'Something went wrong. Please try again.',
            });
        }
    };

    return (
        <>
            <DocumentHead
                title="Organisation Listing - Rookie Me Hub"
                description="Organisation listing page"
            />
            <OrganisationListingView
                data={data ? data.data : []}
                isError={isError}
                isLoading={isLoading}
                isFetching={isFetching}
                showAddModal={showAddModal}
                showCreateModal={showCreateModal}
                showPagination={!!data?.lastEvaluatedKey?.cursor}
                onImportClick={handleImportClick}
                onCloseAddModal={() => setShowAddModal(false)}
                onCloseCreateModal={() => setShowCreateModal(false)}
                onOpenAddModal={() => setShowAddModal(true)}
                onOpenCreateModal={() => setShowCreateModal(true)}
                onCreateSubmitComplete={handleSubmitComplete}
                onLoadMore={handleLoadMore}
            />
        </>
    );
};

export default OrganisationListingContainer;
