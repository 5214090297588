import { useContext, useEffect, useMemo, useState } from 'react';
import { sortBy } from 'lodash';

import {
    useGetLicenceTypesQuery,
    useUpdateLicenceGroupMutation,
} from '../../api/licences';
import { useGetEntityQuery } from '../../api/core';

import { productDetails } from '../../util/constants';
import { formatPrice } from '../../util/helper';

import ProductItem from '../plans/ProductItem';

import { SelectButton } from 'primereact/selectbutton';
import { InputNumber } from 'primereact/inputnumber';
import { Image } from 'primereact/image';
import FormGroup from '../../components/FormGroup';
import RookieButton from '../../components/RookieButton';
import ListItem from '../../components/ListItem';

import { LicenceGroupInfo, RookieProduct } from '../../types/licences';
import { PlanEssentials, StripeInterval } from '../../types/subscriptions';
import Loader from '../../components/Loader';
import { ToastContext } from '../../contexts/ToastContext';
import { ToastMessage } from 'primereact/toast';

interface Props {
    licenceGroup: LicenceGroupInfo;
    onSuccess?: (response: any) => void;
    onError?: (response: any) => void;
    onCancel?: () => void;
}

const UpdateLicenceForm = (props: Props) => {
    const { licenceGroup, onSuccess, onError, onCancel } = props;

    const toast = useContext(ToastContext);

    const [isSubmitting, setSubmitting] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState<PlanEssentials>();
    const [qty, setQty] = useState<number>(licenceGroup.qtyTotal || 1);
    const [billingInterval, setBillingInterval] = useState<StripeInterval>(
        licenceGroup.billingPeriod || 'year'
    );

    const { data: entityData, isLoading: loadingEntity } = useGetEntityQuery(
        {
            entityType: licenceGroup.entityType,
            entityID: licenceGroup.entityID,
        },
        { skip: !licenceGroup }
    );

    const { data: licenceTypesRaw, isLoading: loadingLicenceTypes } =
        useGetLicenceTypesQuery(
            {
                rookieProduct: licenceGroup.rookieProduct,
                sportID: entityData?.data?.entitySportID ?? '',
            },
            { skip: !entityData?.data }
        );

    const [updateLicenceGroup] = useUpdateLicenceGroupMutation();

    // Flatten all prices within a licenceType and make details more consumable
    const plans = useMemo(() => {
        let arr: PlanEssentials[] = [];

        licenceTypesRaw?.data.forEach(
            ({
                product,
                licenceTypeID,
                rookieProduct,
                licenceName,
                sportID,
                sortOrder,
            }) => {
                product?.prices.forEach((price) => {
                    arr.push({
                        id: price.priceID,
                        title: `${licenceName} ${
                            price.inclusions && price.inclusions === 'reports'
                                ? ' + Reports'
                                : ''
                        }`,
                        price: (price.unitAmount || 0) / 100,
                        currency: price.currency,
                        interval: price.recurring?.interval,
                        trial: price.recurring?.trial_period_days,
                        description: product.productDescription || '',
                        licenceTypeID,
                        rookieProduct,
                        sportID,
                        sortOrder,
                        productFeatures: product.productFeatures,
                        tier: price.tier,
                        inclusions: price.inclusions,
                    });
                });
            }
        );

        return sortBy(arr, ['sortOrder', 'rookieProduct']);
    }, [licenceTypesRaw]);

    useEffect(() => {
        const currentPlan = plans.find(
            (plan) => plan.id === licenceGroup.priceID
        );

        if (currentPlan) {
            setSelectedPlan(currentPlan);
        }
    }, [licenceGroup, plans]);

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const handleUpdatePlan = async () => {
        if (!selectedPlan) {
            return;
        }

        setSubmitting(true);

        try {
            const response = await updateLicenceGroup({
                entityType: licenceGroup.entityType,
                entityID: licenceGroup.entityID,
                quantity: qty,
                licenceGroupID: licenceGroup.id,
                priceID: selectedPlan.id,
            }).unwrap();

            showToast({
                severity: 'success',
                summary: 'Success',
                detail: `Successfully updated plan(s).`,
            });

            if (onSuccess) onSuccess(response);
        } catch (error) {
            showToast({
                severity: 'error',
                summary: 'Error',
                detail: `Failed to update plan(s).`,
            });

            if (onError) onError(error);
        } finally {
            setSubmitting(false);
        }
    };

    const handleQtyChange = (qty: number) => {
        if (qty > 0 && qty < 100) {
            setQty(qty);
        }
    };

    const handleReportToggle = (
        product: RookieProduct,
        price: PlanEssentials
    ) => {
        setSelectedPlan((prev) => {
            if (price === prev) {
                const matchedPlan =
                    plans.find(
                        (plan) => plan.tier === price.tier && !plan.inclusions
                    ) || plans[0];

                return matchedPlan;
            } else {
                return price;
            }
        });
    };

    const report = plans.find(
        (price) =>
            price.inclusions &&
            price.inclusions === 'reports' &&
            price.tier === selectedPlan?.tier &&
            price.interval === billingInterval
    );

    const activePrice = (plan: PlanEssentials): boolean => {
        return selectedPlan?.id === plan.id;
    };

    const prices = plans.filter((price) => {
        return (
            !(price.inclusions && price.inclusions === 'reports') &&
            billingInterval === price.interval
        );
    });

    const product = licenceGroup && productDetails[licenceGroup.rookieProduct];

    return (
        <div className="sidebar">
            <div className="sidebar-header">
                <Image src={product.logo} height="40" />
                <RookieButton icon="close" text onClick={onCancel} />
            </div>

            {loadingEntity || loadingLicenceTypes ? (
                <Loader />
            ) : (
                <>
                    <div className="sidebar-body">
                        <div className="plan-sidebar-toolbar">
                            <FormGroup
                                label="Qty"
                                className="plan-sidebar-field-qty"
                            >
                                <InputNumber
                                    name="qty"
                                    min={1}
                                    max={100}
                                    value={qty}
                                    onChange={(e) =>
                                        handleQtyChange(Number(e.value))
                                    }
                                />
                            </FormGroup>
                            <FormGroup
                                label="Billing"
                                className="plan-sidebar-field-billing"
                            >
                                <SelectButton
                                    allowEmpty={false}
                                    value={billingInterval}
                                    onChange={(e) =>
                                        setBillingInterval(e.value)
                                    }
                                    options={[
                                        { label: 'Monthly', value: 'month' },
                                        { label: 'Yearly', value: 'year' },
                                    ]}
                                />
                            </FormGroup>
                        </div>
                        <ProductItem
                            prices={prices}
                            product={licenceGroup.rookieProduct}
                            onPriceChange={(rookieProduct, price) =>
                                setSelectedPlan(price)
                            }
                            selectedPrice={selectedPlan}
                            billingInterval={billingInterval}
                            onReportToggle={handleReportToggle}
                            report={report}
                            activePrice={activePrice}
                            showFeatures={false}
                            licenceTypes={licenceTypesRaw?.data}
                        />
                    </div>
                    <div className="sidebar-footer">
                        {selectedPlan && (
                            <div className="plan-sidebar-summary">
                                <ListItem
                                    title={`${product.title} ${selectedPlan.title}`}
                                    caption={`${formatPrice(
                                        selectedPlan.price
                                    )} x ${qty}`}
                                    end={`${formatPrice(
                                        qty * selectedPlan.price
                                    )}`}
                                    disablePadding
                                />
                            </div>
                        )}
                        <div className="plan-sidebar-action">
                            <RookieButton
                                label="Update Plan"
                                onClick={handleUpdatePlan}
                                loading={isSubmitting}
                                disabled={
                                    isSubmitting ||
                                    (selectedPlan?.id ===
                                        licenceGroup.priceID &&
                                        qty === licenceGroup.qtyTotal)
                                }
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default UpdateLicenceForm;
