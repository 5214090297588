import { groupBy } from 'lodash';
import { GameSummary, StatTypes } from '../../types/reports';
import { formatPercentage, formatTime } from '../../util/helper';

interface Data {
    [key: string]: any;
}

const ratingConfig = [
    {
        level: 1,
        color: '#CD2335',
        icon: 'mood_bad',
        label: 'VeryPoor',
    },
    {
        level: 2,
        color: '#AF553A',
        icon: 'sentiment_dissatisfied',
        label: 'Poor',
    },
    {
        level: 3,
        color: '#9D743C',
        icon: 'sentiment_neutral',
        label: 'Below Average',
    },
    {
        level: 4,
        color: '#F08036',
        icon: 'sentiment_satisfied',
        label: 'Average',
    },
    {
        level: 5,
        color: '#EDB024',
        icon: 'mood',
        label: 'Good',
    },
    {
        level: 6,
        color: '#6FBF42',
        icon: 'add_reaction',
        label: 'Excellent',
    },
];

export const MAX_INT_SCORE = 5;
export const MAX_GT_SCORE = 4;

export const getBenchRating = (value: number, totalAvg: number) => {
    if (value < totalAvg - 22.5 || value > totalAvg + 22.5) {
        return ratingConfig[0];
    } else if (value < totalAvg - 17.5 || value > totalAvg + 17.5) {
        return ratingConfig[1];
    } else if (value < totalAvg - 12.5 || value > totalAvg + 12.5) {
        return ratingConfig[3];
    } else if (value < totalAvg - 7.5 || value > totalAvg + 7.5) {
        return ratingConfig[4];
    } else {
        return ratingConfig[5];
    }
};

export const getRatingFromPercentage = (percentage: number) => {
    if (percentage < 0 || percentage > 1) {
        throw new Error('Percentage should be between 0 and 1.');
    }

    const index = Math.floor(percentage * ratingConfig.length);
    return ratingConfig[Math.min(index, ratingConfig.length - 1)];
};

export const calculateAverages = (
    data: Data,
    group: string | Function,
    count: number
) => {
    return Object.values(groupBy(data, group)).map((item) => {
        const totals = item.reduce((result, curr) => {
            Object.keys(curr).forEach((k) => {
                if (curr.hasOwnProperty(k)) {
                    result[k] =
                        typeof curr[k] === 'number'
                            ? (result[k] || 0) + curr[k]
                            : curr[k];
                }
            });
            return result;
        }, {});

        let averages: Data = {};
        Object.keys(totals).forEach((k) => {
            averages[k] =
                typeof totals[k] === 'number' ? totals[k] / count : totals[k];
        });

        return averages;
    });
};

export const calculateTotals = (data: Data[], group: string | Function) => {
    // Compute gamesPlayed count per playerID where period === 1
    const gamesPlayedMap = data.reduce((acc, curr) => {
        if (curr.period === 1) {
            acc[curr.playerID] = (acc[curr.playerID] || 0) + 1;
        }
        return acc;
    }, {} as Record<string, number>);

    return Object.values(groupBy(data, group)).map((items) => {
        return items.reduce((result, curr) => {
            Object.keys(curr).forEach((k) => {
                result[k] =
                    typeof curr[k] === 'number'
                        ? (result[k] || 0) + curr[k]
                        : curr[k];
            });

            // Ensure gamesPlayed is added for each playerID
            result.gamesPlayed = gamesPlayedMap[curr.playerID] || 0;

            return result;
        }, {});
    });
};

const flagLabels = [
    'skippedTime',
    'shortLongPeriod',
    'playersWithZeroPGT',
    'outsideSchedTime',
    'forceEndGame',
    'shortLongPeriod',
];

/**
 * Rounds a number to the specified decimal places.
 *
 * @param num - The number to be rounded.
 * @param decimalPlaces - The number of decimal places to round to.
 * @returns The rounded number.
 */
export const roundToDecimalPlaces = (
    num: number,
    decimalPlaces: number
): number => {
    if (!Number.isFinite(num)) {
        throw new Error('Num must be a finite number.');
    }
    if (!Number.isInteger(decimalPlaces) || decimalPlaces < 0) {
        throw new Error('Decimal places must be a non-negative number.');
    }

    const factor = Math.pow(10, decimalPlaces);
    return Math.round(num * factor) / factor;
};

export const calculateFairGameTimeScore = (
    games: GameSummary | GameSummary[]
): number | null => {
    if (!Array.isArray(games) && games.noEvents) {
        let uniqueFgtIsNA = 0;

        if (
            games.flags &&
            !Array.isArray(games.flags) &&
            'fgtIsNA' in games.flags
        ) {
            const fgtIsNAArray = (games.flags as Record<string, string[]>)
                .fgtIsNA;
            if (Array.isArray(fgtIsNAArray)) {
                uniqueFgtIsNA = new Set(fgtIsNAArray).size;
            }
        }

        const adjustedNoEvents = games.noEvents - uniqueFgtIsNA;

        return games.fairGameTime && adjustedNoEvents
            ? roundToDecimalPlaces(games.fairGameTime / adjustedNoEvents, 2)
            : null;
    }

    const gamesArr = Array.isArray(games) ? games : [games];
    let totalFairGameTime = 0;
    let count = 0;

    gamesArr.forEach(({ fairGameTime }) => {
        const fgtScore = Number(fairGameTime);

        if (!isNaN(fgtScore) && fgtScore > 0) {
            totalFairGameTime += fgtScore;
            count++;
        }
    });

    const avgScore = count > 0 ? totalFairGameTime / count : 0;

    return roundToDecimalPlaces(avgScore, 2);
};

export const getMatchedIntegrityFlags = (
    gameFlags: string[] | Record<string, string[]>
): string[] => {
    let flags = Array.isArray(gameFlags) ? gameFlags : Object.keys(gameFlags);

    return flags.filter((val: string) => flagLabels.includes(val));
};

export const calculateIntegrityScore = (
    games: GameSummary | GameSummary[]
): number => {
    const gamesArr = Array.isArray(games) ? games : [games];
    let total = 0;

    gamesArr.forEach(({ flags = [], noEvents }) => {
        if (!flags) return null;
        const matchedFlags = getMatchedIntegrityFlags(flags);

        if (noEvents && !Array.isArray(flags) && noEvents > 0) {
            let matchVal = 0;

            matchedFlags.forEach((flag) => {
                const flagCount =
                    flags[flag as keyof typeof flags]?.length || 0;
                matchVal += flagCount / noEvents;
            });

            total += MAX_INT_SCORE - matchVal;
        } else {
            total += MAX_INT_SCORE - matchedFlags.length;
        }
    });

    const avgScore = total / gamesArr.length;

    return roundToDecimalPlaces(avgScore, 2);
};

export type FGTScoreWeights = {
    integrityScoreWeight: number;
    fairGameTimeScoreWeight: number;
    minGtScoreWeight: number;
    disciplineScoreWeight: number;
};

export const calculateDisciplineScore = (
    games: GameSummary | GameSummary[]
): number => {
    const gamesArr = Array.isArray(games) ? games : [games];
    const gameCount = Array.isArray(games) ? games.length : games.noEvents || 0;
    let discCount = 0;

    gamesArr.forEach(({ disciplineList, noEvents }) => {
        if (noEvents && disciplineList && typeof disciplineList === 'object') {
            const playersList = Object.keys(disciplineList);

            playersList.forEach((player) => {
                const playerDiscipline =
                    disciplineList[player as keyof typeof disciplineList];

                if (Array.isArray(playerDiscipline)) {
                    discCount += playerDiscipline.length / (noEvents || 1);
                }
            });
        } else if (Array.isArray(disciplineList)) {
            discCount += disciplineList.length;
        }
    });

    const avgScore = (gameCount - discCount) / gameCount;

    return roundToDecimalPlaces(avgScore, 2);
};

export const calculateMinGameTimeScore = (
    games: GameSummary | GameSummary[]
): number => {
    const gamesArr = Array.isArray(games) ? games : [games];

    const fgtErrorCount = Array.isArray(games)
        ? games.filter(
              (game) =>
                  Array.isArray(game.flags) && !game.flags.includes('fgtIsNA')
          ).length
        : typeof games.flags === 'object' && 'fgtIsNA' in games.flags
        ? (games.flags as Record<string, string[]>)['fgtIsNA'].length
        : 0;

    const gameCount =
        (Array.isArray(games) ? games.length : games.noEvents || 0) -
        fgtErrorCount;

    let pBelowTargetMatchScore = MAX_GT_SCORE * gameCount;

    gamesArr.forEach(({ pBelowTarget, noEvents }) => {
        if (pBelowTarget) {
            if (noEvents && !Array.isArray(pBelowTarget)) {
                const pBelowList = Object.values(pBelowTarget);
                const pBCounts: { [id: string]: number } = {};

                // loop through each player and count same event ID
                pBelowList.forEach((gameIDs) => {
                    gameIDs.forEach((id) => {
                        // Increment the count for each eventID
                        pBCounts[id] = (pBCounts[id] || 0) + 1;
                    });
                });

                // Deduct scores based on event counts
                Object.values(pBCounts).forEach((count) => {
                    pBelowTargetMatchScore -= Math.min(count, 4); // Simplified deduction logic
                });
            } else if (Array.isArray(pBelowTarget)) {
                // Deduct scores based on pBelowTarget counts
                pBelowTargetMatchScore -= Math.min(pBelowTarget.length, 4); // Simplified deduction logic
            }
        }
    });

    return gameCount > 0
        ? roundToDecimalPlaces(pBelowTargetMatchScore / gameCount, 2)
        : 0;
};

const getEnvScoreWeights = (hasFGT: boolean): FGTScoreWeights => {
    // Apply contextual weightings to ENV score
    // If all FGT values are NA (i.e., no bench available to team)
    if (hasFGT) {
        return {
            integrityScoreWeight: 20,
            fairGameTimeScoreWeight: 20,
            minGtScoreWeight: 45,
            disciplineScoreWeight: 15,
        };
    } else {
        return {
            integrityScoreWeight: 60,
            fairGameTimeScoreWeight: 0,
            minGtScoreWeight: 0,
            disciplineScoreWeight: 40,
        };
    }
};

export const getEnvironmentComposition = (
    games: GameSummary | GameSummary[]
) => {
    const intScore = calculateIntegrityScore(games);
    const fgtScore = calculateFairGameTimeScore(games);
    const minGtScore = calculateMinGameTimeScore(games);
    const discScore = calculateDisciplineScore(games);
    const weights = getEnvScoreWeights(!!fgtScore);

    const integrityTotal =
        weights.integrityScoreWeight * (intScore / MAX_INT_SCORE);
    const fgtTotal = fgtScore ? weights.fairGameTimeScoreWeight * fgtScore : 0;
    const minGtTotal = weights.minGtScoreWeight * (minGtScore / MAX_GT_SCORE);
    const disciplineTotal = weights.disciplineScoreWeight * discScore;

    return {
        scores: {
            integrity: integrityTotal,
            fairGameTime: fgtTotal,
            minGameTime: minGtTotal,
            discipline: disciplineTotal,
        },
        weights,
    };
};

export const calculateEnvironmentScore = (
    games: GameSummary | GameSummary[]
) => {
    const { scores } = getEnvironmentComposition(games);

    // Sum all scores (integrity, fairGameTime, minGameTime and discipline)
    const total = Object.values(scores).reduce((a, b) => a + b, 0);

    return total;
};

// Helper for formatting values
export const formatValue = (
    value: any,
    type?: StatTypes,
    isAverage = false,
    totalCount = 1,
    timeAsPercent = false,
    totalTime?: number
) => {
    if (typeof value !== 'number' || !type) return value;

    // Calculate average if needed
    value = isAverage ? value / totalCount : value;

    switch (type) {
        case StatTypes.Percentage:
            return formatPercentage(value);

        case StatTypes.Time:
            if (timeAsPercent && totalTime) {
                if (isAverage) {
                    totalTime = totalTime / totalCount;
                }

                return formatPercentage(value / totalTime);
            }
            return formatTime(value);
        default:
            return roundToDecimalPlaces(value, 2);
    }
};
