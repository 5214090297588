import { generatePath } from 'react-router-dom';
import { createApi } from '@reduxjs/toolkit/query/react';

import { apiEndpoints } from './apiEndpoints';
import { baseQueryWithReauth } from '../util/baseQuery';

import { ReportType } from '../types/reports';

export const reportsApi = createApi({
    reducerPath: 'reportsApi',
    baseQuery: (args, api, extraOptions) =>
        baseQueryWithReauth(
            args,
            api,
            extraOptions,
            process.env.REACT_APP_API_URL
        ),
    endpoints: (builder) => ({
        /**
         *
         * Single Game Reports
         *
         */

        getGameSummaryReport: builder.query<
            any,
            { eventID: string; teamID: string; sessionID: number }
        >({
            query: ({ eventID, teamID, sessionID }) => ({
                url: generatePath(apiEndpoints.getGameReport.url, {
                    eventID,
                    teamID,
                    reportType: ReportType.gameSummary,
                }),
                method: apiEndpoints.getGameReport.method,
                validateStatus: (response: Response) => {
                    return (
                        response.status === 200 ||
                        response.status === 301 ||
                        response.status === 503
                    );
                },
            }),
        }),
        getGameActivityLogReport: builder.query<
            any,
            { eventID: string; teamID: string }
        >({
            query: ({ eventID, teamID }) => ({
                url: generatePath(apiEndpoints.getGameReport.url, {
                    eventID,
                    teamID,
                    reportType: ReportType.gameActivityLog,
                }),
                method: apiEndpoints.getGameReport.method,
                validateStatus: (response: Response) => {
                    return (
                        response.status === 200 ||
                        response.status === 301 ||
                        response.status === 503
                    );
                },
            }),
        }),
        getGameTimeReport: builder.query<
            any,
            {
                eventID: string;
                teamID: string;
                reportType: ReportType;
                sessionID: number;
            }
        >({
            query: ({ eventID, teamID, reportType, sessionID }) => ({
                url: generatePath(apiEndpoints.getGameReport.url, {
                    eventID,
                    teamID,
                    reportType,
                }),
                method: apiEndpoints.getGameReport.method,
                validateStatus: (response: Response) => {
                    return (
                        response.status === 200 ||
                        response.status === 301 ||
                        response.status === 503
                    );
                },
            }),
        }),
        getGameInterchangeOnOffReport: builder.query<
            any,
            { eventID: string; teamID: string; sessionID: number }
        >({
            query: ({ eventID, teamID, sessionID }) => ({
                url: generatePath(apiEndpoints.getGameReport.url, {
                    eventID,
                    teamID,
                    reportType: ReportType.gameInterchangeOnOff,
                }),
                method: apiEndpoints.getGameReport.method,
                validateStatus: (response: Response) => {
                    return (
                        response.status === 200 ||
                        response.status === 301 ||
                        response.status === 503
                    );
                },
            }),
        }),
        getGameInterchangePlayerReport: builder.query<
            any,
            { eventID: string; teamID: string; sessionID: number }
        >({
            query: ({ eventID, teamID, sessionID }) => ({
                url: generatePath(apiEndpoints.getGameReport.url, {
                    eventID,
                    teamID,
                    reportType: ReportType.gameInterchangePlayer,
                }),
                method: apiEndpoints.getGameReport.method,
                validateStatus: (response: Response) => {
                    return (
                        response.status === 200 ||
                        response.status === 301 ||
                        response.status === 503
                    );
                },
            }),
        }),
        getGamePlusMinusReport: builder.query<
            any,
            { eventID: string; teamID: string; sessionID: number }
        >({
            query: ({ eventID, teamID, sessionID }) => ({
                url: generatePath(apiEndpoints.getGameReport.url, {
                    eventID,
                    teamID,
                    reportType: ReportType.gamePlusMinus,
                }),
                method: apiEndpoints.getGameReport.method,
                validateStatus: (response: Response) => {
                    return (
                        response.status === 200 ||
                        response.status === 301 ||
                        response.status === 503
                    );
                },
            }),
        }),
        getGameInterchangeStatTimeSeries: builder.query<
            any,
            {
                eventID: string;
                teamID: string;
                sessionID: number;
            }
        >({
            query: ({ eventID, teamID, sessionID }) => ({
                url: generatePath(apiEndpoints.getGameReport.url, {
                    eventID,
                    teamID,
                    reportType: ReportType.gameInterchangeStatTimeSeries,
                }),
                method: apiEndpoints.getGameReport.method,
                validateStatus: (response: Response) => {
                    return (
                        response.status === 200 ||
                        response.status === 301 ||
                        response.status === 503
                    );
                },
            }),
        }),

        /**
         *
         * Team Season Reports
         *
         */

        getTeamSeasonSummaryReport: builder.query<
            any,
            {
                seasonID: string;
                teamID: string;
                sessionID: number;
                concatenated?: boolean;
            }
        >({
            query: ({ teamID, seasonID, sessionID, concatenated }) => ({
                url: generatePath(apiEndpoints.getTeamSeasonReport.url, {
                    teamID,
                    seasonID,
                    reportType: ReportType.gameSummary,
                }),
                method: apiEndpoints.getTeamSeasonReport.method,
                params: {
                    dimension: concatenated ? 'concatenated' : 'totals',
                },
                validateStatus: (response: Response) => {
                    return (
                        response.status === 200 ||
                        response.status === 301 ||
                        response.status === 503
                    );
                },
            }),
        }),

        getTeamSeasonTimeReport: builder.query<
            any,
            {
                seasonID: string;
                teamID: string;
                sessionID: number;
                reportType: ReportType;
            }
        >({
            query: ({ seasonID, teamID, reportType, sessionID }) => ({
                url: generatePath(apiEndpoints.getTeamSeasonReport.url, {
                    seasonID,
                    teamID,
                    reportType,
                }),
                method: apiEndpoints.getTeamSeasonReport.method,
                validateStatus: (response: Response) => {
                    return (
                        response.status === 200 ||
                        response.status === 301 ||
                        response.status === 503
                    );
                },
            }),
        }),

        getTeamSeasonStatsReport: builder.query<
            any,
            {
                seasonID: string;
                teamID: string;
                sessionID: number;
                reportType: ReportType;
            }
        >({
            query: ({ seasonID, teamID, reportType, sessionID }) => ({
                url: generatePath(apiEndpoints.getTeamSeasonReport.url, {
                    seasonID,
                    teamID,
                    reportType,
                }),
                method: apiEndpoints.getTeamSeasonReport.method,
                validateStatus: (response: Response) => {
                    return (
                        response.status === 200 ||
                        response.status === 301 ||
                        response.status === 503
                    );
                },
            }),
        }),
        getTeamSeasonPlusMinusReport: builder.query<
            any,
            { seasonID: string; teamID: string; sessionID: number }
        >({
            query: ({ seasonID, teamID, sessionID }) => ({
                url: generatePath(apiEndpoints.getTeamSeasonReport.url, {
                    seasonID,
                    teamID,
                    reportType: ReportType.gamePlusMinus,
                }),
                method: apiEndpoints.getTeamSeasonReport.method,
                validateStatus: (response: Response) => {
                    return (
                        response.status === 200 ||
                        response.status === 301 ||
                        response.status === 503
                    );
                },
            }),
        }),
        getTeamSeasonInterchangeStatTimeSeries: builder.query<
            any,
            {
                seasonID: string;
                teamID: string;
                sessionID: number;
                concatenated?: boolean;
            }
        >({
            query: ({ teamID, seasonID, sessionID, concatenated }) => ({
                url: generatePath(apiEndpoints.getTeamSeasonReport.url, {
                    teamID,
                    seasonID,
                    reportType: ReportType.gameInterchangeStatTimeSeries,
                }),
                method: apiEndpoints.getTeamSeasonReport.method,
                params: {
                    dimension: concatenated ? 'concatenated' : undefined,
                },
                validateStatus: (response: Response) => {
                    return (
                        response.status === 200 ||
                        response.status === 301 ||
                        response.status === 503
                    );
                },
            }),
        }),
    }),
});

export const {
    useGetGameSummaryReportQuery,
    useGetGameActivityLogReportQuery,
    useGetGameTimeReportQuery,
    useGetGameInterchangeOnOffReportQuery,
    useLazyGetGameInterchangeOnOffReportQuery,
    useGetGameInterchangePlayerReportQuery,
    useLazyGetGameInterchangePlayerReportQuery,
    useGetGamePlusMinusReportQuery,
    useGetGameInterchangeStatTimeSeriesQuery,
    useGetTeamSeasonSummaryReportQuery,
    useLazyGetTeamSeasonSummaryReportQuery,
    useGetTeamSeasonTimeReportQuery,
    useLazyGetTeamSeasonTimeReportQuery,
    useGetTeamSeasonPlusMinusReportQuery,
    useLazyGetTeamSeasonPlusMinusReportQuery,
    useGetTeamSeasonInterchangeStatTimeSeriesQuery,
    useLazyGetTeamSeasonInterchangeStatTimeSeriesQuery,
    useGetTeamSeasonStatsReportQuery,
    useLazyGetTeamSeasonStatsReportQuery,
} = reportsApi;
