import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useGetEntityQuery } from '../../api/core';
import { useGetLicenceGroupsQuery } from '../../api/licences';

import { entityMap } from '../../routes/routes';

import { ReactComponent as SuccessImage } from '../../assets/images/success.svg';
import { ReactComponent as AppStore } from '../../assets/images/app-store.svg';

import { Message } from 'primereact/message';
import RookieButton from '../../components/RookieButton';
import PageContainer from '../../layout/PageContainer';

import { BaseEntityType } from '../../types/common';

const CheckoutSuccess = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const entityType = searchParams.get('entityType');
    const entityID = searchParams.get('entityID');

    const { data: entityData, isLoading } = useGetEntityQuery(
        // @ts-expect-error
        { entityID, entityType },
        { skip: !entityID || !entityType }
    );

    const { data: licenceGroups } = useGetLicenceGroupsQuery(
        // @ts-expect-error
        { entityID, entityType },
        { skip: !entityID || !entityType }
    );

    const entityUrl = useMemo(
        () =>
            entityType && entityMap[entityType]
                ? `/${entityMap[entityType].urlSegment}/${entityID}`
                : null,
        [entityID, entityType]
    );

    const entityName = useMemo(() => {
        if (entityData?.data) {
            switch (entityType) {
                case BaseEntityType.teams:
                    return entityData.data.teamName;
                case BaseEntityType.organisations:
                    return entityData.data.organisationName;
                case BaseEntityType.associations:
                    return entityData.data.associationName;
            }
        }
    }, [entityData, entityType]);

    const hasExistingSubscription = useMemo(
        () => licenceGroups?.data && licenceGroups.data.length > 1,
        [licenceGroups]
    );

    const hasInAppSubscription = useMemo(() => {
        return (
            licenceGroups?.data &&
            licenceGroups.data.find(
                (l) =>
                    l.transactionID &&
                    l.transactionID !== 'NA' &&
                    l.transactionID !== 0
            )
        );
    }, [licenceGroups]);

    return (
        <PageContainer size="narrow">
            <div style={{ textAlign: 'center' }}>
                <SuccessImage style={{ marginBottom: '1rem' }} />
                <h2>Thank you for your purchase.</h2>
                <p style={{ lineHeight: '1.5' }}>
                    {entityType === BaseEntityType.teams
                        ? 'Congratulations! Your new subscription has been successfully applied.'
                        : 'Your order has been completed successfully.'}{' '}
                    You will reveive a conformation email with details of your
                    order.
                </p>
                {entityType === BaseEntityType.teams &&
                    hasExistingSubscription && (
                        <Message
                            style={{ textAlign: 'left', marginBottom: '1rem' }}
                            text={
                                hasInAppSubscription
                                    ? 'It seems you already have an existing subscription from an in app purchase. If you wish to remove your old subscription, please manage your subscription from your Apple device.'
                                    : 'It seems you already have an existing subscription. If you wish to remove the old subscription, please visit the licence page of your Team.'
                            }
                        />
                    )}
                {entityName && entityUrl ? (
                    <RookieButton
                        label={isLoading ? '' : `Go to ${entityName}`}
                        onClick={() => navigate(entityUrl)}
                        loading={isLoading}
                        severity="secondary"
                    />
                ) : (
                    <RookieButton
                        label="Go to Hub Home"
                        onClick={() => navigate('/')}
                        severity="secondary"
                    />
                )}
                {entityType === BaseEntityType.teams && (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '2rem',
                                margin: '2rem 0',
                                paddingTop: '2rem',
                                borderTop: '1px solid var(--border-color)',
                            }}
                        >
                            <h3>
                                To get started, download Rookie Me Play from the
                                app store.
                            </h3>
                            <a
                                href="https://apps.apple.com/au/app/rm-play/id6448284417"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <AppStore
                                    style={{ width: '200px', height: '60px' }}
                                />
                            </a>
                        </div>
                    </>
                )}
            </div>
        </PageContainer>
    );
};

export default CheckoutSuccess;
