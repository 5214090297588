import { useSearchParams } from 'react-router-dom';

import PageContainer from '../../layout/PageContainer';
import PageHeader from '../../layout/PageHeader';
import StaffForm from './StaffForm';
import StaffPane from './StaffPane';
import { Sidebar } from 'primereact/sidebar';
import { TabMenu } from 'primereact/tabmenu';
import { Badge } from 'primereact/badge';
import { MenuItem } from 'primereact/menuitem';

import { PaginatedResponse, UseQueryResult } from '../../types/api';
import { Action, BaseEntityType } from '../../types/common';
import { Ticket } from '../../types/ticket';
import { UserDetails } from '../../types/user';
import StaffActiveView from './StaffActiveView';
import StaffPendingView from './StaffPendingView';
import StaffRequestView from './StaffRequestView';
import { Association } from '../../types/association';
import { Organisation } from '../../types/organisation';
import { Team } from '../../types/team';

export interface StaffTabProps {
    entity?: Association | Organisation | Team;
    entityID?: string;
    entityType?: BaseEntityType;
    staff: UseQueryResult<PaginatedResponse<UserDetails[]>>;
    invites: UseQueryResult<PaginatedResponse<Ticket[]>>;
    isUpdatingUser?: string | null;
    focusedUser: UserDetails | null;
    isArchived: boolean;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
    showAddToTeamModal: boolean;
    showInviteModal: boolean;
    onAcceptRequest: (user: UserDetails) => void;
    onAddStaff: (submitResult: string) => void;
    onCloseInviteModal: () => void;
    onCloseStaffDrawer: () => void;
    onCloseAddToTeamModal: () => void;
    onDeleteStaff: (user: UserDetails) => void;
    onFocusStaff: (user: UserDetails) => void;
    onLoadMore: () => void;
    onResendTicket: (ticket: Ticket) => void;
    onRevokeTicket: (ticket: Ticket) => void;
    onSelectStaff: (user: UserDetails) => void;
    onShowAddToTeamModal: () => void;
    onShowInviteModal: () => void;
}

const StaffView = (props: StaffTabProps) => {
    const {
        staff: { data: staffData },
        invites: { data: inviteData },
        entityType,
        isArchived,
        permissions,
        showInviteModal,
        onAddStaff,
        onCloseInviteModal,
        onCloseStaffDrawer,
        onShowInviteModal,
    } = props;

    const [searchParams, setSearchParams] = useSearchParams();

    const tab = searchParams.get('tab');

    const activeStaff = staffData?.data.filter(
        (staff) =>
            !staff.roles.some((role) =>
                [
                    'teamsPending',
                    'organisationsPending',
                    'associationsPending',
                ].includes(role.roleID)
            )
    );

    const joinRequests = staffData?.data.filter((staff) =>
        staff.roles.some((role) => role.roleID.includes('Pending'))
    );

    const pendingInvites = inviteData?.data;

    const renderTabWithBadge = (
        item: MenuItem,
        options: any,
        badge?: string
    ) => {
        const { className, onClick, labelClassName } = options;
        return (
            <div onClick={onClick} className={className}>
                <span className={labelClassName}>{item.label}</span>
                {badge && (
                    <Badge
                        style={{
                            marginLeft: '0.5rem',
                            lineHeight: '18px',
                            height: '18px',
                            fontSize: '12px',
                            minWidth: '18px',
                        }}
                        value={badge}
                        severity={!options.active ? 'secondary' : null}
                    />
                )}
            </div>
        );
    };

    const tabs = [
        {
            id: 'active',
            label: 'Active',
            component: StaffActiveView,
            template: (item: MenuItem, options: any) => {
                return renderTabWithBadge(
                    item,
                    options,
                    activeStaff && activeStaff.length.toString()
                );
            },
        },
        {
            id: 'pending',
            label: 'Pending',
            component: StaffPendingView,
            template: (item: MenuItem, options: any) => {
                return renderTabWithBadge(
                    item,
                    options,
                    pendingInvites && pendingInvites.length.toString()
                );
            },
        },
        ...(entityType === BaseEntityType.teams
            ? [
                  {
                      id: 'requests',
                      label: 'Requests',
                      component: StaffRequestView,
                      template: (item: MenuItem, options: any) => {
                          return renderTabWithBadge(
                              item,
                              options,
                              joinRequests && joinRequests.length.toString()
                          );
                      },
                  },
              ]
            : []),
    ];

    const activeTabIndex = tabs.findIndex((t) => tab === t.id);

    const pageHeaderActions: Action[] =
        permissions.canCreate && !isArchived
            ? [
                  {
                      key: 'create',
                      label: 'Invite Staff',
                      onClick: onShowInviteModal,
                      icon: 'add',
                      type: 'button',
                  },
              ]
            : [];

    const TabContent = tabs[activeTabIndex]
        ? tabs[activeTabIndex].component
        : 'div';

    return (
        <PageContainer>
            <PageHeader actions={pageHeaderActions} title="Staff" />
            <TabMenu
                model={tabs}
                activeIndex={activeTabIndex}
                onTabChange={(e) => setSearchParams({ tab: tabs[e.index].id })}
            />
            <TabContent {...props} />
            <Sidebar
                position="right"
                onHide={onCloseStaffDrawer}
                visible={searchParams.has('id')}
            >
                <StaffPane
                    staffID={searchParams.get('id')}
                    canEditRoles={permissions.canEdit}
                />
            </Sidebar>
            <Sidebar
                onHide={onCloseInviteModal}
                header="Invite Staff"
                visible={showInviteModal}
                position="right"
                style={{ width: '100%', maxWidth: '480px' }}
            >
                <StaffForm
                    onCancel={onCloseInviteModal}
                    onSubmit={onAddStaff}
                />
            </Sidebar>
        </PageContainer>
    );
};

export default StaffView;
