// Licences
import { PaginatedResponse, Response } from './api';
import { BaseEntityType } from './common';
import { StripeInterval, Subscription } from './subscriptions';

export enum RookieProduct {
    GameDay = 'game_day',
    Connect = 'connect',
}

export type Limits = { [key: string]: number };

export type Privileges = string[];

export type Restrictions = { [key: string]: string[] };

export interface LicenceType {
    privileges: Privileges;
    limits: Limits;
    restrictions: Restrictions;
    version: string;
    createdAt: string;
    ruleSet: string;
    lastEditedBy: string;
    createdBy?: string;
    licenceTypeStatus: string;
    licenceTypeID: string;
    entitySportID?: string;
    licenceName: string;
    isHidden: boolean;
    lastEdited: string;
    rookieProduct: RookieProduct;
    sportID?: string;
    licenceFlag?: string;
    isFree: boolean | string;
    trialEnabled: boolean;
    appleProducts: any[];
    sortOrder: number;
    product?: Product;
    features?: any;
}

export interface LicenceTypeFeature {
    helpScoutURL?: string;
    unavailable: string | boolean;
    cardTitle?: string;
    createdAt: string;
    featureID: string;
    lastEditedBy: string;
    createdBy: string;
    privilege?: string;
    licenceTypeID: string;
    lastEdited: string;
    cardDescription: string;
    cardImage?: string;
    title: string;
    sortOrder: number;
    tier?: string;
}

export interface LicenceGroup {
    createdAt: string;
    createdBy: string;
    entityID: string;
    entitySportID: string;
    entityType: BaseEntityType;
    invoiceID: string;
    invoiceItemID: string;
    lastEditedAt: string;
    lastEditedBy: string;
    licenceGroupID: string;
    licenceTypeID: string;
    priceID: string;
    quantity: number;
    rookieProduct: any;
    status: string;
    subscription: Subscription;
    subscriptionID: string;
    subscriptionItemID: string;
    totalAssigned: number;
    licenceType?: LicenceType;
    originalEntityID?: string;
    originalEntityType?: BaseEntityType;
    transactionID: string | number;
}

export interface LicenceGroupInfo {
    id: string;
    priceID: string;
    productTitle: string;
    licenceTitle: string;
    price: number;
    currency: string;
    billingPeriod?: StripeInterval;
    renewsAt: Date | null;
    trialActive: any;
    trialEndsAt: any;
    qtyAvailable: number;
    qtyAssigned: number;
    qtyTotal: number;
    status: string;
    groupID: string;
    description: string | null;
    canUpgrade?: boolean;
    isOwner: boolean;
    owner: string;
    features: string[];
    originalEntityID?: string;
    originalEntityType?: BaseEntityType;
    entityID: string;
    sportID?: string;
    entityType: BaseEntityType;
    lastEdited?: string;
    createdAt?: string;
    subscriptionID: string;
    transactionID: string | number;
    entitySportID?: string;
    rookieProduct: RookieProduct;
    tier?: string;
}

export interface Licence {
    createdAt: string;
    createdBy: string;
    entityID: string;
    entitySportID: string;
    entityType: BaseEntityType;
    lastEditedAt: string;
    lastEditedBy: string;
    licenceGroupID: string;
    licenceGroupStatus: string;
    licenceGroupEntityID: string;
    licenceGroupEntityType: BaseEntityType;
    licenceID: string;
    licenceTypeDetails: LicenceType;
    licenceTypeID: string;
    rookieProduct: any;
}

export interface Product {
    prices: StripeWhiteListedPrice[];
    productFeatures: ProductFeature[];
    productID: string;
    productName: string;
    productDescription: string | null;
    defaultPrice: string;
    images: string[];
    unitLabel: string | null;
    rookieProduct: any;
}

export interface StripeWhiteListedPrice {
    recurring: StripeRecurring | null;
    unitAmount: number | null;
    currency: string;
    priceID: string;
    productID: string;
    tiersMode: string | null;
    licenceTypeID?: string;
    default?: boolean;
    rookieProduct?: string;
    sportID?: string;
    tier?: string;
    inclusions?: string;
}

export interface StripeRecurring {
    interval: StripeInterval;
    aggregate_usage: any;
    interval_count: number;
    meter: any;
    trial_period_days: any;
    usage_type: any;
}

export interface ProductFeature {
    name: string;
}

export type LicenceResponse = Response<Licence>;
export type LicencesResponse = PaginatedResponse<Licence[]>;

export type LicenceGroupResponse = Response<LicenceGroup>;
export type LicenceGroupsResponse = Response<LicenceGroup[]>;

export type LicenceTypesResponse = Response<LicenceType[]>;

export type LicenceTypeFeaturesResponse = Response<LicenceTypeFeature[]>;
