import { RadioButton } from 'primereact/radiobutton';
import { classNames } from 'primereact/utils';

interface Props {
    priceID: string;
    label: string;
    value: string;
    disabled?: boolean;
    selected?: boolean;
    active?: boolean;
    onChange?: (priceID: string) => void;
    flag?: string;
    discount?: string;
}

const PriceOption = ({
    priceID,
    label,
    value,
    disabled,
    selected,
    active,
    onChange,
    flag,
    discount,
}: Props) => {
    const className = classNames('price-card', {
        [`is-disabled`]: disabled,
        [`is-selected`]: selected,
        [`is-active`]: active,
        [`has-flag`]: !!flag,
    });

    return (
        <label className={className}>
            <RadioButton
                type="radio"
                name="price"
                className="radio"
                disabled={disabled}
                checked={selected}
                onChange={() => onChange && onChange(priceID)}
                value={priceID}
            />
            <div className="price-details">
                {!!flag && <div className="price-flag">{flag}</div>}
                <div className="price-type">{label}</div>

                <div className="price-cost">
                    {discount && (
                        <span className="price-discount">{discount}</span>
                    )}
                    <span>{value}</span>
                </div>
            </div>
        </label>
    );
};

export default PriceOption;
