import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { uniq, get, sortBy, isObject } from 'lodash';

// Hooks for fetching data from APIs.
import { useLazyGetPlayersQuery } from '../../../api/players';
import { useGetSportPositionsQuery } from '../../../api/sports';
import { useLazyGetTeamSeasonTimeReportQuery } from '../../../api/reports';
import { useGetTeamQuery } from '../../../api/teams';

// Components for rendering and types
import TeamGameTimeReportView from './TeamGameTimeReportView';
import {
    playerAlertTargetCell,
    timeStintCell,
} from '../components/DataTableCells';
import { DataTable } from 'primereact/datatable';
import { SelectItem } from 'primereact/selectitem';

// Helpers and utility functions.
import { defaultReportState } from '../constants';
import { calculateTotals, formatValue } from '../helpers';
import { config } from '../reportConfig';
import { periodSuffix } from '../../../util/helper';
import { Mixpanel } from '../../../util/mixpanel';

// Types
import {
    ReportColumn,
    ReportDataFilters,
    ReportDataTypes,
    ReportDataViews,
    ReportState,
    ReportTimeDisplays,
    ReportType,
    StatTypes,
    TimeStat,
} from '../../../types/reports';
import { PlayerAlertModes, PlayerAlertOptions } from '../../../types/game';
import { Player } from '../../../types/team';

interface Props {
    reportType: ReportType;
}

// Data options for different displays (Total or Average).
const dataTypeOptions = [
    {
        label: ReportDataTypes.Total,
        value: ReportDataTypes.Total,
    },
    {
        label: ReportDataTypes.Average,
        value: ReportDataTypes.Average,
    },
];

// Options for how time data will be displayed (Percentage or Absolute).
const timeDisplayOptions = [
    {
        label: '% Per',
        value: ReportTimeDisplays.Percentage,
    },
    {
        label: 'Time',
        value: ReportTimeDisplays.Time,
    },
];

// Pre-defined time report columns
const availableColumns = [
    'totalPGT',
    'timeOnField',
    'timeOffField',
    'timeBench',
    'timeAssessment',
    'timeInjury',
    'timeDisciplinary',
    'timeOpposition',
    'timeAbsent',
    'zoneTimeFOR',
    'zoneTimeMID',
    'zoneTimeBAC',
    'timePositions',
    'rotationsPerQuarter',
];

const TeamGameTimeReportContainer = ({ reportType }: Props) => {
    // Retrieve the team ID from the route.
    const { teamID } = useParams();

    // Search Params for preloading event, season and category filters.
    const [searchParams] = useSearchParams();
    const eventParam = searchParams.get('event');
    const seasonParam = searchParams.get('season');
    const categoryParam = searchParams.get('category');

    // Retrieve stored player alerts settings from local storage.
    const defaultPlayerAlert = localStorage.getItem('playerAlerts');

    // State Hooks
    const [category, setCategory] = useState<string>(categoryParam || '');
    const [season, setSeason] = useState(seasonParam || '');
    const [hideEmptyColumns, setHideEmptyColumns] = useState<boolean>(true);
    const [emptyColumns, setEmptyColumns] = useState<string[]>([]);
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
    const [columnOrder, setColumnOrder] = useState<string[]>([]);
    const [reportData, setReportData] = useState<
        Record<string, ReportState<any>>
    >({});
    const [filters, setFilters] = useState<ReportDataFilters>({
        event: eventParam ? [eventParam] : [],
        period: 0,
    });
    const [views, setViews] = useState<ReportDataViews>({
        dataType: ReportDataTypes.Total,
        timeDisplay: ReportTimeDisplays.Percentage,
    });
    const [players, setPlayers] = useState<Player[]>([]);
    const [playerAlert, setPlayerAlert] = useState<{
        mode: PlayerAlertModes;
        target: number;
    }>(
        defaultPlayerAlert
            ? JSON.parse(defaultPlayerAlert)
            : {
                  mode: PlayerAlertModes.None,
                  target: 0.5,
              }
    );

    // API
    const [requestReport] = useLazyGetTeamSeasonTimeReportQuery();

    const [
        fetchPlayers,
        { originalArgs: playerArgs, isLoading: isLoadingPlayers },
    ] = useLazyGetPlayersQuery();

    const teamData = useGetTeamQuery({
        teamID: teamID || '',
        expand: 'defaultSeasonDetails',
    });

    const team = useMemo(() => teamData.data?.data, [teamData]);

    const sportPositions = useGetSportPositionsQuery(
        {
            sportID: team?.entitySportID || '',
        },
        {
            skip: !team,
        }
    );

    // Cache busting ref
    const timestampRef = useRef(Date.now()).current;

    // Local Storage helpers
    const storageKey = useMemo(
        () => `${reportType}-${teamID}`,
        [teamID, reportType]
    );

    // Save report settings to local storage
    const saveValue = (key: string, value: any) => {
        const ls = localStorage.getItem(storageKey);
        const parsed = ls ? JSON.parse(ls) : {};

        parsed[key] = value;

        localStorage.setItem(storageKey, JSON.stringify(parsed));
    };

    // Load report settings from local storage
    const loadValue = (key: string) => {
        const ls = localStorage.getItem(storageKey);
        const parsed = ls && JSON.parse(ls);

        return parsed && parsed[key];
    };

    // Handle changes to report filters
    const handleFilterChange = (key: string, value: string) => {
        setFilters((state) => ({
            ...state,
            [key]: value,
        }));
    };

    // Dynamically generate report columns based on fetched data and filters
    const columns: ReportColumn[] = useMemo(() => {
        if (
            !reportData[season] ||
            (reportData[season] && !reportData[season].data)
        ) {
            return [];
        }

        let cols: ReportColumn[] = [
            config.playerNumber,
            config.playerName,
            config.playerGroup,
        ];

        if (filters.event && filters.event.length === 0) {
            cols.push(config.gamesPlayed);
        }

        if (playerAlert.mode !== PlayerAlertModes.None) {
            cols.push({
                id: 'playerAlertTarget',
                name: 'Player Alert Target',
                shortName: 'Target',
                sortField: 'totalPGT',
                body: (rowData: TimeStat) => {
                    return playerAlertTargetCell(
                        parseFloat(rowData.totalPGT) / 100,
                        playerAlert.target
                    );
                },
            });
        }

        // Get all unique report keys
        const reportKeys = [
            ...Array.from(
                new Set(
                    (reportData[season].data || [])
                        .map((data: any) => Object.keys(data))
                        .flat(1)
                )
            ),
        ];

        availableColumns.forEach((colKey) => {
            if (colKey === 'timePositions') {
                const sortedPositions = sortBy(
                    sportPositions.data?.data,
                    'positionSortOrder'
                );

                // Add Positional Stats
                sortedPositions.forEach((pos) => {
                    if (reportKeys.includes(`timePos__${pos.positionID}`)) {
                        cols.push({
                            id: `timePos__${pos.positionID}`,
                            description: `Time a player has spent playing in the ${pos.positionName} position (stints).`,
                            field: `timePos__${pos.positionID}`,
                            name: pos.positionName,
                            shortName: pos.positionShortName,
                            category: 'Positions',
                            body: timeStintCell,
                            type: StatTypes.Time,
                        });
                    }
                });
            }

            if (reportKeys.includes(colKey) && config[colKey]) {
                cols.push(config[colKey]);
            }
        });

        return cols;
    }, [reportData, sportPositions, season, playerAlert, filters.event]);

    const loadPlayers = (cursor: string, status = 'Active') => {
        if (teamID) {
            fetchPlayers(
                {
                    cursor,
                    status,
                    teamID,
                },
                true
            )
                .then((response) => {
                    if (response.data) {
                        if (response?.data?.data) {
                            const d = response.data?.data;

                            setPlayers((prev) => [...prev, ...d]);
                        }

                        // Auto paginate teams
                        if (
                            response.data.lastEvaluatedKey.cursor &&
                            response.data.lastEvaluatedKey.cursor !== cursor
                        ) {
                            loadPlayers(response.data.lastEvaluatedKey.cursor);
                        }
                    }
                })
                .catch((error) => {
                    console.error(
                        `Error fetching events for team ${teamID}`,
                        error
                    );
                });
        }
    };

    // Fetch Active Players
    useEffect(() => {
        loadPlayers('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Fetch Archived Players if required
    useEffect(() => {
        if (
            !reportData.isUninitialized &&
            !reportData.isSuccess &&
            playerArgs?.status === 'Active'
        ) {
            const hasMissingPlayers = reportData[season]?.data?.some(
                (stat: any) => !stat.player
            );

            if (hasMissingPlayers) {
                loadPlayers('', 'Archived');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportData, playerArgs]);

    // Inject player details into data
    /*
    useEffect(() => {
        if (players && reportData[season]?.data) {
            setReportData((prev) => ({
                ...prev,
                [season]: {
                    ...prev[season],
                    data:
                        prev[season].data &&
                        prev[season].data.map((stat: any) => ({
                            ...stat,
                            player: players.find(
                                (p) => p.playerID === stat.playerID
                            ),
                        })),
                },
            }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [players, reportData.isLoading]);
    */

    // Handle fetching the report data when filters change or on mount
    useEffect(() => {
        if (reportData[season] && reportData[season].data) {
            const emptyCols = columns
                .filter((col) => {
                    if (col.persist || col.id === 'playerAlertTarget')
                        return false;
                    return (
                        reportData[season].data?.filter((row: TimeStat) => {
                            const value = col.field && get(row, col.field);

                            return value || value > 0;
                        }).length <= 0
                    );
                })
                .map((col) => col.id);

            setEmptyColumns(emptyCols);
        }
    }, [columns, reportData, season]);

    useEffect(() => {
        const lsHiddenColumns = loadValue('hiddenColumns');
        const lsColumnOrder = loadValue('columnOrder');

        setHiddenColumns(() => {
            if (category === '') {
                return lsHiddenColumns ? lsHiddenColumns : [];
            }
            return columns
                .filter((col) => {
                    const inCategory = col.category === category;

                    return (
                        !col.persist &&
                        (hideEmptyColumns
                            ? emptyColumns.includes(col.id) || !inCategory
                            : !inCategory)
                    );
                })
                .map((col) => col.id);
        });

        setColumnOrder(
            category === '' && lsColumnOrder
                ? lsColumnOrder
                : columns.map((col) => col.id)
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category, columns, emptyColumns, hideEmptyColumns]);

    const handleViewChange = (key: string, value: any) => {
        setViews((state) => ({
            ...state,
            [key]: value,
        }));
    };

    // Set the season to the first available season
    useEffect(() => {
        if (!season && team) {
            setSeason(team.defaultSeasonID);
        }
    }, [team, season]);

    // Set data type to totals when filtering by a single event
    useEffect(() => {
        if (
            filters.event?.length === 1 &&
            views.dataType === ReportDataTypes.Average
        ) {
            handleViewChange('dataType', ReportDataTypes.Total);
        }
    }, [filters.event, views.dataType]);

    const exportCSV = (dataTable: DataTable<any>) => {
        dataTable && dataTable.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: `${reportType} Game Time Report`,
        });
    };

    const categoryOptions = useMemo(() => {
        let options: SelectItem[] = [
            {
                label: 'Custom',
                value: '',
            },
        ];

        let availableCategories = uniq(
            columns.filter((col) => col.category).map((col) => col.category)
        );

        availableCategories.forEach((cat) => {
            options.push({
                label: cat,
                value: cat,
            });
        });

        return options;
    }, [columns]);

    const periodOptions = useMemo(() => {
        if (
            !reportData[season] ||
            (reportData[season] && !reportData[season].data)
        ) {
            return [];
        }
        // Get total periods for the individual game
        const totalPeriods = (
            (reportData[season].data || []) as TimeStat[]
        ).reduce((maxPeriod, item: TimeStat) => {
            return item.period > maxPeriod ? item.period : maxPeriod;
        }, 0);

        return Array.from({ length: totalPeriods }).map((u, i) => {
            const period = i + 1;
            const label = `${period}${periodSuffix(Number(period))}`;
            return {
                label: label,
                value: period,
            };
        });
    }, [reportData, season]);

    const handleColumnOrderChange = (col: ReportColumn[]) => {
        const newState = col.map((c) => c.id);
        setColumnOrder(newState);
        saveValue('columnOrder', newState);
    };

    const handleHiddenColumnChange = (value: string) => {
        setHiddenColumns((prevState) => {
            const newState = prevState.includes(value)
                ? prevState.filter((col) => col !== value)
                : [...prevState, value];

            saveValue('hiddenColumns', newState);

            return newState;
        });
    };

    const handlePlayerAlertChange = (options: PlayerAlertOptions) => {
        // Update state
        setPlayerAlert(options);

        // Update local storage
        localStorage.setItem('playerAlerts', JSON.stringify(options));
    };

    useEffect(() => {
        if (teamID && season && !reportData[season]) {
            const fetchReport = async () => {
                try {
                    // Set report loading in state
                    setReportData((prev) => ({
                        ...prev,
                        [season]: {
                            ...defaultReportState,
                            isLoading: true,
                            isUninitialized: false,
                        },
                    }));

                    //fetch report url
                    const requestReportData = await requestReport({
                        seasonID: season,
                        teamID,
                        sessionID: timestampRef,
                        reportType,
                    }).unwrap();

                    const reportUrl = requestReportData?.data?.objectURL;

                    if (reportUrl) {
                        // Fetch report data
                        const response = await fetch(reportUrl);

                        if (!response.ok) {
                            throw new Error('Failed to load report');
                        }

                        const report = await response.json();

                        // Set report in state
                        setReportData((prev) => ({
                            ...prev,
                            [season]: {
                                ...prev[season],
                                isLoading: false,
                                data: report,
                            },
                        }));
                    } else {
                        throw new Error('Invalid report URL or request failed');
                    }
                } catch (error) {
                    // Set report error in state
                    setReportData((prev) => ({
                        ...prev,
                        [season]: {
                            ...prev[season],
                            isLoading: false,
                            isError: true,
                            error:
                                isObject(error) && 'message' in error
                                    ? error.message
                                    : error,
                        },
                    }));
                }
            };

            fetchReport();
        }
    }, [teamID, season, reportData, requestReport, reportType, timestampRef]);

    const resetFilters = () => {
        setFilters({
            event: [],
            period: 0,
        });
    };

    const handleSeasonChange = (seasonID: string) => {
        setSeason(seasonID);
        resetFilters();
    };

    // Helper for filtering
    const filterData = (data: any, filters: ReportDataFilters) => {
        return data.filter((item: TimeStat) => {
            if (item.period === 'total') return false;

            const hasEventFilter = filters.event && filters.event.length > 0;
            const hasPeriodFilter = filters.period && filters.period > 0;

            if (hasEventFilter && !filters.event?.includes(item.gameID)) {
                return false;
            }
            if (hasPeriodFilter && filters.period !== Number(item.period)) {
                return false;
            }

            return true;
        });
    };

    const tableData = useMemo(() => {
        let newData = reportData[season]?.data;

        if (!newData) return [];

        // Filter data
        newData = filterData(newData, filters);

        // Calculate totals
        newData = calculateTotals(newData, 'playerID');

        // Format data
        return newData.map((data: any) => {
            const uniqueKeys = uniq([
                ...Object.keys(data),
                ...columns.map((col) => col.id),
            ]);

            const player = players.find((p) => p.playerID === data.playerID);

            return uniqueKeys.reduce(
                (acc, key) => {
                    const column = columns.find(
                        (col) => col.id === key && col.id !== 'gamesPlayed'
                    );

                    if (column?.format) {
                        acc[key] = column.format(data, {
                            field: key,
                            column,
                            views,
                            data: reportData[season]?.data,
                        });
                    } else {
                        const totalTime = data['PT'];

                        acc[key] = formatValue(
                            data[key],
                            column?.type,
                            views.dataType === ReportDataTypes.Average,
                            data.gamesPlayed,
                            views.timeDisplay === ReportTimeDisplays.Percentage,
                            totalTime
                        );
                    }

                    return acc;
                },
                { ...data, player }
            );
        });
    }, [columns, season, reportData, views, filters, players]);

    const visibleColumns = useMemo((): ReportColumn[] => {
        return sortBy(
            columns.filter((col) => {
                const isHidden = hiddenColumns.includes(col.id);
                const hideEmpty =
                    hideEmptyColumns && emptyColumns.includes(col.id);

                return !isHidden && !hideEmpty;
            }),
            (col) => columnOrder.indexOf(col.id)
        );
    }, [columns, emptyColumns, columnOrder, hiddenColumns, hideEmptyColumns]);

    const customisableColumns = useMemo(() => {
        return sortBy(
            columns.filter((col) => {
                return (
                    !(hideEmptyColumns && emptyColumns.includes(col.id)) &&
                    !col.persist
                );
            }),
            (col) => columnOrder.indexOf(col.id)
        );
    }, [columns, emptyColumns, columnOrder, hideEmptyColumns]);

    return (
        <TeamGameTimeReportView
            category={category}
            columns={visibleColumns}
            customisableColumns={customisableColumns}
            data={tableData}
            emptyColumns={emptyColumns}
            filters={filters}
            hiddenColumns={hiddenColumns}
            hideEmptyColumns={hideEmptyColumns}
            loading={
                sportPositions.isLoading ||
                teamData.isLoading ||
                isLoadingPlayers
            }
            options={{
                categories: categoryOptions,
                dataType: dataTypeOptions,
                periods: periodOptions,
                timeDisplay: timeDisplayOptions,
            }}
            playerAlert={playerAlert}
            reportData={reportData[season] || {}}
            reportType={reportType}
            season={season}
            team={team}
            views={views}
            onCategoryChange={setCategory}
            onColumnsChange={handleColumnOrderChange}
            onExport={exportCSV}
            onFilterChange={handleFilterChange}
            onHiddenColumnChange={handleHiddenColumnChange}
            onHideEmptyColumns={setHideEmptyColumns}
            onPlayerAlertChange={handlePlayerAlertChange}
            onSeasonChange={handleSeasonChange}
            onViewsChange={handleViewChange}
        />
    );
};

export default TeamGameTimeReportContainer;
