import { useContext, useMemo, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { sortBy } from 'lodash';
import { format } from 'date-fns';

import 'primeicons/primeicons.css';

import PlayerPane from './PlayerPane';
import PlayerForm from './PlayerForm';

import ErrorDisplay from '../../components/ErrorDisplay';
import RookieButton from '../../components/RookieButton';
import SpreadsheetImport from '../../components/SpreadsheetImport';
import PageHeader from '../../layout/PageHeader';

import { Avatar } from 'primereact/avatar';
import { Column, ColumnProps } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { Skeleton } from 'primereact/skeleton';

import { PLAYER_FIELD } from '../../util/importFields';
import { getFirstChars } from '../../util/helper';

import { Action, ERROR_TYPES } from '../../types/common';
import { Player, Team } from '../../types/team';
import { Roles } from '../../types/roles';

import PageContainer from '../../layout/PageContainer';
import { Sidebar } from 'primereact/sidebar';

import { Mixpanel } from '../../util/mixpanel';
import { TabMenu } from 'primereact/tabmenu';
import { ToastContext } from '../../contexts/ToastContext';
import { UserDetails } from '../../types/user';
import { SplitButton } from 'primereact/splitbutton';
import Loader from '../../components/Loader';

const STATUS_SUCCESS = 'success';
const STATUS_UPDATED = 'updated';

interface Props {
    data: Player[];
    team?: Team;
    staffData: UserDetails[];
    focusedUser: Player | null;
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
    isImporting: boolean;
    isDeleting?: boolean;
    isArchiving?: boolean;
    onSelectUser: (player: Player) => void;
    onCloseDrawer: () => void;
    onCloseInviteDialog: () => void;

    onArchiveUser: (player: Player) => void;
    onDeleteUser: (player: Player) => void;
    onFocusUser: (player: Player) => void;

    onLoadMore?: () => void;
    onTabChange?: () => void;
    onImport: (data: any) => void;

    onShowInviteDialog: (isCreate: boolean) => void;

    roles: Roles;
    showPagination: boolean;
    viewMedia?: string;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const PlayersView = (props: Props) => {
    const {
        focusedUser,
        isError,
        isFetching,
        isLoading,
        isImporting,
        onImport,
        staffData,
        team,
        onArchiveUser,
        onDeleteUser,
        onShowInviteDialog,
        onSelectUser,
        onFocusUser,
        isDeleting,
        isArchiving,
        onCloseDrawer,
    } = props;

    const navigate = useNavigate();
    const rowMenu = useRef<Menu>(null);
    const rowContextMenu = useRef<ContextMenu>(null);
    const dataTable = useRef<any>(null);
    const moreMenu = useRef<Menu>(null);
    const toast = useContext(ToastContext);

    const [isSpreadsheetModalOpen, setSpreadsheetModalOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const activeTab = searchParams.get('tab');

    const handleSubmitResult = (submitResult: string) => {
        const hasSubmitted = submitResult === STATUS_SUCCESS;
        const hasUpdated = submitResult === STATUS_UPDATED;

        onCloseDrawer && onCloseDrawer();

        if (toast && toast.current) {
            toast.current.show({
                severity: hasSubmitted || hasUpdated ? 'success' : 'warn',
                summary: hasUpdated
                    ? 'Player Updated'
                    : hasSubmitted
                    ? 'Player Added'
                    : 'Player Addition Unsuccessful',
            });
        }
    };

    const exportCSV = () => {
        dataTable.current && dataTable.current.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: 'Players',
        });
    };

    const titleTemplate = (row: Player) => {
        return (
            <div className="detail-cell">
                <Avatar
                    label={getFirstChars(row.displayName, 2).toUpperCase()}
                    image={row.playerImageURL}
                    style={{ background: '#4A4A4D', color: '#ffffff' }}
                    shape="circle"
                />
                <span>
                    {row.firstName} {row.lastName}
                </span>
            </div>
        );
    };

    const actionTemplate = (row: Player) => {
        return (
            <div className="p-buttonset">
                <RookieButton
                    severity="secondary"
                    text={true}
                    className="td-action"
                    aria-label="More"
                    onClick={(e) => {
                        if (rowMenu.current) {
                            rowMenu.current.toggle(e);
                        }
                        onFocusUser(row);
                    }}
                    icon="more_horiz"
                />
            </div>
        );
    };

    const renderGroup = (row: Player) => {
        return (
            <Avatar
                style={{
                    background: row && row.colour ? row.colour : 'transparent',
                    width: '24px',
                    height: '16px',
                    border: '1px solid #e5e5e5',
                }}
            />
        );
    };

    const columnSchema: ColumnProps[] = [
        {
            body: isLoading ? <Skeleton /> : null,
            field: 'uniformNumber',
            header: '#',
            style: { width: '60px' },
            align: 'center',
            sortable: true,
            alignHeader: 'center',
        },
        {
            body: isLoading ? <Skeleton /> : titleTemplate,
            field: 'firstName',
            header: 'Name',
            sortable: true,
            style: { width: '400px' },
            exportable: false,
        },
        {
            field: 'player.colour',
            header: 'Group',
            sortable: true,
            body: renderGroup,
            style: { width: '100px' },
            align: 'center',
            alignHeader: 'center',
            exportField: 'colour',
        },
        {
            body: isLoading ? <Skeleton /> : null,
            field: 'gender',
            header: 'Gender',
            align: 'center',
            alignHeader: 'center',
            sortable: true,
            style: { width: '400px' },
            exportable: false,
        },
        {
            body: isLoading ? <Skeleton /> : null,
            field: 'dateOfBirth',
            header: 'DOB',
            align: 'center',
            alignHeader: 'center',
            sortable: true,
            style: { width: '400px' },
            exportable: false,
        },
        {
            body: (row: Player) =>
                isLoading ? (
                    <Skeleton />
                ) : (
                    format(new Date(row.createdAt), 'yyyy-MM-dd')
                ),
            field: 'createdAt',
            header: 'Created',
            align: 'center',
            alignHeader: 'center',
            sortable: true,
            style: { width: '400px' },
            exportable: false,
        },
        {
            field: 'createdBy',
            header: 'Created By',
            align: 'center',
            sortable: true,
            style: { width: '400px' },
            exportable: false,
            body: (row) => {
                const staff = staffData.find(
                    (staff) => staff.userID === row.createdBy
                );

                return staff && staff?.firstName === 'Unknown'
                    ? `Past staff member`
                    : staff
                    ? `${staff?.firstName} ${staff?.lastName}`
                    : '';
            },
        },

        {
            body: isLoading ? <Skeleton /> : actionTemplate,
            className: 'actions-td',
            field: 'action',
            header: '',
            exportable: false,
        },
        /**** Export Fields ****/
        {
            field: 'firstName',
            header: 'First Name',
            hidden: true,
        },
        {
            field: 'lastName',
            header: 'Last Name',
            hidden: true,
        },
        {
            field: 'displayName',
            header: 'Display Name',
            hidden: true,
        },
        {
            field: 'dateOfBirth',
            header: 'DOB',
            hidden: true,
        },
        {
            field: 'gender',
            header: 'Gender',
            hidden: true,
        },
        {
            field: 'uniformNumber',
            header: 'Uniform Number',
            hidden: true,
        },
    ];

    const columns = columnSchema.map((col: ColumnProps) => {
        return <Column key={col.field} {...col} />;
    });

    const pageHeaderActions: Action[] =
        props.permissions.canCreate && team?.teamStatus !== 'Archived'
            ? [
                  {
                      key: 'create',
                      label: 'Add Player',
                      icon: 'add',
                      type: 'button',
                      onClick: () => props.onShowInviteDialog(true),
                  },
                  {
                      key: 'import',
                      component: (
                          <SplitButton
                              label="Import Players"
                              onClick={() => setSpreadsheetModalOpen(true)}
                              menuStyle={{ whiteSpace: 'nowrap' }}
                              model={[
                                  {
                                      label: 'Import Template',
                                      icon: 'pi pi-download',
                                      command: () => {
                                          const templateUrl =
                                              'https://cdn.home.rookieme.com/assets/hub/player_import_template.xlsx';

                                          window.open(templateUrl, '_blank');
                                      },
                                  },
                              ]}
                              dropdownIcon="pi pi-chevron-down"
                              className="p-button-secondary"
                          />
                      ),
                  },
                  {
                      key: 'more',
                      icon: 'more_horiz',
                      severity: 'secondary',
                      onClick: (e) => {
                          if (moreMenu.current) {
                              moreMenu.current.toggle(e);
                          }
                      },
                  },
              ]
            : [];

    const playerMenuActions = useMemo(() => {
        let actions: MenuItem[] = [
            {
                label: 'View',
                command: () => focusedUser && onSelectUser(focusedUser),
            },
        ];

        if (activeTab === 'Archived') {
            actions = actions.concat([
                {
                    label: 'Delete',
                    command: () => focusedUser && onDeleteUser(focusedUser),
                },
            ]);
        } else {
            actions = actions.concat([
                {
                    label: 'Archived',
                    command: () => focusedUser && onArchiveUser(focusedUser),
                },
                {
                    label: 'Edit',
                    command: () => focusedUser && onShowInviteDialog(false),
                },
            ]);
        }

        return actions;
    }, [
        activeTab,
        focusedUser,
        onShowInviteDialog,
        onDeleteUser,
        onArchiveUser,
        onSelectUser,
    ]);

    const moreMenuActions: MenuItem[] = [
        {
            label: 'Export Players',
            command: () => exportCSV(),
        },
        ...(props.permissions.canCreate
            ? [
                  {
                      label: 'Player Thresholds',
                      command: () => navigate('thresholds'),
                  },
              ]
            : []),
    ];

    const tableEmptyMessage = (
        <ErrorDisplay
            actions={
                isError
                    ? [
                          {
                              onClick: () => navigate(0), // refresh
                              icon: 'refresh',
                              label: 'Retry',
                          },
                      ]
                    : [
                          {
                              label: 'Add Player',
                              icon: 'add',
                              type: 'button',
                              onClick: () => props.onShowInviteDialog(true),
                          },
                      ]
            }
            alignment="middle"
            errorType={ERROR_TYPES.notFound}
            hasReturn={false}
            proportion="compact"
            title={isError ? 'No data returned' : `No players found`}
            desc={
                isError
                    ? 'Refresh to try the request again'
                    : "To get started select 'Add Player' in the top right area."
            }
        />
    );

    const footerTemplate = () => {
        return (
            <RookieButton
                onClick={props.onLoadMore}
                severity="secondary"
                label="Load more"
                icon="pending"
            />
        );
    };

    const getPlayerModalTitle = () => {
        if (searchParams.has('create')) {
            return focusedUser?.playerID ? 'Edit Player' : 'Add Player';
        }

        return '';
    };

    const tabs = [
        ...(team?.teamStatus !== 'Archived'
            ? [
                  {
                      id: 'Active',
                      label: 'Active',
                  },
              ]
            : []),
        {
            id: 'Archived',
            label: 'Archived',
        },
    ];

    const activeTabIndex = tabs.findIndex((tab) => activeTab === tab.id);

    const playerPaneActions = useMemo(() => {
        if (searchParams.get('create') || !focusedUser) return null;

        if (focusedUser.status === 'Archived') {
            return (
                <RookieButton
                    text
                    icon="delete"
                    onClick={() => {
                        onDeleteUser(focusedUser);
                    }}
                    tooltip="Delete"
                    tooltipOptions={{ position: 'bottom' }}
                />
            );
        }

        return (
            <>
                <RookieButton
                    text
                    icon="edit"
                    onClick={() => {
                        onShowInviteDialog(false);
                    }}
                    tooltip="Edit"
                    tooltipOptions={{ position: 'bottom' }}
                />
                <RookieButton
                    text
                    icon="archive"
                    onClick={() => {
                        onArchiveUser(focusedUser);
                    }}
                    tooltip="Archive"
                    tooltipOptions={{ position: 'bottom' }}
                />
            </>
        );
    }, [
        focusedUser,
        onArchiveUser,
        onDeleteUser,
        onShowInviteDialog,
        searchParams,
    ]);

    return (
        <>
            <PageContainer>
                <PageHeader title="Players" actions={pageHeaderActions} />

                <TabMenu
                    model={tabs}
                    activeIndex={activeTabIndex}
                    onTabChange={(e) => {
                        if (props.onTabChange) {
                            props.onTabChange();
                        }

                        setSearchParams({ tab: tabs[e.index].id });
                    }}
                />
                <DataTable
                    ref={dataTable}
                    value={
                        isLoading
                            ? Array(5).fill(0)
                            : sortBy(
                                  props.data,
                                  (player) =>
                                      player.uniformNumber &&
                                      Number(player.uniformNumber)
                              )
                    }
                    loading={(isImporting || isFetching) && !isLoading}
                    footer={
                        !isFetching && props.showPagination
                            ? footerTemplate
                            : null
                    }
                    emptyMessage={tableEmptyMessage}
                    selectionMode="single"
                    onSelectionChange={(e) => {
                        onSelectUser(e.value);
                    }}
                    onContextMenuSelectionChange={(e) => onFocusUser(e.value)}
                    onContextMenu={(e) => {
                        if (rowContextMenu.current) {
                            rowContextMenu.current.show(e.originalEvent);
                        }
                        if (rowMenu.current) {
                            rowMenu.current.hide(e.originalEvent);
                        }
                    }}
                    exportFilename="players-export"
                >
                    {isLoading
                        ? columns
                        : props.data.length > 0
                        ? columns
                        : null}
                </DataTable>
            </PageContainer>

            <Menu model={playerMenuActions} popup ref={rowMenu} />
            <Menu model={moreMenuActions} popup ref={moreMenu} />

            <ContextMenu model={playerMenuActions} ref={rowContextMenu} />

            <Sidebar
                position="right"
                className="is-form"
                visible={searchParams.has('create') || searchParams.has('id')}
                header={getPlayerModalTitle()}
                onHide={onCloseDrawer}
                icons={playerPaneActions}
            >
                {searchParams.get('create') ? (
                    <PlayerForm
                        onCancel={props.onCloseInviteDialog}
                        onSubmit={handleSubmitResult}
                        playerID={focusedUser?.playerID}
                        initialValues={focusedUser ? focusedUser : undefined}
                    />
                ) : searchParams.get('id') ? (
                    <PlayerPane player={searchParams.get('id') || ''} />
                ) : (
                    <div>Loading...</div>
                )}
            </Sidebar>

            {isSpreadsheetModalOpen && (
                <SpreadsheetImport
                    isOpen={isSpreadsheetModalOpen}
                    onClose={() => setSpreadsheetModalOpen(false)}
                    onSubmit={onImport}
                    fields={PLAYER_FIELD}
                />
            )}

            {isDeleting ||
                (isArchiving && <Loader size="fullscreen" overlay />)}
        </>
    );
};

export default PlayersView;
