import { useState, useEffect, useMemo, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { isObject } from 'lodash';

import { useLazyGetGameInterchangePlayerReportQuery } from '../../../api/reports';
import { useLazyGetPlayersQuery } from '../../../api/players';
import { useGetTeamQuery } from '../../../api/teams';
import { useGetEventQuery, useGetSeasonEventsQuery } from '../../../api/events';

import { DataTable } from 'primereact/datatable';

import { config } from '../reportConfig';
import { Mixpanel } from '../../../util/mixpanel';
import { defaultReportState } from '../constants';
import { formatTime, toISOStringWithTimezone } from '../../../util/helper';

import TeamInterchangePlayerReportView from './TeamInterchangePlayerReportView';

import { Player } from '../../../types/team';
import { BaseEntityType } from '../../../types/common';
import { InterchangeStat, ReportState } from '../../../types/reports';

const TeamInterchangePlayerReportContainer = () => {
    const { teamID } = useParams();
    const [searchParams] = useSearchParams();

    const seasonParam = searchParams.get('seasonID');

    // Ref hooks
    const timestampRef = useRef(Date.now()).current; // for report cache busting

    // State hooks
    const [reportData, setReportData] = useState<{
        [key: string]: { [key: string]: ReportState<InterchangeStat[]> };
    }>({});

    const [event, setEvent] = useState<string | undefined>();
    const [season, setSeason] = useState<string>(seasonParam || '');
    const [players, setPlayers] = useState<Player[]>([]);

    // API hooks
    const [fetchPlayers, { originalArgs: playerArgs }] =
        useLazyGetPlayersQuery();

    const [requestReportData] = useLazyGetGameInterchangePlayerReportQuery();

    const teamData = useGetTeamQuery(
        { teamID: teamID || '', expand: 'defaultSeasonDetails' },
        { skip: !teamID }
    );

    const team = useMemo(() => teamData.data?.data, [teamData]);

    // To prevent pagination caching issues
    const now = useRef(new Date()).current;

    const eventData = useGetSeasonEventsQuery(
        {
            entityType: BaseEntityType.teams,
            entityID: team?.teamID || '',
            seasonID: season,
            to: toISOStringWithTimezone(now),
            //limit: '1',
        },
        {
            skip: !team || !season,
        }
    );

    const { data: selectedEventData } = useGetEventQuery(
        {
            entityType: BaseEntityType.teams,
            entityID: teamID || '',
            eventID: event || '',
            expand: 'gameSummaryReport',
        },
        {
            skip: !event || !team,
        }
    );

    const events = useMemo(() => eventData.data?.data, [eventData]);

    const totalPeriods = useMemo(
        () =>
            selectedEventData?.data.gameDetails?.gameSummaryReport?.gameSummary
                ?.noPeriods || 0,
        [selectedEventData]
    );

    const loadPlayers = (cursor: string, status = 'Active') => {
        if (teamID) {
            fetchPlayers(
                {
                    cursor,
                    status,
                    teamID,
                },
                true
            )
                .then((response) => {
                    if (response.data) {
                        if (response?.data?.data) {
                            const d = response.data?.data;

                            setPlayers((prev) => [...prev, ...d]);
                        }

                        // Auto paginate teams
                        if (
                            response.data.lastEvaluatedKey.cursor &&
                            response.data.lastEvaluatedKey.cursor !== cursor
                        ) {
                            loadPlayers(response.data.lastEvaluatedKey.cursor);
                        }
                    }
                })
                .catch((error) => {
                    console.error(
                        `Error fetching events for team ${teamID}`,
                        error
                    );
                });
        }
    };

    // Set the season to the first available season
    useEffect(() => {
        if (!season && team) {
            setSeason(team.defaultSeasonID);
        }
    }, [team, season]);

    // Set the event to the first event of the season
    useEffect(() => {
        if (
            eventData.status === 'fulfilled' &&
            !event &&
            events &&
            events.length > 0
        ) {
            setEvent(events[0].eventID);
        }
    }, [event, events, eventData]);

    // Set the event to the first event of the season
    useEffect(() => {
        if (eventData.status === 'fulfilled' && events && events.length > 0) {
            setEvent(events[0].eventID);
        }
    }, [season, events, eventData]);

    useEffect(() => {
        if (
            teamID &&
            season &&
            event &&
            (!reportData[season] || !reportData[season][event])
        ) {
            const fetchReport = async () => {
                try {
                    // Set loading states for both reports
                    setReportData((prev) => ({
                        ...prev,
                        [season]: {
                            ...prev[season],
                            [event]: {
                                ...defaultReportState,
                                isLoading: true,
                                isUninitialized: false,
                            },
                        },
                    }));

                    // Fetch both report URLs concurrently
                    const { data } = await requestReportData({
                        eventID: event,
                        teamID: teamID,
                        sessionID: timestampRef,
                    }).unwrap();

                    const reportUrl = data?.objectURL;

                    const response = await fetch(reportUrl);

                    if (!response.ok) {
                        throw new Error(`Failed to load report`);
                    }

                    const report = await response.json();

                    const formattedReport = formatReport(report);

                    setReportData((prev) => ({
                        ...prev,
                        [season]: {
                            ...prev[season],
                            [event]: {
                                ...prev[season][event],
                                isLoading: false,
                                data: formattedReport,
                            },
                        },
                    }));
                } catch (error) {
                    // Handle errors by setting the error state for both reports
                    const errorMessage =
                        isObject(error) && 'message' in error
                            ? error.message
                            : error;

                    setReportData((prev) => ({
                        ...prev,
                        [season]: {
                            ...prev[season],
                            [event]: {
                                ...prev[season][event],
                                isLoading: false,
                                isError: true,
                                error: errorMessage,
                            },
                        },
                    }));
                }
            };

            // Fetch the report
            fetchReport();
        }
    }, [teamID, season, event, reportData, requestReportData, timestampRef]);

    // Fetch Active Players
    useEffect(() => {
        loadPlayers('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Fetch Archived Players if required
    useEffect(() => {
        if (
            event &&
            reportData[season] &&
            !reportData[season].isUninitialized &&
            !reportData[season].isSuccess &&
            playerArgs?.status === 'Active'
        ) {
            const hasMissingPlayers = reportData[season][event].data?.some(
                (stat: InterchangeStat) => !stat.player
            );

            if (hasMissingPlayers) {
                loadPlayers('', 'Archived');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportData, season, playerArgs]);

    useEffect(() => {
        if (
            season &&
            event &&
            players &&
            reportData[season] &&
            reportData[season][event] &&
            reportData[season][event].data
        ) {
            setReportData((prev) => ({
                ...prev,
                [season]: {
                    ...prev[season],
                    [event]: {
                        ...prev[season][event],
                        data: (prev[season][event].data || []).map(
                            (stat: InterchangeStat) => {
                                const player = players.find(
                                    (p) => p.playerID === stat.playerID
                                );
                                return {
                                    ...stat,
                                    player,
                                    playerName:
                                        player &&
                                        `${player.firstName} ${player.lastName}`,
                                };
                            }
                        ),
                    },
                },
            }));
        }
    }, [reportData, players, season, event]);

    const formatReport = (report: any) => {
        return report.map((row: any) =>
            Object.keys(row).reduce((result, key) => {
                if (typeof row[key] === 'number') {
                    result[key] = formatTime(row[key]);
                } else {
                    result[key] = row[key];
                }

                return result;
            }, {} as any)
        );
    };

    const exportCSV = (dataTable: DataTable<any>) => {
        dataTable && dataTable.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: `Team Interchange Player Report`,
        });
    };

    const data = useMemo(() => {
        return event && reportData[season] && reportData[season][event]
            ? reportData[season][event]?.data
            : [];
    }, [reportData, season, event]);

    const columns = useMemo(() => {
        let cols = [config.playerNumber, config.playerName, config.playerGroup];

        return cols;
    }, []);

    return (
        <TeamInterchangePlayerReportView
            columns={columns}
            data={data}
            event={event}
            eventOptions={events}
            loading={
                !!(
                    event &&
                    reportData[season] &&
                    reportData[season][event] &&
                    reportData[season][event].isLoading
                )
            }
            season={season}
            team={team}
            totalPeriods={totalPeriods}
            onExport={exportCSV}
            onEventChange={(value) => setEvent(value)}
            onSeasonChange={(value) => {
                setEvent('');
                setSeason(value);
            }}
        />
    );
};

export default TeamInterchangePlayerReportContainer;
