import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { startCase } from 'lodash';

import {
    Column,
    ColumnBodyOptions,
    ColumnEditorOptions,
    ColumnProps,
} from 'primereact/column';
import { Chip } from 'primereact/chip';
import {
    DataTable,
    DataTableRowEditEvent,
    DataTableValue,
} from 'primereact/datatable';
import { FileUpload, FileUploadHandlerEvent } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { SplitButton } from 'primereact/splitbutton';
import { Toolbar } from 'primereact/toolbar';

import ErrorDisplay from './ErrorDisplay';
import Icon from './Icon';

import { ERROR_TYPES } from '../types/common';
import RookieButton from './RookieButton';

const STATUS_SUCCESS = 'success';
const STATUS_ERROR = 'error';
const STATUS_LOADING = 'loading';
const STATUS_INVALID = 'invalid';

type RowData = { [key: string]: any }[] & { status: any };

interface Props {
    columns: ColumnProps[];
    data: RowData;
    isError: boolean;
    isSubmitting: boolean;
    onImportError?: () => void;
    onImportComplete?: (data: any) => void;
    onEditComplete?: (data: any) => void;
    onClearAll?: () => void;
    onClearCompleted?: () => void;
    onRemoveRow?: (rowIndex: number) => void;
    onAddRow?: (data: any) => void;
    onLoadMore?: () => void;
    onSubmit: () => void;
}

const ImportForm = (props: Props) => {
    const { isSubmitting, isError } = props;
    const navigate = useNavigate();

    // States
    const [editingRows, setEditingRows] = useState<DataTableValue>([]);

    // Refs
    const uploadButton = useRef<FileUpload>(null);

    // Helpers
    const availableCols: string[] = props.columns.map((col) => col.field || '');

    const handleUpload = (e: FileUploadHandlerEvent) => {
        const file = e.files[0];

        switch (file.type) {
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.ms-excel':
                importExcel(file);
                break;
            case 'text/csv':
                importCSV(file);
                break;
            default:
                if (props.onImportError) {
                    props.onImportError();
                }
        }

        // Reset upload button after uploading
        if (uploadButton?.current) {
            uploadButton.current.clear();
        }
    };

    const importCSV = (file: File) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            if (!e.target) {
                return;
            }

            const csv = e.target.result;

            if (typeof csv !== 'string') {
                return;
            }

            const data = csv.split('\n');

            // Prepare DataTable
            const cols = data[0]
                .replace(/['"\r]+/g, '')
                .split(',')
                .filter((col) => availableCols.includes(col));

            data.shift();

            const importedData = data
                .map((d) => {
                    let split = d.split(',');
                    return cols.reduce((obj, c, i) => {
                        obj[c] = split[i].replace(/['"\r]+/g, '');
                        return obj;
                    }, {} as { [key: string]: any });
                })
                .filter((value) => Object.keys(value).length !== 0);

            if (props.onImportComplete) {
                props.onImportComplete(importedData);
            }
        };

        reader.readAsText(file, 'UTF-8');
    };

    const importExcel = (file: File) => {
        import('xlsx').then((xlsx) => {
            const reader = new FileReader();

            reader.onload = (e) => {
                if (!e.target) {
                    return;
                }
                const wb = xlsx.read(e.target.result, { type: 'array' });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                const data: any = xlsx.utils.sheet_to_json(ws, { header: 1 });

                // Prepare DataTable
                const cols: string[] = data[0].filter((col: string) =>
                    availableCols.includes(col)
                );

                data.shift();

                const importedData = data
                    .map((d: any) => {
                        return cols.reduce((obj, c, i) => {
                            obj[c] = d[i];
                            return obj;
                        }, {} as { [key: string]: any });
                    })
                    .filter((value: any) => Object.keys(value).length !== 0);

                if (props.onImportComplete) {
                    props.onImportComplete(importedData);
                }
            };

            reader.readAsArrayBuffer(file);
        });
    };

    const clearAll = () => {
        if (props.onClearAll) {
            props.onClearAll();
        }
    };

    const clearUploaded = () => {
        if (props.onClearCompleted) {
            props.onClearCompleted();
        }
    };

    const cellEditor = (options: ColumnEditorOptions, column: any) => {
        if (column.component) {
            return column.component(options);
        }
        return (
            <InputText
                onChange={(e) =>
                    options.editorCallback
                        ? options.editorCallback(e.target.value)
                        : {}
                }
                placeholder={startCase(options.field)}
                type="text"
                value={options.value}
            />
        );
    };

    const onRowEditComplete = ({
        newData,
        index,
    }: {
        newData: any;
        index: number;
    }) => {
        if (props.onEditComplete) {
            const data = props.data.map((data, i) =>
                i === index ? newData : data
            );
            props.onEditComplete(data);
        }
    };

    const onRowEditChange = (e: DataTableRowEditEvent) => {
        setEditingRows(e.data);
    };

    const addRow = () => {
        const emptyRow = availableCols.reduce((result, val) => {
            result[val] = '';
            return result;
        }, {} as { [key: string]: string });

        if (props.onAddRow) {
            props.onAddRow([...props.data, emptyRow]);
            setEditingRows([emptyRow]);
        }
    };

    const removeRow = (index: number) => {
        if (props.onRemoveRow) {
            props.onRemoveRow(index);
        }
    };

    const rowClass = (data: RowData) => {
        return data && data.status ? `is-${data.status}` : '';
    };

    // clear splitButton action
    const clearActions = [
        {
            command: () => clearUploaded(),
            severity: 'secondary',
            disabled: isSubmitting,
            icon: 'remove_done',
            label: 'Clear Completed',
        },
    ];

    // Templating
    const header = (
        <div className="has-loadingbar">
            <Toolbar
                start={
                    <FileUpload
                        ref={uploadButton}
                        chooseOptions={{
                            className: `${isSubmitting && 'p-disabled'}`,
                            icon: 'upload_file',
                            label: 'Import',
                        }}
                        mode="basic"
                        name="teamImport"
                        auto
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        customUpload
                        uploadHandler={handleUpload}
                    />
                }
                end={
                    <>
                        {props.data.length > 0 && (
                            <>
                                {props.data.some(
                                    (data) => data.status === STATUS_SUCCESS
                                ) ? (
                                    <SplitButton
                                        severity="secondary"
                                        model={clearActions}
                                        onClick={() => clearAll()}
                                        disabled={isSubmitting}
                                        icon={<Icon name="do_not_disturb_on" />}
                                        label="Clear All"
                                    />
                                ) : (
                                    <RookieButton
                                        severity="secondary"
                                        disabled={isSubmitting}
                                        onClick={() => clearAll()}
                                        type="button"
                                        icon="do_not_disturb_on"
                                        label="Clear All"
                                    />
                                )}
                                <RookieButton
                                    severity="success"
                                    disabled={isSubmitting}
                                    onClick={props.onSubmit}
                                    type="submit"
                                    icon="upload"
                                    label="Upload"
                                />
                            </>
                        )}
                    </>
                }
            />
            {isSubmitting && <ProgressBar mode="indeterminate" />}
        </div>
    );

    const obj = props.data[props.data.length - 1];
    let isDisabled = obj && !Object.values(obj).some((val) => !!val);

    const editColumnTemplate = (data: RowData, row: ColumnBodyOptions) => {
        if (row?.rowEditor?.editing) {
            return (
                <div className="actions-td_wrapper">
                    {renderStatus(data)}
                    <div className="p-buttonset set-is-spaced">
                        <RookieButton
                            size="small"
                            rounded={true}
                            severity="success"
                            icon="check_circle"
                            onClick={(e) =>
                                row.rowEditor?.onSaveClick
                                    ? row.rowEditor.onSaveClick(e)
                                    : undefined
                            }
                        />
                        <RookieButton
                            size="small"
                            rounded={true}
                            severity="danger"
                            icon="cancel"
                            onClick={(e) =>
                                row.rowEditor?.onCancelClick
                                    ? row.rowEditor.onCancelClick(e)
                                    : undefined
                            }
                        />
                    </div>
                </div>
            );
        } else if (data && data.status === 'loading') {
            return (
                <div className="actions-td_wrapper">
                    <ProgressSpinner
                        animationDuration="3s"
                        className="loading-spinner is-small clear is-secondary"
                    />
                </div>
            );
        } else if ((data && data.status === 'success') || isSubmitting) {
            return renderStatus(data);
        } else {
            return (
                <div className="actions-td_wrapper">
                    {renderStatus(data)}
                    <div className="p-buttonset set-is-spaced">
                        <RookieButton
                            icon="edit"
                            size="small"
                            rounded={true}
                            severity="secondary"
                            onClick={(e) =>
                                row.rowEditor?.onInitClick
                                    ? row.rowEditor.onInitClick(e)
                                    : undefined
                            }
                        />
                        <RookieButton
                            size="small"
                            rounded={true}
                            severity="secondary"
                            onClick={() => removeRow(row.rowIndex)}
                            icon="remove"
                        />
                    </div>
                </div>
            );
        }
    };

    const renderStatus = (rowData: RowData) => {
        switch (rowData && rowData.status) {
            case STATUS_LOADING:
                return '';
            case STATUS_ERROR:
                return (
                    <Chip
                        className="p-chip--error"
                        template={() => {
                            return (
                                <>
                                    <Icon name="error" />
                                    <span className="p-chip-text">Failed</span>
                                </>
                            );
                        }}
                    />
                );
            case STATUS_SUCCESS:
                return (
                    <Chip
                        className="p-chip--success"
                        template={() => {
                            return (
                                <>
                                    <Icon name="check_circle" />
                                    <span className="p-chip-text">
                                        Uploaded
                                    </span>
                                </>
                            );
                        }}
                    />
                );
            case STATUS_INVALID:
                return (
                    <Chip
                        className="p-chip--error"
                        template={() => {
                            return (
                                <>
                                    <Icon name="warning" />
                                    <span className="p-chip-text">
                                        Invalid Values
                                    </span>
                                </>
                            );
                        }}
                    />
                );
            default:
                return '';
        }
    };

    const tableEmptyState = (
        <FileUpload
            ref={uploadButton}
            chooseOptions={{
                className: `${isSubmitting && 'p-disabled'} p-button-secondary`,
                icon: <Icon name="upload_file" />,
                label: 'Import',
            }}
            mode="advanced"
            name="teamImport"
            auto
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            customUpload
            uploadHandler={handleUpload}
            emptyTemplate={() => {
                return (
                    <ErrorDisplay
                        actions={
                            isError
                                ? [
                                      {
                                          icon: 'refresh',
                                          label: 'Retry',
                                          onClick: () => navigate(0),
                                      },
                                  ]
                                : []
                        }
                        alignment="middle"
                        desc={
                            isError
                                ? 'Refresh to try the request again'
                                : 'Drag and drop or select "Import" to upload a CSV or EXCEL file.'
                        }
                        errorType={ERROR_TYPES.empty}
                        hasReturn={false}
                        proportion="compact"
                        title={isError ? 'No data returned' : `Get Started`}
                    />
                );
            }}
        />
    );

    const footerTemplate = (
        <>
            <RookieButton
                size="small"
                severity="secondary"
                disabled={isDisabled || isSubmitting}
                onClick={addRow}
                icon="add"
                label="Add Row"
            />
            {props.onLoadMore && (
                <RookieButton
                    onClick={props.onLoadMore}
                    severity="secondary"
                    size="small"
                    label="Load more"
                    icon="pending"
                />
            )}
        </>
    );

    return (
        <DataTable
            alwaysShowPaginator={false}
            className="empty-message--has-uploader"
            editMode="row"
            editingRows={editingRows}
            emptyMessage={tableEmptyState}
            footer={footerTemplate}
            header={header}
            onRowEditComplete={onRowEditComplete}
            onRowEditChange={onRowEditChange}
            paginator={props.data.length > 0}
            removableSort
            rows={50}
            rowClassName={rowClass}
            value={props.data}
            columnResizeMode="expand"
            resizableColumns
        >
            {props.data.length > 0 &&
                props.columns.map((col, index) => (
                    <Column
                        key={index}
                        body={col.body}
                        editor={(options) => cellEditor(options, col)}
                        field={col.field}
                        header={col.header}
                        style={col.style}
                        hidden={col.hidden}
                        sortable
                    />
                ))}
            {props.data.length > 0 && (
                <Column
                    body={editColumnTemplate}
                    className="actions-td"
                    rowEditor
                />
            )}
        </DataTable>
    );
};

export default ImportForm;
