import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useGetStaffDetailsQuery } from '../../api/staff';

import { Badge } from 'primereact/badge';
import { Tooltip } from 'primereact/tooltip';

import { getEntityFromParam } from '../../util/helper';

import { BaseEntityType } from '../../types/common';

const StaffBadge = () => {
    const params = useParams();
    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);

    const { data: staffData } = useGetStaffDetailsQuery(
        {
            entityType: activeEntity?.entityType as BaseEntityType,
            entityID: activeEntity?.entityID ?? '',
        },
        {
            skip: !activeEntity,
        }
    );

    const joinRequests = staffData?.data.filter((staff) =>
        staff.roles.some((role) => role.roleID.includes('Pending'))
    );

    if (joinRequests && joinRequests.length > 0) {
        return (
            <>
                <Badge
                    value={joinRequests.length}
                    className="staff-menu-badge"
                />
                <Tooltip
                    target=".staff-menu-badge"
                    content="There are join requests that require responding."
                />
            </>
        );
    }
    return null;
};

export default StaffBadge;
