import { classNames } from 'primereact/utils';

import NoteDetail from './NoteDetail';
import NoteList from './NoteList';
import PageHeader from '../../layout/PageHeader';

import { Note, NoteFormData } from '../../types/note';
import { EntityType } from '../../types/common';

interface Props {
    activeNote?: Note;
    notes: Note[];
    isLoading: boolean;
    isError: boolean;
    isFetching: boolean;
    onNodeClick: (nodeID: string, nodeType: EntityType) => void;
    onNodeDelete: (
        noteID: string,
        nodeID: string,
        nodeType: EntityType
    ) => void;
    onNoteClick: (noteID: string) => void;
    onNoteDelete: (noteID: string) => void;
    onNoteCreate: () => void;
    onNoteSave: (note: NoteFormData) => void;
    onClearSelection: () => void;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const NotesView = ({
    activeNote,
    notes,
    isLoading,
    onNodeClick,
    onNodeDelete,
    onNoteClick,
    onNoteCreate,
    onNoteSave,
    onNoteDelete,
    onClearSelection,
    permissions,
}: Props) => {
    const containerClass = classNames('notes-wrapper', {
        'has-active-note': !!activeNote,
    });

    return (
        <>
            <div className={containerClass}>
                <div className="notes-sidebar">
                    <PageHeader
                        title="Notes"
                        showBreadcrumbs={false}
                        actions={
                            permissions.canCreate
                                ? [
                                      {
                                          label: 'New',
                                          icon: 'add',
                                          onClick: onNoteCreate,
                                      },
                                  ]
                                : []
                        }
                    />
                    <NoteList
                        notes={notes}
                        activeNote={activeNote}
                        onNoteClick={onNoteClick}
                        isLoading={isLoading}
                    />
                </div>
                <NoteDetail
                    note={activeNote}
                    onSave={onNoteSave}
                    onDelete={onNoteDelete}
                    onCreate={onNoteCreate}
                    onNodeClick={onNodeClick}
                    onNodeDelete={onNodeDelete}
                    onBackClick={onClearSelection}
                    permissions={permissions}
                />
            </div>
        </>
    );
};

export default NotesView;
