import React from 'react';
import { classNames } from 'primereact/utils';

type ListItemBaseProps = {
    component?: React.ElementType;
    start?: React.ReactNode;
    end?: React.ReactNode;
    overline?: string | React.ReactNode;
    title?: string | React.ReactNode;
    caption?: string | React.ReactNode;
    supporting?: React.ReactNode;
    multiline?: boolean;
    align?: 'start' | 'center' | 'end';
    selected?: boolean;
    disabled?: boolean;
    compact?: boolean;
    relaxed?: boolean;
    disableGutters?: boolean;
    disablePadding?: boolean;
    divider?: boolean;
    onClick?: React.MouseEventHandler<HTMLElement>;
};

// Infer extra props from the component passed in `component`
type ListItemProps<T extends React.ElementType> = ListItemBaseProps &
    Omit<React.ComponentPropsWithoutRef<T>, keyof ListItemBaseProps> & {
        component?: T;
    };

const ListItem = <T extends React.ElementType = 'div'>({
    component,
    onClick,
    className,
    multiline,
    disablePadding,
    disableGutters,
    divider,
    disabled,
    selected,
    relaxed,
    compact,
    start,
    end,
    supporting,
    title,
    caption,
    overline,
    ...props
}: ListItemProps<T>) => {
    const Component = component || ('div' as T);

    const componentClass = classNames('list-item', className);

    const containerClass = classNames('list-item-container', {
        'is-multiline': multiline,
        'is-compact': compact,
        'has-no-padding': disablePadding,
        'has-no-gutters': disableGutters,
        'has-divider': divider,
        'is-disabled': disabled,
        'is-selected': selected,
        'is-relaxed': relaxed,
    });

    return (
        <Component
            className={componentClass}
            onClick={onClick}
            {...(props as any)}
        >
            <div className={containerClass}>
                {start && <div className="list-item-start">{start}</div>}
                <div className="list-item-text">
                    {overline && (
                        <div className="list-item-overline">{overline}</div>
                    )}
                    {title && <div className="list-item-title">{title}</div>}
                    {caption && (
                        <div className="list-item-caption">{caption}</div>
                    )}
                </div>
                {supporting && (
                    <div className="list-item-support">{supporting}</div>
                )}
                {end && <div className="list-item-end">{end}</div>}
            </div>
        </Component>
    );
};

export default ListItem;
