import { useMemo, useRef } from 'react';
import { format } from 'date-fns';

import { getFirstChars } from '../../util/helper';

import PageHeader from '../../layout/PageHeader';
import PageContainer from '../../layout/PageContainer';
import ErrorDisplay from '../../components/ErrorDisplay';
import RookieButton from '../../components/RookieButton';

import {
    Column,
    ColumnBodyOptions,
    ColumnEditorOptions,
    ColumnEvent,
    ColumnProps,
} from 'primereact/column';
import { Avatar } from 'primereact/avatar';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';
import { Calendar } from 'primereact/calendar';

import { ERROR_TYPES } from '../../types/common';
import { Player } from '../../types/team';
import { Roles } from '../../types/roles';
import Icon from '../../components/Icon';

interface Props {
    data: Player[];
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
    isSaving: boolean;
    onSubmit?: Function;
    onCellEditComplete: (e: ColumnEvent) => void;
    onClearThresholds: (playerID: string) => void;
    roles: Roles;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const PlayerThresholdsView = (props: Props) => {
    const {
        data,
        isError,
        isFetching,
        isLoading,
        isSaving,
        onCellEditComplete,
        onClearThresholds,
        permissions,
    } = props;

    const dataTable = useRef<any>(null);

    const isCellDisabled = (field: string, rowData: any) => {
        const idx = Number(field?.slice(-1)) - 1;

        return idx !== 0 && rowData.thresholds?.length < idx;
    };

    const tableData = useMemo(() => {
        return data.map((player) => {
            let result: any = {
                ...player,
            };

            if (player.thresholds) {
                player.thresholds.forEach((threshold, i) => {
                    result[`threshold_${i + 1}`] = threshold;
                });
            }

            return result;
        });
    }, [data]);

    const playerCell = (row: Player) => {
        return (
            <div className="detail-cell">
                <Avatar
                    label={getFirstChars(row.displayName, 2).toUpperCase()}
                    image={row.playerImageURL}
                    style={{ background: '#4A4A4D', color: '#ffffff' }}
                    shape="circle"
                />
                <span>
                    {row.firstName} {row.lastName}
                </span>
            </div>
        );
    };

    const thresholdCell = (data: any, options: ColumnBodyOptions) => {
        const isDisabled = isCellDisabled(options?.field, data);

        if (isDisabled) return null;

        const currIndex = Number(options?.field?.slice(-1)) - 1;
        const threshold = data.thresholds[currIndex];

        if (threshold) {
            return (
                <div className="threshold-list">
                    <div className="threshold threshold--active">
                        <Icon
                            className="threshold-icon"
                            name="line_end_arrow"
                            fill
                        />
                        <span className="threshold-value">
                            {format(threshold.active, 'mm:ss')}
                        </span>
                    </div>
                    <div className="threshold threshold--rest">
                        <Icon
                            className="threshold-icon"
                            name="line_start_arrow"
                            fill
                        />
                        <span className="threshold-value">
                            {format(threshold.rest, 'mm:ss')}
                        </span>
                    </div>
                </div>
            );
        } else if (permissions.canEdit) {
            return (
                <RookieButton
                    link
                    icon="add"
                    size="small"
                    tooltip="Set threshold"
                />
            );
        }
    };

    const editorCell = (options: ColumnEditorOptions) => {
        const defaultDate = new Date();
        defaultDate.setHours(0, 0, 0, 0);

        return (
            <div className="threshold-inputs">
                <label className="p-inputgroup threshold threshold--active">
                    <span className="p-inputgroup-addon">
                        <Icon
                            className="threshold-icon"
                            name="line_end_arrow"
                            fill
                        />
                    </span>
                    <Calendar
                        panelClassName="hide-hours"
                        value={
                            options.value?.active
                                ? new Date(options.value.active)
                                : defaultDate
                        }
                        onChange={(e) => {
                            options.editorCallback &&
                                options.editorCallback({
                                    ...options.value,
                                    active: e.target.value?.getTime(),
                                });
                        }}
                        timeOnly={true}
                        showSeconds={true}
                        stepHour={0}
                        stepSecond={10}
                        formatDateTime={(date) => format(date, 'mm:ss')}
                        autoFocus={true}
                        tooltip="Active"
                    />
                </label>

                <label className="p-inputgroup threshold threshold--rest">
                    <span className="p-inputgroup-addon">
                        <Icon
                            className="threshold-icon"
                            name="line_start_arrow"
                            fill
                        />
                    </span>
                    <Calendar
                        panelClassName="hide-hours"
                        value={
                            options.value?.rest
                                ? new Date(options.value.rest)
                                : defaultDate
                        }
                        onChange={(e) => {
                            options.editorCallback &&
                                options.editorCallback({
                                    ...options.value,
                                    rest: e.target.value?.getTime(),
                                });
                        }}
                        timeOnly={true}
                        showSeconds={true}
                        stepHour={0}
                        stepSecond={10}
                        formatDateTime={(date) => format(date, 'mm:ss')}
                        autoFocus={false}
                        tooltip="Rest"
                    />
                </label>
            </div>
        );
    };

    const columnSchema: ColumnProps[] = [
        {
            field: 'uniformNumber',
            header: '#',
            style: { width: '60px' },
            align: 'center',
            sortable: true,
            alignHeader: 'right',
            body: isLoading ? <Skeleton /> : null,
        },
        {
            field: 'firstName',
            header: 'Name',
            sortable: true,
            body: isLoading ? <Skeleton /> : playerCell,
        },
        {
            field: 'threshold_1',
            header: 'Threshold 1',
            align: 'center',
            alignHeader: 'center',
            bodyClassName: (data, options) =>
                isCellDisabled(options?.field, data) ? 'is-cell-disabled' : '',
            editor: (options) => editorCell(options),
            onBeforeCellEditShow: (options) =>
                !isCellDisabled(options?.field, options.rowData),
            onCellEditComplete: onCellEditComplete,
            body: isLoading ? <Skeleton /> : thresholdCell,
        },
        {
            field: 'threshold_2',
            header: 'Threshold 2',
            align: 'center',
            alignHeader: 'center',
            bodyClassName: (data, options) =>
                isCellDisabled(options?.field, data) ? 'is-cell-disabled' : '',
            editor: (options) => editorCell(options),
            onBeforeCellEditShow: (options) =>
                !isCellDisabled(options?.field, options.rowData),
            onCellEditComplete: onCellEditComplete,
            body: isLoading ? <Skeleton /> : thresholdCell,
        },
        {
            field: 'threshold_3',
            header: 'Threshold 3',
            align: 'center',
            alignHeader: 'center',
            bodyClassName: (data, options) =>
                isCellDisabled(options?.field, data) ? 'is-cell-disabled' : '',
            editor: (options) => editorCell(options),
            onBeforeCellEditShow: (options) =>
                !isCellDisabled(options?.field, options.rowData),
            onCellEditComplete: onCellEditComplete,
            body: isLoading ? <Skeleton /> : thresholdCell,
        },
        ...(permissions.canEdit
            ? [
                  {
                      body: (data: any) => (
                          <RookieButton
                              onClick={() => onClearThresholds(data.playerID)}
                              tooltip="Clear"
                              icon="clear"
                              tooltipOptions={{ position: 'top' }}
                              size="small"
                              severity="secondary"
                          />
                      ),
                      style: {
                          width: '40px',
                      },
                  },
              ]
            : []),
    ];

    const columns = columnSchema.map((col: ColumnProps) => {
        return <Column key={col.field} {...col} />;
    });

    const tableEmptyMessage = (
        <ErrorDisplay
            alignment="middle"
            errorType={ERROR_TYPES.notFound}
            hasReturn={false}
            proportion="compact"
            title={isError ? 'No data returned' : `No players found`}
            desc={isError ? 'Refresh to try the request again' : undefined}
        />
    );

    return (
        <PageContainer>
            <PageHeader
                title="Player Thresholds"
                beaconArticle="67b25be877c4b941c525f78a"
            />

            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <DataTable
                    ref={dataTable}
                    value={isLoading ? Array(5).fill(0) : tableData}
                    loading={(isFetching || isSaving) && !isLoading}
                    emptyMessage={tableEmptyMessage}
                    columnResizeMode="expand"
                    editMode={permissions.canEdit ? 'cell' : undefined}
                    showGridlines={true}
                    className={!permissions.canEdit ? 'read-only' : ''}
                >
                    {isLoading || tableData.length > 0 ? columns : null}
                </DataTable>
            )}
        </PageContainer>
    );
};

export default PlayerThresholdsView;
