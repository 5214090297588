import { useParams } from 'react-router-dom';
import { Skeleton } from 'primereact/skeleton';
import { useGetSubscriptionQuery } from '../../api/licences';

const SubscriptionBreadcrumb = () => {
    const { userID, subscriptionID } = useParams();

    const { data, isLoading, isError } = useGetSubscriptionQuery({
        userID,
        subscriptionID,
    });

    if (isLoading) return <Skeleton width="4rem" height="1rem" />;

    if (isError) return '';

    return data?.data?.metadata?.entityName;
};
export default SubscriptionBreadcrumb;
