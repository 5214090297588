import { useMemo, useRef } from 'react';

import { productDetails } from '../../util/constants';

import RookieButton from '../../components/RookieButton';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import { Tag } from 'primereact/tag';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { confirmDialog } from 'primereact/confirmdialog';

import { Licence, LicenceGroupInfo } from '../../types/licences';
import { Message } from 'primereact/message';
import Icon from '../../components/Icon';
import ProductTag from '../../components/ProductTag';

interface Props {
    onUnassignLicence: (licence: string) => void;
    onChangePlan: (
        rookieProduct: string,
        licenceGroup?: LicenceGroupInfo
    ) => void;
    rookieProduct: string;
    licence?: Licence;
    licenceGroup?: LicenceGroupInfo;
    hasAvailableLicence: boolean;
    inAppPurchase: boolean;
    createdBy?: string;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const ProductLicenceCard = (props: Props) => {
    const {
        rookieProduct,
        licence,
        licenceGroup,
        permissions,
        onUnassignLicence,
        onChangePlan,
        hasAvailableLicence,
        inAppPurchase,
        createdBy,
    } = props;

    const moreMenu = useRef<Menu>(null);

    const product = productDetails[rookieProduct];

    const isComingSoon = rookieProduct === 'coach';

    const licenceName =
        rookieProduct === 'coach'
            ? 'Coming Soon'
            : licence?.licenceTypeDetails.licenceName || 'No Plan';

    const moreMenuOptions: MenuItem[] = useMemo(() => {
        let options: MenuItem[] = [];

        if (!product) {
            return options;
        }

        if (product.salesUrl) {
            options.push({
                label: 'Learn More',
                command: () => {
                    window.open(product.salesUrl, '_newtab');
                },
            });
        }

        if (product.helpUrl) {
            options.push({
                label: 'Product Help',
                command: () => {
                    window.open(product.helpUrl, '_newtab');
                },
            });
        }

        if (licence && permissions.canEdit) {
            options.push({
                label: 'Unassign Licence',
                command: () => {
                    confirmDialog({
                        message: `Are you sure you want to unassign this licence? By doing so you will not be able to use this team until you assign either the same or a different licence.`,
                        header: 'Unassign Licence',
                        accept: () => {
                            licence?.licenceID &&
                                onUnassignLicence(licence.licenceID);
                        },
                    });
                },
            });
        }

        return options;
    }, [onUnassignLicence, permissions.canEdit, licence, product]);

    const renderCardBody = () => {
        if (isComingSoon) {
            return (
                <p>
                    We're adding Coach licenses to Hub soon! In the meantime, if
                    you need to manage your Rookie Me Coach licence, head over
                    to coach.rookieme.com.
                </p>
            );
        }

        if (!licence && hasAvailableLicence) {
            return (
                <Message
                    severity="secondary"
                    icon={<Icon name="info" style={{ marginRight: '10px' }} />}
                    text="You have a licence available to use."
                />
            );
        }

        if (licence) {
            return (
                <Message
                    severity="secondary"
                    icon={
                        <Icon name={'person'} style={{ marginRight: '10px' }} />
                    }
                    text={createdBy}
                />
            );
        }

        return (
            <Message
                severity="secondary"
                icon={<Icon name="info" style={{ marginRight: '10px' }} />}
                text="No Plans found"
            />
        );
    };

    return (
        <>
            <Card
                className={`app-card ${isComingSoon ? 'app-card-cs' : ''}`}
                title={
                    <>
                        <div className="app-card-title-start">
                            <div className="app-card-title-logo">
                                <Image
                                    className="app-logo-img app-logo-brandmark"
                                    src={product.logomark}
                                    height="48px"
                                    alt={`Rookie Me ${product.title}`}
                                />
                            </div>
                            <div className="app-card-title-content">
                                <h4 className="app-card-title-heading">
                                    {product.title}
                                </h4>
                                <p className="app-card-title-caption">
                                    {product.platform}
                                </p>
                            </div>
                        </div>
                        <div className="app-card-title-end">
                            <Tag
                                className={`app-card-title-badge ${
                                    !licence && !isComingSoon
                                        ? 'p-tag-outlined'
                                        : ''
                                }`}
                                severity={licence ? 'info' : 'secondary'}
                            >
                                {licenceName}
                            </Tag>
                            {inAppPurchase && (
                                <ProductTag
                                    title="In-App"
                                    icon={
                                        <Icon name="smartphone" size="small" />
                                    }
                                />
                            )}
                        </div>
                    </>
                }
                footer={
                    <>
                        {permissions.canEdit && !isComingSoon && (
                            <RookieButton
                                label={licence ? 'Change Plan' : 'Add Plan'}
                                outlined
                                onClick={() =>
                                    onChangePlan(rookieProduct, licenceGroup)
                                }
                            />
                        )}

                        <RookieButton
                            icon="more_horiz"
                            text
                            onClick={(e) => {
                                moreMenu?.current?.toggle(e);
                            }}
                        />
                    </>
                }
            >
                <>{renderCardBody()}</>
            </Card>
            <Menu
                ref={moreMenu}
                model={moreMenuOptions}
                popup
                popupAlignment="right"
            />
        </>
    );
};

export default ProductLicenceCard;
