import { format } from 'date-fns';

import { StaffTabProps } from './StaffView';
import ErrorDisplay from '../../components/ErrorDisplay';
import RookieButton from '../../components/RookieButton';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';

import { ERROR_TYPES } from '../../types/common';

const StaffPendingView = (props: StaffTabProps) => {
    const {
        invites: { data: inviteData, isError, isFetching, isLoading },
        isUpdatingUser,
        permissions,
        onResendTicket,
        onRevokeTicket,
        onShowInviteModal,
    } = props;

    const emptyMessage = (
        <ErrorDisplay
            actions={[
                {
                    label: 'Invite Staff Member',
                    onClick: () => onShowInviteModal(),
                    severity: 'secondary',
                    icon: 'add',
                },
            ]}
            alignment="middle"
            errorType={ERROR_TYPES.inboxEmpty}
            hasReturn={false}
            proportion="compact"
            title={isError ? 'An error has occurred' : 'No Pending Invitations'}
            desc={
                isError
                    ? 'Refresh to try the request again'
                    : "You haven't invited any staff members yet."
            }
        />
    );

    const columnSchema: ColumnProps[] = [
        {
            field: 'metadata.target',
            header: 'Email',
        },
        {
            body: isLoading ? (
                <Skeleton />
            ) : (
                (row) => {
                    return format(new Date(row.lastEdited), 'dd/MM/yyyy p');
                }
            ),
            field: 'lastEdited',
            header: 'Last Sent',
        },
        {
            field: 'actions',
            header: '',
            align: 'right',
            body: isLoading ? (
                <Skeleton />
            ) : (
                (row) => {
                    return (
                        <div
                            className="p-button-group"
                            style={{ justifyContent: 'flex-end' }}
                        >
                            {permissions.canCreate && (
                                <RookieButton
                                    label="Resend Invite"
                                    icon="send"
                                    onClick={() => {
                                        onResendTicket(row);
                                    }}
                                    size="small"
                                    severity="secondary"
                                />
                            )}

                            {permissions.canDelete && (
                                <RookieButton
                                    label="Revoke Invite"
                                    icon="close"
                                    onClick={() => {
                                        onRevokeTicket(row);
                                    }}
                                    size="small"
                                    severity="secondary"
                                />
                            )}
                        </div>
                    );
                }
            ),
        },
    ];

    const columns = columnSchema.map((col: ColumnProps) => {
        return <Column key={col.field} {...col} />;
    });

    return (
        <DataTable
            value={isLoading ? Array(5).fill(0) : inviteData?.data}
            loading={isFetching && !isLoading}
            emptyMessage={emptyMessage}
            rowClassName={(row) =>
                !!isUpdatingUser && isUpdatingUser === row.userID
                    ? 'is-loading'
                    : ''
            }
        >
            {isLoading
                ? columns
                : inviteData?.data && inviteData.data.length > 0
                ? columns
                : null}
        </DataTable>
    );
};

export default StaffPendingView;
