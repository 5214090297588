import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useLazyGetSeasonEventsQuery } from '../../../api/events';
import { toISOStringWithTimezone } from '../../../util/helper';
import { isEmpty } from 'lodash';

import Loader from '../../../components/Loader';
import { MultiSelect, MultiSelectProps } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';

import { BaseEntityType } from '../../../types/common';
import { Event } from '../../../types/event';

interface Props extends MultiSelectProps {
    teamID?: string;
    seasonID?: string;
    initialOptions?: Event[];
    multiselect?: boolean;
    reportType?: string;
}

const EventSelect = ({
    teamID,
    seasonID,
    initialOptions,
    multiselect,
    reportType,
    ...dropdownProps
}: Props) => {
    const [fetchEvents, { data, isUninitialized, isLoading, isFetching }] =
        useLazyGetSeasonEventsQuery();

    // To prevent pagination caching issues
    const now = useRef(new Date()).current;

    const loadEvents = useCallback(
        (cursor: string) => {
            if (teamID && seasonID) {
                fetchEvents({
                    entityType: BaseEntityType.teams,
                    entityID: teamID,
                    seasonID: seasonID,
                    cursor: cursor,
                    to: toISOStringWithTimezone(now),
                })
                    .then((response) => {
                        if (response.data) {
                            // Auto paginate teams
                            if (
                                response.data.lastEvaluatedKey.cursor &&
                                response.data.lastEvaluatedKey.cursor !== cursor
                            ) {
                                loadEvents(
                                    response.data.lastEvaluatedKey.cursor
                                );
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(
                            `Error fetching events for team ${teamID}, season ${seasonID}:`,
                            error
                        );
                    });
            }
        },
        [teamID, seasonID, fetchEvents, now]
    );

    const handleDropdownOpen = () => {
        if (isUninitialized) {
            loadEvents('');
        }
    };

    const options = useMemo(() => {
        const events =
            isUninitialized && initialOptions ? initialOptions : data?.data;

        if (reportType) {
            // Filter events where eventType matches reportType
            return (events || [])
                .filter((event) => event.eventType === reportType)
                .map((event) => ({
                    label: event.eventName,
                    value: event.eventID,
                }));
        }

        return (events || []).map((event) => ({
            label: event.eventName,
            value: event.eventID,
        }));
    }, [data, initialOptions, isUninitialized, reportType]);

    useEffect(() => {
        // Load events if there's an event selected with not initial options
        if (
            isUninitialized &&
            !isEmpty(dropdownProps.value) &&
            !initialOptions
        ) {
            loadEvents('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUninitialized, loadEvents]);

    const Component = (
        multiselect ? MultiSelect : Dropdown
    ) as React.ElementType;

    const loading = isLoading || isFetching;

    return (
        <Component
            {...dropdownProps}
            onShow={handleDropdownOpen}
            loading={loading}
            options={options}
            emptyMessage={loading && <Loader />}
        />
    );
};

export default EventSelect;
