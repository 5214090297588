import { useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { productDetails } from '../../util/constants';

import UpdateLicenceForm from './UpdateLicenceForm';
import TransferLicencesForm from './TransferLicencesForm';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressBar } from 'primereact/progressbar';
import { Skeleton } from 'primereact/skeleton';
import { Sidebar } from 'primereact/sidebar';
import { Badge } from 'primereact/badge';
import { Image } from 'primereact/image';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';

import RookieButton from '../../components/RookieButton';
import PageContainer from '../../layout/PageContainer';
import PageHeader from '../../layout/PageHeader';

import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { User } from '../../types/user';
import { LicenceGroupInfo } from '../../types/licences';
import { BaseEntityType } from '../../types/common';
import AddLicencesForm from './AddLicencesForm';

interface Props {
    roles: Roles;
    route: Route;
    user: User;
    licenceGroups: LicenceGroupInfo[];
    isLoading: boolean;
    onTransferLicences?: (
        licenceGroupID: string,
        organisationID: string
    ) => void;
    showAddModal: boolean;
    showAssignModal: boolean;
    showEditModal: boolean;
    selectedLicenceGroup?: LicenceGroupInfo;
    onCloseAddModal: () => void;
    onCloseAssignModal: () => void;
    onCloseEditModal: () => void;
    onLicenceGroupClick: (licenceGroup: LicenceGroupInfo) => void;
    onShowAddModal: () => void;
    onShowAssignModal: (licenceGroup: LicenceGroupInfo) => void;
    onShowEditModal: (licenceGroup: LicenceGroupInfo) => void;
    setSelectedLicenceGroup: (licenceGroup: LicenceGroupInfo) => void;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const AssociationLicencesView = (props: Props) => {
    const {
        selectedLicenceGroup,
        licenceGroups,
        onTransferLicences,
        showAddModal,
        showAssignModal,
        showEditModal,
        onShowAddModal,
        onShowAssignModal,
        onShowEditModal,
        onCloseAddModal,
        onCloseAssignModal,
        onCloseEditModal,
        onLicenceGroupClick,
        setSelectedLicenceGroup,
        isLoading,
        permissions,
    } = props;

    const { associationID } = useParams();
    const navigate = useNavigate();

    const rowMenu = useRef<Menu>(null);

    const menuOptions: MenuItem[] = useMemo(() => {
        let options: MenuItem[] = [];

        if (!selectedLicenceGroup) {
            return options;
        }

        if (permissions.canView) {
            options.push({
                label: 'View',
                command: () => onLicenceGroupClick(selectedLicenceGroup),
            });
        }

        if (
            ['Active', 'Invalid'].includes(selectedLicenceGroup.status) &&
            selectedLicenceGroup.subscriptionID &&
            selectedLicenceGroup.subscriptionID !== 'NA' &&
            selectedLicenceGroup.isOwner &&
            permissions.canEdit
        ) {
            options.push({
                label: 'Manage',
                command: () =>
                    navigate(
                        `/u/${selectedLicenceGroup.owner}/subscriptions/${selectedLicenceGroup.subscriptionID}`
                    ),
            });
        }

        if (permissions.canEdit) {
            options.push({
                label: 'Change Plan',
                command: () => onShowEditModal(selectedLicenceGroup),
            });
        }

        return options;
    }, [
        navigate,
        onLicenceGroupClick,
        permissions,
        selectedLicenceGroup,
        onShowEditModal,
    ]);

    return (
        <PageContainer>
            <PageHeader
                title="Licences"
                actions={[
                    {
                        label: 'Add Licences',
                        onClick: () => {
                            onShowAddModal();
                        },
                    },
                ]}
            />
            <DataTable
                value={isLoading ? Array(3).fill(0) : licenceGroups}
                onRowSelect={(e) =>
                    permissions.canView && onLicenceGroupClick(e.data)
                }
                selectionMode={permissions.canView ? 'single' : undefined}
            >
                <Column
                    field="rookieProduct"
                    header="Product"
                    body={(data) => {
                        if (isLoading) return <Skeleton />;

                        const product = productDetails[data.rookieProduct];

                        return (
                            <div className="product-cell">
                                <Image
                                    className="product-cell-logo"
                                    src={product.logomark}
                                    width="40"
                                    height="40"
                                />
                                <span className="product-cell-title">
                                    {product.title}
                                </span>
                            </div>
                        );
                    }}
                />
                <Column
                    field="licenceTitle"
                    header="Plan"
                    body={isLoading && <Skeleton />}
                />
                <Column
                    field="billingPeriod" // This should match the actual key in your data object
                    header="Billing"
                    body={(rowData) => {
                        if (isLoading) {
                            return <Skeleton />;
                        }
                        // Replace 'licenceTitle' with the actual key you need to access the data
                        return rowData.billingPeriod === 'year'
                            ? 'Annual'
                            : 'Monthly';
                    }}
                />
                <Column
                    field="qtyTotal"
                    header="Licences"
                    body={(data) =>
                        isLoading ? (
                            <Skeleton />
                        ) : (
                            <Badge value={data.qtyTotal} severity="secondary" />
                        )
                    }
                />
                <Column
                    field="qtyAssigned"
                    header="Used"
                    body={(data) =>
                        isLoading ? (
                            <Skeleton />
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                }}
                            >
                                <ProgressBar
                                    color="var(--info)"
                                    style={{ flex: '1', height: '8px' }}
                                    showValue={false}
                                    value={
                                        (data.qtyAssigned / data.qtyTotal) * 100
                                    }
                                />
                                <span style={{ letterSpacing: '0.1rem' }}>
                                    {data.qtyAssigned}/{data.qtyTotal}
                                </span>
                            </div>
                        )
                    }
                />
                <Column
                    align="right"
                    body={(data) => {
                        if (isLoading) {
                            return <Skeleton />;
                        } else {
                            const canAssign =
                                permissions.canEdit &&
                                data.qtyTotal !== data.qtyAssigned;
                            return (
                                <div
                                    className="p-button-group"
                                    style={{ justifyContent: 'flex-end' }}
                                >
                                    {canAssign && (
                                        <RookieButton
                                            label="Transfer"
                                            onClick={() =>
                                                onShowAssignModal(data)
                                            }
                                            severity="secondary"
                                        />
                                    )}

                                    <RookieButton
                                        icon="more_horiz"
                                        text
                                        onClick={(e) => {
                                            setSelectedLicenceGroup(data);
                                            rowMenu.current?.show(e);
                                        }}
                                    />
                                </div>
                            );
                        }
                    }}
                    style={{ textAlign: 'right' }}
                />
            </DataTable>
            <Sidebar
                header="Add Licences"
                onHide={() => onCloseAddModal()}
                visible={showAddModal}
                position="right"
            >
                {associationID && (
                    <AddLicencesForm
                        entityType={BaseEntityType.associations}
                        entityID={associationID}
                        onCancel={() => onCloseAssignModal()}
                    />
                )}
            </Sidebar>
            <Sidebar
                header="Transfer Licence"
                onHide={() => onCloseAssignModal()}
                visible={showAssignModal}
                position="right"
            >
                {selectedLicenceGroup && associationID && (
                    <TransferLicencesForm
                        licenceGroupID={selectedLicenceGroup.id}
                        associationID={associationID}
                        onCancel={() => onCloseAssignModal()}
                        onSubmit={onTransferLicences}
                    />
                )}
            </Sidebar>
            <Sidebar
                onHide={() => onCloseEditModal()}
                visible={showEditModal}
                position="right"
                className="plan-sidebar"
                content={
                    selectedLicenceGroup && (
                        <UpdateLicenceForm
                            licenceGroup={selectedLicenceGroup}
                            onCancel={onCloseEditModal}
                            onSuccess={() => {
                                window.location.reload();
                            }}
                        />
                    )
                }
            />
            <Menu
                ref={rowMenu}
                model={menuOptions}
                popup
                popupAlignment="right"
            />
        </PageContainer>
    );
};

export default AssociationLicencesView;
