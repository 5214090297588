import { format } from 'date-fns';
import { orderBy } from 'lodash';

import { Column, ColumnProps } from 'primereact/column';
import { DataTable, DataTableValue } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { Skeleton } from 'primereact/skeleton';
import { Tag } from 'primereact/tag';

import ErrorDisplay from '../../components/ErrorDisplay';
import Icon from '../../components/Icon';

import { BaseEntityType, ERROR_TYPES } from '../../types/common';
import { useRef } from 'react';
import { Event, EventParams, EventType } from '../../types/event';
import { useGetTeamQuery } from '../../api/teams';
import { useParams } from 'react-router-dom';
import EntityAvatar from '../../components/EntityAvatar';
import RookieButton from '../../components/RookieButton';

const statusColour = {
    Draft: 'transparent',
    Published: 'transparent',
    Live: '#10B981',
    Complete: '#101010',
};

interface Props {
    data: Event[];
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
    onClickEvent: (event: DataTableValue) => void;
    onCloseEventDialog: () => void;
    onCreateEvent: () => void;
    onDeleteEvent: (event: Event) => void;
    onEditEvent: (event: Event) => void;
    onLoadMore: () => void;
    onSelectEvent: (event: Event | null) => void;
    onSetFilters?: React.Dispatch<React.SetStateAction<EventParams>>;
    selectedEvent: Event | null;
    showEventDialog: boolean;
    showPagination: boolean;
    activeTab?: any;
    orderBy?: 'asc' | 'desc';
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const EventsListing = (props: Props) => {
    const { teamID } = useParams();
    const menu = useRef<Menu>(null);
    const blankRows = Array(5);

    const { data } = useGetTeamQuery(
        { teamID: teamID || '' },
        {
            skip: !teamID,
        }
    );

    const renderTeamScore = (event: Event) => {
        return (
            <div className="event-score">
                <div className="event-score-label">
                    <EntityAvatar
                        entityName={data?.data.teamName || ''}
                        entityID={teamID}
                        entityType={BaseEntityType.teams}
                        avatarProps={{
                            style: { width: '28px', height: '28px' },
                        }}
                    />
                    {data?.data?.shortName}
                </div>
                <div className="event-score-value">
                    {event?.gameDetails?.gameSummaryReport?.gameSummary
                        ?.teamScore === 0 &&
                    event?.gameDetails?.gameSummaryReport?.gameSummary
                        ?.oppositionScore === 0
                        ? '-'
                        : event?.gameDetails?.gameSummaryReport?.gameSummary
                              ?.teamScore}
                </div>
            </div>
        );
    };

    const renderOpponentScore = (event: Event) => {
        return (
            <div className="event-score">
                <div className="event-score-label">
                    <EntityAvatar
                        entityName={
                            event?.gameDetails?.opponentTeam?.teamName || ''
                        }
                        avatarProps={{
                            style: { width: '28px', height: '28px' },
                        }}
                    />
                    {event?.gameDetails?.opponentTeam?.shortName}
                </div>
                <div className="event-score-value">
                    {event?.gameDetails?.gameSummaryReport?.gameSummary
                        ?.teamScore === 0 &&
                    event?.gameDetails?.gameSummaryReport?.gameSummary
                        ?.oppositionScore === 0
                        ? '-'
                        : event?.gameDetails?.gameSummaryReport?.gameSummary
                              ?.oppositionScore}
                </div>
            </div>
        );
    };

    const emptyTemplate = (
        <ErrorDisplay
            alignment="middle"
            desc={`${props.activeTab.title} events will appear here.`}
            errorType={ERROR_TYPES.empty}
            hasReturn={false}
            proportion="compact"
            title="No Events"
        />
    );

    const footerTemplate = () => {
        return (
            <RookieButton
                severity="secondary"
                onClick={props.onLoadMore}
                label="Load more"
                icon="pending"
            />
        );
    };

    const actionTemplate = (rowData: Event) => {
        return (
            <div style={{ textAlign: 'right' }}>
                <RookieButton
                    type="button"
                    text={true}
                    icon="more_horiz"
                    onClick={(event) => {
                        props.onSelectEvent(rowData);
                        menu?.current && menu.current.toggle(event);
                    }}
                />
            </div>
        );
    };

    const rowMenuItems = [];

    if (props.permissions.canEdit) {
        rowMenuItems.push({
            command: () => {
                props.selectedEvent && props.onEditEvent(props.selectedEvent);
            },
            label: 'Edit',
            icon: <Icon name="edit" className="p-menuitem-icon" />,
        });
    }

    if (props.permissions.canDelete) {
        rowMenuItems.push({
            command: () =>
                props.selectedEvent && props.onDeleteEvent(props.selectedEvent),
            label: 'Delete',
            icon: <Icon name="delete" className="p-menuitem-icon" />,
        });
    }

    const columnSchema: ColumnProps[] = [
        {
            field: 'date',
            header: 'Date',
            style: { width: '80px' },
            body: (data) =>
                props.isLoading ? (
                    <Skeleton />
                ) : (
                    <div className="event-date-box">
                        <p>{format(new Date(data.startDateTime), 'dd')}</p>
                        <p>{format(new Date(data.startDateTime), 'MMM')}</p>
                    </div>
                ),
        },
        {
            field: 'eventName',
            header: 'Name',
            body: (data) =>
                props.isLoading ? (
                    <Skeleton />
                ) : (
                    <div>
                        <h3>{data.eventName}</h3>
                        {data.eventType === 'Game' && (
                            <>
                                <div>
                                    <small>
                                        Type:{' '}
                                        {data.gameDetails.isHomeTeam
                                            ? 'Home '
                                            : 'Away '}
                                        Game
                                    </small>
                                </div>
                                <div>
                                    <small>
                                        Opponent:{' '}
                                        {data.gameDetails.opponentTeam
                                            .teamName === ''
                                            ? 'Not provided'
                                            : data.gameDetails.opponentTeam
                                                  .teamName}
                                    </small>
                                </div>
                            </>
                        )}
                        {data.eventType === 'Training' && (
                            <div>
                                <small>Type: Training</small>
                            </div>
                        )}
                        {data.eventType === 'Other' && (
                            <div>
                                <small>Type: Other</small>
                            </div>
                        )}
                        {(data.location?.locationExtraInfo ||
                            data.location?.locationName) && (
                            <div>
                                <small>
                                    Location:{' '}
                                    {data.location?.locationExtraInfo &&
                                    data.location.locationName !== ''
                                        ? `${data.location.locationName} (${data.location.locationExtraInfo}), ${data.location.formattedAddress}`
                                        : data.location?.locationExtraInfo &&
                                          data.location.locationName === ''
                                        ? `${data.location.locationExtraInfo}`
                                        : data.location.locationName !== ''
                                        ? `${data.location.locationName}, ${data.location.formattedAddress}`
                                        : ''}
                                </small>
                            </div>
                        )}

                        {data.description !== '' && (
                            <div>
                                <small>Description: {data.description}</small>
                            </div>
                        )}
                    </div>
                ),
        },

        {
            field: 'startDate',
            header: 'Date',
            body: props.isLoading ? (
                <Skeleton />
            ) : (
                (row: Event) => (
                    <div>
                        <div>
                            {format(new Date(row.startDateTime), 'eee, MMM do')}
                        </div>
                        <div>
                            {format(new Date(row.startDateTime), 'h:mm aaa')}
                        </div>
                    </div>
                )
            ),
        },
        {
            field: 'score',
            header: 'Score',
            body: props.isLoading ? (
                <Skeleton />
            ) : (
                (row: Event) =>
                    row.eventType === EventType.Game ? (
                        <div className="event-scores">
                            {row.gameDetails?.isHomeTeam
                                ? renderTeamScore(row)
                                : renderOpponentScore(row)}
                            {row.gameDetails?.isHomeTeam
                                ? renderOpponentScore(row)
                                : renderTeamScore(row)}
                        </div>
                    ) : row.eventType === EventType.Training ||
                      row.eventType === EventType.Other ? (
                        'N/A'
                    ) : null
            ),
        },
        {
            field: 'eventStatus',
            header: 'Status',
            style: { width: '100px' },
            body: props.isLoading ? (
                <Skeleton />
            ) : (
                (row: Event) => {
                    const reportStatus =
                        row.gameDetails?.gameSummaryReport?.reportStatus;
                    return row.eventType === EventType.Game ||
                        row.eventType === EventType.Training ||
                        row.eventType === EventType.Other ? (
                        <>
                            <Tag
                                style={{
                                    color:
                                        row.eventStatus === 'Published' ||
                                        row.eventStatus === 'Live' ||
                                        row.eventStatus === 'Draft'
                                            ? '#000'
                                            : '#fff',
                                    border: '1px solid rgba(0,0,0,0.3)',
                                    background:
                                        statusColour[
                                            row.eventStatus as keyof typeof statusColour
                                        ],
                                }}
                            >
                                {(row.eventType === 'Game' &&
                                    row.eventStatus === 'Published') ||
                                row.eventStatus === 'Live' ||
                                row.eventStatus === 'Draft' ? (
                                    'Pre-game'
                                ) : (
                                    <>
                                        {row.eventStatus}&nbsp;
                                        <Icon
                                            size="small"
                                            fill
                                            name={
                                                reportStatus === 'Built'
                                                    ? 'check_circle'
                                                    : reportStatus?.startsWith(
                                                          'Error'
                                                      )
                                                    ? 'warning'
                                                    : reportStatus ===
                                                          'Started' ||
                                                      reportStatus ===
                                                          'Building'
                                                    ? 'change_circle'
                                                    : ''
                                            }
                                        />
                                    </>
                                )}
                            </Tag>
                        </>
                    ) : null;
                }
            ),
        },
    ];

    if (rowMenuItems.length > 0) {
        columnSchema.push({
            field: 'actions',
            header: '',
            body: props.isLoading ? <Skeleton /> : actionTemplate,
        });
    }

    const rowGroupHeaderTemplate = (data: Event) =>
        data &&
        data.dateGroup && (
            <strong className="image-text">{data.dateGroup}</strong>
        );

    const dataWithGroup = props.data.map((data: Event) => ({
        ...data,
        dateGroup:
            data.startDateTime &&
            format(new Date(data.startDateTime), 'MMMM, Y'),
    }));

    const orderedData = orderBy(dataWithGroup, 'startDateTime', props.orderBy);

    return (
        <>
            <DataTable
                emptyMessage={emptyTemplate}
                footer={
                    !props.isFetching && props.showPagination
                        ? footerTemplate
                        : null
                }
                loading={props.isFetching && !props.isLoading}
                selectionMode="single"
                value={props.isLoading ? blankRows : orderedData}
                rowGroupMode={!props.isLoading ? 'subheader' : ''}
                groupRowsBy="dateGroup"
                rowGroupHeaderTemplate={rowGroupHeaderTemplate}
                onRowClick={(e) => props.onClickEvent(e.data)}
                columnResizeMode="expand"
                resizableColumns
            >
                {props.isLoading ||
                    (props.data.length > 0 &&
                        columnSchema.map((col: ColumnProps) => (
                            <Column key={col.field} {...col} />
                        )))}
            </DataTable>
            <Menu model={rowMenuItems} popup ref={menu} />
        </>
    );
};

export default EventsListing;
