import { useContext, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import usePermission from '../../hooks/usePermission';
import { getEntityFromParam } from '../../util/helper';
import { ToastContext } from '../../contexts/ToastContext';

import { ToastMessage } from 'primereact/toast';

import AssociationLicencesView from './AssociationLicencesView';

import {
    useGetLicenceGroupsQuery,
    useGetLicenceTypesQuery,
    useGetTransferredLicenceGroupsQuery,
    useTransferLicenceGroupMutation,
} from '../../api/licences';
import { useGetEntityQuery } from '../../api/core';

import { LicenceGroupInfo, LicenceType } from '../../types/licences';
import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { User } from '../../types/user';
import { BaseEntityType } from '../../types/common';
import { Permissions } from '../../types/permissions';

interface Props {
    roles: Roles;
    route: Route;
    user: User;
}

const AssociationLicencesContainer = (props: Props) => {
    const { user } = props;

    // Context hooks
    const toast = useContext(ToastContext);

    // Route Hooks
    const params = useParams();
    const entity = getEntityFromParam(params);
    const navigate = useNavigate();

    // State Hooks
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [selectedLicenceGroup, setSelectedLicenceGroup] =
        useState<LicenceGroupInfo>();

    const { checkPermission } = usePermission(params.associationID || '');

    // API Hooks
    const entityData = useGetEntityQuery(
        {
            entityType: entity?.entityType as BaseEntityType,
            entityID: entity?.entityID ?? '',
        },
        { skip: !entity }
    );
    const { data: licenceGroupsRaw, isLoading: isLoadingGroups } =
        useGetLicenceGroupsQuery(
            {
                entityType: entity?.entityType as BaseEntityType,
                entityID: entity?.entityID ?? '',
            },
            { skip: !entity }
        );

    const { data: licenceTypes, isLoading: isLoadingTypes } =
        useGetLicenceTypesQuery(
            {
                sportID: entityData?.data?.data?.entitySportID ?? '',
                expand: 'productDetails',
            },
            { skip: !entityData?.data?.data }
        );

    const { data: transferredRaw } = useGetTransferredLicenceGroupsQuery({
        entityType: BaseEntityType.associations,
        entityID: params.associationID ?? '',
    });

    const [transferLicence] = useTransferLicenceGroupMutation();

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const handleShowEditModal = (licenceGroup: LicenceGroupInfo) => {
        setSelectedLicenceGroup(licenceGroup);
        setShowEditModal(true);
    };

    const handleShowAssignModal = (licenceGroup: LicenceGroupInfo) => {
        setSelectedLicenceGroup(licenceGroup);
        setShowAssignModal(true);
    };

    const handleLicenceGroupClick = (licenceGroup: LicenceGroupInfo) => {
        navigate(licenceGroup.id);
    };

    const handleTransferLicences = (
        licenceGroupID: string,
        organisationID: string
    ) => {
        if (entity && organisationID && licenceGroupID) {
            transferLicence({
                ...entity,
                licenceGroupID,
                targetEntityType: BaseEntityType.organisations,
                targetEntityID: organisationID,
            })
                .then((response) => {
                    showToast({
                        severity: 'success',
                        detail: 'Success',
                    });
                })
                .catch((err) => {
                    console.log('ERR', err);
                })
                .finally(() => {
                    setShowAssignModal(false);
                });
        }
    };

    const licenceGroups = useMemo<LicenceGroupInfo[]>(() => {
        let arr: LicenceGroupInfo[] = [];

        const nonTransferred = licenceGroupsRaw?.data || [];
        const transferred = transferredRaw?.data || [];

        const allLicenceGroups = [...nonTransferred, ...transferred];

        if (allLicenceGroups.length > 0) {
            allLicenceGroups.forEach((licenceGroup) => {
                const licenceType: LicenceType | undefined =
                    licenceTypes?.data.find(
                        (type) =>
                            licenceGroup.licenceTypeID === type.licenceTypeID
                    );

                const { subscription } = licenceGroup;

                const price = licenceType?.product?.prices.find(
                    (p) => p.priceID === licenceGroup.priceID
                );

                arr.push({
                    id: licenceGroup.licenceGroupID,
                    priceID: price?.priceID || '',
                    productTitle: licenceType?.product?.productName || '',
                    licenceTitle: licenceType?.licenceName || 'Legacy',
                    tier: price && price.tier,
                    price:
                        (price && price.unitAmount && price.unitAmount / 100) ||
                        0,
                    currency: 'AUD',
                    createdAt: licenceGroup.createdAt,
                    lastEdited: licenceGroup.lastEditedAt,
                    billingPeriod: price && price.recurring?.interval,
                    renewsAt: subscription
                        ? new Date(subscription.current_period_end * 1000)
                        : null,
                    trialActive:
                        subscription && subscription.trial_end
                            ? new Date().getTime() -
                                  subscription.trial_end * 1000 <
                              0
                            : false,
                    trialEndsAt:
                        subscription && subscription.trial_end
                            ? new Date(subscription.trial_end * 1000)
                            : null,
                    qtyAvailable:
                        licenceGroup.quantity - licenceGroup.totalAssigned,
                    qtyAssigned: licenceGroup.totalAssigned,
                    qtyTotal: licenceGroup.quantity,
                    status: licenceGroup.status,
                    groupID:
                        licenceGroup.subscriptionID ||
                        licenceGroup.invoiceID ||
                        'unknown',
                    description: licenceType?.product?.productDescription || '',
                    isOwner: licenceGroup.createdBy === user.userID || false, // hard-code this for now,
                    owner: licenceGroup.createdBy,
                    features:
                        licenceType?.product?.productFeatures.map(
                            (feat) => feat.name
                        ) || [],
                    entityID: licenceGroup.entityID,
                    entitySportID: licenceGroup.entitySportID,
                    entityType: licenceGroup.entityType,
                    subscriptionID: licenceGroup.subscriptionID,
                    transactionID: licenceGroup.transactionID,
                    rookieProduct: licenceGroup.rookieProduct,
                });
            });
        }

        // order by groupID (either subID or invoiceID) - more useful for ass and org
        arr.sort((a, b) =>
            a.groupID === b.groupID ? 0 : a.groupID < b.groupID ? -1 : 1
        );

        return arr;
    }, [licenceGroupsRaw, transferredRaw, licenceTypes, user]);

    const permissions = {
        canCreate: checkPermission([
            Permissions.MANAGE_ASSOCIATIONS,
            Permissions.MANAGE_ASSOCIATIONS_ALL,
        ]),
        canDelete: checkPermission([
            Permissions.MANAGE_ASSOCIATIONS,
            Permissions.MANAGE_ASSOCIATIONS_ALL,
        ]),
        canEdit: checkPermission([
            Permissions.MANAGE_ASSOCIATIONS,
            Permissions.MANAGE_ASSOCIATIONS_ALL,
        ]),
        canView: checkPermission([
            Permissions.GET_ASSOCIATIONS,
            Permissions.GET_ASSOCIATIONS_ALL,
            Permissions.MANAGE_ASSOCIATIONS,
            Permissions.MANAGE_ASSOCIATIONS_ALL,
        ]),
    };

    return (
        <AssociationLicencesView
            isLoading={isLoadingGroups || isLoadingTypes}
            licenceGroups={licenceGroups}
            onCloseAddModal={() => setShowAddModal(false)}
            onCloseAssignModal={() => setShowAssignModal(false)}
            onCloseEditModal={() => setShowEditModal(false)}
            onLicenceGroupClick={handleLicenceGroupClick}
            onShowAddModal={() => setShowAddModal(true)}
            onShowAssignModal={handleShowAssignModal}
            onShowEditModal={handleShowEditModal}
            onTransferLicences={handleTransferLicences}
            selectedLicenceGroup={selectedLicenceGroup}
            setSelectedLicenceGroup={setSelectedLicenceGroup}
            showAddModal={showAddModal}
            showAssignModal={showAssignModal}
            showEditModal={showEditModal}
            permissions={permissions}
            {...props}
        />
    );
};

export default AssociationLicencesContainer;
