import { isEmpty, startCase, upperFirst } from 'lodash';
import { format, fromUnixTime } from 'date-fns';
import { productDetails } from '../../util/constants';

import PageHeader from '../../layout/PageHeader';
import PageContainer from '../../layout/PageContainer';
import ErrorDisplay from '../../components/ErrorDisplay';
import RookieButton from '../../components/RookieButton';

import { DataTable, DataTableValue } from 'primereact/datatable';
import { Image } from 'primereact/image';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';

import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { User } from '../../types/user';
import { SubscriptionResponse } from '../../types/subscriptions';
import { UseQueryResult } from '../../types/api';
import { ERROR_TYPES } from '../../types/common';
import EntityName from '../../components/EntityName';

const currency = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
});

interface Props {
    subscription: UseQueryResult<SubscriptionResponse>;
    invoices: UseQueryResult<any>;
    roles: Roles;
    route: Route;
    user: User;
    onCancelSubscription: () => void;
    onSubscriptionClick: (data: DataTableValue) => void;
}

const SubscriptionSingleView = (props: Props) => {
    const {
        subscription: subscriptionResponse,
        invoices: invoicesResponse,
        onCancelSubscription,
        onSubscriptionClick,
    } = props;

    const { data: subscription, isLoading: isLoadingSubscription } =
        subscriptionResponse;

    const { data: invoices, isLoading: isLoadingInvoices } = invoicesResponse;

    const emptyTemplate = (
        <ErrorDisplay
            alignment="middle"
            errorType={ERROR_TYPES.empty}
            hasReturn={false}
            proportion="compact"
            title="No results found"
        />
    );

    const emptyRows = Array(2).fill(0);

    return (
        <PageContainer>
            <PageHeader
                title="Subscription"
                actions={
                    subscription?.data.status !== 'active'
                        ? []
                        : [
                              {
                                  label: 'Cancel Subscription',
                                  onClick: onCancelSubscription,
                                  severity: 'danger',
                              },
                          ]
                }
            />
            <DataTable
                value={
                    isLoadingSubscription
                        ? Array(1).fill(0)
                        : subscription
                        ? [subscription]
                        : []
                }
                className="p-card"
                style={{ margin: '20px 0 40px' }}
            >
                <Column
                    field="metadata.entityID"
                    header="Entity"
                    body={({ data }) => {
                        if (isLoadingSubscription) return <Skeleton />;

                        return !isEmpty(data.metadata) ? (
                            <EntityName
                                entityID={data.metadata.entityID}
                                entityType={data.metadata.entityType}
                            />
                        ) : (
                            <div>Unknown</div>
                        );
                    }}
                />
                <Column
                    header="Created"
                    body={({ data }) => {
                        if (isLoadingSubscription) return <Skeleton />;

                        return data.created
                            ? format(
                                  fromUnixTime(data.created),
                                  'MMMM dd, yyyy'
                              )
                            : '-';
                    }}
                />
                <Column
                    header="Status"
                    body={({ data }) => {
                        if (isLoadingSubscription) return <Skeleton />;

                        return data.canceled_at
                            ? `Cancelled on ${format(
                                  fromUnixTime(data.cancel_at),
                                  'MMMM dd, yyyy'
                              )}`
                            : upperFirst(data?.status);
                    }}
                />
                <Column
                    header="Trial"
                    body={({ data }) => {
                        if (isLoadingSubscription) return <Skeleton />;

                        return data.trial_end
                            ? format(
                                  fromUnixTime(data.trial_end),
                                  'MMMM dd, yyyy'
                              )
                            : '-';
                    }}
                />
                <Column
                    header="Next Billing Cycle"
                    body={({ data }) => {
                        if (isLoadingSubscription) return <Skeleton />;

                        return data?.next_pending_invoice_item_invoice || '-';
                    }}
                />
            </DataTable>

            <h2>Items</h2>
            <DataTable
                value={
                    isLoadingSubscription
                        ? emptyRows
                        : subscription?.data?.items?.data
                        ? subscription.data.items.data
                        : []
                }
                onRowClick={({ data }) => onSubscriptionClick(data)}
                emptyMessage={emptyTemplate}
                selectionMode="single"
                className="p-card"
                style={{ margin: '20px 0 40px' }}
            >
                <Column
                    field="price.nickname"
                    header="Product"
                    body={(data) => {
                        if (isLoadingSubscription) return <Skeleton />;

                        const rookieProduct = data.plan.metadata.rookieProduct;
                        const productDetail =
                            rookieProduct && productDetails[rookieProduct];

                        if (!productDetail) {
                            return 'Unknown';
                        }

                        return (
                            <div className="product-cell">
                                <Image
                                    className="product-cell-logo"
                                    src={productDetail.logomark}
                                    width="40"
                                    height="40"
                                />
                                <div className="product-cell-content">
                                    <p className="product-cell-title">
                                        {productDetail.title}
                                    </p>
                                </div>
                            </div>
                        );
                    }}
                />
                <Column
                    field="plan.metadata.tier"
                    header="Plan"
                    body={(data) => {
                        if (isLoadingSubscription) return <Skeleton />;
                        const inclusions =
                            data.plan.metadata.inclusions !== '' &&
                            startCase(data.plan.metadata.inclusions);

                        return `${startCase(data.plan.metadata.tier)}${
                            inclusions ? ' + ' + inclusions : ''
                        }`;
                    }}
                />

                <Column
                    field="price.recurring.interval"
                    header="Billing"
                    body={(data) => {
                        if (isLoadingSubscription) return <Skeleton />;

                        return `${startCase(data.price.recurring.interval)}ly`;
                    }}
                />

                <Column
                    field="created"
                    header="Created"
                    body={(data) =>
                        isLoadingSubscription ? (
                            <Skeleton />
                        ) : (
                            format(fromUnixTime(data.created), 'MMMM dd, yyyy')
                        )
                    }
                />

                <Column
                    field="quantity"
                    header="Qty"
                    body={isLoadingSubscription && <Skeleton />}
                />
                <Column
                    field="price.unit_amount"
                    header="Unit Price"
                    body={(data) =>
                        isLoadingSubscription ? (
                            <Skeleton />
                        ) : (
                            currency.format(data.price.unit_amount / 100)
                        )
                    }
                />
                <Column
                    header=""
                    align="right"
                    body={(data) => {
                        return (
                            <RookieButton
                                label="View"
                                onClick={() => onSubscriptionClick(data)}
                                text
                            />
                        );
                    }}
                />
            </DataTable>

            <h2>Invoices</h2>

            <DataTable
                value={
                    isLoadingInvoices ? emptyRows : invoices?.data?.data || []
                }
                emptyMessage={emptyTemplate}
                className="p-card"
                selectionMode="single"
                onRowClick={({ data }) => {
                    window.open(`${data.hosted_invoice_url}`, '_newtab');
                }}
                style={{ margin: '20px 0 40px' }}
            >
                <Column
                    field="created"
                    header="Payment Date"
                    body={(data) =>
                        isLoadingInvoices ? (
                            <Skeleton />
                        ) : (
                            format(fromUnixTime(data.created), 'MMMM dd, yyyy')
                        )
                    }
                />
                <Column
                    field="number"
                    header="Invoice No."
                    body={isLoadingInvoices && <Skeleton />}
                />
                <Column
                    field="status"
                    header="Status"
                    body={(data) =>
                        isLoadingInvoices ? (
                            <Skeleton />
                        ) : (
                            startCase(data.status)
                        )
                    }
                />
                <Column
                    field="total"
                    header="Total"
                    body={(data) =>
                        isLoadingInvoices ? (
                            <Skeleton />
                        ) : (
                            currency.format(data.total / 100)
                        )
                    }
                />
                <Column
                    header=""
                    align="right"
                    body={(data) => {
                        return (
                            <a
                                href={`${data.hosted_invoice_url}`}
                                target="_blank"
                                rel="noreferrer"
                                className="p-button p-button-text p-button-small"
                            >
                                <span className="p-button-label">View</span>
                            </a>
                        );
                    }}
                />
            </DataTable>
        </PageContainer>
    );
};

export default SubscriptionSingleView;
