import { ColumnProps, ColumnSortEvent } from 'primereact/column';

import { StatConfig as SportStatConfig } from './sports';

export enum ReportType {
    gameActivityLog = 'gameActivityLog',
    gameSummary = 'gameSummary',
    gameTimeBasic = 'gameTimeBasic',
    gameTimeAdvanced = 'gameTimeAdvanced',
    gameTimeExpert = 'gameTimeExpert',
    gameStatsBasic = 'gameStatsBasic',
    gameStatsAdvanced = 'gameStatsAdvanced',
    gameInterchangeOnOff = 'gameInterchangeOnOff',
    gameInterchangePlayer = 'gameInterchangePlayer',
    gamePlusMinus = 'gamePlusMinus',
    gameInterchangeStatTimeSeries = 'gameInterchangeStatTimeSeries',
}

export enum ReportStatus {
    Queued = 'Queued',
    Started = 'Started',
    Building = 'Building',
    Built = 'Built',
    ErrorServer = 'ErrorServer',
    ErrorEvent = 'ErrorEvent',
}

export interface ReportBase {
    createdAt: string;
    createdBy: string;
    errorMessage?: string;
    eventID: string;
    gameSummary?: GameSummary;
    isHomeTeam: boolean;
    lastEdited: string;
    lastEditedBy: string;
    lastStep: string;
    reportBuild: string;
    reportRoot: string;
    reportStatus: ReportStatus;
    seasonID: string;
    sportID: string;
    teamID: string;
}

export interface ReportState<T> {
    data?: T;
    error?: unknown;
    isError: boolean;
    isLoading: boolean;
    isSuccess: boolean;
    isUninitialized: boolean;
}

export interface ReportColumnOptions {
    column: ReportColumn;
    field: string;
    filters?: ReportDataFilters;
    views?: ReportDataViews;
    data?: any;
}

export interface ColumnConfig extends ColumnProps {
    id: string;
    name: string;
    shortName: string;
    description?: string;
    hsArticle?: string;
    category?: string;
    type?: StatTypes;
    format?: (row: any, options: ReportColumnOptions) => any;
}

export interface ReportColumn extends ColumnProps {
    id: string;
    name: string;
    shortName: string;
    description?: string;
    category?: string;
    type?: StatTypes;
    hsArticle?: string;
    format?: (row: any, options: ReportColumnOptions) => any;
    flagLabels?: string[];
    persist?: boolean;
    defaultSort?: number;
}

export interface GameStat {
    playerID: string;
    teamID: string;
    gameID: string;
    eventID: string;
    organisationID: string;
    period: number | string;
    timeStamp: number;
    [key: string]: any;
}

export interface InterchangeStat {
    playerID: string;
    teamID: string;
    eventID: string;
    organisationID: string;
    timeStamp: number;
    rotation: number;
    quarter: number;
    position: string;
    startTime: number;
    endTime: number;
    [key: string]: any;
}

export interface GameSummary {
    duration: number;
    eventID: string;
    fairGameTime: number;
    flags: string[] | Record<string, string[]>;
    pBelowTarget: string[] | Record<string, string[]>;
    disciplineList: string[] | Record<string, string[]>;
    integrityScore: number;
    lowPGT: number;
    noPeriods: number;
    noEvents?: number;
    oppositionBehinds: number;
    oppositionGoals: number;
    oppositionScore: number;
    rotationCount: number;
    skippedTime: number;
    teamBehinds: number;
    teamGoals: number;
    teamID: string;
    teamScore: number;
    [key: string]: any;
}

export interface SeasonGameSummary {
    duration: number;
    eventID: string;
    fairGameTime: number;
    flags: string[][] & string[];
    integrityScore: number;
    lowPGT: number;
    noPeriods: number;
    oppositionBehinds: number;
    oppositionGoals: number;
    oppositionScore: number;
    rotationCount: number;
    skippedTime: number;
    teamBehinds: number;
    teamGoals: number;
    teamID: string;
    teamScore: number;
    [key: string]: any;
}

export interface TeamStat {
    statID: string;
    statName: string;
    period: number | string;
    team: number;
    opposition: number;
    eventID: string;
    playerID: string;
    [key: string]: any;
}

export interface TeamStatShort {
    team: number;
    opposition: number;
    stat: SportStatConfig;
}

export enum StatTypes {
    Count = 'Count',
    Time = 'Time',
    Percentage = 'Percentage',
    Text = 'Text',
}

export enum TimeCategories {
    General = 'General',
    Status = 'Status',
    Position = 'Position',
    Stint = 'Stint',
    Zone = 'Zone',
}

export enum PlusMinusCategories {
    General = 'General',
    Position = 'Position',
    Zone = 'Zone',
}

export interface StatConfig {
    name: string;
    shortName: string;
    description?: string;
    categories?: string;
    type: StatTypes;
    hsArticle?: string;
    flagLabels?: string[];
    format?: Function;
    category?: string;
    sortable?: boolean;
    exportable?: boolean;
    sortFunction?: (e: ColumnSortEvent) => void;
    value?: number;
}

export interface TimeStat {
    gameID: string;
    playerID: string;
    teamID: string;
    [key: string]: any;
}

export enum ReportDataTypes {
    Total = 'Total',
    Average = 'Average',
    Percentage = '% PER',
    Single = 'Single',
}

export enum ReportTimeDisplays {
    Percentage = 'Percentage',
    Time = 'Time',
}

export interface ReportDataFilters {
    season?: string;
    event?: string[];
    period?: number;
    stat?: string;
}

export interface ReportDataViews {
    dataType?: ReportDataTypes;
    timeDisplay?: ReportTimeDisplays;
}

export enum DataMode {
    Total = 'Total',
    Average = 'Average',
}

export enum DisplayMode {
    Percentage = 'Percentage',
    Time = 'Time',
}

export enum TimeFormat {
    Duration = 'Duration',
    Timestamp = 'Timestamp',
}
