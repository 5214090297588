import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useJoinTeamMutation } from '../../api/teams';

import RookieButton from '../../components/RookieButton';
import { ToastContext } from '../../contexts/ToastContext';
import { InputText } from 'primereact/inputtext';
import { ToastMessage } from 'primereact/toast';
import PageContainer from '../../layout/PageContainer';
import { SelectButton } from 'primereact/selectbutton';

const CODE_LENGTH = 8;

const TeamJoinCode = () => {
    const { userID } = useParams();

    const toast = useContext(ToastContext);

    // Set initial code to empty string values
    const [code, setCode] = useState<string[]>(
        Array.from({ length: CODE_LENGTH }).map((i) => '')
    );

    const [userType, setUserType] = useState('');

    const [isSubmitting, setIsSubmitting] = useState(false);

    // Set each input as a ref
    const inputRefs = useRef<Array<any> | []>([]);

    // Join Team Mutation
    const [joinTeam] = useJoinTeamMutation();

    useEffect(() => {
        // Autofocus first input
        if (inputRefs.current[0]) {
            inputRefs.current[0]?.focus();
        }
    }, []);

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const setCodeAtIndex = (value: string, index: number) => {
        setCode((preValue: string[]) => {
            const newCode = [...preValue];
            newCode[index] = value.toUpperCase();

            return newCode;
        });
    };

    const focusInput = (index: number) => {
        if (inputRefs.current[index]) {
            inputRefs.current[index]?.focus();
            inputRefs.current[index]?.select();
        }
    };

    const isValid = (value: string) => {
        return value.match(/^[a-zA-Z0-9]+$/) !== null;
    };

    const handleSubmit = () => {
        setIsSubmitting(true);

        const teamJoinCode = code.join('');

        if (userID) {
            joinTeam({ userID, teamJoinCode, userType })
                .then((response) => {
                    if ('error' in response) {
                        showToast({
                            severity: 'error',
                            // @ts-ignore
                            summary: response.error.data.error,
                        });
                    } else {
                        showToast({
                            severity: 'success',
                            summary: 'Successfully applied code.',
                        });

                        //@ts-ignore
                        window.location.href = `/t/${response.data.data.teamID}`;
                    }
                })
                .catch(() => {
                    showToast({
                        severity: 'error',
                        summary: 'An error has occurred. Please try again.',
                    });
                })
                .then(() => {
                    setIsSubmitting(false);
                });
        }
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => () => {
        event.target.select();
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        // Set code value
        if (isValid(e.target.value)) {
            setCodeAtIndex(e.target.value, index);

            // Focus and select next input
            focusInput(index + 1);
        }
    };

    const handleBackspace = (
        e: React.KeyboardEvent<HTMLInputElement>,
        index: number
    ) => {
        // Prevent initial deleting
        e.preventDefault();

        // Manually delete value
        setCodeAtIndex('', index);

        // Focus and select previous input
        focusInput(index - 1);
    };

    const handleArrowLeft = (
        e: React.KeyboardEvent<HTMLInputElement>,
        index: number
    ) => {
        e.preventDefault();

        // Focus prev input
        focusInput(index - 1);
    };

    const handleArrowRight = (
        e: React.KeyboardEvent<HTMLInputElement>,
        index: number
    ) => {
        e.preventDefault();

        // Focus next input
        focusInput(index + 1);
    };

    const handlePaste = (
        e: React.ClipboardEvent<HTMLInputElement>,
        index: number
    ) => {
        e.preventDefault();

        // Get pasted data
        const pastedData = e.clipboardData
            .getData('text/plain')
            .slice(0, CODE_LENGTH - index)
            .split('');

        // Prevent pasting if the clipboard data if not alphanumeric
        if (!isValid(pastedData.join(''))) {
            return;
        }

        // Paste data from focused input onwards
        for (let pos = 0; pos < CODE_LENGTH; ++pos) {
            if (pos >= index && pastedData.length > 0) {
                setCodeAtIndex(pastedData.shift() ?? '', pos);
            }
        }
    };

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement>,
        index: number
    ) => {
        switch (e.code) {
            case 'Backspace':
                handleBackspace(e, index);
                break;
            case 'ArrowLeft':
                handleArrowLeft(e, index);
                break;
            case 'ArrowRight':
                handleArrowRight(e, index);
                break;
            default:
        }
    };

    return (
        <PageContainer size="narrow">
            <div className="tjc">
                <h1>Join team with a code.</h1>
                <p>If you have a team code, enter it below.</p>
                <div className="tjc-inputs">
                    {code.map((value: string, index: number) => (
                        <InputText
                            key={`input-${index}`}
                            ref={(el) => el && (inputRefs.current[index] = el)}
                            maxLength={1}
                            type="text"
                            value={String(value)}
                            autoComplete="off"
                            onFocus={(e) => handleFocus(e)}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            onPaste={(e) => handlePaste(e, index)}
                            disabled={isSubmitting}
                            style={{ width: `${100 / CODE_LENGTH}%` }}
                        />
                    ))}
                </div>

                <div className="tjc-user-type">
                    <label className="form-label">
                        Who are you joining as?
                    </label>
                    <SelectButton
                        style={{ width: '100%' }}
                        allowEmpty={false}
                        name="userType"
                        value={userType}
                        onChange={(e) => setUserType(e.value)}
                        options={[
                            {
                                label: 'Player',
                                value: 'entityPlayer',
                            },
                            {
                                label: 'Staff',
                                value: 'entityStaff',
                            },
                        ]}
                    />
                </div>

                <RookieButton
                    label="Join Team"
                    onClick={handleSubmit}
                    disabled={
                        code.join('').trim().length < CODE_LENGTH ||
                        !userType ||
                        isSubmitting
                    }
                    size="large"
                    loading={isSubmitting}
                />
            </div>
        </PageContainer>
    );
};

export default TeamJoinCode;
