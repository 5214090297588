import { useNavigate, useParams } from 'react-router-dom';

import { productDetails } from '../../util/constants';

import PageHeader from '../../layout/PageHeader';
import PageContainer from '../../layout/PageContainer';
import Loader from '../../components/Loader';
import ErrorDisplay from '../../components/ErrorDisplay';
import LicenceGroups from './LicenceGroups';
import ProductLicenceCard from './ProductLicenceCard';

import { BaseEntityType, ERROR_TYPES } from '../../types/common';
import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { User } from '../../types/user';
import { LicenceGroupInfo, Licence } from '../../types/licences';
import { sortBy } from 'lodash';

interface Props {
    roles: Roles;
    route: Route;
    user: User;
    getStaffName: (userID: string) => string | undefined;
    licences: Licence[];
    licenceGroups: LicenceGroupInfo[];
    isLoading: boolean;
    onAssignLicence: (licenceGroupID: string) => void;
    onChangePlan: (
        rookieProduct: string,
        licenceGroup?: LicenceGroupInfo
    ) => void;
    onManageBilling: (subscriptionID: string, inAppPurchase: boolean) => void;
    onUnassignLicence: (licence: string) => void;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const LicencesView = (props: Props) => {
    const {
        getStaffName,
        licences,
        licenceGroups,
        isLoading,
        onAssignLicence,
        onChangePlan,
        onManageBilling,
        onUnassignLicence,
        permissions,
    } = props;
    const { teamID } = useParams();
    const navigate = useNavigate();

    const isEmpty = licenceGroups.length === 0 && licences.length === 0;

    const emptyMessage = (
        <ErrorDisplay
            errorType={ERROR_TYPES.empty}
            hasReturn={false}
            hasReporting={false}
            title="This entity does not have any licenses associated with
                it."
            actions={
                teamID && permissions.canCreate
                    ? [
                          {
                              label: 'Add Licence',
                              onClick: () => {
                                  navigate(
                                      `/plans?entityType=teams&entityID=${teamID}&returnUrl=${window.location.href}`
                                  );
                              },
                          },
                      ]
                    : []
            }
        />
    );

    return (
        <PageContainer>
            <PageHeader
                title="Licences"
                beaconArticle="677f12355cf80034633783b8"
            />

            {isLoading ? (
                <Loader />
            ) : isEmpty ? (
                emptyMessage
            ) : (
                <div>
                    <div className="app-cards-container">
                        {Object.keys(productDetails)
                            .filter((rookieProduct) => rookieProduct !== 'hub')
                            .map((rookieProduct) => {
                                const licence = licences.find(
                                    (licence) =>
                                        licence.rookieProduct === rookieProduct
                                );

                                const licenceGroup = licenceGroups.find(
                                    (group) =>
                                        group.id === licence?.licenceGroupID
                                );

                                const createdBy =
                                    licence?.licenceGroupEntityType !==
                                    BaseEntityType.teams
                                        ? 'Assigned by parent Entity'
                                        : getStaffName(licence.createdBy);

                                const inAppPurchase = !!(
                                    licence &&
                                    licenceGroup &&
                                    licenceGroup.transactionID !== ''
                                );

                                const availableLicence = licenceGroups.find(
                                    (o) => o.rookieProduct === rookieProduct
                                );

                                return (
                                    <ProductLicenceCard
                                        createdBy={createdBy}
                                        hasAvailableLicence={!!availableLicence}
                                        inAppPurchase={inAppPurchase}
                                        licence={licence}
                                        licenceGroup={licenceGroup}
                                        onChangePlan={onChangePlan}
                                        onUnassignLicence={onUnassignLicence}
                                        permissions={permissions}
                                        rookieProduct={rookieProduct}
                                    />
                                );
                            })}
                    </div>
                    {licenceGroups.length > 0 && (
                        <LicenceGroups
                            getStaffName={getStaffName}
                            licenceGroups={sortBy(
                                licenceGroups,
                                'rookieProduct'
                            )}
                            onManageBilling={onManageBilling}
                            onAssignLicence={onAssignLicence}
                            permissions={permissions}
                            usedLicences={licences.map((l) => l.licenceGroupID)}
                        />
                    )}
                </div>
            )}
        </PageContainer>
    );
};

export default LicencesView;
