import { createApi } from '@reduxjs/toolkit/query/react';
import { generatePath } from 'react-router-dom';

import { apiEndpoints } from './apiEndpoints';
import { baseQueryWithReauth } from '../util/baseQuery';

import { BaseEntityType, EntityStructure } from '../types/common';
import {
    PresignedOptions,
    PresignedUrlResponse,
    ResourceLinkResponse,
    ResourceFolderResponse,
    ResourceFolderForm,
    ResourceFileForm,
    ResourceFileResponse,
} from '../types/resources';

export const resourcesApi = createApi({
    reducerPath: 'resourcesApi',
    baseQuery: (args, api, extraOptions) =>
        baseQueryWithReauth(
            args,
            api,
            extraOptions,
            process.env.REACT_APP_API_URL
        ),
    tagTypes: ['Resource'],
    endpoints: (builder) => ({
        createPutPresignedURL: builder.mutation<
            PresignedUrlResponse,
            PresignedOptions & { entityType: BaseEntityType; entityID: string }
        >({
            query: ({ entityType, entityID, ...body }) => ({
                url: generatePath(apiEndpoints.createPutPresignedURL.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.createPutPresignedURL.method,
                body,
            }),
        }),
        getSportResourceLinks: builder.query<
            ResourceLinkResponse,
            { sportID: string }
        >({
            query: ({ sportID }) => ({
                url: generatePath(apiEndpoints.getSportResourceLinks.url, {
                    sportID,
                }),
                method: apiEndpoints.getSportResourceLinks.method,
            }),
        }),
        createResourceFolder: builder.mutation<
            ResourceFolderResponse,
            ResourceFolderForm
        >({
            query: ({ entityType, entityID, ...body }) => ({
                url: generatePath(apiEndpoints.createResourceFolder.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.createResourceFolder.method,
                body,
            }),
            invalidatesTags: ['Resource'],
        }),
        getSportResourceFile: builder.query<
            { url: string; contentType: string },
            any
        >({
            query: ({ url, contentType }) => ({
                url,
                method: 'GET',
                responseHandler: contentType,
            }),
        }),
        createResourceFile: builder.mutation<
            ResourceFileResponse,
            EntityStructure & ResourceFileForm
        >({
            query: ({ entityType, entityID, ...body }) => ({
                url: generatePath(apiEndpoints.createResourceFile.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.createResourceFile.method,
                body,
            }),
            invalidatesTags: ['Resource'],
        }),
        updateResourecFile: builder.mutation<
            ResourceFileResponse,
            EntityStructure & ResourceFileForm
        >({
            query: ({ entityType, entityID, resourceID, ...body }) => ({
                url: generatePath(apiEndpoints.updateResourceFile.url, {
                    entityType,
                    entityID,
                    resourceID,
                }),
                method: apiEndpoints.updateResourceFile.method,
                body,
            }),
            invalidatesTags: ['Resource'],
        }),
        deleteResourceFile: builder.mutation<
            void,
            EntityStructure & { resourceID: string }
        >({
            query: ({ entityType, entityID, resourceID }) => ({
                url: generatePath(apiEndpoints.deleteResourceFile.url, {
                    entityType,
                    entityID,
                    resourceID,
                }),
                method: apiEndpoints.deleteResourceFile.method,
            }),
            invalidatesTags: ['Resource'],
        }),
        updateResourceFolder: builder.mutation<
            ResourceFolderResponse,
            ResourceFolderForm
        >({
            query: ({ entityType, entityID, folderID, ...body }) => ({
                url: generatePath(apiEndpoints.updateResourceFolder.url, {
                    entityType,
                    entityID,
                    folderID,
                }),
                method: apiEndpoints.updateResourceFolder.method,
                body,
            }),
            invalidatesTags: ['Resource'],
        }),
        deleteResourceFolder: builder.mutation<
            void,
            EntityStructure & { folderID: string }
        >({
            query: ({ entityType, entityID, folderID }) => ({
                url: generatePath(apiEndpoints.deleteResourceFolder.url, {
                    entityType,
                    entityID,
                    folderID,
                }),
                method: apiEndpoints.deleteResourceFolder.method,
            }),
            invalidatesTags: ['Resource'],
        }),
        getResourceFolders: builder.query<
            ResourceFolderResponse,
            {
                entityType: BaseEntityType;
                entityID: string;
                expand?: string;
            }
        >({
            query: ({ entityType, entityID, ...params }) => ({
                url: generatePath(apiEndpoints.getResourceFolders.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.getResourceFolders.method,
                params,
            }),
            providesTags: ['Resource'],
        }),
        getResourceFolder: builder.query<
            ResourceFolderResponse,
            {
                entityType: BaseEntityType;
                entityID: string;
                folderID: string;
                expand?: string;
            }
        >({
            query: ({ entityType, entityID, folderID, ...params }) => ({
                url: generatePath(apiEndpoints.getResourceFolder.url, {
                    entityType,
                    entityID,
                    folderID,
                }),
                method: apiEndpoints.getResourceFolder.method,
                params,
            }),
            providesTags: ['Resource'],
        }),
    }),
});

export const {
    useCreatePutPresignedURLMutation,
    useGetSportResourceLinksQuery,
    useGetResourceFolderQuery,
    useLazyGetResourceFolderQuery,
    useLazyGetResourceFoldersQuery,
    useGetResourceFoldersQuery,
    useCreateResourceFolderMutation,
    useCreateResourceFileMutation,
    useUpdateResourceFolderMutation,
    useDeleteResourceFolderMutation,
    useDeleteResourceFileMutation,
    useUpdateResourecFileMutation,
    useLazyGetSportResourceFileQuery,
} = resourcesApi;
