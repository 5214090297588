import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { isObject, uniq } from 'lodash';

import { useLazyGetEventsQuery } from '../../../api/events';
import { useLazyGetTeamSeasonSummaryReportQuery } from '../../../api/reports';
import { useGetTeamQuery } from '../../../api/teams';

import TeamGameSummaryReportView from './TeamGameSummaryReportView';

import { Mixpanel } from '../../../util/mixpanel';
import { toISOStringWithTimezone } from '../../../util/helper';

import { config } from '../reportConfig';
import { formatValue } from '../helpers';
import { defaultReportState } from '../constants';

import { DataTable } from 'primereact/datatable';

import { BaseEntityType } from '../../../types/common';
import {
    GameSummary,
    ReportState,
    ReportDataTypes,
    ReportDataViews,
    ReportTimeDisplays,
} from '../../../types/reports';

const columnSchema = [
    'event',
    'opponent',
    'location',
    'isHomeTeam',
    'integrityScore',
    'fairGameTime',
    'matchResult',
    'teamScoringAccuracy',
    'teamScore',
    'oppositionScore',
    'scoreMargin',
    'rotationCount',
];

const columns = columnSchema
    .filter((col) => config[col as keyof typeof config])
    .map((col) => config[col as keyof typeof config]);

const TeamGameSummaryReportContainer = () => {
    // Route Params
    const { teamID } = useParams();

    // Search Params
    const [searchParams] = useSearchParams();
    const seasonParam = searchParams.get('season');

    // State Hooks
    const [reportData, setReportData] = useState<
        Record<string, ReportState<any>>
    >({});

    const [aggregateData, setAggregateData] = useState<Record<string, any>>({});

    const [season, setSeason] = useState(seasonParam || '');

    const [hideEmptyColumns, setHideEmptyColumns] = useState<boolean>(true);
    const [hideIntFGTScore, setHideIntFGTScore] = useState(false);
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
    const [views, setViews] = useState<ReportDataViews>({
        dataType: ReportDataTypes.Total,
    });

    // To prevent pagination caching issues
    const now = useRef(new Date()).current;

    // API Hooks
    const [requestReport] = useLazyGetTeamSeasonSummaryReportQuery();
    const [fetchEvents, eventData] = useLazyGetEventsQuery();

    const teamData = useGetTeamQuery({
        teamID: teamID || '',
        expand: 'defaultSeasonDetails',
    });

    const team = useMemo(() => teamData.data?.data, [teamData]);

    // Set the season to the first available season
    useEffect(() => {
        if (!season && team) {
            setSeason(team.defaultSeasonID);
        }
    }, [team, season]);

    // Fetch Events with Auto pagination
    useEffect(() => {
        const loadEvents = (cursor: string) => {
            if (teamID) {
                fetchEvents({
                    entityType: BaseEntityType.teams,
                    entityID: teamID,
                    to: toISOStringWithTimezone(now),
                    expand: 'gameDetails',
                    cursor,
                })
                    .then((response) => {
                        if (response.data) {
                            // Auto paginate teams
                            if (
                                response.data.lastEvaluatedKey.cursor &&
                                response.data.lastEvaluatedKey.cursor !== cursor
                            ) {
                                loadEvents(
                                    response.data.lastEvaluatedKey.cursor
                                );
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(
                            `Error fetching events for team ${teamID}`,
                            error
                        );
                    });
            }
        };

        loadEvents('');
    }, [teamID, fetchEvents, now]);

    // Fetch Reports
    useEffect(() => {
        if (teamID && season && !reportData[season]) {
            const fetchReport = async () => {
                try {
                    // Set report loading in state
                    setReportData((prev) => ({
                        ...prev,
                        [season]: {
                            ...defaultReportState,
                            isLoading: true,
                            isUninitialized: false,
                        },
                    }));

                    //fetch report url
                    const requestReportData = await requestReport({
                        seasonID: season,
                        teamID,
                        sessionID: now.getTime(),
                        concatenated: true,
                    }).unwrap();

                    const reportUrl = requestReportData?.data?.objectURL;

                    if (reportUrl) {
                        // Fetch report data
                        const response = await fetch(reportUrl);

                        if (!response.ok) {
                            throw new Error('Failed to load report');
                        }

                        const report = await response.json();

                        // Set report in state
                        setReportData((prev) => ({
                            ...prev,
                            [season]: {
                                ...prev[season],
                                isLoading: false,
                                data: report,
                            },
                        }));
                    } else {
                        throw new Error('Invalid report URL or request failed');
                    }
                } catch (error) {
                    // Set report error in state
                    setReportData((prev) => ({
                        ...prev,
                        [season]: {
                            ...prev[season],
                            isLoading: false,
                            isError: true,
                            error:
                                isObject(error) && 'message' in error
                                    ? error.message
                                    : error,
                        },
                    }));
                }
            };

            fetchReport();
        }
    }, [teamID, season, reportData, requestReport, now]);

    /***
         AGGREGATE DATA
    ***/

    useEffect(() => {
        if (reportData[season]) {
            const data = reportData[season].data || [];

            let teamScoringAccuracy = 0;

            let totals = {
                wins: 0,
                losses: 0,
                draws: 0,
                teamScoringAccuracy: 'N/A',
                teamScore: 0,
                oppositionScore: 0,
                scoreMargin: 0,
                rotationCount: 0,
            };

            if (data && data.length > 0) {
                data.forEach((event: GameSummary) => {
                    teamScoringAccuracy += event.teamScoringAccuracy;
                    totals.wins += event.wins;
                    totals.losses += event.losses;
                    totals.draws += event.draws;
                    totals.teamScore += event.teamScore;
                    totals.oppositionScore += event.oppositionScore;
                    totals.scoreMargin +=
                        event.teamScore - event.oppositionScore;
                    totals.rotationCount += event.rotationCount;
                });

                if (views.dataType !== 'Total') {
                    totals.teamScore = parseFloat(
                        (totals.teamScore / data.length).toFixed(1)
                    );
                    totals.oppositionScore = parseFloat(
                        (totals.oppositionScore / data.length).toFixed(1)
                    );
                    totals.scoreMargin = parseFloat(
                        (totals.scoreMargin / data.length).toFixed(1)
                    );
                    totals.rotationCount = parseFloat(
                        (totals.rotationCount / data.length).toFixed(1)
                    );
                }

                totals.teamScoringAccuracy = `${(
                    Math.round((teamScoringAccuracy / data.length) * 1000) / 10
                ).toFixed(1)}%`;
            }

            setAggregateData(totals);
        }
    }, [reportData, season, views.dataType]);

    /***
         Format Report Data
    ***/
    const tableData = useMemo(() => {
        let newData = reportData[season]?.data;

        if (!newData) return [];

        // Format data
        return newData.map((data: any) => {
            const event = eventData.data?.data.find(
                (o) => o.eventID === data.eventID
            );

            const uniqueKeys = uniq([
                ...Object.keys(data),
                ...columns.map((col) => col.id),
            ]);

            data.event = event;

            return uniqueKeys.reduce(
                (acc, key) => {
                    const column = columns.find((col) => col.id === key);

                    if (column?.format) {
                        acc[key] = column.format(data, {
                            field: key,
                            column,
                            views,
                            data: reportData[season]?.data,
                        });
                    } else {
                        const totalTime = data['PT'];

                        acc[key] = formatValue(
                            data[key],
                            column?.type,
                            views.dataType === ReportDataTypes.Average,
                            1,
                            views.timeDisplay === ReportTimeDisplays.Percentage,
                            totalTime
                        );
                    }

                    return acc;
                },
                { ...data }
            );
        });
    }, [season, eventData, reportData, views]);

    /**
     * EMPTY COLUMNS
     */

    useEffect(() => {
        setHiddenColumns(() =>
            columns
                .filter((col) => {
                    if (
                        hideEmptyColumns &&
                        (aggregateData[col.id] === 0 ||
                            aggregateData[col.id] === '0%')
                    ) {
                        return true;
                    }

                    if (
                        hideIntFGTScore &&
                        (col.id === 'integrityScore' ||
                            col.id === 'fairGameTime')
                    ) {
                        return true;
                    }

                    return false;
                })
                .map((col) => col.id)
        );
    }, [aggregateData, hideEmptyColumns, hideIntFGTScore]);

    const handleSeasonChange = (seasonID: string) => {
        setSeason(seasonID);
    };

    const handleViewChange = (key: string, value: any) => {
        setViews((state) => ({
            ...state,
            [key]: value,
        }));
    };

    const exportCSV = (dataTable: DataTable<any>) => {
        dataTable && dataTable.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: `Team Game Summary Report`,
        });
    };

    const dataTypeOptions = [
        {
            label: ReportDataTypes.Total,
            value: ReportDataTypes.Total,
        },
        {
            label: ReportDataTypes.Average,
            value: ReportDataTypes.Average,
        },
    ];

    return (
        <TeamGameSummaryReportView
            aggregateData={aggregateData}
            columns={columns}
            data={tableData}
            hiddenColumns={hiddenColumns}
            hideEmptyColumns={hideEmptyColumns}
            hideIntFGTScore={hideIntFGTScore}
            options={{
                dataType: dataTypeOptions,
            }}
            reportData={reportData[season]}
            season={season}
            team={team}
            views={views}
            onExport={exportCSV}
            onHideEmptyColumns={setHideEmptyColumns}
            onHideIntFGTScore={setHideIntFGTScore}
            onSeasonChange={handleSeasonChange}
            onViewsChange={handleViewChange}
        />
    );
};

export default TeamGameSummaryReportContainer;
