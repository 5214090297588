import { useRef } from 'react';
import { format } from 'date-fns';

import { getFirstChars } from '../../../util/helper';

import { UnifiedUser } from './TeamParticipationReportContainer';
import PageContainer from '../../../layout/PageContainer';
import PageHeader from '../../../layout/PageHeader';
import FormGroup from '../../../components/FormGroup';
import ListItem from '../../../components/ListItem';
import RookieButton from '../../../components/RookieButton';
import SeasonSelect from '../components/SeasonSelect';
import UserResponseCell from '../components/UserResponseCell';
import UserAttendanceCell from '../components/UserAttendanceCell';
import { Avatar } from 'primereact/avatar';
import { Chart } from 'primereact/chart';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Row } from 'primereact/row';
import { SelectButton } from 'primereact/selectbutton';
import { SelectItem } from 'primereact/selectitem';
import { Toolbar } from 'primereact/toolbar';

import { Team } from '../../../types/team';
import {
    Attendee,
    Event,
    EventUserAttendance,
    EventUserResponse,
    InvitedUser,
} from '../../../types/event';

interface Props {
    chartData: any;
    chartOptions: any;
    filters: Record<string, string>;
    filteredEventData: any;
    options: Record<string, SelectItem[]>;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
    season: string;
    team?: Team;
    totalParticipation: any;
    userData: any;
    view: any;
    onBulkSubmitAttendee: (event: Event) => void;
    onChangeAttendance: (
        user: UnifiedUser,
        event: Event,
        attendance: EventUserAttendance,
        attendee?: Attendee
    ) => void;
    onChangeResponse: (
        user: UnifiedUser,
        event: Event,
        response: EventUserResponse
    ) => void;
    onExport: (dataTable: DataTable<any>) => void;
    onFilterChange: (key: string, value: any) => void;
    onInviteUser: (user: UnifiedUser, event: Event) => void;
    onRemoveInvite: (
        inviteeID: string,
        user: UnifiedUser,
        event: Event
    ) => void;
    onSeasonChange: (value: string) => void;
    onViewChange: (view: string) => void;
}

const TeamParticipationReportView = (props: Props) => {
    const {
        chartData,
        chartOptions,
        filters,
        filteredEventData,
        options,
        permissions,
        season,
        team,
        totalParticipation,
        userData,
        view,
        onBulkSubmitAttendee,
        onChangeAttendance,
        onChangeResponse,
        onExport,
        onFilterChange,
        onInviteUser,
        onRemoveInvite,
        onSeasonChange,
        onViewChange,
    } = props;

    const dataTable = useRef<DataTable<any>>(null);

    // Renders
    const leftToolbar = (
        <>
            <FormGroup label="Season">
                <SeasonSelect
                    onChange={(e) => onSeasonChange(e.value)}
                    value={season}
                    placeholder="Select Season"
                    teamID={team?.teamID}
                    initialOptions={
                        team?.defaultSeasonDetails && [
                            team.defaultSeasonDetails,
                        ]
                    }
                />
            </FormGroup>
            {view === 'memberView' && (
                <FormGroup label="Members">
                    <Dropdown
                        value={filters.userType}
                        options={options.userTypes}
                        onChange={(e) => {
                            onFilterChange('userType', e.value);
                        }}
                    />
                </FormGroup>
            )}
            <FormGroup label="Events">
                <Dropdown
                    value={filters.eventType}
                    options={options.eventTypes}
                    onChange={(e) => {
                        onFilterChange('eventType', e.value);
                    }}
                />
            </FormGroup>
            <FormGroup label="Date Range">
                <Dropdown
                    value={filters.dateRange}
                    options={options.dateRanges}
                    onChange={(e) => {
                        onFilterChange('dateRange', e.value);
                    }}
                />
            </FormGroup>
        </>
    );

    const rightToolbar = (
        <>
            <SelectButton
                value={view}
                onChange={(e) => onViewChange(e.value)}
                options={options.views}
                allowEmpty={false}
            />
        </>
    );

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column
                    footer="Totals"
                    colSpan={2}
                    footerStyle={{
                        textAlign: 'right',
                        backgroundColor: 'white',
                    }}
                />
                {/* Display Yes/No counts stacked in one cell per event */}
                {totalParticipation.map((response: any, index: number) => (
                    <Column
                        colSpan={2}
                        key={index}
                        footer={
                            <span
                                dangerouslySetInnerHTML={{ __html: response }}
                            />
                        }
                        footerStyle={{
                            textAlign: 'center',
                            backgroundColor: 'white',
                        }}
                    />
                ))}
            </Row>
        </ColumnGroup>
    );

    /*
    const renderEmpty = () => {
        const msg = event
            ? "Can't load report for this event. The data may be corrupt. Try rebuilding your report or reach out to us via the question mark at the base of the page."
            : 'Unable to find report. Please try again later';
        const errorType = event
            ? ERROR_TYPES.somethingsWrong
            : ERROR_TYPES.notFound;
    };
    */

    const headerTemplate = (data: any) => {
        return (
            <div className="is-sticky">
                {data.playerID ? 'Players' : 'Staff'}
            </div>
        );
    };

    const checkPastEvent = (event: Event) => {
        return new Date(event.startDateTime) < new Date();
    };

    const renderParticipationCell = (user: any) => {
        if (!user.userID) return '';

        const userEvents = (filteredEventData || []).filter((event: Event) =>
            event.eventInvitees.invitees.some(
                (invitee: InvitedUser) => invitee.userID === user.userID
            )
        );

        const totalUserEvents = userEvents.length;

        // Count the number of times the user responded "Yes"
        const yesCount = userEvents.reduce((count: number, event: any) => {
            const invitee = event.eventInvitees.invitees.find(
                (invitee: any) => invitee.userID === user.userID
            );
            return invitee?.response === 'Yes' ? count + 1 : count;
        }, 0);

        // Calculate percentage (avoid division by zero)
        const attendanceRate =
            totalUserEvents > 0
                ? ((yesCount / totalUserEvents) * 100).toFixed(1)
                : '0';

        return `${attendanceRate}%`;
    };

    const renderUserCell = (user: UnifiedUser) => {
        return (
            <ListItem
                start={
                    <Avatar
                        label={getFirstChars(
                            `${user.firstName} ${user.lastName}`,
                            2
                        ).toUpperCase()}
                        image={user.image || undefined}
                        style={{ background: '#4A4A4D', color: '#ffffff' }}
                        shape="circle"
                    />
                }
                end={
                    !user.userID && (
                        <RookieButton
                            icon="warning"
                            tooltip="Not Linked"
                            size="small"
                            text
                            disabled
                            tooltipOptions={{
                                showOnDisabled: true,
                            }}
                        />
                    )
                }
                title={`${user.firstName} ${user.lastName}`}
                disableGutters
                disablePadding
            />
        );
    };

    const renderEventCellHeader = (event: Event) => {
        return (
            <ListItem
                disableGutters
                disablePadding
                compact
                style={{ width: '200px' }}
                title={event.eventName}
                caption={format(new Date(event.startDateTime), 'dd/MM/yyyy')}
                end={
                    permissions.canEdit &&
                    checkPastEvent(event) && (
                        <RookieButton
                            icon="move_selection_right"
                            text
                            severity="secondary"
                            tooltip="Use invite responses as attendance"
                            tooltipOptions={{
                                position: 'bottom',
                            }}
                            onClick={() => onBulkSubmitAttendee(event)}
                        />
                    )
                }
            />
        );
    };

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column
                    header="Member"
                    rowSpan={1}
                    frozen={true}
                    style={{ minWidth: '250px' }}
                />
                <Column
                    header={
                        ['Past', 'Past7', 'Past30'].includes(filters.dateRange)
                            ? 'Attendance (%)'
                            : ['Future', 'Future7', 'Future30'].includes(
                                  filters.dateRange
                              )
                            ? 'Availability (%)'
                            : 'Participation (%)'
                    }
                    rowSpan={1}
                    style={{ minWidth: '200px' }}
                />
                {filteredEventData?.map((event: Event) => (
                    <Column
                        align="center"
                        colSpan={2}
                        alignHeader={'left'}
                        header={() => renderEventCellHeader(event)}
                    />
                ))}
            </Row>
            <Row>
                <Column frozen={true} />
                <Column />
                {filteredEventData?.map((event: Event) => {
                    return [
                        <Column
                            align="center"
                            headerStyle={{ position: 'relative' }}
                            header="Res."
                            headerTooltip="Response"
                            headerTooltipOptions={{
                                position: 'top',
                            }}
                        />,
                        <Column
                            align="center"
                            header="Att."
                            headerTooltip="Attendance"
                            headerTooltipOptions={{
                                position: 'top',
                            }}
                        />,
                    ];
                })}
            </Row>
        </ColumnGroup>
    );

    return (
        <PageContainer>
            <PageHeader
                title="Participation Report"
                beaconArticle="67a6d93b6a68bc7dd37b28e5"
                actions={[
                    {
                        onClick: () =>
                            dataTable.current && onExport(dataTable.current),
                        label: 'Export',
                        severity: 'secondary',
                        icon: 'download',
                    },
                ]}
            />
            <Toolbar
                className="report-toolbar"
                start={leftToolbar}
                end={rightToolbar}
            />
            {view === 'memberView' ? (
                <DataTable
                    ref={dataTable}
                    showGridlines
                    //footerColumnGroup={footerGroup}
                    scrollable
                    value={userData}
                    stripedRows={true}
                    rowGroupMode="subheader"
                    groupRowsBy="userType"
                    rowGroupHeaderTemplate={headerTemplate}
                    footerColumnGroup={footerGroup}
                    headerColumnGroup={headerGroup}
                    scrollHeight="100vh"
                >
                    <Column
                        header="Member"
                        body={renderUserCell}
                        frozen={true}
                        style={{ minWidth: '250px' }}
                    />

                    <Column
                        header={
                            ['Past', 'Past7', 'Past30'].includes(
                                filters.dateRange
                            )
                                ? 'Attendance (%)'
                                : ['Future', 'Future7', 'Future30'].includes(
                                      filters.dateRange
                                  )
                                ? 'Availability (%)'
                                : 'Participation (%)'
                        }
                        body={renderParticipationCell}
                        style={{ minWidth: '200px' }}
                    />

                    {/* Dynamically generated event columns */}
                    {filteredEventData?.map((event: Event) => {
                        const isPastEvent = checkPastEvent(event);

                        return [
                            <Column
                                key={`${event.eventID}-response`}
                                header={() => renderEventCellHeader(event)}
                                align="center"
                                alignHeader="left"
                                body={(user) => {
                                    if (!event.eventInvitees || !user.userID)
                                        return '-';

                                    const matchedInvitee =
                                        event.eventInvitees?.invitees?.find(
                                            (inv: InvitedUser) =>
                                                inv.userID === user.userID
                                        );

                                    if (!matchedInvitee) {
                                        return isPastEvent ? (
                                            '-'
                                        ) : (
                                            <RookieButton
                                                label="Invite"
                                                size="small"
                                                outlined
                                                severity="secondary"
                                                onClick={() =>
                                                    onInviteUser(user, event)
                                                }
                                            />
                                        );
                                    }

                                    return (
                                        <UserResponseCell
                                            isPastEvent={isPastEvent}
                                            invitee={matchedInvitee}
                                            isEventOwner={
                                                event.eventOwnerID ===
                                                user.userID
                                            }
                                            onChangeResponse={(response) =>
                                                onChangeResponse(
                                                    user,
                                                    event,
                                                    response
                                                )
                                            }
                                            onRemoveInvite={(invitee) =>
                                                onRemoveInvite(
                                                    invitee.inviteeID,
                                                    user,
                                                    event
                                                )
                                            }
                                        />
                                    );
                                }}
                            />,
                            <Column
                                key={`${event.eventID}-attendance`}
                                header={() => renderEventCellHeader(event)}
                                align="center"
                                alignHeader="left"
                                body={(user) => {
                                    const matchedAttendee =
                                        event.eventAttendees?.attendees?.find(
                                            (att: Attendee) =>
                                                att.userID === user.userID
                                        );
                                    const matchedInvitee =
                                        event.eventInvitees?.invitees?.find(
                                            (inv: InvitedUser) =>
                                                inv.userID === user.userID
                                        );

                                    return (
                                        <UserAttendanceCell
                                            isPastEvent={isPastEvent}
                                            isInvited={!!matchedInvitee}
                                            attendee={matchedAttendee}
                                            onChangeAttendance={(
                                                attendance,
                                                attendee
                                            ) =>
                                                onChangeAttendance(
                                                    user,
                                                    event,
                                                    attendance,
                                                    attendee
                                                )
                                            }
                                        />
                                    );
                                }}
                            />,
                        ];
                    })}
                </DataTable>
            ) : (
                <div className="card">
                    <Chart type="bar" data={chartData} options={chartOptions} />
                </div>
            )}
        </PageContainer>
    );
};

export default TeamParticipationReportView;
