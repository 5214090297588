export const DISCOUNT_AMOUNT = 0.5; // 0.1 = 10%
export const DISCOUNT_PRODUCT = ['game_day'];
export const DISCOUNT_EXCL_SPORT = ['aus_football_au'];
export const DISCOUNT_MSG = (
    <>
        Rookie Me Play is now multi-sport! Enjoy{' '}
        <strong>50% off your subscriptions</strong> for a limited time.
    </>
);

export const checkDiscount = (
    sportID?: string,
    rookieProduct?: string
): boolean => {
    return (
        !!DISCOUNT_AMOUNT &&
        DISCOUNT_AMOUNT > 0 &&
        (!sportID || !DISCOUNT_EXCL_SPORT.includes(sportID)) &&
        (!rookieProduct || DISCOUNT_PRODUCT.includes(rookieProduct))
    );
};
