import { createApi } from '@reduxjs/toolkit/query/react';
import { generatePath } from 'react-router-dom';
import { isEqual } from 'lodash';

import { apiEndpoints } from './apiEndpoints';
import { baseQueryWithReauth } from '../util/baseQuery';

import { BaseEntityType } from '../types/common';
import {
    CollectionFormData,
    CollectionResponse,
    CollectionsResponse,
} from '../types/collections';
import { TeamsResponse } from '../types/team';

export const collectionsApi = createApi({
    reducerPath: 'collectionsApi',
    baseQuery: (args, api, extraOptions) =>
        baseQueryWithReauth(
            args,
            api,
            extraOptions,
            process.env.REACT_APP_API_URL
        ),
    tagTypes: ['Collection', 'CollectionTeams'],
    endpoints: (builder) => ({
        getCollections: builder.query<
            CollectionsResponse,
            {
                entityType: BaseEntityType;
                entityID: string;
                cursor?: string;
                status?: string;
                limit?: number;
            }
        >({
            query: ({ entityType, entityID, ...params }) => ({
                url: generatePath(apiEndpoints.getCollections.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.getCollections.method,
                params,
            }),
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ collectionID }) => ({
                              type: 'Collection' as const,
                              collectionID,
                          })),
                          'Collection',
                      ]
                    : ['Collection'];
            },
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (
                    currentCache.lastEvaluatedKey &&
                    newItems.lastEvaluatedKey &&
                    !isEqual(
                        currentCache.lastEvaluatedKey,
                        newItems.lastEvaluatedKey
                    )
                ) {
                    currentCache.data = currentCache.data.concat(newItems.data);
                    currentCache.lastEvaluatedKey = newItems.lastEvaluatedKey;
                } else {
                    currentCache.data = newItems.data;
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
        }),
        getCollection: builder.query<
            CollectionResponse,
            {
                entityType: BaseEntityType;
                entityID: string;
                collectionID: string;
            }
        >({
            query: ({ entityType, entityID, collectionID }) => ({
                url: generatePath(apiEndpoints.getCollection.url, {
                    entityType,
                    entityID,
                    collectionID,
                }),
                method: apiEndpoints.getCollection.method,
            }),
        }),
        getCollectionTeams: builder.query<
            TeamsResponse,
            {
                entityType: BaseEntityType;
                entityID: string;
                collectionID: string;
                cursor?: string;
            }
        >({
            query: ({ entityType, entityID, collectionID, ...params }) => ({
                url: generatePath(apiEndpoints.getCollectionTeams.url, {
                    entityType,
                    entityID,
                    collectionID,
                }),
                method: apiEndpoints.getCollectionTeams.method,
                params,
            }),
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ teamID }) => ({
                              type: 'CollectionTeams' as const,
                              teamID,
                          })),
                          'CollectionTeams',
                      ]
                    : ['Collection'];
            },
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (
                    currentCache.lastEvaluatedKey &&
                    newItems.lastEvaluatedKey &&
                    !isEqual(
                        currentCache.lastEvaluatedKey,
                        newItems.lastEvaluatedKey
                    )
                ) {
                    currentCache.data = currentCache.data.concat(newItems.data);
                    currentCache.lastEvaluatedKey = newItems.lastEvaluatedKey;
                } else {
                    currentCache.data = newItems.data;
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
        }),
        createCollection: builder.mutation<
            CollectionResponse,
            CollectionFormData & {
                entityType: BaseEntityType;
                entityID: string;
            }
        >({
            query: ({ entityType, entityID, ...data }) => ({
                url: generatePath(apiEndpoints.createCollection.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.createCollection.method,
                body: data,
            }),
            invalidatesTags: ['Collection'],
        }),
        updateCollection: builder.mutation<
            CollectionResponse,
            CollectionFormData & {
                entityType: BaseEntityType;
                entityID: string;
            }
        >({
            query: ({ entityType, entityID, collectionID, ...data }) => ({
                url: generatePath(apiEndpoints.updateCollection.url, {
                    entityType,
                    entityID,
                    collectionID,
                }),
                method: apiEndpoints.updateCollection.method,
                body: data,
            }),
            invalidatesTags: ['Collection'],
        }),
        deleteCollection: builder.mutation<
            CollectionResponse,
            {
                entityType: BaseEntityType;
                entityID: string;
                collectionID: string;
            }
        >({
            query: ({ entityType, entityID, collectionID }) => ({
                url: generatePath(apiEndpoints.deleteCollection.url, {
                    entityType,
                    entityID,
                    collectionID,
                }),
                method: apiEndpoints.deleteCollection.method,
            }),
            invalidatesTags: ['Collection'],
        }),
        addTeamToCollection: builder.mutation<
            CollectionResponse,
            {
                entityType: BaseEntityType;
                entityID: string;
                collectionID: string;
                teams: { teamID: string; seasonID: string }[];
            }
        >({
            query: ({ entityType, entityID, collectionID, teams }) => ({
                url: generatePath(apiEndpoints.addTeamToCollection.url, {
                    entityType,
                    entityID,
                    collectionID,
                }),
                method: apiEndpoints.addTeamToCollection.method,
                body: {
                    teams,
                },
            }),
            invalidatesTags: ['CollectionTeams'],
        }),
        removeTeamFromCollection: builder.mutation<
            CollectionResponse,
            {
                entityType: BaseEntityType;
                entityID: string;
                collectionID: string;
                teamID: string;
            }
        >({
            query: ({ entityType, entityID, collectionID, teamID }) => ({
                url: generatePath(apiEndpoints.removeTeamFromCollection.url, {
                    entityType,
                    entityID,
                    collectionID,
                    teamID,
                }),
                method: apiEndpoints.removeTeamFromCollection.method,
            }),
            invalidatesTags: ['CollectionTeams'],
        }),
    }),
});

export const {
    useGetCollectionsQuery,
    useGetCollectionQuery,
    useGetCollectionTeamsQuery,
    useCreateCollectionMutation,
    useUpdateCollectionMutation,
    useDeleteCollectionMutation,
    useAddTeamToCollectionMutation,
    useRemoveTeamFromCollectionMutation,
} = collectionsApi;
