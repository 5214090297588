import { useRef, useMemo } from 'react';
import { format } from 'date-fns';

import { StaffTabProps } from './StaffView';
import ErrorDisplay from '../../components/ErrorDisplay';
import ListItem from '../../components/ListItem';
import RookieButton from '../../components/RookieButton';
import { Avatar } from 'primereact/avatar';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { Skeleton } from 'primereact/skeleton';

import { UserDetails } from '../../types/user';
import { BaseEntityType, ERROR_TYPES } from '../../types/common';
import { Sidebar } from 'primereact/sidebar';
import StaffBulkAddToTeam from './StaffBulkAddToTeam';

const StaffActiveView = (props: StaffTabProps) => {
    const {
        staff: { data: staffData, isError, isFetching, isLoading },
        entityID,
        entityType,
        focusedUser,
        isUpdatingUser,
        permissions,
        showAddToTeamModal,
        onDeleteStaff,
        onFocusStaff,
        onLoadMore,
        onCloseAddToTeamModal,
        onSelectStaff,
        onShowAddToTeamModal,
        onShowInviteModal,
    } = props;

    const rowMenu = useRef<Menu>(null);

    const titleTemplate = (row: UserDetails) => {
        return (
            <ListItem
                title={row.userDetails.name}
                start={
                    <Avatar
                        image={row.userDetails.picture}
                        label={
                            row.userDetails.picture
                                ? undefined
                                : row.userDetails.name
                        }
                    />
                }
                compact
            />
        );
    };

    const getMenuItems = (user: UserDetails) => {
        let items = [];

        const isOwner = user?.permissions.some((permission) =>
            permission.includes('OWNER')
        );

        if (permissions.canDelete && !isOwner) {
            items.push({
                label: 'Delete Staff',
                command: () => focusedUser && onDeleteStaff(focusedUser),
            });
        }

        if (
            entityType === BaseEntityType.organisations &&
            permissions.canEdit
        ) {
            items.push({
                label: 'Bulk add to teams',
                command: () => onShowAddToTeamModal(),
            });
        }

        return items;
    };

    const columnSchema: ColumnProps[] = [
        {
            body: isLoading ? <Skeleton /> : titleTemplate,
            field: 'given_name',
            header: 'Name',
        },
        {
            body: isLoading ? (
                <Skeleton />
            ) : (
                (row: UserDetails) => row.roles[0].roleName
            ),
            field: 'role',
            header: 'Role',
        },
        {
            body: isLoading ? (
                <Skeleton />
            ) : (
                (row) =>
                    row.userDetails.last_login &&
                    format(new Date(row.userDetails.last_login), 'dd/MM/yyyy')
            ),
            field: 'last_login',
            header: 'Last Login',
        },
        {
            body: isLoading ? (
                <Skeleton />
            ) : (
                (row) =>
                    row.createdAt &&
                    format(new Date(row.createdAt), 'dd/MM/yyyy')
            ),
            field: 'createdAt',
            header: 'Date Joined',
        },

        {
            body: (row) => {
                const showMenu = row && getMenuItems(row).length > 0;

                return isLoading ? (
                    <Skeleton />
                ) : showMenu ? (
                    <RookieButton
                        severity="secondary"
                        text={true}
                        className="td-action"
                        aria-label="More"
                        onClick={(e) => {
                            if (rowMenu.current) {
                                rowMenu.current.toggle(e);
                            }
                            onFocusStaff(row);
                        }}
                        icon="more_horiz"
                    />
                ) : undefined;
            },
            className: 'actions-td',
            field: 'action',
            header: '',
        },
    ];

    const columns = columnSchema.map((col: ColumnProps) => {
        return <Column key={col.field} {...col} />;
    });

    const emptyMessage = (
        <ErrorDisplay
            actions={[
                {
                    label: 'Invite Staff Member',
                    onClick: onShowInviteModal,
                    severity: 'secondary',
                    icon: 'add',
                },
            ]}
            alignment="middle"
            errorType={ERROR_TYPES.notFound}
            hasReturn={false}
            proportion="compact"
            title={isError ? 'An error has occurred' : `No Staff`}
            desc={
                isError
                    ? 'Refresh to try the request again'
                    : "To get started select 'Invite Staff' in the top right area."
            }
        />
    );

    const footerTemplate = () => {
        return (
            <RookieButton
                onClick={onLoadMore}
                severity="secondary"
                label="Load more"
                icon="pending"
            />
        );
    };

    const showPagination = !!staffData?.lastEvaluatedKey?.cursor;

    const staff = useMemo(() => {
        if (!staffData?.data) return [];

        return staffData.data.filter(
            (staff) =>
                !staff.roles.some((role) =>
                    [
                        'teamsPending',
                        'organisationsPending',
                        'associationsPending',
                    ].includes(role.roleID)
                )
        );
    }, [staffData]);

    return (
        <>
            <DataTable
                value={isLoading ? Array(3).fill(0) : staff}
                loading={isFetching && !isLoading}
                footer={!isFetching && showPagination ? footerTemplate : null}
                emptyMessage={emptyMessage}
                selectionMode="single"
                onSelectionChange={(e) => {
                    onSelectStaff(e.value);
                }}
                rowClassName={(row) =>
                    !!isUpdatingUser && isUpdatingUser === row.userID
                        ? 'is-loading'
                        : ''
                }
            >
                {isLoading ? columns : staff.length > 0 ? columns : null}
            </DataTable>

            {focusedUser &&
                entityID &&
                entityType === BaseEntityType.organisations && (
                    <Sidebar
                        header="Add to team/s"
                        onHide={onCloseAddToTeamModal}
                        visible={showAddToTeamModal}
                        position="right"
                        content={
                            <StaffBulkAddToTeam
                                organisationID={entityID}
                                user={focusedUser}
                                onClose={onCloseAddToTeamModal}
                                onSuccess={onCloseAddToTeamModal}
                            />
                        }
                    />
                )}

            <Menu
                model={focusedUser ? getMenuItems(focusedUser) : []}
                popup
                ref={rowMenu}
            />
        </>
    );
};

export default StaffActiveView;
