import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { groupBy } from 'lodash';

import { useGetMeQuery, useGetRolesQuery } from '../api/core';
import { generateUrlFromRole, getEntityFromPath } from '../util/helper';

import ListItem from './ListItem';
import EntityAvatar from './EntityAvatar';
import RookieButton from './RookieButton';

import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';

import { Role } from '../types/roles';

const EntitySwitcher = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { data: meData } = useGetMeQuery();

    const rolesData = useGetRolesQuery(
        {
            userID: meData?.data.userID ?? '',
            limit: 2000,
        },
        {
            skip: !meData?.data,
        }
    );

    const currEntity = getEntityFromPath(pathname);
    const roles = useMemo(() => rolesData?.data?.data || [], [rolesData?.data]);

    const activeValue = useMemo(() => {
        return roles.find(
            (role) =>
                role.entityID === currEntity?.entityID &&
                role.entityType === currEntity.entityType
        );
    }, [roles, currEntity]);

    const groupedRoles = useMemo(() => {
        const grouped = groupBy(roles, 'entityType');

        return Object.keys(grouped).reduce((result, entityType) => {
            result.push({
                label: entityType,
                items: grouped[entityType as keyof typeof grouped].slice(0, 5),
            });

            return result;
        }, [] as any);
    }, [roles]);

    const totalVisible = useMemo(
        () =>
            groupedRoles.reduce((result: number, group: any) => {
                return (result += group.items.length);
            }, 0),
        [groupedRoles]
    );

    const handleChange = (event: DropdownChangeEvent) => {
        const url = generateUrlFromRole(event.value);

        if (url) {
            navigate(url);
        }
    };

    const handleViewAll = () => {
        navigate('/');
    };

    const renderEntityTemplate = (role?: Role) => {
        if (!role) {
            return <ListItem title="Select..." disablePadding compact />;
        }
        return (
            <ListItem
                start={
                    <EntityAvatar
                        entityID={role.entityID}
                        entityName={role.entityName}
                        entityType={role.entityType}
                    />
                }
                title={role.entityName}
                caption={role.roles ? role.roles[0].roleName : ''}
                disablePadding
                compact
            />
        );
    };

    return (
        <Dropdown
            value={activeValue}
            onChange={handleChange}
            options={groupedRoles}
            placeholder="Select..."
            optionGroupLabel="label"
            valueTemplate={() => renderEntityTemplate(activeValue)}
            itemTemplate={renderEntityTemplate}
            panelFooterTemplate={
                totalVisible < roles.length && (
                    <RookieButton
                        label="View All"
                        onClick={handleViewAll}
                        size="small"
                        text
                        severity="secondary"
                    />
                )
            }
            emptyMessage="No available roles"
            className="entity-switcher"
        />
    );
};

export default EntitySwitcher;
