import { useRef } from 'react';
import { format } from 'date-fns';
import {
    generatePath,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';

import ErrorDisplay from '../../components/ErrorDisplay';
import PageHeader from '../../layout/PageHeader';
import RookieButton from '../../components/RookieButton';
import PageContainer from '../../layout/PageContainer';

import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Menu } from 'primereact/menu';
import { Skeleton } from 'primereact/skeleton';
import { TabMenu } from 'primereact/tabmenu';

import { Season } from '../../types/seasons';
import { ERROR_TYPES } from '../../types/common';

import { useGetTeamQuery } from '../../api/teams';
import { UserDetails } from '../../types/user';

interface Props {
    data: Season[];
    staffData: UserDetails[];
    isError: boolean;
    isLoading: boolean;
    isFetching: boolean;
    onCloseSeasonDialog: () => void;
    onCloseSettingsDialog: () => void;
    onCreate: () => void;
    onDelete: () => void;
    onLoadMore: () => void;
    onSelect: (season: Season) => void;
    onShowSeasonDialog: () => void;
    onShowSettingsDialog: () => void;
    onUpdate: () => void;
    setFocus: (season: Season) => void;
    selectedSeason: Season | null;
    showPagination: boolean;
    showSeasonDialog: boolean;
    showSettingsDialog: boolean;
}

const TeamSeasonsArchivedView = ({
    data,
    staffData,
    isFetching,
    isLoading,
    onCloseSeasonDialog,
    onCloseSettingsDialog,
    onCreate,
    onLoadMore,
    onSelect,
    onUpdate,
    selectedSeason,
    setFocus,
    showPagination,
    showSettingsDialog,
    showSeasonDialog,
}: Props) => {
    const { teamID } = useParams();
    const rowMenu = useRef<Menu>(null);

    const location = useLocation();
    const navigate = useNavigate();

    const footerTemplate = (
        <RookieButton
            onClick={onLoadMore}
            severity="secondary"
            label="Load more"
            icon="pending"
        />
    );

    const emptyTemplate = (
        <ErrorDisplay
            alignment="middle"
            desc={`Seasons will appear here.`}
            errorType={ERROR_TYPES.empty}
            hasReturn={false}
            proportion="compact"
            title="No Seasons"
        />
    );

    const actionTemplate = (row: Season) => {
        return (
            <div className="p-buttonset">
                <RookieButton
                    severity="secondary"
                    text={true}
                    className="td-action"
                    aria-label="More"
                    onClick={(e) => {
                        if (rowMenu.current) {
                            rowMenu.current.toggle(e);
                        }
                        setFocus(row);
                    }}
                    icon="more_horiz"
                />
            </div>
        );
    };

    // Get default seasonID for the team.
    const teamQueryData = useGetTeamQuery(
        {
            teamID: teamID || '',
        },
        {
            skip: !teamID,
        }
    );

    const teamObjectData: any = teamQueryData.data;
    const defaultSeasonID = teamObjectData?.data?.defaultSeasonID;

    const columns = [
        {
            field: 'seasonName',
            header: 'Name',
            body: isLoading && <Skeleton />,
        },
        {
            header: 'Status',
            body: (row: Season) =>
                row.seasonID === defaultSeasonID
                    ? 'Current Season'
                    : row.seasonStatus,
        },
        {
            field: 'createdAt',
            header: 'Created',
            body: (row: Season) =>
                isLoading ? (
                    <Skeleton />
                ) : (
                    format(new Date(row.createdAt), 'MMM yyyy')
                ),
        },
        {
            field: 'createdBy',
            header: 'Created By',
            body: (row: Season) => {
                const staff = staffData.find(
                    (staff) => staff.userID === row.createdBy
                );

                return staff ? `${staff?.firstName} ${staff?.lastName}` : '';
            },
        },
        {
            body: isLoading ? <Skeleton /> : actionTemplate,
            className: 'actions-td',
            field: 'action',
            header: '',
        },
    ];

    const tabs = [
        {
            id: 'owned',
            label: 'Owned',
            data: {
                path: teamID
                    ? generatePath('/t/:teamID/seasons/owned', { teamID })
                    : '',
            },
        },
        {
            id: 'participating',
            label: 'Participating',
            data: {
                path: teamID
                    ? generatePath('/t/:teamID/seasons/participating', {
                          teamID,
                      })
                    : '',
            },
        },
        {
            id: 'archived',
            label: 'Archived',
            data: {
                path: teamID
                    ? generatePath('/t/:teamID/seasons/archived', { teamID })
                    : '',
            },
        },
    ];

    const onlyArchivedSeasons = data.filter(
        (item) => item.seasonStatus === 'Archived'
    );

    return (
        <PageContainer>
            <PageHeader
                title="Seasons"
                beaconArticle="67a2de88d38ba20c429e61cd"
            />
            <TabMenu
                model={tabs}
                activeIndex={tabs.findIndex((tab) =>
                    location.pathname.includes(tab.data.path)
                )}
                onTabChange={(e) =>
                    e.value.data.path && navigate(e.value.data.path)
                }
            />
            <DataTable
                value={isLoading ? Array(5) : onlyArchivedSeasons}
                loading={isFetching && !isLoading}
                footer={!isFetching && showPagination ? footerTemplate : null}
                emptyMessage={emptyTemplate}
                selectionMode="single"
                onSelectionChange={(e) => {
                    onSelect(e.value);
                }}
                columnResizeMode="expand"
                resizableColumns
            >
                {(isLoading || data.length > 0) &&
                    columns.map((col: ColumnProps) => (
                        <Column key={col.field} {...col} />
                    ))}
            </DataTable>
        </PageContainer>
    );
};

export default TeamSeasonsArchivedView;
