import { generatePath } from 'react-router-dom';
import { createApi } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from './apiEndpoints';
import { baseQueryWithReauth } from '../util/baseQuery';
import {
    FomationConfigResponse,
    FomationsConfigResponse,
} from '../types/sports';

export const formationsApi = createApi({
    reducerPath: 'formationsApi',
    baseQuery: (args, api, extraOptions) =>
        baseQueryWithReauth(
            args,
            api,
            extraOptions,
            process.env.REACT_APP_API_URL
        ),
    endpoints: (builder) => ({
        getFormations: builder.query<
            FomationsConfigResponse,
            { sportID: string }
        >({
            query: ({ sportID }) => ({
                url: generatePath(apiEndpoints.getFormations.url, { sportID }),
                method: apiEndpoints.getFormations.method,
            }),
        }),
        getFormation: builder.query<
            FomationConfigResponse,
            { sportID: string; formationID: string }
        >({
            query: ({ sportID, formationID }) => ({
                url: generatePath(apiEndpoints.getFormation.url, {
                    sportID,
                    formationID,
                }),
                method: apiEndpoints.getFormation.method,
            }),
        }),
    }),
});

export const { useGetFormationsQuery, useGetFormationQuery } = formationsApi;
