import { startCase } from 'lodash';

import { checkDiscount, DISCOUNT_MSG } from './discount';
import PlanSummaryBar from './PlanSummaryBar';
import PlanFeatures from './PlanFeatures';
import PriceItem from './PriceItem';
import ProductItem from './ProductItem';
import SportSelector from './SportSelector';
import PageContainer from '../../layout/PageContainer';
import PageHeader from '../../layout/PageHeader';
import ErrorDisplay from '../../components/ErrorDisplay';
import EntityAvatar from '../../components/EntityAvatar';
import FormGroup from '../../components/FormGroup';
import RookieButton from '../../components/RookieButton';
import { SelectButton } from 'primereact/selectbutton';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import { Message } from 'primereact/message';

import { Association } from '../../types/association';
import { LicenceType, RookieProduct } from '../../types/licences';
import { Organisation } from '../../types/organisation';
import { Sport } from '../../types/sports';
import { PlanEssentials, StripeInterval } from '../../types/subscriptions';
import { BaseEntityType } from '../../types/common';
import { Team } from '../../types/team';

type SelectedPrices = {
    [key: string]: PlanEssentials;
};

interface Props {
    billingInterval: StripeInterval;
    entity?: Association | Organisation | Team;
    entityID?: string | null;
    entityType?: BaseEntityType | null;
    expandSummary: boolean;
    licenceTypes: LicenceType[];
    plans: { [key: string]: PlanEssentials[] };
    qty: number;
    returnUrl: string | null;
    selectedPrices: SelectedPrices;
    selectedSport: string | null;
    submitting: boolean;
    total: string;
    unavailableSportIDs: string[];
    activePrice: (plan: PlanEssentials) => boolean;
    activeTier: (plan: PlanEssentials) => boolean;
    clearSearchParams: () => void;
    onCheckoutClick: (prices: PlanEssentials[]) => void;
    onIntervalChange: (interval: StripeInterval) => void;
    onPriceChange: (product: RookieProduct, priceID: PlanEssentials) => void;
    onQtyChange: (qty: number) => void;
    onReportToggle: (product: RookieProduct, priceID: PlanEssentials) => void;
    onSportClick: (sport: Sport) => void;
    onToggleSummary: () => void;
}

const PlansView = (props: Props) => {
    const {
        billingInterval,
        expandSummary,
        plans,
        entity,
        entityType,
        qty,
        selectedPrices,
        selectedSport,
        total,
        submitting,
        clearSearchParams,
        unavailableSportIDs,
        activeTier,
        activePrice,
        licenceTypes,
        returnUrl,
        onIntervalChange,
        onPriceChange,
        onQtyChange,
        onSportClick,
        onToggleSummary,
        onReportToggle,
        onCheckoutClick,
    } = props;
    const hasPlans = Object.keys(plans).length <= 0;
    const hasOneProduct = Object.keys(plans).length === 1;
    const hasDiscount = selectedSport && checkDiscount(selectedSport);

    const renderEntity = (entity: any) => {
        const entityKeyPrefix = entityType && entityType.slice(0, -1);
        const eName = entity[`${entityKeyPrefix}Name`];
        const eID = entity[`${entityKeyPrefix}ID`];

        if (!entity || !entityType) {
            return null;
        }

        return (
            <FormGroup label={startCase(entityType.slice(0, -1))}>
                <div className="plan-entity">
                    <EntityAvatar
                        entityName={eName}
                        entityID={eID}
                        entityType={entityType}
                    />
                    {eName}
                </div>
            </FormGroup>
        );
    };

    if (!selectedSport) {
        return (
            <PageContainer size="narrow">
                <PageHeader
                    title="Select your sport."
                    showBreadcrumbs={false}
                    align="center"
                />
                <SportSelector
                    onClick={onSportClick}
                    selected={selectedSport}
                    appearance="card"
                    unavailableSportIDs={unavailableSportIDs}
                />
            </PageContainer>
        );
    }

    return (
        <PageContainer style={{ maxWidth: '90rem' }}>
            {hasDiscount && DISCOUNT_MSG && (
                <Message
                    style={{ width: '100%', marginBottom: '2rem' }}
                    text={DISCOUNT_MSG}
                />
            )}
            {returnUrl && (
                <RookieButton
                    label="Back"
                    onClick={() => {
                        window.location.href = returnUrl;
                    }}
                    outlined
                    severity="secondary"
                    icon="arrow_back"
                />
            )}

            <PageHeader
                title="Choose the right plan for your team."
                showBreadcrumbs={false}
                align="center"
            />

            <div>
                <Toolbar
                    className="plans-toolbar"
                    center={
                        <>
                            {entity ? (
                                renderEntity(entity)
                            ) : (
                                <FormGroup
                                    label="Sport"
                                    className="plans-toolbar-field plans-toolbar-field--sport"
                                >
                                    <SportSelector
                                        onClick={onSportClick}
                                        selected={selectedSport}
                                        unavailableSportIDs={
                                            unavailableSportIDs
                                        }
                                    />
                                </FormGroup>
                            )}
                            {entityType !== BaseEntityType.teams && (
                                <FormGroup
                                    label="How many teams?"
                                    className="plans-toolbar-field plans-toolbar-field--qty"
                                >
                                    <InputText
                                        type="number"
                                        name="qty"
                                        min={1}
                                        max={100}
                                        value={qty.toString()}
                                        onChange={(e) =>
                                            onQtyChange(Number(e.target.value))
                                        }
                                    />
                                </FormGroup>
                            )}
                            <FormGroup
                                label="Billing Period"
                                className="plans-toolbar-field plans-toolbar-field--billing"
                            >
                                <SelectButton
                                    allowEmpty={false}
                                    value={billingInterval}
                                    onChange={(e) => onIntervalChange(e.value)}
                                    options={[
                                        {
                                            label: 'Monthly',
                                            value: 'month',
                                        },
                                        {
                                            label: 'Yearly',
                                            value: 'year',
                                        },
                                    ]}
                                />
                            </FormGroup>
                        </>
                    }
                />
                {hasPlans ? (
                    <ErrorDisplay
                        title="No plans found"
                        actions={[
                            {
                                label: 'Reset Filters',
                                onClick: clearSearchParams,
                            },
                        ]}
                        hasReturn={false}
                    />
                ) : (
                    <>
                        <div className="products-container">
                            {Object.keys(plans).map((key) => {
                                const product = key as RookieProduct;
                                const prices = plans[product].filter(
                                    (price) =>
                                        !(
                                            price.inclusions &&
                                            price.inclusions === 'reports'
                                        )
                                );

                                const selectedPrice = selectedPrices?.[product];

                                const report = plans[product].find(
                                    (price) =>
                                        price.inclusions &&
                                        price.inclusions === 'reports' &&
                                        price.tier === selectedPrice?.tier
                                );

                                if (hasOneProduct) {
                                    return prices.map((price, i) => {
                                        const matchedReport = plans[
                                            product
                                        ].find(
                                            (p) =>
                                                p.inclusions &&
                                                p.inclusions === 'reports' &&
                                                p.tier === price?.tier
                                        );

                                        const isActiveTier = activeTier(price);

                                        const flag = licenceTypes.find(
                                            (lt) =>
                                                lt.licenceTypeID ===
                                                price.licenceTypeID
                                        )?.licenceFlag;

                                        return (
                                            <PriceItem
                                                key={price.id}
                                                price={price}
                                                product={product}
                                                report={matchedReport}
                                                activePrice={activePrice}
                                                isActiveTier={isActiveTier}
                                                billingInterval={
                                                    billingInterval
                                                }
                                                onSelectClick={(
                                                    price: PlanEssentials
                                                ) => onCheckoutClick([price])}
                                                flag={flag}
                                                hideSubmit={!entity}
                                            />
                                        );
                                    });
                                }

                                return (
                                    <ProductItem
                                        product={product}
                                        prices={prices}
                                        onPriceChange={onPriceChange}
                                        selectedPrice={selectedPrice}
                                        billingInterval={billingInterval}
                                        onReportToggle={onReportToggle}
                                        report={report}
                                        activePrice={activePrice}
                                        licenceTypes={licenceTypes}
                                    />
                                );
                            })}
                        </div>

                        {!hasOneProduct && (
                            <PlanSummaryBar
                                total={total}
                                prices={selectedPrices}
                                billingInterval={billingInterval}
                                qty={qty}
                                expanded={expandSummary}
                                onExpandToggle={onToggleSummary}
                                onSubmitClick={() =>
                                    onCheckoutClick(
                                        Object.values(selectedPrices)
                                    )
                                }
                                submitting={submitting}
                                hideSubmit={!entity}
                            />
                        )}

                        <PlanFeatures plans={plans} />
                    </>
                )}
            </div>
        </PageContainer>
    );
};

export default PlansView;
