import { useMemo, useRef } from 'react';

import PageContainer from '../../../layout/PageContainer';
import PageHeader from '../../../layout/PageHeader';
import Icon from '../../../components/Icon';
import ReportTable from '../components/ReportTable';
import List from '../../../components/List';
import ListItem from '../../../components/ListItem';
import RookieButton from '../../../components/RookieButton';
import { DataTable } from 'primereact/datatable';
import { InputSwitch } from 'primereact/inputswitch';
import { ProgressBar } from 'primereact/progressbar';
import { SelectButton } from 'primereact/selectbutton';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';

import { Organisation } from '../../../types/organisation';
import { DataMode, ReportColumn } from '../../../types/reports';
import { RookieProduct } from '../../../types/licences';

interface Props {
    columns: ReportColumn[];
    data: any;
    dataMode: DataMode;
    hiddenColumns: string[];
    hideEmptyColumns: boolean;
    hideIntFGTScore: boolean;
    loading?: boolean;
    loadingProgress?: number;
    onExport: (dataTable: DataTable<any>) => void;
    onSeasonChange: (teamID: string, seasonID: string) => void;
    onSeasonDropdownOpen: (teamID: string) => void;
    onDataModeChange: (value: any) => void;
    options: any;
    organisation?: Organisation;
    selectedSeasons: Record<string, string>;
    onHideEmptyColumns: (show: boolean) => void;
    onHideIntFGTScore: (show: boolean) => void;
}

const OrgTeamSummaryReportView = (props: Props) => {
    const {
        columns,
        data,
        dataMode,
        hiddenColumns,
        hideEmptyColumns,
        hideIntFGTScore,
        loading,
        loadingProgress,
        options,
        organisation,
        selectedSeasons,
        onDataModeChange,
        onExport,
        onHideEmptyColumns,
        onHideIntFGTScore,
        onSeasonChange,
        onSeasonDropdownOpen,
    } = props;
    const dataTable = useRef<DataTable<any>>(null);
    const columnEdit = useRef<OverlayPanel>(null);

    const rightToolbar = (
        <div className="p-button-group">
            <SelectButton
                value={dataMode}
                onChange={(e) => onDataModeChange(e.value)}
                options={options.dataMode}
                allowEmpty={false}
                pt={{
                    button: {
                        className: 'p-button-sm',
                    },
                }}
            />
            <RookieButton
                label="Customise"
                onClick={(e) => columnEdit?.current?.toggle(e)}
                size="small"
                outlined
                severity="secondary"
                icon="tune"
            />
        </div>
    );

    const visibleColumns = useMemo(() => {
        const seasonCol: ReportColumn = {
            id: 'season',
            name: 'Season',
            shortName: 'Season',
            sortable: false,
            body: (row: any) => {
                if (!row || !row.team) return null;

                const selected = selectedSeasons[row.team.teamID];

                return (
                    <Dropdown
                        style={{ width: '100%' }}
                        value={selected}
                        onChange={(e) =>
                            onSeasonChange(row.team.teamID, e.value)
                        }
                        onShow={() =>
                            onSeasonDropdownOpen &&
                            onSeasonDropdownOpen(row.team.teamID)
                        }
                        options={options.seasons[row.team.teamID]}
                        className={row?.reportState?.isError ? 'p-invalid' : ''}
                        disabled={row?.reportState?.isLoading}
                        valueTemplate={(val) => {
                            return (
                                <div className="p-dropdown-value">
                                    {val && val.label}

                                    {row?.reportState?.isError && (
                                        <Icon name="error" />
                                    )}
                                </div>
                            );
                        }}
                        tooltip={
                            row?.reportState?.isError &&
                            'Unable to find report.'
                        }
                    />
                );
            },
        };

        const seasonColIndex = 1;

        const columnsWithSeason = [
            ...columns.slice(0, seasonColIndex),
            seasonCol,
            ...columns.slice(seasonColIndex),
        ];

        return columnsWithSeason.filter(
            (col) => !hiddenColumns.includes(col.id)
        );
    }, [
        columns,
        hiddenColumns,
        onSeasonChange,
        selectedSeasons,
        options,
        onSeasonDropdownOpen,
    ]);

    return (
        <PageContainer>
            <PageHeader
                title="Summary Report"
                actions={[
                    {
                        onClick: () =>
                            dataTable.current && onExport(dataTable.current),
                        label: 'Export',
                        severity: 'secondary',
                        icon: 'download',
                    },
                ]}
            />
            <Toolbar className="report-toolbar" end={rightToolbar} />

            {loading ? (
                <ProgressBar
                    value={Math.round(loadingProgress ?? 0)}
                    style={{ height: '20px' }}
                />
            ) : (
                <ReportTable
                    ref={dataTable}
                    data={data}
                    columns={visibleColumns}
                    product={RookieProduct.GameDay}
                    dataTableProps={{
                        sortField: 'team.teamName',
                        sortOrder: 1,
                        exportFilename: `${organisation?.organisationName} - Game Summary Report`,
                    }}
                />
            )}

            <OverlayPanel ref={columnEdit} className="column-popover">
                <List>
                    <ListItem
                        title={
                            <label
                                htmlFor="hideIntFgt"
                                style={{
                                    fontSize: 'inherit',
                                    fontWeight: 'inherit',
                                }}
                            >
                                Hide Integrity and FGT Scores
                            </label>
                        }
                        end={
                            <InputSwitch
                                inputId="hideIntFgt"
                                checked={hideIntFGTScore}
                                onChange={(e) => onHideIntFGTScore(e.value)}
                            />
                        }
                    />
                    <ListItem
                        title={
                            <label
                                htmlFor="hideEmpty"
                                style={{
                                    fontSize: 'inherit',
                                    fontWeight: 'inherit',
                                }}
                            >
                                Hide Empty Columns
                            </label>
                        }
                        end={
                            <InputSwitch
                                inputId="hideEmpty"
                                checked={hideEmptyColumns}
                                onChange={(e) => onHideEmptyColumns(e.value)}
                            />
                        }
                    />
                </List>
            </OverlayPanel>
        </PageContainer>
    );
};

export default OrgTeamSummaryReportView;
