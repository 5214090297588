import { GetTokenSilentlyOptions } from '@auth0/auth0-react';
import { TokenEndpointResponse } from '@auth0/auth0-spa-js';

let getAccessTokenSilently: (
    options?: GetTokenSilentlyOptions | undefined
) => Promise<TokenEndpointResponse>;

export const sec = {
    getAccessTokenSilently: () => getAccessTokenSilently,
    setAccessTokenSilently: (
        func: (
            options?: GetTokenSilentlyOptions | undefined
        ) => Promise<TokenEndpointResponse>
    ) => (getAccessTokenSilently = func),
};
