import { useEffect, useMemo, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';

import { useGetGameLineupsQuery } from '../../api/games';
import { useLazyGetPlayersQuery } from '../../api/players';
import { useGetFormationQuery } from '../../api/formations';

import { periodSuffix } from '../../util/helper';

import Loader from '../../components/Loader';
import { SelectButton } from 'primereact/selectbutton';
import { Toolbar } from 'primereact/toolbar';

import { PlayerPositionStatus } from '../../types/game';
import { UseQueryResult } from '../../types/api';
import { EventResponse } from '../../types/event';
import { Player, TeamResponse } from '../../types/team';
import { Me } from '../../types/user';

interface Props {
    eventData: UseQueryResult<EventResponse>;
    teamData: UseQueryResult<TeamResponse>;
    user: Me;
}

interface LineupPlayerPosition {
    playerPositionID: string;
    playerID: string;
    positionID: string;
    status: PlayerPositionStatus;
    playerName?: string;
    formationID?: string;
    positionName?: string;
    positionShortName?: string;
    xPos?: number;
    yPos?: number;
    zoneName?: string;
    zoneShortName?: string;
    player?: Player;
}

const EventLineup = () => {
    const { teamID, eventID } = useParams();
    const { eventData } = useOutletContext<Props>();

    const [period, setPeriod] = useState(1);

    const totalPeriods = eventData.data?.data.gameDetails?.settings.periods;
    const sportID = eventData?.data?.data.sportID;

    const { data: lineupData, isLoading: loadingLineup } =
        useGetGameLineupsQuery(
            {
                teamID: teamID ?? '',
                gameID: eventID ?? '',
            },
            {
                skip: !eventID || !teamID,
            }
        );

    const activeLineUp = useMemo(() => {
        return lineupData?.data.find((lineup) => lineup.period === period);
    }, [lineupData, period]);

    // Get formations for the given lineup
    const { data: formationsData, isLoading: loadingFormations } =
        useGetFormationQuery(
            {
                sportID: sportID ?? '',
                formationID: activeLineUp?.formationID ?? '',
            },
            {
                skip: !activeLineUp,
            }
        );

    const [fetchPlayers, { data: playerData, isLoading: loadingPlayers }] =
        useLazyGetPlayersQuery();

    const loadPlayers = (cursor: string, status = 'Active') => {
        if (teamID) {
            fetchPlayers(
                {
                    cursor,
                    status,
                    teamID,
                },
                true
            )
                .then((response) => {
                    if (response.data) {
                        // Auto paginate teams
                        if (
                            response.data.lastEvaluatedKey.cursor &&
                            response.data.lastEvaluatedKey.cursor !== cursor
                        ) {
                            loadPlayers(response.data.lastEvaluatedKey.cursor);
                        }
                    }
                })
                .catch((error) => {
                    console.error(
                        `Error fetching events for team ${teamID}`,
                        error
                    );
                });
        }
    };

    useEffect(() => {
        loadPlayers('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const periodOptions = useMemo(
        () =>
            totalPeriods
                ? Array.from({ length: totalPeriods }).map((u, i) => {
                      const period = i + 1;
                      const label = `${period}${periodSuffix(Number(period))}`;
                      return {
                          label: label,
                          value: period,
                      };
                  })
                : [],
        [totalPeriods]
    );

    const mergedFormationLineup = useMemo(():
        | LineupPlayerPosition[]
        | undefined => {
        return activeLineUp?.playerPositions.map((position) => {
            const pos = formationsData?.data.positions.find(
                (formation) => formation.positionID === position.positionID
            );

            const player = playerData?.data.find(
                (player) => player.playerID === position.playerID
            );

            return {
                ...position,
                ...pos,
                player,
            };
        }, []);
    }, [activeLineUp, formationsData, playerData]);

    const fieldPositions = mergedFormationLineup?.filter(
        (position) => position.status === PlayerPositionStatus.Field
    );

    const benchPositions = mergedFormationLineup?.filter(
        (position) => position.status === PlayerPositionStatus.Bench
    );

    const renderPlayerCard = (position: LineupPlayerPosition) => {
        return (
            <div
                key={position.positionID}
                className={`player-card player-card-${position.status.toLowerCase()} ${
                    sportID === 'Unavailable' ? 'unavailable' : ''
                }`}
                style={{
                    top: `${position.yPos}%`,
                    left: `${position.xPos}%`,
                }}
            >
                <div className="player-card_content">
                    <div className="player-card_id">
                        {position.positionName || position.status}
                    </div>
                    <div className="player-card_name">
                        {position.player?.displayName}
                    </div>
                </div>
            </div>
        );
    };

    const isLoading = loadingLineup || loadingFormations || loadingPlayers;

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <Toolbar
                style={{ border: 'none' }}
                center={
                    <SelectButton
                        value={period}
                        multiple={false}
                        allowEmpty={false}
                        onChange={(e) => setPeriod(e.value)}
                        options={periodOptions}
                    />
                }
            />

            <div className="event-lineup">
                <div
                    className={`formation-container ${
                        !mergedFormationLineup
                            ? 'formation-container-empty'
                            : ''
                    }`}
                >
                    <div className="formation-view">
                        <img
                            src={`https://cdn.hub.rookieme.com/sports_ffields/${sportID}_field-0.svg`}
                            alt="Field"
                            className="formation-view_field"
                        />

                        {mergedFormationLineup ? (
                            fieldPositions?.map(renderPlayerCard)
                        ) : (
                            <div className="formation-empty-msg">
                                <h3>No Line-up Set</h3>
                            </div>
                        )}
                    </div>
                    {mergedFormationLineup && (
                        <div className="formation-bench">
                            <h5>Bench</h5>
                            {benchPositions
                                ? benchPositions?.map((position) => (
                                      <div className="formation-bench-item">
                                          {position.player?.displayName}
                                      </div>
                                  ))
                                : 'Empty'}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default EventLineup;
