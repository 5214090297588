import { generatePath } from 'react-router-dom';
import { isEqual } from 'lodash';
import { createApi } from '@reduxjs/toolkit/query/react';

import { apiEndpoints } from './apiEndpoints';
import { baseQueryWithReauth } from '../util/baseQuery';

import {
    GameRole,
    GameRolesResponse,
    GameLineUp,
    GameLineUpResponse,
} from '../types/game';

export const gamesApi = createApi({
    reducerPath: 'gamesApi',
    baseQuery: (args, api, extraOptions) =>
        baseQueryWithReauth(
            args,
            api,
            extraOptions,
            process.env.REACT_APP_API_URL
        ),
    tagTypes: ['GameRole'],
    endpoints: (builder) => ({
        getGameRoles: builder.query<
            GameRolesResponse,
            {
                teamID: string;
                eventID: string;
                cursor?: string;
            }
        >({
            query: ({ teamID, eventID, ...params }) => ({
                url: generatePath(apiEndpoints.getGameRoles.url, {
                    teamID,
                    eventID,
                }),
                method: apiEndpoints.getGameRoles.method,
                params,
            }),
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(
                              ({ role: { roleID } }: GameRole) => ({
                                  type: 'GameRole' as const,
                                  roleID,
                              })
                          ),
                          'GameRole',
                      ]
                    : ['GameRole'];
            },
            serializeQueryArgs: ({
                endpointName,
                queryArgs: { cursor, ...args },
            }) => {
                return `${endpointName}(${JSON.stringify(args)})`;
            },
            merge: (currentCache, newItems, args) => {
                if (currentCache && newItems) {
                    if (
                        args.arg.cursor &&
                        currentCache?.lastEvaluatedKey !==
                            newItems?.lastEvaluatedKey
                    ) {
                        currentCache.data = [
                            ...currentCache.data,
                            ...newItems.data,
                        ];
                        currentCache.lastEvaluatedKey.cursor =
                            newItems.lastEvaluatedKey.cursor;
                    } else {
                        currentCache.data = newItems.data;
                        currentCache.lastEvaluatedKey =
                            newItems.lastEvaluatedKey;
                    }
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
        }),
        createGameLineups: builder.mutation<
            GameLineUp, // Assuming this is the expected response data type
            {
                teamID: string;
                gameID: string;
                body: {
                    lineUpName: string;
                    formationID: string;
                    period: number;
                    playerPositions: Array<{
                        playerID: string;
                        positionID: string;
                    }>;
                };
            }
        >({
            query: ({ teamID, gameID, body }) => ({
                url: generatePath(apiEndpoints.createGameLineups.url, {
                    teamID,
                    gameID,
                }),
                method: apiEndpoints.createGameLineups.method,
                body, // Pass the body here
            }),
        }),

        getGameLineups: builder.query<
            GameLineUpResponse,
            {
                teamID: string;
                gameID: string;
                cursor?: string;
            }
        >({
            query: ({ teamID, gameID, ...params }) => ({
                url: generatePath(apiEndpoints.getGameLineups.url, {
                    teamID,
                    gameID,
                }),
                method: apiEndpoints.getGameLineups.method,
                params,
            }),
        }),
        deleteGameLineups: builder.query<
            GameLineUp,
            {
                teamID: string;
                gameID: string;
                cursor?: string;
            }
        >({
            query: ({ teamID, gameID, ...params }) => ({
                url: generatePath(apiEndpoints.deleteGameLineups.url, {
                    teamID,
                    gameID,
                }),
                method: apiEndpoints.deleteGameLineups.method,
                params,
            }),
        }),
        updateGameLineups: builder.mutation<
            GameLineUp,
            {
                teamID: string;
                gameID: string;
                lineupID: string;
                body: {
                    lineUpName: string;
                    formationID: string;
                    period: number;
                    playerPositions: Array<{
                        playerID: string;
                        positionID: string;
                    }>;
                };
            }
        >({
            query: ({ teamID, gameID, lineupID, body }) => ({
                url: generatePath(apiEndpoints.updateGameLineups.url, {
                    teamID,
                    gameID,
                    lineupID,
                }),
                method: apiEndpoints.updateGameLineups.method,
                body, // Pass the body here
            }),
        }),
        updateGame: builder.mutation<
            any,
            { gameID: string; teamID: string; data: any }
        >({
            query: ({ teamID, gameID, data }) => ({
                url: generatePath(apiEndpoints.updateGame.url, {
                    teamID,
                    gameID,
                }),
                method: apiEndpoints.updateGame.method,
                body: data,
            }),
        }),
    }),
});

export const {
    useGetGameRolesQuery,
    useUpdateGameMutation,
    useGetGameLineupsQuery,
    useLazyGetGameLineupsQuery,
    useCreateGameLineupsMutation,
    useDeleteGameLineupsQuery,
    useUpdateGameLineupsMutation,
} = gamesApi;
