import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Formik, FormikErrors, FormikHelpers, FormikValues } from 'formik';
import { isEmpty } from 'lodash';

import { useCreateStaffInviteMutation } from '../../api/staff';
import { useGetEntityRolesQuery } from '../../api/core';
import { useGetOrganisationTeamsQuery } from '../../api/organisations';

import { getEntityFromParam } from '../../util/helper';
import { Mixpanel } from '../../util/mixpanel';

import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressBar } from 'primereact/progressbar';

import List from '../../components/List';
import ListItem from '../../components/ListItem';
import FormActions from '../../components/FormActions';
import FormFields from '../../components/FormFields';
import FormGroup from '../../components/FormGroup';
import RookieButton from '../../components/RookieButton';
import EntityAvatar from '../../components/EntityAvatar';
import Icon from '../../components/Icon';

import {
    BaseEntityType,
    EntityStructure,
    INPUT_TYPES,
} from '../../types/common';
import { StaffFormData } from '../../types/team';

interface Props {
    formHasActions?: boolean;
    initialValues: StaffFormData;
    onCancel?: Function;
    onSubmit?: Function;
}

const requiredFields = ['email', 'roles'];

const StaffForm = (props: Props) => {
    const params = useParams();
    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);

    const [showRoleHelp, setShowRoleHelp] = useState(false);
    const [showCustomTeamRoles, setShowCustomTeamRoles] = useState(false);
    const [selectedTeams, setSelectedTeams] = useState<string[]>([]); // To store selected team IDs
    const [selectedTeamRole, setSelectedTeamRole] = useState<string>(''); // To store selected team IDs
    const [teamRoles, setTeamRoles] = useState<{
        [teamID: string]: string;
    }>({});

    const { formHasActions, initialValues, onSubmit } = props;

    const { data: staffRolesData } = useGetEntityRolesQuery(
        {
            entityType: activeEntity?.entityType as BaseEntityType,
            entityID: activeEntity?.entityID ?? '',
            assignable: true,
        },
        { skip: !activeEntity }
    );

    useEffect(() => {
        selectedTeams.forEach((teamID) => {
            if (!teamRoles[teamID]) {
                handleTeamRolesChange(teamID, 'teamsViewer');
            }
        });
    }, [selectedTeams, teamRoles]);

    // Fetch organisation teams only when orgID is valid
    const { data: orgTeams } = useGetOrganisationTeamsQuery(
        { organisationID: activeEntity?.entityID },
        { skip: activeEntity?.entityType !== BaseEntityType.organisations }
    );

    const [createStaff] = useCreateStaffInviteMutation();

    const teamsList = orgTeams?.data || [];

    const handleSubmit = async (
        data: StaffFormData,
        { setSubmitting }: FormikHelpers<StaffFormData>
    ) => {
        if (!activeEntity) return;

        setSubmitting(true); // Set loading state to true

        let formData: StaffFormData & EntityStructure = {
            entityType: activeEntity?.entityType,
            entityID: activeEntity?.entityID,
            email: data.email,
            roles: data.roles,
            message: data.message,
        };

        if (activeEntity?.entityType === BaseEntityType.organisations) {
            formData.addToTeams = selectedTeams.map((teamID) => ({
                teamID,
                roles: !showCustomTeamRoles
                    ? [selectedTeamRole]
                    : [teamRoles[teamID] || ''], // Ensure role is assigned, default to empty if not selected
            })) as [{ teamID: string; roles: [string] }];
        }

        try {
            const response = await createStaff(formData);

            Mixpanel.track('Invite Staff');

            // Handle response
            if ('errors' in response) {
                onSubmit && onSubmit('error', response); // If errors exist, pass the error response
            } else {
                onSubmit && onSubmit('success'); // If no errors, pass success
            }
        } catch (err: any) {
            onSubmit && onSubmit('error', err); // If any error occurs during the async call
        } finally {
            setSubmitting(false); // Always stop the loading state
        }
    };

    const staffFormValidation = (values: FormikValues) => {
        const errors: FormikErrors<StaffFormData> = {};

        requiredFields.forEach((field) => {
            if (!values[field] || isEmpty(values[field])) {
                errors[field as keyof StaffFormData] = 'Field cannot be blank';
            } else if (
                values['email'] &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    values['email']
                )
            ) {
                errors['email' as keyof StaffFormData] =
                    'Invalid email address';
            }
        });

        return errors;
    };

    const initial: StaffFormData = {
        ...initialValues,
        email: '',
        roles: [],
        message: '',
    };

    // Function to handle checkbox change
    const handleTeamSelect = (teamID: string) => {
        setSelectedTeams((prev) => {
            if (prev.includes(teamID)) {
                return prev.filter((id) => id !== teamID); // Unselect team if it's already selected
            }
            return [...prev, teamID]; // Select team
        });
    };

    const handleTeamRolesChange = (teamID: string, role: string) => {
        setTeamRoles((prev) => ({
            ...prev,
            [teamID]: role,
        }));
    };

    const staffRoles = useMemo(() => {
        return (staffRolesData?.data || []).filter((roleData) => {
            const roleID = roleData.role.roleID;
            return (
                !roleID.includes('Owner') &&
                !roleID.includes('organisationsTeamsAdmin')
            );
        });
    }, [staffRolesData]);

    const renderTeamInvite =
        activeEntity?.entityType === BaseEntityType.organisations &&
        teamsList.length > 0 ? (
            <>
                <FormGroup>
                    <div>
                        <h3>
                            <span>Invite to Teams</span>
                            <RookieButton
                                icon="info"
                                size="small"
                                text
                                tooltipOptions={{
                                    position: 'left',
                                }}
                                style={{
                                    verticalAlign: 'middle',
                                    opacity: 0.5,
                                }}
                                tooltip="The user will not be able to
                        use any teams on Rookie Me Play or
                        Connect unless they are accept their invite."
                            />
                        </h3>
                        <p style={{ fontSize: '14px' }}>
                            Would you like to also invite this staff member to
                            any teams?
                        </p>
                    </div>
                </FormGroup>
                <FormGroup label="Teams">
                    <MultiSelect
                        name="selectedTeams"
                        value={selectedTeams}
                        options={teamsList}
                        onChange={(e) => setSelectedTeams(e.value)}
                        optionLabel="teamName"
                        optionValue="teamID"
                        style={{ width: '100%' }}
                        maxSelectedLabels={1}
                        selectedItemsLabel={`${selectedTeams.length} teams selected`}
                        placeholder="Select team(s)"
                        disabled={showCustomTeamRoles}
                        selectAllLabel="Select All"
                        itemTemplate={(team) => {
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem',
                                        marginLeft: '0.5rem',
                                    }}
                                >
                                    <EntityAvatar
                                        entityType={BaseEntityType.teams}
                                        entityID={team.teamName}
                                        entityName={team.teamName}
                                    />

                                    <span>{team.teamName}</span>
                                </div>
                            );
                        }}
                    />
                </FormGroup>
                <FormGroup label="Team Role">
                    <Dropdown
                        value={selectedTeamRole}
                        onChange={(e) => setSelectedTeamRole(e.value)}
                        options={[
                            {
                                label: 'Viewer',
                                value: 'teamsViewer',
                            },
                            {
                                label: 'Admin',
                                value: 'teamsAdmin',
                            },
                        ]}
                        placeholder="Select Role"
                        disabled={showCustomTeamRoles}
                    />
                </FormGroup>
                <FormGroup>
                    <div
                        className="p-panel p-panel-toggleable"
                        style={{
                            width: '100%',
                        }}
                    >
                        <div
                            className="p-panel-header"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                cursor: 'pointer',
                            }}
                            onClick={() =>
                                setShowCustomTeamRoles((prev) => !prev)
                            }
                        >
                            <span className="p-panel-title">Custom Roles</span>
                            <span className="p-panel-icons">
                                <Icon
                                    name={
                                        showCustomTeamRoles
                                            ? 'arrow_drop_up'
                                            : 'arrow_drop_down'
                                    }
                                />
                            </span>
                        </div>
                        {showCustomTeamRoles && (
                            <div className="p-toggleable-content">
                                <div className="p-panel-content">
                                    <ListItem
                                        title="Select All"
                                        disablePadding
                                        start={
                                            <Checkbox
                                                inputId={'selectAllTeams'}
                                                name={'selectAllTeams'}
                                                value={'selectAllTeams'}
                                                onChange={() =>
                                                    setSelectedTeams((prev) =>
                                                        prev.length !==
                                                        teamsList.length
                                                            ? teamsList.map(
                                                                  (team) =>
                                                                      team.teamID
                                                              )
                                                            : []
                                                    )
                                                }
                                                checked={
                                                    selectedTeams.length ===
                                                    teamsList.length
                                                }
                                            />
                                        }
                                    />
                                    {teamsList.map((team) => {
                                        const isSelected =
                                            selectedTeams.includes(team.teamID);
                                        return (
                                            <ListItem
                                                key={team.teamID}
                                                disablePadding
                                                start={
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems:
                                                                'center',
                                                            gap: '1rem',
                                                        }}
                                                    >
                                                        <Checkbox
                                                            inputId={
                                                                team.teamID
                                                            }
                                                            name={team.teamID}
                                                            value={
                                                                team.teamName
                                                            }
                                                            onChange={() =>
                                                                handleTeamSelect(
                                                                    team.teamID
                                                                )
                                                            }
                                                            checked={isSelected}
                                                        />
                                                        <EntityAvatar
                                                            entityType={
                                                                BaseEntityType.teams
                                                            }
                                                            entityID={
                                                                team.teamName
                                                            }
                                                            entityName={
                                                                team.teamName
                                                            }
                                                        />
                                                    </div>
                                                }
                                                title={
                                                    <label
                                                        htmlFor={team.teamID}
                                                        style={{
                                                            fontSize: 'inherit',
                                                            color: 'inherit',
                                                            fontWeight:
                                                                'inherit',
                                                        }}
                                                    >
                                                        {team.teamName}
                                                    </label>
                                                }
                                                end={
                                                    isSelected && (
                                                        <Dropdown
                                                            value={
                                                                teamRoles[
                                                                    team.teamID
                                                                ] || ''
                                                            }
                                                            onChange={(e) =>
                                                                handleTeamRolesChange(
                                                                    team.teamID,
                                                                    e.value
                                                                )
                                                            }
                                                            options={[
                                                                {
                                                                    label: 'Viewer',
                                                                    value: 'teamsViewer',
                                                                },
                                                                {
                                                                    label: 'Admin',
                                                                    value: 'teamsAdmin',
                                                                },
                                                            ]}
                                                            required={selectedTeams.includes(
                                                                team.teamID
                                                            )}
                                                        />
                                                    )
                                                }
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </FormGroup>
            </>
        ) : null;

    return (
        <>
            <Formik
                initialValues={initial}
                onSubmit={handleSubmit}
                validate={staffFormValidation}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <FormFields>
                                <FormGroup
                                    label="Email"
                                    htmlFor="email"
                                    error={errors.email}
                                    showError={!!errors.email && touched.email}
                                >
                                    <InputText
                                        id="email"
                                        name="email"
                                        type={INPUT_TYPES.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        required
                                        placeholder="Enter staff email address"
                                        disabled={isSubmitting}
                                    />
                                </FormGroup>
                                <FormGroup
                                    label="Role(s)"
                                    error={errors.roles}
                                    showError={!!errors.roles}
                                >
                                    <div className="p-inputgroup">
                                        <Dropdown
                                            name="roles"
                                            value={
                                                values.roles && values.roles[0]
                                            }
                                            onChange={(e) =>
                                                setFieldValue('roles', [
                                                    e.value,
                                                ])
                                            }
                                            options={staffRoles}
                                            optionLabel="role.roleName"
                                            optionValue="role.roleID"
                                            style={{ width: '100%' }}
                                            placeholder="Select role"
                                            required
                                        />
                                        <span
                                            className="p-inputgroup-addon"
                                            style={{ padding: '0' }}
                                        >
                                            <RookieButton
                                                icon="help"
                                                text
                                                onClick={() =>
                                                    setShowRoleHelp(true)
                                                }
                                            />
                                        </span>
                                    </div>
                                </FormGroup>

                                {renderTeamInvite}

                                <FormGroup
                                    label="Message (optional)"
                                    htmlFor="message"
                                >
                                    <InputTextarea
                                        id="message"
                                        name="message"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.message}
                                        rows={5}
                                        placeholder="Write a message for your staff"
                                    />
                                </FormGroup>
                            </FormFields>

                            {formHasActions && (
                                <FormActions
                                    start={
                                        <RookieButton
                                            severity="secondary"
                                            disabled={isSubmitting}
                                            onClick={() =>
                                                props.onCancel &&
                                                props.onCancel()
                                            }
                                            type="button"
                                            label="Cancel"
                                        />
                                    }
                                    end={
                                        <RookieButton
                                            className={
                                                isSubmitting
                                                    ? 'is-submitting'
                                                    : undefined
                                            }
                                            type="submit"
                                            disabled={isSubmitting}
                                            loading={isSubmitting}
                                            label="Send Invite"
                                        />
                                    }
                                />
                            )}

                            {isSubmitting && (
                                <div className="form-overlay">
                                    <ProgressBar mode="indeterminate" />
                                </div>
                            )}
                        </form>
                    );
                }}
            </Formik>

            <Dialog
                header="Roles"
                visible={showRoleHelp}
                onHide={() => setShowRoleHelp(false)}
                style={{ maxWidth: '600px' }}
            >
                <List>
                    {staffRoles.map((roleData) => {
                        const { roleID, roleName, roleDescription } =
                            roleData.role;

                        return (
                            <ListItem
                                key={roleID}
                                multiline
                                caption={roleDescription}
                                title={
                                    <label
                                        htmlFor={roleID}
                                        style={{
                                            fontSize: 'inherit',
                                        }}
                                    >
                                        {roleName}
                                    </label>
                                }
                            />
                        );
                    })}
                </List>
            </Dialog>
        </>
    );
};

StaffForm.defaultProps = {
    formHasActions: true,
    initialValues: {
        email: '',
    },
};

export default StaffForm;
