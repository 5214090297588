import { Dialog } from 'primereact/dialog';

import PageHeader from '../../layout/PageHeader';
import PageContainer from '../../layout/PageContainer';

import PostForm from './PostForm';
import PostItem from './PostItem';
import RookieButton from '../../components/RookieButton';
import { Message } from 'primereact/message';

import { Post } from '../../types/posts';
import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { BaseEntityType } from '../../types/common';
import Loader from '../../components/Loader';
import ProductTag from '../../components/ProductTag';
import { RookieProduct } from '../../types/licences';

interface Props {
    entityType?: BaseEntityType;
    entityID?: string;
    data: Post[];
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
    onLoadMore?: () => void;
    onDelete?: (postID: string) => void;
    onEdit?: (post: Post) => void;
    roles: Roles;
    showPagination: boolean;
    onCloseCreateModal: () => void;
    onOpenCreateModal: () => void;
    showComposerModal: boolean;
    editing: Post | null;
    route: Route;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const PostsView = (props: Props) => {
    return (
        <>
            <PageContainer>
                <Message
                    text="Posts are currently in early access. Future updates may change functionality and access."
                    style={{
                        width: '100%',
                        borderRadius: 0,
                    }}
                    className="is-subtle"
                    severity="info"
                />
            </PageContainer>
            <PageContainer size="narrow">
                <PageHeader
                    title="Posts"
                    actions={[
                        {
                            component: (
                                <div className="integrated-msg">
                                    <span>Works with</span>
                                    <ProductTag
                                        product={RookieProduct.Connect}
                                    />
                                </div>
                            ),
                        },
                    ]}
                />
                {props.isLoading ? (
                    <Loader />
                ) : (
                    <div className="posts-listing">
                        {props.entityID && props.entityType && (
                            <PostForm
                                entityType={props.entityType}
                                entityID={props.entityID}
                            />
                        )}
                        {props.data.map((post) => (
                            <PostItem
                                key={post.postID}
                                post={post}
                                entityType={props.entityType}
                                entityID={props.entityID}
                                onEdit={props.onEdit}
                                onDelete={props.onDelete}
                            />
                        ))}
                        {props.showPagination && props.onLoadMore && (
                            <RookieButton
                                label="Load More"
                                onClick={props.onLoadMore}
                                size="large"
                                className="load-more"
                                severity="secondary"
                                loading={props.isFetching}
                            />
                        )}
                    </div>
                )}
                {props.entityType && props.entityID && props.editing && (
                    <Dialog
                        onHide={props.onCloseCreateModal}
                        visible={props.showComposerModal}
                        header="Edit Post"
                        resizable={false}
                        draggable={false}
                    >
                        <PostForm
                            post={props.editing}
                            entityType={props.entityType}
                            entityID={props.entityID}
                            onUpdate={() => props.onCloseCreateModal()}
                            onError={() => props.onCloseCreateModal()}
                        />
                    </Dialog>
                )}
            </PageContainer>
        </>
    );
};

export default PostsView;
