import { useMemo } from 'react';
import { useLazyGetTeamParticipatingSeasonsQuery } from '../../../api/seasons';

import Loader from '../../../components/Loader';
import { Dropdown, DropdownProps } from 'primereact/dropdown';

import { Season } from '../../../types/seasons';

interface Props extends DropdownProps {
    teamID?: string;
    initialOptions?: Season[];
}

const SeasonSelect = ({ teamID, initialOptions, ...otherProps }: Props) => {
    const [fetchTeamSeasons, { data, isUninitialized, isLoading, isFetching }] =
        useLazyGetTeamParticipatingSeasonsQuery();

    const loadSeasons = (cursor: string) => {
        if (teamID) {
            fetchTeamSeasons({
                teamID,
                cursor: cursor,
            })
                .then((response) => {
                    if (response.data) {
                        // Auto paginate teams
                        if (
                            response.data.lastEvaluatedKey.cursor &&
                            response.data.lastEvaluatedKey.cursor !== cursor
                        ) {
                            loadSeasons(response.data.lastEvaluatedKey.cursor);
                        }
                    }
                })
                .catch((error) => {
                    console.error(
                        `Error fetching seasons for team ${teamID}:`,
                        error
                    );
                });
        }
    };

    const handleDropdownOpen = () => {
        if (isUninitialized) {
            loadSeasons('');
        }
    };

    const options = useMemo(() => {
        const seasons =
            isUninitialized && initialOptions ? initialOptions : data?.data;

        return seasons?.map((season) => ({
            label: season.seasonName,
            value: season.seasonID,
        }));
    }, [data, initialOptions, isUninitialized]);

    const loading = isLoading || isFetching;

    return (
        <Dropdown
            {...otherProps}
            onShow={handleDropdownOpen}
            options={options}
            loading={loading}
            emptyMessage={loading && <Loader />}
        />
    );
};

export default SeasonSelect;
