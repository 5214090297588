import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { uniqueId } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';

import { Action, ERROR_TYPES } from '../types/common';

import { ReactComponent as Esg404 } from '../assets/images/states/esg_404.svg';
import { ReactComponent as EsgMaintenance } from '../assets/images/states/esg_maintenance.svg';
import { ReactComponent as EsgEmpty } from '../assets/images/states/esg_empty.svg';
import { ReactComponent as EsgEmptyDirectory } from '../assets/images/states/esg_empty-directory.svg';
import { ReactComponent as EsgNotFound } from '../assets/images/states/esg_unable-to-locate.svg';
import { ReactComponent as EsgFileNotFound } from '../assets/images/states/esg_file-not-found.svg';
import { ReactComponent as EsgEmptyInbox } from '../assets/images/states/esg_no-messages.svg';
import { ReactComponent as EsgSomethingWentWrong } from '../assets/images/states/esg_something-went-wrong.svg';
import { ReactComponent as EsgUnplugged } from '../assets/images/states/esg_unplugged.svg';
import RookieButton from './RookieButton';

interface Props {
    actions?: Action[];
    alignment?: 'left' | 'middle' | 'right';
    desc?: string;
    errorType?: ERROR_TYPES;
    handleReset?(): Function | void;
    hasReturn?: boolean;
    hasReporting?: boolean;
    proportion?: 'compact' | 'regular' | 'enlarged' | 'fullscreen';
    title: React.ReactNode;
}

const ErrorDisplay = (props: Props) => {
    const {
        actions,
        alignment,
        desc,
        errorType,
        handleReset,
        hasReporting,
        hasReturn,
        proportion,
        title,
    } = props;

    const { user } = useAuth0();
    const navigate = useNavigate();
    const location = useLocation();

    const getHeaderTag = useMemo(() => {
        if (proportion) {
            switch (proportion) {
                case 'compact':
                    return 'h4';
                case 'regular':
                    return 'h3';
                case 'enlarged':
                    return 'h2';
                case 'fullscreen':
                    return 'h1';
                default:
                    return 'h3';
            }
        }
    }, [proportion]);
    const HeaderTag = getHeaderTag;

    const getButtonSize = useMemo(() => {
        if (proportion) {
            switch (proportion) {
                case 'compact':
                    return 'small';
                case 'enlarged':
                    return 'large';
                case 'fullscreen':
                    return 'large';
                default:
                    return undefined;
            }
        }
    }, [proportion]);

    /***
     * @desc apply error state graphics (esg) to a custom tag based on returned state prop
     * @param proportion: "left" | "middle" | "right"
     */
    const getAlignment = useMemo(() => {
        switch (alignment) {
            case 'left':
                return 'is-aligned--left';
            case 'middle':
                return '';
            case 'right':
                return 'is-aligned--right';
            default:
                return '';
        }
    }, [alignment]);

    /***
     * @desc apply error state graphics (esg) to a custom tag based on returned errorType prop
     * @param ErrorTypes
     */
    const getEmptyStateGraphic = useMemo(() => {
        switch (errorType) {
            case ERROR_TYPES.pageNotFound:
                return Esg404;
            case ERROR_TYPES.maintenance:
                return EsgMaintenance;
            case ERROR_TYPES.empty:
                return EsgEmpty;
            case ERROR_TYPES.emptyFolder:
                return EsgEmptyDirectory;
            case ERROR_TYPES.notFound:
                return EsgNotFound;
            case ERROR_TYPES.fileError:
                return EsgFileNotFound;
            case ERROR_TYPES.inboxEmpty:
                return EsgEmptyInbox;
            case ERROR_TYPES.somethingsWrong:
                return EsgSomethingWentWrong;
            case ERROR_TYPES.unplugged:
                return EsgUnplugged;
            default:
                return EsgSomethingWentWrong;
        }
    }, [errorType]);
    const EsgTag = getEmptyStateGraphic;

    /**
     * @desc map over actions prop and render buttons
     * @return JSX
     */
    const actionsMap =
        actions &&
        actions.map((action, i) => {
            return (
                <RookieButton
                    key={`error-action-${i} `}
                    severity={action.severity}
                    className={action.className ? action.className : ''}
                    size={getButtonSize}
                    onClick={action.onClick}
                    icon={action.icon}
                    label={action.label}
                />
            );
        });

    const renderBackButton = () => {
        if (location.key && location.key !== 'default') {
            return (
                <RookieButton
                    onClick={() => navigate(-1)}
                    severity="secondary"
                    icon="keyboard_return"
                    label="Go Back"
                />
            );
        } else {
            return (
                <RookieButton
                    onClick={() => navigate('/')}
                    severity="secondary"
                    icon="keyboard_return"
                    label="Go Home"
                />
            );
        }
    };

    // boolean check for any available action
    const actionCheck = actions || handleReset || hasReporting || hasReturn;
    return (
        <div
            className={`error-display ${getAlignment} error-display-${proportion}`}
        >
            <EsgTag
                className={`error-display_state-graphic is-${proportion}`}
            />
            {HeaderTag && (
                <HeaderTag className="error-display_title">{title}</HeaderTag>
            )}
            {desc && <p className="error-display_desc">{desc}</p>}
            {actionCheck && (
                <div className={`error-display_button-group is-${proportion}`}>
                    {handleReset && (
                        <RookieButton
                            onClick={() => handleReset()}
                            icon="restart_alt"
                            label="Retry"
                        />
                    )}
                    {hasReturn && renderBackButton()}
                    {hasReporting && (
                        <RookieButton
                            onClick={() =>
                                Sentry.showReportDialog({
                                    logger: uniqueId('error-' + title + '-'),
                                    transaction: location,
                                    user: user && {
                                        name:
                                            user.firstName +
                                            ' ' +
                                            user.lastName,
                                        email: user.email,
                                    },
                                })
                            }
                            severity="secondary"
                            icon="rate_review"
                            label="Submit Feedback"
                        />
                    )}
                    {actions && actionsMap}
                </div>
            )}
        </div>
    );
};

ErrorDisplay.defaultProps = {
    alignment: 'middle',
    hasReporting: false,
    hasReturn: true,
    proportion: 'regular',
    title: 'An error has occurred',
};

export default ErrorDisplay;
