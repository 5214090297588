import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetAssociationQuery } from '../../api/associations';
import { ToastContext } from '../../contexts/ToastContext';

import TransferEntityForm from '../users/TransferEntityForm';
import AssociationForm from './AssociationForm';
import PageContainer from '../../layout/PageContainer';
import DocumentHead from '../../components/DocumentHead';
import ErrorDisplay from '../../components/ErrorDisplay';
import RookieButton from '../../components/RookieButton';
import Loader from '../../components/Loader';
import { Sidebar } from 'primereact/sidebar';

import {
    BaseEntityType,
    ERROR_TYPES,
    FormSubmitCallback,
} from '../../types/common';
import { User } from '../../types/user';

interface Props {
    user?: User;
}

const AssociationSettings = (props: Props) => {
    const { associationID } = useParams();

    const toast = useContext(ToastContext);

    const [showTransferModal, setShowTransferModal] = useState(false);

    const navigate = useNavigate();

    // API Calls
    const { data, isLoading, isError } = useGetAssociationQuery(
        { associationID },
        {
            skip: !associationID,
        }
    );

    const handleSubmitComplete: FormSubmitCallback = (
        status,
        action,
        _,
        error
    ) => {
        if (status === 'success') {
            toast?.current?.show({
                severity: 'success',
                summary: 'Success',
                detail:
                    action === 'create'
                        ? `The association has been created successfully.`
                        : `The association has been updated successfully.`,
            });
        } else if (status === 'error' && error) {
            toast?.current?.show({
                severity: 'warn',
                summary: 'Error',
                detail: error.message,
            });
        } else {
            toast?.current?.show({
                severity: 'warn',
                summary: 'Error',
                detail: 'Something went wrong. Please try again.',
            });
        }
    };

    if (isLoading) {
        return <Loader size="fullscreen" />;
    }

    if (isError) {
        return (
            <ErrorDisplay
                actions={[
                    {
                        onClick: () => navigate(0), // refresh
                        icon: 'refresh',
                        label: 'Retry',
                    },
                ]}
                desc="We were unable to load your Association Settings, try again or contact Association Owner."
                errorType={ERROR_TYPES.notFound}
                hasReturn
                proportion="fullscreen"
                title="No Roles Returned"
            />
        );
    }

    return (
        <>
            <DocumentHead
                title="Association Settings - Rookie Me Hub"
                description="Association settings page"
            />
            <PageContainer size="narrow">
                {data && (
                    <AssociationForm
                        associationID={data.data?.associationID}
                        initialValues={data?.data}
                        onSubmitComplete={handleSubmitComplete}
                    />
                )}
                <RookieButton
                    label="Transfer Ownership"
                    onClick={() => setShowTransferModal(true)}
                />
            </PageContainer>

            <Sidebar
                header="Transfer Ownership"
                onHide={() => setShowTransferModal(false)}
                visible={showTransferModal}
                position="right"
            >
                {associationID && (
                    <TransferEntityForm
                        entityType={BaseEntityType.associations}
                        entityID={associationID}
                        onSuccess={() => setShowTransferModal(false)}
                    />
                )}
            </Sidebar>
        </>
    );
};

export default AssociationSettings;
