import { createApi } from '@reduxjs/toolkit/query/react';
import { generatePath } from 'react-router-dom';
import { isEqual } from 'lodash';

import { apiEndpoints } from './apiEndpoints';
import { baseQueryWithReauth } from '../util/baseQuery';

import {
    StaffForm,
    UserDetailResponse,
    UserDetailsResponse,
} from '../types/user';
import { StaffFormData, StaffResponse } from '../types/team';
import { Ticket, TicketsResponse } from '../types/ticket';
import { EntityStructure } from '../types/common';

export const staffApi = createApi({
    reducerPath: 'staffApi',
    baseQuery: (args, api, extraOptions) =>
        baseQueryWithReauth(
            args,
            api,
            extraOptions,
            process.env.REACT_APP_API_URL
        ),
    tagTypes: ['Staff', 'StaffInvite', 'OrganisationStaff', 'AssociationStaff'],
    endpoints: (builder) => ({
        getStaff: builder.query<
            StaffResponse,
            EntityStructure & { cursor: string }
        >({
            query: ({ entityType, entityID, cursor }) => ({
                url: generatePath(apiEndpoints.getStaff.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.getStaff.method,
                params: {
                    cursor,
                },
            }),
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ userID }) => ({
                              type: 'Staff' as const,
                              userID,
                          })),
                          'Staff',
                      ]
                    : ['Staff'];
            },
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (
                    currentCache.lastEvaluatedKey &&
                    newItems.lastEvaluatedKey &&
                    !isEqual(
                        currentCache.lastEvaluatedKey,
                        newItems.lastEvaluatedKey
                    )
                ) {
                    currentCache.data = currentCache.data.concat(newItems.data);
                    currentCache.lastEvaluatedKey = newItems.lastEvaluatedKey;
                } else {
                    currentCache.data = newItems.data;
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
        }),
        createEntityStaff: builder.mutation<
            void,
            EntityStructure & { userID: string; entityName: string }
        >({
            query: ({ entityType, entityID, userID, entityName, ...data }) => ({
                url: generatePath(apiEndpoints.createEntityStaff.url, {
                    entityType,
                    entityID,
                    entityName,
                    userID,
                    body: JSON.stringify({
                        userID: userID,
                        entityName,
                        roles: ['teamsAdmin'],
                    }),
                }),
                method: apiEndpoints.createEntityStaff.method,
                body: JSON.stringify({
                    userID: userID,
                    entityName,
                    roles: ['teamsAdmin'],
                }),
            }),
            invalidatesTags: ['Staff'],
        }),
        getStaffDetails: builder.query<
            UserDetailsResponse,
            EntityStructure & { cursor?: string }
        >({
            query: ({ entityType, entityID, cursor }) => ({
                url: generatePath(apiEndpoints.getStaffDetails.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.getStaffDetails.method,
                params: {
                    cursor,
                },
            }),
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ userID }) => ({
                              type: 'Staff' as const,
                              userID,
                          })),
                          'Staff',
                      ]
                    : ['Staff'];
            },
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName;
            },
            merge: (currentCache, newItems) => {
                if (
                    currentCache.lastEvaluatedKey &&
                    newItems.lastEvaluatedKey &&
                    !isEqual(
                        currentCache.lastEvaluatedKey,
                        newItems.lastEvaluatedKey
                    )
                ) {
                    currentCache.data = currentCache.data.concat(newItems.data);
                    currentCache.lastEvaluatedKey = newItems.lastEvaluatedKey;
                } else {
                    currentCache.data = newItems.data;
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
        }),
        getStaffSingle: builder.query<
            UserDetailResponse,
            EntityStructure & { userID: string }
        >({
            query: ({ entityType, entityID, userID }) => ({
                url: generatePath(apiEndpoints.getStaffSingle.url, {
                    entityType,
                    entityID,
                    userID,
                }),
                method: apiEndpoints.getStaffSingle.method,
            }),
            providesTags: ['Staff'],
        }),
        getStaffDetailsSingle: builder.query<
            UserDetailResponse,
            EntityStructure & { userID: string }
        >({
            query: ({ entityType, entityID, userID }) => ({
                url: generatePath(apiEndpoints.getStaffDetailsSingle.url, {
                    entityType,
                    entityID,
                    userID,
                }),
                method: apiEndpoints.getStaffDetailsSingle.method,
            }),
            providesTags: ['Staff'],
        }),
        updateStaff: builder.mutation<void, EntityStructure & StaffForm>({
            query: ({ entityType, entityID, userID, ...data }) => ({
                url: generatePath(apiEndpoints.updateStaff.url, {
                    entityType,
                    entityID,
                    userID,
                }),
                method: apiEndpoints.updateStaff.method,
                body: data,
            }),
            invalidatesTags: ['Staff'],
        }),
        deleteStaff: builder.mutation<
            void,
            EntityStructure & { userID: string }
        >({
            query: ({ entityType, entityID, userID }) => ({
                url: generatePath(apiEndpoints.deleteStaff.url, {
                    entityType,
                    entityID,
                    userID,
                }),
                method: apiEndpoints.deleteStaff.method,
            }),
            invalidatesTags: ['Staff'],
        }),

        /**
         *
         * Staff Invites
         *
         **/

        getStaffInvites: builder.query<TicketsResponse, EntityStructure>({
            query: ({ entityType, entityID }) => ({
                url: generatePath(apiEndpoints.getStaffInvites.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.getStaffInvites.method,
            }),
            providesTags: ['StaffInvite'],
        }),
        createStaffInvite: builder.mutation<
            Ticket,
            EntityStructure & StaffFormData
        >({
            query: ({ entityType, entityID, ...data }) => ({
                url: generatePath(apiEndpoints.createStaffInvite.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.createStaffInvite.method,
                body: data,
            }),
            invalidatesTags: ['StaffInvite'],
        }),
        resendStaffInvite: builder.mutation<
            Ticket,
            EntityStructure & { ticketID: string }
        >({
            query: ({ entityType, entityID, ticketID }) => ({
                url: generatePath(apiEndpoints.resendTicket.url, {
                    entityType,
                    entityID,
                    ticketID,
                }),
                method: apiEndpoints.resendTicket.method,
            }),
            invalidatesTags: ['Staff', 'StaffInvite'],
        }),
        revokeStaffInvite: builder.mutation<
            Ticket,
            EntityStructure & { ticketID: string }
        >({
            query: ({ entityType, entityID, ticketID }) => ({
                url: generatePath(apiEndpoints.revokeTicket.url, {
                    entityType,
                    entityID,
                    ticketID,
                }),
                method: apiEndpoints.revokeTicket.method,
            }),
            invalidatesTags: ['Staff', 'StaffInvite'],
        }),
    }),
});

export const {
    useGetStaffQuery,
    useCreateEntityStaffMutation,
    useGetStaffSingleQuery,
    useUpdateStaffMutation,
    useDeleteStaffMutation,
    useResendStaffInviteMutation,
    useGetStaffDetailsQuery,
    useLazyGetStaffDetailsQuery,
    useCreateStaffInviteMutation,
    useRevokeStaffInviteMutation,
    useGetStaffDetailsSingleQuery,
    useGetStaffInvitesQuery,
} = staffApi;
