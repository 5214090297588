import { TooltipProps } from 'primereact/tooltip';

export const defaultReportState = {
    data: undefined,
    error: undefined,
    isError: false,
    isLoading: false,
    isSuccess: false,
    isUninitialized: true,
};

export const defaultTooltipOptions: TooltipProps = {
    position: 'top',
    showDelay: 500,
};

export const niceFlags = {
    skippedTime: '• Clock Integirty',
    shortLongPeriod: '• Unexpected Period Length',
    playersWithZeroPGT: '• Absent Players Included in Game',
    outsideSchedTime: '• Unexpected Game Start Time',
    forceEndGame: '• Game Not Ended',
};
