import Icon from '../../../components/Icon';
import ListItem from '../../../components/ListItem';
import ListHeader from '../../../components/ListHeader';
import { Checkbox } from 'primereact/checkbox';
import { InputSwitch } from 'primereact/inputswitch';
import { ListBox } from 'primereact/listbox';
import { OrderList } from 'primereact/orderlist';
import { RadioButton } from 'primereact/radiobutton';
import { SelectItem } from 'primereact/selectitem';
import { ReportColumn } from '../../../types/reports';

interface Props {
    categories: SelectItem[];
    columns: ReportColumn[];
    emptyColumns: string[];
    hiddenColumns: string[];
    hideEmptyColumns: boolean;
    selectedCategory: string;
    onCategoryChange: (categoryID: string) => void;
    onColumnsChange: (columnID: string) => void;
    onHideEmptyColumns: (hide: boolean) => void;
    onHideColumn: (columnID: string) => void;
}

const ColumnEditPanel = ({
    categories,
    columns,
    emptyColumns,
    hiddenColumns,
    hideEmptyColumns,
    selectedCategory,
    onCategoryChange,
    onColumnsChange,
    onHideEmptyColumns,
    onHideColumn,
}: Props) => {
    return (
        <>
            <div className="column-popover-empty">
                <ListItem
                    compact
                    title="Hide Empty Columns"
                    end={
                        <InputSwitch
                            checked={hideEmptyColumns}
                            onChange={(e) => onHideEmptyColumns(e.value)}
                        />
                    }
                />
            </div>

            <div className="column-popover-presets">
                <ListHeader>Presets</ListHeader>
                <ListBox
                    itemTemplate={(option) => (
                        <ListItem
                            compact
                            title={option.label}
                            start={
                                <RadioButton
                                    checked={option.value === selectedCategory}
                                />
                            }
                        />
                    )}
                    value={selectedCategory}
                    options={categories}
                    onChange={(e) => {
                        onCategoryChange(e.value);
                    }}
                />
            </div>
            <div className="column-popover-columns">
                <ListHeader>Columns</ListHeader>
                <OrderList
                    dataKey="id"
                    className={selectedCategory !== '' ? 'is-disabled' : ''}
                    value={columns}
                    moveUpIcon={<Icon name="stat_1" />}
                    moveDownIcon={<Icon name="stat_minus_1" />}
                    moveTopIcon={<Icon name="stat_2" />}
                    moveBottomIcon={<Icon name="stat_minus_2" />}
                    dragdrop={selectedCategory === ''}
                    itemTemplate={(item) => {
                        const isDisabled =
                            selectedCategory !== '' ||
                            (hideEmptyColumns && emptyColumns.includes(item));
                        return (
                            <ListItem
                                compact
                                disabled={isDisabled}
                                title={`${item.name} (${item.shortName})`}
                                start={
                                    <>
                                        <Icon
                                            name="drag_handle"
                                            style={{ opacity: 0.4 }}
                                        />
                                        <Checkbox
                                            disabled={isDisabled}
                                            value={item}
                                            checked={
                                                !hiddenColumns.includes(item.id)
                                            }
                                            onChange={(e) =>
                                                onHideColumn(e.value.id)
                                            }
                                        />
                                    </>
                                }
                            />
                        );
                    }}
                    onChange={(e) => onColumnsChange(e.value)}
                />
            </div>
        </>
    );
};

export default ColumnEditPanel;
