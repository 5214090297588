import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    getEntityFromParam,
    isWithinLast30Days,
    isWithinLast7Days,
    isWithinNext30Days,
    isWithinNext7Days,
    toISOStringWithTimezone,
} from '../../../util/helper';
import usePermission from '../../../hooks/usePermission';
import { ToastContext } from '../../../contexts/ToastContext';
import { Mixpanel } from '../../../util/mixpanel';

import {
    useGetEntityPlayersQuery,
    useGetPlayersQuery,
} from '../../../api/players';
import { useGetTeamQuery } from '../../../api/teams';
import {
    useCreateEventAttendeeMutation,
    useCreateEventInviteeMutation,
    useDeleteEventAttendeeMutation,
    useDeleteEventInviteMutation,
    useGetEventsQuery,
    useUpdateEventResponseMutation,
} from '../../../api/events';
import { useGetStaffDetailsQuery } from '../../../api/staff';

import TeamParticipationReportView from './TeamParticipationReportView';
import Icon from '../../../components/Icon';
import { DataTable } from 'primereact/datatable';
import { ToastMessage } from 'primereact/toast';

import { BaseEntityType } from '../../../types/common';
import {
    Attendee,
    Event,
    EventType,
    EventUserAttendance,
    EventUserResponse,
    InvitedUser,
} from '../../../types/event';
import { Permissions } from '../../../types/permissions';
import { Me, User } from '../../../types/user';

interface Props {
    user: Me;
}

type UserInput = {
    userID?: string | null;
    firstName?: string;
    lastName?: string;
    playerID?: string;
    userType?: string;
    picture?: string;
    image?: string;
    userDetails?: User;
};

export type UnifiedUser = {
    userID: string | null;
    firstName: string;
    lastName: string;
    playerID: string | null;
    userType: string;
    image: string | null;
    email: string | null;
};

const TeamParticipationReportContainer = ({ user }: Props) => {
    const params = useParams();
    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);
    const { teamID } = useParams();

    const { checkPermission } = usePermission(params.teamID);
    const toast = useContext(ToastContext);

    // State Hooks
    const [season, setSeason] = useState('');
    const [playersCursor] = useState<string>('');
    const [view, setView] = useState('memberView');
    const [filters, setFilters] = useState({
        userType: 'all',
        eventType: 'all',
        dateRange: 'all',
    });
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    const options = useMemo(() => {
        let options: any = {};

        options.eventTypes = [
            { label: 'All', value: 'all' }, // Add this option first
            ...Object.keys(EventType).map((type) => ({
                label: type,
                value: type,
            })),
        ];

        options.dateRanges = [
            { label: 'Entire Season', value: 'all' },
            { label: 'Past Events', value: 'Past' },
            { label: 'Past 30 Days', value: 'Past30' },
            { label: 'Past 7 Days', value: 'Past7' },
            { label: 'Future Events', value: 'Future' },
            { label: 'Future 30 Days', value: 'Future30' },
            { label: 'Future 7 Days', value: 'Future7' },
        ];

        options.userTypes = [
            { label: 'All', value: 'all' },
            { label: 'Players', value: 'entityPlayer' },
            { label: 'Staff', value: 'entityStaff' },
        ];

        options.views = [
            {
                label: <Icon name="bar_chart" />,
                value: 'eventView',
                icon: 'chart',
            },
            {
                label: <Icon name="table_rows" />,
                value: 'memberView',
            },
        ];

        return options;
    }, []);

    // API Hooks
    const { data: teamData } = useGetTeamQuery(
        { teamID: teamID || '', expand: 'defaultSeasonDetails' },
        {
            skip: !teamID,
        }
    );

    const [updateResponse] = useUpdateEventResponseMutation();
    const [createInvitee] = useCreateEventInviteeMutation();
    const [deleteInvitee] = useDeleteEventInviteMutation();
    const [createAttendee] = useCreateEventAttendeeMutation();
    const [deleteAttendee] = useDeleteEventAttendeeMutation();

    const eventData = useGetEventsQuery({
        entityType: BaseEntityType.teams,
        entityID: teamID || '',
        from: toISOStringWithTimezone(new Date('2000-01-01')),
        to: toISOStringWithTimezone(new Date('3000-01-01')),
        limit: '50',
        expand: 'eventInvitees|eventAttendees',
    });

    const entityPlayersData = useGetEntityPlayersQuery(
        {
            teamID: teamID ?? '',
            cursor: '',
        },
        {
            skip: activeEntity?.entityType !== BaseEntityType.teams,
        }
    );

    const placeHolderPlayerData = useGetPlayersQuery(
        {
            teamID: teamID ?? '',
            status: 'Active',
            cursor: playersCursor,
        },
        {
            skip: !teamID,
        }
    );

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    // Apply to userID to playerData where the placeHolder playerID matches that of the entityPlayer playerID
    const playerData = useMemo(() => {
        return (
            placeHolderPlayerData.data?.data?.map((player) => {
                const matchingEntityPlayer = entityPlayersData.data?.data?.find(
                    (entityPlayer) => entityPlayer.playerID === player.playerID
                );

                return {
                    ...player,
                    userID: matchingEntityPlayer?.userID || null,
                };
            }) ?? []
        );
    }, [placeHolderPlayerData.data?.data, entityPlayersData.data?.data]);

    const staffData = useGetStaffDetailsQuery(
        {
            entityType: activeEntity?.entityType as BaseEntityType,
            entityID: teamID ?? '',
        },
        {
            skip: !activeEntity,
        }
    );

    const unifiedUsers = useMemo(() => {
        const playerList = playerData;
        const staffList = staffData?.data?.data ?? [];

        const unifyUsers = (objects: UserInput[]): UnifiedUser[] => {
            return objects.map((obj) => ({
                playerID: obj.playerID ?? null, // Use null if undefined
                userID: obj.userID ?? null, // Use null if undefined
                userType:
                    obj.userType ?? (obj.playerID ? 'entityPlayer' : 'unknown'),
                firstName: obj.firstName || 'Unknown',
                lastName: obj.lastName || 'Unknown',
                image: obj.picture ?? obj.image ?? null,
                email: obj?.userDetails?.email || null,
            }));
        };

        return unifyUsers([...staffList, ...playerList]);
    }, [playerData, staffData]);

    // Filter staff or player members only if selected
    const userData = useMemo(() => {
        return unifiedUsers.filter(
            (user) =>
                filters.userType === 'all' || user.userType === filters.userType
        );
    }, [filters.userType, unifiedUsers]);

    // Handle changes to report filters
    const handleFilterChange = (key: string, value: string) => {
        setFilters((state) => ({
            ...state,
            [key]: value,
        }));
    };

    const exportCSV = (dataTable: DataTable<any>) => {
        dataTable && dataTable.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: `Participation Report`,
        });
    };

    const filteredEventData = useMemo(() => {
        if (!eventData?.data?.data) return; // Ensure eventData exists

        const allEvents = eventData.data.data;
        const { dateRange } = filters;

        // Extract userIDs from userData for quick lookup
        const validUserIDs = userData
            .filter((user) => user.userID)
            .map((user: UnifiedUser) => user.userID);

        // Filter by event type
        let filteredData =
            filters.eventType === 'all'
                ? allEvents // Show all events if 'all' is selected
                : allEvents.filter(
                      (event) => event.eventType === filters.eventType
                  );

        // Filter by the selected season and keep 'Other' events
        if (season) {
            filteredData = filteredData.filter(
                (event) =>
                    event.seasonID === season || event.eventType === 'Other'
            );
        }

        // Filter by the selected event period
        if (dateRange === 'Past') {
            filteredData = filteredData.filter(
                (event) => new Date(event.startDateTime) < new Date()
            );
        } else if (dateRange === 'Past7') {
            filteredData = filteredData.filter((event) =>
                isWithinLast7Days(new Date(event.startDateTime))
            );
        } else if (dateRange === 'Past30') {
            filteredData = filteredData.filter((event) =>
                isWithinLast30Days(new Date(event.startDateTime))
            );
        } else if (dateRange === 'Future') {
            filteredData = filteredData.filter(
                (event) => new Date(event.startDateTime) > new Date()
            );
        } else if (dateRange === 'Future7') {
            filteredData = filteredData.filter((event) =>
                isWithinNext7Days(new Date(event.startDateTime))
            );
        } else if (dateRange === 'Future30') {
            filteredData = filteredData.filter((event) =>
                isWithinNext30Days(new Date(event.startDateTime))
            );
        }

        // Filter events based on userIDs: only exclude if userID exists and is NOT in userData
        filteredData = filteredData.filter((event) => {
            const attendeeUserIDs =
                event.eventAttendees?.attendees?.map((att) => att.userID) || [];
            const inviteeUserIDs =
                event.eventInvitees?.invitees?.map((inv) => inv.userID) || [];

            return (
                attendeeUserIDs.some(
                    (userID) =>
                        userID === undefined ||
                        userID === null ||
                        validUserIDs.includes(userID)
                ) ||
                inviteeUserIDs.some(
                    (userID) =>
                        userID === undefined ||
                        userID === null ||
                        validUserIDs.includes(userID)
                )
            );
        });

        return filteredData;
    }, [filters, eventData, season, userData]);

    const handleBulkSubmitAttendee = (event: Event) => {
        if (!activeEntity || !event) {
            console.error('Missing teamID or eventID in generatePath');
            return;
        }

        event.eventInvitees?.invitees.forEach((invitee: InvitedUser) => {
            // Find the corresponding entity member from the userData
            const matchedInvitee = userData.find(
                (user) => user.userID === invitee.userID
            );

            if (
                matchedInvitee &&
                matchedInvitee.email &&
                (invitee.response === 'Yes' ||
                    invitee.response === 'NoReply' ||
                    invitee.response === undefined)
            ) {
                createAttendee({
                    entityType: activeEntity.entityType,
                    entityID: activeEntity.entityID,
                    eventID: event.eventID,
                    data: {
                        attendeeID: invitee.userID,
                        firstName: matchedInvitee.firstName, // Use the first name from the matched entity member
                        lastName: matchedInvitee.lastName,
                        email: matchedInvitee.email,
                        userID: invitee.userID,
                        checkInTime: '',
                        checkOutTime: '',
                        notes: [''],
                        eventRoles: ['eventsAttendee'],
                        gameRoles: ['gamesViewer'],
                        createdAt: new Date().toISOString(),
                        createdBy: user.userID,
                    },
                });
            } else if (matchedInvitee && invitee.response === 'No') {
                deleteAttendee({
                    entityType: BaseEntityType.teams,
                    entityID: activeEntity?.entityID,
                    eventID: event.eventID,
                    attendeeID: invitee.userID,
                });
            }
        });
    };

    const handleChangeResponse = async (
        user: UnifiedUser,
        event: Event,
        response: EventUserResponse
    ) => {
        if (!activeEntity || !user.userID) return;

        try {
            const result = await updateResponse({
                entityType: activeEntity?.entityType,
                entityID: activeEntity?.entityID,
                inviteeID: user.userID,
                eventID: event.eventID,
                response,
            });
            if ('error' in result) {
                let errorMessage = 'An unexpected error occurred.';

                if (
                    'data' in result.error &&
                    typeof result.error.data === 'object' &&
                    result.error.data !== null
                ) {
                    errorMessage =
                        (result.error.data as { details?: string }).details ||
                        errorMessage;
                } else if ('error' in result.error) {
                    errorMessage =
                        (result.error as { error: string }).error ||
                        errorMessage;
                }

                showToast({
                    severity: 'error',
                    summary: 'Error',
                    detail: errorMessage,
                });
            } else {
                showToast({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Successfully amended response.',
                });
            }
        } catch (error) {
            showToast({
                severity: 'error',
                summary: 'Error',
                detail: 'Could not complete action.',
            });
        }
    };

    const handleChangeAttendance = (
        user: UnifiedUser,
        event: Event,
        attendance: EventUserAttendance,
        attendee?: Attendee
    ) => {
        if (!activeEntity || !user.userID) return;

        if (attendance === EventUserAttendance.Attended) {
            createAttendee({
                entityType: activeEntity.entityType,
                entityID: activeEntity?.entityID,
                eventID: event.eventID,
                data: {
                    userID: user.userID,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    checkInTime: '',
                    checkOutTime: '',
                    notes: [''],
                    eventRoles: ['eventsAttendee'],
                    gameRoles: ['gamesViewer'],
                    createdAt: new Date().toISOString(),
                    createdBy: user.userID,
                },
            });
        }

        if (attendance === EventUserAttendance.Absent && attendee) {
            deleteAttendee({
                entityType: BaseEntityType.teams,
                entityID: activeEntity?.entityID,
                eventID: event.eventID,
                attendeeID: attendee.attendeeID,
            });
        }
    };

    const handleRemoveInvite = async (
        inviteeID: string,
        user: UnifiedUser,
        event: Event
    ) => {
        if (!activeEntity || !user.userID) return;
        try {
            const response = await deleteInvitee({
                entityType: activeEntity?.entityType,
                entityID: activeEntity?.entityID,
                inviteeID: inviteeID,
                eventID: event.eventID,
            });

            if ('error' in response) {
                let errorMessage = 'An unexpected error occurred.';

                // Type check: Ensure response.error is a FetchBaseQueryError before accessing 'data'
                if (
                    'data' in response.error &&
                    typeof response.error.data === 'object' &&
                    response.error.data !== null
                ) {
                    errorMessage =
                        (response.error.data as { details?: string }).details ||
                        errorMessage;
                } else if ('error' in response.error) {
                    errorMessage =
                        (response.error as { error: string }).error ||
                        errorMessage;
                }

                showToast({
                    severity: 'error',
                    summary: 'Error',
                    detail: errorMessage,
                });
            } else {
                showToast({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Successfully amended response.',
                });
            }
        } catch (error) {
            showToast({
                severity: 'error',
                summary: 'Error',
                detail: 'Could not complete action.',
            });
        }
    };

    const handleInviteUser = async (user: UnifiedUser, event: Event) => {
        if (!activeEntity || !user.userID) return;
        try {
            const response = await createInvitee({
                entityType: activeEntity?.entityType,
                entityID: activeEntity?.entityID,
                eventID: event.eventID,
                data: {
                    userID: user.userID,
                    gameRoles: ['gamesViewer'],
                    eventRoles: ['eventsAttendee'],
                },
            });

            if ('error' in response) {
                let errorMessage = 'An unexpected error occurred.';

                // Type check: Ensure response.error is a FetchBaseQueryError before accessing 'data'
                if (
                    'data' in response.error &&
                    typeof response.error.data === 'object' &&
                    response.error.data !== null
                ) {
                    errorMessage =
                        (response.error.data as { details?: string }).details ||
                        errorMessage;
                } else if ('error' in response.error) {
                    errorMessage =
                        (response.error as { error: string }).error ||
                        errorMessage;
                }

                showToast({
                    severity: 'error',
                    summary: 'Error',
                    detail: errorMessage,
                });
            } else {
                showToast({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Successfully sent invite.',
                });
            }
        } catch (error) {
            showToast({
                severity: 'error',
                summary: 'Error',
                detail: 'Could not complete action.',
            });
        }
    };

    const totalParticipation = useMemo(() => {
        if (!filteredEventData || !userData) return [];

        const memberUserIDs = userData.map((user) => user.userID);

        /*
        const responses = filteredEventData.reduce(
            (result, event) => {
                event.eventAttendees.attendees.forEach((attendee) => {
                    const user = userData.find(
                        (user) => user.userID === attendee.userID
                    );

                    if (user) {
                        result[EventUserAttendance.Attended].push(user);
                    }
                });

                event.eventInvitees.invitees.forEach((invitee) => {
                    const user = userData.find(
                        (user) => user.userID === invitee.userID
                    );

                    if (user) {
                        result[invitee.response].push(user);
                    }

                    if (
                        !result[EventUserAttendance.Attended].some(
                            (user: UnifiedUser) =>
                                user.userID === invitee.userID
                        )
                    ) {
                        result[EventUserAttendance.Absent].push(user);
                    }
                });

                return result;
            },
            {
                [EventUserAttendance.Attended]: [],
                [EventUserAttendance.Absent]: [],
                [EventUserResponse.Yes]: [],
                [EventUserResponse.No]: [],
                [EventUserResponse.NoReply]: [],
            } as any
        );
        */

        return filteredEventData.map((event) => {
            const yesAttendeeCount = event.eventAttendees.attendees.filter(
                (attendee) => memberUserIDs.includes(attendee.userID)
            ).length;

            const yesInviteCount = event.eventInvitees.invitees.filter(
                (invitee) =>
                    invitee.response === EventUserResponse.Yes &&
                    memberUserIDs.includes(invitee.userID)
            ).length;

            const noAttendeeCount = Math.max(
                event.eventInvitees.invitees.filter((invitee) =>
                    memberUserIDs.includes(invitee.userID)
                ).length - yesAttendeeCount,
                0
            );

            const noInviteCount = event.eventInvitees.invitees.filter(
                (invitee) =>
                    invitee.response === EventUserResponse.No &&
                    memberUserIDs.includes(invitee.userID)
            ).length;

            const noInviteReplyCount = event.eventInvitees.invitees.filter(
                (invitee) =>
                    invitee.response === EventUserResponse.NoReply &&
                    memberUserIDs.includes(invitee.userID)
            ).length;

            return `Yes: ${yesAttendeeCount} | ${yesInviteCount}<br />No: ${noAttendeeCount} | ${noInviteCount}<br />No Reply: ${noInviteReplyCount}`;
        });
    }, [filteredEventData, userData]);

    // Here is where we create the stacked bar chart using filteredData
    useEffect(() => {
        if (view === 'eventView' && filteredEventData?.length) {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue('--text-color');
            const textColorSecondary = documentStyle.getPropertyValue(
                '--text-color-secondary'
            );
            const surfaceBorder =
                documentStyle.getPropertyValue('--surface-border');

            // Extract labels (event names)
            const labels = filteredEventData.map((event) => event.eventName);

            // Count responses
            const yesCounts = filteredEventData.map(
                (event) =>
                    event.eventInvitees?.invitees?.filter(
                        (invitee) => invitee.response === 'Yes'
                    ).length || 0
            );
            const noReplyCounts = filteredEventData.map(
                (event) =>
                    event.eventInvitees?.invitees?.filter(
                        (invitee) => invitee.response === 'NoReply'
                    ).length || 0
            );
            const noCounts = filteredEventData.map(
                (event) =>
                    event.eventInvitees?.invitees?.filter(
                        (invitee) => invitee.response === 'No'
                    ).length || 0
            );

            const data = {
                labels,
                datasets: [
                    {
                        type: 'bar',
                        label: 'Yes',
                        backgroundColor:
                            documentStyle.getPropertyValue('--green-500'),
                        data: yesCounts,
                    },
                    {
                        type: 'bar',
                        label: 'No',
                        backgroundColor:
                            documentStyle.getPropertyValue('--red-500'),
                        data: noCounts,
                    },
                    {
                        type: 'bar',
                        label: 'No Reply',
                        backgroundColor:
                            documentStyle.getPropertyValue('--blue-500'),
                        data: noReplyCounts,
                    },
                ],
            };

            const options = {
                maintainAspectRatio: false,
                aspectRatio: 0.8,
                plugins: {
                    tooltip: {
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                            label: function (tooltipItem: any) {
                                const datasetIndex = tooltipItem.datasetIndex;
                                const dataIndex = tooltipItem.dataIndex;
                                const datasetLabel = tooltipItem.dataset.label;
                                const event = filteredEventData[dataIndex];

                                let userIDs: string[] = [];

                                // Get user IDs based on the response type
                                if (datasetIndex === 0) {
                                    // 'Yes' responses
                                    userIDs = event.eventInvitees.invitees
                                        .filter(
                                            (invitee: { response: string }) =>
                                                invitee.response === 'Yes'
                                        )
                                        .map(
                                            (invitee: { userID: string }) =>
                                                invitee.userID
                                        );
                                } else if (datasetIndex === 1) {
                                    // 'NoReply' responses
                                    userIDs = event.eventInvitees.invitees
                                        .filter(
                                            (invitee: { response: string }) =>
                                                invitee.response === 'No'
                                        )
                                        .map(
                                            (invitee: { userID: string }) =>
                                                invitee.userID
                                        );
                                } else if (datasetIndex === 2) {
                                    // 'No' responses
                                    userIDs = event.eventInvitees.invitees
                                        .filter(
                                            (invitee: { response: string }) =>
                                                invitee.response === 'NoReply'
                                        )
                                        .map(
                                            (invitee: { userID: string }) =>
                                                invitee.userID
                                        );
                                }

                                // If there are user IDs, get their names from userData
                                const userNames = userIDs.map((userID) => {
                                    // Find the matching user in userData
                                    const user = userData.find(
                                        (user) => user.userID === userID
                                    );

                                    // Check if the user is found and userID is not null
                                    if (user && user.userID !== null) {
                                        return `${user.firstName as string} ${
                                            user.lastName as string
                                        }`;
                                    }
                                    return 'Unknown User'; // Fallback for null userID
                                });

                                // If there are user names, include them in the tooltip
                                const userNamesText =
                                    userNames.length > 0
                                        ? ` (${userNames.join(', ')})`
                                        : '';

                                return `${datasetLabel}: ${tooltipItem.raw}${userNamesText}`;
                            },
                        },
                    },
                    legend: {
                        labels: { color: textColor },
                    },
                },
                scales: {
                    x: {
                        stacked: true,
                        title: {
                            display: true,
                            text: 'Selected Sessions',
                            color: textColor,
                            font: { size: 14, weight: 'bold' },
                        },
                        ticks: { color: textColorSecondary },
                        grid: { color: surfaceBorder, display: false },
                    },
                    y: {
                        stacked: true,
                        title: {
                            display: true,
                            text: 'Total Invitees',
                            color: textColor,
                            fonteventResponses: { size: 14, weight: 'bold' },
                        },
                        ticks: {
                            color: textColorSecondary,
                            stepSize: Math.ceil((1 - 0) / 10), // Adjust 10 based on desired interval count
                        },
                        grid: { color: surfaceBorder, display: false },
                    },
                },
            };

            setChartData(data);
            setChartOptions(options);
        }
    }, [view, filteredEventData, userData]);

    const permissions = {
        canCreate: checkPermission([
            Permissions.POST_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canDelete: checkPermission([
            Permissions.DELETE_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canEdit: checkPermission([
            Permissions.PUT_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canView: checkPermission([
            Permissions.GET_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
    };

    return (
        <TeamParticipationReportView
            onSeasonChange={setSeason}
            season={season}
            team={teamData?.data}
            options={options}
            filters={filters}
            onFilterChange={handleFilterChange}
            onViewChange={(view) => setView(view)}
            onExport={exportCSV}
            view={view}
            chartData={chartData}
            chartOptions={chartOptions}
            permissions={permissions}
            onInviteUser={handleInviteUser}
            onRemoveInvite={handleRemoveInvite}
            onChangeResponse={handleChangeResponse}
            onChangeAttendance={handleChangeAttendance}
            onBulkSubmitAttendee={handleBulkSubmitAttendee}
            filteredEventData={filteredEventData}
            userData={userData}
            totalParticipation={totalParticipation}
        />
    );
};

export default TeamParticipationReportContainer;
