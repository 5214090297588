import { useContext, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getEntityFromParam } from '../../util/helper';
import usePermission from '../../hooks/usePermission';
import { ToastContext } from '../../contexts/ToastContext';

import { ToastMessage } from 'primereact/toast';

import OrganisationLicencesView from './OrganisationLicencesView';

import {
    useAssignLicenceMutation,
    useGetLicenceGroupsQuery,
    useGetLicenceTypesQuery,
} from '../../api/licences';
import { useGetEntityQuery } from '../../api/core';

import { LicenceGroupInfo, LicenceType } from '../../types/licences';
import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { User } from '../../types/user';
import { Team } from '../../types/team';
import { Permissions } from '../../types/permissions';

interface Props {
    roles: Roles;
    route: Route;
    user: User;
}

const OrganisationLicencesContainer = (props: Props) => {
    const { user } = props;

    // Context hooks
    const toast = useContext(ToastContext);

    // Route Hooks
    const navigate = useNavigate();
    const params = useParams();
    const entity = getEntityFromParam(params);

    // State Hooks
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
    const [selectedLicenceGroup, setSelectedLicenceGroup] =
        useState<LicenceGroupInfo>();

    const { checkPermission } = usePermission(params.organisationID || '');

    // API Hooks
    const entityData = useGetEntityQuery(
        // @ts-expect-error
        { entityType: entity?.entityType, entityID: entity?.entityID || '' },
        { skip: !entity }
    );
    const { data: licenceGroupsRaw, isLoading: isLoadingGroups } =
        // @ts-ignore
        useGetLicenceGroupsQuery(entity, { skip: !entity });

    const { data: licenceTypes, isLoading: isLoadingTypes } =
        useGetLicenceTypesQuery(
            {
                sportID: entityData?.data?.data?.entitySportID ?? '',
                expand: 'productDetails',
            },
            { skip: !entityData?.data?.data }
        );

    const [assignLicence] = useAssignLicenceMutation();

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const handleShowEditModal = (licenceGroup: LicenceGroupInfo) => {
        setSelectedLicenceGroup(licenceGroup);
        setShowEditModal(true);
    };

    const handleShowAssignModal = (licenceGroup: LicenceGroupInfo) => {
        setSelectedLicenceGroup(licenceGroup);
        setShowAssignModal(true);
    };

    const handleAssignLicences = (licenceGroupID: string, teams: Team[]) => {
        if (teams.length > 0) {
            if (entity) {
                assignLicence({
                    ...entity,
                    licenceGroupID,
                    teamsIDList: teams.map((t) => t.teamID),
                })
                    .then((response) => {
                        if ('error' in response) {
                            showToast({
                                severity: 'error',
                                summary: 'Error',
                                // @ts-ignore
                                detail: response.error.data.error,
                            });
                        } else {
                            showToast({
                                severity: 'success',
                                summary: 'Success',
                                detail: 'Licences have successfully been assigned.',
                            });
                        }
                    })
                    .catch((err) => {
                        showToast({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'There was error assigning licences. Please try again later.',
                        });
                    })
                    .finally(() => {
                        setShowAssignModal(false);
                    });
            }
        }
    };

    const handleLicenceGroupClick = (licenceGroup: LicenceGroupInfo) => {
        navigate(licenceGroup.id);
    };

    const licenceGroups = useMemo<LicenceGroupInfo[]>(() => {
        let arr: LicenceGroupInfo[] = [];

        if (licenceGroupsRaw && licenceGroupsRaw?.data.length > 0) {
            licenceGroupsRaw.data.forEach((licenceGroup) => {
                const licenceType: LicenceType | undefined =
                    licenceTypes?.data.find(
                        (type) =>
                            licenceGroup.licenceTypeID === type.licenceTypeID
                    );

                const { subscription } = licenceGroup;

                const price = licenceType?.product?.prices.find(
                    (p) => p.priceID === licenceGroup.priceID
                );

                arr.push({
                    id: licenceGroup.licenceGroupID,
                    priceID: price?.priceID || '',
                    productTitle: licenceType?.product?.productName || '',
                    licenceTitle: licenceType?.licenceName || 'Legacy',
                    tier: price && price.tier,
                    price:
                        (price && price.unitAmount && price.unitAmount / 100) ||
                        0,
                    currency: 'AUD',
                    createdAt: licenceGroup.createdAt,
                    lastEdited: licenceGroup.lastEditedAt,
                    billingPeriod: price && price.recurring?.interval,
                    renewsAt: subscription
                        ? new Date(subscription.current_period_end * 1000)
                        : null,
                    trialActive:
                        subscription && subscription.trial_end
                            ? new Date().getTime() -
                                  subscription.trial_end * 1000 <
                              0
                            : false,
                    trialEndsAt:
                        subscription && subscription.trial_end
                            ? new Date(subscription.trial_end * 1000)
                            : null,
                    qtyAvailable:
                        licenceGroup.quantity - licenceGroup.totalAssigned,
                    qtyAssigned: licenceGroup.totalAssigned,
                    qtyTotal: licenceGroup.quantity,
                    status: licenceGroup.status,
                    groupID:
                        licenceGroup.subscriptionID ||
                        licenceGroup.invoiceID ||
                        'unknown',
                    description: licenceType?.product?.productDescription || '',
                    isOwner: licenceGroup.createdBy === user.userID || false, // hard-code this for now,
                    owner: licenceGroup.createdBy,
                    features:
                        licenceType?.product?.productFeatures.map(
                            (feat) => feat.name
                        ) || [],
                    entityID: licenceGroup.entityID,
                    entitySportID: licenceGroup.entitySportID,
                    entityType: licenceGroup.entityType,
                    subscriptionID: licenceGroup.subscriptionID,
                    transactionID: licenceGroup.transactionID,
                    rookieProduct: licenceGroup.rookieProduct,
                });
            });
        }

        // order by groupID (either subID or invoiceID) - more useful for ass and org
        arr.sort((a, b) =>
            a.groupID === b.groupID ? 0 : a.groupID < b.groupID ? -1 : 1
        );

        return arr;
    }, [licenceGroupsRaw, licenceTypes, user]);

    const permissions = {
        canCreate: checkPermission([
            Permissions.POST_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canDelete: checkPermission([
            Permissions.DELETE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canEdit: checkPermission([
            Permissions.POST_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canView: checkPermission([
            Permissions.GET_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
    };

    return (
        <OrganisationLicencesView
            isLoading={isLoadingGroups || isLoadingTypes}
            licenceGroups={licenceGroups}
            onAssignLicences={handleAssignLicences}
            showAddModal={showAddModal}
            showAssignModal={showAssignModal}
            showEditModal={showEditModal}
            selectedLicenceGroup={selectedLicenceGroup}
            onLicenceGroupClick={handleLicenceGroupClick}
            onShowAddModal={() => setShowAddModal(true)}
            onShowAssignModal={handleShowAssignModal}
            onShowEditModal={handleShowEditModal}
            onCloseAddModal={() => setShowAddModal(false)}
            onCloseAssignModal={() => setShowAssignModal(false)}
            onCloseEditModal={() => setShowEditModal(false)}
            setSelectedLicenceGroup={setSelectedLicenceGroup}
            permissions={permissions}
            {...props}
        />
    );
};

export default OrganisationLicencesContainer;
