import { useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    useDeleteCollectionMutation,
    useGetCollectionQuery,
    useGetCollectionTeamsQuery,
    useRemoveTeamFromCollectionMutation,
} from '../../api/collections';

import { ToastContext } from '../../contexts/ToastContext';
import usePermission from '../../hooks/usePermission';

import CollectionSingleView from './CollectionSingleView';

import { getEntityFromParam } from '../../util/helper';

import DocumentHead from '../../components/DocumentHead';
import Icon from '../../components/Icon';
import { ToastMessage } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';

import { Permissions } from '../../types/permissions';
import { Route } from '../../types/route';
import { Roles } from '../../types/roles';
import { OrganisationStatus } from '../../types/organisation';

interface Props {
    roles: Roles;
    route: Route;
}

const CollectionSingleContainer = (props: Props) => {
    const params = useParams();
    const { collectionID } = params;
    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);
    const [cursor, setCursor] = useState('');

    const [editing, setEditing] = useState<boolean>(false);
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [organisationFilter, setOrganisationFilter] =
        useState<OrganisationStatus>(OrganisationStatus.Active);

    const toast = useContext(ToastContext);

    const { data: collectionData } = useGetCollectionQuery(
        // @ts-expect-error
        { ...activeEntity, collectionID },
        {
            skip: !activeEntity,
        }
    );

    const { data, isLoading, isError, isFetching } = useGetCollectionTeamsQuery(
        // @ts-expect-error
        { ...activeEntity, collectionID, cursor },
        {
            skip: !activeEntity,
        }
    );

    const collectionTeams = useMemo(() => data?.data, [data]);

    const [deleteCollection] = useDeleteCollectionMutation();
    const [removeTeam] = useRemoveTeamFromCollectionMutation();

    // Permission Hook
    const { checkPermission } = usePermission(activeEntity?.entityID || '');

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const handleLoadMore = () => {
        if (data?.lastEvaluatedKey && data.lastEvaluatedKey.cursor) {
            setCursor(data.lastEvaluatedKey.cursor);
        }
    };

    const handleEditCollection = () => {
        setEditing(true);
    };

    const handleRemoveTeam = (teamID: string) => {
        if (activeEntity && collectionID && teamID) {
            confirmDialog({
                message: `Are you sure you want to remove this team from your collection?`,
                header: 'Remove Team',
                icon: <Icon name="dangerous" />,
                accept: () =>
                    removeTeam({
                        ...activeEntity,
                        collectionID,
                        teamID,
                    })
                        .then((response) => {
                            if ('error' in response) {
                                showToast({
                                    severity: 'error',
                                    detail: 'There was an error removing the team. Please try again.',
                                    summary: 'Error',
                                });
                            }
                        })
                        .catch(() => {
                            showToast({
                                severity: 'success',
                                detail: 'Successfully removed team.',
                            });
                        }),
            });
        }
    };

    const handleDeleteCollection = (collectionID: string) => {
        if (activeEntity) {
            confirmDialog({
                message: `Are you sure you want to delete this collection?`,
                header: 'Delete Collection',
                icon: <Icon name="dangerous" />,
                accept: () =>
                    deleteCollection({ ...activeEntity, collectionID })
                        .then((response) => {
                            if ('error' in response) {
                                showToast({
                                    severity: 'error',
                                    detail: 'There was an error deleting the Collection. Please try again.',
                                    summary: 'Error',
                                });
                            } else {
                                showToast({
                                    severity: 'success',
                                    detail: 'Successfully updated collection.',
                                });
                            }
                        })
                        .catch((err) => {
                            showToast({
                                severity: 'error',
                                detail: err.error,
                                summary: 'Error',
                            });
                        }),
            });
        }
    };

    const handleCloseModal = () => {
        setEditing(false);
    };

    const permissions = {
        canCreate: checkPermission([Permissions.MANAGE_TEAMS_ALL]),
        canDelete: checkPermission([Permissions.MANAGE_TEAMS_ALL]),
        canEdit: checkPermission([Permissions.MANAGE_TEAMS_ALL]),
        canView: checkPermission([
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_TEAMS_ALL,
        ]),
    };

    return (
        <>
            <DocumentHead
                title="Collections - Rookie Me Hub"
                description="Collections page"
            />
            <CollectionSingleView
                entityType={activeEntity?.entityType}
                entityID={activeEntity?.entityID}
                collectionID={collectionID}
                collection={collectionData?.data}
                collectionTeams={collectionTeams}
                editing={editing}
                isError={isError}
                isFetching={isFetching}
                isLoading={isLoading}
                organisationFilter={organisationFilter}
                onEdit={handleEditCollection}
                onDelete={handleDeleteCollection}
                onCloseEditModal={handleCloseModal}
                onLoadMore={handleLoadMore}
                onRemoveTeam={handleRemoveTeam}
                setOrganisationFilter={setOrganisationFilter}
                permissions={permissions}
                showPagination={!!data?.lastEvaluatedKey?.cursor}
                showAddModal={showAddModal}
                onToggleAddModal={() => setShowAddModal((prev) => !prev)}
                {...props}
            />
        </>
    );
};

export default CollectionSingleContainer;
