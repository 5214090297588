import { useEffect, useMemo, useState } from 'react';
import { isObject, sortBy } from 'lodash';

import { useAddTeamToCollectionMutation } from '../../api/collections';
import { useGetAssociationOrganisationsQuery } from '../../api/associations';
import { useLazyGetOrganisationTeamsQuery } from '../../api/organisations';

import {
    Tree,
    TreeCheckboxSelectionKeys,
    TreeCheckboxSelectionKeyType,
    TreeMultipleSelectionKeys,
    TreeSelectionEvent,
} from 'primereact/tree';
import { TreeNode } from 'primereact/treenode';
import Icon from '../../components/Icon';
import Loader from '../../components/Loader';
import RookieButton from '../../components/RookieButton';

import { BaseEntityType } from '../../types/common';
import { OrganisationStatus } from '../../types/organisation';
import { useLazyGetTeamParticipatingSeasonsQuery } from '../../api/seasons';
import { Dropdown } from 'primereact/dropdown';

interface Props {
    entityType: BaseEntityType;
    entityID: string;
    collectionID: string;
    disabledIDs?: string[];
    organisationFilter: OrganisationStatus;
    onError?: (error?: any) => void;
    onComplete?: (response: any) => void;
}

interface CustomTreeNode extends TreeNode {
    status?: string; // Add status property
}

const AddToCollectionForm = ({
    entityType,
    entityID,
    collectionID,
    disabledIDs,
    organisationFilter,
    onError,
    onComplete,
}: Props) => {
    const [loading, setLoading] = useState(false);

    const [selectedKeys, setSelectedKeys] = useState<
        string | TreeMultipleSelectionKeys | TreeCheckboxSelectionKeys | null
    >(null);

    const [nodes, setNodes] = useState<TreeNode[]>([]);
    const [selectedSeasons, setSelectedSeasons] = useState<{
        [teamID: string]: string;
    }>({});

    const { data: organisationsData, isLoading } =
        useGetAssociationOrganisationsQuery({
            associationID: entityID,
            limit: 50,
        });

    const [getTeams] = useLazyGetOrganisationTeamsQuery();
    const [fetchTeamParticipatingSeasons] =
        useLazyGetTeamParticipatingSeasonsQuery();
    const [addToCollection] = useAddTeamToCollectionMutation();

    useEffect(() => {
        if (organisationsData?.data && organisationsData?.data.length > 0) {
            const sortedOrgs = sortBy(
                organisationsData.data,
                'organisationName'
            );

            setNodes(
                sortedOrgs.map((org, index) => ({
                    key: index.toString(),
                    label: org.organisationName,
                    leaf: false,
                    id: org.organisationID,
                    data: {
                        entityType: BaseEntityType.organisations,
                    },
                }))
            );
        }
    }, [organisationsData]);

    // Get selected nodes based on keys
    const getSelectedNodes = (
        nodes: TreeNode[],
        selectedKeys: (string | number | undefined)[]
    ): TreeNode[] => {
        const selectedNodes: TreeNode[] = [];

        const findSelectedNodes = (nodeList: TreeNode[]) => {
            for (const node of nodeList) {
                // Check if the current node is selected
                if (selectedKeys.includes(node.key)) {
                    selectedNodes.push(node);
                }

                // If the node has children, recursively check them
                if (node.children && node.children.length > 0) {
                    findSelectedNodes(node.children);
                }
            }
        };

        // Start recursive traversal from the top level nodes
        findSelectedNodes(nodes);

        return selectedNodes;
    };

    const handleSelectionChange = ({ value }: TreeSelectionEvent) => {
        if (isObject(value)) {
            // get a list of selected nodes
            const selectedNodes = getSelectedNodes(nodes, Object.keys(value));

            // get a list of selectable node keys
            const selectableKeys = selectedNodes
                .filter((node) => node.id && !disabledIDs?.includes(node.id))
                .map((node) => node.key);

            const selection = Object.keys(value).reduce((result, key) => {
                if (selectableKeys.includes(key)) {
                    result[key] = value[key] as TreeCheckboxSelectionKeyType;
                }
                return result;
            }, {} as TreeCheckboxSelectionKeys);

            setSelectedKeys(selection);
        }
    };

    const handleSubmit = () => {
        setLoading(true);

        if (selectedKeys && entityType && entityID) {
            const checked = Object.keys(selectedKeys).filter((key) => {
                const value =
                    isObject(selectedKeys) &&
                    (selectedKeys[key] as TreeCheckboxSelectionKeyType);

                return value && value.checked;
            });

            const selected = getSelectedNodes(nodes, checked)
                .filter((node) => node.data.entityType === BaseEntityType.teams)
                .map((node) => ({
                    teamID: node.id || '',
                    seasonID: node.data?.seasonIDs?.[0]?.seasonID || 'default',
                }));

            addToCollection({
                entityType,
                entityID,
                collectionID,
                teams: selected,
            })
                .then((response) => {
                    if ('error' in response) {
                        if (onError) {
                            onError(response.error);
                        }
                    } else {
                        if (onComplete) {
                            onComplete(response.data.data);
                        }
                    }
                })
                .catch((err) => {
                    if (onError) {
                        onError(err);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);

            if (onError) {
                onError('No entityType or entityID provided');
            }
        }
    };

    const loadTeams = async (node: TreeNode, selectAll = false) => {
        if (node.leaf === false) {
            if (!node.children) {
                node.children = [];

                if (node.id && node.key) {
                    const keyString = node.key.toString();

                    try {
                        // Fetch active and archived teams
                        const [activeTeams, archivedTeams] = await Promise.all([
                            getTeams({
                                organisationID: node.id,
                                entityStatus: organisationFilter,
                                limit: 50,
                                cursor: '',
                            }).unwrap(),
                            getTeams({
                                organisationID: node.id,
                                entityStatus: OrganisationStatus.Archived,
                                limit: 50,
                                cursor: '',
                            }).unwrap(),
                        ]);

                        // Merge and sort teams
                        const teams = [
                            ...activeTeams.data,
                            ...archivedTeams.data,
                        ];
                        const sortedTeams = sortBy(teams, [
                            'teamStatus',
                            'teamName',
                        ]);

                        // Fetch all participating seasons concurrently
                        const teamSeasons: any = await Promise.all(
                            sortedTeams.map(async (team) => {
                                try {
                                    const seasonsResult =
                                        await fetchTeamParticipatingSeasons({
                                            teamID: team.teamID,
                                            cursor: '',
                                        });

                                    return {
                                        teamID: team.teamID,
                                        seasons: seasonsResult.data?.data || [],
                                    };
                                } catch (error) {
                                    console.error(
                                        `Error fetching seasons for team ${team.teamID}:`,
                                        error
                                    );
                                    return { teamID: team.teamID, seasons: [] }; // Return empty array on failure
                                }
                            })
                        );

                        // Assign fetched seasonIDs to node.children
                        node.children = sortedTeams.map((team, index) => {
                            const teamSeasonData =
                                teamSeasons.find(
                                    (t: any) => t.teamID === team.teamID
                                )?.seasons || [];

                            return {
                                key: `${keyString}-${index}`,
                                label: `${team.teamName} Status: ${team.teamStatus}`,
                                id: team.teamID,
                                status: team.teamStatus,
                                selectable: !disabledIDs?.includes(team.teamID),
                                className: disabledIDs?.includes(team.teamID)
                                    ? 'p-disabled'
                                    : '',
                                data: {
                                    entityType: BaseEntityType.teams,
                                    seasonIDs: teamSeasonData,
                                    seasonNames: teamSeasonData.map(
                                        (s: any) => ({
                                            label: s.seasonName,
                                            value: s.seasonID,
                                        })
                                    ),
                                },
                            };
                        });

                        // Update nodes state
                        setNodes((prevNodes) =>
                            prevNodes.map((n) =>
                                n.key === node.key ? node : n
                            )
                        );

                        // Select all logic
                        if (selectAll) {
                            const selectedChildren = node.children.reduce(
                                (result, item) => {
                                    if (
                                        item.key &&
                                        item.id &&
                                        !disabledIDs?.includes(item.id)
                                    ) {
                                        result[item.key] = {
                                            checked: true,
                                            partialChecked: false,
                                        };
                                    }
                                    return result;
                                },
                                {} as TreeCheckboxSelectionKeys
                            );

                            setSelectedKeys(
                                (prev) =>
                                    ({
                                        ...(isObject(prev) ? prev : {}),
                                        ...selectedChildren,
                                    } as TreeCheckboxSelectionKeys)
                            );
                        }
                    } catch (error) {
                        console.error(
                            `Error fetching teams for ${node.label}:`,
                            error
                        );
                    }
                }
            }
        }
    };

    const filteredNodes = useMemo(() => {
        return nodes.map((node) => {
            if (node.children) {
                return {
                    ...node,
                    children: (node.children as CustomTreeNode[]).filter(
                        (child) => child.status === organisationFilter
                    ),
                };
            }
            return node;
        });
    }, [nodes, organisationFilter]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div className="addto-collections-form">
            <Tree
                className="p-tree-borderless"
                value={filteredNodes}
                onExpand={(e) => loadTeams(e.node)}
                loading={loading}
                selectionMode="checkbox"
                selectionKeys={selectedKeys}
                expandIcon={() => <Icon name="arrow_right" />}
                collapseIcon={() => <Icon name="arrow_drop_down" />}
                onSelectionChange={handleSelectionChange}
                nodeTemplate={(node) => (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                        }}
                    >
                        <span>{node.label}</span>
                        {node.data?.entityType === 'teams' &&
                            node.data?.seasonNames?.length > 0 &&
                            node.id && (
                                <Dropdown
                                    value={selectedSeasons[node.id] || null}
                                    options={node.data.seasonNames}
                                    onChange={(e) =>
                                        setSelectedSeasons((prev) => ({
                                            ...prev,
                                            [node.id as string]: e.value,
                                        }))
                                    }
                                    placeholder="Select Season"
                                    style={{ width: '150px' }}
                                    required
                                />
                            )}
                    </div>
                )}
            />

            <RookieButton label="Add To Collection" onClick={handleSubmit} />
        </div>
    );
};

export default AddToCollectionForm;
