import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
    uniq,
    union,
    uniqBy,
    intersectionWith,
    sortBy,
    map,
    reduce,
    isObject,
    isNumber,
    indexOf,
} from 'lodash';

import { useLazyGetTeamSeasonStatsReportQuery } from '../../../api/reports';
import { useGetStatViewsQuery, useGetStatsQuery } from '../../../api/stats';
import { useGetTeamQuery } from '../../../api/teams';

import TeamStatsReportView from './TeamStatsReportView';

import { DataTable } from 'primereact/datatable';
import { SelectItem } from 'primereact/selectitem';

import usePrivilege from '../../../hooks/usePrivilege';
import { periodSuffix } from '../../../util/helper';
import { calculateTotals, roundToDecimalPlaces } from '../helpers';
import { Mixpanel } from '../../../util/mixpanel';
import { defaultReportState } from '../constants';

import { BaseEntityType } from '../../../types/common';
import {
    ReportDataTypes,
    ReportState,
    ReportType,
    StatTypes,
    ReportTimeDisplays,
    TimeStat,
    ReportDataFilters,
    ReportDataViews,
    ReportColumn,
} from '../../../types/reports';

const dataTypeOptions = [
    {
        label: ReportDataTypes.Total,
        value: ReportDataTypes.Total,
    },
    {
        label: ReportDataTypes.Average,
        value: ReportDataTypes.Average,
    },
];

const timeDisplayOptions = [
    {
        label: '% Per',
        value: ReportTimeDisplays.Percentage,
    },
    {
        label: 'Time',
        value: ReportTimeDisplays.Time,
    },
];

const TeamStatsReportContainer = () => {
    // Route Params
    const { teamID } = useParams();

    // Search Params
    const [searchParams] = useSearchParams();
    const eventParam = searchParams.get('event');
    const seasonParam = searchParams.get('season');
    const categoryParam = searchParams.get('category');

    // State Hooks
    const [hideEmpty, setHideEmpty] = useState<boolean>(true);
    const [emptyStats, setEmptyStats] = useState<string[]>([]);
    const [hiddenRows, setHiddenRows] = useState<string[]>([]);
    const [rowOrder, setRowsOrder] = useState<string[]>([]);

    const [season, setSeason] = useState(seasonParam || '');
    const [category, setCategory] = useState<string>(categoryParam || '');
    const [filters, setFilters] = useState<ReportDataFilters>({
        event: eventParam ? [eventParam] : [],
        period: 0,
    });
    const [views, setViews] = useState<ReportDataViews>({
        dataType: ReportDataTypes.Total,
        timeDisplay: ReportTimeDisplays.Percentage,
    });
    const [reportData, setReportData] = useState<
        Record<string, ReportState<any>>
    >({});

    // Cache busting ref
    const timestampRef = useRef(Date.now()).current;

    // API
    const [requestReport] = useLazyGetTeamSeasonStatsReportQuery();

    const teamData = useGetTeamQuery({
        teamID: teamID || '',
        expand: 'defaultSeasonDetails',
    });

    const team = useMemo(() => teamData.data?.data, [teamData]);
    const sportID = team?.entitySportID;

    // Fetch Stat Config
    const statConfigData = useGetStatsQuery(
        { sportID: sportID || '' },
        { skip: !sportID }
    );
    const statViewsData = useGetStatViewsQuery(
        { sportID: sportID || '' },
        { skip: !sportID }
    );

    const { checkRestrictions } = usePrivilege(teamID, BaseEntityType.teams);

    // Helpers

    // Local Storage helpers
    const storageKey = useMemo(() => `Team-Stats-${teamID}`, [teamID]);

    // Save report settings to local storage
    const saveValue = (key: string, value: any) => {
        const ls = localStorage.getItem(storageKey);
        const parsed = ls ? JSON.parse(ls) : {};

        parsed[key] = value;

        localStorage.setItem(storageKey, JSON.stringify(parsed));
    };

    // Load report settings from local storage
    const loadValue = (key: string) => {
        const ls = localStorage.getItem(storageKey);
        const parsed = ls && JSON.parse(ls);

        return parsed && parsed[key];
    };

    const statConfig = useMemo(
        () => statConfigData?.data?.data,
        [statConfigData]
    );

    const eventsCount = useMemo(() => {
        return filters.event && filters.event.length > 0
            ? filters.event.length
            : uniq(reportData[season]?.data?.map((o: any) => o.eventID)).length;
    }, [reportData, season, filters.event]);

    const stats: ReportColumn[] = useMemo(() => {
        if (statViewsData.data) {
            const allowedStatViews = statViewsData.data?.data.filter(
                (statView) => {
                    return checkRestrictions({
                        PLAY_STAT_VIEWS: [statView.statViewID],
                    });
                }
            );

            if (allowedStatViews && allowedStatViews.length > 0) {
                return allowedStatViews[0].statViewStats.map((stat) => ({
                    id: stat.statID,
                    field: stat.statID,
                    name: stat.name,
                    shortName: stat.shortName,
                    category: stat.categoryName,
                    type: StatTypes.Count,
                    defaultSort: stat.statSortOrder,
                }));
            }
        }
        return [];
    }, [checkRestrictions, statViewsData]);

    useEffect(() => {
        if (reportData[season] && reportData[season].data) {
            setEmptyStats(
                stats
                    .filter((o) => {
                        return (
                            reportData[season].data.filter((item: any) => {
                                return item[o.id] && item[o.id] > 0;
                            }).length <= 0
                        );
                    })
                    .map((col) => col.id)
            );
        }
    }, [stats, reportData, season]);

    useEffect(() => {
        if (teamID && season && !reportData[season]) {
            const fetchReport = async () => {
                try {
                    // Set report loading in state
                    setReportData((prev) => ({
                        ...prev,
                        [season]: {
                            ...defaultReportState,
                            isLoading: true,
                            isUninitialized: false,
                        },
                    }));

                    //fetch report url
                    const requestReportData = await requestReport({
                        seasonID: season,
                        teamID,
                        sessionID: timestampRef,
                        reportType: ReportType.gameStatsAdvanced,
                    }).unwrap();

                    const reportUrl = requestReportData?.data?.objectURL;

                    if (reportUrl) {
                        // Fetch report data
                        const response = await fetch(reportUrl);

                        if (!response.ok) {
                            throw new Error('Failed to load report');
                        }

                        const report = await response.json();

                        // Set report in state
                        setReportData((prev) => ({
                            ...prev,
                            [season]: {
                                ...prev[season],
                                isLoading: false,
                                data: report,
                            },
                        }));
                    } else {
                        throw new Error('Invalid report URL or request failed');
                    }
                } catch (error) {
                    // Set report error in state
                    setReportData((prev) => ({
                        ...prev,
                        [season]: {
                            ...prev[season],
                            isLoading: false,
                            isError: true,
                            error:
                                isObject(error) && 'message' in error
                                    ? error.message
                                    : error,
                        },
                    }));
                }
            };

            fetchReport();
        }
    }, [teamID, season, reportData, requestReport, timestampRef]);

    // Set season to default team season
    useEffect(() => {
        if (!season && team) {
            setSeason(team.defaultSeasonID);
        }
    }, [team, season]);

    // Set data type to totals when filtering by a single event
    useEffect(() => {
        if (
            filters.event?.length === 1 &&
            views.dataType === ReportDataTypes.Average
        ) {
            handleViewChange('dataType', ReportDataTypes.Total);
        }
    }, [filters.event, views.dataType]);

    useEffect(() => {
        const lsHiddenRows = loadValue('hiddenRows');
        const lsRowOrder = loadValue('rowOrder');

        setHiddenRows(() => {
            if (category === '') {
                return lsHiddenRows ? lsHiddenRows : [];
            }

            return stats
                .filter((col) => {
                    const inCategory = col.category === category;

                    return (
                        !col.persist &&
                        (hideEmpty
                            ? emptyStats.includes(col.id) || !inCategory
                            : !inCategory)
                    );
                })
                .map((col) => col.id);
        });

        setRowsOrder(
            category === '' && lsRowOrder
                ? lsRowOrder
                : sortBy(stats, (stat) => stat.defaultSort).map(
                      (stat) => stat.id
                  )
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category, stats, emptyStats, hideEmpty]);

    const exportCSV = (dataTable: DataTable<any>) => {
        dataTable && dataTable.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: `Team Stat Report`,
        });
    };

    const resetFilters = () => {
        setFilters({
            event: [],
            period: 0,
        });
    };

    const handleSeasonChange = (seasonID: string) => {
        setSeason(seasonID);
        resetFilters();
    };

    const handleFilterChange = (key: string, value: string) => {
        setFilters((state) => ({
            ...state,
            [key]: value,
        }));
    };

    const handleViewChange = (key: string, value: any) => {
        setViews((state) => ({
            ...state,
            [key]: value,
        }));
    };

    // Helper for filtering
    const filterData = (data: any, filters: ReportDataFilters) => {
        return data.filter((item: TimeStat) => {
            if (
                item.period === 'total' ||
                (item.playerID !== 'opposition' && item.playerID !== 'team')
            )
                return false;

            const hasEventFilter = filters.event && filters.event.length > 0;
            const hasPeriodFilter = filters.period && filters.period > 0;

            if (
                hasEventFilter &&
                !filters.event?.includes(item.gameID) &&
                !filters.event?.includes(item.eventID)
            ) {
                return false;
            }
            if (hasPeriodFilter && filters.period !== Number(item.period)) {
                return false;
            }

            return true;
        });
    };

    const tableData = useMemo(() => {
        let newData = reportData[season]?.data;

        if (!newData) return [];

        // Filter data
        newData = filterData(newData, filters);

        // Calculate totals
        newData = calculateTotals(
            newData,
            (o: any) => o.playerID === 'opposition'
        );

        // Format data
        newData = map(
            reduce(
                newData,
                (result, curr) => {
                    stats.forEach((col) => {
                        const { id } = col;

                        const value =
                            views.dataType === ReportDataTypes.Average &&
                            curr[id]
                                ? curr[id] / eventsCount
                                : curr[id];

                        result[id] = result[id] || {
                            stat: col,
                            team: 0,
                            opposition: 0,
                        };

                        result[id][curr.playerID] = isNumber(value)
                            ? roundToDecimalPlaces(value, 1)
                            : value;
                    });

                    return result;
                },
                {} as { [key: string]: any }
            )
        );

        // Filter to show relevant rows
        newData = newData.filter((row: any) => {
            const isHidden = hiddenRows.includes(row.stat.id);
            const isEmpty = hideEmpty && emptyStats.includes(row.stat.id);
            const isCategory =
                category === '' ||
                (category !== '' && category === row.stat.category);

            return isCategory && !isHidden && !isEmpty;
        });

        return sortBy(newData, (o) => indexOf(rowOrder, o.stat.id));
    }, [
        category,
        emptyStats,
        eventsCount,
        filters,
        hideEmpty,
        reportData,
        stats,
        season,
        views.dataType,
        rowOrder,
        hiddenRows,
    ]);

    // Filter Options
    const categoryOptions = useMemo(() => {
        let options: SelectItem[] = [
            {
                label: 'Custom',
                value: '',
            },
        ];

        const uniqStats =
            reportData[season] &&
            reportData[season].data &&
            union(
                ...reportData[season].data.map((d: TimeStat) => Object.keys(d))
            );

        const uniqCategories = uniqBy(
            intersectionWith(statConfig, uniqStats, (config, statID) => {
                return config.statID === statID;
            }),
            'statCategory.name'
        );

        const sortedCategories = sortBy(
            uniqCategories,
            'statCategory.sortOrder'
        );

        sortedCategories.forEach((config) => {
            options.push({
                label: config.statCategory.name,
                value: config.statCategory.name,
            });
        });

        return options;
    }, [statConfig, reportData, season]);

    const periodOptions = useMemo(() => {
        if (
            !reportData[season] ||
            (reportData[season] && !reportData[season].data)
        ) {
            return [];
        }

        // Get total periods for the individual game
        const totalPeriods = (
            (reportData[season].data || []) as TimeStat[]
        ).reduce((maxPeriod, item: TimeStat) => {
            return item.period > maxPeriod ? item.period : maxPeriod;
        }, 0);

        return Array.from({ length: totalPeriods }).map((u, i) => {
            const period = i + 1;
            const label = `${period}${periodSuffix(Number(period))}`;
            return {
                label: label,
                value: period,
            };
        });
    }, [reportData, season]);

    const handleHiddenRowChange = (value: string) => {
        setHiddenRows((prevState) => {
            const newState = prevState.includes(value)
                ? prevState.filter((col) => col !== value)
                : [...prevState, value];

            saveValue('hiddenRows', newState);

            return newState;
        });
    };

    const handleRowsOrderChange = (col: ReportColumn[]) => {
        const newState = col.map((c) => c.id);
        setRowsOrder(newState);
        saveValue('rowOrder', newState);
    };

    return (
        <TeamStatsReportView
            category={category}
            data={tableData}
            emtpyRows={emptyStats}
            filters={filters}
            hiddenRows={hiddenRows}
            hideEmptyRows={hideEmpty}
            options={{
                timeDisplay: timeDisplayOptions,
                dataType: dataTypeOptions,
                categories: categoryOptions,
                periods: periodOptions,
            }}
            reportData={reportData[season]}
            rows={sortBy(stats, (o) => indexOf(rowOrder, o.id))}
            season={season}
            team={team}
            teamData={teamData}
            views={views}
            onCategoryChange={setCategory}
            onExport={exportCSV}
            onFilterChange={handleFilterChange}
            onHiddenRowChange={handleHiddenRowChange}
            onHideEmptyRows={setHideEmpty}
            onSeasonChange={handleSeasonChange}
            onRowsChange={handleRowsOrderChange}
            onViewsChange={handleViewChange}
        />
    );
};

export default TeamStatsReportContainer;
