import React from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
    size?: 'full' | 'fluid' | 'large' | 'narrow';
}

const PageContainer = ({ size, children, ...otherProps }: Props) => {
    return (
        <div
            className={`page-container ${size ? 'page-container-' + size : ''}`}
            {...otherProps}
        >
            {children}
        </div>
    );
};

PageContainer.defaultProps = {
    size: 'fluid',
};

export default PageContainer;
