import { useParams } from 'react-router-dom';
import { useGetTeamQuery } from '../../api/teams';
import { TeamJoinMode } from '../../types/team';
import { Badge } from 'primereact/badge';
import { Tooltip } from 'primereact/tooltip';

const TeamSettingsBadge = () => {
    const { teamID } = useParams();

    const { data: teamData } = useGetTeamQuery(
        { teamID: teamID || '' },
        {
            skip: !teamID,
        }
    );

    if (
        teamData?.data?.teamJoinMode === TeamJoinMode.Enabled &&
        teamData?.data?.teamJoinRole === 'teamsAdmin'
    ) {
        return (
            <>
                <Badge
                    value="!"
                    severity="danger"
                    className="team-settings-menu-badge"
                />
                <Tooltip
                    target=".team-settings-menu-badge"
                    content="Anyone with the join code can become an admin without approval. Consider changing this to improve security."
                />
            </>
        );
    }
    return null;
};

export default TeamSettingsBadge;
