import { useContext, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Formik, FormikErrors, FormikValues } from 'formik';
import { isEqual, pick } from 'lodash';

import {
    useArchiveTeamMutation,
    useDeleteTeamMutation,
    useGetTeamQuery,
    useUpdateTeamMutation,
} from '../../api/teams';
import { coreApi } from '../../api/core';
import { useGetEntityCategoryQuery } from '../../api/sports';

import usePermission from '../../hooks/usePermission';
import { getParentEntityFromParam } from '../../util/helper';
import { ToastContext } from '../../contexts/ToastContext';
import {
    teamAgeOptions,
    teamGenderOptions,
    teamGradeOptions,
} from '../../util/constants';

import TransferEntityForm from '../users/TransferEntityForm';
import PageContainer from '../../layout/PageContainer';
import PageHeader from '../../layout/PageHeader';
import PageSection, { Annotation } from '../../layout/PageSection';
import DocumentHead from '../../components/DocumentHead';
import EntityDesigner from '../../components/EntityDesigner';
import EntityPreview from '../../components/EntityPreview';
import ErrorDisplay from '../../components/ErrorDisplay';
import FormActions from '../../components/FormActions';
import FormFields from '../../components/FormFields';
import FormGroup from '../../components/FormGroup';
import Icon from '../../components/Icon';
import Loader from '../../components/Loader';
import RookieButton from '../../components/RookieButton';
import { Dialog } from 'primereact/dialog';
import { confirmDialog } from 'primereact/confirmdialog';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { SelectButton } from 'primereact/selectbutton';
import { Tooltip } from 'primereact/tooltip';
import { Sidebar } from 'primereact/sidebar';

import { User } from '../../types/user';
import { Permissions } from '../../types/permissions';
import { TeamForm, TeamJoinMode } from '../../types/team';
import { BaseEntityType, ERROR_TYPES, INPUT_TYPES } from '../../types/common';

interface Props {
    user?: User;
}

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const requiredFields: Array<keyof TeamForm> = [
    'teamName',
    'shortName',
    'age',
    'gender',
];

const TeamSettings = (props: Props) => {
    const dispatch = useDispatch();
    const params = useParams();

    const { user } = props;
    const { teamID } = params;

    // Context Hooks
    const toast = useContext(ToastContext);

    // Route Hooks
    const navigate = useNavigate();

    const parentEntity = getParentEntityFromParam(params);
    const { checkPermission, role } = usePermission(
        parentEntity?.entityID || ''
    );

    // State Hooks
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);

    // API Hooks
    const [updateTeam] = useUpdateTeamMutation();
    const [deleteTeamMutation] = useDeleteTeamMutation();
    const [archiveTeamMutation] = useArchiveTeamMutation();
    const { data, isLoading, isError } = useGetTeamQuery(
        { teamID: teamID || '' },
        {
            skip: !teamID,
        }
    );

    const team = useMemo(() => data?.data, [data]);

    const { data: entityData } = useGetEntityCategoryQuery(
        {
            sportID: 'aus_football_au',
            // @ts-ignore
            entityCategoryID: team?.entityCategoryID,
        },
        {
            skip: !team?.entityCategoryID,
        }
    );

    const options = useMemo(() => {
        let entityOptions = {
            age: teamAgeOptions,
            gender: teamGenderOptions,
            grade: teamGradeOptions,
        } as {
            [key: string]: Array<{ label: string; value: string }>;
        };

        if (entityData?.data?.validProperties) {
            entityData.data.validProperties.forEach((prop) => {
                entityOptions[prop.propertyKey] = prop.propertyValues.map(
                    (val) => ({
                        label: val,
                        value: val,
                    })
                );
            });
        }

        return entityOptions;
    }, [entityData]);

    const deleteTeam = async () => {
        if (!teamID) return;

        try {
            setIsSubmitting(true);

            await deleteTeamMutation({ teamID });

            // Wait for 4 seconds to allow server to respond
            await delay(4000);

            // Refresh roles
            dispatch(coreApi.util.invalidateTags(['Role']));

            setIsSubmitting(false);

            // Show success toast
            toast?.current?.show({
                severity: 'success',
                summary: 'Success',
                detail: `${team?.teamName} has successfully been deleted.`,
            });

            // Show success toast
            navigate(`/u/${user?.userID}/entities`, {
                replace: false,
            });
        } catch (err) {
            toast?.current?.show({
                severity: 'warn',
                summary: 'Error',
                detail: `Sorry, there was an error. Please try again later.`,
            });
        }
    };

    const archiveTeam = async () => {
        if (!teamID) return;

        try {
            setIsSubmitting(true);

            await archiveTeamMutation({ teamID });

            // Wait for 4 seconds to allow server to respond
            await delay(4000);

            setIsSubmitting(false);

            toast?.current?.show({
                severity: 'success',
                summary: 'Success',
                detail: `${team?.teamName} has successfully been archived.`,
            });

            // Refresh roles
            dispatch(coreApi.util.invalidateTags(['Role']));

            navigate(`/u/${user?.userID}/entities`, {
                replace: false,
            });
        } catch (err) {
            toast?.current?.show({
                severity: 'warn',
                summary: 'Error',
                detail: `Sorry, there was an error. Please try again later.`,
            });
        }
    };

    const handleDelete = () => {
        confirmDialog({
            message: `This is permanent and cannot be undone. Are you sure you want to delete ${team?.teamName}?`,
            header: 'Delete Team',
            accept: () => deleteTeam(),
        });
    };

    const handleArchive = () => {
        confirmDialog({
            message: `Are you sure you want to archive ${team?.teamName}?`,
            header: 'Archive Team',
            accept: () => archiveTeam(),
        });
    };

    const handleSubmit = async (data: Partial<TeamForm>) => {
        try {
            setIsSubmitting(true);

            const response = await updateTeam({
                teamID,
                ...data,
            });

            if ('error' in response) {
                throw new Error(
                    'Sorry, there was an error updating your team. Please try again later.'
                );
            }

            toast?.current?.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Your team details have been updated.',
            });

            setIsSubmitting(false);
            setShowEditor(false);

            // Wait for 4 seconds to allow server to respond
            await delay(4000);

            // Refresh roles
            dispatch(coreApi.util.invalidateTags(['Role']));
        } catch (error) {
            toast?.current?.show({
                severity: 'warn',
                summary: 'Error',
                detail:
                    error instanceof Error
                        ? error.message
                        : 'An unknown error occurred.',
            });

            setIsSubmitting(false);
        }
    };

    const handleRefreshCode = () => {
        updateTeam({
            teamID,
            refreshTeamJoinCode: true,
        })
            .then((response) => {
                if ('error' in response) {
                    toast?.current?.show({
                        severity: 'warn',
                        summary: 'Error',
                        // @ts-ignore
                        detail: response.error.data.error,
                    });
                } else {
                    toast?.current?.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: 'Team Join Code has been refreshed',
                    });
                }
            })
            .catch((err) => {
                toast?.current?.show({
                    severity: 'warn',
                    summary: 'Error',
                    detail: `Sorry, there was an error. Please try again later.`,
                });
            });
    };

    const handleCodeClick = () => {
        const mode = team?.teamJoinMode;
        const code = team?.teamJoinCode;

        if (mode !== 'Disabled' && code) {
            // Copy the code
            navigator.clipboard.writeText(code);

            // Show toast
            toast?.current?.show({
                summary: 'Team code copied',
                detail: code,
            });
        }
    };

    const validate = (values: FormikValues) => {
        const errors: FormikErrors<TeamForm> = {};

        requiredFields.forEach((field) => {
            if (!values[field]) {
                errors[field] = 'Field cannot be blank';
            }
        });

        return errors;
    };

    if (isLoading) {
        return <Loader size="fullscreen" />;
    }

    if (isError || !team) {
        return (
            <ErrorDisplay
                desc="We were unable to load your Team Settings, try again or contact Team Owner."
                errorType={ERROR_TYPES.notFound}
                hasReturn
                proportion="fullscreen"
                title="No Roles Returned"
            />
        );
    }

    const permissions = {
        canCreate: checkPermission([
            Permissions.POST_TEAMS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canDelete: checkPermission([
            Permissions.DELETE_TEAMS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canEdit: checkPermission([
            Permissions.PUT_TEAMS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canView: checkPermission([
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
    };

    const isOwner = role.data?.roles.some((role) =>
        role.roleID.includes('Owner')
    );

    return (
        <>
            <DocumentHead
                title="Team Settings - Rookie Me Hub"
                description="Team settings page"
            />
            <PageContainer>
                {team.teamJoinMode === TeamJoinMode.Enabled &&
                    team.teamJoinRole === 'teamsAdmin' && (
                        <Message
                            style={{ marginBottom: '1rem', width: '100%' }}
                            severity="warn"
                            content={
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '1rem',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Icon name="warning" />
                                    <div>
                                        Your current settings allow anyone who
                                        knows or guesses your team join code to
                                        join as an admin without approval. We
                                        strongly recommend updating your
                                        settings to prevent unauthorised access.
                                    </div>
                                    <RookieButton
                                        onClick={() => {
                                            const e =
                                                document.getElementById(
                                                    'tjc-form'
                                                );
                                            e?.scrollIntoView({
                                                behavior: 'auto',
                                                block: 'center',
                                                inline: 'center',
                                            });
                                        }}
                                        style={{ flex: 'none' }}
                                        label="Edit"
                                    />
                                </div>
                            }
                        />
                    )}

                <PageHeader title="Settings" />

                <PageSection>
                    <Annotation
                        title="Kit"
                        description="Update the design of your kit"
                    >
                        <div
                            className="kit-edit"
                            onClick={() =>
                                permissions.canEdit && setShowEditor(true)
                            }
                        >
                            <EntityPreview design={team.design} />
                            {permissions.canEdit && (
                                <div className="edit-badge">
                                    <Icon name="edit" size="small" />
                                </div>
                            )}
                        </div>
                        <Dialog
                            header="Kit Designer"
                            onHide={() => setShowEditor(false)}
                            visible={showEditor}
                        >
                            <EntityDesigner
                                initialValues={team.design}
                                onCancel={() => setShowEditor(false)}
                                onSubmit={(design) => {
                                    handleSubmit({ design });
                                }}
                                submitLabel="Save"
                            />
                        </Dialog>
                    </Annotation>
                    <Annotation title="Team Information">
                        <Formik
                            initialValues={pick(team as TeamForm, [
                                'teamName',
                                'shortName',
                                'age',
                                'gender',
                                'grade',
                            ])}
                            onSubmit={handleSubmit}
                            validate={validate}
                            enableReinitialize
                            validateOnBlur={false}
                            validateOnChange={false}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                                setFieldTouched,
                                isSubmitting,
                                initialValues,
                            }) => {
                                const isDisabled =
                                    isSubmitting ||
                                    isEqual(values, initialValues);

                                const isReadOnly = !permissions.canEdit;

                                return (
                                    <form onSubmit={handleSubmit}>
                                        <FormFields>
                                            <FormGroup
                                                label="Team Name"
                                                htmlFor="teamName"
                                                error={errors.teamName}
                                                showError={
                                                    !!errors.teamName &&
                                                    touched.teamName
                                                }
                                            >
                                                <InputText
                                                    id="teamName"
                                                    name="teamName"
                                                    type={INPUT_TYPES.text}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder="Enter Team Name"
                                                    value={values.teamName}
                                                    required
                                                    disabled={isReadOnly}
                                                />
                                            </FormGroup>
                                            <FormGroup
                                                label={
                                                    <>
                                                        Short Name
                                                        <Tooltip target=".shortName-help" />
                                                        <span
                                                            className="help-tip shortName-help"
                                                            data-pr-tooltip="Short Name can contain only alphabetical characters."
                                                            data-pr-position="top"
                                                        >
                                                            <Icon
                                                                name="info"
                                                                size="small"
                                                            />
                                                        </span>
                                                        <small className="form-label--right">
                                                            {
                                                                values.shortName
                                                                    .length
                                                            }
                                                            /4
                                                        </small>
                                                    </>
                                                }
                                                htmlFor="shortName"
                                                error={errors.shortName}
                                                showError={
                                                    !!errors.shortName &&
                                                    touched.shortName
                                                }
                                            >
                                                <InputText
                                                    id="shortName"
                                                    maxLength={4}
                                                    name="shortName"
                                                    onBlur={handleBlur}
                                                    onChange={(event) => {
                                                        let shortNameVal =
                                                            event.target.value;
                                                        shortNameVal =
                                                            shortNameVal.replace(
                                                                /[^a-zA-Z]/g,
                                                                ''
                                                            );

                                                        shortNameVal =
                                                            shortNameVal.toUpperCase();

                                                        setFieldValue(
                                                            'shortName',
                                                            shortNameVal
                                                        );

                                                        setFieldTouched(
                                                            'shortName',
                                                            true
                                                        );
                                                    }}
                                                    placeholder="Short Name"
                                                    required
                                                    type={INPUT_TYPES.text}
                                                    value={values.shortName}
                                                    disabled={isReadOnly}
                                                />
                                            </FormGroup>
                                            <FormGroup
                                                label="Team Age"
                                                htmlFor="age"
                                                error={errors.age}
                                                showError={
                                                    !!errors.age && touched.age
                                                }
                                            >
                                                <Dropdown
                                                    inputId="age"
                                                    name="age"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder="Team Age"
                                                    value={values.age}
                                                    options={options.age}
                                                    disabled={isReadOnly}
                                                />
                                            </FormGroup>
                                            <FormGroup
                                                label="Team Gender"
                                                htmlFor="gender"
                                                error={errors.gender}
                                                showError={
                                                    !!errors.gender &&
                                                    touched.gender
                                                }
                                            >
                                                <SelectButton
                                                    id="gender"
                                                    value={values.gender}
                                                    options={options.gender}
                                                    onChange={(e) => {
                                                        setFieldValue(
                                                            'gender',
                                                            e.value
                                                        );
                                                        setFieldTouched(
                                                            'gender',
                                                            true
                                                        );
                                                    }}
                                                    disabled={isReadOnly}
                                                />
                                            </FormGroup>
                                            <FormGroup
                                                label="Team Grade"
                                                htmlFor="grade"
                                            >
                                                <Dropdown
                                                    inputId="grade"
                                                    name="grade"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    placeholder="Team Grade"
                                                    value={values.grade}
                                                    options={options.grade}
                                                    editable
                                                    disabled={isReadOnly}
                                                />
                                            </FormGroup>
                                        </FormFields>
                                        {!isReadOnly && (
                                            <FormActions
                                                start={
                                                    <RookieButton
                                                        type="submit"
                                                        label="Save"
                                                        loading={isSubmitting}
                                                        disabled={isDisabled}
                                                    />
                                                }
                                            />
                                        )}
                                    </form>
                                );
                            }}
                        </Formik>
                    </Annotation>
                    <Annotation
                        title="Team Join Code"
                        description="Configue how members can join your team."
                    >
                        <Formik
                            initialValues={pick(team as TeamForm, [
                                'teamJoinMode',
                                'teamJoinRole',
                            ])}
                            onSubmit={handleSubmit}
                            enableReinitialize
                        >
                            {({
                                values,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                                setFieldTouched,
                                isSubmitting,
                                initialValues,
                            }) => {
                                const isDisabled =
                                    isSubmitting ||
                                    isEqual(values, initialValues);

                                const isReadOnly = !permissions.canEdit;
                                const joinDisabled =
                                    team?.teamJoinMode === 'Disabled';

                                return (
                                    <form id="tjc-form" onSubmit={handleSubmit}>
                                        <FormFields>
                                            {!joinDisabled && (
                                                <FormGroup>
                                                    <div className="p-button-group">
                                                        <RookieButton
                                                            type="button"
                                                            tooltip="Copy code to clipboard"
                                                            tooltipOptions={{
                                                                showDelay: 500,
                                                            }}
                                                            outlined
                                                            plain
                                                            onClick={
                                                                handleCodeClick
                                                            }
                                                            label={
                                                                team?.teamJoinCode
                                                            }
                                                            icon="content_copy"
                                                            iconPos="right"
                                                        />
                                                        <RookieButton
                                                            type="button"
                                                            severity="secondary"
                                                            label="Refresh"
                                                            icon="refresh"
                                                            onClick={
                                                                handleRefreshCode
                                                            }
                                                        />
                                                    </div>
                                                </FormGroup>
                                            )}
                                            <FormGroup
                                                label="Enable Team Join Code"
                                                htmlFor="teamJoinMode"
                                            >
                                                <InputSwitch
                                                    inputId="teamJoinMode"
                                                    name="teamJoinMode"
                                                    checked={
                                                        values.teamJoinMode ===
                                                        'Enabled'
                                                    }
                                                    onChange={(e) => {
                                                        setFieldValue(
                                                            'teamJoinMode',
                                                            e.value
                                                                ? 'Enabled'
                                                                : 'Disabled'
                                                        );
                                                        setFieldTouched(
                                                            'teamJoinMode',
                                                            true
                                                        );
                                                    }}
                                                    disabled={isReadOnly}
                                                />
                                            </FormGroup>
                                            {values?.teamJoinMode ===
                                                'Enabled' && (
                                                <FormGroup
                                                    label="Default Join Role"
                                                    htmlFor="teamJoinRole"
                                                >
                                                    <Dropdown
                                                        inputId="teamJoinRole"
                                                        name="teamJoinRole"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        placeholder="Select role"
                                                        value={
                                                            values.teamJoinRole
                                                        }
                                                        options={[
                                                            {
                                                                label: 'Require approval to join',
                                                                value: 'teamsPending',
                                                            },
                                                            {
                                                                label: 'Admin',
                                                                value: 'teamsAdmin',
                                                            },
                                                            {
                                                                label: 'Manager',
                                                                value: 'teamsManager',
                                                            },
                                                            {
                                                                label: 'Viewer',
                                                                value: 'teamsViewer',
                                                            },
                                                        ]}
                                                        showClear={true}
                                                        disabled={isReadOnly}
                                                    />
                                                </FormGroup>
                                            )}
                                        </FormFields>

                                        {!isEqual(values, initialValues) && (
                                            <Message
                                                className="form-message message message-floated"
                                                severity="warn"
                                                text={
                                                    values.teamJoinMode !==
                                                    'Disabled'
                                                        ? 'Please note changing these settings will disable any existing join code, and generate a new one.'
                                                        : 'Please note changing these settings will disable any existing join code.'
                                                }
                                                icon={
                                                    <Icon
                                                        name="warning"
                                                        size="small"
                                                    />
                                                }
                                            />
                                        )}
                                        {!isReadOnly && (
                                            <FormActions
                                                start={
                                                    <RookieButton
                                                        type="submit"
                                                        label="Save"
                                                        loading={isSubmitting}
                                                        disabled={isDisabled}
                                                    />
                                                }
                                            />
                                        )}
                                    </form>
                                );
                            }}
                        </Formik>
                    </Annotation>

                    <Annotation
                        title="Danger Zone"
                        description="These actions are permanent and you will not be able to undo."
                    >
                        <div className="p-button-group">
                            <RookieButton
                                onClick={() => setShowTransferModal(true)}
                                label="Transfer Ownership"
                                severity="danger"
                                disabled={!isOwner}
                                tooltipOptions={{ showOnDisabled: true }}
                                tooltip={
                                    !isOwner
                                        ? 'You must be an Team Owner to complete these actions.'
                                        : undefined
                                }
                            />

                            <RookieButton
                                onClick={() => handleDelete()}
                                label="Delete Team"
                                severity="danger"
                                disabled={!isOwner}
                                tooltipOptions={{ showOnDisabled: true }}
                                tooltip={
                                    !isOwner
                                        ? 'You must be an Team Owner to complete these actions.'
                                        : undefined
                                }
                            />

                            <RookieButton
                                onClick={() => handleArchive()}
                                label="Archive Team"
                                severity="danger"
                                disabled={!isOwner}
                                tooltipOptions={{ showOnDisabled: true }}
                                tooltip={
                                    !isOwner
                                        ? 'You must be an Team Owner to complete these actions.'
                                        : undefined
                                }
                            />
                        </div>
                    </Annotation>
                </PageSection>
            </PageContainer>
            <Sidebar
                header={
                    <div>
                        Transfer Ownership <br />
                        <strong>*WARNING*:</strong> Irreversible unless
                        retransferred.
                    </div>
                }
                onHide={() => setShowTransferModal(false)}
                visible={showTransferModal}
                position="right"
            >
                {teamID ? (
                    <TransferEntityForm
                        entityType={BaseEntityType.teams}
                        entityID={teamID}
                        onSuccess={() => setShowTransferModal(false)}
                    />
                ) : (
                    <div>Loading...</div>
                )}
            </Sidebar>

            {isSubmitting && <Loader size="fullscreen" overlay />}
        </>
    );
};

export default TeamSettings;
