import { useMemo, useRef } from 'react';

import RookieButton from '../../../components/RookieButton';
import Icon from '../../../components/Icon';
import { Menu } from 'primereact/menu';

import { Attendee, EventUserAttendance } from '../../../types/event';

interface Props {
    attendee?: Attendee;
    isInvited?: boolean;
    isPastEvent?: boolean;
    onChangeAttendance: (
        attendance: EventUserAttendance,
        attendee?: Attendee
    ) => void;
}

const statusConfig = {
    [EventUserAttendance.Attended]: {
        color: '#0072D1',
        icon: 'check_circle',
        label: 'Attended',
    },
    [EventUserAttendance.Absent]: {
        color: '#EF4444',
        icon: 'cancel',
        label: 'Absent',
    },
    [EventUserAttendance.Pending]: {
        color: '#656567',
        icon: 'pending',
        label: 'Pending',
    },
};

const UserAttendanceCell = ({
    attendee,
    isInvited,
    isPastEvent,
    onChangeAttendance,
}: Props) => {
    const menu = useRef<Menu>(null);

    const menuItems = [
        {
            label: 'Change Attendance',
            items: [
                {
                    label: statusConfig[EventUserAttendance.Attended].label,
                    icon: (
                        <Icon
                            fill
                            name={
                                statusConfig[EventUserAttendance.Attended].icon
                            }
                            style={{
                                color: statusConfig[
                                    EventUserAttendance.Attended
                                ].color,
                            }}
                        />
                    ),
                    command: () =>
                        onChangeAttendance(
                            EventUserAttendance.Attended,
                            attendee
                        ),
                    disabled: !!attendee,
                },
                {
                    label: statusConfig[EventUserAttendance.Absent].label,
                    icon: (
                        <Icon
                            fill
                            name={statusConfig[EventUserAttendance.Absent].icon}
                            style={{
                                color: statusConfig[EventUserAttendance.Absent]
                                    .color,
                            }}
                        />
                    ),
                    command: () =>
                        onChangeAttendance(
                            EventUserAttendance.Absent,
                            attendee
                        ),
                    disabled: !!!attendee,
                },
            ],
        },
    ];

    const status = useMemo(() => {
        if (isInvited) {
            if (isPastEvent) {
                return attendee
                    ? EventUserAttendance.Attended
                    : EventUserAttendance.Absent;
            } else {
                return EventUserAttendance.Pending;
            }
        }
    }, [attendee, isInvited, isPastEvent]);

    if (!isInvited || !status) {
        return '-';
    }

    const config = statusConfig[status];

    return (
        <>
            <RookieButton
                onClick={(event) => menu?.current?.toggle(event)}
                text
                severity="secondary"
                icon={
                    <Icon
                        style={{ color: config.color }}
                        name={config.icon}
                        fill
                    />
                }
                aria-haspopup
                disabled={!isPastEvent}
                tooltip={config.label}
                tooltipOptions={{
                    showOnDisabled: true,
                    position: 'top',
                }}
            />
            <Menu model={menuItems} popup ref={menu} />
        </>
    );
};

export default UserAttendanceCell;
