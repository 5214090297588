import PageContainer from '../../layout/PageContainer';
import PageHeader from '../../layout/PageHeader';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Sidebar } from 'primereact/sidebar';
import Loader from '../../components/Loader';
import RookieButton from '../../components/RookieButton';

import GroupsForm from './GroupsForm';

import { BaseEntityType, UserType } from '../../types/common';
import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { Group, GroupUser } from '../../types/groups';
import AddToGroupForm from './AddToGroupForm';

interface Props {
    entityType?: BaseEntityType;
    entityID?: string;
    groupID: string | undefined;
    group: Group | undefined;
    groupUsers: GroupUser[] | undefined;
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
    showPagination: boolean;
    onLoadMore?: () => void;
    onDelete?: (groupID: string) => void;
    onEdit?: (group: Group) => void;
    roles: Roles;
    onCloseEditModal: () => void;
    editing: boolean;
    showAddModal: boolean;
    onToggleAddModal: () => void;
    onRemoveUser: (userID: string) => void;
    route: Route;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const GroupSingleView = (props: Props) => {
    return (
        <PageContainer>
            <PageHeader
                title={props.group?.groupName || 'Group'}
                actions={
                    props.permissions.canCreate
                        ? [
                              {
                                  label: 'Edit',
                                  onClick: () =>
                                      props.onEdit &&
                                      props.group &&
                                      props.onEdit(props.group),
                                  severity: 'secondary',
                              },
                              {
                                  label: 'Add Users',
                                  onClick: props.onToggleAddModal,
                              },
                          ]
                        : []
                }
            />

            {props.isLoading ? (
                <Loader />
            ) : (
                <DataTable
                    value={props.groupUsers}
                    footer={
                        props.showPagination &&
                        props.onLoadMore && (
                            <RookieButton
                                label="Load More"
                                onClick={props.onLoadMore}
                                size="large"
                                className="load-more"
                                severity="secondary"
                                loading={props.isFetching}
                            />
                        )
                    }
                >
                    <Column header="First Name" field="given_name" />
                    <Column header="Last Name" field="family_name" />
                    <Column
                        header="Type"
                        field="userType"
                        body={(row) =>
                            row.userType === UserType.entityPlayer
                                ? 'Player'
                                : row.userType === UserType.entityStaff
                                ? 'Staff'
                                : row.userType === UserType.entityGuardian
                                ? 'Parent/Guardian'
                                : null
                        }
                    />
                    <Column
                        body={(row) => (
                            <RookieButton
                                onClick={() => props.onRemoveUser(row.userID)}
                                icon="remove"
                                severity="secondary"
                            />
                        )}
                        align="right"
                    />
                </DataTable>
            )}
            {props.entityType && props.entityID && (
                <Dialog
                    onHide={props.onCloseEditModal}
                    visible={props.editing}
                    header="Edit Group"
                    resizable={false}
                    draggable={false}
                >
                    <GroupsForm
                        group={props.group}
                        entityType={props.entityType}
                        entityID={props.entityID}
                        onUpdate={() => props.onCloseEditModal()}
                        onCreate={() => props.onCloseEditModal()}
                        onError={() => props.onCloseEditModal()}
                    />
                </Dialog>
            )}
            {props.entityType && props.entityID && props.groupID && (
                <Sidebar
                    onHide={props.onToggleAddModal}
                    visible={props.showAddModal}
                    position="right"
                    header="Select User"
                >
                    <AddToGroupForm
                        entityID={props.entityID}
                        entityType={props.entityType}
                        groupID={props.groupID}
                        hiddenIDs={props.groupUsers}
                        onComplete={() => props.onToggleAddModal()}
                    />
                </Sidebar>
            )}
        </PageContainer>
    );
};

export default GroupSingleView;
