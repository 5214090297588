import { startCase } from 'lodash';
import { format } from 'date-fns';

import { isInAppSubscription } from '../../util/helper';
import { productDetails } from '../../util/constants';

import RookieButton from '../../components/RookieButton';
import ProductTag from '../../components/ProductTag';
import Icon from '../../components/Icon';

import { Column } from 'primereact/column';
import { Image } from 'primereact/image';
import { DataTable } from 'primereact/datatable';

import { LicenceGroup, LicenceGroupInfo } from '../../types/licences';

interface Props {
    getStaffName: (userID: string) => string | undefined;
    onAssignLicence: (licence: string) => void;
    onManageBilling: (subscriptionID: string, inAppPurchase: boolean) => void;
    licenceGroups?: LicenceGroupInfo[];
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
    usedLicences: string[];
}

const LicenceGroups = (props: Props) => {
    const {
        getStaffName,
        licenceGroups,
        onAssignLicence,
        onManageBilling,
        permissions,
        usedLicences,
    } = props;
    return (
        <div>
            <h2>Available Licences</h2>
            <DataTable
                value={licenceGroups}
                className="p-card"
                style={{ margin: '20px 0 40px' }}
            >
                <Column
                    header="Product"
                    field="rookieProduct"
                    body={(data: LicenceGroup) => {
                        const product = productDetails[data.rookieProduct];

                        return (
                            <div className="product-cell">
                                <Image
                                    className="product-cell-logo"
                                    src={product.logomark}
                                    width="40"
                                    height="40"
                                />
                                <span className="product-cell-title">
                                    {product.title}
                                </span>
                            </div>
                        );
                    }}
                />
                <Column header="Plan" field="licenceTitle" />

                <Column
                    header="Billing"
                    field="billingPeriod"
                    body={(data: LicenceGroupInfo) => {
                        return data.billingPeriod
                            ? `${startCase(data.billingPeriod)}ly`
                            : 'N/A';
                    }}
                />

                <Column
                    header="Created"
                    body={(data) => {
                        const staffName = getStaffName(data.owner);

                        return `${format(
                            new Date(data.createdAt),
                            'MMM d, yyyy'
                        )} by ${staffName}`;
                    }}
                />

                <Column
                    header="Managed"
                    body={(data) => {
                        const inAppPurchase = isInAppSubscription(data);

                        return inAppPurchase ? (
                            <ProductTag
                                title="In-App"
                                icon={<Icon name="smartphone" size="small" />}
                            />
                        ) : (
                            <ProductTag product="hub" />
                        );
                    }}
                />

                <Column
                    header="In Use"
                    body={(data) => {
                        return usedLicences.includes(data.id) ? 'Active' : '-';
                    }}
                />

                <Column
                    body={(data) => {
                        const inAppPurchase = isInAppSubscription(data);

                        return (
                            <div
                                className="p-button-group"
                                style={{
                                    justifyContent: 'flex-end',
                                }}
                            >
                                {['Active', 'Invalid'].includes(data.status) &&
                                    data.subscriptionID &&
                                    data.subscriptionID !== 'NA' &&
                                    data.isOwner &&
                                    permissions.canEdit && (
                                        <RookieButton
                                            label="Manage"
                                            severity="secondary"
                                            onClick={() =>
                                                onManageBilling(
                                                    data.subscriptionID,
                                                    inAppPurchase
                                                )
                                            }
                                        />
                                    )}

                                <RookieButton
                                    label="Use"
                                    outlined
                                    disabled={data.qtyAvailable === 0}
                                    onClick={() => onAssignLicence(data.id)}
                                />
                            </div>
                        );
                    }}
                />
            </DataTable>
        </div>
    );
};

export default LicenceGroups;
