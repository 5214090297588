import { useRef } from 'react';

import RookieButton from '../../../components/RookieButton';

import { Menu } from 'primereact/menu';
import { EventUserResponse, InvitedUser } from '../../../types/event';
import Icon from '../../../components/Icon';

interface Props {
    invitee: InvitedUser;
    isPastEvent?: boolean;
    isEventOwner?: boolean;
    onRemoveInvite?: (invitee: InvitedUser) => void;
    onChangeResponse?: (response: EventUserResponse) => void;
}

const statusConfig = {
    [EventUserResponse.Yes]: {
        color: '#0072D1',
        icon: 'check_circle',
        label: 'Going',
    },
    [EventUserResponse.No]: {
        color: '#EF4444',
        icon: 'cancel',
        label: 'Not Going',
    },
    [EventUserResponse.Maybe]: {
        color: '#F2A200',
        icon: 'do_not_disturb_on',
        label: 'Interested',
    },
    [EventUserResponse.NoReply]: {
        color: '#656567',
        icon: 'help',
        label: 'No Response',
    },
};

const UserResponseCell = ({
    invitee,
    isPastEvent,
    isEventOwner,
    onRemoveInvite,
    onChangeResponse,
}: Props) => {
    const menu = useRef<Menu>(null);

    const reponseItems = [
        {
            label: 'Change Response',
            items: [
                {
                    label: statusConfig[EventUserResponse.Yes].label,
                    icon: (
                        <Icon
                            fill
                            name={statusConfig[EventUserResponse.Yes].icon}
                            style={{
                                color: statusConfig[EventUserResponse.Yes]
                                    .color,
                            }}
                        />
                    ),
                    command: () => {
                        onChangeResponse &&
                            onChangeResponse(EventUserResponse.Yes);
                    },
                    disabled: invitee.response === EventUserResponse.Yes,
                },
                {
                    label: statusConfig[EventUserResponse.No].label,
                    icon: (
                        <Icon
                            fill
                            name={statusConfig[EventUserResponse.No].icon}
                            style={{
                                color: statusConfig[EventUserResponse.No].color,
                            }}
                        />
                    ),
                    command: () => {
                        onChangeResponse &&
                            onChangeResponse(EventUserResponse.No);
                    },
                    disabled: invitee.response === EventUserResponse.No,
                },
                {
                    label: 'Remove Invite',
                    icon: <Icon name="delete" />,
                    command: () => {
                        onRemoveInvite && onRemoveInvite(invitee);
                    },
                },
            ],
        },
    ];

    const config = statusConfig[invitee.response as keyof typeof statusConfig];

    return (
        <>
            <RookieButton
                onClick={(event) => menu?.current?.toggle(event)}
                text
                icon={
                    <Icon
                        style={{ color: config.color }}
                        name={config.icon}
                        fill
                    />
                }
                aria-haspopup
                disabled={isEventOwner || isPastEvent}
                tooltip={
                    isEventOwner && !isPastEvent
                        ? 'Event owner must respond with Yes.'
                        : config.label
                }
                tooltipOptions={{
                    showOnDisabled: true,
                    position: 'top',
                }}
            />
            <Menu model={reponseItems} popup ref={menu} />
        </>
    );
};

export default UserResponseCell;
