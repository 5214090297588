import { forwardRef, memo } from 'react';

import ReportHeaderCell from './ReportHeaderCell';
import ErrorDisplay from '../../../components/ErrorDisplay';
import { Column } from 'primereact/column';
import { DataTable, DataTableProps } from 'primereact/datatable';

import { ERROR_TYPES } from '../../../types/common';
import { ReportColumn } from '../../../types/reports';
import { RookieProduct } from '../../../types/licences';
import { productDetails } from '../../../util/constants';
import { Image } from 'primereact/image';
import { Skeleton } from 'primereact/skeleton';

interface Props {
    data: any[];
    dataTableProps?: DataTableProps<any>;
    columns: ReportColumn[];
    isLoading?: boolean;
    isFetching?: boolean;
    pinnedColumns?: string[];
    product?: RookieProduct;
}

type Ref = DataTable<any>;

const ReportTable = forwardRef<Ref, Props>((props, ref) => {
    const {
        columns,
        data,
        dataTableProps,
        isFetching,
        isLoading,
        pinnedColumns,
        product,
    } = props;

    const emptyMessage = (
        <ErrorDisplay
            alignment="middle"
            title="No Report Found"
            desc="Can't load report. The data may be corrupt. Please refresh and if the issue persists, please contact us if you would like further information."
            errorType={ERROR_TYPES.somethingsWrong}
            hasReturn={false}
            proportion="compact"
        />
    );

    const tableFooter = () => {
        if (!product) return null;

        const prodDeats = productDetails[product];

        return (
            <div className="report-disclaimer">
                <span>Report generated by</span>{' '}
                <Image
                    height="24px"
                    src={prodDeats.logo}
                    alt={prodDeats.title}
                />
            </div>
        );
    };

    const renderLoadingColumns = () => {
        return (columns && columns.length > 0 ? columns : Array(5).fill(0)).map(
            () => <Column body={<Skeleton width="40px" />} />
        );
    };

    const renderColumns = () => {
        return columns.map((column) => [
            <Column
                align={column.align || 'center'}
                alignHeader={column.alignHeader || 'center'}
                columnKey={column.id}
                key={column.id}
                sortable={column.sortable !== false}
                header={<ReportHeaderCell column={column} />}
                headerClassName={`cell-${column.id}`}
                frozen={pinnedColumns?.includes(column.id)}
                {...column}
                exportable={false}
            />,
            ...(column.exportable !== false
                ? [
                      <Column
                          key={`${column.id}-hidden`}
                          columnKey={`${column.id}-hidden`}
                          header={column.name}
                          hidden={true}
                          exportable={true}
                          {...column}
                      />,
                  ]
                : []),
        ]);
    };

    return (
        <DataTable
            ref={ref}
            emptyMessage={
                isLoading && !isFetching ? <div>Loading...</div> : emptyMessage
            }
            loading={isFetching && !isLoading}
            removableSort
            scrollable
            scrollHeight="100vh"
            showGridlines
            stripedRows={true}
            value={isLoading ? Array(5).fill(0) : data}
            footer={() => tableFooter()}
            {...dataTableProps}
        >
            {isLoading ? renderLoadingColumns() : renderColumns()}
        </DataTable>
    );
});

export default memo(ReportTable);
