import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { isObject, sortBy, uniqBy } from 'lodash';

import { useLazyGetTeamSeasonInterchangeStatTimeSeriesQuery } from '../../../api/reports';
import { useGetStatsQuery } from '../../../api/stats';
import { useGetTeamQuery } from '../../../api/teams';

import { Mixpanel } from '../../../util/mixpanel';
import { periodSuffix } from '../../../util/helper';
import { defaultReportState } from '../constants';

import TeamStatDensityReportView from './TeamStatDensityReportView';
import { Chart } from 'primereact/chart';

import {
    ReportDataFilters,
    ReportState,
    TeamStat,
} from '../../../types/reports';

const TOTAL_BINS = 20; // set number of bins, 20 = going up in 5% incremenets 10 = 10% increments

const TeamStatDensityReportContainer = () => {
    // Route Params
    const { teamID } = useParams();

    // Search Params
    const [searchParams] = useSearchParams();
    const eventParam = searchParams.get('event');
    const seasonParam = searchParams.get('season');
    const statParam = searchParams.get('stat');

    // State Hooks
    const [chartData, setChartData] = useState({});
    const [reportData, setReportData] = useState<
        Record<string, ReportState<any>>
    >({});
    const [season, setSeason] = useState<string>(seasonParam || '');
    const [filters, setFilters] = useState<ReportDataFilters>({
        event: eventParam ? [eventParam] : [],
        period: 0,
        stat: statParam || '',
    });

    // Cache busting ref
    const timestampRef = useRef(Date.now()).current;

    // API Hooks
    const teamData = useGetTeamQuery({
        teamID: teamID || '',
        expand: 'defaultSeasonDetails',
    });

    const team = useMemo(() => teamData.data?.data, [teamData]);

    const [requestReport] =
        useLazyGetTeamSeasonInterchangeStatTimeSeriesQuery();

    const statConfigData = useGetStatsQuery(
        {
            sportID: team?.entitySportID as string,
        },
        {
            skip: !team,
        }
    );

    const statConfig = useMemo(
        () => statConfigData?.data?.data,
        [statConfigData]
    );

    const seasonReport: TeamStat[] = useMemo(
        () => reportData[season]?.data || [],
        [reportData, season]
    );

    const totalPeriods = useMemo(() => {
        return seasonReport.reduce((maxPeriod: number, item: TeamStat) => {
            const itemPeriod =
                typeof item.period === 'string'
                    ? parseInt(item.period, 10)
                    : item.period;
            return itemPeriod > maxPeriod ? itemPeriod : maxPeriod;
        }, 0);
    }, [seasonReport]);

    const periodOptions = useMemo(
        () =>
            Array.from({ length: totalPeriods }).map((u, i) => {
                const period = i + 1;
                const label = `${period}${periodSuffix(Number(period))}`;
                return {
                    label: label,
                    value: period,
                };
            }),
        [totalPeriods]
    );

    const statOptions = useMemo(() => {
        if (!statConfig) {
            return [];
        }

        const sortedStats = sortBy(statConfig, 'statSortOrder');
        const uniqueStats = uniqBy(seasonReport, 'statId').map(
            (stat) => stat.statId
        );

        return sortedStats
            .filter((stat) => uniqueStats.includes(stat.statID))
            .map((stat) => ({
                label: stat.statName,
                value: stat.statID,
            }));
    }, [seasonReport, statConfig]);

    const resetFilters = () => {
        setFilters({
            event: [],
            period: 0,
            stat: '',
        });
    };

    const handleSeasonChange = (seasonID: string) => {
        setSeason(seasonID);
        resetFilters();
    };

    const handleFilterChange = (key: string, value: string) => {
        setFilters((state) => ({
            ...state,
            [key]: value,
        }));
    };

    const handleDownloadChartImage = (chart: Chart) => {
        const image = chart.getBase64Image();

        if (image) {
            const downloadLink = document.createElement('a');

            downloadLink.href = image;
            downloadLink.download = `${filters.stat}_density.png`;
            downloadLink.click();

            Mixpanel.track('Export Report', {
                reportType: `Stat Density Report`,
            });
        }
    };

    // Set the season to the first available season
    useEffect(() => {
        if (!season && team) {
            setSeason(team.defaultSeasonID);
        }
    }, [team, season]);

    useEffect(() => {
        if (teamID && season && !reportData[season]) {
            const fetchReport = async () => {
                try {
                    // Set report loading in state
                    setReportData((prev) => ({
                        ...prev,
                        [season]: {
                            ...defaultReportState,
                            isLoading: true,
                            isUninitialized: false,
                        },
                    }));

                    //fetch report url
                    const requestReportData = await requestReport({
                        seasonID: season,
                        teamID,
                        sessionID: timestampRef,
                        concatenated: true,
                    }).unwrap();

                    const reportUrl = requestReportData?.data?.objectURL;

                    if (reportUrl) {
                        // Fetch report data
                        const response = await fetch(reportUrl);

                        if (!response.ok) {
                            throw new Error('Failed to load report');
                        }

                        const report = await response.json();

                        // Set report in state
                        setReportData((prev) => ({
                            ...prev,
                            [season]: {
                                ...prev[season],
                                isLoading: false,
                                data: report,
                            },
                        }));
                    } else {
                        throw new Error('Invalid report URL or request failed');
                    }
                } catch (error) {
                    // Set report error in state
                    setReportData((prev) => ({
                        ...prev,
                        [season]: {
                            ...prev[season],
                            isLoading: false,
                            isError: true,
                            error:
                                isObject(error) && 'message' in error
                                    ? error.message
                                    : error,
                        },
                    }));
                }
            };

            fetchReport();
        }
    }, [teamID, season, reportData, requestReport, timestampRef]);

    useEffect(() => {
        if (statOptions && statOptions.length > 0) {
            // Set stat to the first option in statOptions
            handleFilterChange('stat', statOptions[0].value);
        }
    }, [statOptions]);

    useEffect(() => {
        const { event, period, stat } = filters;

        // Create labels for bins (0 to 1, incrementing by 0.1)
        const bins = Array.from({ length: TOTAL_BINS }, (_, i) =>
            (i / 10).toFixed(1)
        );
        const teamBinCounts = Array(bins.length).fill(0);
        const oppositionBinCounts = Array(bins.length).fill(0);

        // Poplate bin values
        seasonReport.forEach((item: any) => {
            const isEventValid =
                !event || event.length === 0 || event.includes(item.gameId);

            const isStatValid =
                item.event.includes('STAT') &&
                item.name.includes('teamID') &&
                stat === item.statId;

            const isPeriodValid = period === 0 || period === item.period;

            // Filter to only show relevant stats
            if (isEventValid && isStatValid && isPeriodValid) {
                // Calculate bin counts for team
                const value =
                    period !== 0 ? item.percOfPeriod : item.percOfGame;
                const binIndex = Math.floor(value * TOTAL_BINS); // Bin index from 0 to 9

                if (item.value.includes('_opponent')) {
                    if (
                        binIndex >= 0 &&
                        binIndex < oppositionBinCounts.length
                    ) {
                        oppositionBinCounts[binIndex]++;
                    }
                } else {
                    if (binIndex >= 0 && binIndex < teamBinCounts.length) {
                        teamBinCounts[binIndex]++;
                    }
                }
            }
        });

        let data = {};

        if (
            !(
                teamBinCounts.every((item) => item === 0) &&
                oppositionBinCounts.every((item) => item === 0)
            )
        ) {
            // Here we define the x axis labels if we are looking at a match of period
            const step = 100 / TOTAL_BINS;
            const xLabels = Array.from({ length: TOTAL_BINS }, (_, i) => {
                const start = Math.round(i * step);
                const end = Math.round((i + 1) * step);
                return `${start}-${end}%`;
            });

            data = {
                datasets: [
                    {
                        label: `Team`,
                        data: teamBinCounts.map((value, index) => ({
                            x: xLabels[index], // Use selected xLabels based on filter
                            y: value + 0.05, // Add a small y-offset
                        })),
                        tension: 0.2,
                        borderColor: '#ff6700',
                        backgroundColor: 'rgba(255,167,38,0.2)',
                        pointStyle: 'circle', // Unique point style
                    },
                    {
                        label: `Opposition`,
                        data: oppositionBinCounts,
                        tension: 0.2,
                        borderColor: 'black',
                        backgroundColor: 'rgba(0,0,0,0.2)',
                    },
                ],
            };
        }

        setChartData(data);
    }, [seasonReport, filters]);

    const chartOptions = useMemo(() => {
        const statLabel = statOptions.find(
            (o) => o.value === filters.stat
        )?.label;
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue(
            '--text-color-secondary'
        );
        const surfaceBorder =
            documentStyle.getPropertyValue('--surface-border');

        return {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    xLabels: {
                        color: textColor,
                    },
                },
                tooltip: {
                    callbacks: {
                        label: (context: any) => {
                            const label = context.dataset.label || '';
                            const value =
                                context.raw.y !== undefined
                                    ? context.raw.y
                                    : context.raw;
                            return `${label}: ${Math.round(value)}`;
                        },
                    },
                },
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text:
                            filters.period !== 0
                                ? `Percentage of ${
                                      filters.period
                                  }${periodSuffix(
                                      Number(filters.period)
                                  )} Period`
                                : 'Percentage of Game',
                        color: textColor,
                        font: {
                            size: 16,
                            weight: 'bold',
                        },
                    },
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                },
                secondX: {
                    position: 'top',
                    labels: [
                        ...periodOptions.map((period) => period.label),
                        '',
                    ],
                    grid: {
                        borderWidth: 0,
                        tickLength: 0,
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: statLabel,
                        color: textColor,
                        font: {
                            size: 16,
                            weight: 'bold',
                        },
                    },
                    ticks: {
                        color: textColorSecondary,
                    },
                    grid: {
                        color: surfaceBorder,
                    },
                },
            },
        };
    }, [filters, statOptions, periodOptions]);

    const isLoading =
        statConfigData.isLoading ||
        teamData.isLoading ||
        reportData[season]?.isLoading;

    return (
        <TeamStatDensityReportView
            chartData={chartData}
            chartOptions={chartOptions}
            filters={filters}
            isLoading={isLoading}
            options={{
                stats: statOptions,
                periods: periodOptions,
            }}
            season={season}
            team={team}
            onDownloadChartImage={handleDownloadChartImage}
            onFilterChange={handleFilterChange}
            onSeasonChange={handleSeasonChange}
        />
    );
};

export default TeamStatDensityReportContainer;
