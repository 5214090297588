import { PaginatedResponse, Response } from './api';
import { Design } from './design';

export enum OrganisationStatus {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted',
}

export interface Organisation {
    organisationID: string;
    organisationName: string;
    createdAt: string;
    lastEditedBy: string;
    lastEdited: string;
    design: Design;
    organisationStatus: OrganisationStatus;
    jumperKit?: string;
    entityCategoryID: string;
    entitySportID: string;
    data?: any;
}

export interface OrganisationFormData {
    organisationID?: string;
    associationID?: string;
    entityStatus?: OrganisationStatus;
    userID?: string;
    entitySportID?: string;
    expand?: string;
    cursor?: string;
    limit?: number;
}

export type OrganisationsResponse = PaginatedResponse<Organisation[]>;
export type OrganisationResponse = Response<Organisation>;
