import Loader from '../../components/Loader';
import PageContainer from '../../layout/PageContainer';
import PageHeader from '../../layout/PageHeader';
import MessageSettingsForm from './MessageSettingsForm';
import { BaseEntityType } from '../../types/common';
import { MessageSettings } from '../../types/messages';

interface Props {
    isLoading: boolean;
    entityType?: BaseEntityType;
    entityID?: string;
    onSuccess?: () => void;
    messageSettings?: MessageSettings;
}

const MessageSetupView = ({
    isLoading,
    entityType,
    entityID,
    onSuccess,
    messageSettings,
}: Props) => {
    if (isLoading) {
        return <Loader />;
    }

    if (!entityType || !entityID) {
        return <div>No Entity Found</div>;
    }

    return (
        <PageContainer size="narrow">
            <PageHeader title="Welcome To Messaging" showBreadcrumbs={false} />
            <p>To get started, select which channels you would like enabled.</p>
            <MessageSettingsForm
                entityType={entityType}
                entityID={entityID}
                onSuccess={onSuccess}
                messageSettings={messageSettings}
            />
        </PageContainer>
    );
};

export default MessageSetupView;
