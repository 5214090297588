import { SyntheticEvent, useMemo, useRef, useState } from 'react';
import { groupBy, sortBy, uniq } from 'lodash';

import PlanFeatureCard from './PlanFeaturesCard';
import { productDetails } from '../../util/constants';
import Icon from '../../components/Icon';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Image } from 'primereact/image';
import { MenuItem, MenuItemOptions } from 'primereact/menuitem';
import { OverlayPanel } from 'primereact/overlaypanel';
import { TabMenu } from 'primereact/tabmenu';

import { PlanEssentials } from '../../types/subscriptions';
import ErrorDisplay from '../../components/ErrorDisplay';
import { LicenceTypeFeature } from '../../types/licences';

const tierRank = {
    lite: 1,
    basic: 2,
    advanced: 3,
    expert: 4,
};

interface Props {
    plans: { [key: string]: PlanEssentials[] };
}

const PlanFeatures = ({ plans }: Props) => {
    const op = useRef<OverlayPanel>(null);

    const [activeProduct, setActiveProduct] = useState<string>(
        Object.keys(plans)[0]
    );
    const [activeFeatureCard, setActiveFeatureCard] = useState<any>(null);

    const activePlan = useMemo(
        () => plans[activeProduct],
        [plans, activeProduct]
    );

    const tiers = useMemo(() => {
        const tiers = uniq(activePlan.map((plan) => plan.tier));

        return sortBy(tiers, (tier) => tierRank[tier as keyof typeof tierRank]);
    }, [activePlan]);

    const features = useMemo(() => {
        const allFeatures = activePlan
            .reduce((result, item) => {
                if (item.features) {
                    result = result.concat(
                        item.features.map((feat) => ({
                            ...feat,
                            tier: item.tier,
                        }))
                    );
                }
                return result;
            }, [] as LicenceTypeFeature[])
            .filter((feat) => !feat.title.includes('Everything'));

        const groupedFeatures = groupBy(allFeatures, 'title');

        const mapped = Object.entries(groupedFeatures).map(
            ([key, features]) => {
                let item: any = { title: key };

                tiers.forEach((tier, index) => {
                    const prevTierKey = tiers[index - 1];
                    const prevTier = item[prevTierKey as keyof typeof item];
                    const feature = features.find((v) => v.tier === tier);

                    item[tier as keyof typeof item] = feature || prevTier;
                });

                return item;
            }
        );

        return mapped;
    }, [activePlan, tiers]);

    const tabs = Object.keys(plans).map((product) => ({
        label: product,
        id: product,
        template: ({ id }: MenuItem, options: MenuItemOptions) => {
            const logo = productDetails[id as keyof typeof productDetails].logo;

            return (
                <div {...options}>
                    <Image
                        className="app-logo-img app-logo-brandmark"
                        src={logo}
                        height="40px"
                        alt="Rookie Me Hub"
                    />
                </div>
            );
        },
    }));

    const handleFeatureClick = (
        e: SyntheticEvent<Element, Event>,
        feature: any
    ) => {
        setActiveFeatureCard(feature);
        op?.current?.toggle(e);
    };

    return (
        <div className="plan-comparison">
            <h1>Compare Features</h1>

            <TabMenu
                model={tabs}
                activeIndex={
                    activeProduct
                        ? tabs.map((e) => e.id).indexOf(activeProduct)
                        : 0
                }
                onTabChange={(e) => setActiveProduct(tabs[e.index].id)}
            />

            <DataTable
                value={features}
                emptyMessage={
                    <ErrorDisplay
                        title={'Features Coming Soon...'}
                        hasReturn={false}
                        hasReporting={false}
                    />
                }
            >
                <Column
                    header="Feature"
                    field="title"
                    style={{ width: '50%' }}
                />

                {tiers.map((tier) => (
                    <Column
                        key={tier}
                        header={tier}
                        field={`${tier}.unavailable`}
                        align="center"
                        alignHeader="center"
                        body={(row) => {
                            const rowValue = row[tier as keyof typeof tier];

                            if (rowValue) {
                                const hasFeatureCard =
                                    !!rowValue.cardDescription &&
                                    !!rowValue.cardTitle;

                                const value =
                                    !rowValue.unavailable ||
                                    rowValue.unavailable !== 'false';

                                return (
                                    value && (
                                        <span
                                            onMouseOver={(e) =>
                                                hasFeatureCard &&
                                                handleFeatureClick(e, rowValue)
                                            }
                                            onMouseOut={() =>
                                                op?.current?.hide()
                                            }
                                        >
                                            <Icon
                                                name="check_circle"
                                                fill
                                                size="large"
                                                style={{ color: '#ff6700' }}
                                            />
                                        </span>
                                    )
                                );
                            }
                        }}
                    />
                ))}
            </DataTable>

            <OverlayPanel ref={op}>
                <PlanFeatureCard feature={activeFeatureCard} />
            </OverlayPanel>
        </div>
    );
};

export default PlanFeatures;
