import { useNavigate } from 'react-router-dom';

import { format, fromUnixTime } from 'date-fns';

import EntityName from '../../components/EntityName';
import PageHeader from '../../layout/PageHeader';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { User } from '../../types/user';
import { Subscription, SubscriptionItem } from '../../types/subscriptions';
import { Skeleton } from 'primereact/skeleton';
import RookieButton from '../../components/RookieButton';
import PageContainer from '../../layout/PageContainer';
import { isEmpty } from 'lodash';

interface Props {
    data: Subscription[];
    isLoading: boolean;
    isError: boolean;
    isFetching?: boolean;
    showPagination: boolean;
    roles: Roles;
    route: Route;
    user: User;

    onManageClick: () => void;
    onLoadMore: () => void;
}

const SubscriptionsView = (props: Props) => {
    const {
        data,
        onManageClick,
        onLoadMore,
        showPagination,
        isLoading,
        isFetching,
    } = props;
    const navigate = useNavigate();

    const handleRowClick = (row: any) => {
        navigate(row.data.id);
    };

    const footerTemplate = () => {
        return (
            <RookieButton
                onClick={onLoadMore}
                severity="secondary"
                label="Load more"
                icon="pending"
            />
        );
    };

    return (
        <PageContainer>
            <PageHeader
                title="Subscriptions"
                actions={
                    data.length > 0
                        ? [{ label: 'Manage', onClick: onManageClick }]
                        : []
                }
            />
            <DataTable
                value={isLoading ? Array(3).fill(0) : data}
                onRowClick={handleRowClick}
                footer={!isFetching && showPagination ? footerTemplate : null}
                columnResizeMode="expand"
                resizableColumns
                selectionMode="single"
                className="p-card"
            >
                <Column
                    field="metadata.entityID"
                    header="Entity"
                    body={(data) =>
                        isLoading ? (
                            <Skeleton />
                        ) : !isEmpty(data.metadata) ? (
                            <EntityName
                                entityID={data.metadata.entityID}
                                entityType={data.metadata.entityType}
                            />
                        ) : (
                            <div>Unknown</div>
                        )
                    }
                />
                <Column
                    field="created"
                    header="Created"
                    body={(data) =>
                        isLoading ? (
                            <Skeleton />
                        ) : (
                            format(fromUnixTime(data.created), 'MMMM dd, yyyy')
                        )
                    }
                />
                <Column
                    field="items.total_count"
                    header="Plans"
                    body={isLoading && <Skeleton />}
                />
                <Column
                    header="Licences"
                    body={(data) => {
                        if (isLoading) {
                            return <Skeleton />;
                        } else {
                            let count = 0;

                            data.items.data.forEach(
                                (item: SubscriptionItem) => {
                                    count += item.quantity;
                                }
                            );

                            return count;
                        }
                    }}
                />
            </DataTable>
        </PageContainer>
    );
};

export default SubscriptionsView;
