import { useContext, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import usePermission from '../../hooks/usePermission';
import { ToastContext } from '../../contexts/ToastContext';
import { productDetails } from '../../util/constants';
import { getEntityFromParam, isInAppSubscription } from '../../util/helper';

import {
    useAssignLicenceMutation,
    useGetLicenceGroupsQuery,
    useGetLicenceTypesQuery,
    useGetLicencesQuery,
    useUnAssignLicenceMutation,
} from '../../api/licences';
import { useGetEntityQuery } from '../../api/core';
import { useGetStaffDetailsQuery } from '../../api/staff';

import LicencesView from './LicencesView';
import { ToastMessage } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';
import RookieButton from '../../components/RookieButton';

import { LicenceGroupInfo, LicenceType } from '../../types/licences';
import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { User } from '../../types/user';
import { BaseEntityType } from '../../types/common';
import { Permissions } from '../../types/permissions';

interface Props {
    roles: Roles;
    route: Route;
    user: User;
}

const LicencesContainer = (props: Props) => {
    const { user } = props;

    // Context Hooks
    const toast = useContext(ToastContext);

    // Route Hooks
    const navigate = useNavigate();
    const params = useParams();

    const entity = getEntityFromParam(params);

    const { checkPermission } = usePermission(
        params.organisationID || params.teamID || ''
    );

    // API Hooks
    const entityData = useGetEntityQuery(
        {
            entityType: entity?.entityType as BaseEntityType,
            entityID: entity?.entityID as string,
            expand:
                entity?.entityType === BaseEntityType.teams
                    ? 'organisationDetails'
                    : '',
        },

        { skip: !entity }
    );

    const { data: licenceGroupsRaw, isLoading: isLoadingGroups } =
        useGetLicenceGroupsQuery(
            {
                entityType: entity?.entityType as BaseEntityType,
                entityID: entity?.entityID as string,
            },
            { skip: !entity }
        );

    const { data: licencesRaw, isLoading: isLoadingLicences } =
        useGetLicencesQuery(
            {
                entityType: entity?.entityType as BaseEntityType,
                entityID: entity?.entityID as string,
            },
            { skip: !entity }
        );

    const { data: licenceTypes, isLoading: isLoadingTypes } =
        useGetLicenceTypesQuery(
            {
                sportID: entityData?.data?.data?.entitySportID as string,
                expand: 'productDetails',
            },
            { skip: !entityData?.data?.data }
        );

    const { data: staffData, isLoading: isLoadingStaff } =
        useGetStaffDetailsQuery(
            {
                entityType: entity?.entityType as BaseEntityType,
                entityID: entity?.entityID as string,
            },
            {
                skip: !entity,
            }
        );

    const [assignLicence] = useAssignLicenceMutation();
    const [unassignLicence] = useUnAssignLicenceMutation();

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const showInAppDialog = () => {
        confirmDialog({
            message: `Your subsription is managed via your Apple account. Use the Rookie Me Play app to view or update your subscription.`,
            header: 'Manage on the Play app',
            footer: (confirmProps) => {
                return (
                    <div>
                        <RookieButton
                            label="Ok"
                            onClick={confirmProps.accept}
                        />
                    </div>
                );
            },
        });
    };

    const handleManageBilling = (
        subscriptionID: string,
        inAppPurchase = false
    ) => {
        if (inAppPurchase) {
            showInAppDialog();
        } else if (subscriptionID) {
            navigate(`/u/${user.userID}/subscriptions/${subscriptionID}`);
        }
    };

    const handleChangePlan = (
        rookieProduct: string,
        licenceGroup?: LicenceGroupInfo
    ) => {
        const inAppPurchase = licenceGroup && isInAppSubscription(licenceGroup);
        const url = `/plans?entityType=${entity?.entityType}&entityID=${entity?.entityID}&rookieProduct=${rookieProduct}&returnUrl=${window.location.href}`;

        if (inAppPurchase && licenceGroup.price > 0) {
            showInAppDialog();
        } else if (params.teamID) {
            const unassignedLicence = licenceGroupsRaw?.data.find(
                (group) => group.rookieProduct === rookieProduct
            );

            if (!licenceGroup && unassignedLicence) {
                const prod = productDetails[rookieProduct];

                confirmDialog({
                    message: `You already have a ${prod.title} licence. Click "Assign" to activate it.`,
                    header: 'Licence Already Exists',
                    rejectLabel: 'Cancel',
                    acceptLabel: 'Continue',
                    accept: () => {
                        handleAssignLicence(unassignedLicence.licenceGroupID);
                    },
                    footer: (confirmProps) => {
                        return (
                            <div className="p-button-group">
                                <RookieButton
                                    label="Cancel"
                                    onClick={confirmProps.reject}
                                    severity="secondary"
                                />

                                <RookieButton
                                    label="View Plans"
                                    onClick={() => navigate(url)}
                                    severity="secondary"
                                />
                                <RookieButton
                                    label="Assign"
                                    onClick={confirmProps.accept}
                                />
                            </div>
                        );
                    },
                });
            } else {
                navigate(url);
            }
        }
    };

    const handleAssignLicence = (licenceGroupID: string) => {
        if (params.teamID) {
            assignLicence({
                entityID: params.teamID,
                entityType: BaseEntityType.teams,
                licenceGroupID,
                teamsIDList: [params.teamID],
            })
                .then((response) => {
                    showToast({
                        severity: 'success',
                        detail: 'Successfully applied licence.',
                    });
                })
                .catch((err) => {
                    showToast({
                        severity: 'error',
                        detail: 'Error applying licence.',
                    });
                });
        }
    };

    const handleUnassignLicence = (licenceID: string) => {
        if (params.teamID) {
            unassignLicence({
                entityID: params.teamID,
                entityType: BaseEntityType.teams,
                licenceID,
            })
                .then((response) => {
                    showToast({
                        severity: 'success',
                        detail: 'Successfully removed licence.',
                    });
                })
                .catch((err) => {
                    showToast({
                        severity: 'error',
                        detail: 'Error removing licence. Please try again.',
                    });
                });
        }
    };

    const getStaffName = (userID: string) => {
        if (userID === user.userID) {
            return 'Me';
        }

        const staff = staffData?.data.find((o) => o.userID === userID);

        return staff && staff.userDetails.name;
    };

    const licenceGroups = useMemo<LicenceGroupInfo[]>(() => {
        let arr: LicenceGroupInfo[] = [];

        if (licenceGroupsRaw && licenceGroupsRaw?.data.length > 0) {
            licenceGroupsRaw.data.forEach((licenceGroup) => {
                const licenceType: LicenceType | undefined =
                    licenceTypes?.data.find(
                        (type) =>
                            licenceGroup.licenceTypeID === type.licenceTypeID
                    );

                const { subscription } = licenceGroup;

                const price = licenceType?.product?.prices.find(
                    (p) => p.priceID === licenceGroup.priceID
                );

                arr.push({
                    id: licenceGroup.licenceGroupID,
                    priceID: price?.priceID || '',
                    productTitle: licenceType?.product?.productName || '',
                    licenceTitle: licenceType?.licenceName || 'Legacy',
                    tier: price && price.tier,
                    price:
                        (price && price.unitAmount && price.unitAmount / 100) ||
                        0,
                    currency: 'AUD',
                    createdAt: licenceGroup.createdAt,
                    lastEdited: licenceGroup.lastEditedAt,
                    billingPeriod: price && price.recurring?.interval,
                    renewsAt: subscription
                        ? new Date(subscription.current_period_end * 1000)
                        : null,
                    trialActive:
                        subscription && subscription.trial_end
                            ? new Date().getTime() -
                                  subscription.trial_end * 1000 <
                              0
                            : false,
                    trialEndsAt:
                        subscription && subscription.trial_end
                            ? new Date(subscription.trial_end * 1000)
                            : null,
                    qtyAvailable:
                        licenceGroup.quantity - licenceGroup.totalAssigned,
                    qtyAssigned: licenceGroup.totalAssigned,
                    qtyTotal: licenceGroup.quantity,
                    status: licenceGroup.status,
                    groupID:
                        licenceGroup.subscriptionID ||
                        licenceGroup.invoiceID ||
                        'unknown',
                    description: licenceType?.product?.productDescription || '',
                    isOwner: licenceGroup.createdBy === user.userID || false, // hard-code this for now,
                    owner: licenceGroup.createdBy,
                    features:
                        licenceType?.product?.productFeatures.map(
                            (feat) => feat.name
                        ) || [],
                    entityID: licenceGroup.entityID,
                    entitySportID: licenceGroup.entitySportID,
                    entityType: licenceGroup.entityType,
                    subscriptionID: licenceGroup.subscriptionID,
                    transactionID: licenceGroup.transactionID,
                    rookieProduct: licenceGroup.rookieProduct,
                });
            });
        }

        // order by groupID (either subID or invoiceID) - more useful for ass and org
        arr.sort((a, b) =>
            a.groupID === b.groupID ? 0 : a.groupID < b.groupID ? -1 : 1
        );

        return arr;
    }, [licenceGroupsRaw, licenceTypes, user]);

    const permissions = {
        canCreate: checkPermission([
            Permissions.POST_TEAMS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canDelete: checkPermission([
            Permissions.DELETE_TEAMS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canEdit: checkPermission([
            Permissions.PUT_TEAMS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canView: checkPermission([
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
    };

    const isLoading =
        isLoadingLicences ||
        isLoadingGroups ||
        isLoadingTypes ||
        isLoadingStaff;

    return (
        <LicencesView
            getStaffName={getStaffName}
            isLoading={isLoading}
            licences={licencesRaw?.data || []}
            licenceGroups={licenceGroups}
            onAssignLicence={handleAssignLicence}
            onChangePlan={handleChangePlan}
            onManageBilling={handleManageBilling}
            onUnassignLicence={handleUnassignLicence}
            permissions={permissions}
            {...props}
        />
    );
};

export default LicencesContainer;
