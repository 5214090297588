import { useContext, useEffect } from 'react';
import {
    Channel,
    ChannelHeader,
    Chat,
    LoadingIndicator,
    MessageInput,
    MessageList,
    Thread,
    useCreateChatClient,
    Window,
} from 'stream-chat-react';
import { Channel as ChannelType } from 'stream-chat';
import { format } from 'date-fns';

import { LayoutContext } from '../../contexts/LayoutContext';
import PageHeader from '../../layout/PageHeader';
import ErrorDisplay from '../../components/ErrorDisplay';
import MessageSettingsForm from './MessageSettingsForm';
import ListItem from '../../components/ListItem';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { Badge } from 'primereact/badge';

import { ThemeMode } from '../../types/layout';
import { BaseEntityType, ERROR_TYPES } from '../../types/common';
import { MessageChannel, MessageSettings } from '../../types/messages';

import 'stream-chat-react/dist/css/v2/index.css';
import ProductTag from '../../components/ProductTag';
import { RookieProduct } from '../../types/licences';

interface Props {
    channels?: MessageChannel[];
    selectedChannel?: ChannelType;
    userData: any;
    streamToken: any;
    filters: any;
    sort: any;
    options: any;
    isLoading: boolean;
    showSettings: boolean;
    entityID?: string;
    entityType?: BaseEntityType;
    messageSettings?: MessageSettings;
    onShowSettings: (show: boolean) => void;
    onSelectChannel: (chatClient: any, channel: MessageChannel) => void;
}

const MessagesView = (props: Props) => {
    const { layoutConfig } = useContext(LayoutContext);

    const { isLoading, selectedChannel, channels, onSelectChannel } = props;

    const apiKey = process.env.REACT_APP_GET_STREAM_API_KEY || '';

    // Keep the chat client stable across renders
    const chatClient = useCreateChatClient({
        apiKey,
        tokenOrProvider: props.streamToken,
        userData: props.userData,
    });

    useEffect(() => {
        if (chatClient && !selectedChannel && channels && channels.length > 0) {
            onSelectChannel(chatClient, channels[0]);
        }
    }, [chatClient, selectedChannel, channels, onSelectChannel]);

    if (isLoading || !chatClient || !chatClient.user) {
        return <LoadingIndicator />;
    }

    return (
        <>
            {!channels || channels.length <= 0 ? (
                <ErrorDisplay
                    actions={[
                        {
                            icon: 'refresh',
                            label: 'Reload Page',
                            onClick: () => window.location.reload(),
                        },
                        {
                            onClick: () => props.onShowSettings(true),
                            icon: 'settings',
                            label: 'Settings',
                            severity: 'secondary',
                        },
                    ]}
                    alignment="middle"
                    desc={
                        'If you have recently updated Settings, please allow up to 30 seconds for changes to reflect.'
                    }
                    errorType={ERROR_TYPES.empty}
                    hasReturn={false}
                    title={`No Message Channels Found Yet`}
                />
            ) : (
                <>
                    <div className="chat-wrapper">
                        <Chat
                            client={chatClient}
                            theme={
                                layoutConfig.theme === ThemeMode.Dark
                                    ? 'str-chat__theme-dark'
                                    : 'str-chat__theme-light'
                            }
                        >
                            <div className="chat-sidebar">
                                <PageHeader
                                    title="Messages"
                                    showBreadcrumbs={false}
                                    actions={[
                                        {
                                            icon: 'settings',
                                            onClick: () =>
                                                props.onShowSettings(true),
                                            severity: 'secondary',
                                        },
                                    ]}
                                />
                                <div>
                                    {channels?.map((channel) => (
                                        <ListItem
                                            key={channel.cid}
                                            selected={
                                                channel.cid ===
                                                selectedChannel?.cid
                                            }
                                            component="button"
                                            title={channel.name}
                                            caption={`Last read ${format(
                                                new Date(channel.lastRead),
                                                'dd MMM yyyy'
                                            )}`}
                                            end={
                                                channel.unreadMessages > 0 && (
                                                    <Badge
                                                        value={
                                                            channel.unreadMessages
                                                        }
                                                    />
                                                )
                                            }
                                            onClick={() =>
                                                onSelectChannel(
                                                    chatClient,
                                                    channel
                                                )
                                            }
                                        />
                                    ))}
                                    <div className="integrated-msg">
                                        <span>Works with</span>
                                        <ProductTag
                                            product={RookieProduct.Connect}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="chat-details">
                                <Channel channel={selectedChannel}>
                                    <Window>
                                        <Message
                                            text="Messages are currently in early access. Future updates may change its availability and access."
                                            className="is-subtle"
                                            severity="info"
                                            style={{
                                                width: '100%',
                                                borderRadius: 0,
                                            }}
                                        />
                                        <ChannelHeader />

                                        <MessageList />
                                        <MessageInput />
                                    </Window>
                                    <Thread />
                                </Channel>
                            </div>
                        </Chat>
                    </div>
                </>
            )}
            <Dialog
                header="Message Settings"
                onHide={() => props.onShowSettings(false)}
                visible={props.showSettings}
            >
                {props.entityID && props.entityType && (
                    <MessageSettingsForm
                        entityID={props.entityID}
                        entityType={props.entityType}
                        messageSettings={props.messageSettings}
                        onSuccess={() => props.onShowSettings(false)}
                    />
                )}
            </Dialog>
        </>
    );
};

export default MessagesView;
