import { useEffect, useState } from 'react';

import RookieButton from '../../components/RookieButton';

import { useGetStaffSingleQuery } from '../../api/staff';
import { useGetMeQuery } from '../../api/core';

import { Chip } from 'primereact/chip';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import ErrorDisplay from '../../components/ErrorDisplay';

import { ERROR_TYPES, EntityType } from '../../types/common';
import { Note, NoteFormData, NotePrivacy } from '../../types/note';
import usePrivilege from '../../hooks/usePrivilege';

interface Props {
    note?: Note;
    onDelete?: (noteID: string) => void;
    onSave: (note: NoteFormData) => void;
    onCreate: () => void;
    onBackClick?: () => void;
    onNodeClick: (nodeID: string, nodeType: EntityType) => void;
    onNodeDelete: (
        noteID: string,
        nodeID: string,
        nodeType: EntityType
    ) => void;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const NoteDetail = ({
    note,
    onDelete,
    onSave,
    onCreate,
    onNodeClick,
    onBackClick,
    permissions,
}: Props) => {
    const [noteContent, setNoteContent] = useState('');
    const [noteTitle, setNoteTitle] = useState('');
    const [notePrivacy, setNotePrivacy] = useState<NotePrivacy>(
        NotePrivacy.Private
    );

    const { data: meData } = useGetMeQuery();

    const { data: lastEditedUserData } = useGetStaffSingleQuery(
        {
            //@ts-expect-error
            entityID: note?.entityID,
            //@ts-expect-error
            entityType: note?.entityType,
            //@ts-expect-error
            userID: note?.lastEditedBy,
        },
        { skip: !note || note.lastEditedBy === meData?.data.userID }
    );

    // Privilige Hook
    const { checkRestrictions } = usePrivilege(
        note?.entityID,
        note?.entityType,
        !note
    );

    const lastEditedUser =
        note?.lastEditedBy === meData?.data.userID
            ? 'You'
            : `${lastEditedUserData?.data.firstName} ${lastEditedUserData?.data.lastName}`;

    useEffect(() => {
        if (note) {
            setNoteTitle(note.noteTitle);
            setNoteContent(note.noteContent);
            setNotePrivacy(note.notePrivacy);
        }
    }, [note]);

    const handleSave = () => {
        note &&
            onSave &&
            onSave({
                noteID: note.noteID,
                noteContent,
                noteTitle,
                notePrivacy,
            });
    };

    const noteMeta = (
        <div className="note-meta">Last edited by {lastEditedUser}</div>
    );

    const enablePublicNotes =
        note && checkRestrictions({ HUB_TEAM_NOTES: ['public'] });

    return (
        <div className="notes-details">
            {note ? (
                <>
                    <Toolbar
                        className="note-toolbar"
                        start={
                            <>
                                <RookieButton
                                    className="back-btn"
                                    onClick={onBackClick}
                                    icon="arrow_back"
                                    text
                                />
                                <Dropdown
                                    value={notePrivacy}
                                    options={[
                                        { value: 'Private', label: 'Private' },
                                        {
                                            value: 'Public',
                                            label: 'Public',
                                            disabled: !enablePublicNotes,
                                        },
                                    ]}
                                    onChange={(e) => setNotePrivacy(e.value)}
                                    disabled={!permissions.canEdit}
                                />
                            </>
                        }
                        center={noteMeta}
                        end={
                            <div className="p-button-group">
                                {permissions.canDelete && (
                                    <RookieButton
                                        text={true}
                                        icon="delete"
                                        onClick={() =>
                                            onDelete && onDelete(note.noteID)
                                        }
                                    />
                                )}
                                {permissions.canEdit && (
                                    <RookieButton
                                        label="Save"
                                        onClick={handleSave}
                                    />
                                )}
                            </div>
                        }
                    />
                    <div className="note-composer">
                        <div className="p-input-title">
                            <InputText
                                value={noteTitle}
                                onChange={(e) => setNoteTitle(e.target.value)}
                                placeholder="Untitled"
                                maxLength={30}
                            />
                        </div>
                        <div className="p-input-description">
                            <InputTextarea
                                value={noteContent}
                                onChange={(e) => setNoteContent(e.target.value)}
                                placeholder="Enter some text"
                            />
                        </div>
                    </div>
                    <div className="note-nodes">
                        {note.nodeList.map((node) => (
                            <Chip
                                label={node.nodeSummary}
                                onClick={() =>
                                    onNodeClick(node.nodeID, node.nodeType)
                                }
                            />
                        ))}
                    </div>
                    {noteMeta}
                </>
            ) : (
                <ErrorDisplay
                    errorType={ERROR_TYPES.emptyFolder}
                    title="You don't have any notes selected."
                    desc="Create or select a note and it will appear here."
                    hasReturn={false}
                    actions={[{ label: 'Create Note', onClick: onCreate }]}
                />
            )}
        </div>
    );
};

export default NoteDetail;
