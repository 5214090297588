import { useParams } from 'react-router-dom';

import UpdateLicenceForm from './UpdateLicenceForm';
import AssignLicencesForm from './AssignLicencesForm';

import EntityName from '../../components/EntityName';
import RookieButton from '../../components/RookieButton';
import PageHeader from '../../layout/PageHeader';
import PageContainer from '../../layout/PageContainer';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Sidebar } from 'primereact/sidebar';
import { Skeleton } from 'primereact/skeleton';

import { Licence, LicenceGroupInfo, LicenceType } from '../../types/licences';
import { Team } from '../../types/team';
import { User } from '../../types/user';
import { useMemo, useRef } from 'react';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';

interface Props {
    licences: Licence[];
    licenceGroup: LicenceGroupInfo | null;
    licenceTypes: LicenceType[];
    showAssignModal: boolean;
    showEditModal: boolean;
    isLoading: boolean;
    onAssignLicence: (licenceGroupID: string, teams: Team[]) => void;
    onUnassignLicence: (licenceID: string) => void;
    onManageBilling: (subscriptionID: string) => void;
    onShowEditModal: () => void;
    onCloseEditModal: () => void;
    onShowAssignModal: () => void;
    onCloseAssignModal: () => void;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
    user: User;
}

const OrganisationLicenceSingleView = (props: Props) => {
    const {
        licences,
        licenceGroup,
        onAssignLicence,
        onUnassignLicence,
        showAssignModal,
        showEditModal,
        onManageBilling,
        onShowEditModal,
        onCloseEditModal,
        onShowAssignModal,
        onCloseAssignModal,
        isLoading,
        permissions,
        user,
    } = props;
    const { organisationID, licenceGroupID } = useParams();
    const menu = useRef<Menu>(null);

    const rowActions = (data: any) => (
        <div>
            {data.status === 'Unassigned' && (
                <RookieButton
                    label="Assign Licence"
                    onClick={() => onShowAssignModal()}
                />
            )}
            {permissions.canEdit && (
                <RookieButton
                    label="Remove Licence"
                    onClick={() => onUnassignLicence(data.licenceID)}
                    severity="secondary"
                />
            )}
        </div>
    );

    const menuOptions = useMemo(() => {
        if (!permissions.canEdit) {
            return [];
        }

        let options: MenuItem[] = [
            {
                label: 'Change Plan',
                command: () => onShowEditModal(),
            },
        ];

        if (
            licenceGroup?.owner === user.userID &&
            licenceGroup?.subscriptionID
        ) {
            options.push({
                label: 'Manage Subscription',
                command: () => onManageBilling(licenceGroup.subscriptionID),
            });
        }

        return options;
    }, [licenceGroup, onManageBilling, onShowEditModal, permissions, user]);

    return (
        <PageContainer>
            <PageHeader
                title="Licences"
                actions={
                    permissions.canEdit
                        ? [
                              {
                                  label: 'Assign Licences',
                                  onClick: () => onShowAssignModal(),
                              },
                              {
                                  onClick: (e: any) => menu.current?.show(e),
                                  icon: 'more_horiz',
                                  severity: 'secondary',
                              },
                          ]
                        : []
                }
            />
            <DataTable
                value={isLoading ? Array(3) : licences}
                emptyMessage="No assigned licences"
                columnResizeMode="expand"
                resizableColumns
            >
                <Column
                    field="entityID"
                    header="Team"
                    body={(data) =>
                        isLoading ? (
                            <Skeleton />
                        ) : (
                            <EntityName
                                entityType={data.entityType}
                                entityID={data.entityID}
                            />
                        )
                    }
                />
                <Column
                    body={isLoading ? <Skeleton /> : rowActions}
                    style={{ textAlign: 'right' }}
                />
            </DataTable>
            <Sidebar
                onHide={() => onCloseEditModal()}
                visible={showEditModal}
                position="right"
                className="plan-sidebar"
                content={
                    licenceGroup && (
                        <UpdateLicenceForm
                            licenceGroup={licenceGroup}
                            onCancel={onCloseEditModal}
                            onSuccess={() => {
                                window.location.reload();
                            }}
                        />
                    )
                }
            />
            <Sidebar
                header="Assign Licence"
                onHide={() => onCloseAssignModal()}
                visible={showAssignModal}
                position="right"
            >
                {licenceGroupID && organisationID && (
                    <AssignLicencesForm
                        licenceGroupID={licenceGroupID}
                        organisationID={organisationID}
                        onCancel={() => onCloseAssignModal()}
                        onSubmit={onAssignLicence}
                    />
                )}
            </Sidebar>

            <Menu ref={menu} model={menuOptions} popup popupAlignment="right" />
        </PageContainer>
    );
};

export default OrganisationLicenceSingleView;
