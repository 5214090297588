import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import usePermission from '../hooks/usePermission';

import { USE_CUSTOM_TOKEN } from '../util/constants';

import ErrorDisplay from '../components/ErrorDisplay';
import Loader from '../components/Loader';

import { BaseEntityType, ERROR_TYPES } from '../types/common';
import { Route } from '../types/route';
import { Me } from '../types/user';
import { getEntityFromPath, getParentEntityFromPath } from '../util/helper';
import { useGetLicencesQuery } from '../api/licences';

interface Props {
    children: React.ReactElement;
    route: Route;
    user?: Me;
}

const ProtectedRoute = ({ children, route, user }: Props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { loginWithRedirect, isAuthenticated } = useAuth0();

    const useCustomToken = localStorage.getItem(USE_CUSTOM_TOKEN);

    const parentEntity = getParentEntityFromPath(location.pathname);
    const currentEntity = getEntityFromPath(location.pathname);

    const { data: teamLicences } = useGetLicencesQuery(
        {
            entityType: BaseEntityType.teams,
            entityID: currentEntity?.entityID || '',
        },
        {
            skip:
                !currentEntity ||
                currentEntity.entityType !== BaseEntityType.teams,
        }
    );

    // Permission Checking
    const { checkPermission, isAdmin, role } = usePermission(
        parentEntity?.entityID || '',
        !user
    );

    if (!isAuthenticated && useCustomToken !== 'true') {
        loginWithRedirect({
            appState: {
                returnTo: window.location.href,
            },
        });

        return <Loader size="fullscreen" title="Redirecting to login.." />;
    }

    const hasPermission = checkPermission(route.permissions);

    const requiresRole =
        parentEntity &&
        [
            BaseEntityType.associations,
            BaseEntityType.organisations,
            BaseEntityType.teams,
        ].includes(parentEntity.entityType);

    const resetRoute = () => navigate(0);

    if (requiresRole && !role.data && !isAdmin) {
        return (
            <ErrorDisplay
                errorType={ERROR_TYPES.pageNotFound}
                title="You don't have a role within this entity"
                desc="Contact the entity admin if you believe this is in error."
                proportion="enlarged"
                handleReset={() => resetRoute()}
            />
        );
    }

    if (!hasPermission) {
        return (
            <ErrorDisplay
                errorType={ERROR_TYPES.somethingsWrong}
                title="You lack permissions to see this page"
                desc="Contact the page admin if you believe this is in error."
                proportion="enlarged"
                handleReset={() => resetRoute()}
            />
        );
    }

    if (
        !isAdmin &&
        route.requireLicence &&
        currentEntity?.entityType === BaseEntityType.teams &&
        teamLicences?.data &&
        teamLicences.data.length === 0
    ) {
        return (
            <ErrorDisplay
                errorType={ERROR_TYPES.unplugged}
                title="This team does not have a licence."
                desc="Add a Play or Connect licence to continue to use Hub."
                proportion="enlarged"
                hasReturn={false}
                hasReporting={false}
                actions={[
                    {
                        label: 'View Plans',
                        onClick: () =>
                            navigate(
                                `/plans?entityType=teams&entityID=${currentEntity.entityID}&returnUrl=${window.location.href}`
                            ),
                    },
                ]}
            />
        );
    }

    return children;
};

export default ProtectedRoute;
