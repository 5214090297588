import PageHeader from '../../layout/PageHeader';
import PageContainer from '../../layout/PageContainer';
import EntityName from '../../components/EntityName';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Licence } from '../../types/licences';

interface Props {
    licences: Licence[];
    onManageBilling: () => void;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const AssociationLicenceSingleView = (props: Props) => {
    const { licences, onManageBilling, permissions } = props;

    return (
        <PageContainer>
            <PageHeader
                title="Licences"
                actions={
                    permissions.canEdit
                        ? [
                              {
                                  label: 'Manage Subscription',
                                  onClick: () => onManageBilling(),
                              },
                          ]
                        : []
                }
            />
            <DataTable
                value={licences}
                emptyMessage="No assigned licences"
                columnResizeMode="expand"
                resizableColumns
            >
                <Column
                    field="entityID"
                    header="Team"
                    body={(data) => (
                        <EntityName
                            entityType={data.entityType}
                            entityID={data.entityID}
                        />
                    )}
                />
            </DataTable>
        </PageContainer>
    );
};

export default AssociationLicenceSingleView;
