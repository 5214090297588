import { Response } from './api';
import { BaseEntityType } from './common';

export interface MessageChannel {
    cid: string;
    disabled: boolean;
    lastRead: string;
    name: string;
    unreadMessages: number;
}

export enum ChannelsEnabledOptions {
    Groups = 'groups',
    Players = 'players',
    Guardians = 'guardians',
    Staff = 'staff',
    Everybody = 'everybody',
}
export interface MessageSettings {
    enabled: boolean;
    createdAt: string;
    createdBy: string;
    entityID: string;
    entityType: BaseEntityType;
    lastEdited: string;
    lastEditedBy: string;
    moderators: string[];
    channelsEnabled: {
        [key: string]: boolean;
    }[];
}

export interface MessageSettingsFormData {
    enabled?: boolean;
    moderators?: string[];
    channelsEnabled?: {
        [key: string]: boolean;
    };
}

export type MessageChannelsResponse = Response<MessageChannel[]>;
export type MessageSettingsResponse = Response<MessageSettings>;
