import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import hubLogo from '../assets/images/logos/rm-hub-logo.svg';
import ListItem from '../components/ListItem';
import { Image } from 'primereact/image';
import { Tag } from 'primereact/tag';
import { CUSTOM_TOKEN_ID, USE_CUSTOM_TOKEN } from '../util/constants';
import { isValidToken, parseJwt } from '../util/helper';
import { useMemo } from 'react';
import { Avatar } from 'primereact/avatar';
import { useLazyGetMeQuery } from '../api/core';
import Loader from '../components/Loader';

const AccountSelector = () => {
    const { user: authUser, logout } = useAuth0();
    const navigate = useNavigate();

    const [getMe, me] = useLazyGetMeQuery();

    // Get custom token and user account selection from localstorage
    const customToken = localStorage.getItem(CUSTOM_TOKEN_ID);

    const user = useMemo(
        () => customToken && parseJwt(customToken),
        [customToken]
    );
    const expired = useMemo(() => !isValidToken(user), [user]);

    const handleLogout = () => {
        localStorage.clear();
        logout();
    };

    return (
        <div className="account-selector">
            <div className="account-selector-container">
                <div style={{ textAlign: 'center', padding: '30px 0' }}>
                    <Image src={hubLogo} height="60px" alt="Rookie Me Hub" />
                    <p>Choose your account</p>
                </div>
                <div className="p-card">
                    {user && (
                        <ListItem
                            start={
                                <Avatar
                                    image={user.picture}
                                    label={user.name}
                                />
                            }
                            caption={user.email}
                            title={user.name}
                            onClick={
                                expired
                                    ? handleLogout
                                    : () => {
                                          localStorage.setItem(
                                              USE_CUSTOM_TOKEN,
                                              'true'
                                          );

                                          getMe().then(() => {
                                              navigate('/');
                                          });
                                      }
                            }
                            end={
                                expired ? (
                                    <Tag severity="danger">Session Expired</Tag>
                                ) : undefined
                            }
                        />
                    )}
                    {authUser && (
                        <ListItem
                            start={
                                <Avatar
                                    image={authUser.picture}
                                    label={authUser.name}
                                />
                            }
                            caption={authUser.email}
                            title={authUser.name || ''}
                            onClick={() => {
                                localStorage.setItem(USE_CUSTOM_TOKEN, 'false');

                                getMe().then(() => {
                                    navigate('/');
                                });
                            }}
                        />
                    )}
                    <ListItem
                        className="list-item-centered"
                        onClick={handleLogout}
                        title="Login as someone else"
                    />
                </div>
            </div>
            {me.isLoading && <Loader overlay />}
        </div>
    );
};

export default AccountSelector;
