import { User } from '@auth0/auth0-react';
import coachLogo from '../assets/images/logos/rm-coach-logo.svg';
import coachLogomark from '../assets/images/logos/rm-coach-logomark.svg';
import connectLogo from '../assets/images/logos/rm-connect-logo.svg';
import connectLogomark from '../assets/images/logos/rm-connect-logomark.svg';
import playLogo from '../assets/images/logos/rm-play-logo.svg';
import playLogomark from '../assets/images/logos/rm-play-logomark.svg';
import hubLogo from '../assets/images/logos/rm-hub-logo.svg';
import hubLogomark from '../assets/images/logos/rm-hub-logomark.svg';

import {
    TeamGrade,
    TeamSport,
    TeamCategory,
    TeamGender,
    TeamAge,
} from '../types/team';

import { ProductDetails } from '../types/common';

export const teamGradeOptions = Object.keys(TeamGrade).map((key, i) => ({
    label: Object.values(TeamGrade)[i],
    value: key,
}));

export const teamSportOptions = Object.keys(TeamSport).map((key, i) => ({
    label: Object.values(TeamSport)[i],
    value: key,
}));

export const teamTypeOptions = Object.keys(TeamCategory).map((key, i) => ({
    label: Object.values(TeamCategory)[i],
    value: key,
}));

export const teamGenderOptions = Object.keys(TeamGender).map((key, i) => ({
    label: Object.values(TeamGender)[i],
    value: key,
}));

export const teamAgeOptions = Object.values(TeamAge).map((val, i) => ({
    label: val,
    value: val,
}));

export const ID_TOKEN_PARAM = 'id_token';
export const CUSTOM_TOKEN_ID = 'custom_token_id';
export const USE_CUSTOM_TOKEN = 'use_custom_token';

export const AUTH_UUID_KEY = 'https://passport.rookieme.com/uuid';

export const STATIC_ENDPOINT_SPORTS = `${process.env.REACT_APP_CDN_URL}/static/sports.json`;
export const STATIC_ENDPOINT_LICENCE_TYPES = `${process.env.REACT_APP_CDN_URL}/static/licenceTypes_all.json`;

export const loginSourceMap = {
    unknown: 'Unknown',
    'google-oauth2': 'Google',
    auth0: 'Auth0',
    facebook: 'Facebook',
    twitter: 'Twitter',
    'oauth2|CoachAFL': 'Coach AFL',
} as keyof User['sub'];

export const entityNameMap = {
    users: 'user',
    teams: 'team',
    organisations: 'organisation',
    associations: 'association',
};

export const productDetails: ProductDetails = {
    game_day: {
        logo: playLogo,
        logomark: playLogomark,
        title: 'Play',
        description: '',
        platform: 'IOS',
        salesUrl: 'https://www.rookieme.com/play',
        helpUrl: 'https://help.play.rookieme.com',
    },
    connect: {
        logo: connectLogo,
        logomark: connectLogomark,
        title: 'Connect',
        description: '',
        platform: 'IOS and Android',
        salesUrl: 'https://www.rookieme.com/connect',
        helpUrl: 'https://help.connect.rookieme.com',
    },
    coach: {
        logo: coachLogo,
        logomark: coachLogomark,
        title: 'Coach',
        description: '',
        platform: 'Web',
        salesUrl: 'https://www.rookieme.com/coach',
        helpUrl: 'https://help.coach.rookieme.com',
    },
    hub: {
        logo: hubLogo,
        logomark: hubLogomark,
        title: 'Hub',
        description: '',
        platform: 'Web',
        salesUrl: 'https://www.rookieme.com/hub',
        helpUrl: 'https://help.hub.rookieme.com',
    },
};
