import { createApi } from '@reduxjs/toolkit/query/react';
import { generatePath } from 'react-router-dom';

import { apiEndpoints } from './apiEndpoints';
import { baseQueryWithReauth } from '../util/baseQuery';

import {
    StatConfig,
    StatConfigResponse,
    StatView,
    StatViewsResponse,
} from '../types/sports';

export const statsApi = createApi({
    reducerPath: 'statsApi',
    baseQuery: (args, api, extraOptions) =>
        baseQueryWithReauth(
            args,
            api,
            extraOptions,
            process.env.REACT_APP_API_URL
        ),
    endpoints: (builder) => ({
        getStats: builder.query<StatConfigResponse, { sportID: string }>({
            query: ({ sportID }) => ({
                url: generatePath(apiEndpoints.getStats.url, { sportID }),
                method: apiEndpoints.getStats.method,
            }),
        }),
        getStat: builder.query<StatConfig, { sportID: string; statID: string }>(
            {
                query: ({ sportID, statID }) => ({
                    url: generatePath(apiEndpoints.getStat.url, {
                        sportID,
                        statID,
                    }),
                    method: apiEndpoints.getStat.method,
                }),
            }
        ),
        getStatViews: builder.query<StatViewsResponse, { sportID: string }>({
            query: ({ sportID }) => ({
                url: generatePath(apiEndpoints.getStatViews.url, {
                    sportID,
                }),
                method: apiEndpoints.getStatViews.method,
            }),
        }),
        getStatView: builder.query<
            StatView,
            { sportID: string; statID: string }
        >({
            query: ({ sportID, statID }) => ({
                url: generatePath(apiEndpoints.getStatView.url, {
                    sportID,
                    statID,
                }),
                method: apiEndpoints.getStatView.method,
            }),
        }),
    }),
});

export const {
    useGetStatsQuery,
    useGetStatQuery,
    useGetStatViewsQuery,
    useGetStatViewQuery,
} = statsApi;
