import {
    ChangeEvent,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Formik, FormikErrors, FormikValues } from 'formik';

import {
    postsApi,
    useCreatePostMutation,
    useCreateSharePostMutation,
    useUpdatePostMutation,
} from '../../api/posts';

import { InputTextarea } from 'primereact/inputtextarea';

import FormActions from '../../components/FormActions';
import RookieButton from '../../components/RookieButton';

import { Post, PostFormData } from '../../types/posts';
import { BaseEntityType } from '../../types/common';
import useFileUpload from '../../hooks/useFileUpload';
import { useDispatch } from 'react-redux';
import { ToastContext } from '../../contexts/ToastContext';
import Loader from '../../components/Loader';
import { Dropdown } from 'primereact/dropdown';
import { useGetEntityQuery } from '../../api/core';
import { useLazyGetOrganisationTeamsQuery } from '../../api/organisations';
import { useLazyGetAssociationOrganisationsQuery } from '../../api/associations';
import { MultiSelect } from 'primereact/multiselect';
import FormGroup from '../../components/FormGroup';

interface Props {
    entityType: BaseEntityType;
    entityID: string;
    post?: Post;
    onError?: (error?: any) => void;
    onCreate?: (response: Post) => void;
    onUpdate?: (response: Post) => void;
}

interface ImageFile {
    file: File;
    preview: string;
}

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const MAX_IMAGES = 5;

const PostForm = ({
    entityType,
    entityID,
    post,
    onError,
    onCreate,
    onUpdate,
}: Props) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const [images, setImages] = useState<ImageFile[]>([]);
    const [loading, setLoading] = useState(false);
    const [sharedEntities, setSharedEntities] = useState([]);

    const dispatch = useDispatch();
    const toast = useContext(ToastContext);

    const isUpdating = !!post?.postID;

    const { data: entityData } = useGetEntityQuery(
        {
            entityID,
            entityType,
        },
        { skip: !entityID || !entityType }
    );

    const [createPost] = useCreatePostMutation();
    const [updatePost] = useUpdatePostMutation();

    const [fetchOrganisations, { data: organisationsData }] =
        useLazyGetAssociationOrganisationsQuery();

    const [fetchTeams, { data: teamsData }] =
        useLazyGetOrganisationTeamsQuery();

    const [createSharePost] = useCreateSharePostMutation();

    const entityOptions = useMemo(() => {
        if (entityType === BaseEntityType.associations) {
            return organisationsData?.data?.map((ent) => ({
                id: ent.organisationID,
                name: ent.organisationName,
                entityType: BaseEntityType.organisations,
            }));
        } else if (entityType === BaseEntityType.organisations) {
            return teamsData?.data?.map((ent) => ({
                id: ent.teamID,
                name: ent.teamName,
                entityType: BaseEntityType.teams,
            }));
        }
    }, [entityType, teamsData, organisationsData]);

    useEffect(() => {
        const fetchEntities = async () => {
            if (entityType === BaseEntityType.associations) {
                await fetchOrganisations({
                    associationID: entityID,
                });
            } else if (entityType === BaseEntityType.organisations) {
                await fetchTeams({
                    organisationID: entityID,
                });
            }
        };
        fetchEntities();
    }, [entityID, entityType, fetchOrganisations, fetchTeams]);

    const entityName = useMemo(() => {
        if (entityData?.data) {
            switch (entityType) {
                case BaseEntityType.teams:
                    return entityData.data.teamName;
                case BaseEntityType.organisations:
                    return entityData.data.organisationName;
                case BaseEntityType.associations:
                    return entityData.data.associationName;
            }
        }
    }, [entityData, entityType]);

    const { uploadToPresignedUrl } = useFileUpload({
        entityType,
        entityID,
    });

    const handleUpdatePost = (data: PostFormData) => {
        setLoading(true);

        if (entityType && entityID) {
            updatePost({
                postID: post?.postID,
                entityType,
                entityID,
                ...data,
            })
                .then((response) => {
                    if ('error' in response) {
                        if (onError) {
                            onError(response.error);
                        }
                    } else {
                        if (onUpdate) {
                            onUpdate(response.data.data);
                        }
                    }
                })
                .catch((err) => {
                    if (onError) {
                        onError(err);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);

            if (onError) {
                onError('No entityType or entityID provided');
            }
        }
    };

    const handleCreatePost = async ({ ownerType, ...data }: PostFormData) => {
        const formData: PostFormData = data;

        if (ownerType === entityType) {
            formData.ownerID = entityID;
            formData.ownerType = entityType;
        }

        if (entityType && entityID) {
            setLoading(true);

            try {
                // First, create the post
                const response = await createPost({
                    entityType,
                    entityID,
                    requestAttachmentPresignedPutURLs: images.length,
                    ...formData,
                });

                if ('error' in response) {
                    if (onError) {
                        onError(response.error);
                    }
                    return;
                }

                const responseData = response.data.data;

                // If there are presigned URLs for attachments, upload them
                if (responseData.putPresignedUrls) {
                    const uploadPromises = responseData.putPresignedUrls.map(
                        (url, index) => {
                            if (images[index]) {
                                return uploadToPresignedUrl(
                                    url,
                                    images[index].file
                                );
                            }
                            return Promise.resolve();
                        }
                    );

                    // Wait for all uploads to complete
                    await Promise.all(uploadPromises);

                    // Share posts if required
                    if (sharedEntities) {
                        await sharePost(responseData, sharedEntities);
                    }

                    // Delay invalidation by 5 second to give the server time to update
                    setTimeout(() => {
                        dispatch(postsApi.util.invalidateTags(['Post']));

                        // Run the onCreate callback after invalidation
                        if (onCreate) {
                            onCreate(responseData);
                        }
                        setLoading(false);
                    }, 5000);
                } else {
                    // Share posts if required
                    if (sharedEntities) {
                        await sharePost(responseData, sharedEntities);
                    }
                    // Run the onCreate callback after invalidation
                    if (onCreate) {
                        onCreate(responseData);
                    }
                    setLoading(false);
                }
            } catch (err) {
                if (onError) {
                    onError(err);
                }

                setLoading(false);
            }
        } else {
            if (onError) {
                onError('No entityType or entityID provided');
            }
        }
    };

    const sharePost = async (post: Post, entities: any[] = []) => {
        if (entities.length > 0) {
            const sharePromises = entities.map(async (ent, index) => {
                await delay(index * 2000);

                createSharePost({
                    entityID: ent.id,
                    entityType: ent.entityType,
                    postID: post.postID,
                    content: '',
                    visibility: 'Visible',
                });

                return Promise.resolve();
            });

            await Promise.all(sharePromises);
        }
    };

    const handleImageUploadClick = () => {
        fileInputRef.current?.click();
    };

    const handleImageUploadChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || []);
        const validFiles: ImageFile[] = [];
        const invalidFiles: string[] = [];

        files.forEach((file) => {
            if (
                (file.type === 'image/jpeg' || file.type === 'image/png') &&
                file.size <= 1024 * 1024
            ) {
                validFiles.push({
                    file: file,
                    preview: URL.createObjectURL(file),
                });
            } else {
                invalidFiles.push(file.name);
            }
        });

        if (invalidFiles.length > 0) {
            if (toast?.current) {
                toast.current.show({
                    summary: 'Invalid Files',
                    detail: `The following files are invalid: ${invalidFiles.join(
                        ', '
                    )}`,
                });
            }
        }

        if (validFiles.length + images.length > MAX_IMAGES) {
            if (toast?.current) {
                toast.current.show({
                    detail: 'Please choose up to 5 photos.',
                });
            }
        } else {
            setImages((prevImages) =>
                [...prevImages, ...validFiles].slice(0, MAX_IMAGES)
            );
        }
    };

    const handleImageRemove = (preview: string) => {
        setImages((prevImages) =>
            prevImages.filter((image) => image.preview !== preview)
        );
    };

    const validate = (values: FormikValues) => {
        const errors: FormikErrors<PostFormData> = {};

        if (
            (!values.content || values.content.trim() === '') &&
            images.length <= 0
        ) {
            errors.content = 'Field cannot be blank';
        }

        return errors;
    };

    return (
        <div className="post-composer">
            <Formik
                initialValues={{
                    ownerType: post?.ownerType || BaseEntityType.users,
                    content: post?.content || '',
                }}
                onSubmit={isUpdating ? handleUpdatePost : handleCreatePost}
                validate={validate}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
            >
                {({ values, handleChange, handleBlur, handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <InputTextarea
                                id="content"
                                name="content"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter your message"
                                value={values.content}
                                autoResize
                                autoFocus
                                className={
                                    values.content.length < 80 ? 'is-large' : ''
                                }
                            />
                            <div className="media-preview">
                                {images.map((image, index) => (
                                    <div
                                        key={`image-upload-${index}`}
                                        className="media-preview-item"
                                    >
                                        <img
                                            src={image.preview}
                                            alt={image.file.name}
                                            title={`${image.file.name} - ${(
                                                image.file.size / 1024
                                            ).toFixed(2)} KB`}
                                        />
                                        <RookieButton
                                            className="media-preview-remove"
                                            onClick={() =>
                                                handleImageRemove(image.preview)
                                            }
                                            icon="close"
                                            size="small"
                                            text
                                        />
                                    </div>
                                ))}
                            </div>
                            <FormActions
                                start={
                                    !isUpdating && (
                                        <>
                                            <input
                                                ref={fileInputRef}
                                                type="file"
                                                multiple
                                                accept="image/*"
                                                onChange={
                                                    handleImageUploadChange
                                                }
                                                style={{ display: 'none' }}
                                            />

                                            <RookieButton
                                                type="button"
                                                icon="image"
                                                outlined
                                                onClick={handleImageUploadClick}
                                                disabled={
                                                    loading ||
                                                    images.length >= MAX_IMAGES
                                                }
                                                plain
                                                severity="secondary"
                                            />
                                            <Dropdown
                                                name="ownerType"
                                                value={values.ownerType}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                placeholder="Post as"
                                                options={[
                                                    {
                                                        value: BaseEntityType.users,
                                                        label: 'Post as You',
                                                    },
                                                    {
                                                        label: entityName
                                                            ? `Post as ${entityName}`
                                                            : 'Post as entity',
                                                        value: entityType,
                                                    },
                                                ]}
                                            />
                                        </>
                                    )
                                }
                                end={
                                    <RookieButton
                                        type="submit"
                                        label={isUpdating ? 'Save' : 'Post'}
                                        icon="send"
                                        iconPos="right"
                                        disabled={
                                            loading ||
                                            (values.content === '' &&
                                                images.length <= 0)
                                        }
                                    />
                                }
                            />
                        </form>
                    );
                }}
            </Formik>

            {entityType !== BaseEntityType.teams && (
                <FormGroup className="post-share" label="Share To">
                    <MultiSelect
                        value={sharedEntities}
                        onChange={(e) => setSharedEntities(e.value)}
                        options={entityOptions}
                        optionLabel="name"
                        display="chip"
                        placeholder={`Select ${
                            entityType === BaseEntityType.associations
                                ? 'Organisations'
                                : 'Teams'
                        }`}
                        maxSelectedLabels={3}
                    />
                </FormGroup>
            )}

            {loading && <Loader size="large" />}
        </div>
    );
};

export default PostForm;
