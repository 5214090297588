import { generatePath } from 'react-router-dom';
import { createApi } from '@reduxjs/toolkit/query/react';
import { isEqual } from 'lodash';

import { apiEndpoints } from './apiEndpoints';
import { baseQueryWithReauth } from '../util/baseQuery';

import {
    LicenceGroup,
    LicenceGroupResponse,
    LicenceGroupsResponse,
    LicencesResponse,
    LicenceTypeFeaturesResponse,
    LicenceTypesResponse,
    RookieProduct,
} from '../types/licences';
import { BaseEntityType, EntityStructure } from '../types/common';
import {
    BillingSession,
    CheckoutSession,
    Subscription,
} from '../types/subscriptions';

export const licencesApi = createApi({
    reducerPath: 'licencesApi',
    baseQuery: (args, api, extraOptions) =>
        baseQueryWithReauth(
            args,
            api,
            extraOptions,
            process.env.REACT_APP_API_URL
        ),
    tagTypes: [
        'Invoice',
        'Licence',
        'LicenceGroup',
        'LicenceType',
        'Subscription',
        'TransferredLicenceGroup',
    ],

    endpoints: (builder) => ({
        getSubscriptions: builder.query({
            query: ({ userID, cursor }) => ({
                url: generatePath(apiEndpoints.getSubscriptions.url, {
                    userID,
                }),
                method: apiEndpoints.getSubscriptions.method,
                params: { expand: 'paymentDetails', cursor },
            }),
            //providesTags: ['Subscription'],
            providesTags: (result) => {
                return result?.data?.data
                    ? [
                          ...result.data.data.map(({ id }: Subscription) => ({
                              type: 'Subscription' as const,
                              id,
                          })),
                          'Subscription',
                      ]
                    : ['Subscription'];
            },
            serializeQueryArgs: ({
                endpointName,
                queryArgs: { cursor, ...args },
            }) => {
                return `${endpointName}(${JSON.stringify(args)})`;
            },
            merge: (currentCache, newItems, args) => {
                if (currentCache && newItems) {
                    if (args.arg.cursor) {
                        currentCache.data.has_more = newItems.data.has_more;
                        currentCache.data.data = [
                            ...currentCache.data.data,
                            ...newItems.data.data,
                        ];
                    } else {
                        currentCache.data.data = newItems.data.data;
                    }
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
        }),
        getSubscription: builder.query({
            query: ({ userID, subscriptionID }) => ({
                url: generatePath(apiEndpoints.getSubscription.url, {
                    userID,
                    subscriptionID,
                }),
                method: apiEndpoints.getSubscription.method,
                params: { expand: 'paymentDetails' },
            }),
            providesTags: ['Subscription'],
        }),
        getSubscriptionInvoices: builder.query({
            query: ({ userID, subscriptionID }) => ({
                url: generatePath(apiEndpoints.getSubscriptionInvoices.url, {
                    userID,
                    subscriptionID,
                }),
                method: apiEndpoints.getSubscriptionInvoices.method,
                params: { expand: 'paymentDetails' },
            }),
            providesTags: ['Invoice'],
        }),
        getInvoice: builder.query({
            query: ({ userID, invoiceID }) => ({
                url: generatePath(apiEndpoints.getInvoice.url, {
                    userID,
                    invoiceID,
                }),
                method: apiEndpoints.getInvoice.method,
                params: { expand: 'paymentDetails' },
            }),
            providesTags: ['Invoice'],
        }),
        getLicenceTypes: builder.query<
            LicenceTypesResponse,
            {
                sportID: string;
                rookieProduct?: RookieProduct;
                hideIncReports?: boolean;
                expand?: string;
            }
        >({
            query: (params) => ({
                url: apiEndpoints.getLicenceTypes.url,
                method: apiEndpoints.getLicenceTypes.method,
                params,
            }),
            providesTags: ['LicenceType', 'LicenceGroup'],
        }),
        getLicenceTypeFeatures: builder.query<
            LicenceTypeFeaturesResponse,
            { licenceTypeID: string }
        >({
            query: ({ licenceTypeID }) => ({
                url: generatePath(apiEndpoints.getLicenceTypeFeatures.url, {
                    licenceTypeID,
                }),
                method: apiEndpoints.getLicenceTypeFeatures.method,
            }),
        }),
        getLicenceGroups: builder.query<LicenceGroupsResponse, EntityStructure>(
            {
                query: ({ entityType, entityID }) => ({
                    url: generatePath(apiEndpoints.getLicenceGroups.url, {
                        entityType,
                        entityID,
                    }),
                    method: apiEndpoints.getLicenceGroups.method,
                    params: { expand: 'paymentDetails' },
                }),
                providesTags: ['LicenceGroup'],
            }
        ),
        getLicenceGroup: builder.query<
            LicenceGroupResponse,
            EntityStructure & { licenceGroupID: string }
        >({
            query: ({ entityType, entityID, licenceGroupID }) => ({
                url: generatePath(apiEndpoints.getLicenceGroup.url, {
                    entityType,
                    entityID,
                    licenceGroupID,
                }),
                method: apiEndpoints.getLicenceGroup.method,
                params: { expand: 'paymentDetails' },
            }),
            providesTags: ['LicenceGroup'],
        }),
        getLicenceGroupLicences: builder.query<
            LicencesResponse,
            { licenceGroupID: string } & EntityStructure
        >({
            query: ({ entityType, entityID, licenceGroupID }) => ({
                url: generatePath(apiEndpoints.getLicenceGroupLicences.url, {
                    entityType,
                    entityID,
                    licenceGroupID,
                }),
                method: apiEndpoints.getLicenceGroupLicences.method,
                params: { expand: 'paymentDetails' },
            }),
            providesTags: ['Licence', 'LicenceType', 'LicenceGroup'],
        }),
        getTransferredLicenceGroups: builder.query<
            LicenceGroupsResponse,
            EntityStructure
        >({
            query: ({ entityType, entityID }) => ({
                url: generatePath(
                    apiEndpoints.getTransferredLicenceGroups.url,
                    { entityType, entityID }
                ),
                method: apiEndpoints.getTransferredLicenceGroups.method,
                params: {},
            }),
            providesTags: ['LicenceType', 'LicenceGroup'],
        }),
        getLicences: builder.query<
            LicencesResponse,
            {
                rookieProduct?: RookieProduct;
            } & EntityStructure
        >({
            query: ({ entityType, entityID, rookieProduct }) => ({
                url: generatePath(apiEndpoints.getLicences.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.getLicences.method,
                params: { rookieProduct, expand: 'productDetails' },
            }),
            providesTags: ['Licence', 'LicenceType', 'LicenceGroup'],
        }),

        createBillingSession: builder.mutation<
            BillingSession,
            { userID: string; returnURL?: string }
        >({
            query: ({ userID, returnURL }) => ({
                url: generatePath(apiEndpoints.createBillingSession.url, {
                    userID,
                }),
                method: apiEndpoints.createBillingSession.method,
                body: { returnURL },
            }),
        }),
        cancelSubscription: builder.mutation<
            any,
            { userID: string; subscriptionID: string }
        >({
            query: ({ userID, subscriptionID }) => ({
                url: generatePath(apiEndpoints.cancelSubscription.url, {
                    userID,
                    subscriptionID,
                }),
                method: apiEndpoints.cancelSubscription.method,
                body: {
                    forceNow: true,
                },
            }),
            invalidatesTags: ['Subscription'],
        }),
        updateSubscription: builder.mutation<
            any,
            { userID: string; subscriptionID: string; paymentMethodID: string }
        >({
            query: ({ userID, subscriptionID, paymentMethodID }) => ({
                url: generatePath(apiEndpoints.updateSubscription.url, {
                    userID,
                    subscriptionID,
                }),
                method: apiEndpoints.updateSubscription.method,
                body: { paymentMethodID },
            }),
            invalidatesTags: ['Subscription'],
        }),

        createCheckoutSession: builder.mutation<
            CheckoutSession,
            {
                entityType: BaseEntityType;
                entityID: string;
                successURL: string;
                cancelURL: string;
                items: { quantity: number; price: string }[];
                promoCode?: string;
            }
        >({
            query: ({ entityType, entityID, ...data }) => ({
                url: generatePath(apiEndpoints.createCheckoutSession.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.createCheckoutSession.method,
                body: data,
            }),
            transformResponse: (response: any, meta) => ({
                ...response,
                status: meta?.response?.status, // Extract status from meta
            }),
        }),

        updateLicenceGroup: builder.mutation({
            query: ({
                entityType,
                entityID,
                licenceGroupID,
                quantity,
                priceID,
            }) => ({
                url: generatePath(apiEndpoints.updateLicenceGroup.url, {
                    entityType,
                    entityID,
                    licenceGroupID,
                }),
                method: apiEndpoints.updateLicenceGroup.method,
                body: { quantity, priceID },
            }),
            invalidatesTags: [
                'Subscription',
                'LicenceGroup',
                'LicenceType',
                'Licence',
            ],
        }),
        assignLicence: builder.mutation<
            { teamsIDList: string[] },
            {
                entityType: BaseEntityType;
                entityID: string;
                licenceGroupID: string;
                teamsIDList: string[];
            }
        >({
            query: ({ entityType, entityID, licenceGroupID, teamsIDList }) => ({
                url: generatePath(apiEndpoints.assignLicence.url, {
                    entityType,
                    entityID,
                    licenceGroupID,
                }),
                method: apiEndpoints.assignLicence.method,
                body: { teamsIDList },
            }),
            invalidatesTags: ['LicenceGroup', 'Licence'],
        }),
        unAssignLicence: builder.mutation<
            any,
            { entityType: BaseEntityType; entityID: string; licenceID: string }
        >({
            query: ({ entityType, entityID, licenceID }) => ({
                url: generatePath(apiEndpoints.unAssignLicence.url, {
                    entityType,
                    entityID,
                    licenceID,
                }),
                method: apiEndpoints.unAssignLicence.method,
            }),
            invalidatesTags: ['LicenceGroup', 'LicenceType', 'Licence'],
        }),

        transferLicenceGroup: builder.mutation<
            LicenceGroup,
            {
                entityType: BaseEntityType;
                entityID: string;
                licenceGroupID: string;
                targetEntityType: BaseEntityType;
                targetEntityID: string;
            }
        >({
            query: ({
                entityType,
                entityID,
                licenceGroupID,
                targetEntityType,
                targetEntityID,
            }) => ({
                url: generatePath(apiEndpoints.updateLicenceGroup.url, {
                    entityType,
                    entityID,
                    licenceGroupID,
                }),
                method: apiEndpoints.updateLicenceGroup.method,
                body: {
                    entityType: targetEntityType,
                    entityID: targetEntityID,
                },
            }),
            invalidatesTags: ['LicenceGroup', 'LicenceType'],
        }),
    }),
});

export const {
    useGetSubscriptionsQuery,
    useLazyGetSubscriptionsQuery,
    useGetSubscriptionQuery,
    useGetSubscriptionInvoicesQuery,
    useGetInvoiceQuery,
    useGetLicenceTypesQuery,
    useGetLicenceTypeFeaturesQuery,
    useGetLicenceGroupsQuery,
    useGetLicenceGroupQuery,
    useGetLicenceGroupLicencesQuery,
    useGetTransferredLicenceGroupsQuery,
    useGetLicencesQuery,
    useCreateBillingSessionMutation,
    useCancelSubscriptionMutation,
    useUpdateSubscriptionMutation,
    useCreateCheckoutSessionMutation,
    useUpdateLicenceGroupMutation,
    useAssignLicenceMutation,
    useUnAssignLicenceMutation,
    useTransferLicenceGroupMutation,
} = licencesApi;
