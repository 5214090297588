import { useMemo, useState } from 'react';
import { sortBy } from 'lodash';

import { useAddTeamToCollectionMutation } from '../../api/collections';
import { useGetOrganisationTeamsQuery } from '../../api/organisations';

import Loader from '../../components/Loader';
import RookieButton from '../../components/RookieButton';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';

import { BaseEntityType } from '../../types/common';
import { OrganisationStatus } from '../../types/organisation';

interface Props {
    entityType: BaseEntityType;
    entityID: string;
    collectionID: string;
    disabledIDs?: string[];
    organisationFilter: OrganisationStatus;
    onError?: (error?: any) => void;
    onComplete?: (response: any) => void;
}

type SelectedTeam = {
    teamID: string;
    seasonID: string;
};

const AddToOrganisationCollectionForm = ({
    entityType,
    entityID,
    collectionID,
    disabledIDs,
    organisationFilter,
    onError,
    onComplete,
}: Props) => {
    const [loading, setLoading] = useState(false);

    const [selected, setSelected] = useState<SelectedTeam[]>([]);

    const { data: teamsData, isLoading } = useGetOrganisationTeamsQuery({
        organisationID: entityID,
        entityStatus: organisationFilter,
        limit: 50,
    });

    const [addToCollection] = useAddTeamToCollectionMutation();

    const handleSelectionChange = (e: CheckboxChangeEvent) => {
        const teamID = e.value;
        const isChecked = e.checked;

        // Find the full team object so we can get the seasonID
        const team = sortedTeams.find((t) => t.teamID === teamID);
        if (!team) return;

        const seasonID = team.defaultSeasonID;

        let updated = [...selected];

        if (isChecked) {
            updated.push({ teamID, seasonID });
        } else {
            updated = updated.filter((t) => t.teamID !== teamID);
        }

        setSelected(updated);
    };

    const handleSubmit = () => {
        setLoading(true);

        if (selected && entityType && entityID) {
            addToCollection({
                entityType,
                entityID,
                collectionID,
                teams: selected,
            })
                .then((response) => {
                    if ('error' in response) {
                        if (onError) {
                            onError(response.error);
                        }
                    } else {
                        if (onComplete) {
                            onComplete(response.data.data);
                        }
                    }
                })
                .catch((err) => {
                    if (onError) {
                        onError(err);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);

            if (onError) {
                onError('No entityType or entityID provided');
            }
        }
    };

    const sortedTeams = useMemo(
        () => sortBy(teamsData?.data, 'teamName'),
        [teamsData]
    );

    if (isLoading) {
        return <Loader />;
    }
    console.log(sortedTeams);

    return (
        <div className="addto-collections-form">
            <div className="addto-collections-inputs">
                {sortedTeams.map((team) => {
                    return (
                        <div key={team.teamID}>
                            <Checkbox
                                inputId={team.teamID}
                                name="team"
                                value={team.teamID}
                                onChange={handleSelectionChange}
                                checked={selected.some(
                                    (s) => s.teamID === team.teamID
                                )}
                                disabled={(disabledIDs || []).includes(
                                    team.teamID
                                )}
                            />
                            <label htmlFor={team.teamID}>{team.teamName}</label>
                        </div>
                    );
                })}
            </div>

            <RookieButton
                label="Add To Collection"
                onClick={handleSubmit}
                disabled={loading}
            />
        </div>
    );
};

export default AddToOrganisationCollectionForm;
