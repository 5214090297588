import { classNames } from 'primereact/utils';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { ButtonProps } from 'primereact/button';
import RookieButton from '../../components/RookieButton';
import ProductTag from '../../components/ProductTag';

interface Props {
    label: string;
    price?: string;
    description?: string;
    action?: ButtonProps;
    selected?: boolean;
    disabled?: boolean;
    onChange?: (event: CheckboxChangeEvent) => void;
}

const AddonOption = ({
    label,
    price,
    description,
    action,
    onChange,
    selected,
    disabled,
}: Props) => {
    const className = classNames('addon-card', {
        [`is-disabled`]: disabled,
        [`is-selected`]: selected,
    });

    return (
        <label className={className}>
            <Checkbox
                name="addon"
                checked={!!selected}
                onChange={onChange}
                disabled={disabled}
                className="addon-checkbox"
            />
            <span className="addon-details">
                <h3 className="addon-title">{label}</h3>
                {price && <p className="addon-price">{price}</p>}
                {description && <p className="addon-desc">{description}</p>}
                {action && <RookieButton {...action} />}
            </span>
            <ProductTag product="hub" />
        </label>
    );
};

export default AddonOption;
