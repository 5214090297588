import { getNiceRole } from '../../util/helper';

import { Campaign } from './CampaignContainer';
import TeamForm from '../teams/TeamForm';

import PageContainer from '../../layout/PageContainer';
import EntityAvatar from '../../components/EntityAvatar';
import Icon from '../../components/Icon';
import List from '../../components/List';
import ListItem from '../../components/ListItem';
import RookieButton from '../../components/RookieButton';
import Loader from '../../components/Loader';
import ListHeader from '../../components/ListHeader';

import { Permissions } from '../../types/permissions';
import { Role } from '../../types/roles';

interface Props {
    teams: Role[];
    showCreate: boolean;
    isLoading: boolean;
    isError: boolean;
    setShowCreate: (show: boolean) => void;
    onTeamSelect: (teamID: string) => void;
    campaign: Campaign;
    userID: string;
}

const CampaignView = ({
    teams,
    showCreate,
    isLoading,
    onTeamSelect,
    setShowCreate,
    campaign,
    userID,
}: Props) => {
    return (
        <PageContainer size="narrow">
            <div style={{ margin: '40px 0' }}>
                <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
                    <h1>{campaign.title}</h1>
                    {campaign.description && <p>{campaign.description}</p>}
                </div>
                {showCreate ? (
                    <TeamForm
                        userID={userID}
                        onCancel={() => setShowCreate(false)}
                        onSubmitComplete={(status, action, response) =>
                            status === 'success' &&
                            action === 'create' &&
                            onTeamSelect(response.data.data.teamID)
                        }
                    />
                ) : (
                    <div className="entity-widget">
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <List>
                                <ListHeader>Select Your Team</ListHeader>
                                {teams.map((team) => {
                                    const disabled = !team.permissions.includes(
                                        Permissions.MANAGE_TEAMS_ALL
                                    );

                                    return (
                                        <ListItem
                                            key={team.entityID}
                                            title={team.entityName}
                                            caption={team.roles.map((role) =>
                                                getNiceRole(role.roleName)
                                            )}
                                            start={
                                                <EntityAvatar
                                                    entityID={team.entityID}
                                                    entityName={team.entityName}
                                                    entityType={team.entityType}
                                                />
                                            }
                                            end={
                                                disabled ? (
                                                    <RookieButton
                                                        icon="warning"
                                                        text
                                                        tooltip="You lack permission."
                                                    />
                                                ) : (
                                                    <Icon name="chevron_right" />
                                                )
                                            }
                                            onClick={() =>
                                                !disabled &&
                                                onTeamSelect(team.entityID)
                                            }
                                            disabled={disabled}
                                        />
                                    );
                                })}
                                <ListItem
                                    title="Create Team"
                                    start={<Icon name="add" size="large" />}
                                    onClick={() => setShowCreate(true)}
                                />
                            </List>
                        )}
                    </div>
                )}
            </div>
        </PageContainer>
    );
};

export default CampaignView;
