import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { orderBy } from 'lodash';
import { useDispatch } from 'react-redux';

import {
    useCreateAssociationMutation,
    useDeleteAssociationMutation,
    useUpdateAssociationMutation,
} from '../../api/associations';
import { useGetEntityCategoriesQuery } from '../../api/sports';
import { coreApi } from '../../api/core';

import { Mixpanel } from '../../util/mixpanel';
import { getDirtyValues } from '../../util/helper';

import { Dropdown } from 'primereact/dropdown';
import { confirmDialog } from 'primereact/confirmdialog';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Skeleton } from 'primereact/skeleton';

import FormActions from '../../components/FormActions';
import FormFields from '../../components/FormFields';
import FormGroup from '../../components/FormGroup';
import RookieButton from '../../components/RookieButton';
import Loader from '../../components/Loader';

import { FormSubmitCallback, INPUT_TYPES } from '../../types/common';
import { AssociationFormData } from '../../types/association';

interface FormProps {
    associationID?: string;
    associationName: string;
    entityCategoryID?: string;
    shortName?: string;
}

interface AssociationFormProps {
    initialValues: FormProps;
    associationID?: AssociationFormData['associationID'];
    onCancel?: () => void;
    onSubmitComplete: FormSubmitCallback;
}

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const AssociationForm = (props: AssociationFormProps) => {
    const {
        associationID: associationIDProp,
        initialValues,
        onCancel,
        onSubmitComplete,
    } = props;
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { associationID: associationIDParam, userID } = useParams();

    const associationID = associationIDProp || associationIDParam;

    const [isDeleting, setIsDeleting] = useState(false);

    const [createAssociation] = useCreateAssociationMutation();
    const [updateAssociation] = useUpdateAssociationMutation();
    const [deleteAssociation] = useDeleteAssociationMutation();

    const { data: categories, isLoading: isLoadingCategories } =
        useGetEntityCategoriesQuery({
            sportID: 'aus_football_au',
        });

    const requiredFields: Array<keyof FormProps> = [
        'associationName',
        'shortName',
        'entityCategoryID',
    ];

    const isCreate = !associationID;

    const handleSubmit = async (
        data: FormProps,
        { setSubmitting }: FormikHelpers<FormProps>
    ) => {
        try {
            setSubmitting(true);

            const formData: any = isCreate
                ? data
                : getDirtyValues(data, initialValues);

            let response;

            if (isCreate) {
                response = await createAssociation({ userID, ...formData });

                Mixpanel.track('Create Association');
            } else {
                response = await updateAssociation({
                    associationID,
                    ...formData,
                });

                Mixpanel.track('Update Association');
            }

            if ('error' in response) {
                throw new Error('An error has occurred.');
            }

            onSubmitComplete &&
                onSubmitComplete(
                    'success',
                    isCreate ? 'create' : 'update',
                    response
                );

            // Wait for 4 seconds to allow server to respond
            await delay(4000);

            // Refresh roles
            dispatch(coreApi.util.invalidateTags(['Role']));
        } catch (error) {
            onSubmitComplete &&
                onSubmitComplete(
                    'success',
                    isCreate ? 'create' : 'update',
                    undefined,
                    error
                );
        } finally {
            setSubmitting(false);
        }
    };

    const handleDelete = async () => {
        try {
            // Set form to submitting state
            setIsDeleting(true);

            // Delete association
            const response = await deleteAssociation({
                associationID: associationID ?? '',
            });

            // Wait for 4 seconds to allow server to respond
            await delay(4000);

            // Refresh roles
            dispatch(coreApi.util.invalidateTags(['Role']));

            // Call success callback if available
            onSubmitComplete && onSubmitComplete('success', 'delete', response);

            navigate(`/`, {
                replace: true,
            });
        } catch (error) {
            // Call error callback if available
            onSubmitComplete &&
                onSubmitComplete('error', 'delete', undefined, error);
        } finally {
            // Ensure submitting state is reset
            setIsDeleting(false);
        }
    };

    const handleDeleteClick = () => {
        confirmDialog({
            draggable: false,
            message: `Are you sure you want to delete this association?`,
            header: 'Delete Association',
            accept: () => handleDelete(),
        });
    };

    const entityCategories = useMemo(() => {
        const options = categories?.data
            ?.filter((cat) => cat.entityType === 'associations')
            .map((cat) => ({
                label: cat.entityCategoryName,
                value: cat.entityCategoryID,
            }));

        return orderBy(options, 'label');
    }, [categories]);

    return (
        <Formik
            initialValues={initialValues}
            validate={(values) => {
                const errors: { [key: string]: string } = {};
                requiredFields.forEach((field) => {
                    if (!values[field]) {
                        errors[field] = 'Required';
                    }
                });
                return errors;
            }}
            onSubmit={handleSubmit}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <>
                    <form onSubmit={handleSubmit}>
                        {!isCreate && (
                            <div className="wrapped-form_header">
                                <h4 className="title">Association Settings</h4>
                            </div>
                        )}
                        <FormFields>
                            <FormGroup
                                label="Association Type"
                                htmlFor="entityCategoryID"
                                error={errors.entityCategoryID}
                                showError={
                                    !!errors.entityCategoryID &&
                                    touched.entityCategoryID
                                }
                            >
                                {isLoadingCategories ? (
                                    <Skeleton height="44px" />
                                ) : (
                                    <Dropdown
                                        id="entityCategoryID"
                                        name="entityCategoryID"
                                        value={values.entityCategoryID}
                                        options={entityCategories}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        required={isCreate}
                                        disabled={!isCreate}
                                    />
                                )}
                            </FormGroup>
                            <FormGroup
                                label="Association ID"
                                htmlFor="associationID"
                                error={errors.associationID}
                                showError={
                                    !!errors.associationID &&
                                    touched.associationID
                                }
                            >
                                <InputText
                                    id="associationID"
                                    name="associationID"
                                    type={INPUT_TYPES.text}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Association ID"
                                    value={values.associationID}
                                    required={isCreate}
                                    disabled={!isCreate}
                                />
                            </FormGroup>
                            <FormGroup
                                label="Association Name"
                                htmlFor="associationName"
                                error={errors.associationName}
                                showError={
                                    !!errors.associationName &&
                                    touched.associationName
                                }
                            >
                                <InputText
                                    id="associationName"
                                    name="associationName"
                                    type={INPUT_TYPES.text}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Association Name"
                                    value={values.associationName}
                                    required={isCreate}
                                />
                            </FormGroup>
                            <FormGroup
                                label="Short Name"
                                htmlFor="associationName"
                                error={errors.associationName}
                                showError={
                                    !!errors.associationName &&
                                    touched.associationName
                                }
                            >
                                <InputText
                                    id="shortName"
                                    name="shortName"
                                    type={INPUT_TYPES.text}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Association Short Name"
                                    value={values.shortName}
                                    required={isCreate}
                                />
                            </FormGroup>
                        </FormFields>

                        <FormActions
                            start={
                                <>
                                    {isCreate && (
                                        <RookieButton
                                            text={true}
                                            severity="secondary"
                                            disabled={isSubmitting}
                                            onClick={() =>
                                                onCancel && onCancel()
                                            }
                                            type="button"
                                            icon="cancel"
                                            label="Cancel"
                                        />
                                    )}
                                    {!isCreate && (
                                        <RookieButton
                                            severity="danger"
                                            disabled={isSubmitting}
                                            onClick={handleDeleteClick}
                                            type="button"
                                            label="Delete"
                                        />
                                    )}
                                </>
                            }
                            end={
                                <RookieButton
                                    className={
                                        isSubmitting ? 'is-submitting' : ''
                                    }
                                    disabled={isSubmitting}
                                    type="submit"
                                    label={
                                        !isCreate && isSubmitting
                                            ? 'Updating'
                                            : !isCreate
                                            ? 'Update Association'
                                            : isSubmitting
                                            ? 'Creating'
                                            : 'Create Association'
                                    }
                                />
                            }
                        />
                        {isSubmitting && (
                            <div className="form-overlay">
                                <ProgressBar mode="indeterminate" />
                            </div>
                        )}
                    </form>
                    {isDeleting && <Loader size="fullscreen" overlay />}
                </>
            )}
        </Formik>
    );
};

AssociationForm.defaultProps = {
    initialValues: {
        associationID: '',
        associationName: '',
        shortName: '',
        entityCategoryID: '',
    },
};

export default AssociationForm;
