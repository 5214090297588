import { createApi } from '@reduxjs/toolkit/query/react';
import { generatePath } from 'react-router-dom';

import { apiEndpoints } from './apiEndpoints';
import { baseQueryWithReauth } from '../util/baseQuery';

import { EntityStructure } from '../types/common';
import {
    MessageChannelsResponse,
    MessageSettingsFormData,
    MessageSettingsResponse,
} from '../types/messages';

export const messagesApi = createApi({
    reducerPath: 'messagesApi',
    baseQuery: (args, api, extraOptions) =>
        baseQueryWithReauth(
            args,
            api,
            extraOptions,
            process.env.REACT_APP_API_URL
        ),
    tagTypes: ['MessageSettings'],
    endpoints: (builder) => ({
        getMessageToken: builder.query<any, { userID: string }>({
            query: ({ userID }) => ({
                url: generatePath(apiEndpoints.getMessageToken.url, {
                    userID,
                }),
                method: apiEndpoints.getMessageToken.method,
            }),
        }),
        getEntityChannels: builder.query<
            MessageChannelsResponse,
            EntityStructure
        >({
            query: ({ entityType, entityID }) => ({
                url: generatePath(apiEndpoints.getEntityChannels.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.getEntityChannels.method,
            }),
        }),
        getMessageSettings: builder.query<
            MessageSettingsResponse,
            EntityStructure
        >({
            query: ({ entityType, entityID }) => ({
                url: generatePath(apiEndpoints.getMessageSettings.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.getMessageSettings.method,
            }),
            providesTags: ['MessageSettings'],
        }),
        createMessageSettings: builder.mutation<
            MessageSettingsResponse,
            EntityStructure & MessageSettingsFormData
        >({
            query: ({ entityID, entityType, ...data }) => ({
                url: generatePath(apiEndpoints.createMessageSettings.url, {
                    entityID,
                    entityType,
                }),
                method: apiEndpoints.createMessageSettings.method,
                body: data,
            }),
            invalidatesTags: ['MessageSettings'],
        }),
        updateMessageSettings: builder.mutation<
            MessageSettingsResponse,
            EntityStructure & MessageSettingsFormData
        >({
            query: ({ entityID, entityType, ...data }) => ({
                url: generatePath(apiEndpoints.updateMessageSettings.url, {
                    entityID,
                    entityType,
                }),
                method: apiEndpoints.updateMessageSettings.method,
                body: data,
            }),
            invalidatesTags: ['MessageSettings'],
        }),
    }),
});

export const {
    useGetMessageTokenQuery,
    useGetEntityChannelsQuery,
    useGetMessageSettingsQuery,
    useCreateMessageSettingsMutation,
    useUpdateMessageSettingsMutation,
} = messagesApi;
