import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetLicenceGroupQuery } from '../../api/licences';

import { getEntityFromParam } from '../../util/helper';
import { productDetails } from '../../util/constants';
import { Skeleton } from 'primereact/skeleton';

import { BaseEntityType } from '../../types/common';

const LicenceBreadcrumb = () => {
    const params = useParams();
    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);

    const { data, isLoading, isError } = useGetLicenceGroupQuery(
        {
            entityType: activeEntity?.entityType as BaseEntityType,
            entityID: activeEntity?.entityID ?? '',
            licenceGroupID: params.licenceGroupID ?? '',
        },
        { skip: !activeEntity }
    );

    if (isLoading) return <Skeleton width="4rem" height="1rem" />;

    if (isError) return 'Licence';

    const product =
        data?.data?.rookieProduct && productDetails[data?.data?.rookieProduct];

    return product?.title;
};
export default LicenceBreadcrumb;
