import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    useLazyGetPlayersQuery,
    useUpdatePlayerMutation,
} from '../../api/players';

import DocumentHead from '../../components/DocumentHead';
import PlayerThresholdsView from './PlayerThresholdsView';
import { ColumnEvent } from 'primereact/column';

import usePermission from '../../hooks/usePermission';

import { Route } from '../../types/route';
import { Roles } from '../../types/roles';
import { Permissions } from '../../types/permissions';
import { ToastContext } from '../../contexts/ToastContext';
import { PlayerThreshold } from '../../types/team';

interface Props {
    roles: Roles;
    route: Route;
}

const PlayerThresholdsContainer = (props: Props) => {
    const { teamID, organisationID } = useParams();
    const toast = useContext(ToastContext);

    const { checkPermission } = usePermission(organisationID || teamID || '');

    const [isSaving, setIsSaving] = useState(false);

    const [fetchPlayers, { data, isLoading, isError, isFetching }] =
        useLazyGetPlayersQuery();

    const [updatePlayer] = useUpdatePlayerMutation();

    const savePlayerThresholds = (
        playerID: string,
        thresholds: PlayerThreshold[]
    ) => {
        setIsSaving(true);

        updatePlayer({
            playerID,
            teamID,
            thresholds,
        })
            .then((response) => {
                toast?.current?.show({
                    severity: 'success',
                    summary: 'Player threshold updated.',
                });
            })
            .catch((e) => {
                toast?.current?.show({
                    severity: 'error',
                    summary: 'Error updating threshold. Please try again.',
                });
            })
            .finally(() => {
                setIsSaving(false);
            });
    };

    const loadPlayers = (cursor: string, status = 'Active') => {
        if (teamID) {
            fetchPlayers(
                {
                    cursor,
                    status,
                    teamID,
                },
                true
            )
                .then((response) => {
                    // Auto paginate teams
                    if (
                        response.data &&
                        response.data.lastEvaluatedKey.cursor &&
                        response.data.lastEvaluatedKey.cursor !== cursor
                    ) {
                        loadPlayers(response.data.lastEvaluatedKey.cursor);
                    }
                })
                .catch((error) => {
                    console.error(
                        `Error fetching events for team ${teamID}`,
                        error
                    );
                });
        }
    };

    useEffect(() => {
        loadPlayers('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCellEditComplete = (e: ColumnEvent) => {
        if (!e.newValue || !e.newValue.active || !e.newValue.rest) {
            return false;
        }

        const index = Number(e.field.slice(-1)) - 1;
        let thresholds;

        if (index >= e.rowData.thresholds.length) {
            thresholds = [...e.rowData.thresholds, e.newValue];
        } else {
            thresholds = e.rowData.thresholds.map(
                (threshold: PlayerThreshold, idx: number) => {
                    if (index === idx) {
                        return e.newValue;
                    } else {
                        return threshold;
                    }
                }
            );
        }

        if (e.newValue !== e.value) {
            savePlayerThresholds(e.rowData.playerID, thresholds);
        }
    };

    const handleClearThresholds = (playerID: string) => {
        savePlayerThresholds(playerID, []);
    };

    const permissions = {
        canCreate: checkPermission([
            Permissions.POST_PLAYERS_USERS,
            Permissions.POST_PLAYERS,
            Permissions.MANAGE_PLAYERS_USERS,
            Permissions.MANAGE_PLAYERS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canDelete: checkPermission([
            Permissions.DELETE_PLAYERS_USERS,
            Permissions.DELETE_PLAYERS,
            Permissions.MANAGE_PLAYERS_USERS,
            Permissions.MANAGE_PLAYERS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canEdit: checkPermission([
            Permissions.PUT_PLAYERS,
            Permissions.MANAGE_PLAYERS_USERS,
            Permissions.MANAGE_PLAYERS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
        canView: checkPermission([
            Permissions.GET_PLAYERS,
            Permissions.MANAGE_PLAYERS_USERS,
            Permissions.MANAGE_PLAYERS,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ]),
    };

    return (
        <>
            <DocumentHead
                title="Player Thresholds - Rookie Me Hub"
                description="Player thresholds management page"
            />
            <PlayerThresholdsView
                data={data && data.data ? data.data : []}
                isError={isError}
                isFetching={isFetching}
                isLoading={isLoading}
                isSaving={isSaving}
                onCellEditComplete={handleCellEditComplete}
                onClearThresholds={handleClearThresholds}
                permissions={permissions}
                roles={props.roles}
            />
        </>
    );
};

export default PlayerThresholdsContainer;
