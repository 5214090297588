import { useMemo, useRef } from 'react';

import { periodSuffix } from '../../../util/helper';

import ReportTable from '../components/ReportTable';
import SeasonSelect from '../components/SeasonSelect';
import EventSelect from '../components/EventSelect';
import FormGroup from '../../../components/FormGroup';
import PageContainer from '../../../layout/PageContainer';
import PageHeader from '../../../layout/PageHeader';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import { Row } from 'primereact/row';

import { Team } from '../../../types/team';
import { RookieProduct } from '../../../types/licences';

interface Props {
    columns: any[];
    data: any;
    event?: string;
    eventOptions?: any[];
    loading: boolean;
    season: string;
    team?: Team;
    totalPeriods: number;
    onEventChange: (value: string) => void;
    onExport: (dataTable: DataTable<any>) => void;
    onSeasonChange: (value: string) => void;
}

const totalColumnSchema = [
    {
        header: 'Total on',
        field: 'totalOn',
    },
    {
        header: 'Avg. on',
        field: 'avgOn',
    },
    {
        header: 'Max on stint',
        field: 'maxOnStint',
    },
    {
        header: 'Total off',
        field: 'totalOff',
    },
    {
        header: 'Avg. off',
        field: 'avgOff',
    },
    {
        header: 'Max off stint',
        field: 'maxOffStint',
    },
];

const periodColumnSchema = [
    {
        field: 'On',
        label: 'On',
    },
    {
        field: 'AvgOn',
        label: 'Avg. on',
    },
    {
        field: 'Off',
        label: 'Off',
    },
    {
        field: 'AvgOff',
        label: 'Avg. off',
    },
];

const TeamInterchangePlayerReportView = (props: Props) => {
    const {
        totalPeriods,
        onExport,
        data,
        columns,
        loading,
        onSeasonChange,
        onEventChange,
        season,
        team,
        event,
        eventOptions,
    } = props;

    const dataTable = useRef<DataTable<any>>(null);

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Player" colSpan={columns.length} />
                <Column header="Total" colSpan={totalColumnSchema.length} />

                {Array(totalPeriods)
                    .fill(undefined)
                    .map((val, idx) => {
                        const period = idx + 1;

                        return (
                            <Column
                                header={`${period}${periodSuffix(period)}`}
                                colSpan={periodColumnSchema.length}
                            />
                        );
                    })}
            </Row>
            <Row>
                {columns.map((col) => (
                    <Column header={col.shortName} {...col} sortable />
                ))}

                {totalColumnSchema.map((col) => (
                    <Column {...col} sortable />
                ))}

                {Array(totalPeriods)
                    .fill(undefined)
                    .map((val, idx) => {
                        return periodColumnSchema.map((col) => (
                            <Column
                                header={col.label}
                                sortable
                                field={`q${idx + 1}${col.field}`}
                            />
                        ));
                    })}
            </Row>
        </ColumnGroup>
    );

    const cols = useMemo(() => {
        let periodCols: any = [];

        Array(totalPeriods)
            .fill(undefined)
            .forEach((val, idx) => {
                const period = idx + 1;

                return periodColumnSchema.forEach((col) => {
                    const header = `${period}${periodSuffix(period)} ${
                        col.label
                    }`;
                    const field = `q${period}${col.field}`;

                    periodCols.push({
                        field,
                        header,
                    });
                });
            });

        return [...columns, ...totalColumnSchema, ...periodCols];
    }, [columns, totalPeriods]);

    return (
        <PageContainer>
            <PageHeader
                title="Interchange Player Report"
                beaconArticle="672450d81cdd50682627a794"
                actions={[
                    {
                        onClick: () =>
                            dataTable.current && onExport(dataTable.current),
                        label: 'Export',
                        severity: 'secondary',
                        icon: 'download',
                    },
                ]}
            />
            <Toolbar
                className="report-toolbar"
                start={
                    <>
                        <FormGroup label="Season">
                            <SeasonSelect
                                onChange={(e) => onSeasonChange(e.value)}
                                value={season}
                                placeholder="Select Season"
                                teamID={team?.teamID}
                                initialOptions={
                                    team?.defaultSeasonDetails && [
                                        team.defaultSeasonDetails,
                                    ]
                                }
                            />
                        </FormGroup>
                        <FormGroup label="Event">
                            <EventSelect
                                key={season}
                                onChange={(e) => onEventChange(e.value)}
                                value={event}
                                placeholder="Entire Season"
                                teamID={team?.teamID}
                                reportType="Game"
                                seasonID={season}
                                initialOptions={eventOptions}
                                multiselect={false}
                            />
                        </FormGroup>
                    </>
                }
            />
            <ReportTable
                ref={dataTable}
                data={data}
                columns={cols}
                isLoading={loading}
                product={RookieProduct.GameDay}
                dataTableProps={{
                    sortField: 'team.teamName',
                    sortOrder: 1,
                    exportFilename: `${team?.teamName} - Interchange Player Report`,
                    headerColumnGroup: headerGroup,
                }}
            />
        </PageContainer>
    );
};

export default TeamInterchangePlayerReportView;
