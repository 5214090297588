import React from 'react';
import { Helmet } from 'react-helmet';

interface DocumentHeadProps {
    title: string;
    description?: string;
    keywords?: string;
    className?: string;
}

// The DocumentHead component which sets the document's meta information
const DocumentHead: React.FC<DocumentHeadProps> = ({
    title,
    description,
    keywords,
    className,
}) => {
    return (
        <Helmet>
            {/* Set the title of the document */}
            <title>{title}</title>

            {/* If a description is provided, set the document's meta description */}
            {description && <meta name="description" content={description} />}

            {/* If keywords are provided, set the document's meta keywords */}
            {keywords && <meta name="keywords" content={keywords} />}

            {className && <body className={className} />}
        </Helmet>
    );
};

export default DocumentHead;
