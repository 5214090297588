interface Props {
    feature: any;
}

const PlanFeatureCard = ({ feature }: Props) => {
    if (!feature) {
        return 'Feature coming soon...';
    }

    return (
        <div className="feature-card">
            {feature?.cardVideo ? (
                <video
                    className="feature-card-video"
                    src={`https://cdn.hub.rookieme.com/licence_fcards/${feature.cardVideo}`}
                />
            ) : (
                feature?.cardImage && (
                    <img
                        className="feature-card-img"
                        src={`https://cdn.hub.rookieme.com/licence_fcards/${feature.cardImage}`}
                        alt={feature?.cardTitle}
                    />
                )
            )}
            <h3 className="feature-card-title">{feature?.cardTitle}</h3>
            <p className="feature-card-desc">{feature?.cardDescription}</p>
        </div>
    );
};

export default PlanFeatureCard;
