import { createApi } from '@reduxjs/toolkit/query/react';

import { apiEndpoints } from './apiEndpoints';
import { baseQueryWithReauth } from '../util/baseQuery';
import { BaseEntityType } from '../types/common';
import { generatePath } from 'react-router-dom';

export const adminApi = createApi({
    reducerPath: 'adminApi',
    baseQuery: (args, api, extraOptions) =>
        baseQueryWithReauth(
            args,
            api,
            extraOptions,
            process.env.REACT_APP_CLIENTAPI_URL?.replace('{client}', 'admin')
        ),
    endpoints: (builder) => ({
        createGodCheckoutSession: builder.mutation<
            any,
            {
                entityType: BaseEntityType;
                entityID: string;
                entityName: string | undefined;
                items: { quantity: number; priceID: string }[];
                cancelAfter: string;
            }
        >({
            query: ({ ...data }) => ({
                url: generatePath(
                    apiEndpoints.createGodCheckoutSession.url,
                    {}
                ),
                method: apiEndpoints.createGodCheckoutSession.method,
                body: data,
            }),
        }),
    }),
});

export const { useCreateGodCheckoutSessionMutation } = adminApi;
