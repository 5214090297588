import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import PageContainer from '../../layout/PageContainer';
import { useGetGameTimeReportQuery } from '../../api/reports';
import { useLazyGetPlayersQuery } from '../../api/players';
import { GameStat, ReportState, ReportType } from '../../types/reports';
import { UseQueryResult } from '../../types/api';
import { Player, TeamResponse } from '../../types/team';
import { EventResponse } from '../../types/event';
import { Me } from '../../types/user';
import { Role } from '../../types/roles';
import { defaultReportState } from '../reports/constants';

interface Props {
    eventData: UseQueryResult<EventResponse>;
    teamData: UseQueryResult<TeamResponse>;
    user: Me;
}

const getUniquePlayerIDs = (reportData: ReportState<GameStat[]>) => {
    if (!reportData.data) return [];
    const playerIDs = reportData.data.map((item) => item.playerID);
    return Array.from(new Set(playerIDs));
};

const getPlayersDisplayNames = (
    uniquePlayerIDs: string[],
    players: { playerID: string; displayName: string }[] = []
) => {
    return uniquePlayerIDs.map((playerID) => {
        const player = players.find((p) => p.playerID === playerID);
        return player ? player.displayName : 'Unknown Player';
    });
};

type PlayerField = 'bestPlayer' | 'bestFairest' | 'performanceRating';

const EventVoting = (props: Props) => {
    const { user } = props;

    const { teamID, eventID, organisationID, associationID } = useParams();

    // State hooks
    const [reportData, setReportData] =
        useState<ReportState<GameStat[]>>(defaultReportState);
    const [players, setPlayers] = useState<Player[]>([]);
    const [playerData, setPlayerData] = useState<
        {
            name: string;
            bestPlayer: string;
            bestFairest: string;
            performanceRating: string;
        }[]
    >([]);

    // Cache busting ref
    const timestampRef = useRef(Date.now()).current;

    // Fetching report and player data
    const { data, isLoading, isError, isFetching } = useGetGameTimeReportQuery(
        {
            eventID: eventID || '',
            teamID: teamID || '',
            reportType: ReportType.gameTimeBasic,
            sessionID: timestampRef,
        },
        { skip: !teamID || !eventID }
    );

    const [fetchPlayers, { originalArgs: playerArgs }] =
        useLazyGetPlayersQuery();

    const currentEntityID = organisationID ? organisationID : teamID;

    const currentEntityRoles = (user?.roles?.filter(
        (role: Role) => role.entityID === currentEntityID
    ) ?? []) as Role[];

    const requestReportData = data;

    // Check if associationID present, if yes then they cannot view tab
    let canView = true;
    if (
        associationID ||
        !currentEntityRoles[0]?.permissions?.includes('OWNER_ROLE')
    ) {
        canView = false;
    }

    const loadPlayers = (cursor: string, status = 'Active') => {
        if (teamID) {
            fetchPlayers(
                {
                    cursor,
                    status,
                    teamID,
                },
                true
            )
                .then((response) => {
                    if (response.data) {
                        if (response?.data?.data) {
                            const d = response.data?.data;

                            setPlayers((prev) => [...prev, ...d]);
                        }

                        // Auto paginate teams
                        if (
                            response.data.lastEvaluatedKey.cursor &&
                            response.data.lastEvaluatedKey.cursor !== cursor
                        ) {
                            loadPlayers(response.data.lastEvaluatedKey.cursor);
                        }
                    }
                })
                .catch((error) => {
                    console.error(
                        `Error fetching events for team ${teamID}`,
                        error
                    );
                });
        }
    };

    // Fetch Active Players
    useEffect(() => {
        loadPlayers('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Fetch Archived Players if required
    useEffect(() => {
        if (
            !reportData.isUninitialized &&
            !reportData.isSuccess &&
            playerArgs?.status === 'Active'
        ) {
            const hasMissingPlayers = reportData.data?.some(
                (stat: any) => !stat.player
            );

            if (hasMissingPlayers) {
                loadPlayers('', 'Archived');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportData, playerArgs]);

    useEffect(() => {
        if (players && reportData.data) {
            setReportData((prev) => ({
                ...prev,
                data:
                    prev.data &&
                    prev.data.map((stat: any) => ({
                        ...stat,
                        player: players.find(
                            (p) => p.playerID === stat.playerID
                        ),
                    })),
            }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [players, reportData.isLoading]);

    // Set report data after fetching
    useEffect(() => {
        const reportUrl = requestReportData?.data?.objectURL;
        if (reportUrl) {
            setReportData((prev) => ({
                ...prev,
                isError: false,
                isLoading: true,
                isUninitialized: false,
            }));

            fetch(reportUrl)
                .then((response) => response.json())
                .then((data) =>
                    setReportData((prev) => ({
                        ...prev,
                        data,
                        isLoading: false,
                    }))
                )
                .catch((err) =>
                    setReportData((prev) => ({
                        ...prev,
                        error: err,
                        isError: true,
                        isLoading: false,
                    }))
                );
        }
    }, [requestReportData]);

    // Update playerData state after fetching player names
    useEffect(() => {
        if (reportData.data && players) {
            const uniquePlayerIDs = getUniquePlayerIDs(reportData);
            const playerNames = getPlayersDisplayNames(
                uniquePlayerIDs,
                players
            );

            setPlayerData(
                playerNames.map((name) => ({
                    name,
                    bestPlayer: '',
                    bestFairest: '',
                    performanceRating: '',
                }))
            );
        }
    }, [reportData, players]);

    // Update the onEditorValueChange function to use PlayerField
    const onEditorValueChange = (
        props: any,
        value: string,
        field: PlayerField
    ) => {
        const updatedData = [...playerData];
        updatedData[props.rowIndex][field] = value.replace(/[^0-9]/g, ''); // Allows only numbers
        setPlayerData(updatedData);
    };

    // Update the inputTextEditor function to use PlayerField
    const inputTextEditor = (props: any, field: PlayerField) => (
        <InputText
            type="text"
            value={props.rowData[field]}
            onChange={(e) => onEditorValueChange(props, e.target.value, field)}
        />
    );

    if (eventID && (isLoading || isFetching || isError)) {
        return <div>Loading...</div>;
    }

    return (
        <PageContainer>
            {!canView ? (
                <div>
                    You do not have permission to view this data. You must be a
                    team owner.
                </div>
            ) : playerData.length > 0 ? (
                <DataTable
                    value={playerData}
                    selectionMode="single"
                    columnResizeMode="expand"
                    resizableColumns
                >
                    <Column field="name" header="Player"></Column>
                    <Column
                        field="bestPlayer"
                        header="Best Player"
                        editor={(props) => inputTextEditor(props, 'bestPlayer')}
                    ></Column>
                    <Column
                        field="bestFairest"
                        header="Best & Fairest"
                        editor={(props) =>
                            inputTextEditor(props, 'bestFairest')
                        }
                    ></Column>
                    <Column
                        field="performanceRating"
                        header="Performance Rating"
                        editor={(props) =>
                            inputTextEditor(props, 'performanceRating')
                        }
                    ></Column>
                </DataTable>
            ) : (
                <div>No player data available</div>
            )}
        </PageContainer>
    );
};

export default EventVoting;
