import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    useGetOrganisationQuery,
    useGetOrganisationTeamsQuery,
} from '../../../api/organisations';
import { useLazyGetTeamSeasonSummaryReportQuery } from '../../../api/reports';
import { useLazyGetTeamParticipatingSeasonsQuery } from '../../../api/seasons';

import OrgTeamSummaryReportView from './OrgTeamSummaryReportView';

import Loader from '../../../components/Loader';
import { DataTable } from 'primereact/datatable';

import { config } from '../reportConfig';
import { formatValue } from '../helpers';
import { Mixpanel } from '../../../util/mixpanel';
import { defaultReportState } from '../constants';

import { DataMode, ReportState } from '../../../types/reports';

interface TeamReports {
    [key: string]: {
        // teamID
        [key: string]: {
            // seasonID
            [key: string]: any;
            reportState: Omit<ReportState<any>, 'data'>;
        };
    };
}

const columnSchema = [
    'team',
    'games',
    'environmentCoeff',
    'integrityScore',
    'fairGameTime',
    'seasonResult',
    'teamScoringAccuracy',
    'teamScore',
    'oppositionScore',
    'scoreMargin',
    'rotationCount',
];

const columns = columnSchema
    .filter((col) => config[col as keyof typeof config])
    .map((col) => config[col as keyof typeof config]);

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

// Helper to batch requests with delay
const fetchInBatches = async (
    items: any[],
    fetchHandler: (item: any) => Promise<any>,
    batchSize: number,
    delayMs: number,
    progressHandler?: (progress: number) => void
) => {
    const total = items.length;
    const results: any[] = [];
    let completed = 0;

    const updateProgress = () => {
        completed++;
        if (progressHandler) {
            progressHandler((completed / total) * 100);
        }
    };

    for (let i = 0; i < total; i += batchSize) {
        const batch = items.slice(i, i + batchSize);

        const batchResults = await Promise.all(
            batch.map(async (item, ind) => {
                const result = await fetchHandler(item);
                updateProgress(); // Update progress after each fetch
                return result;
            })
        );

        // Append the results after each fetch
        results.push(...batchResults);

        if (i + batchSize < total) {
            await delay(delayMs); // Delay between batches
        }
    }

    return results;
};

const OrgTeamSummaryReportContainer = () => {
    const { organisationID } = useParams();

    // API Hooks
    const {
        data: teamsData,
        isLoading: teamsLoading,
        error: teamsError,
    } = useGetOrganisationTeamsQuery({
        organisationID: organisationID,
        expand: 'defaultSeasonDetails',
    });

    const organisationData = useGetOrganisationQuery({
        organisationID: organisationID ?? '',
    });

    const organisation = useMemo(
        () => organisationData.data?.data,
        [organisationData]
    );

    const [fetchReport] = useLazyGetTeamSeasonSummaryReportQuery();
    const [fetchTeamSeasons] = useLazyGetTeamParticipatingSeasonsQuery();

    const timestampRef = useRef(Date.now()).current;

    const [reports, setReports] = useState<TeamReports>({});

    const [seasons, setSeasons] = useState<Record<string, any[]>>({});
    const [selectedSeasons, setSelectedSeasons] = useState<
        Record<string, string>
    >({});
    const [hideEmptyColumns, setHideEmptyColumns] = useState<boolean>(true);
    const [hideIntFGTScore, setHideIntFGTScore] = useState(false);
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
    const [dataMode, setDataMode] = useState<DataMode>(DataMode.Total);

    const [loadingProgress, setLoadingProgress] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    const teams = useMemo(() => teamsData?.data || [], [teamsData]);

    const formatReport = (report: Record<string, any>) => {
        const formattedReport = {
            noEvents: report.noEvents,
            ...report.teamSeasonSummary,
        };

        columns.forEach((column) => {
            if (column.format) {
                const accessor = column.field || column.id;

                if (accessor) {
                    formattedReport[accessor] = column.format(formattedReport, {
                        column,
                        field: accessor,
                    });
                }
            }
        });

        return formattedReport;
    };

    const fetchTeamReport = async (teamID: string, seasonID: string) => {
        setSelectedSeasons((prev) => ({
            ...prev,
            [teamID]: seasonID,
        }));

        try {
            const { data: report } = await fetchReport({
                teamID,
                seasonID,
                sessionID: timestampRef,
            }).unwrap();

            const formattedReport = formatReport(report);

            return {
                teamID,
                seasonID,
                report,
                formattedReport,
            };
        } catch (error) {
            return {
                teamID,
                seasonID,
                error,
            };
        }
    };

    // Initially fetch default season reports
    useEffect(() => {
        if (teams && teams.length > 0) {
            const fetchReports = async () => {
                const results = await fetchInBatches(
                    teams,
                    (team) => {
                        setSeasons((prev) => ({
                            ...prev,
                            [team.teamID]: [
                                {
                                    label: team.defaultSeasonDetails
                                        ?.seasonName,
                                    value: team.defaultSeasonID,
                                },
                            ],
                        }));
                        return fetchTeamReport(
                            team.teamID,
                            team.defaultSeasonID
                        );
                    },
                    10, // batch size
                    500, // delay in ms
                    (progress) => setLoadingProgress(progress)
                );

                const reportsMap = results.reduce(
                    (acc, { teamID, seasonID, formattedReport, error }) => ({
                        ...acc,
                        [teamID]: {
                            ...acc[teamID],
                            [seasonID]: {
                                ...formattedReport,
                                reportState: {
                                    error,
                                    isError: !!error,
                                    isLoading: false,
                                    isSuccess: !error,
                                    isUninitialized: false,
                                },
                            },
                        },
                    }),
                    {} as ReportState<any>
                );

                setReports(reportsMap);
                setLoading(false);
            };

            fetchReports();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teams, fetchReport]);

    /**
     * EMPTY COLUMNS
     **/

    useEffect(() => {
        setHiddenColumns(() =>
            columns
                .filter((col) => {
                    if (
                        hideIntFGTScore &&
                        (col.id === 'integrityScore' ||
                            col.id === 'fairGameTime')
                    ) {
                        return true;
                    }

                    return false;
                })
                .map((col) => col.id)
        );
    }, [hideEmptyColumns, hideIntFGTScore]);

    // Handle season change for a specific team
    const handleSeasonChange = async (teamID: string, seasonID: string) => {
        setSelectedSeasons((prev) => ({ ...prev, [teamID]: seasonID }));

        if (!reports[teamID] || !reports[teamID][seasonID]) {
            setReports((prev) => ({
                ...prev,
                [teamID]: {
                    ...prev[teamID],
                    [seasonID]: {
                        reportState: {
                            ...defaultReportState,
                            isLoading: true,
                            isUninitialized: false,
                        },
                    },
                },
            }));

            const { formattedReport, error } = await fetchTeamReport(
                teamID,
                seasonID
            );

            setReports((prev) => ({
                ...prev,
                [teamID]: {
                    ...prev[teamID],
                    [seasonID]: {
                        ...formattedReport,
                        reportState: {
                            ...prev[teamID][seasonID].reportState,
                            isLoading: false,
                            isError: !!error,
                            isSuccess: !error,
                            error,
                        },
                    },
                },
            }));
        }
    };

    const handleSeasonDropdownOpen = async (teamID: string) => {
        if (!seasons[teamID] || seasons[teamID].length === 1) {
            try {
                const { data: teamSeasons } = await fetchTeamSeasons({
                    teamID,
                    cursor: '',
                }).unwrap();

                setSeasons((prev) => ({
                    ...prev,
                    [teamID]: teamSeasons.map((season) => ({
                        label: season.seasonName,
                        value: season.seasonID,
                    })),
                }));
            } catch (error) {
                console.error(
                    `Error fetching seasons for team ${teamID}:`,
                    error
                );
            }
        }
    };

    const handleExport = (dataTable: DataTable<any>) => {
        dataTable && dataTable.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: `Organisation Team Summary Report`,
        });
    };

    const dataModeOptions = [
        {
            label: DataMode.Total,
            value: DataMode.Total,
        },
        {
            label: DataMode.Average,
            value: DataMode.Average,
        },
    ];

    const tableData = useMemo(() => {
        return teams.map((team) => {
            const teamSeason = selectedSeasons[team.teamID];
            const seasonReport: any =
                reports[team.teamID] && reports[team.teamID][teamSeason]
                    ? reports[team.teamID][teamSeason]
                    : {};

            let data = { ...seasonReport };

            if (dataMode === DataMode.Average && seasonReport.noEvents > 0) {
                columns.forEach((col) => {
                    if (data[col.id]) {
                        data[col.id] = formatValue(
                            seasonReport[col.id],
                            col?.type,
                            dataMode === DataMode.Average,
                            seasonReport.noEvents
                        );
                    }
                });
            }

            return {
                ...data,
                team,
            };
        });
    }, [selectedSeasons, teams, dataMode, reports]);

    if (teamsLoading) {
        return <Loader />;
    }

    if (teamsError) {
        return <div>Failed to load teams. Please try again later.</div>;
    }

    return (
        <OrgTeamSummaryReportView
            columns={columns}
            data={tableData}
            dataMode={dataMode}
            hiddenColumns={hiddenColumns}
            hideEmptyColumns={hideEmptyColumns}
            hideIntFGTScore={hideIntFGTScore}
            loading={loading}
            loadingProgress={loadingProgress}
            options={{
                dataMode: dataModeOptions,
                seasons: seasons,
            }}
            organisation={organisation}
            selectedSeasons={selectedSeasons}
            onDataModeChange={setDataMode}
            onExport={handleExport}
            onHideEmptyColumns={setHideEmptyColumns}
            onHideIntFGTScore={setHideIntFGTScore}
            onSeasonChange={handleSeasonChange}
            onSeasonDropdownOpen={handleSeasonDropdownOpen}
        />
    );
};

export default OrgTeamSummaryReportContainer;
