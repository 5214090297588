import { uniq } from 'lodash';
import { formatPercentage } from '../../util/helper';
import {
    calculateEnvironmentScore,
    calculateFairGameTimeScore,
    calculateIntegrityScore,
} from './helpers';

import {
    integrityScoreCell,
    matchResultCell,
    playerGroupCell,
    playerNameCell,
    renderRating,
    eventCell,
    teamCell,
    timeStintCell,
} from './components/DataTableCells';

import {
    GameStat,
    ReportColumn,
    ReportDataTypes,
    StatTypes,
    TimeCategories,
} from '../../types/reports';

export const columns = {
    gameSummary: [
        'event',
        'isHomeTeam',
        'integrityScore',
        'fairGameTime',
        'matchResult',
        'teamScoringAccuracy',
        'teamScore',
        'oppositionScore',
        'scoreMargin',
        'rotationCount',
    ],
    orgGameSummary: [
        'team',
        'season',
        //'environmentCoeff',
        'integrityScore',
        'fairGameTime',
        'matchResult',
        'teamScoringAccuracy',
        'teamScore',
        'oppositionScore',
        'scoreMargin',
        'rotationCount',
    ],
};

export const config: { [key: string]: ReportColumn } = {
    games: {
        id: 'games',
        name: 'Games',
        shortName: 'Games',
        field: 'noEvents',
        category: TimeCategories.General,
        persist: true,
    },
    scoringAccuracy: {
        id: 'scoringAccuracy',
        name: 'Scoring Accuracy',
        shortName: 'SA',
        field: 'scoringAccuracy',
        category: TimeCategories.General,
        format: (data, options) => {
            let value = null;

            if (options.data) {
                const totalEvents = uniq(
                    options.data.map((o: any) => o.gameID)
                ).length;
                value = options.data.filter(
                    (o: any) => o.playerID === data.playerID && o.period === 1
                ).length;

                if (options?.views?.dataType === ReportDataTypes.Average) {
                    value = formatPercentage(value / totalEvents);
                }
            }

            return value;
        },
    },
    team: {
        id: 'team',
        field: 'team.teamName',
        name: 'Team',
        shortName: 'Team',
        type: StatTypes.Text,
        body: teamCell,
        align: 'left',
        alignHeader: 'left',
    },
    event: {
        id: 'event',
        field: 'event.eventName',
        name: 'Event',
        shortName: 'Event',
        type: StatTypes.Text,
        body: eventCell,
        align: 'left',
        alignHeader: 'left',
        sortField: 'event.startDateTime',
    },
    location: {
        id: 'location',
        field: 'event.locationName',
        name: 'Location',
        shortName: 'Location',
        type: StatTypes.Text,
    },
    opponent: {
        id: 'opponent',
        field: 'event.gameDetails.opponentTeam.teamName',
        name: 'Opponent',
        shortName: 'Opponent',
        type: StatTypes.Text,
    },
    isHomeTeam: {
        id: 'isHomeTeam',
        field: 'event.gameDetails.isHomeTeam',
        name: 'Home/Away',
        shortName: 'Home/Away',
        type: StatTypes.Text,
        body: (row) => (row.event?.gameDetails?.isHomeTeam ? 'Home' : 'Away'),
    },
    playerNumber: {
        id: 'playingNumber',
        category: TimeCategories.General,
        description: '',
        field: 'player.uniformNumber',
        name: 'Playing Number',
        shortName: 'No',
        type: StatTypes.Text,
        persist: true,
    },
    playerName: {
        id: 'playerName',
        align: 'left',
        alignHeader: 'left',
        format: (row) => `${row?.player?.firstName} ${row?.player?.lastName}`,
        body: playerNameCell,
        category: TimeCategories.General,
        description: '',
        sortField: 'player.lastName',
        field: 'playerName',
        name: 'Player',
        shortName: 'Player',
        type: StatTypes.Text,
        persist: true,
    },
    playerGroup: {
        id: 'playerGroup',
        body: playerGroupCell,
        category: TimeCategories.General,
        description: '',
        field: 'player.colour',
        name: 'Group',
        shortName: 'GRP',
        type: StatTypes.Text,
        persist: true,
    },
    gamesPlayed: {
        id: 'gamesPlayed',
        description: '',
        field: 'gamesPlayed',
        name: 'Games Played',
        shortName: 'Games',
        type: StatTypes.Text,
        persist: true,
    },
    playerAlertTarget: {
        id: '"playerAlertTarget"',
        description: '',
        field: '"playerAlertTarget"',
        name: 'Player Alert Target',
        shortName: 'Target',
        type: StatTypes.Text,
        sortField: 'totalPGT',
        persist: true,
    },
    totalPGT: {
        id: 'totalPGT',
        category: TimeCategories.General,
        field: 'totalPGT',
        format: (row: GameStat) => {
            return formatPercentage(
                row.timeOnField / row.period__available_time
            );
        },
        hsArticle: '662dc12f0cfcb4508af6a0fc',
        name: 'Percentage Game Time',
        shortName: 'PGT',
        type: StatTypes.Percentage,
    },
    PT: {
        id: 'PT',
        category: TimeCategories.General,
        field: 'PT',
        name: 'Period Time',
        shortName: 'PT',
        type: StatTypes.Time,
    },
    timeOnField: {
        id: 'timeOnField',
        category: TimeCategories.Status,
        description: 'Time a player has spent on the field.',
        field: 'timeOnField',
        hsArticle: '662dc12f0cfcb4508af6a0fc',
        name: 'Match Time',
        shortName: 'MT',
        type: StatTypes.Time,
        body: timeStintCell,
    },
    timeOffField: {
        id: 'timeOffField',
        category: TimeCategories.Status,
        description: 'Time a player has spent off the field (stints).',
        field: 'timeOffField',
        name: 'Time Off Field',
        shortName: 'OFF',
        type: StatTypes.Time,
    },
    timeBench: {
        id: 'timeBench',
        category: TimeCategories.Status,
        description: 'Time a player has spent on the bench (stints).',
        field: 'timeBench',
        name: 'Time on Bench',
        shortName: 'BEN',
        type: StatTypes.Time,
    },
    timeAssessment: {
        id: 'timeAssessment',
        category: TimeCategories.Status,
        description: 'Time a player has spent in assessment (stints).',
        field: 'timeAssessment',
        name: 'Time in Assessment',
        shortName: 'ASS',
        type: StatTypes.Time,
    },
    timeInjury: {
        id: 'timeInjury',
        category: TimeCategories.Status,
        description: 'Time a player has spent injured (stints).',
        field: 'timeInjury',
        name: 'Injury',
        shortName: 'INJ',
        type: StatTypes.Time,
    },
    timeDisciplinary: {
        id: 'timeDisciplinary',
        category: TimeCategories.Status,
        description: 'Time a player has spent in disciplinary (stints).',
        field: 'timeDisciplinary',
        name: 'Disciplinary',
        shortName: 'DIS',
        type: StatTypes.Time,
    },
    timeOpposition: {
        id: 'timeOpposition',
        category: TimeCategories.Status,
        description:
            'Time a player has spent playing the opposition team (stints).',
        field: 'timeOpposition',
        name: 'Opposition',
        shortName: 'OPP',
        type: StatTypes.Time,
    },
    timeAbsent: {
        id: 'timeAbsent',
        category: TimeCategories.Status,
        description:
            'Time a player has spent playing for the opposition team (stints).',
        field: 'timeAbsent',
        name: 'Absent',
        shortName: 'ABS',
        type: StatTypes.Time,
    },

    zoneTimeFOR: {
        id: 'zoneTimeFOR',
        category: TimeCategories.Zone,
        description:
            'Time a player has spent in the forward zone on the field.',
        field: 'zoneTimeFOR',
        name: 'Forward Zone',
        shortName: 'FOR',
        type: StatTypes.Time,
    },
    zoneTimeMID: {
        id: 'zoneTimeMID',
        category: TimeCategories.Zone,
        description:
            'Time a player has spent in the midfield zone on the field.',
        field: 'zoneTimeMID',
        name: 'Midfield Zone',
        shortName: 'MID',
        type: StatTypes.Time,
    },
    zoneTimeBAC: {
        id: 'zoneTimeBAC',
        category: TimeCategories.Zone,
        description:
            'Time a player has spent in the defensive zone on the field.',
        field: 'zoneTimeBAC',
        name: 'Defensive Zone',
        shortName: 'DEF',
        type: StatTypes.Time,
    },
    countStintBench: {
        id: 'countStintBench',
        category: TimeCategories.Stint,
        description: 'Amount of stints a player has had on the bench.',
        field: 'countStintBench',
        name: 'Bench',
        shortName: 'COUNT BEN',
        type: StatTypes.Count,
    },
    countStintAssessment: {
        id: 'countStintAssessment',
        category: TimeCategories.Stint,
        description: 'Amount of stints a player has had in assessment.',
        field: 'countStintAssessment',
        name: 'Assessment',
        shortName: 'COUNT ASS',
        type: StatTypes.Count,
    },
    countStintInjury: {
        id: 'countStintInjury',
        category: TimeCategories.Stint,
        description: 'Amount of stints a player has had in injury.',
        field: 'countStintInjury',
        name: 'Injury',
        shortName: 'COUNT INJ',
        type: StatTypes.Count,
    },
    countStintDisciplinary: {
        id: 'countStintDisciplinary',
        category: TimeCategories.Stint,
        description: 'Amount of stints a player has had in disciplinary.',
        field: 'countStintDisciplinary',
        name: 'Disciplinary',
        shortName: 'COUNT DIS',
        type: StatTypes.Count,
    },
    countStintOpposition: {
        id: 'countStintOpposition',
        category: TimeCategories.Stint,
        description:
            'Amount of stints a player has had playing for the opposition.',
        field: 'countStintOpposition',
        name: 'Opposition',
        shortName: 'COUNT OPP',
        type: StatTypes.Count,
    },
    countStintAbsent: {
        id: 'countStintAbsent',
        category: TimeCategories.Stint,
        description: 'Amount of stints a player has been absent.',
        field: 'countStintAbsent',
        name: 'Absent',
        shortName: 'COUNT ABS',
        type: StatTypes.Count,
    },
    countStintOnField: {
        id: 'countStintOnField',
        description: 'Amount of stints a player has been on the field.',
        category: TimeCategories.Stint,
        field: 'countStintOnField',
        name: 'Stints on the field',
        shortName: 'COUNT ON',
        type: StatTypes.Count,
    },
    countStintOffField: {
        id: 'countStintOffField',
        category: TimeCategories.Stint,
        description: 'Amount of stints a player has been off the field.',
        field: 'countStintOffField',
        name: 'Stints off the field',
        shortName: 'COUNT OFF',
        type: StatTypes.Count,
    },
    period__unavailable_time: {
        id: 'period__unavailable_time',
        category: TimeCategories.General,
        description: 'Amount of unavailable within a period.',
        field: 'period__unavailable_time',
        name: 'Unavailable period time',
        shortName: 'PUT',
        type: StatTypes.Time,
    },
    period__available_time: {
        id: 'period__available_time',
        category: TimeCategories.General,
        description: 'Amount of unavailable within a period.',
        field: 'period__available_time',
        name: 'Available period time',
        shortName: 'PAT',
        type: StatTypes.Time,
    },
    PMT: {
        id: 'PMT',
        category: TimeCategories.General,
        description: 'Total match time.',
        field: 'PMT',
        name: 'Period Match Time',
        shortName: 'PMT',
        type: StatTypes.Time,
    },
    rotationsPerQuarter: {
        id: 'rotationsPerQuarter',
        category: TimeCategories.General,
        description: 'Total rotations per period.',
        field: 'rotationsPerQuarter',
        name: 'Rotations Per Period',
        shortName: 'RPP',
        type: StatTypes.Count,
    },

    /*** 

    Summary Reports

    ***/

    environmentCoeff: {
        id: 'environmentCoeff',
        field: 'environmentCoeff',
        hsArticle: '667e3204d5f6cf2baa1f05c3',
        name: 'Enviroment Score',
        shortName: 'ENV',
        format: calculateEnvironmentScore,
        body: (data) => {
            if (!data.environmentCoeff) return null;
            return renderRating(data.environmentCoeff / 100);
        },
    },
    integrityScore: {
        id: 'integrityScore',
        field: 'integrityScore',
        hsArticle: '6530a9986ea22a692042cc16',
        name: 'Integrity Score',
        shortName: 'INT',
        format: calculateIntegrityScore,
        body: (data, options) => integrityScoreCell(data, options),
    },
    fairGameTime: {
        id: 'fairGameTime',
        field: 'fairGameTime',
        hsArticle: '6530a57b554393421d94c725',
        name: 'Fair Game Time Score',
        shortName: 'FGT',
        body: (data) => {
            if (!data.fairGameTime) return 'N/A';
            return renderRating(data.fairGameTime);
        },
        format: calculateFairGameTimeScore,
    },
    rotationCount: {
        id: 'rotationCount',
        description:
            'Number of rotations completed for the game, not including changes made pre game or during period breaks.',
        field: 'rotationCount',
        name: 'Rotation Count',
        shortName: 'ROT',
        type: StatTypes.Count,
    },
    wins: {
        id: 'wins',
        field: 'wins',
        name: 'Wins',
        shortName: 'W',
        type: StatTypes.Count,
    },
    losses: {
        id: 'losses',
        field: 'losses',
        name: 'Losses',
        shortName: 'L',
        type: StatTypes.Count,
    },
    draws: {
        id: 'draws',
        field: 'draws',
        name: 'Draws',
        shortName: 'D',
        type: StatTypes.Count,
    },
    teamScoringAccuracy: {
        id: 'teamScoringAccuracy',
        description:
            'Percentage of scoring shots (including any missed shots recorded) that resulted in a goal, derived by goals divided by the sum of goals and behinds.',
        field: 'teamScoringAccuracy',
        name: 'Scoring Accuracy',
        shortName: 'ACC',
        type: StatTypes.Percentage,
        format: (data) =>
            formatPercentage(data.teamScoringAccuracy / (data.noEvents || 1)) ||
            0,
    },
    teamScore: {
        id: 'teamScore',
        field: 'teamScore',
        name: 'Team Score',
        shortName: 'TS',
        type: StatTypes.Count,
        format: (data) => data.teamScore || 0,
    },
    oppositionScore: {
        id: 'oppositionScore',
        field: 'oppositionScore',
        name: 'Opposition Score',
        shortName: 'OS',
        type: StatTypes.Count,
        format: (data) => data.oppositionScore || 0,
    },
    scoreMargin: {
        id: 'scoreMargin',
        field: 'scoreMargin',
        format: (row: GameStat) => row.teamScore - row.oppositionScore,
        name: 'Score Margin',
        shortName: 'MAR',
        type: StatTypes.Count,
    },
    matchResult: {
        id: 'matchResult',
        field: 'matchResult',
        format: (row) =>
            row.wins > 0
                ? 'Win'
                : row.losses > 0
                ? 'Loss'
                : row.draws > 0
                ? 'Draw'
                : 'N/A',
        body: matchResultCell,
        name: 'Results',
        shortName: 'RESULT',
        type: StatTypes.Text,
    },
    seasonResult: {
        id: 'seasonResult',
        field: 'matchResult',
        format: (row) => `W: ${row.wins} | L: ${row.losses} | D: ${row.draws}`,
        name: 'Results',
        shortName: 'RESULT',
        type: StatTypes.Text,
    },
};
