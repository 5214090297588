import { useNavigate, useParams } from 'react-router-dom';
import {
    useDeleteAssociationSeasonMutation,
    useGetAssociationSeasonsQuery,
} from '../../api/seasons';
import { useState } from 'react';
import { confirmDialog } from 'primereact/confirmdialog';
import AssociationSeasonsView from './AssociationSeasonsView';
import { Season } from '../../types/seasons';
import { isEmpty } from 'lodash';
import { useGetStaffDetailsQuery } from '../../api/staff';
import { BaseEntityType } from '../../types/common';

const AssociationSeasonsContainer = () => {
    // Route Hooks
    const { associationID } = useParams();
    const navigate = useNavigate();

    // State Hooks
    const [cursor, setCursor] = useState<string>('');
    const [selectedSeason, setSelectedSeason] = useState<Season | null>(null);
    const [showSeasonDialog, setShowSeasonDialog] = useState<boolean>(false);
    const [showSettingsDialog, setShowSettingsDialog] =
        useState<boolean>(false);

    // API Hooks
    const [deleteSeason] = useDeleteAssociationSeasonMutation();

    const { data, isError, isLoading, isFetching } =
        useGetAssociationSeasonsQuery(
            {
                associationID: associationID || '',
                cursor,
            },
            {
                skip: !associationID,
            }
        );

    const handleUpdate = () => {
        setShowSeasonDialog(true);
    };

    const { data: staffData } = useGetStaffDetailsQuery(
        {
            entityType: BaseEntityType.teams,
            entityID: associationID ? associationID : '',
            cursor: cursor,
        },
        {
            skip: !associationID,
        }
    );

    const handleDelete = () => {
        confirmDialog({
            message: `Are you sure you want to delete ${
                selectedSeason ? selectedSeason.seasonName : 'this'
            } season?`,
            header: 'Delete Confirmation',
            acceptClassName: 'p-button-danger',
            accept: () =>
                associationID &&
                selectedSeason &&
                deleteSeason({
                    associationID,
                    seasonID: selectedSeason.seasonID,
                }),
        });
    };

    const handleSelect = (season: Season) => {
        setSelectedSeason(season);
        navigate(season.seasonID);
    };

    const handleSetFocus = (season: Season) => {
        setSelectedSeason(season);
    };

    const handleLoadMore = () => {
        if (data?.lastEvaluatedKey?.cursor) {
            setCursor(data.lastEvaluatedKey.cursor);
        }
    };

    const handleShowSeasonDialog = () => {
        setShowSeasonDialog(true);
    };

    const handleCloseSeasonDialog = () => {
        setShowSeasonDialog(false);
    };

    const handleShowSettingsDialog = () => {
        setShowSettingsDialog(true);
    };

    const handleCloseSettingsDialog = () => {
        setShowSettingsDialog(false);
    };

    const handleCreate = () => {
        setSelectedSeason(null);
        setShowSeasonDialog(true);
    };

    return (
        <>
            <AssociationSeasonsView
                data={data?.data || []}
                staffData={staffData?.data || []}
                isLoading={isLoading}
                isError={isError}
                isFetching={isFetching}
                onCreate={handleCreate}
                onDelete={handleDelete}
                onLoadMore={handleLoadMore}
                onSelect={handleSelect}
                onUpdate={handleUpdate}
                selectedSeason={selectedSeason}
                setFocus={handleSetFocus}
                showPagination={!isEmpty(cursor)}
                showSeasonDialog={showSeasonDialog}
                showSettingsDialog={showSettingsDialog}
                onShowSeasonDialog={handleShowSeasonDialog}
                onCloseSeasonDialog={handleCloseSeasonDialog}
                onShowSettingsDialog={handleShowSettingsDialog}
                onCloseSettingsDialog={handleCloseSettingsDialog}
            />
        </>
    );
};

export default AssociationSeasonsContainer;
