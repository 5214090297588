import { useRef } from 'react';

import ColumnEditPanel from '../components/ColumnEditPanel';
import EventSelect from '../components/EventSelect';
import ReportTable from '../components/ReportTable';
import SeasonSelect from '../components/SeasonSelect';
import PageContainer from '../../../layout/PageContainer';
import PageHeader from '../../../layout/PageHeader';
import RookieButton from '../../../components/RookieButton';
import FormGroup from '../../../components/FormGroup';
import { DataTable } from 'primereact/datatable';
import { OverlayPanel } from 'primereact/overlaypanel';
import { SelectButton } from 'primereact/selectbutton';
import { SelectItem } from 'primereact/selectitem';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';

import {
    ReportColumn,
    ReportDataFilters,
    ReportDataViews,
    ReportState,
    TimeStat,
} from '../../../types/reports';
import { Team } from '../../../types/team';

interface Props {
    category: string;
    customisableColumns: ReportColumn[];
    columns: ReportColumn[];
    data: any;
    emptyColumns: string[];
    filters: ReportDataFilters;
    hiddenColumns: string[];
    hideEmptyColumns: boolean;
    options: Record<string, SelectItem[]>;
    reportData: ReportState<TimeStat>;
    season: string;
    team?: Team;
    views: ReportDataViews;
    onCategoryChange: (value: any) => void;
    onColumnsChange: (value: any) => void;
    onExport: (dataTable: DataTable<any>) => void;
    onFilterChange: (key: string, value: any) => void;
    onHiddenColumnChange: (value: any) => void;
    onHideEmptyColumns: (hide: boolean) => void;
    onSeasonChange: (value: string) => void;
    onViewsChange: (key: string, value: any) => void;
}

const TeamPlayerStatsReportView = ({
    category,
    columns,
    customisableColumns,
    data,
    emptyColumns,
    filters,
    hiddenColumns,
    hideEmptyColumns,
    options,
    reportData,
    season,
    team,
    views,
    onCategoryChange,
    onColumnsChange,
    onExport,
    onFilterChange,
    onHiddenColumnChange,
    onHideEmptyColumns,
    onSeasonChange,
    onViewsChange,
}: Props) => {
    const dataTable = useRef<DataTable<any>>(null);
    const columnEdit = useRef<OverlayPanel>(null);

    // Renders
    const leftToolbar = (
        <>
            <FormGroup label="Season">
                <SeasonSelect
                    onChange={(e) => onSeasonChange(e.value)}
                    value={season}
                    placeholder="Select Season"
                    teamID={team?.teamID}
                    initialOptions={
                        team?.defaultSeasonDetails && [
                            team.defaultSeasonDetails,
                        ]
                    }
                />
            </FormGroup>
            <FormGroup label="Event">
                <EventSelect
                    key={season}
                    onChange={(e) => onFilterChange('event', e.value)}
                    value={filters.event}
                    placeholder="Entire Season"
                    teamID={team?.teamID}
                    reportType="Game"
                    seasonID={season}
                    showClear={true}
                    maxSelectedLabels={1}
                    selectAllLabel="Select All"
                    selectedItemsLabel={`${filters.event?.length} Selected`}
                    multiselect
                />
            </FormGroup>
            <FormGroup label="Period">
                <Dropdown
                    value={filters.period}
                    options={[{ label: 'All', value: 0 }, ...options.periods]}
                    onChange={(e) => onFilterChange('period', e.value)}
                />
            </FormGroup>
        </>
    );

    const rightToolbar = (
        <div className="p-button-group">
            <SelectButton
                value={views.dataType}
                onChange={(e) => onViewsChange('dataType', e.value)}
                options={options.dataType}
                disabled={filters.event?.length === 1}
                allowEmpty={false}
                pt={{
                    button: {
                        className: 'p-button-sm',
                    },
                }}
            />
            <RookieButton
                label="Customise"
                onClick={(e) => columnEdit?.current?.toggle(e)}
                size="small"
                outlined
                severity="secondary"
                icon="tune"
            />
        </div>
    );

    return (
        <PageContainer>
            <PageHeader
                title="Player Stat Report"
                actions={[
                    {
                        onClick: () =>
                            dataTable.current && onExport(dataTable.current),
                        label: 'Export',
                        severity: 'secondary',
                        icon: 'download',
                    },
                ]}
            />
            <Toolbar
                className="report-toolbar"
                start={leftToolbar}
                end={rightToolbar}
            />
            <ReportTable
                ref={dataTable}
                data={data}
                columns={columns}
                isLoading={reportData.isLoading || reportData.isUninitialized}
                pinnedColumns={['playerNumber', 'playerName']}
                dataTableProps={{
                    sortField: 'player.uniformNumber',
                    sortOrder: 1,
                    exportFilename: `${team?.teamName} - Player Stat Report`,
                }}
            />
            <OverlayPanel ref={columnEdit} className="column-popover">
                <ColumnEditPanel
                    categories={options.categories}
                    columns={customisableColumns}
                    emptyColumns={emptyColumns}
                    hiddenColumns={hiddenColumns}
                    hideEmptyColumns={hideEmptyColumns}
                    selectedCategory={category}
                    onCategoryChange={onCategoryChange}
                    onColumnsChange={onColumnsChange}
                    onHideColumn={onHiddenColumnChange}
                    onHideEmptyColumns={onHideEmptyColumns}
                />
            </OverlayPanel>
        </PageContainer>
    );
};

export default TeamPlayerStatsReportView;
