import { PlanEssentials, StripeInterval } from '../../types/subscriptions';
import { SelectButton } from 'primereact/selectbutton';
import { RadioButton } from 'primereact/radiobutton';
import { useMemo, useState } from 'react';
import { InputNumber } from 'primereact/inputnumber';

import {
    useCreateCheckoutSessionMutation,
    useGetLicenceTypesQuery,
} from '../../api/licences';
import { useCreateGodCheckoutSessionMutation } from '../../api/admin';

import { BaseEntityType } from '../../types/common';
import RookieButton from '../../components/RookieButton';

import { Mixpanel } from '../../util/mixpanel';
import { useGetEntityQuery } from '../../api/core';
import usePermission from '../../hooks/usePermission';
import { sortBy } from 'lodash';
import FormGroup from '../../components/FormGroup';
import ListItem from '../../components/ListItem';
import List from '../../components/List';
import ListDivider from '../../components/ListDivider';
import Loader from '../../components/Loader';
import { productDetails } from '../../util/constants';

interface Props {
    entityID: string;
    entityType: BaseEntityType;
    onError?: (response: any) => void;
    onCancel?: () => void;
}

const AddLicencesForm = (props: Props) => {
    const { entityID, entityType, onError } = props;

    const [selectedPlan, setSelectedPlan] = useState<string | undefined>();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [qty, setQty] = useState<number>(1);
    const [interval, setInterval] = useState<StripeInterval>('month');

    const entityData = useGetEntityQuery(
        { entityType, entityID },
        { skip: !entityType || !entityID }
    );
    const { isAdmin } = usePermission(entityData.data?.data.entityID);

    const { data: licenceTypesRaw, isLoading: loadingLicenceTypes } =
        useGetLicenceTypesQuery(
            {
                sportID: entityData?.data?.data?.entitySportID ?? '',
                expand: 'productDetails',
                hideIncReports: true,
            },
            { skip: !entityData?.data?.data }
        );

    const [createGodCheckoutSession] = useCreateGodCheckoutSessionMutation();
    const [createCheckoutSession] = useCreateCheckoutSessionMutation();

    // Flatten all prices within a licenceType and make details more consumable
    const plans = useMemo(() => {
        let arr: PlanEssentials[] = [];

        licenceTypesRaw?.data.forEach(
            ({
                product,
                licenceTypeID,
                rookieProduct,
                licenceName,
                sportID,
                sortOrder,
            }) => {
                product?.prices.forEach((price) => {
                    arr.push({
                        id: price.priceID,
                        title: `${licenceName} ${
                            price.inclusions && price.inclusions === 'reports'
                                ? ' + Reports'
                                : ''
                        }`,
                        price: (price.unitAmount || 0) / 100,
                        currency: price.currency,
                        interval: price.recurring?.interval,
                        trial: price.recurring?.trial_period_days,
                        description: product.productDescription || '',
                        licenceTypeID,
                        rookieProduct,
                        sportID,
                        sortOrder,
                        productFeatures: product.productFeatures,
                        tier: price.tier,
                        inclusions: price.inclusions,
                    });
                });
            }
        );

        return sortBy(arr, ['sortOrder', 'rookieProduct']);
    }, [licenceTypesRaw]);

    const purchasePlan = (priceID: string) => {
        const returnUrl = window.location.href;

        setLoading(true);

        createCheckoutSession({
            entityType,
            entityID,
            items: [{ quantity: qty, price: priceID }],
            successURL: returnUrl,
            cancelURL: returnUrl,
        })
            .then((response) => {
                if ('error' in response) {
                    if (onError) {
                        onError(response);
                    }
                } else {
                    Mixpanel.track(`Create Checkout Session`);

                    // Redirect to stripe checkout
                    window.location.reload();
                }
            })
            .catch((err) => {
                if (onError) {
                    onError(err);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const purchaseGodPlan = (priceID: string) => {
        setLoading(true);

        createGodCheckoutSession({
            entityType,
            entityID,
            entityName: entityData.data?.data.organisationName,
            cancelAfter: interval === 'year' ? 'ONE_YEAR' : 'ONE_MONTH',
            items: [{ quantity: qty, priceID: priceID }],
        })
            .then((response) => {
                if ('error' in response) {
                    if (onError) {
                        onError(response);
                    }
                } else {
                    Mixpanel.track(`Create Checkout Session`);

                    // Redirect to stripe checkout
                    window.location.reload();
                }
            })
            .catch((err) => {
                if (onError) {
                    onError(err);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const plan = plans.find((p: PlanEssentials) => p.id === selectedPlan);

    const loading = isLoading || loadingLicenceTypes;

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <div className="form">
                <div style={{ marginBottom: '1rem' }}>
                    <FormGroup label="Billing">
                        <SelectButton
                            allowEmpty={false}
                            value={interval}
                            onChange={(e) => setInterval(e.value)}
                            options={[
                                { label: 'Monthly', value: 'month' },
                                { label: 'Yearly', value: 'year' },
                            ]}
                        />
                    </FormGroup>
                </div>
                <div style={{ marginBottom: '1rem' }}>
                    <FormGroup label="Qty">
                        <InputNumber
                            value={qty}
                            onValueChange={(e) => e.value && setQty(e.value)}
                            min={1}
                            max={100}
                            step={1}
                            showButtons
                            buttonLayout="horizontal"
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                            decrementButtonClassName="p-button-secondary"
                            incrementButtonClassName="p-button-secondary"
                        />
                    </FormGroup>
                </div>
                <div style={{ marginBottom: '1rem' }}>
                    <FormGroup label="Select Plan">
                        <div style={{ width: '100%' }}>
                            <List>
                                {sortBy(plans, 'sortOrder')
                                    .filter(
                                        (o) =>
                                            o.interval === interval &&
                                            o.price > 0
                                    )
                                    .sort((a, b) => a.price - b.price)
                                    .map((plan) => {
                                        const {
                                            id,
                                            title,
                                            price,
                                            rookieProduct,
                                        } = plan;

                                        const details =
                                            productDetails[rookieProduct];

                                        return (
                                            <ListItem
                                                onClick={() =>
                                                    setSelectedPlan(id)
                                                }
                                                selected={selectedPlan === id}
                                                start={
                                                    <RadioButton
                                                        inputId={id}
                                                        name={title}
                                                        value={id}
                                                        checked={
                                                            selectedPlan === id
                                                        }
                                                    />
                                                }
                                                title={`${details.title} - ${title}`}
                                                caption={`${
                                                    price > 0
                                                        ? '$' + price.toFixed(2)
                                                        : 'FREE'
                                                } per ${interval}`}
                                            />
                                        );
                                    })}
                                <ListDivider />
                            </List>
                        </div>
                    </FormGroup>
                </div>

                {plan && (
                    <ListItem
                        title="Total"
                        end={`${(qty * plan.price).toFixed(
                            2
                        )} ${plan.currency.toUpperCase()}`}
                    />
                )}

                <div className="p-button-group" style={{ marginTop: '1rem' }}>
                    {isAdmin && (
                        <RookieButton
                            disabled={!selectedPlan || loading}
                            label="ADD (GOD)"
                            onClick={() => {
                                if (selectedPlan) {
                                    purchaseGodPlan(selectedPlan);
                                }
                            }}
                        />
                    )}
                    <RookieButton
                        disabled={!selectedPlan || loading}
                        label="Add Licences"
                        onClick={() => {
                            if (selectedPlan) {
                                purchasePlan(selectedPlan);
                            }
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default AddLicencesForm;
