import { formatPrice, isNullOrUndef } from '../../util/helper';
import { productDetails } from '../../util/constants';

import { Image } from 'primereact/image';
import ListItem from '../../components/ListItem';
import RookieButton from '../../components/RookieButton';

import { PlanEssentials, StripeInterval } from '../../types/subscriptions';
import { checkDiscount, DISCOUNT_AMOUNT } from './discount';

type SelectedPrices = {
    [key: string]: PlanEssentials;
};

interface Props {
    submitLabel: string;
    onSubmitClick?: () => void;
    onExpandToggle: () => void;
    expanded: boolean;
    total: string;
    billingInterval: StripeInterval;
    prices: SelectedPrices;
    qty: number;
    submitting?: boolean;
    hideSubmit?: boolean;
}

const PlanSummaryBar = ({
    submitLabel,
    expanded,
    onExpandToggle,
    onSubmitClick,
    total,
    billingInterval,
    prices,
    qty,
    submitting,
    hideSubmit,
}: Props) => {
    return (
        <div className="plans-bar is-theme-darkest">
            {expanded && (
                <div className="plans-bar-details">
                    {Object.values(prices).map((price) => {
                        const hasDiscount = checkDiscount(
                            price.sportID,
                            price.rookieProduct
                        );

                        const priceAmount =
                            hasDiscount && !isNullOrUndef(DISCOUNT_AMOUNT)
                                ? price.price - price.price * DISCOUNT_AMOUNT
                                : price.price;

                        return (
                            <ListItem
                                start={
                                    <Image
                                        src={
                                            productDetails[price.rookieProduct]
                                                .logomark
                                        }
                                        height="48px"
                                        alt="Rookie Me Hub"
                                    />
                                }
                                title={`${
                                    productDetails[price.rookieProduct].title
                                } ${price.title}`}
                                caption={`${formatPrice(priceAmount)} x ${qty}`}
                                end={`${formatPrice(qty * priceAmount)}`}
                            />
                        );
                    })}
                </div>
            )}
            <div className="plans-bar-summary">
                <div className="plans-bar-total">
                    You'll Pay {total} per {billingInterval}
                </div>
                <div className="p-button-group">
                    <RookieButton
                        label={`${expanded ? 'Hide' : 'Show'} Details`}
                        text
                        icon={
                            expanded ? 'expand_circle_down' : 'expand_circle_up'
                        }
                        iconPos="right"
                        onClick={onExpandToggle}
                    />
                    {!hideSubmit && (
                        <RookieButton
                            onClick={onSubmitClick}
                            label={submitLabel}
                            size="large"
                            loading={submitting}
                            disabled={submitting}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

PlanSummaryBar.defaultProps = {
    submitLabel: 'Go To Checkout',
};

export default PlanSummaryBar;
