import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';

import { useGetLicencesQuery } from '../api/licences';

import { BaseEntityType } from '../types/common';
import {
    LicencesResponse,
    Limits,
    Privileges,
    Restrictions,
} from '../types/licences';
import { UseQueryResult } from '../types/api';

type PrivilegeHook = {
    checkLimits: (limits: Limits) => boolean;
    checkPrivilege: (privileges: Privileges) => boolean;
    checkRestrictions: (restrictions: Restrictions) => boolean;
    licences: UseQueryResult<LicencesResponse>;
    limits?: Limits;
    privileges?: Privileges;
    restrictions?: Restrictions;
};

const usePrivilege = (
    entityID?: string,
    entityType?: BaseEntityType,
    skip = false
): PrivilegeHook => {
    // Get all Licences for entity
    const licencesData = useGetLicencesQuery(
        // @ts-expect-error
        { entityID, entityType },
        {
            skip,
        }
    );

    // Merge all privileges, restrictions and limits from all licences
    const conditions = useMemo(() => {
        return licencesData.data?.data.reduce((result, item) => {
            result.limits = result.limits || {};
            result.privileges = result.privileges || [];
            result.restrictions = result.restrictions || {};

            result.privileges = [
                ...result.privileges,
                ...item.licenceTypeDetails.privileges,
            ];

            result.restrictions = {
                ...result.restrictions,
                ...item.licenceTypeDetails.restrictions,
            };

            result.limits = {
                ...result.limits,
                ...item.licenceTypeDetails.limits,
            };

            return result;
        }, {} as any);
    }, [licencesData]);

    const checkLimits = React.useCallback(
        (limits: Limits) => {
            let allow = false;

            if (limits && conditions?.limits) {
                Object.keys(limits).forEach((key) => {
                    const value = limits[key as keyof typeof limits];
                    const max = conditions.limits[key];

                    if (max && (value || value === 0) && value < max) {
                        allow = true;
                    }
                });
            }
            return allow;
        },
        [conditions]
    );

    const checkPrivilege = React.useCallback(
        (privileges: Privileges) => {
            if (privileges && conditions?.privileges) {
                if (
                    isEmpty(privileges) ||
                    privileges.some((p) => conditions.privileges.includes(p))
                ) {
                    return true;
                }
            }

            return false;
        },
        [conditions]
    );

    const checkRestrictions = React.useCallback(
        (restrictions: Restrictions) => {
            let allow = false;

            if (restrictions && conditions?.restrictions) {
                Object.keys(restrictions).forEach((key) => {
                    if (conditions.restrictions[key]) {
                        const restriction =
                            restrictions[key as keyof typeof restrictions];

                        if (
                            isEmpty(restriction) ||
                            restriction.some((r) =>
                                conditions.restrictions[key].includes(r)
                            )
                        ) {
                            allow = true;
                        }
                    }
                });
            }

            return allow;
        },
        [conditions]
    );

    return {
        checkPrivilege,
        checkRestrictions,
        checkLimits,
        licences: licencesData,
        ...conditions,
    };
};

export default usePrivilege;
