import { UseQueryResult } from '../../types/api';
import { TeamResponse } from '../../types/team';
import { EventResponse } from '../../types/event';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Me } from '../../types/user';
import RookieButton from '../../components/RookieButton';

interface Props {
    eventData: UseQueryResult<EventResponse>;
    teamData: UseQueryResult<TeamResponse>;
    user: Me;
}

const EventReports = () => {
    const { organisationID } = useParams();
    const { eventData, teamData } = useOutletContext<Props>();

    const eventID = eventData.data?.data.eventID;
    const seasonID = eventData.data?.data.seasonID;
    const teamID = teamData.data?.data.teamID;

    const orgPrefix = organisationID ? `/o/${organisationID}` : '';

    const navigate = useNavigate();
    const reports = [
        {
            name: 'Time - Basic',
            description: 'Add desc',
            path: `${orgPrefix}/t/${teamID}/reports/time-basic?season=${seasonID}&event=${eventID}`,
        },
        {
            name: 'Time - Advanced',
            description: 'Add desc',
            path: `${orgPrefix}/t/${teamID}/reports/time-advanced?season=${seasonID}&event=${eventID}`,
        },
        {
            name: 'Time - Expert',
            description: 'Add desc',
            path: `${orgPrefix}/t/${teamID}/reports/time-expert?season=${seasonID}&event=${eventID}`,
        },
        {
            name: 'Team Stats',
            description: 'Add desc',
            path: `${orgPrefix}/t/${teamID}/reports/team-stats?season=${seasonID}&event=${eventID}`,
        },
        {
            name: 'Team Stats - Stat Density',
            description: 'Add desc',
            path: `${orgPrefix}/t/${teamID}/reports/stat-density-report?season=${seasonID}&event=${eventID}`,
        },
        {
            name: 'Player Stats',
            description: 'Add desc',
            path: `${orgPrefix}/t/${teamID}/reports/player-stats?season=${seasonID}&event=${eventID}`,
        },
        {
            name: 'Player Stats - Plus Minus',
            description: 'Add desc',
            path: `${orgPrefix}/t/${teamID}/reports/season-plus-minus?season=${seasonID}&event=${eventID}`,
        },
        {
            name: 'Interchange On/Off',
            description: 'Add desc',
            path: `${orgPrefix}/t/${teamID}/reports/season-interchange-on-off?season=${seasonID}&event=${eventID}`,
        },
        {
            name: 'Interchange Player',
            description: 'Add desc',
            path: `${orgPrefix}/t/${teamID}/reports/season-interchange-player?season=${seasonID}&event=${eventID}`,
        },
    ];

    return (
        <DataTable
            value={reports}
            selectionMode="single"
            columnResizeMode="expand"
            resizableColumns
        >
            <Column field="name" header="Name"></Column>
            <Column
                align="right"
                field="action"
                body={(value) =>
                    value.disabled ? (
                        <RookieButton
                            severity="secondary"
                            icon="lock"
                            label="Upgrade"
                            disabled
                        />
                    ) : (
                        <RookieButton
                            onClick={() => navigate(value.path)}
                            label="Generate"
                        />
                    )
                }
            ></Column>
        </DataTable>
    );
};

export default EventReports;
