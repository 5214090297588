import { isArray } from 'lodash';

// User Imports
import UserHome from '../pages/users/UserHome';
import UserBreadcrumb from '../pages/users/UserBreadcrumb';
import EntityListingContainer from '../pages/users/EntityListingContainer';
import SubscriptionBreadcrumb from '../pages/subscriptions/SubscriptionBreadcrumb';
import SubscriptionsContainer from '../pages/subscriptions/SubscriptionsContainer';
import SubscriptionSingleContainer from '../pages/subscriptions/SubscriptionSingleContainer';
import CompleteAccountContainer from '../pages/account/CompleteAccountContainer';
import AccountSelector from './AccountSelector';
import CheckoutSuccess from '../pages/users/CheckoutSuccess';
import TeamJoinCode from '../pages/teams/TeamJoinCode';
import AccountContainer from '../pages/account/AccountContainer';
import TicketContainer from '../pages/tickets/TicketContainer';
import LogOut from './LogOut';

// Event Imports
import EventsContainer from '../pages/events/EventsContainer';
import EventsSingleContainer from '../pages/events/EventSingleContainer';
import EventBreadcrumb from '../pages/events/EventBreadcrumb';
import EventDetails from '../pages/events/EventDetails';
import EventReports from '../pages/events/EventReports';
import EventVoting from '../pages/events/EventVoting';
import EventInvitees from '../pages/events/EventInvitees';
import CalendarContainer from '../pages/events/CalendarContainer';

// Association Imports
import AssociationHome from '../pages/associations/AssociationHome';
import AssociationSettings from '../pages/associations/AssociationSettings';
import AssociationBreadcrumb from '../pages/associations/AssociationBreadcrumb';
import AssociationSeasonsContainer from '../pages/seasons/AssociationSeasonsContainer';
import AssociationLicencesContainer from '../pages/licences/AssociationLicencesContainer';
import AssociationLicenceSingleContainer from '../pages/licences/AssociationLicenceSingleContainer';
import AssocSeasonReportsListing from '../pages/reports/associations/AssocReportsListing';
import SeasonSingleContainer from '../pages/seasons/SeasonSingleContainer';
import SeasonTeamsContainer from '../pages/seasons/SeasonTeamsContainer';
import SeasonSettingsContainer from '../pages/seasons/SeasonSettingsContainer';
import SeasonBreadcrumb from '../pages/seasons/SeasonBreadcrumb';
import AssocCompetitionSummaryReport from '../pages/reports/associations/AssocCompetitionSummaryReport';
import AssocCompetitionPGTReport from '../pages/reports/associations/AssocCompetitionPGTReport';
import AssocOrgSummaryReport from '../pages/reports/associations/AssocOrgSummaryReport';

// Organisation Imports
import OrganisationHome from '../pages/organisations/OrganisationHome';
import OrganisationBreadcrumb from '../pages/organisations/OrganisationBreadcrumb';
import OrganisationSettings from '../pages/organisations/OrganisationSettings';
import OrganisationsImport from '../pages/organisations/OrganisationsImport';
import OrganisationListingContainer from '../pages/organisations/OrganisationListingContainer';
import OrganisationLicencesContainer from '../pages/licences/OrganisationLicencesContainer';
import OrganisationLicenceSingleContainer from '../pages/licences/OrganisationLicenceSingleContainer';
import OrgSeasonReportsListing from '../pages/reports/organisations/OrgReportsListing';
import OrgTeamFairPlayReportContainer from '../pages/reports/organisations/OrgTeamFairPlayReportContainer';
import OrgTeamSummaryReportContainer from '../pages/reports/organisations/OrgTeamSummaryReportContainer';

// Team Imports
import TeamHome from '../pages/teams/TeamHome';
import TeamBreadcrumb from '../pages/teams/TeamBreadcrumb';
import TeamForm from '../pages/teams/TeamForm';
import TeamSettings from '../pages/teams/TeamSettings';
import TeamsImport from '../pages/teams/TeamsImport';
import TeamGameSummaryReportContainer from '../pages/reports/teams/TeamGameSummaryReportContainer';
import TeamSeasonReportsListing from '../pages/reports/teams/TeamReportsListing';
import TeamPlayerVotesReport from '../pages/reports/teams/TeamPlayerVotesReport';
import TeamSeasonsContainer from '../pages/seasons/TeamSeasonsContainer';
import TeamSeasonsOwnedContainer from '../pages/seasons/TeamSeasonsOwnedContainer';
import TeamSeasonsParticipatingContainer from '../pages/seasons/TeamSeasonsParticipatingContainer';
import TeamSeasonsArchivedContainer from '../pages/seasons/TeamSeasonsArchivedContainer';
import TeamListingContainer from '../pages/teams/TeamListingContainer';
import StaffContainer from '../pages/staff/StaffContainer';
import PlayersContainer from '../pages/players/PlayersContainer';
import PlayerThresholdsContainer from '../pages/players/PlayerThresholdsContainer';
import TeamBenchStartsContainer from '../pages/reports/teams/TeamBenchStartsContainer';
import TeamInterchangeOnOffReportContainer from '../pages/reports/teams/TeamInterchangeOnOffReportContainer';
import TeamInterchangePlayerReportContainer from '../pages/reports/teams/TeamInterchangePlayerReportContainer';
import TeamPlayerPlusMinusReportContainer from '../pages/reports/teams/TeamPlayerPlusMinusReportContainer';
import TeamStatDensityReportContainer from '../pages/reports/teams/TeamStatDensityReportContainer';
import TeamPlayerStatsReportContainer from '../pages/reports/teams/TeamPlayerStatsReportContainer';
import TeamStatsReportContainer from '../pages/reports/teams/TeamStatsReportContainer';
import TeamGameTimeReportContainer from '../pages/reports/teams/TeamGameTimeReportContainer';
import TeamParticipationReportContainer from '../pages/reports/teams/TeamParticipationReportContainer';
import LicenceBreadcrumb from '../pages/licences/LicenceBreadcrumb';
import LicencesContainer from '../pages/licences/LicencesContainer';
import TeamSettingsBadge from '../pages/teams/TeamSettingsBadge';

// Commons Services
import PostsContainer from '../pages/posts/PostsContainer';
import NotesContainer from '../pages/notes/NotesContainer';
import CollectionsContainer from '../pages/collections/CollectionsContainer';
import CollectionSingleContainer from '../pages/collections/CollectionSingleContainer';
import GroupsContainer from '../pages/groups/GroupsContainer';
import GroupSingleContainer from '../pages/groups/GroupSingleContainer';
import PlansContainer from '../pages/plans/PlansContainer';
import ResourcesContainer from '../pages/resources/ResourcesContainer';
import MessagesContainer from '../pages/messages/MessagesContainer';
import MessageSetupContainer from '../pages/messages/MessageSetupContainer';

import { ReportType } from '../types/reports';
import { Permissions } from '../types/permissions';
import { Route } from '../types/route';

import StaffBadge from '../pages/staff/StaffBadge';
import EventLineup from '../pages/events/EventLineup';
import TeamLineup from '../pages/reports/teams/TeamLineups';

// Helpers
export const ASSOCIATION_ROUTE = 'a';
export const ASSOCIATION_PARAM = 'associationID';
export const ORGANISATION_ROUTE = 'o';
export const ORGANISATION_PARAM = 'organisationID';
export const TEAM_ROUTE = 't';
export const TEAM_PARAM = 'teamID';
export const USERS_PARAM = 'userID';
export const USERS_ROUTE = 't';

interface MapType {
    [roleName: string]: {
        urlSegment: string;
        urlParam: string;
    };
}

export const entityMap: MapType = {
    associations: {
        urlSegment: ASSOCIATION_ROUTE,
        urlParam: 'ASSOCIATION_PARAM',
    },
    organisations: {
        urlSegment: ORGANISATION_ROUTE,
        urlParam: ORGANISATION_PARAM,
    },
    teams: {
        urlSegment: TEAM_ROUTE,
        urlParam: TEAM_PARAM,
    },
    users: {
        urlSegment: USERS_ROUTE,
        urlParam: USERS_PARAM,
    },
};

// Routes

const globalRoutes: Route[] = [
    {
        id: 'root',
        title: 'Home',
        path: '/',
        breadcrumb: 'Home',
        permissions: [],
        component: UserHome,
        authGateway: 'private',
    },
    {
        id: 'logout',
        title: 'Log Out',
        path: '/logout',
        permissions: [],
        component: LogOut,
        authGateway: 'public',
    },
    {
        id: 'completeAccount',
        title: 'Complete Account',
        path: '/signin/complete',
        permissions: [],
        component: CompleteAccountContainer,
        authGateway: 'session',
    },
    {
        id: 'accountSelector',
        title: 'Select Account',
        path: '/signin/account',
        permissions: [],
        component: AccountSelector,
        authGateway: 'private',
    },
    {
        id: 'checkoutSuccess',
        title: 'Success',
        path: '/checkout-success',
        permissions: [],
        component: CheckoutSuccess,
        authGateway: 'private',
    },
    {
        id: 'plans',
        title: 'Plans',
        path: '/plans',
        permissions: [],
        component: PlansContainer,
        authGateway: 'public',
    },
];

const userRoutes: Route[] = [
    {
        id: 'user-home',
        title: 'Home',
        path: '/u/:userID',
        permissions: [],
        component: UserHome,
        breadcrumb: UserBreadcrumb,
        authGateway: 'private',
    },
    {
        id: 'user-entities',
        title: 'Entities',
        path: '/u/:userID/entities/*',
        permissions: [],
        component: EntityListingContainer,
        authGateway: 'private',
    },
    {
        id: 'user-events',
        title: 'Events',
        path: '/u/:userID/events',
        permissions: [],
        component: CalendarContainer,
        authGateway: 'private',
    },
    {
        id: 'user-profile',
        title: 'Profile',
        path: '/u/:userID/profile',
        permissions: [],
        component: AccountContainer,
        authGateway: 'private',
    },
    {
        id: 'user-subscriptions',
        title: 'Subscriptions',
        path: '/u/:userID/subscriptions',
        permissions: [],
        component: SubscriptionsContainer,
        authGateway: 'private',
    },
    {
        id: 'user-subscription',
        title: 'Subscription',
        path: '/u/:userID/subscriptions/:subscriptionID',
        permissions: [],
        component: SubscriptionSingleContainer,
        breadcrumb: SubscriptionBreadcrumb,
        authGateway: 'private',
    },
    {
        id: 'user-teams-import',
        title: 'Team',
        path: '/u/:userID/teams/import',
        permissions: [],
        component: TeamsImport,
        authGateway: 'private',
    },
    {
        id: 'user-organisations-import',
        title: 'Organisations',
        path: '/u/:userID/organisations/import',
        permissions: [],
        component: OrganisationsImport,
        authGateway: 'private',
    },
    {
        id: 'tickets',
        title: 'Tickets',
        path: '/tickets/:ticketID/:action',
        permissions: [],
        component: TicketContainer,
        authGateway: 'private',
    },
    {
        id: 'user-team-join',
        title: 'Teams',
        path: '/u/:userID/team-join',
        permissions: [],
        component: TeamJoinCode,
        authGateway: 'private',
    },
    {
        id: `user-messages`,
        path: `/u/:userID/messages`,
        icon: 'chat',
        title: 'Messages',
        permissions: [],
        component: MessagesContainer,
        breadcrumb: null,
        authGateway: 'private',
    },
];

const teamRoutes = (idPrefix = '', urlPrefix = ''): Route[] => [
    {
        id: `${idPrefix}teams-home`,
        path: `${urlPrefix}/t/:teamID`,
        permissions: [],
        requireLicence: true,
        icon: 'home',
        title: 'Team',
        component: TeamHome,
        breadcrumb: TeamBreadcrumb,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-events`,
        path: `${urlPrefix}/t/:teamID/events`,
        icon: 'event',
        title: 'Events',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.GET_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: EventsContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-event`,
        path: `${urlPrefix}/t/:teamID/events/:eventID`,
        icon: 'event',
        title: 'Events',
        breadcrumb: EventBreadcrumb,
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.GET_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_EVENTS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: EventsSingleContainer,
        authGateway: 'private',
        children: [
            {
                id: `${idPrefix}teams-event-details`,
                path: 'details',
                icon: 'event',
                title: 'Details',
                permissions: [],
                component: EventDetails,
                breadcrumb: null,
                authGateway: 'private',
            },
            {
                id: `${idPrefix}teams-event-invitees`,
                path: 'invitees',
                icon: 'event',
                title: 'Invitees',
                permissions: [],
                component: EventInvitees,
                breadcrumb: null,
                authGateway: 'private',
            },
            {
                id: `${idPrefix}teams-event-reports`,
                path: 'reports',
                icon: 'event',
                title: 'Reports',
                permissions: [],
                component: EventReports,
                showBreadcrumb: false,
                breadcrumb: null,
                authGateway: 'private',
            },
            {
                id: `${idPrefix}teams-event-voting`,
                path: 'voting',
                icon: 'event',
                title: 'Voting',
                permissions: [],
                component: EventVoting,
                showBreadcrumb: false,
                breadcrumb: null,
                authGateway: 'private',
            },
            {
                id: `${idPrefix}teams-event-lineup`,
                path: 'lineup',
                icon: 'event',
                title: 'Line-up',
                permissions: [],
                component: EventLineup,
                showBreadcrumb: false,
                breadcrumb: null,
                authGateway: 'private',
            },
        ],
    },
    {
        id: `${idPrefix}teams-staff`,
        path: `${urlPrefix}/t/:teamID/staff`,
        icon: 'manage_accounts',
        title: 'Staff',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: StaffContainer,
        badge: StaffBadge,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-players`,
        path: `${urlPrefix}/t/:teamID/players`,
        title: 'Players',
        icon: 'group',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: PlayersContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-player-thresholds`,
        path: `${urlPrefix}/t/:teamID/players/thresholds`,
        title: 'Player Thresholds',
        icon: 'group',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: PlayerThresholdsContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-lineups`,
        path: `${urlPrefix}/t/:teamID/lineups`,
        icon: 'fact_check',
        title: 'Lineups',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: TeamLineup,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-posts`,
        path: `${urlPrefix}/t/:teamID/posts`,
        title: 'Posts',
        icon: 'post',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: PostsContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports`,
        path: `${urlPrefix}/t/:teamID/reports`,
        icon: 'bar_chart',
        title: 'Season Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: TeamSeasonReportsListing,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports-summary`,
        path: `${urlPrefix}/t/:teamID/reports/summary`,
        icon: 'bar_chart',
        title: 'Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: TeamGameSummaryReportContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports-participation`,
        path: `${urlPrefix}/t/:teamID/reports/participation`,
        icon: 'bar_chart',
        title: 'Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: TeamParticipationReportContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports-bench-starts`,
        path: `${urlPrefix}/t/:teamID/reports/bench-starts`,
        icon: 'bar_chart',
        title: 'Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: TeamBenchStartsContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports-player-votes`,
        path: `${urlPrefix}/t/:teamID/reports/player-votes`,
        permissions: [],
        requireLicence: true,
        title: 'Player Votes',
        authGateway: 'private',
        component: TeamPlayerVotesReport,
    },
    {
        id: `${idPrefix}teams-reports-season-interchange-player`,
        path: `${urlPrefix}/t/:teamID/reports/season-interchange-player`,
        icon: 'bar_chart',
        title: 'Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: TeamInterchangePlayerReportContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports-season-interchange-on-off`,
        path: `${urlPrefix}/t/:teamID/reports/season-interchange-on-off`,
        icon: 'bar_chart',
        title: 'Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: TeamInterchangeOnOffReportContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports-season-plus-minus`,
        path: `${urlPrefix}/t/:teamID/reports/season-plus-minus`,
        icon: 'bar_chart',
        title: 'Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: TeamPlayerPlusMinusReportContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports-stat-density-report`,
        path: `${urlPrefix}/t/:teamID/reports/stat-density-report`,
        icon: 'bar_chart',
        title: 'Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: TeamStatDensityReportContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports-time`,
        path: `${urlPrefix}/t/:teamID/reports/time-basic`,
        icon: 'bar_chart',
        title: 'Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: TeamGameTimeReportContainer,
        props: {
            reportType: ReportType.gameTimeBasic,
        },
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports-time`,
        path: `${urlPrefix}/t/:teamID/reports/time-advanced`,
        icon: 'bar_chart',
        title: 'Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: TeamGameTimeReportContainer,
        props: {
            reportType: ReportType.gameTimeAdvanced,
        },
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports-time`,
        path: `${urlPrefix}/t/:teamID/reports/time-expert`,
        icon: 'bar_chart',
        title: 'Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: TeamGameTimeReportContainer,
        props: {
            reportType: ReportType.gameTimeExpert,
        },
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports-team`,
        path: `${urlPrefix}/t/:teamID/reports/team-stats`,
        icon: 'bar_chart',
        title: 'Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: TeamStatsReportContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-reports-player`,
        path: `${urlPrefix}/t/:teamID/reports/player-stats`,
        icon: 'bar_chart',
        title: 'Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: TeamPlayerStatsReportContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-seasons`,
        path: `${urlPrefix}/t/:teamID/seasons`,
        icon: 'event_upcoming',
        title: 'Seasons',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: TeamSeasonsContainer,
        authGateway: 'private',
        children: [
            {
                id: `${idPrefix}teams-seasons-owned`,
                path: 'owned',
                icon: 'event_upcoming',
                title: 'My Seasons',
                permissions: [],
                breadcrumb: null,
                component: TeamSeasonsOwnedContainer,
                authGateway: 'private',
            },
            {
                id: `${idPrefix}teams-seasons-participating`,
                path: 'participating',
                icon: 'event_upcoming',
                title: 'Participating Seasons',
                permissions: [],
                breadcrumb: null,
                component: TeamSeasonsParticipatingContainer,
                authGateway: 'private',
            },
            {
                id: `${idPrefix}teams-seasons-archived`,
                path: 'archived',
                icon: 'event_upcoming',
                title: 'My Seasons',
                permissions: [],
                breadcrumb: null,
                component: TeamSeasonsArchivedContainer,
                authGateway: 'private',
            },
        ],
    },
    {
        id: `${idPrefix}teams-licences`,
        path: `${urlPrefix}/t/:teamID/licences`,
        icon: 'key',
        title: 'Licences',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: LicencesContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-notes`,
        path: `${urlPrefix}/t/:teamID/notes/:noteID?`,
        icon: 'note',
        title: 'Notes',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: NotesContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-resources`,
        path: `${urlPrefix}/t/:teamID/resources/folders?/:folderID?`,
        icon: 'folder_data',
        title: 'Resources',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: ResourcesContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-settings`,
        path: `${urlPrefix}/t/:teamID/settings`,
        icon: 'settings',
        title: 'Settings',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: TeamSettings,
        badge: TeamSettingsBadge,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-groups`,
        path: `${urlPrefix}/t/:teamID/groups`,
        icon: 'group',
        title: 'Groups',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: GroupsContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-group`,
        path: `${urlPrefix}/t/:teamID/groups/:groupID`,
        icon: 'group',
        title: 'Group',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: GroupSingleContainer,
        breadcrumb: null,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-messages`,
        path: `${urlPrefix}/t/:teamID/messages`,
        icon: 'chat',
        title: 'Messages',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: MessagesContainer,
        breadcrumb: null,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}teams-message-setup`,
        path: `${urlPrefix}/t/:teamID/messages/setup`,
        icon: 'chat',
        title: 'Message Settings',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        requireLicence: true,
        component: MessageSetupContainer,
        breadcrumb: null,
        authGateway: 'private',
    },
];

const organisationRoutes = (idPrefix = '', urlPrefix = ''): Route[] => [
    {
        id: `${idPrefix}organisations-home`,
        path: `${urlPrefix}/o/:organisationID`,
        icon: 'home',
        title: 'Organisation',
        permissions: [],
        breadcrumb: OrganisationBreadcrumb,
        component: OrganisationHome,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-events`,
        path: `${urlPrefix}/o/:organisationID/events`,
        icon: 'event',
        title: 'Events',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: EventsContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-staff`,
        path: `${urlPrefix}/o/:organisationID/staff`,
        icon: 'manage_accounts',
        title: 'Staff',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: StaffContainer,
        badge: StaffBadge,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-teams`,
        path: `${urlPrefix}/o/:organisationID/teams`,
        icon: 'group',
        title: 'Teams',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: TeamListingContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-teams-create`,
        path: `${urlPrefix}/o/:organisationID/teams/new`,
        icon: 'add',
        title: 'Teams',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: TeamForm,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-teams-import`,
        path: `${urlPrefix}/o/:organisationID/teams/import`,
        icon: 'upload',
        title: 'Import',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: TeamsImport,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-posts`,
        path: `${urlPrefix}/o/:organisationID/posts`,
        title: 'Posts',
        icon: 'post',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: PostsContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-reports`,
        path: `${urlPrefix}/o/:organisationID/reports`,
        icon: 'bar_chart',
        title: 'Season Reports',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: OrgSeasonReportsListing,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-reports-summary`,
        path: `${urlPrefix}/o/:organisationID/reports/summary`,
        icon: 'bar_chart',
        title: 'Season Report',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: OrgTeamSummaryReportContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-reports-fair-play`,
        path: `${urlPrefix}/o/:organisationID/reports/fair-play`,
        icon: 'bar_chart',
        title: 'Season Report',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: OrgTeamFairPlayReportContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-licences`,
        path: `${urlPrefix}/o/:organisationID/licences`,
        icon: 'key',
        title: 'Licences',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: OrganisationLicencesContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-licence`,
        path: `${urlPrefix}/o/:organisationID/licences/:licenceGroupID`,
        icon: 'key',
        title: 'Licences',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        breadcrumb: LicenceBreadcrumb,
        component: OrganisationLicenceSingleContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisation-notes`,
        path: `${urlPrefix}/o/:organisationID/notes/:noteID?`,
        icon: 'note',
        title: 'Notes',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: NotesContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-resources`,
        path: `${urlPrefix}/o/:organisationID/resources/folders?/:folderID?`,
        icon: 'folder_data',
        title: 'Resources',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: ResourcesContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-collections`,
        path: `${urlPrefix}/o/:organisationID/collections`,
        icon: 'group',
        title: 'Collections',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: CollectionsContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-collection`,
        path: `${urlPrefix}/o/:organisationID/collections/:collectionID`,
        icon: 'group',
        title: 'Collection',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: CollectionSingleContainer,
        authGateway: 'private',
        breadcrumb: null,
    },
    {
        id: `${idPrefix}organisations-settings`,
        path: `${urlPrefix}/o/:organisationID/settings`,
        icon: 'settings',
        title: 'Settings',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: OrganisationSettings,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-messages`,
        path: `${urlPrefix}/o/:organisationID/messages`,
        icon: 'chat',
        title: 'Messages',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: MessagesContainer,
        breadcrumb: null,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}organisations-message-setup`,
        path: `${urlPrefix}/o/:organisationID/messages/setup`,
        icon: 'chat',
        title: 'Message Settings',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: MessageSetupContainer,
        breadcrumb: null,
        authGateway: 'private',
    },
];

const associationRoutes = (idPrefix = '', urlPrefix = ''): Route[] => [
    {
        id: `${idPrefix}association-home`,
        path: `${urlPrefix}/a/:associationID`,
        icon: 'home',
        title: 'Association',
        permissions: [],
        breadcrumb: AssociationBreadcrumb,
        component: AssociationHome,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}association-organisations`,
        path: `${urlPrefix}/a/:associationID/organisations`,
        icon: 'group',
        title: 'Organisations',
        permissions: [],
        component: OrganisationListingContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}association-events`,
        path: `${urlPrefix}/a/:associationID/events`,
        icon: 'event',
        title: 'Events',
        permissions: [
            Permissions.GET_ASSOCIATIONS,
            Permissions.GET_ASSOCIATIONS_ALL,
            Permissions.MANAGE_ASSOCIATIONS,
            Permissions.MANAGE_ASSOCIATIONS_ALL,
            //Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: EventsContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}association-staff`,
        path: `${urlPrefix}/a/:associationID/staff`,
        icon: 'manage_accounts',
        title: 'Staff',
        permissions: [],
        component: StaffContainer,
        badge: StaffBadge,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}association-posts`,
        path: `${urlPrefix}/a/:associationID/posts`,
        title: 'Posts',
        icon: 'post',
        permissions: [
            Permissions.GET_POSTS,
            Permissions.GET_ASSOCIATIONS_ALL,
            Permissions.MANAGE_ASSOCIATIONS,
            Permissions.MANAGE_ASSOCIATIONS_ALL,
        ],
        component: PostsContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}association-reports`,
        path: `${urlPrefix}/a/:associationID/reports`,
        icon: 'bar_chart',
        title: 'Season Reports',
        permissions: [
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
            Permissions.GET_ASSOCIATIONS,
            Permissions.GET_ASSOCIATIONS_ALL,
            Permissions.MANAGE_ASSOCIATIONS,
            Permissions.MANAGE_ASSOCIATIONS_ALL,
            Permissions.MANAGE_USERS_ASSOCIATIONS,
            Permissions.POST_USERS_ASSOCIATIONS,
            Permissions.PUT_USERS_ASSOCIATIONS,
            Permissions.DELETE_USERS_ASSOCIATIONS,
        ],
        component: AssocSeasonReportsListing,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}associations-reports-environment`,
        path: `${urlPrefix}/a/:associationID/reports/environment`,
        icon: 'bar_chart',
        title: 'Season Report',
        permissions: [],
        component: AssocOrgSummaryReport,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}associations-reports-competition-environment`,
        path: `${urlPrefix}/a/:associationID/reports/competition-environment`,
        icon: 'bar_chart',
        title: 'Season Report',
        permissions: [],
        component: AssocCompetitionSummaryReport,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}associations-reports-competition-fair-play`,
        path: `${urlPrefix}/a/:associationID/reports/competition-fair-play`,
        icon: 'bar_chart',
        title: 'Season Report',
        permissions: [],
        component: AssocCompetitionPGTReport,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}association-seasons`,
        path: `${urlPrefix}/a/:associationID/seasons`,
        icon: 'event_upcoming',
        title: 'Seasons',
        permissions: [],
        component: AssociationSeasonsContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}association-season`,
        path: `${urlPrefix}/a/:associationID/seasons/:seasonID`,
        icon: 'event_upcoming',
        title: 'Season Team',
        permissions: [],
        breadcrumb: SeasonBreadcrumb,
        component: SeasonSingleContainer,
        authGateway: 'private',
        children: [
            {
                id: `${idPrefix}association-season-teams`,
                path: 'teams',
                icon: 'group',
                title: 'Teams',
                permissions: [],
                breadcrumb: null,
                component: SeasonTeamsContainer,
                authGateway: 'private',
            },
            {
                id: `${idPrefix}association-season-settings`,
                path: 'settings',
                icon: 'cog',
                title: 'Settings',
                permissions: [],
                breadcrumb: null,
                component: SeasonSettingsContainer,
                authGateway: 'private',
            },
        ],
    },
    {
        id: `${idPrefix}association-resources`,
        path: `${urlPrefix}/a/:associationID/resources/folders?/:folderID?`,
        icon: 'folder_data',
        title: 'Resources',
        permissions: [],
        component: ResourcesContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}association-licences`,
        path: `${urlPrefix}/a/:associationID/licences`,
        icon: 'key',
        title: 'Licences',
        permissions: [],
        component: AssociationLicencesContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}association-licences-single`,
        path: `${urlPrefix}/a/:associationID/licences/:licenceGroupID`,
        icon: 'key',
        title: 'Licences',
        permissions: [],
        breadcrumb: LicenceBreadcrumb,
        component: AssociationLicenceSingleContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}association-collections`,
        path: `${urlPrefix}/a/:associationID/collections`,
        icon: 'group',
        title: 'Collections',
        permissions: [],
        component: CollectionsContainer,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}association-collection`,
        path: `${urlPrefix}/a/:associationID/collections/:collectionID`,
        icon: 'group',
        title: 'Collection',
        permissions: [],
        component: CollectionSingleContainer,
        authGateway: 'private',
        breadcrumb: null,
    },
    {
        id: `${idPrefix}association-settings`,
        path: `${urlPrefix}/a/:associationID/settings`,
        icon: 'settings',
        title: 'Settings',
        permissions: [],
        component: AssociationSettings,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}associations-messages`,
        path: `${urlPrefix}/a/:associationID/messages`,
        icon: 'chat',
        title: 'Messages',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: MessagesContainer,
        breadcrumb: null,
        authGateway: 'private',
    },
    {
        id: `${idPrefix}associations-message-setup`,
        path: `${urlPrefix}/a/:associationID/messages/setup`,
        icon: 'chat',
        title: 'Message Settings',
        permissions: [
            Permissions.GET_TEAMS,
            Permissions.GET_TEAMS_ALL,
            Permissions.MANAGE_TEAMS,
            Permissions.MANAGE_TEAMS_ALL,
            Permissions.GET_ORGANISATIONS,
            Permissions.GET_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
            Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
        ],
        component: MessageSetupContainer,
        breadcrumb: null,
        authGateway: 'private',
    },
];

export const routes = [
    ...globalRoutes,
    ...userRoutes,
    ...teamRoutes(),
    ...teamRoutes('organisations-', '/o/:organisationID'),
    ...teamRoutes(
        'association-organisations-',
        '/a/:associationID/o/:organisationID'
    ),
    ...organisationRoutes(),
    ...organisationRoutes('associations-', '/a/:associationID'),
    ...associationRoutes(),
];

const flattenArray = (arr: Route[]) =>
    arr.reduce((result: Route[], val: Route) => {
        result.push(val);

        const prevPath = isArray(val.path) ? val.path[0] : val.path;

        if (val.children) {
            result.push(
                ...flattenArray(
                    val.children.map((child) => ({
                        ...child,
                        path: `${prevPath}/${child.path}`,
                    }))
                )
            );
        }

        return result;
    }, []);

const flattenedRoutes = flattenArray(routes);

const getRoutesByID = (ids: string[]) =>
    flattenedRoutes
        .filter((route) => ids.includes(route.id))
        .sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));

export const getRouteByID = (id: string) =>
    flattenedRoutes.find((route) => id === route.id);

// Menus

export const TopMenuItems = getRoutesByID([
    //'user-events',
    'user-subscriptions',
    'help',
]);

export const AssociationMenuItems = getRoutesByID([
    'association-organisations',
    'association-events',
    'association-staff',
    'association-reports',
    'association-seasons',
    'association-resources',
    'association-licences',
    'association-settings',
]);

export const OrganisationMenuItems = getRoutesByID([
    'organisations-teams',
    //'organisations-events',
    'organisations-staff',
    'organisations-reports',
    'organisations-collections',
    'organisations-posts',
    'organisations-resources',
    'organisations-licences',
    'organisation-notes',
    'organisations-settings',
]);

export const TeamMenuItems = getRoutesByID([
    'teams-events',
    'teams-staff',
    'teams-players',
    'teams-reports',
    'teams-seasons',
    'teams-messages',
    'teams-posts',
    'teams-groups',
    'teams-resources',
    'teams-licences',
    'teams-notes',
    'teams-settings',
]);

export const UserMenuItems = getRoutesByID([
    'user-profile',
    'user-account',
    // 'user-messages',
    'user-subscriptions',
    'logout',
]);

export const AccountMenuItems = getRoutesByID([
    'user-profile',
    'user-account',
    // 'user-messages',
    'user-subscriptions',
    'logout',
]);
