import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { isObject } from 'lodash';

// Hooks for fetching data from APIs.
import { useGetPlayersQuery } from '../../../api/players';
import {
    useLazyGetTeamSeasonSummaryReportQuery,
    useLazyGetTeamSeasonTimeReportQuery,
} from '../../../api/reports';
import { useGetTeamQuery } from '../../../api/teams';

// Components for rendering and types
import TeamBenchStartsView from './TeamBenchStartsView';
import { DataTable } from 'primereact/datatable';

// Helpers and utility functions.
import { config } from '../reportConfig';

import { Mixpanel } from '../../../util/mixpanel';

// Types
import {
    ReportDataTypes,
    ReportDataViews,
    ReportState,
    ReportType,
} from '../../../types/reports';
import { defaultReportState } from '../constants';

// Data options for different displays (Total or Average).
const dataTypeOptions = [
    {
        label: ReportDataTypes.Total,
        value: ReportDataTypes.Total,
    },
    {
        label: ReportDataTypes.Percentage,
        value: ReportDataTypes.Percentage,
    },
];

const columnSchema = ['playerNumber', 'playerName', 'playerGroup'];

const columns = columnSchema
    .filter((col) => config[col as keyof typeof config])
    .map((col) => config[col as keyof typeof config]);

const TeamBenchStartsContainer = () => {
    // Retrieve the team ID from the route.
    const { teamID } = useParams();

    // Search Params for preloading event and season filters.
    const [searchParams] = useSearchParams();
    const seasonParam = searchParams.get('season');

    // State Hooks
    const [season, setSeason] = useState(seasonParam || '');
    const [timeReportData, setTimeReportData] = useState<
        Record<string, ReportState<any>>
    >({});
    const [summaryReportData, setSummaryReportData] = useState<
        Record<string, ReportState<any>>
    >({});

    const [views, setViews] = useState<ReportDataViews>({
        dataType: ReportDataTypes.Total,
    });
    const [playersCursor, setPlayersCursor] = useState<string>('');

    const [reportData, setReportData] = useState([]);
    const [totalAvgGameStarts, setTotalAvgGameStarts] = useState(0);
    const [totalAvgPeriodStarts, setTotalAvgPeriodStarts] = useState(0);
    const [countGameThreshold, setCountGameThreshold] = useState(0);
    const [countPeriodThreshold, setCountPeriodThreshold] = useState(0);

    // API
    const [requestTimeReport] = useLazyGetTeamSeasonTimeReportQuery();
    const [requestSummaryReport] = useLazyGetTeamSeasonSummaryReportQuery();

    const teamData = useGetTeamQuery({
        teamID: teamID || '',
        expand: 'defaultSeasonDetails',
    });

    const team = useMemo(() => teamData.data?.data, [teamData]);

    // Cache busting ref
    const timestampRef = useRef(Date.now()).current;

    // Fetch Players
    const playerData = useGetPlayersQuery(
        {
            cursor: playersCursor,
            status: 'All',
            teamID: teamID || '',
        },
        {
            skip: !teamID,
        }
    );

    // Auto paginate players
    useEffect(() => {
        const currentCursor = playerData.data?.lastEvaluatedKey.cursor;

        if (currentCursor && currentCursor !== playersCursor) {
            setPlayersCursor(currentCursor);
        }
    }, [playerData, playersCursor]);

    // Set the season to the first available season
    useEffect(() => {
        if (!season && team) {
            setSeason(team.defaultSeasonID);
        }
    }, [team, season]);

    const exportCSV = (dataTable: DataTable<any>) => {
        dataTable && dataTable.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: `Team Bench Starts Report`,
        });
    };

    useEffect(() => {
        if (teamID && season && !timeReportData[season]) {
            const fetchReports = async () => {
                try {
                    // Set loading states for both reports
                    setTimeReportData((prev) => ({
                        ...prev,
                        [season]: {
                            ...defaultReportState,
                            isLoading: true,
                            isUninitialized: false,
                        },
                    }));

                    // Set loading states for both reports
                    setSummaryReportData((prev) => ({
                        ...prev,
                        [season]: {
                            ...defaultReportState,
                            isLoading: true,
                            isUninitialized: false,
                        },
                    }));

                    // Fetch both report URLs concurrently
                    const [timeReportResponse, summaryReportResponse] =
                        await Promise.all([
                            requestTimeReport({
                                seasonID: season,
                                teamID,
                                sessionID: timestampRef,
                                reportType: ReportType.gameTimeBasic,
                            }).unwrap(),
                            requestSummaryReport({
                                seasonID: season,
                                teamID,
                                sessionID: timestampRef,
                                concatenated: false,
                            }).unwrap(),
                        ]);

                    const timeReportUrl = timeReportResponse?.data?.objectURL;

                    // Helper function to fetch a report and update its corresponding state
                    const fetchAndSetReport = async (
                        url: string,
                        setState: (
                            value: React.SetStateAction<
                                Record<string, ReportState<any>>
                            >
                        ) => void,
                        reportName: string
                    ) => {
                        if (!url)
                            throw new Error(
                                `Invalid ${reportName} report URL or request failed`
                            );

                        const response = await fetch(url);
                        if (!response.ok)
                            throw new Error(
                                `Failed to load ${reportName} report`
                            );

                        const report = await response.json();

                        // Update the state with the fetched report data
                        setState((prev) => ({
                            ...prev,
                            [season]: {
                                ...prev[season],
                                isLoading: false,
                                data: report,
                            },
                        }));
                    };

                    // Fetch both reports concurrently
                    await Promise.all([
                        fetchAndSetReport(
                            timeReportUrl,
                            setTimeReportData,
                            'Time'
                        ),
                        setSummaryReportData((prev) => ({
                            ...prev,
                            [season]: {
                                ...prev[season],
                                isLoading: false,
                                data: summaryReportResponse?.data
                                    ?.teamSeasonSummary,
                            },
                        })),
                    ]);
                } catch (error) {
                    // Handle errors by setting the error state for both reports
                    const errorMessage =
                        isObject(error) && 'message' in error
                            ? error.message
                            : error;
                    setTimeReportData((prev) => ({
                        ...prev,
                        [season]: {
                            ...prev[season],
                            isLoading: false,
                            isError: true,
                            error: errorMessage,
                        },
                    }));
                    setSummaryReportData((prev) => ({
                        ...prev,
                        [season]: {
                            ...prev[season],
                            isLoading: false,
                            isError: true,
                            error: errorMessage,
                        },
                    }));
                }
            };

            // Call the function
            fetchReports();
        }
    }, [
        teamID,
        season,
        timeReportData,
        requestTimeReport,
        requestSummaryReport,
        timestampRef,
    ]);

    const handleViewChange = (key: string, value: any) => {
        setViews((state) => ({
            ...state,
            [key]: value,
        }));
    };

    const handleSeasonChange = (seasonID: string) => {
        setSeason(seasonID);
    };

    useEffect(() => {
        let result: any = {};

        if (timeReportData[season]?.data) {
            timeReportData[season].data
                .filter((game: any) => game.period !== 'total')
                .forEach((game: any) => {
                    const { playerID } = game;

                    // Set initial values
                    result[playerID] = result[playerID] || {
                        playerID,
                        gamesPlayed: 0,
                        periodsPlayed: 0,
                        startGamesBench: 0,
                        startPeriodBench: 0,
                    };

                    // Inject Player Info
                    if (!result[playerID].player) {
                        const player = playerData.data?.data.find(
                            (p) => p.playerID === playerID
                        );
                        result[playerID].player = player;
                        result[playerID].playerName =
                            player && `${player.firstName} ${player.lastName}`;
                    }

                    // Count all periods a player has participated in
                    result[playerID].periodsPlayed++;

                    // Count all games a player has participated in
                    if (game.period === 1) {
                        result[playerID].gamesPlayed++;
                    }
                });

            // Populate count of bench and period starts
            const benchStarts = summaryReportData[season]?.data?.benchStarts;

            if (benchStarts) {
                for (const playerID of Object.keys(result)) {
                    if (benchStarts[playerID]) {
                        // Count all numbers in the array for spbCounts
                        result[playerID].startGamesBench += benchStarts[
                            playerID
                        ].filter((period: number) => period === 1).length;

                        result[playerID].startPeriodBench +=
                            benchStarts[playerID].length;
                    }
                }
            }
        }

        if (views.dataType === ReportDataTypes.Percentage) {
            for (const playerID of Object.keys(result)) {
                result[playerID].startGamesBench =
                    (result[playerID].startGamesBench /
                        result[playerID].gamesPlayed) *
                    100;

                result[playerID].startPeriodBench =
                    (result[playerID].startPeriodBench /
                        result[playerID].periodsPlayed) *
                    100;

                // Set Total Averages
                const playerLength = Object.keys(result).length;

                let totalStartGames = 0;
                let totalStartPeriod = 0;

                Object.values(result).forEach((player: any) => {
                    totalStartGames += player.startGamesBench;
                    totalStartPeriod += player.startPeriodBench;
                });

                const avgStartGames = totalStartGames / playerLength;
                const avgStartPeriod = totalStartPeriod / playerLength;

                setTotalAvgGameStarts(avgStartGames);
                setTotalAvgPeriodStarts(avgStartPeriod);
            }
        }

        setReportData(Object.values(result));
    }, [season, summaryReportData, timeReportData, playerData, views]);

    useEffect(() => {
        if (views.dataType === ReportDataTypes.Percentage) {
            const allGameValues = reportData.map(
                (data: any) => data.startGamesBench
            );
            const allPeriodValues = reportData.map(
                (data: any) => data.startPeriodBench
            );

            const gameBenchThreshold = calculateBenchThreshold(
                allGameValues,
                totalAvgGameStarts
            );

            const periodBenchThreshold = calculateBenchThreshold(
                allPeriodValues,
                totalAvgPeriodStarts
            );

            setCountGameThreshold(gameBenchThreshold);
            setCountPeriodThreshold(periodBenchThreshold);
        }
    }, [reportData, totalAvgGameStarts, totalAvgPeriodStarts, views]);

    const calculateBenchThreshold = (benchArr: number[], totalAvg: number) => {
        const countAbove22 =
            benchArr.filter(
                (val) => val > totalAvg + 22.5 || val < totalAvg - 22.5
            ).length * 1;

        const countAbove1722 =
            benchArr.filter(
                (val) =>
                    (val > totalAvg + 17.5 && val < totalAvg + 22.5) ||
                    (val < totalAvg - 17.5 && val > totalAvg - 22.5)
            ).length * 2;

        const countAbove1217 =
            benchArr.filter(
                (val) =>
                    (val > totalAvg + 12.5 && val < totalAvg + 17.5) ||
                    (val < totalAvg - 12.5 && val > totalAvg - 17.5)
            ).length * 3;

        const countAbove712 =
            benchArr.filter(
                (val) =>
                    (val > totalAvg + 7.5 && val < totalAvg + 12.5) ||
                    (val < totalAvg - 7.5 && val > totalAvg - 12.5)
            ).length * 4;

        const countAbove07 =
            benchArr.filter(
                (val) =>
                    (val > totalAvg + 0 && val < totalAvg + 7.5) ||
                    (val < totalAvg - 0 && val > totalAvg - 7.5)
            ).length * 5;

        const total = Math.round(
            ((countAbove22 +
                countAbove1722 +
                countAbove1217 +
                countAbove712 +
                countAbove07) /
                benchArr.length /
                5) *
                100
        );

        return total;
    };

    return (
        <TeamBenchStartsView
            columns={columns}
            countGameThreshold={countGameThreshold}
            countPeriodThreshold={countPeriodThreshold}
            data={reportData}
            loading={teamData.isLoading || playerData.isLoading}
            options={{
                dataType: dataTypeOptions,
            }}
            reportData={timeReportData[season] || {}}
            season={season}
            team={team}
            totalAvgGameStarts={totalAvgGameStarts}
            totalAvgPeriodStarts={totalAvgPeriodStarts}
            views={views}
            onExport={exportCSV}
            onSeasonChange={handleSeasonChange}
            onViewsChange={handleViewChange}
        />
    );
};

export default TeamBenchStartsContainer;
