import { useContext, useEffect, useMemo, useState } from 'react';
import { sortBy } from 'lodash';

import { ToastContext } from '../../contexts/ToastContext';

import CampaignView from './CampaignView';

import { useGetRolesQuery } from '../../api/core';
import { useCreateCheckoutSessionMutation } from '../../api/licences';

import ErrorDisplay from '../../components/ErrorDisplay';
import { ToastMessage } from 'primereact/toast';

import { BaseEntityType, ERROR_TYPES } from '../../types/common';

interface CheckoutItem {
    price: string;
    quantity: number;
}

export interface Campaign {
    title: string;
    description: string;
    items: CheckoutItem[];
}

const CAMPAIGNS: { [key: string]: Campaign } = {
    'junior-upgrade': {
        title: 'Claim Your Licence',
        description:
            'Select or create a team to assign your Rookie Me Play Basic licence.',
        items: [
            {
                price: 'price_1OHhIyCInuHLpH03OnUM9TFg',
                quantity: 1,
            },
        ],
    },
    'senior-upgrade': {
        title: 'Claim Your Offer',
        description:
            'Select or create a team to upgrade to Rookie Me Play Expert.',
        items: [
            {
                price: 'price_1OgDA2CInuHLpH03YUQmGOna',
                quantity: 1,
            },
        ],
    },
};

interface Props {
    userID: string;
    campaign: string | null;
    promoCode: string | null;
}

const CampaignContainer = ({ userID, campaign, promoCode }: Props) => {
    const [showCreate, setShowCreate] = useState(false);
    const [loadingCheckout, setLoadingCheckout] = useState(false);

    const toast = useContext(ToastContext);

    const campaignItem = CAMPAIGNS[campaign as keyof typeof CAMPAIGNS];

    const {
        data: rolesData,
        isLoading,
        isError,
    } = useGetRolesQuery({
        userID,
        params: { entityType: 'teams' },
    });

    const [createCheckoutSession] = useCreateCheckoutSessionMutation();

    const teams = useMemo(() => {
        return sortBy(rolesData?.data, 'entityName');
    }, [rolesData]);

    useEffect(() => {
        if (!isLoading && teams?.length === 0 && showCreate === false) {
            setShowCreate(true);
        }
    }, [teams, isLoading, showCreate]);

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const handleTeamSelect = (teamID: string) => {
        setLoadingCheckout(true);

        createCheckoutSession({
            entityType: BaseEntityType.teams,
            entityID: teamID,
            cancelURL: window.location.href,
            successURL: `${
                window.location.origin
            }/checkout-success?entityType=${
                BaseEntityType.teams
            }&entityID=${teamID}&items=${JSON.stringify(campaignItem.items)}`,
            items: campaignItem.items,
            promoCode: promoCode || '',
        })
            .then((response) => {
                if ('error' in response) {
                    showToast({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'There was error creating a checkout session. Please try again later.',
                    });
                } else {
                    // Redirect to stripe checkout
                    window.location.href = response.data.data.url;
                }
            })
            .catch(() => {
                showToast({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'There was error creating a checkout session. Please try again later.',
                });
            })
            .finally(() => {
                setLoadingCheckout(false);
            });
    };

    if (!campaignItem) {
        return (
            <ErrorDisplay
                title="No Campaign Found"
                errorType={ERROR_TYPES.notFound}
            />
        );
    }

    return (
        <CampaignView
            teams={teams || []}
            showCreate={showCreate}
            setShowCreate={setShowCreate}
            isLoading={isLoading || loadingCheckout}
            isError={isError}
            onTeamSelect={handleTeamSelect}
            campaign={campaignItem}
            userID={userID}
        />
    );
};

export default CampaignContainer;
