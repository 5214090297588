import PageContainer from '../../layout/PageContainer';
import PageHeader from '../../layout/PageHeader';

import { TabItem } from '../../types/common';
import { TabPanel, TabView, TabViewTabChangeEvent } from 'primereact/tabview';

interface Props {
    onTabChange?: (e: TabViewTabChangeEvent) => void;
    activeTabIndex: number;
    tabs: TabItem[];
}

const ResourcesView = (props: Props) => {
    const { tabs, onTabChange, activeTabIndex } = props;
    return (
        <PageContainer>
            <PageHeader
                title="Resources"
                showBreadcrumbs={false}
                beaconArticle="677df2595b19695133fe5755"
            />
            <TabView activeIndex={activeTabIndex} onTabChange={onTabChange}>
                {tabs.map(({ id, title, component: TabContent }) => {
                    return (
                        <TabPanel key={title} header={title}>
                            <TabContent
                                activeTab={tabs[activeTabIndex]}
                                orderBy={id === 'past' ? 'desc' : 'asc'}
                                {...props}
                            />
                        </TabPanel>
                    );
                })}
            </TabView>
        </PageContainer>
    );
};

export default ResourcesView;
