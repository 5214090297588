import { useRef } from 'react';

import { periodSuffix } from '../../../util/helper';

import ReportTable from '../components/ReportTable';
import SeasonSelect from '../components/SeasonSelect';
import EventSelect from '../components/EventSelect';
import PageContainer from '../../../layout/PageContainer';
import PageHeader from '../../../layout/PageHeader';
import FormGroup from '../../../components/FormGroup';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Row } from 'primereact/row';

import { Team } from '../../../types/team';
import { TimeFormat } from '../../../types/reports';
import { RookieProduct } from '../../../types/licences';

interface Props {
    columns: any[];
    data: any;
    event: string;
    eventOptions?: any[];
    maxRotationsPerPeriod: {
        [key: string]: number;
    };
    season: string;
    team?: Team;
    timeFormat: string;
    onEventChange: (value: string) => void;
    onExport: (dataTable: DataTable<any>) => void;
    onSeasonChange: (value: string) => void;
    onTimeFormatChange: (value: TimeFormat) => void;
}

const TeamSeasonInterchangeOnOffReportView = (props: Props) => {
    const {
        columns,
        data,
        event,
        eventOptions,
        maxRotationsPerPeriod,
        season,
        team,
        timeFormat,
        onEventChange,
        onExport,
        onSeasonChange,
        onTimeFormatChange,
    } = props;

    const dataTable = useRef<DataTable<any>>(null);

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header="Player" colSpan={3} />
                {Object.keys(maxRotationsPerPeriod).map((period) => {
                    return (
                        <Column
                            header={`${period}${periodSuffix(Number(period))}`}
                            colSpan={maxRotationsPerPeriod[period]}
                        />
                    );
                })}
            </Row>
            <Row>
                <Column header="#" />
                <Column header="Player" />
                <Column header="Group" />
                {Object.keys(maxRotationsPerPeriod).map((period) =>
                    Array(maxRotationsPerPeriod[period])
                        .fill(undefined)
                        .map((val, idx) => {
                            const label = idx % 2 === 0 ? 'ON' : 'OFF';
                            return (
                                <Column
                                    header={label}
                                    field={`p${period}-r${idx + 1}`}
                                />
                            );
                        })
                )}
            </Row>
        </ColumnGroup>
    );

    return (
        <PageContainer>
            <PageHeader
                title="Interchange On/Off Report"
                beaconArticle="6724555f1cdd50682627a798"
                actions={[
                    {
                        onClick: () =>
                            dataTable.current && onExport(dataTable.current),
                        label: 'Export',
                        severity: 'secondary',
                        icon: 'download',
                    },
                ]}
            />
            <Toolbar
                className="report-toolbar"
                start={
                    <>
                        <FormGroup label="Season">
                            <SeasonSelect
                                onChange={(e) => onSeasonChange(e.value)}
                                value={season}
                                placeholder="Select Season"
                                teamID={team?.teamID}
                                initialOptions={
                                    team?.defaultSeasonDetails && [
                                        team.defaultSeasonDetails,
                                    ]
                                }
                            />
                        </FormGroup>
                        <FormGroup label="Event">
                            <EventSelect
                                key={season}
                                onChange={(e) => onEventChange(e.value)}
                                value={event}
                                placeholder="Entire Season"
                                teamID={team?.teamID}
                                reportType="Game"
                                seasonID={season}
                                initialOptions={eventOptions}
                                multiselect={false}
                            />
                        </FormGroup>
                        <FormGroup label="Time">
                            <Dropdown
                                options={Object.keys(TimeFormat).map(
                                    (value) => ({
                                        label: value,
                                        value: value,
                                    })
                                )}
                                onChange={(e) => onTimeFormatChange(e.value)}
                                value={timeFormat}
                            />
                        </FormGroup>
                    </>
                }
            />
            <ReportTable
                ref={dataTable}
                data={data}
                columns={columns}
                product={RookieProduct.GameDay}
                dataTableProps={{
                    sortField: 'team.teamName',
                    sortOrder: 1,
                    exportFilename: `${team?.teamName} - Interchange On/Off Report`,
                    headerColumnGroup: headerGroup,
                }}
            />
        </PageContainer>
    );
};

export default TeamSeasonInterchangeOnOffReportView;
