import { useState } from 'react';

import { Tooltip } from 'primereact/tooltip';
import RookieButton from '../../../components/RookieButton';

import { ReportColumn } from '../../../types/reports';

interface Props {
    column: ReportColumn;
}

const ReportHeaderCell = ({ column }: Props) => {
    const [showTooltipInfo, setShowTooltipInfo] = useState<boolean>(false);

    return (
        <>
            {column.id && (
                <Tooltip
                    target={`.cell-${column.id}`}
                    autoHide={false}
                    position="top"
                    onBeforeHide={() => setShowTooltipInfo(false)}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ color: '#fff' }}>
                            <div>{column.name}</div>
                            {showTooltipInfo && (
                                <div className="tooltip-info">
                                    {column.description}
                                </div>
                            )}
                        </div>
                        {column.hsArticle ? (
                            <RookieButton
                                data-beacon-article={column.hsArticle}
                                text={true}
                                icon="help"
                                size="small"
                            />
                        ) : (
                            column.description && (
                                <RookieButton
                                    onClick={() =>
                                        setShowTooltipInfo(!showTooltipInfo)
                                    }
                                    text={true}
                                    icon={showTooltipInfo ? 'close' : 'info'}
                                    size="small"
                                />
                            )
                        )}
                    </div>
                </Tooltip>
            )}
            {column.shortName}
        </>
    );
};

export default ReportHeaderCell;
