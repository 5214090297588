import { useState, useEffect, useMemo, useContext } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

import StaffView from './StaffView';
import DocumentHead from '../../components/DocumentHead';
import usePermission from '../../hooks/usePermission';

import { Mixpanel } from '../../util/mixpanel';

import {
    useDeleteStaffMutation,
    useGetStaffDetailsQuery,
    useGetStaffInvitesQuery,
    useLazyGetStaffDetailsQuery,
    useResendStaffInviteMutation,
    useRevokeStaffInviteMutation,
    useUpdateStaffMutation,
} from '../../api/staff';
import { useGetEntityQuery } from '../../api/core';

import { getEntityFromParam } from '../../util/helper';
import { ToastContext } from '../../contexts/ToastContext';

import { ToastMessage } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';

import { BaseEntityType } from '../../types/common';
import { UserDetails } from '../../types/user';

import { Ticket } from '../../types/ticket';
import { Permissions } from '../../types/permissions';

const StaffContainer = () => {
    const params = useParams();
    const { teamID, organisationID, associationID } = params;

    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const tab = searchParams.get('tab');

    const toast = useContext(ToastContext);

    const [isUpdatingUser, setIsUpdatingUser] = useState<string | null>();
    const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
    const [focusedUser, setFocusedUser] = useState<UserDetails | null>(null);

    const [showAddToTeamModal, setShowAddToTeamModal] =
        useState<boolean>(false);

    const { checkPermission, role } = usePermission(activeEntity?.entityID);

    const { data: entityData } = useGetEntityQuery(
        {
            entityType: activeEntity?.entityType as BaseEntityType,
            entityID: activeEntity?.entityID ?? '',
        },
        {
            skip: !activeEntity,
        }
    );

    const staffData = useGetStaffDetailsQuery(
        {
            entityType: activeEntity?.entityType as BaseEntityType,
            entityID: activeEntity?.entityID ?? '',
        },
        {
            skip: !activeEntity,
        }
    );

    const inviteData = useGetStaffInvitesQuery(
        {
            entityType: activeEntity?.entityType as BaseEntityType,
            entityID: activeEntity?.entityID ?? '',
        },
        { skip: !activeEntity }
    );

    const [refetch] = useLazyGetStaffDetailsQuery();
    const [deleteUser] = useDeleteStaffMutation();
    const [updateStaff] = useUpdateStaffMutation();
    const [revokeInvite] = useRevokeStaffInviteMutation();
    const [resendInvite] = useResendStaffInviteMutation();

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const handleLoadMore = () => {
        if (
            activeEntity &&
            staffData?.data?.lastEvaluatedKey &&
            staffData.data.lastEvaluatedKey.cursor
        ) {
            refetch({
                entityType: activeEntity.entityType,
                entityID: activeEntity.entityID,
                cursor: staffData.data.lastEvaluatedKey.cursor,
            });
        }
    };

    const handleAcceptRequest = (user: UserDetails) => {
        if (!activeEntity) return;

        setIsUpdatingUser(user.userID);

        updateStaff({
            entityType: activeEntity?.entityType,
            entityID: activeEntity?.entityID,
            userID: user.userID,
            roles: 'teamsAdmin',
        })
            .then((response) => {
                if ('error' in response) {
                    throw new Error(
                        // @ts-ignore
                        response?.error?.data?.error ||
                            'An error has occurred, please try again.'
                    );
                } else {
                    showToast({
                        severity: 'success',
                        summary: `Success`,
                        detail: `${user.firstName} ${user.lastName} has been added with View permissions`,
                    });
                }
            })
            .catch((error) => {
                showToast({
                    severity: 'warn',
                    summary: `Error`,
                    detail: error || 'An error has occurred, please try again.',
                });
            })
            .finally(() => {
                setIsUpdatingUser(null);
            });
    };

    const handleDeleteStaff = (user: UserDetails) => {
        if (activeEntity) {
            const isPending = user?.roles.some((role) =>
                role.roleID.includes('Pending')
            );

            confirmDialog({
                header: `Delete ${isPending ? 'Request' : 'Staff Member'}`,
                message: `Are you sure you want to delete ${
                    user
                        ? user.firstName + ' ' + user.lastName
                        : 'this staff member'
                }${isPending ? "'s request to join the team." : ''}`,
                accept: () => {
                    setIsUpdatingUser(user.userID);

                    deleteUser({
                        entityType: activeEntity.entityType,
                        entityID: activeEntity.entityID,
                        userID: user.userID,
                    })
                        .then((response) => {
                            if (response && 'error' in response) {
                                throw response.error;
                            }

                            // Show success toast message
                            showToast({
                                severity: 'success',
                                detail: 'Successfully deleted staff member.',
                            });
                        })
                        .catch((error: any) => {
                            let errorMessage =
                                'There was an error deleting the event. Please try again.';

                            if (error) {
                                if ('data' in error && error.data?.error) {
                                    errorMessage = error.data.error;
                                } else if ('message' in error) {
                                    errorMessage = error.message;
                                }
                            }

                            // Show error toast message
                            showToast({
                                severity: 'error',
                                detail: errorMessage,
                                summary: 'Error',
                            });
                        })
                        .finally(() => {
                            setIsUpdatingUser(null);
                        });
                },
            });
        }
    };

    const handleRevokeUser = (ticket: Ticket) => {
        if (activeEntity) {
            confirmDialog({
                header: 'Revoke Invite',
                message: 'Are you sure you want to revoke your invite?',
                accept: () => {
                    revokeInvite({
                        entityType: activeEntity.entityType,
                        entityID: activeEntity.entityID,
                        ticketID: ticket.ticketID,
                    })
                        .then(() => {
                            showToast({
                                severity: 'success',
                                summary: 'Invite Revoked',
                                detail: 'The invitation has successfully been revoked.',
                            });
                        })
                        .catch(() => {
                            showToast({
                                severity: 'error',
                                summary: 'Error',
                                detail: 'There was an error revoking the invite. Please try again later.',
                            });
                        });
                },
            });
        }
    };

    const handleResendInvite = (ticket: Ticket) => {
        if (activeEntity) {
            resendInvite({
                entityType: activeEntity.entityType,
                entityID: activeEntity.entityID,
                ticketID: ticket.ticketID,
            })
                .then(() => {
                    showToast({
                        severity: 'success',
                        summary: 'Sucess',
                        detail: 'The invitation has successfully resent.',
                    });
                })
                .catch(() => {
                    showToast({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'There was an error resending the invite. Please try again later.',
                    });
                });
        }
    };

    const handleAddStaff = (submitResult: string) => {
        const hasSubmitted = submitResult === 'success';

        showToast({
            severity: hasSubmitted ? 'success' : 'warn',
            summary: hasSubmitted
                ? 'Staff Member Invited'
                : 'Invitation Unsuccessful',
            detail: hasSubmitted
                ? 'The invitation has been successfully sent.'
                : 'The Invitation failed to send, please check details and try again.',
        });

        if (hasSubmitted) {
            // Close invite modal
            handleCloseInviteModal();
            // Navigate to pending tab
            setSearchParams({ tab: 'pending' });
        }
    };

    const handleSelectStaff = (user: UserDetails) => {
        setFocusedUser(user);

        setSearchParams((params) => {
            params.set('id', user.userID);
            return params;
        });
    };

    const handleFocusUser = (user: UserDetails) => {
        setFocusedUser(user);
    };

    const handleShowInviteModal = () => {
        setShowInviteModal(true);

        Mixpanel.track('Open Staff Invite');
    };

    const handleCloseInviteModal = () => {
        setShowInviteModal(false);
    };

    const handleCloseStaffDrawer = () => {
        setSearchParams((params) => {
            params.delete('id');
            return params;
        });
    };

    useEffect(() => {
        // Handle redirection to "active" for specific URLs with /staff or /events
        if (!tab || !['active', 'pending', 'requests'].includes(tab)) {
            navigate({ search: '?tab=active' }, { replace: true });
        }
    }, [navigate, tab]);

    const permissions = teamID
        ? {
              canCreate: checkPermission([
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_USERS_TEAMS_ALL,
                  Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
                  Permissions.MANAGE_TEAMS,
                  Permissions.MANAGE_TEAMS_ALL,
              ]),
              canDelete: checkPermission([
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_USERS_TEAMS_ALL,
                  Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
                  Permissions.MANAGE_TEAMS,
                  Permissions.MANAGE_TEAMS_ALL,
              ]),
              canEdit: checkPermission([
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_USERS_TEAMS_ALL,
                  Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
                  Permissions.MANAGE_TEAMS,
                  Permissions.MANAGE_TEAMS_ALL,
              ]),
              canView: checkPermission([
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_USERS_TEAMS_ALL,
                  Permissions.MANAGE_ORGANISATIONS_TEAMS_ALL,
                  Permissions.MANAGE_TEAMS,
                  Permissions.MANAGE_TEAMS_ALL,
              ]),
          }
        : organisationID
        ? {
              canCreate: checkPermission([
                  Permissions.POST_USERS_ORGANISATIONS,
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_USERS_ORGANISATIONS,
                  Permissions.MANAGE_ORGANISATIONS,
                  Permissions.MANAGE_ORGANISATIONS_ALL,
              ]),
              canDelete: checkPermission([
                  Permissions.DELETE_USERS_ORGANISATIONS,
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_USERS_ORGANISATIONS,
                  Permissions.MANAGE_ORGANISATIONS,
                  Permissions.MANAGE_ORGANISATIONS_ALL,
              ]),
              canEdit: checkPermission([
                  Permissions.PUT_USERS_ORGANISATIONS,
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_USERS_ORGANISATIONS,
                  Permissions.MANAGE_ORGANISATIONS,
                  Permissions.MANAGE_ORGANISATIONS_ALL,
              ]),
              canView: checkPermission([
                  Permissions.GET_ORGANISATIONS_ALL,
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_USERS_ORGANISATIONS,
                  Permissions.MANAGE_ORGANISATIONS,
                  Permissions.MANAGE_ORGANISATIONS_ALL,
              ]),
          }
        : associationID
        ? {
              canCreate: checkPermission([
                  Permissions.POST_USERS_ASSOCIATIONS,
                  Permissions.MANAGE_USERS_ASSOCIATIONS,
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_ASSOCIATIONS,
                  Permissions.MANAGE_ASSOCIATIONS_ALL,
              ]),
              canDelete: checkPermission([
                  Permissions.DELETE_USERS_ASSOCIATIONS,
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_USERS_ASSOCIATIONS,
                  Permissions.MANAGE_ASSOCIATIONS,
                  Permissions.MANAGE_ASSOCIATIONS_ALL,
              ]),
              canEdit: checkPermission([
                  Permissions.PUT_USERS_ORGANISATIONS,
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_USERS_ASSOCIATIONS,
                  Permissions.MANAGE_ASSOCIATIONS,
                  Permissions.MANAGE_ASSOCIATIONS_ALL,
              ]),
              canView: checkPermission([
                  Permissions.GET_USERS_DETAILS,
                  Permissions.MANAGE_USERS,
                  Permissions.MANAGE_USERS_ALL,
                  Permissions.MANAGE_USERS_ASSOCIATIONS,
                  Permissions.MANAGE_ASSOCIATIONS,
                  Permissions.MANAGE_ASSOCIATIONS_ALL,
              ]),
          }
        : {
              canView: false,
              canEdit: false,
              canCreate: false,
              canDelete: false,
          };

    return (
        <>
            <DocumentHead
                title="Staff - Rookie Me Hub"
                description="Staff management page"
            />
            <StaffView
                entity={entityData?.data}
                entityID={activeEntity?.entityID}
                entityType={activeEntity?.entityType}
                staff={staffData}
                focusedUser={focusedUser}
                isArchived={role.data?.entityStatus === 'Archived'}
                invites={inviteData}
                isUpdatingUser={isUpdatingUser}
                permissions={permissions}
                showAddToTeamModal={showAddToTeamModal}
                showInviteModal={showInviteModal}
                onAcceptRequest={handleAcceptRequest}
                onAddStaff={handleAddStaff}
                onFocusStaff={handleFocusUser}
                onCloseAddToTeamModal={() => setShowAddToTeamModal(false)}
                onCloseInviteModal={handleCloseInviteModal}
                onCloseStaffDrawer={handleCloseStaffDrawer}
                onDeleteStaff={handleDeleteStaff}
                onLoadMore={handleLoadMore}
                onResendTicket={handleResendInvite}
                onRevokeTicket={handleRevokeUser}
                onShowAddToTeamModal={() => setShowAddToTeamModal(true)}
                onShowInviteModal={handleShowInviteModal}
                onSelectStaff={handleSelectStaff}
            />
        </>
    );
};

export default StaffContainer;
