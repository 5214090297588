import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { isEmpty, orderBy, pick } from 'lodash';

import { coreApi } from '../../api/core';
import {
    useGetOrganisationQuery,
    useUpsertOrganisationMutation,
} from '../../api/organisations';
import {
    useGetEntityCategoriesQuery,
    useGetSportsQuery,
} from '../../api/sports';
import { useCreateAssociationOrganisationMutation } from '../../api/associations';

import { Mixpanel } from '../../util/mixpanel';
import { getDirtyValues } from '../../util/helper';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Skeleton } from 'primereact/skeleton';
import { Tooltip } from 'primereact/tooltip';
import { Dropdown } from 'primereact/dropdown';

import EntityDesigner from '../../components/EntityDesigner';
import EntityPreview from '../../components/EntityPreview';
import Icon from '../../components/Icon';
import RookieButton from '../../components/RookieButton';
import FormActions from '../../components/FormActions';
import FormFields from '../../components/FormFields';
import FormGroup from '../../components/FormGroup';

import { FormSubmitCallback, INPUT_TYPES } from '../../types/common';
import { OrganisationFormData } from '../../types/organisation';
import { Design } from '../../types/design';
import { Sport } from '../../types/sports';

interface FormProps {
    design: Design;
    organisationName?: string;
    entitySportID?: string;
    entityCategoryID?: string;
    shortName?: string;
    sportID?: string;
}

interface OrganisationFormProps {
    initialValues: FormProps;
    organisationID?: OrganisationFormData['organisationID'];
    onCancel?: () => void;
    onSubmitComplete: FormSubmitCallback;
}

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const OrganisationForm = (props: OrganisationFormProps) => {
    const {
        initialValues,
        organisationID: organisationIDProp,

        onSubmitComplete,
    } = props;
    const { organisationID: organisationIDParam, associationID } = useParams();
    const dispatch = useDispatch();

    const organisationID = organisationIDProp || organisationIDParam;

    const [selectedSport, setSelectedSport] = useState<string | undefined>();

    const [showEditor, setShowEditor] = useState(false);

    const [upsertOrganisation] = useUpsertOrganisationMutation();
    const [linkOrganisation] = useCreateAssociationOrganisationMutation();

    const { data: organisationData } = useGetOrganisationQuery(
        { organisationID },
        { skip: !organisationID }
    );

    const { data: sports, isLoading: isLoadingSports } = useGetSportsQuery();

    const { data: categories, isLoading: isLoadingCategories } =
        useGetEntityCategoriesQuery(
            { sportID: selectedSport || '' },
            { skip: !selectedSport }
        );

    useEffect(() => {
        if (organisationData?.data) {
            setSelectedSport(organisationData.data.entitySportID);
        }
    }, [organisationData]);

    const requiredFields: Array<keyof FormProps> = [
        'design',
        'entitySportID',
        'entityCategoryID',
        'organisationName',
        'shortName',
    ];

    const isCreate = !organisationID;

    const handleSubmit = async (
        data: FormProps,
        { setSubmitting }: FormikHelpers<FormProps>
    ) => {
        setSubmitting(true);

        const formData: any = isCreate
            ? data
            : getDirtyValues(data, initialValues);

        let payload = formData;

        if (payload.design) {
            payload.design = {
                ...initialValues.design,
                ...payload.design,
            };
        }

        if (!isCreate) {
            delete payload.entitySportID;
            delete payload.entityCategoryID;
        }

        if (!isEmpty(payload)) {
            try {
                const response = await upsertOrganisation({
                    organisationID,
                    ...payload,
                });

                if ('error' in response) {
                    throw new Error('Error creating organisation.');
                }

                if (isCreate) {
                    const newOrganisationID =
                        response.data?.data?.organisationID;

                    // Link org to assoc if creating from within assoc
                    if (associationID) {
                        await linkOrgToAssoc(newOrganisationID);
                    }

                    // Success Create Callback
                    onSubmitComplete &&
                        onSubmitComplete('success', 'create', response);
                } else {
                    // Success Update Callback
                    onSubmitComplete &&
                        onSubmitComplete('success', 'update', response);
                }

                Mixpanel.track(
                    `${organisationID ? 'Update' : 'Create'} Organisation`
                );

                // Wait for 4 seconds to allow server to respond
                await delay(4000);

                // Refresh roles
                dispatch(coreApi.util.invalidateTags(['Role']));
            } catch (error) {
                // Error Callback
                onSubmitComplete &&
                    onSubmitComplete(
                        'error',
                        isCreate ? 'create' : 'update',
                        undefined,
                        error
                    );
            }
        }

        setSubmitting(false);
    };

    const linkOrgToAssoc = async (organisationID: string) => {
        if (!associationID || !organisationID) return;

        try {
            const response = await linkOrganisation({
                associationID,
                organisationID,
            });

            if ('error' in response) {
                throw new Error('Error linking organisation.');
            }
            // Success Link Callback
            onSubmitComplete && onSubmitComplete('success', 'link', response);
        } catch (error) {
            // Error Link Callback
            onSubmitComplete &&
                onSubmitComplete('error', 'link', undefined, error);
        }
    };

    const sportOptions = useMemo(() => {
        const options = sports?.data.map((sport: Sport) => ({
            label: sport.sportName,
            value: sport.sportID,
        }));

        return orderBy(options, 'label');
    }, [sports]);

    const entityCategories = useMemo(() => {
        const options = categories?.data
            ?.filter((cat) => cat.entityType === 'organisations')
            .map((cat) => ({
                label: cat.entityCategoryName,
                value: cat.entityCategoryID,
                name: cat.entityCategoryName,
            }));

        return orderBy(options, 'label');
    }, [categories]);

    return (
        <Formik
            initialValues={pick(initialValues, requiredFields)}
            validate={(values) => {
                const errors: { [key: string]: string } = {};

                requiredFields.forEach((field) => {
                    if (!values[field]) {
                        errors[field] = 'Required';
                    }
                });

                return errors;
            }}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={handleSubmit}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
                setSubmitting,
            }) => (
                <>
                    <Dialog
                        header="Kit Designer"
                        onHide={() => setShowEditor(false)}
                        visible={showEditor}
                    >
                        <EntityDesigner
                            initialValues={values.design}
                            onCancel={() => setShowEditor(false)}
                            onSubmit={(value) => {
                                setFieldValue('design', value);
                                setShowEditor(false);
                            }}
                            submitLabel="Save Design"
                        />
                    </Dialog>

                    <form onSubmit={handleSubmit}>
                        <FormFields>
                            <FormGroup className="kit-edit_wrap">
                                <div
                                    className="kit-edit"
                                    onClick={() => setShowEditor(true)}
                                >
                                    <EntityPreview design={values.design} />
                                    <div className="edit-badge">
                                        <Icon name="edit" size="small"></Icon>
                                    </div>
                                </div>
                            </FormGroup>

                            <FormGroup
                                label="Sport"
                                htmlFor="sportID"
                                error={errors.entitySportID}
                                showError={
                                    !!errors.entitySportID &&
                                    touched.entitySportID
                                }
                            >
                                {isLoadingSports ? (
                                    <Skeleton height="44px" />
                                ) : (
                                    <Dropdown
                                        disabled={!!organisationID}
                                        value={selectedSport}
                                        options={sportOptions}
                                        onChange={(e) => {
                                            setSelectedSport(e.value);
                                            setFieldValue(
                                                'entitySportID',
                                                e.value
                                            );
                                            setFieldValue(
                                                'entityCategoryID',
                                                ''
                                            );
                                        }}
                                        id="entitySportID"
                                        name="entitySportID"
                                    />
                                )}
                            </FormGroup>

                            {selectedSport && (
                                <FormGroup
                                    label="Organisation Type"
                                    htmlFor="entityCategoryID"
                                    error={errors.entityCategoryID}
                                    showError={
                                        !!errors.entityCategoryID &&
                                        touched.entityCategoryID
                                    }
                                >
                                    {isLoadingCategories ? (
                                        <Skeleton height="44px" />
                                    ) : (
                                        <Dropdown
                                            id="entityCategoryID"
                                            name="entityCategoryID"
                                            value={values.entityCategoryID}
                                            options={entityCategories}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={!isCreate}
                                        />
                                    )}
                                </FormGroup>
                            )}
                            <FormGroup
                                label="Organisation Name"
                                htmlFor="organisationName"
                                error={errors.organisationName}
                                showError={
                                    !!errors.organisationName &&
                                    touched.organisationName
                                }
                            >
                                <InputText
                                    id="organisationName"
                                    name="organisationName"
                                    type={INPUT_TYPES.text}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Organisation Name"
                                    value={values.organisationName}
                                    required
                                />
                            </FormGroup>
                            <FormGroup
                                label={
                                    <>
                                        Short Name
                                        <Tooltip target=".shortName-help" />
                                        <span
                                            className="help-tip shortName-help"
                                            data-pr-tooltip="Short Name can contain only up to 4 alphabetical characters."
                                            data-pr-position="top"
                                        >
                                            <Icon name="info" size="small" />
                                        </span>
                                        {values.shortName && (
                                            <small className="form-label--right">
                                                {values.shortName.length}
                                                /4
                                            </small>
                                        )}
                                    </>
                                }
                                htmlFor="shortName"
                                error={errors.shortName}
                                showError={
                                    !!errors.shortName && touched.shortName
                                }
                            >
                                <InputText
                                    id="shortName"
                                    maxLength={4}
                                    name="shortName"
                                    onBlur={handleBlur}
                                    onChange={(event) => {
                                        let shortNameVal = event.target.value;
                                        shortNameVal = shortNameVal.replace(
                                            /[^a-zA-Z]/g,
                                            ''
                                        );

                                        shortNameVal =
                                            shortNameVal.toUpperCase();

                                        setFieldValue(
                                            'shortName',
                                            shortNameVal
                                        );

                                        setFieldTouched('shortName', true);
                                    }}
                                    placeholder="Short Name"
                                    required
                                    type={INPUT_TYPES.text}
                                    value={values.shortName}
                                />
                            </FormGroup>
                        </FormFields>

                        <FormActions
                            start={
                                <RookieButton
                                    disabled={isSubmitting}
                                    type="submit"
                                    className={
                                        isSubmitting
                                            ? 'is-submitting'
                                            : undefined
                                    }
                                    label={
                                        !isCreate && isSubmitting
                                            ? 'Updating'
                                            : !isCreate
                                            ? 'Save'
                                            : isSubmitting
                                            ? 'Creating'
                                            : 'Create Organisation'
                                    }
                                />
                            }
                        />

                        {isSubmitting && (
                            <div className="form-overlay">
                                <ProgressBar mode="indeterminate" />
                            </div>
                        )}
                    </form>
                </>
            )}
        </Formik>
    );
};

OrganisationForm.defaultProps = {
    initialValues: {
        organisationName: '',
        shortName: '',
        entityCategoryID: '',
        design: {
            template: '',
            primary: '',
            secondary: '',
            tertiary: '',
        },
    },
};

export default OrganisationForm;
