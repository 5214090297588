import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import type {
    Channel,
    ChannelFilters,
    ChannelOptions,
    ChannelSort,
} from 'stream-chat';
import {
    useGetMessageSettingsQuery,
    useGetMessageTokenQuery,
    useGetEntityChannelsQuery,
} from '../../api/messages';
import { getEntityFromParam } from '../../util/helper';

import MessagesView from './MessagesView';
import Loader from '../../components/Loader';

import { UserDetails } from '../../types/user';
import { BaseEntityType } from '../../types/common';
import { MessageChannel } from '../../types/messages';

interface Props {
    user: UserDetails;
}

const MessagesContainer = (props: Props) => {
    const { user } = props;
    const { userID } = user;

    const navigate = useNavigate();
    const params = useParams();

    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);

    const [showSettings, setShowSettings] = useState(false);
    const [selectedChannel, setSelectedChannel] = useState<Channel>();

    let filters: ChannelFilters = {
        members: { $in: [userID] },
    };

    // Conditionally filter channels to this entity
    if (activeEntity) {
        filters = {
            ...filters,
            type: { $eq: 'team' },
            team: [activeEntity.entityType, activeEntity.entityID].join('_'),
        };
    }

    const { data: channelsData, isLoading: loadingChannels } =
        useGetEntityChannelsQuery(
            {
                entityID: activeEntity?.entityID ?? '',
                entityType: activeEntity?.entityType as BaseEntityType,
            },
            {
                skip: !activeEntity,
            }
        );

    const {
        data: messageSettingsResponse,
        isLoading: loadingSettings,
        isSuccess,
    } = useGetMessageSettingsQuery(
        {
            entityID: activeEntity?.entityID ?? '',
            entityType: activeEntity?.entityType as BaseEntityType,
        },
        {
            skip: !activeEntity,
        }
    );

    const { data: messageTokenResponse } = useGetMessageTokenQuery(
        { userID: userID || '' },
        {
            skip: !userID,
        }
    );
    const messageSettings = messageSettingsResponse?.data || null;
    const streamToken = messageTokenResponse?.data?.token || null;
    const sort: ChannelSort = { last_message_at: -1 };
    const options: ChannelOptions = {
        limit: 200,
    };

    const userData = useMemo(
        () => ({
            id: userID,
            name: user?.name,
            image: user?.picture,
        }),
        [user, userID]
    );

    useEffect(() => {
        // Handle redirection to message setup if message settings doesnt exist
        if (
            activeEntity &&
            isSuccess &&
            (!messageSettings || !messageSettings?.enabled)
        ) {
            navigate('setup', {
                replace: true,
            });
        }
    }, [navigate, activeEntity, isSuccess, messageSettings]);

    const handleSelectChannel = async (
        chatClient: any,
        channel: MessageChannel
    ) => {
        const splitChannelID = channel.cid.split(':');

        const streamChannel = chatClient?.channel(
            splitChannelID[0],
            splitChannelID[1]
        );

        if (!streamChannel) return;

        await streamChannel.watch();

        setSelectedChannel(streamChannel);
    };

    if (!streamToken) {
        return <Loader />;
    }

    return (
        <MessagesView
            channels={channelsData?.data}
            entityID={activeEntity?.entityID}
            entityType={activeEntity?.entityType}
            filters={filters}
            options={options}
            isLoading={loadingChannels || loadingSettings}
            messageSettings={messageSettings || undefined}
            selectedChannel={selectedChannel}
            showSettings={showSettings}
            sort={sort}
            streamToken={streamToken}
            userData={userData}
            onSelectChannel={handleSelectChannel}
            onShowSettings={setShowSettings}
        />
    );
};

export default MessagesContainer;
