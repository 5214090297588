import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    Formik,
    FormikErrors,
    FormikHelpers,
    FormikProps,
    FormikValues,
} from 'formik';
import { pick, isEqual, orderBy } from 'lodash';

import {
    teamAgeOptions,
    teamGenderOptions,
    teamGradeOptions,
} from '../../util/constants';
import { Mixpanel } from '../../util/mixpanel';

import { useCreateTeamMutation } from '../../api/teams';
import {
    useGetEntityCategoriesQuery,
    useGetSportsQuery,
} from '../../api/sports';
import {
    useCreateOrganisationTeamMutation,
    useGetOrganisationQuery,
} from '../../api/organisations';

import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { Skeleton } from 'primereact/skeleton';
import { Tooltip } from 'primereact/tooltip';

import EntityPreview from '../../components/EntityPreview';
import EntityDesigner from '../../components/EntityDesigner';
import FormActions from '../../components/FormActions';
import FormFields from '../../components/FormFields';
import FormGroup from '../../components/FormGroup';
import Icon from '../../components/Icon';
import RookieButton from '../../components/RookieButton';

import { FormSubmitCallback, INPUT_TYPES } from '../../types/common';
import { TeamForm as TeamFormTypes } from '../../types/team';
import { Design } from '../../types/design';
import { Sport } from '../../types/sports';

interface Props {
    initialValues: TeamFormTypes;
    userID?: string;
    organisationID?: string;
    onCancel?: () => void;
    onSubmitComplete: FormSubmitCallback;
}

const requiredFields: Array<keyof TeamFormTypes> = [
    'entityCategoryID',
    'design',
    'teamName',
    'shortName',
    'age',
    'gender',
];

const TeamForm = (props: Props) => {
    const {
        initialValues,
        onCancel,
        organisationID,
        userID,
        onSubmitComplete,
    } = props;

    const formRef = useRef<FormikProps<TeamFormTypes>>(null);
    const [selectedSport, setSelectedSport] = useState<string | undefined>();
    const [showEditor, setShowEditor] = useState(false);

    const [createUserTeam] = useCreateTeamMutation();
    const [createOrganisationTeam] = useCreateOrganisationTeamMutation();

    const createTeam = organisationID ? createOrganisationTeam : createUserTeam;

    const { data: organisationData } = useGetOrganisationQuery(
        { organisationID },
        { skip: !organisationID }
    );

    const { data: sports, isLoading: isLoadingSports } = useGetSportsQuery();

    const { data: categories, isLoading: isLoadingCategories } =
        useGetEntityCategoriesQuery(
            { sportID: selectedSport || '' },
            { skip: !selectedSport }
        );

    useEffect(() => {
        if (organisationData?.data) {
            setSelectedSport(organisationData.data.entitySportID);
        }
    }, [organisationData]);

    const handleSubmit = async (
        data: TeamFormTypes,
        { setSubmitting }: FormikHelpers<TeamFormTypes>
    ) => {
        try {
            // Set formik to submitting
            setSubmitting(true);

            const response = await createTeam({
                seasonName: new Date().getFullYear().toString(),
                entitySportID: selectedSport,
                ...{
                    [organisationID ? 'organisationID' : 'userID']:
                        organisationID ? organisationID : userID,
                },
                ...data,
            });

            if ('error' in response) {
                throw new Error('An error has occurred.');
            }

            onSubmitComplete && onSubmitComplete('success', 'create', response);

            Mixpanel.track('Create Team');
        } catch (error) {
            onSubmitComplete &&
                onSubmitComplete('error', 'create', undefined, error);
        } finally {
            setSubmitting(false);
        }
    };

    const validate = (values: FormikValues) => {
        const errors: FormikErrors<TeamFormTypes> = {};

        requiredFields.forEach((field) => {
            if (!values[field]) {
                errors[field as keyof TeamFormTypes] = 'Field cannot be blank';
            }
        });

        return errors;
    };

    const entityCategories = useMemo(() => {
        const options = categories?.data
            ?.filter((cat) => cat.entityType === 'teams')
            .map((cat) => ({
                label: cat.entityCategoryName,
                value: cat.entityCategoryID,
            }));

        return orderBy(options, 'label');
    }, [categories]);

    useEffect(() => {
        // If organisation team, auto select matching entity categoryID
        if (organisationData?.data.entityCategoryID) {
            const orgCatID = entityCategories.find((cat) =>
                cat.value.includes(organisationData?.data.entityCategoryID)
            );

            if (formRef.current && orgCatID) {
                formRef.current.setFieldValue(
                    'entityCategoryID',
                    orgCatID.value
                );
            }
        }
    }, [entityCategories, organisationData]);

    const sportOptions = useMemo(() => {
        const options = sports?.data.map((sport: Sport) => ({
            label: sport.sportName,
            value: sport.sportID,
        }));

        return orderBy(options, 'label');
    }, [sports]);

    const getEntityOptions = useCallback(
        (entityCategoryID?: string) => {
            let entityOptions = {
                age: teamAgeOptions,
                gender: teamGenderOptions,
                grade: teamGradeOptions,
            } as {
                [key: string]: Array<{ label: string; value: string }>;
            };

            if (entityCategoryID) {
                const category = categories?.data.find(
                    (cat) => cat.entityCategoryID === entityCategoryID
                );

                if (
                    category?.validProperties &&
                    category.validProperties.length > 0
                ) {
                    category.validProperties.forEach((prop) => {
                        if (prop.propertyKey && prop.propertyValues) {
                            entityOptions[prop.propertyKey] =
                                prop.propertyValues.map((val) => ({
                                    label: val,
                                    value: val,
                                }));
                        }
                    });
                }
            }

            return entityOptions;
        },
        [categories]
    );

    return (
        <Formik
            initialValues={pick(initialValues, requiredFields)}
            validate={validate}
            onSubmit={handleSubmit}
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={false}
            innerRef={formRef}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
                initialValues,
            }) => {
                const options = getEntityOptions(values.entityCategoryID);

                return (
                    <>
                        <Dialog
                            header="Kit Designer"
                            onHide={() => setShowEditor(false)}
                            visible={showEditor}
                        >
                            <EntityDesigner
                                initialValues={values.design}
                                onCancel={() => setShowEditor(false)}
                                onSubmit={(value: Design) => {
                                    setFieldValue('design', value);
                                    setFieldTouched('design', true);
                                    setShowEditor(false);
                                }}
                                submitLabel="Save Design"
                            />
                        </Dialog>
                        <form className="form" onSubmit={handleSubmit}>
                            <FormFields>
                                <FormGroup className="kit-edit_wrap">
                                    <div
                                        className="kit-edit"
                                        onClick={() => setShowEditor(true)}
                                    >
                                        <EntityPreview design={values.design} />
                                        <div className="edit-badge">
                                            <Icon name="edit" size="small" />
                                        </div>
                                    </div>
                                </FormGroup>
                                {!organisationID && (
                                    <FormGroup label="Sport" htmlFor="sportID">
                                        {isLoadingSports ? (
                                            <Skeleton height="44px" />
                                        ) : (
                                            <Dropdown
                                                value={selectedSport}
                                                options={sportOptions}
                                                onChange={(e) =>
                                                    setSelectedSport(e.value)
                                                }
                                                id="sportID"
                                                placeholder="Team Sport"
                                                name="sportID"
                                                required
                                            />
                                        )}
                                    </FormGroup>
                                )}
                                {selectedSport && (
                                    <FormGroup
                                        label="Team Type"
                                        htmlFor="entityCategoryID"
                                        error={errors.entityCategoryID}
                                        showError={
                                            !!errors.entityCategoryID &&
                                            touched.entityCategoryID
                                        }
                                    >
                                        {isLoadingCategories ? (
                                            <Skeleton height="44px" />
                                        ) : (
                                            <Dropdown
                                                id="entityCategoryID"
                                                name="entityCategoryID"
                                                value={values.entityCategoryID}
                                                options={entityCategories}
                                                onChange={(e) => {
                                                    setFieldValue(
                                                        'entityCategoryID',
                                                        e.value
                                                    );
                                                    setFieldTouched(
                                                        'entityCategoryID',
                                                        true
                                                    );

                                                    if (
                                                        !options.age.find(
                                                            (o) =>
                                                                o.value ===
                                                                values.age
                                                        )
                                                    ) {
                                                        setFieldValue(
                                                            'age',
                                                            ''
                                                        );
                                                    }

                                                    if (
                                                        !options.gender.find(
                                                            (o) =>
                                                                o.value ===
                                                                values.gender
                                                        )
                                                    ) {
                                                        setFieldValue(
                                                            'gender',
                                                            ''
                                                        );
                                                    }
                                                }}
                                            />
                                        )}
                                    </FormGroup>
                                )}
                                <FormGroup
                                    label="Team Name"
                                    htmlFor="teamName"
                                    error={errors.teamName}
                                    showError={
                                        !!errors.teamName && touched.teamName
                                    }
                                >
                                    <InputText
                                        id="teamName"
                                        name="teamName"
                                        type={INPUT_TYPES.text}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Team Name"
                                        value={values.teamName}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup
                                    label={
                                        <>
                                            Short Name
                                            <Tooltip target=".shortName-help" />
                                            <span
                                                className="help-tip shortName-help"
                                                data-pr-tooltip="Short Name can contain only alphabetical characters."
                                                data-pr-position="top"
                                            >
                                                <Icon
                                                    name="info"
                                                    size="small"
                                                />
                                            </span>
                                            <small className="form-label--right">
                                                {values.shortName.length}
                                                /4
                                            </small>
                                        </>
                                    }
                                    htmlFor="shortName"
                                    error={errors.shortName}
                                    showError={
                                        !!errors.shortName && touched.shortName
                                    }
                                >
                                    <InputText
                                        id="shortName"
                                        maxLength={4}
                                        name="shortName"
                                        onBlur={handleBlur}
                                        onChange={(event) => {
                                            let shortNameVal =
                                                event.target.value;
                                            shortNameVal = shortNameVal.replace(
                                                /[^a-zA-Z]/g,
                                                ''
                                            );

                                            shortNameVal =
                                                shortNameVal.toUpperCase();

                                            setFieldValue(
                                                'shortName',
                                                shortNameVal
                                            );

                                            setFieldTouched('shortName', true);
                                        }}
                                        placeholder="Short Name"
                                        required
                                        type={INPUT_TYPES.text}
                                        value={values.shortName}
                                    />
                                </FormGroup>
                                <FormGroup
                                    label="Team Age"
                                    htmlFor="age"
                                    error={errors.age}
                                    showError={!!errors.age && touched.age}
                                >
                                    <Dropdown
                                        inputId="age"
                                        name="age"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Team Age"
                                        value={values.age}
                                        options={options.age}
                                    />
                                </FormGroup>
                                <FormGroup
                                    label="Team Gender"
                                    htmlFor="gender"
                                    error={errors.gender}
                                    showError={
                                        !!errors.gender && touched.gender
                                    }
                                >
                                    <Dropdown
                                        id="gender"
                                        name="gender"
                                        value={values.gender}
                                        options={options?.gender}
                                        placeholder="Team Gender"
                                        onChange={(e) => {
                                            setFieldValue('gender', e.value);
                                            setFieldTouched('gender', true);
                                        }}
                                    />
                                </FormGroup>
                                <FormGroup
                                    label="Team Grade (optional)"
                                    htmlFor="grade"
                                >
                                    <Dropdown
                                        inputId="grade"
                                        name="grade"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Team Grade"
                                        value={values.grade}
                                        options={options?.grade}
                                    />
                                </FormGroup>
                            </FormFields>
                            <FormActions
                                start={
                                    <RookieButton
                                        type="button"
                                        label="Cancel"
                                        text={true}
                                        disabled={isSubmitting}
                                        severity="secondary"
                                        onClick={() => onCancel && onCancel()}
                                    />
                                }
                                end={
                                    <RookieButton
                                        type="submit"
                                        disabled={
                                            isSubmitting ||
                                            isEqual(values, initialValues)
                                        }
                                        loading={isSubmitting}
                                        label="Create Team"
                                    />
                                }
                            />

                            {isSubmitting && (
                                <div className="form-overlay">
                                    <ProgressBar mode="indeterminate" />
                                </div>
                            )}
                        </form>
                    </>
                );
            }}
        </Formik>
    );
};

export default TeamForm;

TeamForm.defaultProps = {
    formHasActions: true,
    initialValues: {
        teamName: '',
        shortName: '',
        entityCategoryID: '',
        age: '',
        gender: '',
        design: {
            template: 'team-avatar-01',
            primary: '#000000',
            secondary: '#000000',
            tertiary: '#000000',
        },
    },
};
