import { useMemo } from 'react';
import { useGetRolesQuery } from '../api/core';
import { UseQueryResult } from '../types/api';
import { Role } from '../types/roles';

const useGetRole = (
    userID?: string,
    entityID?: string
): UseQueryResult<Role> => {
    const rolesData = useGetRolesQuery(
        { userID: userID ?? '', limit: 2000 },
        {
            skip: !userID,
        }
    );

    const role = useMemo(
        () => rolesData?.data?.data.find((role) => role.entityID === entityID),
        [entityID, rolesData]
    );

    return {
        ...rolesData,
        currentData: role,
        data: role,
    };
};

export default useGetRole;
