import { useRef } from 'react';
import ReportTable from '../components/ReportTable';

import PageContainer from '../../../layout/PageContainer';
import PageHeader from '../../../layout/PageHeader';

import FormGroup from '../../../components/FormGroup';
import RookieButton from '../../../components/RookieButton';
import PlayerAlertSettings from '../components/PlayerAlertSettings';
import EventSelect from '../components/EventSelect';
import SeasonSelect from '../components/SeasonSelect';
import ColumnEditPanel from '../components/ColumnEditPanel';
import { SelectItem } from 'primereact/selectitem';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { OverlayPanel } from 'primereact/overlaypanel';
import { SelectButton } from 'primereact/selectbutton';
import { Toolbar } from 'primereact/toolbar';

import {
    ReportColumn,
    ReportDataFilters,
    ReportDataViews,
    ReportState,
    ReportType,
} from '../../../types/reports';
import { Team } from '../../../types/team';
import { PlayerAlertOptions } from '../../../types/game';
import { RookieProduct } from '../../../types/licences';

interface Props {
    category: string;
    columns: ReportColumn[];
    customisableColumns: ReportColumn[];
    data: any;
    emptyColumns: string[];
    filters: ReportDataFilters;
    hideEmptyColumns: boolean;
    hiddenColumns: string[];
    loading: boolean;
    options: Record<string, SelectItem[]>;
    playerAlert: PlayerAlertOptions;
    reportData: ReportState<any>;
    reportType: ReportType;
    season: string;
    team?: Team;
    views: ReportDataViews;
    onCategoryChange: (value: any) => void;
    onColumnsChange: (value: any) => void;
    onExport: (dataTable: DataTable<any>) => void;
    onFilterChange: (key: string, value: any) => void;
    onHiddenColumnChange: (value: any) => void;
    onHideEmptyColumns: (hide: boolean) => void;
    onPlayerAlertChange: (playerAlert: PlayerAlertOptions) => void;
    onSeasonChange: (value: string) => void;
    onViewsChange: (key: string, value: any) => void;
}

const TeamGameTimeReportView = ({
    category,
    columns,
    customisableColumns,
    data,
    emptyColumns,
    filters,
    hideEmptyColumns,
    hiddenColumns,
    loading,
    options,
    playerAlert,
    reportData,
    reportType,
    season,
    team,
    views,
    onCategoryChange,
    onColumnsChange,
    onExport,
    onFilterChange,
    onHiddenColumnChange,
    onHideEmptyColumns,
    onPlayerAlertChange,
    onSeasonChange,
    onViewsChange,
}: Props) => {
    const dataTable = useRef<DataTable<any>>(null);
    const columnEdit = useRef<OverlayPanel>(null);
    const playerAlertOverlay = useRef<OverlayPanel>(null);

    const leftToolbar = (
        <>
            <FormGroup label="Season">
                <SeasonSelect
                    onChange={(e) => onSeasonChange(e.value)}
                    value={season}
                    placeholder="Select Season"
                    teamID={team?.teamID}
                    initialOptions={
                        team?.defaultSeasonDetails && [
                            team.defaultSeasonDetails,
                        ]
                    }
                />
            </FormGroup>
            <FormGroup label="Event">
                <EventSelect
                    key={season}
                    onChange={(e) => onFilterChange('event', e.value)}
                    value={filters.event}
                    placeholder="Entire Season"
                    teamID={team?.teamID}
                    reportType="Game"
                    seasonID={season}
                    showClear={true}
                    maxSelectedLabels={1}
                    selectAllLabel="Select All"
                    selectedItemsLabel={`${filters.event?.length} Selected`}
                    multiselect
                />
            </FormGroup>
            <FormGroup label="Period">
                <Dropdown
                    value={filters.period}
                    options={[{ label: 'All', value: 0 }, ...options.periods]}
                    onChange={(e) => onFilterChange('period', e.value)}
                />
            </FormGroup>
        </>
    );

    const rightToolbar = (
        <div className="p-button-group">
            <SelectButton
                value={views.timeDisplay}
                onChange={(e) => onViewsChange('timeDisplay', e.value)}
                options={options.timeDisplay}
                allowEmpty={false}
                pt={{
                    button: {
                        className: 'p-button-sm',
                    },
                }}
            />
            <SelectButton
                value={views.dataType}
                onChange={(e) => onViewsChange('dataType', e.value)}
                options={options.dataType}
                disabled={
                    (filters.event && filters.event.length === 1) ||
                    views.timeDisplay === 'Percentage'
                }
                allowEmpty={false}
                pt={{
                    button: {
                        className: 'p-button-sm',
                    },
                }}
            />
            {[ReportType.gameTimeAdvanced, ReportType.gameTimeExpert].includes(
                reportType
            ) && (
                <RookieButton
                    onClick={(e) =>
                        playerAlertOverlay.current &&
                        playerAlertOverlay.current.toggle(e)
                    }
                    label="PGT"
                    outlined={playerAlert.mode === 'None'}
                    badge={
                        playerAlert.mode !== 'None'
                            ? `${Math.round(playerAlert.target * 100)}%`
                            : ''
                    }
                    size="small"
                    severity="secondary"
                />
            )}
            <RookieButton
                label="Customise"
                onClick={(e) => columnEdit?.current?.toggle(e)}
                size="small"
                outlined
                severity="secondary"
                icon="tune"
            />
        </div>
    );

    return (
        <PageContainer>
            <PageHeader
                title="Time Report"
                actions={[
                    {
                        onClick: () =>
                            dataTable.current && onExport(dataTable.current),
                        label: 'Export',
                        severity: 'secondary',
                        icon: 'download',
                    },
                ]}
            />
            <Toolbar
                className="report-toolbar"
                start={leftToolbar}
                end={rightToolbar}
            />
            <ReportTable
                ref={dataTable}
                data={data}
                columns={columns}
                pinnedColumns={[
                    'playerAlertTarget',
                    'playerNumber',
                    'playerName',
                ]}
                isLoading={loading || reportData.isLoading}
                product={RookieProduct.GameDay}
                dataTableProps={{
                    sortField: 'player.uniformNumber',
                    sortOrder: 1,
                    exportFilename: `${team?.teamName} - Game Time Report`,
                }}
            />

            <OverlayPanel ref={playerAlertOverlay} dismissable>
                <PlayerAlertSettings
                    onModeChange={(mode) =>
                        onPlayerAlertChange({
                            target: playerAlert.target,
                            mode,
                        })
                    }
                    onTargetChange={(target) =>
                        onPlayerAlertChange({ target, mode: playerAlert.mode })
                    }
                    mode={playerAlert.mode}
                    target={playerAlert.target}
                />
            </OverlayPanel>

            <OverlayPanel ref={columnEdit} className="column-popover">
                <ColumnEditPanel
                    hideEmptyColumns={hideEmptyColumns}
                    onHideEmptyColumns={onHideEmptyColumns}
                    selectedCategory={category}
                    categories={options.categories}
                    onCategoryChange={onCategoryChange}
                    emptyColumns={emptyColumns}
                    columns={customisableColumns}
                    hiddenColumns={hiddenColumns}
                    onColumnsChange={onColumnsChange}
                    onHideColumn={onHiddenColumnChange}
                />
            </OverlayPanel>
        </PageContainer>
    );
};

export default TeamGameTimeReportView;
