import { useState, useEffect, useMemo, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { groupBy, isObject, sortBy } from 'lodash';

import { useLazyGetGameInterchangeOnOffReportQuery } from '../../../api/reports';
import { useLazyGetPlayersQuery } from '../../../api/players';
import { useGetTeamQuery } from '../../../api/teams';
import { useGetSeasonEventsQuery } from '../../../api/events';

import { DataTable } from 'primereact/datatable';

import { config } from '../reportConfig';
import { Mixpanel } from '../../../util/mixpanel';
import { defaultReportState } from '../constants';
import { formatTime, toISOStringWithTimezone } from '../../../util/helper';

import TeamInterchangeOnOffReportView from './TeamInterchangeOnOffReportView';

import { Player } from '../../../types/team';
import { BaseEntityType } from '../../../types/common';
import {
    InterchangeStat,
    ReportState,
    TimeFormat,
} from '../../../types/reports';

const TeamInterchangeOnOffReportContainer = () => {
    const { teamID } = useParams();
    const [searchParams] = useSearchParams();

    const eventParam = searchParams.get('event');
    const seasonParam = searchParams.get('season');

    // Ref hooks
    const timestampRef = useRef(Date.now()).current; // for report cache busting

    // State hooks
    const [reportData, setReportData] = useState<{
        [key: string]: ReportState<InterchangeStat[]>;
    }>({});

    const [timeFormat, setTimeFormat] = useState<TimeFormat>(
        TimeFormat.Duration
    );

    const [event, setEvent] = useState<string>(eventParam || '');
    const [season, setSeason] = useState<string>(seasonParam || '');
    const [players, setPlayers] = useState<Player[]>([]);

    // API hooks
    const [fetchPlayers, { originalArgs: playerArgs }] =
        useLazyGetPlayersQuery();

    const [requestReportData] = useLazyGetGameInterchangeOnOffReportQuery();

    const teamData = useGetTeamQuery(
        { teamID: teamID || '', expand: 'defaultSeasonDetails' },
        { skip: !teamID }
    );

    const team = useMemo(() => teamData.data?.data, [teamData]);

    // To prevent pagination caching issues
    const now = useRef(new Date()).current;

    const eventData = useGetSeasonEventsQuery(
        {
            entityType: BaseEntityType.teams,
            entityID: team?.teamID || '',
            seasonID: season,
            to: toISOStringWithTimezone(now),
            limit: '1',
        },
        {
            skip: !team || !season,
        }
    );

    const events = useMemo(() => eventData.data?.data, [eventData]);

    const loadPlayers = (cursor: string, status = 'Active') => {
        if (teamID) {
            fetchPlayers(
                {
                    cursor,
                    status,
                    teamID,
                },
                true
            )
                .then((response) => {
                    if (response.data) {
                        if (response?.data?.data) {
                            const d = response.data?.data;

                            setPlayers((prev) => [...prev, ...d]);
                        }

                        // Auto paginate teams
                        if (
                            response.data.lastEvaluatedKey.cursor &&
                            response.data.lastEvaluatedKey.cursor !== cursor
                        ) {
                            loadPlayers(response.data.lastEvaluatedKey.cursor);
                        }
                    }
                })
                .catch((error) => {
                    console.error(
                        `Error fetching events for team ${teamID}`,
                        error
                    );
                });
        }
    };

    // Set the season to the first available season
    useEffect(() => {
        if (!season && team) {
            setSeason(team.defaultSeasonID);
        }
    }, [team, season]);

    // Set the event to the first event of the season
    useEffect(() => {
        if (!event && events && events.length > 0) {
            setEvent(events[0].eventID);
        }
    }, [event, events]);

    // Set the event to the first event of the season
    useEffect(() => {
        if (
            eventData.status === 'fulfilled' &&
            events &&
            events.length > 0 &&
            !event
        ) {
            setEvent(events[0].eventID);
        }
    }, [season, events, event, eventData]);

    useEffect(() => {
        if (teamID && season && event && !reportData[season]) {
            const fetchReport = async () => {
                try {
                    // Set loading states for both reports
                    setReportData((prev) => ({
                        ...prev,
                        [season]: {
                            ...defaultReportState,
                            isLoading: true,
                            isUninitialized: false,
                        },
                    }));

                    // Fetch both report URLs concurrently
                    const { data } = await requestReportData({
                        eventID: event,
                        teamID: teamID,
                        sessionID: timestampRef,
                    }).unwrap();

                    const reportUrl = data?.objectURL;

                    const response = await fetch(reportUrl);

                    if (!response.ok) {
                        throw new Error(`Failed to load report`);
                    }

                    const report = await response.json();

                    setReportData((prev) => ({
                        ...prev,
                        [season]: {
                            ...prev[season],
                            isLoading: false,
                            data: report,
                        },
                    }));
                } catch (error) {
                    // Handle errors by setting the error state for both reports
                    const errorMessage =
                        isObject(error) && 'message' in error
                            ? error.message
                            : error;

                    setReportData((prev) => ({
                        ...prev,
                        [season]: {
                            ...prev[season],
                            isLoading: false,
                            isError: true,
                            error: errorMessage,
                        },
                    }));
                }
            };

            // Fetch the report
            fetchReport();
        }
    }, [teamID, season, event, reportData, requestReportData, timestampRef]);

    // Fetch Active Players
    useEffect(() => {
        loadPlayers('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Fetch Archived Players if required
    useEffect(() => {
        if (
            reportData[season] &&
            !reportData[season].isUninitialized &&
            !reportData[season].isSuccess &&
            playerArgs?.status === 'Active'
        ) {
            const hasMissingPlayers = reportData[season].data?.some(
                (stat: InterchangeStat) => !stat.player
            );

            if (hasMissingPlayers) {
                loadPlayers('', 'Archived');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportData, season, playerArgs]);

    useEffect(() => {
        if (players && reportData[season] && reportData[season].data) {
            setReportData((prev) => ({
                ...prev,
                [season]: {
                    ...prev[season],
                    data: (prev[season].data || []).map(
                        (stat: InterchangeStat) => {
                            const player = players.find(
                                (p) => p.playerID === stat.playerID
                            );
                            return {
                                ...stat,
                                player,
                                playerName:
                                    player &&
                                    `${player.firstName} ${player.lastName}`,
                            };
                        }
                    ),
                },
            }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [players, season]);

    const exportCSV = (dataTable: DataTable<any>) => {
        dataTable && dataTable.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: `Team Interchange On/Off Report`,
        });
    };

    const data = useMemo(() => {
        const rawData = reportData[season]?.data || [];

        // merge endTime part of the same rotation
        const merged = rawData.reduce((acc, item) => {
            const key = `${item.playerID}-${item.quarter}-${item.rotation}`;

            acc[key] = acc[key] || item;
            acc[key].endTime = item.endTime;

            return acc;
        }, {} as { [keyof: string]: InterchangeStat });

        return Object.values(merged);
    }, [reportData, season]);

    const maxRotationsPerPeriod = useMemo(() => {
        const groupByPeriod = groupBy(data, 'quarter');

        return Object.keys(groupByPeriod).reduce((res, key) => {
            const items = groupByPeriod[key];
            const groupByPlayer = groupBy(items, 'playerID');

            res[key] = Object.values(groupByPlayer).reduce((a, b) => {
                let count = b.length;

                if (b[0].position === 'Bench') {
                    count++;
                }

                return Math.max(a, count);
            }, -Infinity);

            return res;
        }, {} as any);
    }, [data]);

    const tableData = useMemo(() => {
        const grouped = groupBy(data, 'playerID');

        const updatedData = Object.keys(grouped).reduce((result, key) => {
            const rotations = grouped[key];
            let collect = {} as any;

            // Used to reindex per period
            let index = 1;

            rotations.forEach((item) => {
                if (collect[`p${item.quarter}-r${index}`]) {
                    index++;
                } else {
                    index = 1;
                }

                // Add empty dataset when starting on bench to offset initial ON column
                if (item.position === 'Bench' && index === 1) {
                    collect[`p${item.quarter}-r${index}`] = {
                        start: null,
                        end: null,
                        [TimeFormat.Timestamp]: 'X',
                        [TimeFormat.Duration]: 'X',
                        position: item.position,
                    };
                    index++;
                }

                collect[`p${item.quarter}-r${index}`] = {
                    start: item.startTime,
                    end: item.endTime,
                    [TimeFormat.Timestamp]: formatTime(item.startTime),
                    [TimeFormat.Duration]: formatTime(
                        item.endTime - item.startTime
                    ),
                    position: item.position,
                };
            });

            result[key] = collect;

            return result;
        }, {} as { [key: string]: any });

        const updatedDataArr = Object.entries(updatedData).map(
            ([key, value]) => {
                const player = players.find((p) => p.playerID === key);
                return {
                    ...value,
                    playerID: key,
                    player,
                    playerName:
                        player && `${player.firstName} ${player.lastName}`,
                };
            }
        );

        return sortBy(
            updatedDataArr,
            (s: any) => s.player && Number(s.player.uniformNumber)
        );
    }, [data, players]);

    const columns = useMemo(() => {
        let cols = [config.playerNumber, config.playerName, config.playerGroup];

        Object.keys(maxRotationsPerPeriod).forEach((period, pIndex) =>
            Array(maxRotationsPerPeriod[period])
                .fill(undefined)
                .forEach((val, idx) => {
                    cols.push({
                        id: `p${period}-r${idx + 1}.${timeFormat}`,
                        shortName: `P${pIndex + 1} - R${idx + 1}`,
                        name: `Period ${pIndex + 1} - Rotation ${idx + 1}`,
                        field: `p${period}-r${idx + 1}.${timeFormat}`,
                    });
                })
        );

        return cols;
    }, [maxRotationsPerPeriod, timeFormat]);

    return (
        <TeamInterchangeOnOffReportView
            columns={columns}
            data={tableData}
            event={event}
            eventOptions={events}
            maxRotationsPerPeriod={maxRotationsPerPeriod}
            season={season}
            team={team}
            timeFormat={timeFormat}
            onEventChange={(value) => setEvent(value)}
            onExport={exportCSV}
            onSeasonChange={(value) => setSeason(value)}
            onTimeFormatChange={(value) => setTimeFormat(value)}
        />
    );
};

export default TeamInterchangeOnOffReportContainer;
