import { generatePath } from 'react-router-dom';
import { createApi } from '@reduxjs/toolkit/query/react';

import { apiEndpoints } from './apiEndpoints';
import { baseQueryWithReauth } from '../util/baseQuery';

import { EntityStructure } from '../types/common';
import { DesignOptions, DesignsResponse } from '../types/design';
import { EntityRolesResponse, RolesResponse } from '../types/roles';
import { Team, TeamResponse } from '../types/team';
import { MeResponse, UserResponse } from '../types/user';
import { OrganisationResponse } from '../types/organisation';
import { AssociationResponse } from '../types/association';
import { isEqual } from 'lodash';

export const coreApi = createApi({
    reducerPath: 'coreApi',
    baseQuery: (args, api, extraOptions) =>
        baseQueryWithReauth(
            args,
            api,
            extraOptions,
            process.env.REACT_APP_API_URL
        ),
    tagTypes: ['Role'],
    endpoints: (builder) => ({
        getMe: builder.query<MeResponse, void>({
            query: () => ({
                url: apiEndpoints.getMe.url,
                method: apiEndpoints.getMe.method,
            }),
        }),
        getRoles: builder.query<
            RolesResponse,
            {
                userID: string;
                cursor?: string;
                limit?: number;
                params?: { [key: string]: string };
            }
        >({
            query: ({ userID, cursor, limit, params }) => ({
                url: generatePath(apiEndpoints.getRoles.url, { userID }),
                method: apiEndpoints.getRoles.method,
                params: {
                    cursor,
                    limit,
                    ...params,
                },
            }),
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ entityID }) => ({
                              type: 'Role' as const,
                              entityID,
                          })),
                          'Role',
                      ]
                    : ['Role'];
            },
            serializeQueryArgs: ({
                endpointName,
                queryArgs: { cursor, limit, params, ...args },
            }) => {
                return `${endpointName}(${JSON.stringify(args)})`;
            },
            merge: (currentCache, newItems, args) => {
                if (currentCache && newItems) {
                    if (
                        args.arg.cursor &&
                        currentCache?.lastEvaluatedKey !==
                            newItems?.lastEvaluatedKey
                    ) {
                        currentCache.data = [
                            ...currentCache.data,
                            ...newItems.data,
                        ];
                        currentCache.lastEvaluatedKey.cursor =
                            newItems.lastEvaluatedKey.cursor;
                    } else {
                        currentCache.data = newItems.data;
                        currentCache.lastEvaluatedKey =
                            newItems.lastEvaluatedKey;
                    }
                }
            },

            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
        }),
        getEntityRoles: builder.query<
            EntityRolesResponse,
            EntityStructure & { assignable: boolean }
        >({
            query: ({ entityType, entityID, assignable }) => ({
                url: generatePath(apiEndpoints.getEntityRoles.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.getEntityRoles.method,
                params: {
                    assignable,
                },
            }),
        }),
        getEntity: builder.query<
            AssociationResponse & OrganisationResponse & TeamResponse,
            EntityStructure & { expand?: string }
        >({
            query: ({ entityType, entityID, ...params }) => ({
                url: generatePath(apiEndpoints.getEntity.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.getEntity.method,
                params,
            }),
        }),
        getUserDetails: builder.query<UserResponse, { userID: string }>({
            query: ({ userID }) => ({
                url: generatePath(apiEndpoints.getUserDetails.url, { userID }),
                method: apiEndpoints.getUserDetails.method,
            }),
        }),
        getDesignOptions: builder.query<DesignsResponse, void>({
            query: () => ({
                url: apiEndpoints.getDesignOptions.url,
                method: apiEndpoints.getDesignOptions.method,
            }),
        }),
        getEntityKit: builder.query<string, EntityStructure>({
            query: ({ entityType, entityID }) => ({
                url: generatePath(apiEndpoints.getEntityKit.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.getEntityKit.method,
                responseHandler: 'text',
            }),
        }),
        updateEntityKit: builder.mutation<
            Team,
            EntityStructure & { data: DesignOptions }
        >({
            query: ({ entityType, entityID, data }) => ({
                url: generatePath(apiEndpoints.updateEntityKit.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.updateEntityKit.method,
                body: data,
            }),
        }),
        transferEntityOwnership: builder.mutation<
            any,
            EntityStructure & { data: any }
        >({
            query: ({ entityType, entityID, data }) => ({
                url: generatePath(apiEndpoints.transferEntityOwnership.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.transferEntityOwnership.method,
                body: data,
            }),
        }),
    }),
});

export const {
    useGetMeQuery,
    useLazyGetMeQuery,
    useGetRolesQuery,
    useLazyGetRolesQuery,
    useGetEntityRolesQuery,
    useGetEntityQuery,
    useGetUserDetailsQuery,
    useLazyGetUserDetailsQuery,
    useGetDesignOptionsQuery,
    useGetEntityKitQuery,
    useUpdateEntityKitMutation,
    useTransferEntityOwnershipMutation,
} = coreApi;
