import { useParams } from 'react-router-dom';

import AssociationLicenceSingleView from './AssociationLicenceSingleView';

import usePermission from '../../hooks/usePermission';

import {
    useCreateBillingSessionMutation,
    useGetLicenceGroupLicencesQuery,
} from '../../api/licences';

import { Roles } from '../../types/roles.js';
import { Route } from '../../types/route.js';
import { User } from '../../types/user.js';
import { Permissions } from '../../types/permissions';
import { BaseEntityType } from '../../types/common';

interface Props {
    roles: Roles;
    route: Route;
    user: User;
}

const AssociationLicenceSingleContainer = (props: Props) => {
    const { user } = props;
    const { licenceGroupID, associationID } = useParams();

    const { checkPermission } = usePermission(associationID ?? '');

    const [fetchBillingUrl] = useCreateBillingSessionMutation();

    const { data: licencesRaw } = useGetLicenceGroupLicencesQuery(
        {
            entityType: BaseEntityType.associations,
            entityID: associationID ?? '',
            licenceGroupID: licenceGroupID ?? '',
        },
        {
            skip: !associationID || !licenceGroupID,
        }
    );

    const handleManageBilling = () => {
        fetchBillingUrl({
            userID: user.userID,
            returnURL: window.location.href,
        }).then((response) => {
            if (!('error' in response)) {
                window.location.href = response.data.data.url;
            }
        });
    };

    const licences = licencesRaw?.data || [];

    const permissions = {
        canCreate: checkPermission([
            Permissions.MANAGE_ASSOCIATIONS,
            Permissions.MANAGE_ASSOCIATIONS_ALL,
        ]),
        canDelete: checkPermission([
            Permissions.MANAGE_ASSOCIATIONS,
            Permissions.MANAGE_ASSOCIATIONS_ALL,
        ]),
        canEdit: checkPermission([
            Permissions.PUT_ORGANISATIONS,
            Permissions.MANAGE_ASSOCIATIONS,
            Permissions.MANAGE_ASSOCIATIONS_ALL,
        ]),
        canView: checkPermission([
            Permissions.GET_ASSOCIATIONS,
            Permissions.GET_ASSOCIATIONS_ALL,
            Permissions.MANAGE_ASSOCIATIONS,
            Permissions.MANAGE_ASSOCIATIONS_ALL,
        ]),
    };

    return (
        <AssociationLicenceSingleView
            licences={licences}
            permissions={permissions}
            onManageBilling={handleManageBilling}
        />
    );
};

export default AssociationLicenceSingleContainer;
