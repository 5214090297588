import { useEffect, useState } from 'react';
import {
    Route,
    Routes,
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { useGetMeQuery } from './api/core';

import { isValidToken, parseJwt } from './util/helper';
import { Mixpanel } from './util/mixpanel';
import { sec } from './util/security';

import { routes } from './routes/routes';
import AccountSelector from './routes/AccountSelector';
import ProtectedRoute from './routes/ProtectedRoute';
import SessionRoute from './routes/SessionRoute';

import ErrorDisplay from './components/ErrorDisplay';
import Layout from './layout/Layout';

import { ERROR_TYPES } from './types/common';
import { Route as RouteType } from './types/route';

import './assets/scss/main.scss';

import {
    USE_CUSTOM_TOKEN,
    CUSTOM_TOKEN_ID,
    ID_TOKEN_PARAM,
    AUTH_UUID_KEY,
} from './util/constants';
import Loader from './components/Loader';

const App = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loadingToken, setLoadingToken] = useState(false);

    // Custom Token
    const customToken = localStorage.getItem(CUSTOM_TOKEN_ID);
    const useCustomToken = localStorage.getItem(USE_CUSTOM_TOKEN);

    const {
        getAccessTokenSilently,
        isAuthenticated,
        isLoading: loadingAuth,
        user: authUser,
        logout,
    } = useAuth0();

    // Logout if using CoachAFL auth0 provider
    if (authUser?.sub?.includes('CoachAFL')) {
        logout();
    }

    const shouldFetchData =
        isAuthenticated ||
        (customToken && useCustomToken === 'true') ||
        searchParams.has('session_id');

    // API Handling
    const {
        data: dataUser,
        isLoading: loadingUser,
        isFetching: fetchingUser,
    } = useGetMeQuery(undefined, {
        skip: !shouldFetchData,
    });

    // MixPanel Tracking
    useEffect(() => {
        Mixpanel.trackPageView();
    }, [location]);

    useEffect(() => {
        if (!loadingAuth && isAuthenticated && authUser) {
            Mixpanel.identify(authUser[AUTH_UUID_KEY]);
        }
    }, [authUser, isAuthenticated, loadingAuth]);

    // Custom JWT token provided
    useEffect(() => {
        if (searchParams.has(ID_TOKEN_PARAM)) {
            const token = searchParams.get(ID_TOKEN_PARAM);

            if (token) {
                // Store token in local storage
                localStorage.setItem(CUSTOM_TOKEN_ID, token);
                // Clear token param form url
                searchParams.delete(ID_TOKEN_PARAM);
                setSearchParams(searchParams);
            }
        }
    }, [searchParams, setSearchParams]);

    // Navigate to Account Switcher page if Custom JWT and logged in to another account
    useEffect(() => {
        if (customToken && useCustomToken === null) {
            setLoadingToken(true);

            if (!loadingAuth) {
                const parsedToken = parseJwt(customToken);
                const validToken = isValidToken(parsedToken);

                if (validToken) {
                    if (
                        authUser &&
                        authUser[AUTH_UUID_KEY] !== parsedToken[AUTH_UUID_KEY]
                    ) {
                        navigate('/signin/account');
                    } else {
                        localStorage.setItem(USE_CUSTOM_TOKEN, 'true');
                    }
                } else {
                    localStorage.setItem(USE_CUSTOM_TOKEN, 'false');
                }
            }

            setTimeout(() => setLoadingToken(false), 1000);
        }
    }, [authUser, navigate, loadingAuth, customToken, useCustomToken]);

    // Cleanup invalid custom tokens from localStorage
    useEffect(() => {
        if (customToken) {
            const parsedToken = parseJwt(customToken);
            const validToken = isValidToken(parsedToken);

            if (!validToken) {
                localStorage.removeItem(CUSTOM_TOKEN_ID);
                localStorage.removeItem(USE_CUSTOM_TOKEN);
            }
        }
    }, [customToken]);

    // Set getAccessTokenSilently function to access from outside a react component
    //@ts-expect-error
    sec.setAccessTokenSilently(getAccessTokenSilently);

    if (loadingAuth || loadingToken || loadingUser || fetchingUser) {
        return <Loader size="fullscreen" title="Loading Stiuff..." />;
    }

    const renderRoute = (route: RouteType): React.ReactNode => {
        const { component: Element, children } = route;

        const child = (
            <Element route={route} user={dataUser?.data} {...route.props} />
        );

        return (
            <Route
                key={route.id}
                path={route.path}
                element={
                    route.authGateway === 'private' ? (
                        <ProtectedRoute route={route} user={dataUser?.data}>
                            {child}
                        </ProtectedRoute>
                    ) : route.authGateway === 'session' ? (
                        <SessionRoute route={route}>{child}</SessionRoute>
                    ) : (
                        child
                    )
                }
            >
                {children &&
                    children.map((childRoute) => renderRoute(childRoute))}
            </Route>
        );
    };

    return (
        <Routes>
            <Route path="/signin/account" element={<AccountSelector />} />
            <Route path="/" element={<Layout user={dataUser?.data} />}>
                {routes.map((route) => renderRoute(route))}
            </Route>
            <Route
                path="*"
                element={
                    <ErrorDisplay
                        proportion="fullscreen"
                        errorType={ERROR_TYPES.maintenance}
                        title="Page not found"
                        desc="Sorry we were unable to find that page."
                    />
                }
            />
        </Routes>
    );
};

export default App;
