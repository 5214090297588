import { classNames } from 'primereact/utils';

// Sport icons mapping
const icons = {
    aus_football_au: require('../assets/icons/sports/sport_aus_football_au.svg')
        .ReactComponent,
    bball_au: require('../assets/icons/sports/sport_bball_au.svg')
        .ReactComponent,
    fball_au: require('../assets/icons/sports/sport_fball_au.svg')
        .ReactComponent,
    fhoc_au: require('../assets/icons/sports/sport_fhoc_au.svg').ReactComponent,
    lacr_au: require('../assets/icons/sports/sport_lacr_au.svg').ReactComponent,
    netb_au: require('../assets/icons/sports/sport_netb_au.svg').ReactComponent,
};

interface Props {
    sportID: string;
    size?: 'small' | 'medium' | 'large';
    style?: any;
    className?: string;
}

const SportIcon = (props: Props) => {
    const { sportID, size, className } = props;

    const IconComponent = icons[sportID as keyof typeof icons];

    const iconClassnames = classNames('icon', className, {
        [`is-${size}`]: size,
    });

    if (!IconComponent) return null;

    return (
        <span style={props.style} className={iconClassnames}>
            <IconComponent />
        </span>
    );
};

SportIcon.defaultProps = {
    size: 'medium',
    style: {},
};

export default SportIcon;
