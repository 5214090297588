import { generatePath } from 'react-router-dom';
import { createApi } from '@reduxjs/toolkit/query/react';
import { isEqual } from 'lodash';

import { apiEndpoints } from './apiEndpoints';
import { baseQueryWithReauth } from '../util/baseQuery';

import { BaseEntityType, EntityStructure } from '../types/common';
import {
    AddAttendeeUserInput,
    Event,
    EventFormData,
    EventInviteeForm,
    EventResponse,
    EventsResponse,
    EventUserResponse,
} from '../types/event';

export const eventsApi = createApi({
    reducerPath: 'eventsApi',
    baseQuery: (args, api, extraOptions) =>
        baseQueryWithReauth(
            args,
            api,
            extraOptions,
            process.env.REACT_APP_API_URL
        ),
    tagTypes: ['Event'],
    endpoints: (builder) => ({
        getEvents: builder.query<
            EventsResponse,
            {
                entityType: BaseEntityType;
                entityID: string;
                cursor?: string;
                to?: string;
                from?: string;
                expand?: string | string[];
                limit?: string;
                status?: string;
                eventType?: string;
            }
        >({
            query: ({ entityType, entityID, ...params }) => ({
                url: generatePath(apiEndpoints.getEvents.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.getEvents.method,
                params,
            }),
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ eventID }: Event) => ({
                              type: 'Event' as const,
                              eventID,
                          })),
                          'Event',
                      ]
                    : ['Event'];
            },
            serializeQueryArgs: ({
                endpointName,
                queryArgs: { cursor, ...args },
            }) => {
                return `${endpointName}(${JSON.stringify(args)})`;
            },
            merge: (currentCache, newItems, args) => {
                if (currentCache && newItems) {
                    if (
                        args.arg.cursor &&
                        currentCache?.lastEvaluatedKey !==
                            newItems?.lastEvaluatedKey
                    ) {
                        currentCache.data = [
                            ...currentCache.data,
                            ...newItems.data,
                        ];
                        currentCache.lastEvaluatedKey.cursor =
                            newItems.lastEvaluatedKey.cursor;
                    } else {
                        currentCache.data = newItems.data;
                        currentCache.lastEvaluatedKey =
                            newItems.lastEvaluatedKey;
                    }
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
        }),
        getEvent: builder.query<
            EventResponse,
            {
                entityType: BaseEntityType;
                entityID: string;
                eventID: string;
                expand?: string;
            }
        >({
            query: ({ entityType, entityID, eventID, ...params }) => ({
                url: generatePath(apiEndpoints.getEvent.url, {
                    entityType,
                    entityID,
                    eventID,
                }),
                method: apiEndpoints.getEvent.method,
                params,
            }),
            providesTags: ['Event'],
        }),
        createEvent: builder.mutation<
            EventResponse,
            EntityStructure & { data: EventFormData }
        >({
            query: ({ entityType, entityID, data }) => ({
                url: generatePath(apiEndpoints.createEvent.url, {
                    entityType,
                    entityID,
                }),
                method: apiEndpoints.createEvent.method,
                body: data,
            }),
            invalidatesTags: ['Event'],
        }),
        updateEvent: builder.mutation<
            EventResponse,
            EntityStructure & { eventID: string; data: EventFormData }
        >({
            query: ({ entityType, entityID, eventID, data }) => ({
                url: generatePath(apiEndpoints.updateTeam.url, {
                    entityType,
                    entityID,
                    eventID,
                }),
                method: apiEndpoints.updateEvent.method,
                body: data,
            }),
            invalidatesTags: ['Event'],
        }),
        upsertEvent: builder.mutation<
            EventResponse,
            EntityStructure & { eventID?: string; data: EventFormData }
        >({
            query: ({ entityType, entityID, eventID, data }) => ({
                url: generatePath(
                    eventID
                        ? apiEndpoints.updateEvent.url
                        : apiEndpoints.createEvent.url,
                    { entityType, entityID, eventID }
                ),
                method: eventID
                    ? apiEndpoints.updateEvent.method
                    : apiEndpoints.createEvent.method,
                body: data,
            }),
            invalidatesTags: ['Event'],
        }),
        deleteEvent: builder.mutation<
            void,
            EntityStructure & { eventID: string }
        >({
            query: ({ entityType, entityID, eventID }) => ({
                url: generatePath(apiEndpoints.deleteEvent.url, {
                    entityType,
                    entityID,
                    eventID,
                }),
                method: apiEndpoints.deleteEvent.method,
            }),
            invalidatesTags: ['Event'],
        }),
        createEventInvitee: builder.mutation<
            EventResponse,
            EntityStructure & { eventID: String; data: EventInviteeForm }
        >({
            query: ({ entityType, entityID, eventID, data }) => ({
                url: generatePath(apiEndpoints.createEventInvitee.url, {
                    entityType,
                    entityID,
                    eventID,
                }),
                method: apiEndpoints.createEventInvitee.method,
                body: data,
            }),
            invalidatesTags: ['Event'],
        }),
        createEventAttendee: builder.mutation<
            EventResponse,
            EntityStructure & { eventID: String; data: AddAttendeeUserInput }
        >({
            query: ({ entityType, entityID, eventID, data }) => ({
                url: generatePath(apiEndpoints.createEventAttendee.url, {
                    entityType,
                    entityID,
                    eventID,
                }),
                method: apiEndpoints.createEventAttendee.method,
                body: data,
            }),
            invalidatesTags: ['Event'],
        }),
        deleteEventInvite: builder.mutation<
            void,
            EntityStructure & { eventID: string; inviteeID: string }
        >({
            query: ({ entityType, entityID, eventID, inviteeID }) => ({
                url: generatePath(apiEndpoints.deleteEventInvitee.url, {
                    entityType,
                    entityID,
                    eventID,
                    inviteeID,
                }),
                method: apiEndpoints.deleteEventInvitee.method,
            }),
            invalidatesTags: ['Event'],
        }),
        deleteEventAttendee: builder.mutation<
            void,
            EntityStructure & { eventID: string; attendeeID: string }
        >({
            query: ({ entityType, entityID, eventID, attendeeID }) => ({
                url: generatePath(apiEndpoints.deleteEventAttendee.url, {
                    entityType,
                    entityID,
                    eventID,
                    attendeeID,
                }),
                method: apiEndpoints.deleteEventAttendee.method,
            }),
            invalidatesTags: ['Event'],
        }),
        updateEventResponse: builder.mutation<
            EventResponse,
            EntityStructure & {
                eventID: string;
                inviteeID: string;
                response: EventUserResponse;
            }
        >({
            query: ({
                entityType,
                entityID,
                eventID,
                inviteeID,
                response,
            }) => ({
                url: generatePath(apiEndpoints.updateEventResponse.url, {
                    entityType,
                    entityID,
                    eventID,
                    inviteeID,
                }),
                method: apiEndpoints.updateEventResponse.method,
                body: {
                    response,
                },
            }),
            invalidatesTags: ['Event'],
        }),

        /**
         *
         * Season Events
         *
         **/

        getSeasonEvents: builder.query<
            EventsResponse,
            {
                entityType: BaseEntityType;
                entityID: string;
                seasonID: string;
                cursor?: string;
                expand?: string;
                from?: string;
                to?: string;
                status?: string;
                limit?: string;
            }
        >({
            query: ({ entityType, entityID, seasonID, ...params }) => {
                return {
                    url: `${generatePath(apiEndpoints.getSeasonEvents.url, {
                        seasonID,
                        entityType,
                        entityID,
                    })}`,
                    method: apiEndpoints.getSeasonEvents.method,
                    params,
                };
            },
            providesTags: (result) => {
                return result && result.data
                    ? [
                          ...result.data.map(({ eventID }: Event) => ({
                              type: 'Event' as const,
                              eventID,
                          })),
                          'Event',
                      ]
                    : ['Event'];
            },
            serializeQueryArgs: ({
                endpointName,
                queryArgs: { cursor, ...args },
            }) => {
                return `${endpointName}(${JSON.stringify(args)})`;
            },
            merge: (currentCache, newItems, args) => {
                if (currentCache && newItems) {
                    if (
                        args.arg.cursor &&
                        currentCache?.lastEvaluatedKey !==
                            newItems?.lastEvaluatedKey
                    ) {
                        currentCache.data = [
                            ...currentCache.data,
                            ...newItems.data,
                        ];

                        currentCache.lastEvaluatedKey.cursor =
                            newItems.lastEvaluatedKey.cursor;
                    } else {
                        currentCache.data = newItems.data;
                        currentCache.lastEvaluatedKey =
                            newItems.lastEvaluatedKey;
                    }
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return !isEqual(currentArg, previousArg);
            },
        }),
    }),
});

export const {
    useLazyGetEventsQuery,
    useGetEventsQuery,
    useGetEventQuery,
    useGetSeasonEventsQuery,
    useLazyGetSeasonEventsQuery,
    useCreateEventMutation,
    useUpdateEventMutation,
    useUpsertEventMutation,
    useDeleteEventMutation,
    useCreateEventInviteeMutation,
    useCreateEventAttendeeMutation,
    useDeleteEventInviteMutation,
    useDeleteEventAttendeeMutation,
    useUpdateEventResponseMutation,
} = eventsApi;
