import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useGetMessageSettingsQuery } from '../../api/messages';

import MessageSetupView from './MessageSetupView';

import { BaseEntityType } from '../../types/common';
import { useEffect, useMemo } from 'react';
import { getEntityFromParam } from '../../util/helper';

const MessageSetupContainer = () => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const activeEntity = useMemo(() => getEntityFromParam(params), [params]);

    const {
        data: messageSettingsResponse,
        isLoading,
        isSuccess,
    } = useGetMessageSettingsQuery(
        {
            entityID: activeEntity?.entityID ?? '',
            entityType: activeEntity?.entityType as BaseEntityType,
        },
        {
            skip: !activeEntity,
        }
    );

    const messageSettings = messageSettingsResponse?.data || undefined;

    useEffect(() => {
        // Handle redirection to "files"
        if (isSuccess && messageSettings && messageSettings.enabled) {
            navigate(location.pathname.replace('setup', '')); // refresh
        }
    }, [isSuccess, location.pathname, messageSettings, navigate]);

    const handleSuccess = () => {
        navigate(location.pathname.replace('setup', '')); // refresh
    };

    return (
        <MessageSetupView
            entityType={activeEntity?.entityType}
            entityID={activeEntity?.entityID}
            onSuccess={handleSuccess}
            isLoading={isLoading}
            messageSettings={messageSettings}
        />
    );
};

export default MessageSetupContainer;
