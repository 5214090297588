import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
    uniq,
    union,
    uniqBy,
    intersectionWith,
    sortBy,
    get,
    isObject,
} from 'lodash';

import { useGetPlayersQuery } from '../../../api/players';
import { useLazyGetTeamSeasonTimeReportQuery } from '../../../api/reports';
import { useGetStatViewsQuery, useGetStatsQuery } from '../../../api/stats';
import { useGetTeamQuery } from '../../../api/teams';

import TeamPlayerStatsReportView from './TeamPlayerStatsReportView';
import { DataTable } from 'primereact/datatable';
import { SelectItem } from 'primereact/selectitem';

import usePrivilege from '../../../hooks/usePrivilege';
import { periodSuffix } from '../../../util/helper';
import { Mixpanel } from '../../../util/mixpanel';
import { calculateTotals, formatValue } from '../helpers';
import { defaultReportState } from '../constants';
import { config } from '../reportConfig';

import { BaseEntityType } from '../../../types/common';
import {
    ReportColumn,
    ReportDataFilters,
    ReportDataTypes,
    ReportDataViews,
    ReportTimeDisplays,
    ReportState,
    StatTypes,
    TimeStat,
    ReportType,
} from '../../../types/reports';

const dataTypeOptions = [
    {
        label: ReportDataTypes.Total,
        value: ReportDataTypes.Total,
    },
    {
        label: ReportDataTypes.Average,
        value: ReportDataTypes.Average,
    },
];

const timeDisplayOptions = [
    {
        label: '% Per',
        value: ReportTimeDisplays.Percentage,
    },
    {
        label: 'Time',
        value: ReportTimeDisplays.Time,
    },
];
const TeamPlayerStatsReportContainer = () => {
    // Cache busting ref
    const timestampRef = useRef(Date.now()).current;

    // Route Params
    const { teamID } = useParams();

    // Search Params
    const [searchParams] = useSearchParams();
    const eventParam = searchParams.get('event');
    const seasonParam = searchParams.get('season');
    const categoryParam = searchParams.get('category');

    // State Hooks
    const [hideEmptyColumns, setHideEmptyColumns] = useState<boolean>(true);
    const [emptyColumns, setEmptyColumns] = useState<string[]>([]);
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
    const [columnOrder, setColumnOrder] = useState<string[]>([]);
    const [category, setCategory] = useState<string>(categoryParam || '');
    const [season, setSeason] = useState(seasonParam || '');
    const [filters, setFilters] = useState<ReportDataFilters>({
        event: eventParam ? [eventParam] : [],
        period: 0,
    });
    const [views, setViews] = useState<ReportDataViews>({
        dataType: ReportDataTypes.Total,
        timeDisplay: ReportTimeDisplays.Percentage,
    });

    const [reportData, setReportData] = useState<
        Record<string, ReportState<any>>
    >({});

    const [playersCursor, setPlayersCursor] = useState<string>('');

    // API Hooks
    const [requestReport] = useLazyGetTeamSeasonTimeReportQuery();

    const teamData = useGetTeamQuery({
        teamID: teamID || '',
        expand: 'defaultSeasonDetails',
    });

    const playerData = useGetPlayersQuery(
        {
            cursor: playersCursor,
            status: 'All',
            teamID: teamID || '',
        },
        {
            skip: !teamID,
        }
    );

    const team = useMemo(() => teamData.data?.data, [teamData]);
    const sportID = team?.entitySportID;

    // Fetch Stat Config
    const statConfigData = useGetStatsQuery(
        { sportID: sportID || '' },
        { skip: !sportID }
    );
    const statViewsData = useGetStatViewsQuery(
        { sportID: sportID || '' },
        { skip: !sportID }
    );

    const { checkRestrictions } = usePrivilege(teamID, BaseEntityType.teams);

    // Helpers
    const statConfig = statConfigData?.data?.data;

    // Local Storage helpers
    const storageKey = useMemo(() => `player-stats-${teamID}`, [teamID]);

    // Save report settings to local storage
    const saveValue = (key: string, value: any) => {
        const ls = localStorage.getItem(storageKey);
        const parsed = ls ? JSON.parse(ls) : {};

        parsed[key] = value;

        localStorage.setItem(storageKey, JSON.stringify(parsed));
    };

    // Load report settings from local storage
    const loadValue = (key: string) => {
        const ls = localStorage.getItem(storageKey);
        const parsed = ls && JSON.parse(ls);

        return parsed && parsed[key];
    };

    // Dynamically generate report columns based on fetched data and filters
    const columns: ReportColumn[] = useMemo(() => {
        let cols: ReportColumn[] = [
            config.playerNumber,
            config.playerName,
            config.playerGroup,
            config.gamesPlayed,
        ];

        if (statViewsData.data) {
            const allowedStatViews = statViewsData.data?.data.filter(
                (statView) => {
                    return checkRestrictions({
                        PLAY_STAT_VIEWS: [statView.statViewID],
                    });
                }
            );

            if (allowedStatViews && allowedStatViews.length > 0) {
                const stats = allowedStatViews[0].statViewStats;
                const sortedStats = sortBy(stats, 'statSortOrder');

                sortedStats.forEach((stat) => {
                    cols.push({
                        id: stat.statID,
                        field: stat.statID,
                        name: stat.name,
                        shortName: stat.shortName,
                        category: stat.categoryName,
                        type: StatTypes.Count,
                    });
                });
            }
        }

        return cols;
    }, [checkRestrictions, statViewsData]);

    useEffect(() => {
        const lsHiddenColumns = loadValue('hiddenColumns');
        const lsColumnOrder = loadValue('columnOrder');

        setHiddenColumns(() => {
            if (category === '') {
                return lsHiddenColumns ? lsHiddenColumns : [];
            }
            return columns
                .filter((col) => {
                    const inCategory = col.category === category;

                    return (
                        !col.persist &&
                        (hideEmptyColumns
                            ? emptyColumns.includes(col.id) || !inCategory
                            : !inCategory)
                    );
                })
                .map((col) => col.id);
        });

        setColumnOrder(
            category === '' && lsColumnOrder
                ? lsColumnOrder
                : columns.map((col) => col.id)
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category, columns, emptyColumns, hideEmptyColumns]);

    // Handle fetching the report data when filters change or on mount
    useEffect(() => {
        if (reportData[season] && reportData[season].data) {
            const emptyCols = columns
                .filter((col) => {
                    if (col.persist) return false;

                    return (
                        reportData[season].data.filter((row: TimeStat) => {
                            const value = col.field && get(row, col.field);

                            return value || value > 0;
                        }).length <= 0
                    );
                })
                .map((col) => col.id);

            setEmptyColumns(emptyCols);
        }
    }, [columns, reportData, season]);

    const resetFilters = () => {
        setFilters({
            event: [],
            period: 0,
        });
    };

    const handleSeasonChange = (seasonID: string) => {
        setSeason(seasonID);
        resetFilters();
    };

    const handleFilterChange = (key: string, value: string) => {
        setFilters((state) => ({
            ...state,
            [key]: value,
        }));
    };

    const handleViewChange = (key: string, value: any) => {
        setViews((state) => ({
            ...state,
            [key]: value,
        }));
    };

    // Auto paginate players
    useEffect(() => {
        const { data } = playerData;

        if (
            data?.lastEvaluatedKey &&
            data.lastEvaluatedKey.cursor &&
            data.lastEvaluatedKey.cursor !== playersCursor
        ) {
            setPlayersCursor(data.lastEvaluatedKey.cursor);
        }
    }, [playerData, playersCursor]);

    useEffect(() => {
        if (teamID && season && !reportData[season]) {
            const fetchReport = async () => {
                try {
                    // Set report loading in state
                    setReportData((prev) => ({
                        ...prev,
                        [season]: {
                            ...defaultReportState,
                            isLoading: true,
                            isUninitialized: false,
                        },
                    }));

                    //fetch report url
                    const requestReportData = await requestReport({
                        seasonID: season,
                        teamID,
                        sessionID: timestampRef,
                        reportType: ReportType.gameStatsAdvanced,
                    }).unwrap();

                    const reportUrl = requestReportData?.data?.objectURL;

                    if (reportUrl) {
                        // Fetch report data
                        const response = await fetch(reportUrl);

                        if (!response.ok) {
                            throw new Error('Failed to load report');
                        }

                        const report = await response.json();

                        // Set report in state
                        setReportData((prev) => ({
                            ...prev,
                            [season]: {
                                ...prev[season],
                                isLoading: false,
                                data: report,
                            },
                        }));
                    } else {
                        throw new Error('Invalid report URL or request failed');
                    }
                } catch (error) {
                    // Set report error in state
                    setReportData((prev) => ({
                        ...prev,
                        [season]: {
                            ...prev[season],
                            isLoading: false,
                            isError: true,
                            error:
                                isObject(error) && 'message' in error
                                    ? error.message
                                    : error,
                        },
                    }));
                }
            };

            fetchReport();
        }
    }, [teamID, season, reportData, requestReport, timestampRef]);

    // Set the season to the first available season
    useEffect(() => {
        if (!season && team) {
            setSeason(team.defaultSeasonID);
        }
    }, [team, season]);

    // Set data type to totals when filtering by a single event
    useEffect(() => {
        if (
            filters.event?.length === 1 &&
            views.dataType === ReportDataTypes.Average
        ) {
            handleViewChange('dataType', ReportDataTypes.Total);
        }
    }, [filters.event, views.dataType]);

    const exportCSV = (dataTable: DataTable<any>) => {
        dataTable && dataTable.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: `Team Player Stats Report`,
        });
    };

    // Filter Options
    const categoryOptions = useMemo(() => {
        let options: SelectItem[] = [
            {
                label: 'Custom',
                value: '',
            },
        ];

        const uniqStats =
            reportData[season] &&
            reportData[season].data &&
            union(
                ...reportData[season].data.map((d: TimeStat) => Object.keys(d))
            );

        const uniqCategories = uniqBy(
            intersectionWith(statConfig, uniqStats, (config, statID) => {
                return config.statID === statID;
            }),
            'statCategory.name'
        );

        const sortedCategories = sortBy(
            uniqCategories,
            'statCategory.sortOrder'
        );

        sortedCategories.forEach((config) => {
            options.push({
                label: config.statCategory.name,
                value: config.statCategory.name,
            });
        });

        return options;
    }, [statConfig, reportData, season]);

    const periodOptions = useMemo(() => {
        if (
            !reportData[season] ||
            (reportData[season] && !reportData[season].data)
        ) {
            return [];
        }
        // Get total periods for the individual game
        const totalPeriods = (
            (reportData[season].data || []) as TimeStat[]
        ).reduce((maxPeriod, item: TimeStat) => {
            return item.period > maxPeriod ? item.period : maxPeriod;
        }, 0);

        return Array.from({ length: totalPeriods }).map((u, i) => {
            const period = i + 1;
            const label = `${period}${periodSuffix(Number(period))}`;
            return {
                label: label,
                value: period,
            };
        });
    }, [reportData, season]);

    const handleColumnOrderChange = (col: ReportColumn[]) => {
        const newState = col.map((c) => c.id);
        setColumnOrder(newState);
        saveValue('columnOrder', newState);
    };

    const handleHiddenColumnChange = (value: string) => {
        setHiddenColumns((prevState) => {
            const newState = prevState.includes(value)
                ? prevState.filter((col) => col !== value)
                : [...prevState, value];

            saveValue('hiddenColumns', newState);

            return newState;
        });
    };

    // Helper for filtering
    const filterData = (data: any, filters: ReportDataFilters) => {
        // Remove the total to avoid summing both the individual periods and the total
        return data.filter((item: TimeStat) => {
            if (
                item.period === 'total' ||
                item.playerID === 'team' ||
                item.playerID === 'opposition'
            ) {
                return false;
            }

            const hasEventFilter = filters.event && filters.event.length > 0;
            const hasPeriodFilter = filters.period && filters.period > 0;

            if (hasEventFilter && !filters.event?.includes(item.eventID)) {
                return false;
            }
            if (hasPeriodFilter && filters.period !== Number(item.period)) {
                return false;
            }

            return true;
        });
    };

    const visibleColumns = useMemo((): ReportColumn[] => {
        return sortBy(
            columns.filter((col) => {
                const isHidden = hiddenColumns.includes(col.id);
                const hideEmpty =
                    hideEmptyColumns && emptyColumns.includes(col.id);

                return !isHidden && !hideEmpty;
            }),
            (col) => columnOrder.indexOf(col.id)
        );
    }, [columns, emptyColumns, columnOrder, hiddenColumns, hideEmptyColumns]);

    const customisableColumns = useMemo(() => {
        return sortBy(
            columns.filter((col) => {
                return (
                    !(hideEmptyColumns && emptyColumns.includes(col.id)) &&
                    !col.persist
                );
            }),
            (col) => columnOrder.indexOf(col.id)
        );
    }, [columns, emptyColumns, columnOrder, hideEmptyColumns]);

    const tableData = useMemo(() => {
        let newData = reportData[season]?.data;

        if (!newData) return [];

        // Filter data
        newData = filterData(newData, filters);

        // Calculate totals
        newData = calculateTotals(newData, 'playerID');

        // Format data
        return newData.map((data: any) => {
            const player = playerData.data?.data.find(
                (p) => p.playerID === data.playerID
            );

            const uniqueKeys = uniq([
                ...Object.keys(data),
                ...columns.map((col) => col.id),
            ]);

            data.player = player;
            //gamesPlayed = data.gamesPlayed;
            return uniqueKeys.reduce(
                (acc, key) => {
                    const column = columns.find(
                        (col) => col.id === key && col.id !== 'gamesPlayed'
                    );

                    if (column?.format) {
                        acc[key] = column.format(data, {
                            field: key,
                            column,
                            views,
                            data: reportData[season]?.data,
                        });
                    } else {
                        const totalTime = data['PT'];

                        acc[key] = formatValue(
                            data[key],
                            column?.type,
                            views.dataType === ReportDataTypes.Average,
                            data.gamesPlayed,
                            views.timeDisplay === ReportTimeDisplays.Percentage,
                            totalTime
                        );
                    }

                    return acc;
                },
                { ...data }
            );
        });
    }, [columns, season, playerData, reportData, views, filters]);

    return (
        <TeamPlayerStatsReportView
            category={category}
            columns={visibleColumns}
            customisableColumns={customisableColumns}
            data={tableData}
            emptyColumns={emptyColumns}
            filters={filters}
            hideEmptyColumns={hideEmptyColumns}
            hiddenColumns={hiddenColumns}
            options={{
                timeDisplay: timeDisplayOptions,
                dataType: dataTypeOptions,
                categories: categoryOptions,
                periods: periodOptions,
            }}
            reportData={reportData[season] || {}}
            season={season}
            team={team}
            views={views}
            onCategoryChange={setCategory}
            onColumnsChange={handleColumnOrderChange}
            onExport={exportCSV}
            onFilterChange={handleFilterChange}
            onHiddenColumnChange={handleHiddenColumnChange}
            onHideEmptyColumns={setHideEmptyColumns}
            onSeasonChange={handleSeasonChange}
            onViewsChange={handleViewChange}
        />
    );
};

export default TeamPlayerStatsReportContainer;
