import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { PrimeReactProvider } from 'primereact/api';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integration } from '@sentry/types';
import { Provider } from 'react-redux';
import { store } from './store';

import ErrorDisplay from './components/ErrorDisplay';
import { LayoutProvider } from './contexts/LayoutContext';
import { ToastProvider } from './contexts/ToastContext';
import { ConfirmDialog } from 'primereact/confirmdialog';

Sentry.init({
    dsn: 'https://2f16517721d1467fb13b065ef5b69168@o1337471.ingest.sentry.io/4504115855949824',
    integrations: [
        new Sentry.BrowserTracing({
            tracePropagationTargets: [process.env.REACT_APP_API_URL || ''],
        }) as Integration,
        new Sentry.Replay(),
    ],
    beforeSend(event, hint) {
        // Check if it is an exception, and if so, show the report dialog
        if (event.exception) {
            Sentry.showReportDialog({ eventId: event.event_id });
        }
        return event;
    },
    enabled: process.env.REACT_APP_SST_STAGE === 'prod', // disable reporting to Sentry while working locally
    // Performance Monitoring
    tracesSampleRate: 1.0, // adjust this value in production
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

// Redirect after Auth0 login
const onRedirectCallback = (appState?: AppState) => {
    if (appState && appState.returnTo) {
        window.location.replace(appState.returnTo);
    }
};

root.render(
    <React.StrictMode>
        <PrimeReactProvider>
            <Provider store={store}>
                <Auth0Provider
                    domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
                    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
                    redirectUri={process.env.REACT_APP_FRONTEND_URL}
                    scope={process.env.REACT_APP_AUTH0_SCOPE || ''}
                    useRefreshTokens={true}
                    cacheLocation="localstorage"
                    onRedirectCallback={onRedirectCallback}
                >
                    <BrowserRouter>
                        <Sentry.ErrorBoundary
                            fallback={({ resetError }) => (
                                <ErrorDisplay
                                    handleReset={resetError}
                                    title="An error has occurred"
                                    desc="Use one of the below options to continue or leave feedback."
                                    proportion="fullscreen"
                                    hasReporting
                                />
                            )}
                        >
                            <LayoutProvider>
                                <ToastProvider>
                                    <App />
                                </ToastProvider>
                                <ConfirmDialog />
                            </LayoutProvider>
                        </Sentry.ErrorBoundary>
                    </BrowserRouter>
                </Auth0Provider>
            </Provider>
        </PrimeReactProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
