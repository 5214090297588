import { useOutletContext } from 'react-router-dom';
import { groupBy } from 'lodash';

import { useGetUserDetailsQuery } from '../../api/core';

import List from '../../components/List';
import ListItem from '../../components/ListItem';
import ListHeader from '../../components/ListHeader';
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';

import {
    EventResponse,
    EventUserResponse,
    InvitedUser,
} from '../../types/event';
import { GameSummary, ReportState } from '../../types/reports';
import { TeamResponse } from '../../types/team';
import { UseQueryResult } from '../../types/api';
import RookieButton from '../../components/RookieButton';
import { GameRolesResponse } from '../../types/game';

interface Props {
    eventData: UseQueryResult<EventResponse>;
    teamData: UseQueryResult<TeamResponse>;
    gameSummaryData: ReportState<GameSummary>;
    gameRolesData: UseQueryResult<GameRolesResponse>;
    onDeleteInvite: (inviteeID: string) => void;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

interface ItemProps {
    user: InvitedUser;
    onDeleteInvite: (inviteeID: string) => void;
    gameRolesData: UseQueryResult<GameRolesResponse>;
    permissions: {
        canCreate: boolean;
        canDelete: boolean;
        canEdit: boolean;
        canView: boolean;
    };
}

const UserItem = ({
    user,
    onDeleteInvite,
    gameRolesData,
    permissions,
}: ItemProps) => {
    const { userID, eventRoles, gameRoles } = user;
    const { data } = useGetUserDetailsQuery({
        userID,
    });

    if (!data) {
        return null;
    }

    return (
        <ListItem
            start={<Avatar image={data.data.picture} shape="circle" />}
            end={
                <>
                    {eventRoles.map(
                        (role) =>
                            (role === 'eventsAdmin' ||
                                role === 'eventsOwner') && (
                                <Badge
                                    key={role}
                                    value={role.replace('events', '')}
                                />
                            )
                    )}
                    {permissions.canDelete &&
                        gameRoles &&
                        !gameRoles.includes('gamesOwner') && (
                            <RookieButton
                                onClick={() => onDeleteInvite(user.inviteeID)}
                                icon="delete"
                                text
                            />
                        )}
                </>
            }
            title={`${data.data.given_name} ${data.data.family_name}`}
            caption={
                gameRoles?.length
                    ? gameRoles
                          .map(
                              (role) =>
                                  gameRolesData?.data?.data?.find(
                                      (gameRole) =>
                                          gameRole.role.roleID === role
                                  )?.role.roleName
                          )
                          .filter(Boolean) // Removes any undefined values
                          .join(', ')
                    : 'No roles assigned'
            }
        />
    );
};

const EventInvitees = () => {
    const { eventData, onDeleteInvite, gameRolesData, permissions } =
        useOutletContext<Props>();

    const event = eventData?.data?.data;
    const groupByResponse = groupBy(event?.eventInvitees.invitees, 'response');

    return (
        <List>
            {groupByResponse[EventUserResponse.Yes] && (
                <>
                    <ListHeader>Going</ListHeader>
                    {groupByResponse[EventUserResponse.Yes].map((invitee) => (
                        <UserItem
                            key={invitee.inviteeID}
                            user={invitee}
                            onDeleteInvite={onDeleteInvite}
                            gameRolesData={gameRolesData}
                            permissions={permissions}
                        />
                    ))}
                </>
            )}
            {groupByResponse[EventUserResponse.Maybe] && (
                <>
                    <ListHeader>Maybe</ListHeader>
                    {groupByResponse[EventUserResponse.Maybe].map((invitee) => (
                        <UserItem
                            key={invitee.inviteeID}
                            user={invitee}
                            onDeleteInvite={onDeleteInvite}
                            gameRolesData={gameRolesData}
                            permissions={permissions}
                        />
                    ))}
                </>
            )}
            {groupByResponse[EventUserResponse.No] && (
                <>
                    <ListHeader>Not Going</ListHeader>
                    {groupByResponse[EventUserResponse.No].map((invitee) => (
                        <UserItem
                            key={invitee.inviteeID}
                            user={invitee}
                            onDeleteInvite={onDeleteInvite}
                            gameRolesData={gameRolesData}
                            permissions={permissions}
                        />
                    ))}
                </>
            )}
            {groupByResponse[EventUserResponse.NoReply] && (
                <>
                    <ListHeader>No Response</ListHeader>
                    {groupByResponse[EventUserResponse.NoReply].map(
                        (invitee) => (
                            <UserItem
                                key={invitee.inviteeID}
                                user={invitee}
                                onDeleteInvite={onDeleteInvite}
                                gameRolesData={gameRolesData}
                                permissions={permissions}
                            />
                        )
                    )}
                </>
            )}
            {event?.eventInvitees.invitedExternals &&
                event?.eventInvitees.invitedExternals.length > 0 && (
                    <>
                        <ListHeader>Guests</ListHeader>
                        {event.eventInvitees.invitedExternals.map((invitee) => (
                            <ListItem
                                key={invitee.inviteeID}
                                start={
                                    <Avatar
                                        label={invitee.name}
                                        shape="circle"
                                    />
                                }
                                title={invitee.name}
                                caption={invitee.role}
                            />
                        ))}
                    </>
                )}
        </List>
    );
};

export default EventInvitees;
