import { useState, useMemo, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { uniq } from 'lodash';

import { useGetRolesQuery } from '../../api/core';
import { generateUrlFromEntity, getNiceEntityType } from '../../util/helper';
import { ToastContext } from '../../contexts/ToastContext';

import DocumentHead from '../../components/DocumentHead';
import EntityListingView from './EntityListingView';

import { Me } from '../../types/user';
import { Route } from '../../types/route';
import { Roles } from '../../types/roles';
import { Mixpanel } from '../../util/mixpanel';
import { BaseEntityType, FormSubmitCallback } from '../../types/common';

interface Props {
    roles: Roles;
    route: Route;
    user: Me;
}

const EntityListingContainer = (props: Props) => {
    const { userID } = useParams();
    const navigate = useNavigate();

    const toast = useContext(ToastContext);

    const [filter, setFilter] = useState<BaseEntityType[]>([]);
    const [search, setSearch] = useState('');
    const [showCreateModal, setShowCreateModal] = useState<BaseEntityType>();

    const { data, isLoading, isError, isFetching, isUninitialized } =
        useGetRolesQuery(
            {
                userID: userID ?? '',
                limit: 2000,
            },
            {
                skip: !userID,
            }
        );

    const entityTypes = useMemo(
        () => uniq(data?.data.map((ent) => ent.entityType)).sort(),
        [data]
    );

    const handleFilterChange = (entityType: BaseEntityType) => {
        setFilter((prev) =>
            prev.includes(entityType)
                ? prev.filter((p) => p !== entityType)
                : [...prev, entityType]
        );
    };

    const handleShowCreateModal = (entityType: BaseEntityType) => {
        setShowCreateModal(entityType);

        Mixpanel.track(`Open ${entityType} Create`, {
            entityType,
        });
    };

    const closeCreateModal = () => {
        setShowCreateModal(undefined);
    };

    const handleSubmitComplete: FormSubmitCallback = (
        status,
        action,
        response,
        error,
        entityType
    ) => {
        const type = entityType ? getNiceEntityType(entityType) : 'entity';

        if (status === 'success' && response) {
            const entity = response.data.data;

            const entityName =
                entity.associationName ||
                entity.organisationName ||
                entity.teamName ||
                type;

            toast?.current?.show({
                severity: 'success',
                summary: 'Success',
                detail:
                    action === 'create'
                        ? `${entityName} has been created successfully.`
                        : action === 'delete'
                        ? `${entityName} has been delete successfully.`
                        : `${entityName} has been updated successfully.`,
            });

            closeCreateModal();

            if (entity) {
                const entityUrl = entity && generateUrlFromEntity(entity);

                navigate(entityUrl);
            }
        } else if (status === 'error' && error) {
            toast?.current?.show({
                severity: 'warn',
                summary: 'Error',
                detail: error.message,
            });
        } else {
            toast?.current?.show({
                severity: 'warn',
                summary: 'Error',
                detail: `Something went wrong ${
                    action === 'create'
                        ? 'creating'
                        : action === 'delete'
                        ? 'deleting'
                        : 'updating'
                } the ${type}. Please try again.`,
            });
        }
    };

    const handleJoinClick = () => {
        navigate(`/u/${props.user.userID}/team-join`);
    };

    return (
        <>
            <DocumentHead
                title="Your Entities - Rookie Me Hub"
                description="Entity listing page"
                className="page-entities"
            />
            <EntityListingView
                data={data ? data.data : []}
                entityTypes={entityTypes}
                filter={filter}
                isError={isError}
                isFetching={isFetching}
                isLoading={isLoading || isUninitialized}
                search={search}
                showCreateModal={showCreateModal}
                showPagination={false}
                onCloseCreateModal={closeCreateModal}
                onFilterChange={handleFilterChange}
                onJoinClick={handleJoinClick}
                onSearchChange={setSearch}
                onShowCreateModal={handleShowCreateModal}
                onSubmitComplete={handleSubmitComplete}
                {...props}
            />
        </>
    );
};

export default EntityListingContainer;
