import { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { uniq } from 'lodash';

import { ToastContext } from '../../contexts/ToastContext';

import {
    useCreateOrganisationTeamMutation,
    useGetOrganisationQuery,
} from '../../api/organisations';
import { useGetEntityCategoryQuery } from '../../api/sports';

import SpreadsheetImport from '../../components/SpreadsheetImport';

import {
    teamAgeOptions,
    teamGenderOptions,
    teamGradeOptions,
} from '../../util/constants';

import { Team, TeamJoinMode } from '../../types/team';

interface Props {
    show: boolean;
    onHide: () => void;
    onComplete: () => void;
}

const TeamsImport = (props: Props) => {
    const { onComplete, onHide, show } = props;
    const { organisationID } = useParams();

    const toast = useContext(ToastContext);

    const [createTeam] = useCreateOrganisationTeamMutation();

    const { data: organisationData } = useGetOrganisationQuery({
        organisationID,
    });

    const organisation = useMemo(
        () => organisationData?.data,
        [organisationData]
    );

    const { data: category } = useGetEntityCategoryQuery(
        {
            sportID: organisation?.entitySportID || '',
            entityCategoryID: organisation?.entityCategoryID || '',
        },
        { skip: !organisation }
    );

    const handleSubmit = async (spreadsheetData: any) => {
        const { validData, all } = spreadsheetData;
        let errors: string[] = [];

        // Add seasonName being the current year to each entry in validData
        const currentYear = new Date().getFullYear();
        const updatedValidData = validData.map((entry: any) => ({
            ...entry,
            seasonName: `${currentYear}`,
        }));

        // Helper function to delay execution
        const delay = (ms: number) =>
            new Promise((resolve) => setTimeout(resolve, ms));

        // Break import into chunks of 10
        const chunkSize = 10;
        const chunks = [];
        for (let i = 0; i < updatedValidData.length; i += chunkSize) {
            chunks.push(updatedValidData.slice(i, i + chunkSize));
        }

        let successCount = 0;

        if (toast && toast.current) {
            toast.current.show({
                summary: `Importing ${all.length} teams`,
                detail: 'Please wait...',
                sticky: true,
            });
        }

        // Process each chunk with delay
        for (let i = 0; i < chunks.length; i++) {
            const chunk = chunks[i];
            const results = await Promise.allSettled(
                chunk.map(async (team: Team) => {
                    let formData: any = { ...team };

                    return createTeam({
                        organisationID,
                        ...formData,
                        design: organisation?.design,
                        entityCategoryID: category?.data.entityCategoryChildren
                            ? category?.data.entityCategoryChildren[0]
                            : '',
                        teamJoinMode: TeamJoinMode.Enabled,
                        teamJoinRole: 'teamsAdmin',
                    })
                        .then((response) => {
                            return 'data' in response && response.data;
                        })
                        .catch((err) => {
                            errors.push(err.toString());
                            return false;
                        });
                })
            );

            successCount += results.filter(
                (result): result is PromiseFulfilledResult<number> =>
                    result.status === 'fulfilled' && result.value
            ).length;

            // Update toast with progress after each batch
            if (toast && toast.current) {
                toast.current.replace({
                    summary: `Importing ${all.length} teams`,
                    detail: `${successCount}/${all.length} teams imported`,
                    sticky: true,
                });
            }

            // Delay before processing the next batch
            if (i < chunks.length - 1) {
                await delay(500); // Delay of 500ms between batches
            }
        }

        // Final toast summary
        const failedCount = all.length - successCount;

        if (toast && toast.current) {
            toast.current.replace({
                severity:
                    successCount === 0
                        ? 'error'
                        : successCount === updatedValidData.length
                        ? 'success'
                        : undefined,
                summary: `${successCount}/${all.length} imported`,
                detail:
                    failedCount > 0
                        ? `${failedCount} failed due to: ${
                              errors.length > 0
                                  ? uniq(errors).toString()
                                  : 'Unknown'
                          }`
                        : '',
                sticky: true,
            });
        }

        if (onComplete) onComplete();
    };

    const options = useMemo(() => {
        let entityOptions = {
            age: teamAgeOptions,
            gender: teamGenderOptions,
            grade: teamGradeOptions,
        } as {
            [key: string]: Array<{ label: string; value: string }>;
        };

        if (organisation?.entityCategoryID) {
            if (
                category?.data?.validProperties &&
                category?.data?.validProperties.length > 0
            ) {
                category.data.validProperties.forEach((prop) => {
                    if (prop.propertyKey && prop.propertyValues) {
                        entityOptions[prop.propertyKey] =
                            prop.propertyValues.map((val) => ({
                                label: val,
                                value: val,
                            }));
                    }
                });
            }
        }

        return entityOptions;
    }, [category, organisation]);

    const importFields = [
        {
            label: 'Team Name',
            key: 'teamName',
            example: 'Rookie Me U15 Blue',
            fieldType: { type: 'input' },
            alternateMatches: [
                'name',
                'Name',
                'team',
                'Team',
                'team name',
                'Team Name',
            ],
            validations: [
                {
                    rule: 'required',
                    errorMessage: 'Team Name is required',
                    level: 'error',
                },
            ],
        },
        {
            label: 'Short Name',
            key: 'shortName',
            example: 'RM',
            fieldType: { type: 'input' },
            alternateMatches: ['short name', 'Short Name'],
            validations: [
                {
                    rule: 'required',
                    errorMessage: 'Team Short Name is required',
                    level: 'error',
                },
            ],
        },
        {
            label: 'Team Age',
            key: 'age',
            example: 'Under 15',
            alternateMatches: [
                'age',
                'Age',
                'year',
                'Year',
                'team age',
                'Team Age',
                'team year',
                'Team Year',
            ],
            fieldType: {
                type: 'select',
                options: options.age,
            },
            validations: [
                {
                    rule: 'required',
                    errorMessage: 'Team Age is required',
                    level: 'error',
                },
            ],
        },
        {
            label: 'Team Gender',
            key: 'gender',
            alternateMatches: [
                'gender',
                'Gender',
                'team gender',
                'Team Gender',
            ],
            example: 'Male',
            fieldType: {
                type: 'select',
                options: options.gender,
            },
            validations: [
                {
                    rule: 'required',
                    errorMessage: 'Team Gender is required',
                    level: 'error',
                },
            ],
        },
        {
            label: 'Team Grade',
            key: 'grade',
            alternateMatches: ['grade', 'Grade', 'team grade', 'Team Grade'],
            example: 'Blue',
            fieldType: {
                type: 'select',
                options: options.grade,
            },
        },
    ];

    return (
        show && (
            <SpreadsheetImport
                isOpen={show}
                onClose={() => onHide && onHide()}
                onSubmit={handleSubmit}
                fields={importFields}
            />
        )
    );
};

export default TeamsImport;
