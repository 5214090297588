import { useEffect, useMemo, useContext, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { unionBy } from 'lodash';
import { Dialog } from 'primereact/dialog';
import { useGetEventsQuery } from '../../../api/events';
import { InputText } from 'primereact/inputtext';
import {
    useGetTeamOwnedSeasonsQuery,
    useGetTeamParticipatingSeasonsQuery,
} from '../../../api/seasons';
import { useGetPlayersQuery } from '../../../api/players';
import { useGetTeamQuery } from '../../../api/teams';
import { useGetTeamSeasonQuery } from '../../../api/seasons';
import { ToastContext } from '../../../contexts/ToastContext';
import { ToastMessage } from 'primereact/toast';

import { useGetFormationsQuery } from '../../../api/formations';
import { GameLineUp } from '../../../types/game';
import {
    useCreateGameLineupsMutation,
    useLazyGetGameLineupsQuery,
    useUpdateGameLineupsMutation,
} from '../../../api/games';

import PageHeader from '../../../layout/PageHeader';

import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';

import { toISOStringWithTimezone } from '../../../util/helper';

import { Event } from '../../../types/event';
import { BaseEntityType } from '../../../types/common';
import RookieButton from '../../../components/RookieButton';
import PageContainer from '../../../layout/PageContainer';

interface FormationPosition {
    positionID: string;
    positionName: string;
    positionShortName: string;
    status: string;
    xPos: number;
    yPos: number;
    zoneName: string;
    zoneShortName: string;
    formationID?: string;
    playerID: string | null;
    playerName: string | undefined;
}

interface DraggedCard {
    position: any;
    playerName: string | null;
    playerID: string | null;
}

interface BaseLineupData {
    lineUpName: string;
    lineupID?: string;
    formationID: string;
    seasonID: string | null;
    finalFormation: any;
}

interface GameLineupData extends BaseLineupData {
    period: number;
    eventID: string | null;
}

const TeamLineup = () => {
    // Route Params
    const { teamID } = useParams();

    // Search Params
    const [searchParams] = useSearchParams();
    const seasonParam = searchParams.get('seasonID');

    // State Hooks
    const [season, setSeason] = useState<string | null>(seasonParam || null);
    const [event, setEvent] = useState<string>(''); // This is the event ID used to apply a lineup to
    const [selectedFormationID, setSelectedFormationID] = useState<any>(null);
    const [draggedCard, setDraggedCard] = useState<DraggedCard | any>([]);
    const [finalFormation, setFinalFormation] = useState<FormationPosition[]>(
        []
    );
    const [futureMatch, setFutureMatch] = useState<any>(null);
    const [chosenPeriod, setChosenPeriod] = useState<number>(0);
    const [userChangedPeriod, setUserChangedPeriod] = useState(false);
    const [saveEventName, setSaveEventName] = useState('');
    const [playersCursor, setPlayersCursor] = useState<string>('');
    const [loadLineupVisible, setLoadLineupVisible] = useState(false);
    const [resetFormation, setResetFormation] = useState(false);
    const [saveGameVisible, setSaveGameVisible] = useState(false);
    const [chosenLineupID, setChosenLineupID] = useState<string>('');
    const [existingLineupID, setExistingLineupID] = useState<string>('');

    const [isSaving, setIsSaving] = useState(false);
    const [overWrite, setOverWrite] = useState(false);
    const [createLineup] = useCreateGameLineupsMutation();
    const [updateExistingLineup] = useUpdateGameLineupsMutation();
    const toast = useContext(ToastContext);
    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };
    const [chosenSavedLineup, setChosenSavedLineup] = useState<string>('');

    // API hooks
    const teamData = useGetTeamQuery(
        { teamID: teamID || '' },
        { skip: !teamID }
    );
    // API Hooks
    const teamSeason = useGetTeamSeasonQuery(
        {
            seasonID: season || '',
            teamID: teamID || '',
        },
        { skip: !teamID }
    );
    const teamSportID = teamData?.data?.data.entitySportID;
    // Get formations for the given sport
    const formationData = useGetFormationsQuery(
        {
            sportID: teamSportID || '',
        },
        {
            skip: !teamSportID,
        }
    );

    const futureEventdata = useGetEventsQuery({
        entityType: BaseEntityType.teams,
        entityID: teamID || '',
        eventType: 'Game',
        from: toISOStringWithTimezone(new Date()),
        limit: '50',
        status: 'All',
        expand: 'gameDetails',
    });

    const pastEventdata = useGetEventsQuery({
        entityType: BaseEntityType.teams,
        entityID: teamID || '',
        eventType: 'Game',
        to: toISOStringWithTimezone(new Date()),
        limit: '50',
        status: 'All',
    });

    const allEventIDs = useMemo(() => {
        return [
            ...(futureEventdata?.data?.data?.map((event) => event.eventID) ||
                []),
            ...(pastEventdata?.data?.data?.map((event) => event.eventID) || []),
        ];
    }, [futureEventdata, pastEventdata]);

    const [triggerGetLineups] = useLazyGetGameLineupsQuery();
    const [savedLineups, setLineups] = useState<Record<string, any>>({});

    useEffect(() => {
        if (teamID) {
            Promise.all(
                allEventIDs.map(async (eventID) => {
                    try {
                        const data = await triggerGetLineups({
                            teamID,
                            gameID: eventID,
                        }).unwrap();

                        return { [eventID]: data };
                    } catch (error) {
                        return {}; // Return an empty object on failure
                    }
                })
            ).then((results) => {
                const combinedResults = results.reduce(
                    (acc, curr) => ({ ...acc, ...curr }),
                    {}
                );

                setLineups(combinedResults);
            });
        }
    }, [teamID, allEventIDs, triggerGetLineups]);

    useEffect(() => {
        if (event) {
            const existingLineupID = Object.values(savedLineups || '')
                .flatMap((entry) => entry?.data || [])
                .filter((item: GameLineUp) => item.eventID === event);

            if (
                existingLineupID &&
                chosenPeriod > 0 &&
                chosenPeriod <= existingLineupID.length
            ) {
                setExistingLineupID(
                    existingLineupID[chosenPeriod - 1].lineupID
                );
            }
        }
    }, [event, savedLineups, chosenPeriod]);
    useEffect(() => {
        if (existingLineupID !== '') {
            setChosenLineupID(existingLineupID);
        }
    }, [existingLineupID]);

    const playerData = useGetPlayersQuery(
        {
            cursor: playersCursor,
            status: 'Active',
            teamID: teamID || '',
        },
        {
            skip: !teamID,
        }
    );

    const createSaveLineup = () => async (data: any) => {
        setIsSaving(true);
        // Filter out items in finalFormation where positionName is empty or playerID is null
        const filteredFormation = data.finalFormation.filter(
            (item: {
                playerID: string | null;
                positionName: string;
                status: string;
            }) =>
                item.positionName !== '' &&
                item.positionName !== 'Absent' &&
                item.status !== 'Absent' &&
                item.playerID !== null
        );

        setFutureMatch(data.eventID);
        const playerPositions = filteredFormation.map(
            (item: {
                playerID: string;
                positionID: string;
                status: string;
            }) => {
                const positionID = item.positionID.includes('bench')
                    ? 'bench'
                    : item.positionID;
                return {
                    playerID: item.playerID,
                    positionID: positionID,
                    status: item.status,
                };
            }
        );

        const apiPayload = {
            lineUpName: data.lineUpName,
            formationID: data.formationID,
            period: data.period,
            playerPositions: playerPositions,
        };

        try {
            const response = await createLineup({
                teamID: teamID!,
                gameID: data.eventID!,
                body: apiPayload,
            });

            if ('error' in response) {
                setSaveGameVisible(false);
                setOverWrite(true);
            } else if ('data' in response) {
                showToast({
                    severity: 'success',
                    detail: 'Successfully saved lineup.',
                });
            }

            setIsSaving(false);
        } catch (e) {
            showToast({
                severity: 'error',
                detail: 'There was an error saving the lineup. Please check all entry fields and try again.',
                summary: 'Error',
            });
            setIsSaving(false);
        }
    };

    const [saveGameLineup] = useState<(data: GameLineupData) => void>(() =>
        createSaveLineup()
    );

    const handleSaveClick = () => {
        setSaveGameVisible(true);
    };

    const handleFormationLoad = () => {
        setLoadLineupVisible(true);
        setResetFormation(true);
    };

    const handleSaveGameClick = () => handleSaveClick();

    const handleSave = () => {
        const lineupData: BaseLineupData = {
            lineUpName: saveEventName, // The name the user entered
            lineupID: existingLineupID,
            formationID: selectedFormation.formationID,
            seasonID: season,
            finalFormation,
        };

        // Add the period field for the game lineup
        const gameLineupData: GameLineupData = {
            ...lineupData, // Spread the base lineupData
            period: chosenPeriod,
            eventID: futureMatch,
        };
        saveGameLineup(gameLineupData); // API call for game lineup

        setSaveGameVisible(false);
    };

    const handleUpdate = () => {
        // Create the base lineup data
        const lineupData: BaseLineupData = {
            lineUpName: saveEventName,
            lineupID: existingLineupID,
            formationID: selectedFormation.formationID,
            seasonID: season,
            finalFormation,
        };

        // Add the period field for the game lineup
        const gameLineupData: GameLineupData = {
            ...lineupData,
            period: chosenPeriod,
            eventID: futureMatch,
        };
        updateGameLineup(gameLineupData, chosenLineupID, existingLineupID);

        setOverWrite(false);
    };

    const handleLoadLineUp = () => {
        // Get the loaded lineup formation
        let filteredLoadedFormation = Object.values(savedLineups || {})
            .flatMap((entry) => entry?.data || [])
            .filter((item: GameLineUp) => item.lineupID === chosenSavedLineup);

        if (filteredLoadedFormation && filteredLoadedFormation.length > 0) {
            setSelectedFormationID(filteredLoadedFormation[0].formationID);

            if (
                !formationOptions.some(
                    (option) =>
                        option.value === filteredLoadedFormation[0].formationID
                )
            ) {
                showToast({
                    severity: 'error',
                    detail: 'The chosen lineup does not match your season settings for available formations. Either amend your season settings or load a different lineup.',
                    summary: 'Error',
                });
            }
        }

        filteredLoadedFormation = filteredLoadedFormation.map((formation) => {
            let benchIndex = 1;
            let otherIndex = 1;

            return {
                ...formation,
                playerPositions: formation.playerPositions.map((player: any) =>
                    player.positionID === 'bench' || player.status === 'Bench'
                        ? { ...player, positionID: `bench${benchIndex++}` }
                        : player.status === 'Absent'
                        ? { ...player, positionID: `other${otherIndex++}` }
                        : player
                ),
            };
        });
        console.log('loaded lineup: adjusted', filteredLoadedFormation);
        console.log('final lineup', finalFormation);
        setChosenLineupID(filteredLoadedFormation[0].lineupID as string);

        const normalizedFilteredLoadedFormation = Array.isArray(
            filteredLoadedFormation
        )
            ? filteredLoadedFormation
            : [filteredLoadedFormation];

        setFinalFormation((prevFinalFormation) =>
            prevFinalFormation.map((finalPlayer) => {
                const matchedPlayer = normalizedFilteredLoadedFormation
                    .flatMap((gameData) => gameData?.playerPositions)
                    .find(
                        (filteredPlayer) =>
                            finalPlayer.positionID ===
                            filteredPlayer?.positionID
                    );

                if (matchedPlayer) {
                    // Find the matching player in playerData to get displayName
                    const playerInfo = playerData?.currentData?.data?.find(
                        (player) => player.playerID === matchedPlayer.playerID
                    );

                    return {
                        ...finalPlayer,
                        playerID: matchedPlayer.playerID,
                        playerName: playerInfo?.displayName || 'Unknown',
                    };
                }

                if (
                    normalizedFilteredLoadedFormation
                        .flatMap((gameData) => gameData?.playerPositions)
                        .some(
                            (filteredPlayer) =>
                                finalPlayer.playerID ===
                                filteredPlayer?.playerID
                        )
                ) {
                    return {
                        ...finalPlayer,
                        playerID: null,
                        playerName: undefined,
                    };
                }

                return finalPlayer;
            })
        );

        setLoadLineupVisible(false);
        setChosenSavedLineup('');
    };

    const updateLineup =
        () =>
        async (data: any, chosenLineupID: string, existingLineupID: string) => {
            setIsSaving(true);

            const filteredFormation = data.finalFormation.filter(
                (item: {
                    playerID: string | null;
                    positionName: string;
                    status: string;
                }) =>
                    item.positionName !== '' &&
                    item.positionName !== 'Absent' &&
                    item.status !== 'Absent' &&
                    item.playerID !== null
            );

            setFutureMatch(data.eventID);
            const playerPositions = filteredFormation.map(
                (item: {
                    playerID: string;
                    positionID: string;
                    status: string;
                }) => {
                    const positionID = item.positionID.includes('bench')
                        ? 'bench'
                        : item.positionID;
                    return {
                        playerID: item.playerID,
                        positionID: positionID,
                        status: item.status,
                    };
                }
            );

            const apiPayload = {
                lineUpName: data.lineUpName,
                formationID: data.formationID,
                period: data.period,
                playerPositions: playerPositions,
            };

            try {
                const response = await updateExistingLineup({
                    teamID: teamID!,
                    gameID: data.eventID!,
                    lineupID:
                        existingLineupID === ''
                            ? chosenLineupID
                            : existingLineupID,
                    body: apiPayload,
                });

                if ('error' in response) {
                    let errorMessage =
                        'The lineup update has failed, please check all fields and try again.';

                    showToast({
                        severity: 'error',
                        detail: errorMessage,
                        summary: 'Error',
                    });
                } else if ('data' in response) {
                    showToast({
                        severity: 'success',
                        detail: 'Successfully updated lineup.',
                    });
                }

                setIsSaving(false);
            } catch (e) {
                showToast({
                    severity: 'error',
                    detail: 'The lineup update has failed, please check all fields and try again.',
                    summary: 'Error',
                });
                setIsSaving(false);
            }
        };

    const [updateGameLineup] = useState<
        (
            data: GameLineupData,
            setChosenLineupID: string,
            existingLineupID: string
        ) => void
    >(() => updateLineup());

    const dialogSaveFooter = (
        <RookieButton label="Save" onClick={handleSave} icon="save" />
    );

    const dialogUpdateFooter = (
        <RookieButton label="Save" onClick={handleUpdate} icon="save" />
    );

    const dialogLoadFooter = (
        <RookieButton label="Load" onClick={handleLoadLineUp} icon="download" />
    );

    const maxOnField = teamSeason?.data?.data?.maxOnField;

    const availableFormations = useMemo(() => {
        return (
            formationData?.data?.data.filter(
                (entry) => entry.maxOnField === maxOnField
            ) || []
        );
    }, [formationData, maxOnField]);

    const selectedFormation = useMemo(() => {
        const formation = availableFormations.find(
            (formation) => formation.formationID === selectedFormationID
        );

        if (!formation) {
            return {
                formationID: 'default',
                formationName: 'Default Formation',
                positions: [],
            };
        }

        return {
            ...formation,
        };
    }, [availableFormations, selectedFormationID]);

    const [adjustedFormation, setAdjustedFormation] = useState(() => ({
        formationID: selectedFormation?.formationID || '',
        positions: selectedFormation?.positions || [],
    }));

    useEffect(() => {
        if (chosenSavedLineup !== '') {
            let filteredLoadedFormation = Object.values(savedLineups || {}) // Extract all { data: [...] } objects
                .flatMap((entry) => entry?.data || []) // Flatten all data arrays
                .filter(
                    (item: GameLineUp) => item.lineupID === chosenSavedLineup
                );

            setSelectedFormationID(filteredLoadedFormation[0]?.formationID);
        }
    }, [chosenSavedLineup, savedLineups]);

    useEffect(() => {
        if (
            selectedFormation &&
            adjustedFormation?.formationID !== selectedFormation?.formationID
        ) {
            setAdjustedFormation({ ...selectedFormation });
        }
    }, [selectedFormation, adjustedFormation]); // Dependency on selectedFormation only

    // This useEffect creates the finalFormation based on user actions
    useEffect(() => {
        if (!selectedFormation || !playerData?.currentData?.data) return;

        const players = playerData.currentData.data; // Extract player list

        // Base formation with empty players
        const formationPositions: FormationPosition[] =
            selectedFormation.positions.map((position) => ({
                ...position,
                playerID: null,
                playerName: undefined,
                status: 'Field',
            }));

        const allEntries: FormationPosition[] = [
            ...Array.from({ length: 10 }, (_, index) => ({
                positionID: `bench${index + 1}`, // "bench1", "bench2", etc.
                positionName: 'Bench',
                positionShortName: 'B',
                status: 'Bench',
                xPos: -10 - Math.floor(index / 10) * 20, // Decrease xPos by 10 every 10 iterations
                yPos: 10 * ((index % 10) + 1), // Reset yPos to 10 after every 10 iterations
                zoneName: '',
                zoneShortName: '',
                formationID: selectedFormation.formationID,
                playerID: null,
                playerName: undefined,
            })),
            ...players.map((player, index) => {
                const xPos = -30 - Math.floor(index / 10) * 20; // Decrease xPos by 10 every 10 iterations
                const yPos = 10 * ((index % 10) + 1); // Reset yPos to 10 after every 10 iterations

                return {
                    positionID: `other${index + 1}`, // Generate "other1", "other2", etc.
                    positionName: 'Absent',
                    positionShortName: `O${index + 1}`,
                    xPos: xPos,
                    yPos: yPos,
                    zoneName: '',
                    zoneShortName: '',
                    status: 'Absent',
                    formationID: selectedFormation.formationID,
                    playerID: player.playerID,
                    playerName: player.displayName,
                };
            }),
        ];

        // Combine both lists
        setFinalFormation([...formationPositions, ...allEntries]);
        setResetFormation(false);
    }, [selectedFormation, playerData, resetFormation]);

    // Set formtation ID to the first available
    useEffect(() => {
        if (!selectedFormationID && availableFormations.length > 0) {
            setSelectedFormationID(availableFormations[0].formationID);
        }
    }, [selectedFormationID, availableFormations]);

    const seasonOwnedData = useGetTeamOwnedSeasonsQuery(
        {
            teamID: teamID || '',
            cursor: '',
        },
        { skip: !teamID }
    );

    const seasonParticipatingData = useGetTeamParticipatingSeasonsQuery(
        {
            teamID: teamID || '',
            cursor: '',
        },
        { skip: !teamID }
    );

    // Helpers
    const mergedSeasons = useMemo(() => {
        const ownedSeasons = seasonOwnedData?.data?.data;
        const participatingSeasons = seasonParticipatingData?.data?.data;

        return ownedSeasons && participatingSeasons
            ? unionBy(ownedSeasons, participatingSeasons, 'seasonID')
            : [];
    }, [seasonOwnedData, seasonParticipatingData]);

    // Auto paginate players
    useEffect(() => {
        const { data } = playerData;

        if (
            data?.lastEvaluatedKey &&
            data.lastEvaluatedKey.cursor &&
            data.lastEvaluatedKey.cursor !== playersCursor
        ) {
            setPlayersCursor(data.lastEvaluatedKey.cursor);
        }
    }, [playerData, playersCursor]);

    // Set the season to the first available season
    useEffect(() => {
        if (!season && mergedSeasons.length > 0) {
            setSeason(teamData?.data?.data.defaultSeasonID || '');
        }
    }, [mergedSeasons, season, teamData]);

    // Future Event Filter Options
    const futureEventOptions = useMemo(() => {
        let options: any = [];

        if (futureEventdata.data) {
            futureEventdata.data.data.forEach((event: Event) => {
                // Filter events with eventStatus === 'Complete'
                if (event.eventStatus === 'Published') {
                    options.push({
                        label: event.eventName,
                        value: event.eventID,
                    });
                }
            });
        }

        // Filter out options where error is true
        return options.filter((option: any) => !option.error);
    }, [futureEventdata]);

    // Get the period options for the future event and apply to dropdown
    const totalPeriodOptions = useMemo(() => {
        let options: { label: string; value: number }[] = [];

        if (event !== '') {
            const filteredData = futureEventdata?.data?.data.filter(
                (item: Event) => item.eventID === event
            );

            if (filteredData && filteredData.length > 0) {
                const maxPeriods =
                    Number(filteredData[0].gameDetails?.settings?.periods) || 0;

                // Generate an array from 1 to maxPeriods and map to objects with label and value
                options = Array.from({ length: maxPeriods }, (_, index) => ({
                    label: (index + 1).toString(),
                    value: index + 1,
                }));
            }
        }
        return options;
    }, [event, futureEventdata]);

    useEffect(() => {
        if (totalPeriodOptions.length > 0 && !userChangedPeriod) {
            setChosenPeriod(totalPeriodOptions[0].value);
        }
    }, [totalPeriodOptions, userChangedPeriod]);

    useMemo(() => {
        if (futureMatch) {
            setEvent(futureMatch);
        }
    }, [futureMatch]);

    const formationOptions = useMemo(() => {
        let options = availableFormations
            ? availableFormations?.map((data) => ({
                  label: data.formationName,
                  value: data.formationID,
              }))
            : [];

        return options;
    }, [availableFormations]);

    const savedLineupOptions = useMemo(() => {
        let options = Object.values(savedLineups || {})
            .flatMap((entry) => entry?.data || [])
            .sort(
                (a, b) =>
                    new Date(b.lastEdited).getTime() -
                    new Date(a.lastEdited).getTime()
            )
            .filter((lineup) =>
                formationOptions.some(
                    (formation) => formation.value === lineup.formationID
                )
            )
            .map((lineup) => ({
                label: `Name: ${lineup.lineUpName} | Period: ${lineup.period}`,
                value: lineup.lineupID,
            }));

        return options;
    }, [savedLineups, formationOptions]);

    // This handles the text change for saving a formations name
    const handleTextChange = (e: React.FormEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        if (value.length <= 25) {
            setSaveEventName(value);
        }
    };

    const leftToolbar = (
        <div className="p-button-group">
            <Dropdown
                value={futureMatch}
                options={futureEventOptions}
                onChange={(e) => setFutureMatch(e.value)}
                placeholder="Select a Future Match"
            />
            {futureMatch && (
                <>
                    <Dropdown
                        value={chosenPeriod}
                        options={totalPeriodOptions}
                        onChange={(e) => {
                            setChosenPeriod(e.value);
                            setUserChangedPeriod(true);
                        }}
                    />
                    <Dropdown
                        value={selectedFormationID}
                        options={formationOptions}
                        onChange={(e) => setSelectedFormationID(e.value)}
                    />
                </>
            )}
        </div>
    );

    const rightToolbar = (
        <div className="p-button-group">
            {futureMatch && (
                <>
                    <RookieButton
                        type="button"
                        onClick={handleFormationLoad}
                        label="Load LineUp"
                    />
                    <RookieButton
                        type="button"
                        onClick={handleSaveGameClick}
                        label="Save to Game"
                        severity="secondary"
                    />
                </>
            )}
        </div>
    );

    const handleDragStart = (e: React.DragEvent, position: any) => {
        setDraggedCard({ position });
    };

    const handleDrop = (e: React.DragEvent, targetPosition: any) => {
        e.preventDefault();

        if (!draggedCard) return;

        // Valid drop into a target
        updatePlayerName(draggedCard, targetPosition);

        // Reset dragged card state
        setDraggedCard(null);
    };

    const updatePlayerName = (draggedCard: any, targetPosition: any) => {
        // Clone the formation to avoid directly modifying the state
        const updatedFormation = [...finalFormation];

        // Find the index of the dragged card in the formation
        const draggedCardIndex = updatedFormation.findIndex(
            (position) =>
                position.positionID === draggedCard.position.positionID
        );

        // Find the index of the target position in the formation
        const targetPositionIndex = updatedFormation.findIndex(
            (position) => position.positionID === targetPosition.positionID
        );

        if (draggedCardIndex !== -1 && targetPositionIndex !== -1) {
            // Swap the playerName and playerID between draggedCard and targetPosition
            const tempPlayerName =
                updatedFormation[draggedCardIndex].playerName;
            const tempPlayerID = updatedFormation[draggedCardIndex].playerID;
            updatedFormation[draggedCardIndex].playerName =
                updatedFormation[targetPositionIndex].playerName;
            updatedFormation[draggedCardIndex].playerID =
                updatedFormation[targetPositionIndex].playerID;
            updatedFormation[targetPositionIndex].playerName = tempPlayerName;
            updatedFormation[targetPositionIndex].playerID = tempPlayerID;
        }

        // Set the updated formation to the state
        setFinalFormation(updatedFormation);
    };

    const renderPlayerName = (position: any) => {
        if (position && position.playerName) {
            return position.playerName || null;
        }
        return null;
    };

    return (
        <PageContainer>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <PageHeader title="Team Lineup" />
                <RookieButton
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        color: 'inherit',
                        opacity: 'inherit',
                        marginLeft: '-90px',
                        marginTop: '50px',
                    }}
                    text={true}
                    data-beacon-article="672aeb91548b490ca0c6089b"
                    icon="help"
                />
            </div>
            <Toolbar start={leftToolbar} end={rightToolbar} />

            {/* Save Game Lineup Dialog Component */}
            <Dialog
                header="Save your lineup to one of your future matches"
                className=".generate-box"
                visible={saveGameVisible}
                onHide={() => setSaveGameVisible(false)}
                footer={dialogSaveFooter}
                style={{ width: '50vw' }}
            >
                <div className="p-button-group">
                    <InputText
                        id="eventName"
                        name="eventName"
                        placeholder="Lineup Name"
                        required
                        onInput={handleTextChange}
                        value={saveEventName}
                    />
                    <Dropdown
                        value={futureMatch}
                        options={futureEventOptions}
                        onChange={(e) => setFutureMatch(e.value)}
                        placeholder="Select a Future Match"
                    />
                    <Dropdown
                        value={chosenPeriod}
                        options={totalPeriodOptions}
                        onChange={(e) => {
                            setChosenPeriod(e.value);
                            setUserChangedPeriod(true);
                        }}
                    />
                </div>
            </Dialog>
            {/* Load Game Lineup Dialog Component */}
            <Dialog
                header="Load past lineup"
                className=".generate-box"
                visible={loadLineupVisible}
                onHide={() => setLoadLineupVisible(false)}
                footer={dialogLoadFooter}
                style={{ width: '50vw' }}
            >
                <div className="p-button-group">
                    <Dropdown
                        onChange={(e) => {
                            setChosenSavedLineup(e.value);
                        }}
                        value={chosenSavedLineup}
                        options={savedLineupOptions}
                        placeholder="Select Saved Lineup"
                    />
                </div>
            </Dialog>
            {/* Load Game Lineup Dialog Component */}
            <Dialog
                header="Overwrite existing lineup"
                className=".generate-box"
                visible={overWrite}
                onHide={() => setOverWrite(false)}
                footer={dialogUpdateFooter}
                style={{ width: '50vw' }}
            >
                <div className="p-button-group">
                    This will overwrite the existing lineup for the period of
                    the match selected. Click save to continue.
                </div>
            </Dialog>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                }}
            >
                {isSaving ? (
                    'Saving...'
                ) : futureMatch !== null ? (
                    <div
                        className="formation-container"
                        style={{ display: 'flex' }}
                    >
                        {/* Center Section: Field and Players */}
                        <div
                            className="formation-view"
                            style={{ display: 'inline-block', flex: 1 }}
                        >
                            {/* Field Image */}
                            <img
                                src={`https://cdn.hub.rookieme.com/sports_ffields/${teamSportID}_field-0.svg`}
                                alt="Field"
                                className="formation-view_field"
                            />
                            {finalFormation?.map((position) => {
                                const playerName = renderPlayerName(position);
                                const isOpaque =
                                    !position.positionID
                                        .toLowerCase()
                                        .includes('other') && !playerName;
                                return (
                                    <div
                                        className={`player-card ${
                                            position.positionName ===
                                            'Unavailable'
                                                ? 'unavailable'
                                                : ''
                                        }`}
                                        style={{
                                            top: `${position.yPos}%`,
                                            left: `${position.xPos}%`,
                                            opacity: isOpaque ? 0.85 : 1,
                                        }}
                                        key={position.positionID}
                                        draggable
                                        onDragStart={(e) =>
                                            handleDragStart(e, position)
                                        }
                                        onDragOver={(e) => e.preventDefault()}
                                        onDrop={(e) => handleDrop(e, position)}
                                    >
                                        <div className="player-card_content">
                                            <div className="player-card_id">
                                                {position.positionName}
                                            </div>
                                            <div className="player-card_name">
                                                {position.playerName}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ) : null}
            </div>
        </PageContainer>
    );
};

export default TeamLineup;
