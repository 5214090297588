import React, { useContext, useEffect, useMemo, useState } from 'react';
import { groupBy, sortBy } from 'lodash';
import { useSearchParams } from 'react-router-dom';

import {
    useCreateCheckoutSessionMutation,
    useGetLicenceGroupsQuery,
    useUpdateLicenceGroupMutation,
} from '../../api/licences';
import { useGetEntityQuery } from '../../api/core';

import { ToastContext } from '../../contexts/ToastContext';
import { formatPrice, isNullOrUndef } from '../../util/helper';
import {
    STATIC_ENDPOINT_LICENCE_TYPES,
    STATIC_ENDPOINT_SPORTS,
} from '../../util/constants';
import { checkDiscount, DISCOUNT_AMOUNT } from './discount';

import PlansView from './PlansView';

import Loader from '../../components/Loader';
import { ToastMessage } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';

import { Roles } from '../../types/roles';
import { Route } from '../../types/route';
import { Sport } from '../../types/sports';
import { User } from '../../types/user';
import { BaseEntityType } from '../../types/common';
import { LicenceType, RookieProduct } from '../../types/licences';
import { PlanEssentials, StripeInterval } from '../../types/subscriptions';

interface UpdateLicenceGroupArgs {
    licenceGroupID: string;
    priceID: string;
}

type SelectedPrices = {
    [key: string]: PlanEssentials;
};

interface Props {
    user: User;
    roles: Roles;
    route: Route;
}

const PlansContainer = ({ user }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const toast = useContext(ToastContext);

    const returnUrl = searchParams.get('returnUrl');
    const entityTypeParam = searchParams.get('entityType') as BaseEntityType;
    const entityIDParam = searchParams.get('entityID');
    const sportIDParam = searchParams.get('sportID');
    const productParam = searchParams.get('rookieProduct');
    const promoParam = searchParams.get('promo');
    const qtyParam = searchParams.get('qty');

    // API Hooks
    const { data: entityData, isLoading: loadingEntity } = useGetEntityQuery(
        {
            entityType: entityTypeParam as BaseEntityType,
            entityID: entityIDParam as string,
            expand:
                entityTypeParam === BaseEntityType.teams
                    ? 'organisationDetails'
                    : '',
        },
        { skip: !user || !entityTypeParam || !entityIDParam }
    );

    const { data: licenceGroupData, isLoading: loadingLicenceGroups } =
        useGetLicenceGroupsQuery(
            {
                entityType: entityTypeParam as BaseEntityType,
                entityID: entityIDParam as string,
            },
            { skip: !user || !entityTypeParam || !entityIDParam }
        );

    const [createCheckoutSession] = useCreateCheckoutSessionMutation();
    const [updateLicenceGroup] = useUpdateLicenceGroupMutation();

    const [sports, setSports] = useState<Sport[]>([]);
    const [licenceTypes, setLicenceTypes] = useState<LicenceType[]>([]);

    // Validate sportID
    const defaultSportID =
        sportIDParam && sports.some((sport) => sport.sportID === sportIDParam)
            ? sportIDParam
            : null;

    const [selectedSport, setSelectedSport] = useState<string | null>(
        defaultSportID
    );
    const [expandSummary, setExpandSummary] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [qty, setQty] = useState(qtyParam ? Number(qtyParam) : 1);
    const [billingInterval, setBillingInterval] =
        useState<StripeInterval>('year');
    const [selectedPrices, setSelectedPrices] = useState<SelectedPrices>({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Promise.all([
            fetch(STATIC_ENDPOINT_SPORTS).then((res) => res.json()),
            fetch(STATIC_ENDPOINT_LICENCE_TYPES).then((res) => res.json()),
        ])
            .then(([sportsData, licenceTypesData]) => {
                setSports(sportsData);
                setLicenceTypes(licenceTypesData);
            })
            .catch((error) => console.error('Error fetching data:', error))
            .finally(() => setLoading(false)); // Set loading to false after both requests complete
    }, []);

    // Flatten all prices within a licenceType and make details more consumable
    const plans = useMemo(() => {
        let arr: PlanEssentials[] = [];

        licenceTypes.forEach(
            ({
                product,
                licenceTypeID,
                rookieProduct,
                licenceName,
                sportID,
                sortOrder,
                features,
            }) => {
                product?.prices.forEach((price) => {
                    arr.push({
                        id: price.priceID,
                        title: `${licenceName} ${
                            price.inclusions && price.inclusions === 'reports'
                                ? ' + Reports'
                                : ''
                        }`,
                        price: (price.unitAmount || 0) / 100,
                        currency: price.currency,
                        interval: price.recurring?.interval,
                        trial: price.recurring?.trial_period_days,
                        description: product.productDescription || '',
                        licenceTypeID,
                        rookieProduct,
                        sportID,
                        sortOrder,
                        productFeatures: product.productFeatures,
                        features: features,
                        tier: price.tier,
                        inclusions: price.inclusions,
                    });
                });
            }
        );

        return sortBy(arr, ['sortOrder', 'rookieProduct']);
    }, [licenceTypes]);

    const groupedPlans = useMemo((): {
        [key: string]: PlanEssentials[];
    } => {
        const types = plans.filter((o) => {
            const filterProduct =
                !productParam ||
                (productParam && o.rookieProduct === productParam);
            const filterBilling = billingInterval === o.interval;
            const filterSport = o.sportID === selectedSport;

            return filterSport && filterBilling && filterProduct;
        });
        return groupBy(types, 'rookieProduct');
    }, [plans, selectedSport, billingInterval, productParam]);

    const total = useMemo(() => {
        let value = 0;
        Object.values(selectedPrices).forEach((price) => {
            const hasDiscount = checkDiscount(
                price.sportID,
                price.rookieProduct
            );
            value +=
                (hasDiscount && !isNullOrUndef(DISCOUNT_AMOUNT)
                    ? price.price - price.price * DISCOUNT_AMOUNT
                    : price.price) * qty;
        });

        return formatPrice(value);
    }, [qty, selectedPrices]);

    const unavailableSportIDs = useMemo(() => {
        if (productParam) {
            return sports
                .filter((sport) => {
                    return !plans.some(
                        (o) =>
                            o.sportID === sport.sportID &&
                            o.rookieProduct === productParam
                    );
                })
                .map((sport) => sport.sportID);
        }
        return [];
    }, [plans, productParam, sports]);

    // Set sport as entities sport if provided
    useEffect(() => {
        if (entityData?.data?.entitySportID) {
            setSelectedSport(entityData.data.entitySportID);
        }
    }, [entityData]);

    useEffect(() => {
        const updatedPrices: SelectedPrices = {};

        let hasChanges = false;

        Object.entries(groupedPlans).forEach(([rookieProduct, currPlans]) => {
            const selectedPrice = selectedPrices[rookieProduct];

            const isValidSelectedPrice = currPlans.some(
                (plan) => selectedPrice && plan.id === selectedPrice.id
            );

            // If theres no price selected, or if there's no valid price for product
            if (!selectedPrice || !isValidSelectedPrice) {
                // Check if there is an equivalent plan for the new product.
                // Find equivalent plan with report to preselect report addon.
                const prevPlan =
                    selectedPrice &&
                    plans.find((plan) => plan.id === selectedPrice.id);

                const matchedPlan =
                    prevPlan &&
                    (currPlans.find(
                        (plan) => plan.tier === prevPlan.tier && plan.inclusions
                    ) ||
                        currPlans.find((plan) => plan.tier === prevPlan.tier));

                const newPrice = matchedPlan ? matchedPlan : currPlans[0];

                if (
                    !selectedPrices[rookieProduct] ||
                    selectedPrices[rookieProduct].id !== newPrice.id
                ) {
                    hasChanges = true;
                }

                updatedPrices[rookieProduct] = newPrice;
            } else {
                updatedPrices[rookieProduct] = selectedPrice;
            }
        });

        // Only update state if changes have been made to prevent endless loop
        if (hasChanges) {
            setSelectedPrices(updatedPrices);
        }
    }, [groupedPlans, plans, selectedPrices]);

    const handleSportClick = (sport: Sport) => {
        setSelectedSport(sport.sportID);
    };

    const handleIntervalChange = (interval: StripeInterval) => {
        setBillingInterval(interval);
    };

    const handlePriceChange = (
        product: RookieProduct,
        price: PlanEssentials
    ) => {
        setSelectedPrices((prev) => {
            if (prev[product].inclusions) {
                const matchPlanned = groupedPlans[product].find(
                    (plan) => plan.tier === price.tier && plan.inclusions
                );

                if (matchPlanned) {
                    return {
                        ...prev,
                        [product]: matchPlanned,
                    };
                }
            }

            return {
                ...prev,
                [product]: price,
            };
        });
    };

    const handleReportToggle = (
        product: RookieProduct,
        price: PlanEssentials
    ) => {
        setSelectedPrices((prev) => {
            if (price === prev[product]) {
                const matchedPlan =
                    groupedPlans[product].find(
                        (plan) => plan.tier === price.tier && !plan.inclusions
                    ) || groupedPlans[product][0];

                return {
                    ...prev,
                    [product]: matchedPlan,
                };
            } else {
                return {
                    ...prev,
                    [product]: price,
                };
            }
        });
    };

    const handleToggleSummary = () => {
        setExpandSummary((show) => !show);
    };

    const handleQtyChange = (qty: number) => {
        if (qty > 0 && qty < 100) {
            setQty(qty);
        }
    };

    const handleCreateLicenceGroup = async (prices: PlanEssentials[]) => {
        if (!entityIDParam || !entityTypeParam) {
            return;
        }

        const returnUrl = searchParams.get('returnUrl') || window.location.href;

        const items = prices.map((price) => ({
            quantity: qty,
            price: price.id,
        }));

        try {
            const response = await createCheckoutSession({
                entityType: entityTypeParam,
                entityID: entityIDParam,
                items,
                successURL: returnUrl,
                cancelURL: window.location.href,
                promoCode: promoParam || '',
            });

            if ('error' in response) {
                const error: any = response.error;
                const errorMsg = error.data.error;

                showToast({
                    severity: 'error',
                    summary: 'Error',
                    detail:
                        errorMsg || `Failed to update ${items.length} plan(s).`,
                });
            } else {
                if ('status' in response.data) {
                    const status = response.data.status;

                    if (status === 201) {
                        // Successfully created, bypass checkout and handle Success

                        showToast({
                            severity: 'success',
                            summary: 'Success',
                            detail: `Successfully updated ${items.length} plan(s).`,
                        });

                        window.location.href = returnUrl;
                    } else if (status === 200) {
                        // Handle Redirection to Stripe Checkout
                        window.location.href = response.data.data.url;
                    }
                }
            }
        } catch (error) {
            showToast({
                severity: 'error',
                summary: 'Error',
                detail: 'There was an error creating a checkout session. Please try again later.',
            });
        }
    };

    const handleUpdateLicenceGroup = async (
        updatePlans: UpdateLicenceGroupArgs[]
    ) => {
        const updatedPlans: string[] = [];
        const failedPlans: string[] = [];

        try {
            await Promise.allSettled(
                updatePlans.map(async ({ licenceGroupID, priceID }) => {
                    try {
                        await updateLicenceGroup({
                            entityType: entityTypeParam,
                            entityID: entityIDParam,
                            quantity: qty,
                            licenceGroupID,
                            priceID,
                        }).unwrap();

                        // Collect successful updates
                        updatedPlans.push(priceID);
                    } catch (error) {
                        // Collect failed updates
                        failedPlans.push(priceID);
                    }
                })
            );

            // Show a single toast message summarizing updates
            if (updatedPlans.length > 0) {
                showToast({
                    severity: 'success',
                    summary: 'Success',
                    detail: `Successfully updated ${updatedPlans.length} plan(s).`,
                });
            }

            if (failedPlans.length > 0) {
                showToast({
                    severity: 'error',
                    summary: 'Error',
                    detail: `Failed to update ${failedPlans.length} plan(s).`,
                });
            }
        } catch (error) {
            showToast({
                severity: 'error',
                summary: 'Error',
                detail: 'An unexpected error occurred while updating plans.',
            });
        }
    };

    const handleCheckoutClick = async (prices: PlanEssentials[]) => {
        if (!entityTypeParam || !entityIDParam) {
            showToast({
                severity: 'error',
                summary: 'Error',
                detail: 'No Entity Found',
            });

            return;
        }

        const newPlans: PlanEssentials[] = [];
        const updatePlans: UpdateLicenceGroupArgs[] = [];

        // Ensure all prices are sorted before continuing
        await Promise.all(
            prices.map(async (price) => {
                const licenceGroup = licenceGroupData?.data.find(
                    (lg) =>
                        lg.rookieProduct === price.rookieProduct &&
                        lg.subscriptionID !== '' &&
                        lg.subscriptionID !== 'NA'
                );

                const existingLicenceGroup = licenceGroupData?.data.find(
                    (lg) => lg.licenceTypeID === price.licenceTypeID
                );

                if (
                    existingLicenceGroup &&
                    existingLicenceGroup.priceID === price.id
                ) {
                    // Skip processing if the same plan is already active
                    return;
                }

                if (licenceGroup) {
                    updatePlans.push({
                        licenceGroupID: licenceGroup.licenceGroupID,
                        priceID: price.id,
                    });
                } else {
                    newPlans.push(price);
                }
            })
        );

        // Check if any updated plan has a billing cycle change
        const isSwitchingBilling = updatePlans.some((plan) => {
            const licenceGroup = licenceGroupData?.data.find(
                (lg) => lg.licenceGroupID === plan.licenceGroupID
            );
            const newPlan = prices.find((p) => p.id === plan.priceID);

            return (
                licenceGroup?.subscription?.plan?.interval &&
                licenceGroup.subscription.plan.interval !== newPlan?.interval
            );
        });

        // Show confirmation only if there are BOTH updates and new plans
        const isUpdatingAndCreating =
            updatePlans.length > 0 && newPlans.length > 0;

        if (isSwitchingBilling || isUpdatingAndCreating) {
            let confirmMessage: string | React.ReactNode = '';

            if (isSwitchingBilling) {
                confirmMessage = (
                    <div>
                        <p>You are switching to a different billing cycle.</p>
                        <p>
                            Your change will take effect after your current
                            billing cycle ends. If you want the change to take
                            effect immediately, you must cancel your current
                            subscription and repurchase the new licence.
                        </p>
                    </div>
                );
            } else if (isUpdatingAndCreating) {
                confirmMessage = (
                    <div>
                        <p>
                            Some of your selected plans already exist and will
                            be updated. After updating, you will proceed to
                            checkout your new plans.
                        </p>
                    </div>
                );
            }

            confirmDialog({
                message: confirmMessage,
                header: 'Confirm Subscription Changes',
                acceptLabel: 'Continue',
                rejectLabel: 'Cancel',
                accept: async () => {
                    await processPlans(updatePlans, newPlans);
                },
            });
        } else {
            // If there are only updates OR only new plans, proceed without confirmation
            await processPlans(updatePlans, newPlans);
        }
    };

    // Helper function to process plans
    const processPlans = async (
        updatePlans: UpdateLicenceGroupArgs[],
        newPlans: PlanEssentials[]
    ) => {
        setSubmitting(true);

        // Update existing plans if there are any
        if (updatePlans.length > 0) {
            await handleUpdateLicenceGroup(updatePlans);
        }

        // Create new plans if there are any
        if (newPlans.length > 0) {
            await handleCreateLicenceGroup(newPlans);
        }

        setSubmitting(false);
    };

    const clearSearchParams = () => {
        if (searchParams.has('entityID')) {
            searchParams.delete('entityID');
        }
        if (searchParams.has('entityType')) {
            searchParams.delete('entityType');
        }
        if (searchParams.has('sportID')) {
            searchParams.delete('sportID');
        }
        if (searchParams.has('rookieProduct')) {
            searchParams.delete('rookieProduct');
        }

        setSearchParams(searchParams);
    };

    const activePrice = (plan: PlanEssentials): boolean => {
        return !!(
            licenceGroupData &&
            licenceGroupData.data.some(
                (group) =>
                    group.status === 'Active' && group.priceID === plan.id
            )
        );
    };

    const activeTier = (plan: PlanEssentials): boolean => {
        const activePrice: PlanEssentials | undefined = plans.find(
            (p) =>
                licenceGroupData?.data.some(
                    (group) => group.priceID === p.id
                ) &&
                p.rookieProduct === plan.rookieProduct &&
                p.tier === plan.tier &&
                p.sportID === plan.sportID
        );

        if (activePrice) {
            return plans.some(
                (p) =>
                    p.rookieProduct === activePrice.rookieProduct &&
                    p.sportID === activePrice.sportID &&
                    p.tier === activePrice.tier &&
                    p.id === activePrice.id
            );
        }

        return false;
    };

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    if (loading || loadingEntity || loadingLicenceGroups) {
        return <Loader />;
    }

    return (
        <PlansView
            billingInterval={billingInterval}
            activePrice={activePrice}
            activeTier={activeTier}
            clearSearchParams={clearSearchParams}
            entity={entityData?.data}
            expandSummary={expandSummary}
            onCheckoutClick={handleCheckoutClick}
            onIntervalChange={handleIntervalChange}
            onPriceChange={handlePriceChange}
            onQtyChange={handleQtyChange}
            onReportToggle={handleReportToggle}
            onSportClick={handleSportClick}
            onToggleSummary={handleToggleSummary}
            plans={groupedPlans}
            qty={qty}
            selectedPrices={selectedPrices}
            selectedSport={selectedSport}
            submitting={submitting}
            total={total}
            unavailableSportIDs={unavailableSportIDs}
            entityID={entityIDParam}
            entityType={entityTypeParam}
            licenceTypes={licenceTypes}
            returnUrl={returnUrl}
        />
    );
};

export default PlansContainer;
