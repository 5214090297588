import { classNames } from 'primereact/utils';
import Icon from './Icon';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    align?: 'left' | 'center';
    description?: string;
    disabled?: boolean;
    icon: React.ReactNode | string;
    label: string;
    selected?: boolean;
    stacked?: boolean;
}

const CardButton = ({
    align,
    className,
    description,
    disabled,
    icon,
    label,
    selected,
    stacked,
    ...otherProps
}: Props) => {
    const buttonClass = classNames('card-button', className, {
        'is-disabled': disabled,
        'is-stacked': stacked,
        'is-selected': selected,
        [`is-align-${align}`]: align,
    });

    return (
        <button className={buttonClass} {...otherProps}>
            <div className="card-button-content">
                {typeof icon === 'string' ? <Icon name={icon} /> : icon}
                <div className="card-button-label">{label}</div>
                {description && (
                    <div className="card-button-description">{description}</div>
                )}
            </div>
        </button>
    );
};

export default CardButton;
