import { useContext, useState } from 'react';

import { ToastContext } from '../../contexts/ToastContext';

import { useGetOrganisationTeamsQuery } from '../../api/organisations';
import { useCreateEntityStaffMutation } from '../../api/staff';

import Loader from '../../components/Loader';
import RookieButton from '../../components/RookieButton';
import EntityAvatar from '../../components/EntityAvatar';
import ListItem from '../../components/ListItem';
import { Avatar } from 'primereact/avatar';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { ToastMessage } from 'primereact/toast';

import { BaseEntityType } from '../../types/common';
import { UserDetails } from '../../types/user';
import { OrganisationStatus } from '../../types/organisation';
import { Team } from '../../types/team';

interface Props {
    organisationID: string;
    user: UserDetails;
    onSuccess?: (response: any) => void;
    onError?: (error: any) => void;
    onClose?: () => void;
}

const StaffBulkAddToTeam = ({
    organisationID,
    user,
    onError,
    onClose,
    onSuccess,
}: Props) => {
    const toast = useContext(ToastContext);
    const [selected, setSelected] = useState<string[]>([]);
    const [isSubmitting, setSubmitting] = useState(false);

    const {
        data: teamData,
        isLoading,
        isFetching,
    } = useGetOrganisationTeamsQuery(
        {
            organisationID,
            entityStatus: OrganisationStatus.Active,
        },
        {
            skip: !organisationID,
        }
    );

    const [bulkAddUserToTeams] = useCreateEntityStaffMutation();

    const showToast = (toastOptions: ToastMessage) => {
        if (toast && toast.current) {
            toast.current.show(toastOptions);
        }
    };

    const handleChange = (e: CheckboxChangeEvent) => {
        const value = e.value;

        setSelected((prev) =>
            prev.includes(value)
                ? prev.filter((teamID) => teamID !== value)
                : [...prev, value]
        );
    };

    const handleSubmit = async () => {
        setSubmitting(true);

        try {
            // Track successes and failures
            const results: { [key: string]: { team: Team; error?: string }[] } =
                {
                    successes: [],
                    errors: [],
                };

            // Collect all mutation Promises
            const mutationPromises = selected.map(async (teamID) => {
                const team = teamData?.data.find(
                    (team) => team.teamID === teamID
                );

                if (!team) return;

                try {
                    const response = await bulkAddUserToTeams({
                        entityType: BaseEntityType.teams,
                        entityID: teamID,
                        entityName: team.teamName,
                        userID: user.userID,
                    });

                    if (response && 'error' in response) {
                        throw response.error;
                    }

                    results.successes.push({ team });
                    return response;
                } catch (error: any) {
                    let errorMessage = 'There was an error adding the user';
                    if (error) {
                        if ('data' in error && error.data?.error) {
                            errorMessage = error.data.error;
                        } else if ('message' in error) {
                            errorMessage = error.message;
                        }
                    }
                    results.errors.push({
                        team: team,
                        error: errorMessage,
                    });
                    throw error;
                }
            });

            // Wait for all mutations to settle (resolve or reject)
            await Promise.allSettled(mutationPromises);

            // Show summary toast
            if (results.successes.length > 0 && results.errors.length > 0) {
                // Mixed results
                showToast({
                    severity: 'warn',
                    summary: 'Add Staff To Team(s) Complete',
                    detail:
                        `Success: ${
                            results.successes.length
                        } teams (${results.successes
                            .map((s) => s.team.teamName)
                            .join(', ')})\n` +
                        `Failed: ${
                            results.errors.length
                        } teams (${results.errors
                            .map((e) => e.team.teamName)
                            .join(
                                ', '
                            )}). This is probably due to them already being on the team.`,
                    sticky: true,
                });
            } else if (results.successes.length > 0) {
                // All successful
                showToast({
                    severity: 'success',
                    summary: 'Add Staff To Team(s) Successful',
                    detail: `Successfully added staff member to ${
                        results.successes.length
                    } team${
                        results.successes.length > 1 ? 's' : ''
                    }: ${results.successes
                        .map((s) => s.team.teamName)
                        .join(', ')}`,
                    sticky: true,
                });
                if (onSuccess) onSuccess(results.successes);
            } else if (results.errors.length > 0) {
                // All failed
                showToast({
                    severity: 'error',
                    summary: 'Add Staff To Team(s) Failed',
                    detail: `Failed to add staff member to ${
                        results.errors.length
                    } team${
                        results.errors.length > 1 ? 's' : ''
                    }: ${results.errors
                        .map((e) => e.team.teamName)
                        .join(
                            ', '
                        )}. This is probably due to them already being on the team.`,
                    sticky: true,
                });
                if (onError) onError(results.errors);
            }

            setSubmitting(false);
        } catch (error) {
            setSubmitting(false);
            // This catch block is mainly for unexpected errors outside individual mutations
            showToast({
                severity: 'error',
                summary: 'Unexpected Error',
                detail: 'An unexpected error occurred during adding staff to teams',
                life: 3000,
            });
            if (onError) onError(error);
        }
    };

    if (isLoading || isFetching) {
        return <Loader />;
    }

    return (
        <div className="sidebar">
            <div className="sidebar-header">
                <ListItem
                    start={
                        <Avatar
                            image={user.picture}
                            label={`${user.firstName} ${user.lastName}`
                                .trim()
                                .substring(0, 2)
                                .toUpperCase()}
                        />
                    }
                    end={
                        onClose && (
                            <RookieButton icon="close" text onClick={onClose} />
                        )
                    }
                    title={`${user.firstName} ${user.lastName}`}
                    caption={user.userDetails.email}
                    disablePadding
                />
            </div>
            <div className="sidebar-body">
                <p style={{ marginBottom: '2rem' }}>
                    Select teams you want to add {user.firstName}{' '}
                    {user.lastName} to?
                </p>
                <div className="addto-collections-form">
                    <div className="addto-collections-inputs">
                        {teamData?.data.map((team) => {
                            return (
                                <ListItem
                                    key={team.teamID}
                                    title={
                                        <label htmlFor={team.teamID}>
                                            {team.teamName}
                                        </label>
                                    }
                                    start={
                                        <EntityAvatar
                                            entityType={BaseEntityType.teams}
                                            entityID={team.teamID}
                                            entityName={team.teamName}
                                        />
                                    }
                                    end={
                                        <Checkbox
                                            inputId={team.teamID}
                                            name="team"
                                            value={team.teamID}
                                            onChange={handleChange}
                                            checked={selected.includes(
                                                team.teamID
                                            )}
                                        />
                                    }
                                    disablePadding
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="sidebar-footer">
                <RookieButton
                    label="Add to teams"
                    style={{ width: '100%' }}
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                />
            </div>
        </div>
    );
};

export default StaffBulkAddToTeam;
