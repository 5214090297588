import { createApi } from '@reduxjs/toolkit/query/react';
import { generatePath } from 'react-router-dom';

import { apiEndpoints } from './apiEndpoints';
import { baseQueryWithReauth } from '../util/baseQuery';

import { NotificationsResponse } from '../types/notification';

export const notificationsApi = createApi({
    reducerPath: 'notificationsApi',
    baseQuery: (args, api, extraOptions) =>
        baseQueryWithReauth(
            args,
            api,
            extraOptions,
            process.env.REACT_APP_API_URL
        ),
    endpoints: (builder) => ({
        getNotifications: builder.query<
            NotificationsResponse,
            { userID: string }
        >({
            query: ({ userID }) => ({
                url: generatePath(apiEndpoints.getNotifications.url, {
                    userID,
                }),
                method: apiEndpoints.getNotifications.method,
            }),
        }),
    }),
});

export const { useGetNotificationsQuery } = notificationsApi;
