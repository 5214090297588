import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    useGetOrganisationQuery,
    useLazyGetOrganisationTeamsQuery,
} from '../../../api/organisations';
import { useLazyGetTeamSeasonSummaryReportQuery } from '../../../api/reports';
import { useLazyGetTeamParticipatingSeasonsQuery } from '../../../api/seasons';

import OrgTeamFairPlayReportView from './OrgTeamFairPlayReportView';

import { DataTable } from 'primereact/datatable';

import { Mixpanel } from '../../../util/mixpanel';

import { useLazyGetSeasonEventsQuery } from '../../../api/events';
import { toISOStringWithTimezone } from '../../../util/helper';
import { BaseEntityType } from '../../../types/common';
import { useLazyGetPlayersQuery } from '../../../api/players';
import { useLazyGetNotesQuery } from '../../../api/notes'; // Delay helper

import { isWithinInterval, subDays } from 'date-fns';
import { isArray } from 'lodash';
import { Note, NotePrivacy } from '../../../types/note';
import { Event } from '../../../types/event';
import { Player, Team } from '../../../types/team';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

// Set day range for month option to 30 days
const isWithinLast30Days = (date: Date): boolean => {
    const today = new Date();
    const thirtyDaysAgo = subDays(today, 30);
    return isWithinInterval(date, { start: thirtyDaysAgo, end: today });
};

// Set day range for month option to 7 days
const isWithinLast7Days = (date: Date): boolean => {
    const today = new Date();
    const sevenDaysAgo = subDays(today, 7);
    return isWithinInterval(date, { start: sevenDaysAgo, end: today });
};

interface RequestError {
    error: string;
}

interface TeamData {
    team: Team;
    notes: Note[] | RequestError;
    players: Player[] | RequestError;
    seasons: {
        [key: string]: {
            reports: any[] | RequestError;
            events: Event[] | RequestError;
        };
    };
}

const dataRangeOptions = [
    { label: 'Last 7 Days', value: 'week' },
    { label: 'Last 30 Days', value: 'month' },
    { label: 'Entire Season', value: 'season' },
];

const categoryOptions = [
    { label: 'Minimum Game Time', value: 'Minimum Game Time' },
    { label: 'Disciplinary', value: 'Disciplinary' },
    { label: 'Injury', value: 'Injury' },
    { label: 'Assessment', value: 'Assessment' },
    { label: 'Opposition', value: 'Opposition' },
];

const OrgTeamFairPlayReportContainer = () => {
    const { organisationID } = useParams();

    const [fetchTeams] = useLazyGetOrganisationTeamsQuery();
    const [fetchReport] = useLazyGetTeamSeasonSummaryReportQuery();
    const [fetchSeasons] = useLazyGetTeamParticipatingSeasonsQuery();
    const [fetchEvents] = useLazyGetSeasonEventsQuery();
    const [fetchPlayers] = useLazyGetPlayersQuery();
    const [fetchNotes] = useLazyGetNotesQuery();

    const now = useRef(new Date()).current;
    const timestampRef = useRef(Date.now()).current;

    const [seasons, setSeasons] = useState<Record<string, any[]>>({});
    const [selectedSeasons, setSelectedSeasons] = useState<
        Record<string, string>
    >({});
    const [hideEmptyRows, setHideEmptyRows] = useState<boolean>(true);

    const [category, setCategory] = useState<string>('Minimum Game Time');
    const [dateRange, setDateRange] = useState<string>('week');

    const [loadingProgress, setLoadingProgress] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    const [teamData, setTeamData] = useState<{ [key: string]: TeamData }>({});

    const organisationData = useGetOrganisationQuery({
        organisationID: organisationID ?? '',
    });

    const organisation = useMemo(
        () => organisationData.data?.data,
        [organisationData]
    );

    async function fetchAllPaginatedData<T>(
        fetchFunction: any,
        args: object
    ): Promise<T | any> {
        let results: T | any = [];

        const recursiveFetch = async (cursor: string) => {
            const response = await fetchFunction({
                ...args,
                cursor,
            }).unwrap();

            // Append results
            if (response?.data) {
                results = response?.data;
            }

            // Check if there is a cursor for the next results
            if (response?.lastEvaluatedKey?.cursor) {
                const nextCursor = response.lastEvaluatedKey.cursor;
                if (nextCursor !== cursor) {
                    await recursiveFetch(nextCursor); // Recursively fetch the next results
                }
            }
        };

        await recursiveFetch('');

        return results;
    }

    // Helper function to fetch a report and update its corresponding state
    const fetchReportData = async (teamID: string, seasonID: string) => {
        const reportResponse = await fetchReport({
            teamID,
            seasonID,
            sessionID: timestampRef,
            concatenated: true,
        }).unwrap();

        const reportUrl = reportResponse?.data?.objectURL;

        if (!reportUrl)
            throw new Error(`Invalid report URL for team ${teamID}`);

        const response = await fetch(reportUrl);

        if (!response.ok)
            throw new Error(`Failed to load report for team ${teamID}`);

        return await response.json();
    };

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            setLoadingProgress(0);
            setTeamData({});

            try {
                const { data: teams } = await fetchTeams({
                    organisationID: organisationID,
                    expand: 'defaultSeasonDetails',
                }).unwrap();

                const totalTeams = teams.length;
                let completedTeams = 0;

                // Helper function to process each team
                const processTeam = async (team: Team) => {
                    const teamResults: TeamData = {
                        team,
                        players: [],
                        notes: [],
                        seasons: {
                            [team.defaultSeasonID]: {
                                events: [],
                                reports: [],
                            },
                        },
                    };

                    setSeasons((prev) => ({
                        ...prev,
                        [team.teamID]: [
                            {
                                label: team.defaultSeasonDetails?.seasonName,
                                value: team.defaultSeasonID,
                            },
                        ],
                    }));

                    try {
                        // Attempt to fetch each piece of data independently
                        try {
                            teamResults.seasons[team.defaultSeasonID].reports =
                                await fetchReportData(
                                    team.teamID,
                                    team.defaultSeasonID
                                );
                        } catch (error) {
                            teamResults.seasons[team.defaultSeasonID].reports =
                                {
                                    error: `Failed to fetch report`,
                                };
                        }

                        try {
                            teamResults.seasons[team.defaultSeasonID].events =
                                await fetchAllPaginatedData<Event[]>(
                                    fetchEvents,
                                    {
                                        seasonID: team.defaultSeasonID,
                                        entityType: BaseEntityType.teams,
                                        entityID: team.teamID,
                                        to: toISOStringWithTimezone(now),
                                    }
                                );
                        } catch (error) {
                            teamResults.seasons[team.defaultSeasonID].events = {
                                error: `Failed to fetch events`,
                            };
                        }

                        try {
                            teamResults.players = await fetchAllPaginatedData<
                                Player[]
                            >(fetchPlayers, {
                                teamID: team.teamID,
                            });
                        } catch (error) {
                            teamResults.players = {
                                error: `Failed to fetch players`,
                            };
                        }

                        try {
                            const notesData = await fetchAllPaginatedData<{
                                notes: Note[];
                            }>(fetchNotes, {
                                entityID: team.teamID,
                                entityType: BaseEntityType.teams,
                            });
                            teamResults.notes = notesData?.notes;
                        } catch (error) {
                            teamResults.notes = {
                                error: `Failed to fetch notes`,
                            };
                        }

                        // Update team data state with isolated results
                        setTeamData((prevData) => ({
                            ...prevData,
                            [team.teamID]: teamResults,
                        }));
                    } catch (error) {
                        // Save the partially successful team data
                        setTeamData((prevData) => ({
                            ...prevData,
                            [team.teamID]: teamResults,
                        }));
                    } finally {
                        completedTeams++;
                        setLoadingProgress(
                            Math.round((completedTeams / totalTeams) * 100)
                        );
                    }
                };

                // Process teams with delay between requests
                for (const team of teams) {
                    setSelectedSeasons((prev) => ({
                        ...prev,
                        [team.teamID]: team.defaultSeasonID,
                    }));
                    await processTeam(team); // Process the team
                    await delay(1000); // Delay for 200ms (adjust as needed)
                }
            } catch (error) {
                console.error('Error loading teams:', error);
            } finally {
                setLoading(false);
            }
        };

        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisationID]);

    // Handle season change for a specific team
    const handleSeasonChange = async (teamID: string, seasonID: string) => {
        setSelectedSeasons((prev) => ({ ...prev, [teamID]: seasonID }));

        let reports: any[] | RequestError;
        let events: Event[] | RequestError;

        try {
            reports = await fetchReportData(teamID, seasonID);
        } catch (error) {
            reports = {
                error: `Failed to fetch report`,
            };
        }

        try {
            events = await fetchAllPaginatedData<Event[]>(fetchEvents, {
                seasonID,
                entityType: BaseEntityType.teams,
                entityID: teamID,
                to: toISOStringWithTimezone(now),
            });
        } catch (error) {
            events = {
                error: `Failed to fetch events`,
            };
        }

        // Update team data state with isolated results
        setTeamData((prevData) => ({
            ...prevData,
            [teamID]: {
                ...prevData[teamID],
                seasons: {
                    ...prevData[teamID]['seasons'],
                    [seasonID]: {
                        events,
                        reports,
                    },
                },
            },
        }));
    };

    const handleSeasonDropdownOpen = async (teamID: string) => {
        if (!seasons[teamID] || seasons[teamID].length === 1) {
            try {
                const { data: teamSeasons } = await fetchSeasons({
                    teamID,
                    cursor: '',
                }).unwrap();

                setSeasons((prev) => ({
                    ...prev,
                    [teamID]: teamSeasons.map((season) => ({
                        label: season.seasonName,
                        value: season.seasonID,
                    })),
                }));
            } catch (error) {
                console.error(
                    `Error fetching seasons for team ${teamID}:`,
                    error
                );
            }
        }
    };

    const handleExport = (dataTable: DataTable<any>) => {
        dataTable && dataTable.exportCSV();

        Mixpanel.track('Export Report', {
            reportType: `Organisation Fair Play Report`,
        });
    };

    const checkDuplicate = (
        listKey: string,
        playerID: string,
        reports: any[]
    ) => {
        let count = 0;
        reports.forEach((report) => {
            if (
                isArray(report[listKey]) &&
                report[listKey].some((p: string) => p === playerID)
            ) {
                count++;
            }
        });
        return count;
    };

    const tableData = useMemo(() => {
        let filteredData: any[] = [];

        Object.entries(teamData).forEach(([teamID, data]) => {
            const season = selectedSeasons[teamID];
            const currentSeason = data.seasons[season];
            const events = currentSeason?.events;
            const reports = currentSeason?.reports;

            if (!reports || !isArray(reports)) {
                filteredData.push({
                    ...reports,
                    team: data.team,
                    notes: [],
                    event: [],
                    playerLists: [],
                });
                return;
            }

            const newReports = reports
                .map((report: any) => {
                    const event =
                        isArray(events) &&
                        events.find((e: Event) => e.eventID === report.eventID);

                    const notes =
                        isArray(data.notes) &&
                        data.notes.filter((note: Note) => {
                            return (
                                note.notePrivacy === NotePrivacy.Public &&
                                note.nodeList.some(
                                    (node) =>
                                        node.nodeType === 'events' &&
                                        node.nodeID === report.eventID
                                )
                            );
                        });

                    let playerLists: { [key: string]: string[] } = {};

                    [
                        'disciplineList',
                        'injuryList',
                        'oppositionList',
                        'assessmentList',
                        'pBelowTarget',
                    ].forEach((key) => {
                        const list = report[key];
                        playerLists[key] = [];

                        if (
                            list &&
                            list.every((i: any) => typeof i === 'string')
                        ) {
                            playerLists[key] = list.map((playerID: string) => {
                                const player =
                                    isArray(data.players) &&
                                    data.players.find(
                                        (player) => player.playerID === playerID
                                    );

                                const count = checkDuplicate(
                                    key,
                                    playerID,
                                    reports
                                );

                                return !player
                                    ? null
                                    : `${player.uniformNumber}. ${
                                          player.firstName
                                      } ${player.lastName} ${
                                          count > 1 ? '(' + count + ')' : ''
                                      }`;
                            });
                        }
                    });

                    return {
                        ...report,
                        team: data.team,
                        notes,
                        event,
                        playerLists,
                    };
                })
                .filter((report: any) => {
                    if (!report.event) {
                        //return false;
                    }

                    if (dateRange === 'season') {
                        return true;
                    } else if (
                        dateRange === 'week' &&
                        report.event?.startDateTime
                    ) {
                        return isWithinLast7Days(
                            new Date(report.event.startDateTime)
                        );
                    } else if (
                        dateRange === 'month' &&
                        report.event?.startDateTime
                    ) {
                        return isWithinLast30Days(
                            new Date(report.event.startDateTime)
                        );
                    }
                    return false;
                });

            filteredData = filteredData.concat(newReports);
        });

        return filteredData.sort((a, b) => {
            const dateA = a.event?.startDateTime
                ? new Date(a.event.startDateTime).getTime()
                : 0;
            const dateB = b.event?.startDateTime
                ? new Date(b.event.startDateTime).getTime()
                : 0;
            return dateA - dateB; // Sort ascending (oldest first)
        });
    }, [dateRange, selectedSeasons, teamData]);

    const filteredData = useMemo(() => {
        return tableData.filter((row) => {
            let list: string[] | undefined;

            switch (category) {
                case 'Disciplinary':
                    list = row.disciplineList;
                    break;
                case 'Injury':
                    list = row.injuryList;
                    break;
                case 'Opposition':
                    list = row.oppositionList;
                    break;
                case 'Assessment':
                    list = row.assessmentList;
                    break;
                default:
                    list = row.pBelowTarget;
            }

            // Check if list is defined and is an array of strings
            const isListArray = Array.isArray(list);
            const containsOnlyStrings =
                isListArray && list?.every((i) => typeof i === 'string');
            const isEmptyOrNotStringList =
                !isListArray || list?.length === 0 || !containsOnlyStrings;

            if (hideEmptyRows) {
                return !isEmptyOrNotStringList;
            }

            return true;
        });
    }, [tableData, hideEmptyRows, category]);

    return (
        <OrgTeamFairPlayReportView
            data={filteredData}
            hideEmptyRows={hideEmptyRows}
            loading={loading}
            loadingProgress={loadingProgress}
            options={{
                seasons: seasons,
                dateRanges: dataRangeOptions,
                categories: categoryOptions,
            }}
            organisation={organisation}
            onExport={handleExport}
            onSeasonChange={handleSeasonChange}
            onSeasonDropdownOpen={handleSeasonDropdownOpen}
            selectedSeasons={selectedSeasons}
            setHideEmptyRows={setHideEmptyRows}
            setDateRange={setDateRange}
            dateRange={dateRange}
            category={category}
            setCategory={setCategory}
        />
    );
};

export default OrgTeamFairPlayReportContainer;
