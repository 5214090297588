import { useMemo, useState } from 'react';
import { startCase } from 'lodash';

import AddonOption from './AddonOption';

import { formatPrice, isNullOrUndef } from '../../util/helper';
import { productDetails } from '../../util/constants';

import Icon from '../../components/Icon';
import RookieButton from '../../components/RookieButton';

import { Image } from 'primereact/image';
import { CheckboxChangeEvent } from 'primereact/checkbox';
import { classNames } from 'primereact/utils';

import { RookieProduct } from '../../types/licences';
import { PlanEssentials, StripeInterval } from '../../types/subscriptions';
import { checkDiscount, DISCOUNT_AMOUNT } from './discount';

interface Props {
    price: PlanEssentials;
    product: RookieProduct;
    report?: PlanEssentials;
    isActiveTier: boolean;
    billingInterval: StripeInterval;
    onSelectClick: (price: PlanEssentials) => void;
    activePrice: (plan: PlanEssentials) => boolean;
    flag?: string;
    hideSubmit?: boolean;
}

const FEATURE_SHOW_LIMIT = 8;

const PriceItem = ({
    price,
    product,
    report,
    isActiveTier,
    onSelectClick,
    billingInterval,
    flag,
    hideSubmit,
    activePrice,
}: Props) => {
    const [expandFeatures, setExpandFeatures] = useState(false);
    const [reportChecked, setReportChecked] = useState<boolean | undefined>(
        true
    );

    const productDetail = productDetails[product];

    const isActive = activePrice(reportChecked && report ? report : price);

    const productClassName = classNames('product-item', {
        'has-flag': !!flag,
    });

    const hasMoreFeatures =
        price.productFeatures &&
        price.productFeatures?.length > FEATURE_SHOW_LIMIT;

    const hasDiscount = checkDiscount(price.sportID, price.rookieProduct);

    const priceAmount = useMemo(() => {
        if (reportChecked && report) {
            return report.price;
        }

        return price.price;
    }, [price, report, reportChecked]);

    const discountAmount = useMemo(() => {
        if (hasDiscount && !isNullOrUndef(DISCOUNT_AMOUNT)) {
            return priceAmount - priceAmount * DISCOUNT_AMOUNT;
        }
    }, [hasDiscount, priceAmount]);

    const reportPriceDifference = useMemo(() => {
        if (report) {
            let amount = report.price - price.price;

            if (hasDiscount && !isNullOrUndef(DISCOUNT_AMOUNT)) {
                return amount - amount * DISCOUNT_AMOUNT;
            } else {
                return amount;
            }
        }
    }, [hasDiscount, price, report]);

    const features = useMemo(() => {
        if (!price?.productFeatures) return [];

        return price.productFeatures.length > FEATURE_SHOW_LIMIT &&
            !expandFeatures
            ? price.productFeatures.slice(0, FEATURE_SHOW_LIMIT)
            : price.productFeatures;
    }, [expandFeatures, price]);

    return (
        <div className={productClassName}>
            {!!flag && <div className="product-item-badge">{flag}</div>}
            <div className="product-item-header">
                {isActiveTier && (
                    <div className="product-item-message p-message">
                        <div className="p-message-wrapper">
                            <div className="p-message-detail">
                                Current Plan Tier.
                            </div>
                        </div>
                    </div>
                )}
                <h1 className="product-item-title">{price.title}</h1>
                <a
                    href={productDetail.salesUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Image
                        className="app-logo-img app-logo-brandmark"
                        src={productDetail.logo}
                        height="48px"
                        alt={productDetail.title}
                    />
                </a>
                <p className="product-item-description">{price.description}</p>

                {hasDiscount && priceAmount > 0 && (
                    <p className="product-item-discount">
                        Was{' '}
                        {formatPrice(
                            price.interval === 'year'
                                ? priceAmount / 12
                                : priceAmount
                        )}
                        /mo
                    </p>
                )}
                <p className="product-item-price">
                    {hasDiscount && !isNullOrUndef(discountAmount)
                        ? formatPrice(
                              price.interval === 'year'
                                  ? discountAmount / 12
                                  : discountAmount
                          )
                        : formatPrice(
                              price.interval === 'year'
                                  ? priceAmount / 12
                                  : priceAmount
                          )}
                    <span className="product-item-interval">/mo</span>
                </p>
                {billingInterval === 'year' && (
                    <p className="product-item-billing">
                        {formatPrice(
                            discountAmount ? discountAmount : priceAmount
                        )}{' '}
                        billed yearly.
                    </p>
                )}
            </div>
            <div className="product-item-body">
                <div className="product-item-block">
                    <h5 className="product-item-subheading">Included:</h5>
                    <AddonOption
                        label="Desktop Access"
                        selected
                        disabled
                        price="Free"
                        description="Manage your team and games from your computer."
                    />
                </div>
                {report && !isNullOrUndef(reportPriceDifference) && (
                    <div className="product-item-block">
                        <h5 className="product-item-subheading">Addons:</h5>
                        <AddonOption
                            label={`${startCase(report.tier)} Reports`}
                            price={`+${formatPrice(
                                reportPriceDifference
                            )} per ${report.interval}`}
                            description="Get detailed insights into player participation, game and scores, rotation patterns, fair play averages, and more."
                            selected={reportChecked}
                            onChange={(e: CheckboxChangeEvent) =>
                                setReportChecked(e.checked)
                            }
                        />
                    </div>
                )}
                {features && features.length > 0 && (
                    <div className="product-item-block">
                        <h5 className="product-item-subheading">Features</h5>
                        <ul className="plan-features">
                            {features.map((feat, index) => (
                                <li key={`${product}-${index}`}>
                                    <Icon name="check" />
                                    <span>{feat.name}</span>
                                </li>
                            ))}
                        </ul>
                        {hasMoreFeatures && (
                            <RookieButton
                                link
                                size="small"
                                label={`Show ${
                                    expandFeatures ? 'Less' : 'More'
                                }`}
                                onClick={() =>
                                    setExpandFeatures((prev) => !prev)
                                }
                                iconPos="right"
                                icon={
                                    expandFeatures
                                        ? 'arrow_drop_up'
                                        : 'arrow_drop_down'
                                }
                            />
                        )}
                    </div>
                )}
            </div>
            {!hideSubmit && (
                <div className="product-item-footer">
                    <RookieButton
                        label={
                            isActive
                                ? 'Current Plan'
                                : isActiveTier
                                ? reportChecked
                                    ? 'Upgrade Plan'
                                    : `Switch To ${price.interval}ly`
                                : 'Select Plan'
                        }
                        disabled={isActive}
                        onClick={() =>
                            onSelectClick(
                                reportChecked && report ? report : price
                            )
                        }
                    />
                </div>
            )}
        </div>
    );
};

export default PriceItem;
