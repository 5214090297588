import { useRef } from 'react';

import ColumnEditPanel from '../components/ColumnEditPanel';
import EventSelect from '../components/EventSelect';
import ReportTable from '../components/ReportTable';
import SeasonSelect from '../components/SeasonSelect';

import PageHeader from '../../../layout/PageHeader';
import PageContainer from '../../../layout/PageContainer';
import EntityAvatar from '../../../components/EntityAvatar';
import FormGroup from '../../../components/FormGroup';
import RookieButton from '../../../components/RookieButton';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';
import { SelectButton } from 'primereact/selectbutton';
import { Toolbar } from 'primereact/toolbar';
import { Skeleton } from 'primereact/skeleton';
import { SelectItem } from 'primereact/selectitem';

import {
    ReportColumn,
    ReportDataFilters,
    ReportDataViews,
    ReportState,
    TimeStat,
} from '../../../types/reports';
import { Team, TeamResponse } from '../../../types/team';
import { UseQueryResult } from '../../../types/api';
import { BaseEntityType } from '../../../types/common';

interface Props {
    category: string;
    data: any;
    emtpyRows: string[];
    filters: ReportDataFilters;
    hiddenRows: string[];
    hideEmptyRows: boolean;
    options: Record<string, SelectItem[]>;
    reportData: ReportState<TimeStat>;
    rows: ReportColumn[];
    season: string;
    team?: Team;
    teamData: UseQueryResult<TeamResponse>;
    views: ReportDataViews;
    onCategoryChange: (value: any) => void;
    onExport: (dataTable: DataTable<any>) => void;
    onFilterChange: (key: string, value: any) => void;
    onHideEmptyRows: (hide: boolean) => void;
    onViewsChange: (key: string, value: any) => void;
    onSeasonChange: (value: string) => void;
    onHiddenRowChange: (value: any) => void;
    onRowsChange: (value: any) => void;
}

const TeamStatsReportView = ({
    category,
    data,
    emtpyRows,
    filters,
    hiddenRows,
    hideEmptyRows,
    options,
    reportData,
    rows,
    season,
    team,
    teamData,
    views,
    onCategoryChange,
    onExport,
    onFilterChange,
    onHiddenRowChange,
    onHideEmptyRows,
    onRowsChange,
    onSeasonChange,
    onViewsChange,
}: Props) => {
    const dataTable = useRef<DataTable<any>>(null);
    const columnEdit = useRef<OverlayPanel>(null);

    const leftToolbar = (
        <>
            <FormGroup label="Season">
                <SeasonSelect
                    onChange={(e) => onSeasonChange(e.value)}
                    value={season}
                    placeholder="Select Season"
                    teamID={team?.teamID}
                    initialOptions={
                        team?.defaultSeasonDetails && [
                            team.defaultSeasonDetails,
                        ]
                    }
                />
            </FormGroup>
            <FormGroup label="Event">
                <EventSelect
                    key={season}
                    onChange={(e) => onFilterChange('event', e.value)}
                    value={filters.event}
                    placeholder="Entire Season"
                    teamID={team?.teamID}
                    reportType="Game"
                    seasonID={season}
                    showClear={true}
                    maxSelectedLabels={1}
                    selectAllLabel="Select All"
                    selectedItemsLabel={`${filters.event?.length} Selected`}
                    multiselect
                />
            </FormGroup>
            <FormGroup label="Period">
                <Dropdown
                    value={filters.period}
                    options={[{ label: 'All', value: 0 }, ...options.periods]}
                    onChange={(e) => onFilterChange('period', e.value)}
                />
            </FormGroup>
        </>
    );

    const rightToolbar = (
        <div className="p-button-group">
            <SelectButton
                value={views.dataType}
                onChange={(e) => onViewsChange('dataType', e.value)}
                options={options.dataType}
                disabled={filters.event?.length === 1}
                allowEmpty={false}
                pt={{
                    button: {
                        className: 'p-button-sm',
                    },
                }}
            />
            <RookieButton
                label="Customise"
                onClick={(e) => columnEdit?.current?.toggle(e)}
                size="small"
                outlined
                severity="secondary"
                icon="tune"
            />
        </div>
    );

    const columns: ReportColumn[] = [
        {
            id: 'statName',
            name: 'Stat',
            shortName: 'Stat',
            field: 'stat.name',
        },
        {
            id: 'team',
            name: 'Team',
            shortName: 'Team',
            field: 'team',
            header: () =>
                teamData.isLoading ? (
                    <Skeleton width="3rem" height="1rem" />
                ) : (
                    team && (
                        <div className="detail-cell">
                            <EntityAvatar
                                entityType={BaseEntityType.teams}
                                entityID={team.teamID}
                                entityName={team.teamName}
                            />
                            <span>{team.teamName}</span>
                        </div>
                    )
                ),
            body: reportData?.isLoading && (
                <Skeleton width="2rem" height="1rem" />
            ),
        },
        {
            id: 'opposition',
            name: 'Opposition',
            shortName: 'Opp',
            field: 'opposition',
            header: 'Opposition',
            body: reportData?.isLoading && (
                <Skeleton width="2rem" height="1rem" />
            ),
        },
    ];

    return (
        <PageContainer>
            <PageHeader
                title="Team Stats Report"
                actions={[
                    {
                        onClick: () =>
                            dataTable.current && onExport(dataTable.current),
                        label: 'Export',
                        severity: 'secondary',
                        icon: 'download',
                    },
                ]}
            />
            <Toolbar
                className="report-toolbar"
                start={leftToolbar}
                end={rightToolbar}
            />
            <ReportTable
                ref={dataTable}
                data={data}
                columns={columns}
                isLoading={reportData?.isLoading || reportData?.isUninitialized}
                dataTableProps={{
                    exportFilename: `${team?.teamName} - Team Stat Report`,
                }}
            />
            <OverlayPanel ref={columnEdit} className="column-popover">
                <ColumnEditPanel
                    hideEmptyColumns={hideEmptyRows}
                    onHideEmptyColumns={onHideEmptyRows}
                    selectedCategory={category}
                    categories={options.categories}
                    onCategoryChange={onCategoryChange}
                    emptyColumns={emtpyRows}
                    columns={rows}
                    hiddenColumns={hiddenRows}
                    onColumnsChange={onRowsChange}
                    onHideColumn={onHiddenRowChange}
                />
            </OverlayPanel>
        </PageContainer>
    );
};

export default TeamStatsReportView;
