import { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { sortBy } from 'lodash';

import usePermission from '../../hooks/usePermission';

import OrganisationForm from './OrganisationForm';
import OrganisationAddForm from './OrganisationAddForm';
import PageHeader from '../../layout/PageHeader';
import PageContainer from '../../layout/PageContainer';
import RookieButton from '../../components/RookieButton';
import ErrorDisplay from '../../components/ErrorDisplay';
import ListItem from '../../components/ListItem';
import EntityPreview from '../../components/EntityPreview';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Sidebar } from 'primereact/sidebar';
import { Skeleton } from 'primereact/skeleton';

import { Action, ERROR_TYPES, FormSubmitCallback } from '../../types/common';
import { Organisation } from '../../types/organisation';

interface Props {
    data: Organisation[];
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
    showAddModal: boolean;
    showCreateModal: boolean;
    showPagination: boolean;
    onAddSubmitComplete?: (submitResult: any) => void;
    onCreateSubmitComplete: FormSubmitCallback;
    onCloseAddModal: () => void;
    onCloseCreateModal: () => void;
    onImportClick: () => void;
    onLoadMore: () => void;
    onOpenCreateModal: () => void;
    onOpenAddModal: () => void;
}

const OrganisationListingView = (props: Props) => {
    const {
        data,
        isError,
        isFetching,
        isLoading,
        showAddModal,
        showCreateModal,
        showPagination,
        onImportClick,
        onCreateSubmitComplete,
        onOpenCreateModal,
        onOpenAddModal,
        onCloseCreateModal,
        onCloseAddModal,
        onLoadMore,
    } = props;
    const navigate = useNavigate();
    const location = useLocation();

    const { associationID } = useParams();

    const { isAdmin } = usePermission(associationID);

    const columns = useMemo(() => {
        return [
            {
                field: 'organisationName',
                header: 'Name',
                sortable: true,
                body: isLoading ? (
                    <Skeleton />
                ) : (
                    (row: Organisation) => (
                        <ListItem
                            title={row.organisationName}
                            start={
                                <EntityPreview
                                    design={row.design}
                                    style={{
                                        width: '32px',
                                        height: '32px',
                                        borderRadius: '4px',
                                    }}
                                />
                            }
                        />
                    )
                ),
            },
            {
                body: isLoading ? (
                    <Skeleton />
                ) : (
                    (data: Organisation) =>
                        format(parseISO(data.lastEdited), 'dd/MM/yyyy')
                ),
                field: 'lastEdited',
                header: 'Last Edited',
                sortable: true,
            },
        ];
    }, [isLoading]);

    const mappedColumns = columns.map((col, i) => (
        <Column key={`col-${i}`} {...col} />
    ));

    const headerActions: Action[] = isAdmin
        ? [
              {
                  onClick: onImportClick,
                  key: 'import',
                  label: 'Import',
                  severity: 'secondary',
              },
              {
                  onClick: () => onOpenAddModal(),
                  icon: 'add',
                  key: 'add',
                  label: 'Link Organisation',
              },
              {
                  onClick: () => onOpenCreateModal(),
                  icon: 'add',
                  key: 'create-new',
                  label: 'Create New',
              },
          ]
        : [];

    const tableEmptyMessage = (
        <ErrorDisplay
            actions={
                isError
                    ? [
                          {
                              onClick: () => navigate(0), // refresh
                              icon: 'refresh',
                              label: 'Retry',
                          },
                      ]
                    : headerActions
            }
            alignment="middle"
            desc={
                isError
                    ? 'Refresh to try the request again'
                    : 'Check in with Rookie Me to receive oversight of your organisations.'
            }
            errorType={ERROR_TYPES.notFound}
            hasReturn={false}
            proportion="compact"
            title={isError ? 'No data returned' : `No Organisations found`}
        />
    );

    return (
        <PageContainer>
            <PageHeader title="Organisations" actions={headerActions} />
            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <DataTable
                    value={
                        isLoading
                            ? Array(5).fill(0)
                            : sortBy(data, 'organisationName')
                    }
                    loading={isFetching && !isLoading}
                    emptyMessage={tableEmptyMessage}
                    columnResizeMode="expand"
                    resizableColumns
                    onRowClick={(e) => {
                        navigate(
                            `${location.pathname.replace(
                                'organisations',
                                'o'
                            )}/${e.data.organisationID}`
                        );
                    }}
                    footer={
                        !isFetching && showPagination ? (
                            <RookieButton
                                onClick={onLoadMore}
                                severity="secondary"
                                label="Load more"
                                icon="pending"
                            />
                        ) : null
                    }
                >
                    {isLoading
                        ? mappedColumns
                        : data.length > 0
                        ? mappedColumns
                        : null}
                </DataTable>
            )}

            <Sidebar
                header="Create Organisation"
                onHide={onCloseCreateModal}
                visible={showCreateModal}
                position="right"
            >
                <OrganisationForm
                    onSubmitComplete={onCreateSubmitComplete}
                    onCancel={onCloseCreateModal}
                />
            </Sidebar>
            <Sidebar
                header="Add Organisation"
                onHide={onCloseAddModal}
                visible={showAddModal}
                position="right"
            >
                <OrganisationAddForm onCancel={onCloseAddModal} />
            </Sidebar>
        </PageContainer>
    );
};

export default OrganisationListingView;
