import { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
    useGetOrganisationQuery,
    useDeleteOrganisationMutation,
} from '../../api/organisations';
import { coreApi } from '../../api/core';

import { ToastContext } from '../../contexts/ToastContext';
import usePermission from '../../hooks/usePermission';

import OrganisationForm from './OrganisationForm';
import TransferEntityForm from '../users/TransferEntityForm';
import PageSection, { Annotation } from '../../layout/PageSection';
import PageContainer from '../../layout/PageContainer';
import PageHeader from '../../layout/PageHeader';
import ErrorDisplay from '../../components/ErrorDisplay';
import Loader from '../../components/Loader';
import RookieButton from '../../components/RookieButton';
import { Sidebar } from 'primereact/sidebar';
import { confirmDialog } from 'primereact/confirmdialog';

import {
    BaseEntityType,
    ERROR_TYPES,
    FormSubmitCallback,
} from '../../types/common';
import { User } from '../../types/user';
import { Permissions } from '../../types/permissions';

interface Props {
    user?: User;
}

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const OrganisationSettings = (props: Props) => {
    const dispatch = useDispatch();
    const { organisationID } = useParams();
    const { user } = props;

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [deleteOrganisationMutation] = useDeleteOrganisationMutation();

    const toast = useContext(ToastContext);

    const navigate = useNavigate();

    const { checkPermission, role } = usePermission(organisationID || '');

    const { data, isLoading, isError } = useGetOrganisationQuery(
        { organisationID },
        {
            skip: !organisationID,
        }
    );
    const deleteOrganisation = async () => {
        if (!organisationID) return;

        try {
            setIsSubmitting(true);

            await deleteOrganisationMutation({ organisationID });

            // Wait for 4 seconds to allow server to respond
            await delay(4000);

            // Refresh roles
            dispatch(coreApi.util.invalidateTags(['Role']));

            toast?.current?.show({
                severity: 'success',
                summary: 'Success',
                detail: `${data?.data.organisationName} has successfully been deleted.`,
            });

            navigate(`/u/${user?.userID}/entities`, {
                replace: true,
            });
        } catch (error) {
            toast?.current?.show({
                severity: 'warn',
                summary: 'Error',
                detail: 'Sorry, there was an error. Please try again later.',
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    // Handle form deletion submission result
    const handleDelete = () => {
        confirmDialog({
            message: `This is permanent and cannot be undone. Are you sure you want to delete ${data?.data.organisationName}?`,
            header: 'Delete Organisation',
            accept: () => deleteOrganisation(),
            draggable: false,
        });
    };

    const handleSubmitComplete: FormSubmitCallback = (
        status,
        action,
        _,
        error
    ) => {
        if (status === 'success') {
            toast?.current?.show({
                severity: 'success',
                summary: 'Success',
                detail:
                    action === 'create'
                        ? `The organisation has been created successfully.`
                        : `The organisation has been updated successfully.`,
            });
        } else if (status === 'error' && error) {
            toast?.current?.show({
                severity: 'warn',
                summary: 'Error',
                detail: error.message,
            });
        } else {
            toast?.current?.show({
                severity: 'warn',
                summary: 'Error',
                detail: 'Something went wrong. Please try again.',
            });
        }
    };

    if (isLoading) {
        return <Loader size="fullscreen" />;
    }

    if (isError) {
        return (
            <ErrorDisplay
                actions={[
                    {
                        onClick: () => navigate(0), // refresh
                        icon: 'refresh',
                        label: 'Retry',
                    },
                ]}
                desc="We were unable to load your Organisation Settings, try again or contact Organisation Owner."
                errorType={ERROR_TYPES.notFound}
                hasReturn
                proportion="fullscreen"
                title="No Roles Returned"
            />
        );
    }

    const permissions = {
        canCreate: checkPermission([
            Permissions.POST_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canDelete: checkPermission([
            Permissions.DELETE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canEdit: checkPermission([
            Permissions.PUT_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
        canView: checkPermission([
            Permissions.GET_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS,
            Permissions.MANAGE_ORGANISATIONS_ALL,
        ]),
    };

    const isOwner = role.data?.roles.some((role) =>
        role.roleID.includes('Owner')
    );

    return (
        <>
            <PageContainer>
                <PageHeader title="Settings" />
                <PageSection>
                    {permissions.canEdit ? (
                        <>
                            <Annotation title="Club information">
                                <OrganisationForm
                                    organisationID={data?.data?.organisationID}
                                    initialValues={data?.data}
                                    onSubmitComplete={handleSubmitComplete}
                                />
                            </Annotation>
                        </>
                    ) : (
                        <ErrorDisplay
                            errorType={ERROR_TYPES.somethingsWrong}
                            title="You lack permissions to see this page"
                            desc="Contact the page admin if you believe this is in error."
                            proportion="enlarged"
                        />
                    )}

                    <Annotation
                        title="Danger Zone"
                        description="These actions are permanent and you will not be able to undo."
                    >
                        <div className="p-button-group">
                            <RookieButton
                                onClick={() => setShowTransferModal(true)}
                                label="Transfer Ownership"
                                severity="danger"
                                disabled={!isOwner}
                                tooltipOptions={{ showOnDisabled: true }}
                                tooltip={
                                    !isOwner
                                        ? 'You must be an Organisation Owner to complete these actions.'
                                        : undefined
                                }
                            />

                            <RookieButton
                                onClick={() => handleDelete()}
                                label="Delete Organisation"
                                severity="danger"
                                disabled={!isOwner}
                                tooltipOptions={{ showOnDisabled: true }}
                                tooltip={
                                    !isOwner
                                        ? 'You must be an Organisation Owner to complete these actions.'
                                        : undefined
                                }
                            />
                        </div>
                    </Annotation>
                </PageSection>
            </PageContainer>
            <Sidebar
                header="Transfer Ownership"
                onHide={() => setShowTransferModal(false)}
                visible={showTransferModal}
                position="right"
            >
                {organisationID && (
                    <TransferEntityForm
                        entityType={BaseEntityType.organisations}
                        entityID={organisationID}
                        onSuccess={() => setShowTransferModal(false)}
                    />
                )}
            </Sidebar>

            {isSubmitting && <Loader size="fullscreen" overlay />}
        </>
    );
};

export default OrganisationSettings;
