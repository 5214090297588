import { useMemo, useState } from 'react';
import { startCase } from 'lodash';

import { formatPrice, isNullOrUndef } from '../../util/helper';
import { productDetails } from '../../util/constants';

import PriceOption from './PriceOption';
import AddonOption from './AddonOption';

import Icon from '../../components/Icon';
import RookieButton from '../../components/RookieButton';
import { Image } from 'primereact/image';

import { PlanEssentials, StripeInterval } from '../../types/subscriptions';
import { LicenceType, RookieProduct } from '../../types/licences';
import { checkDiscount, DISCOUNT_AMOUNT } from './discount';

interface Props {
    prices: PlanEssentials[];
    activePrice: (plan: PlanEssentials) => boolean;
    onPriceChange: (product: RookieProduct, price: PlanEssentials) => void;
    onReportToggle: (product: RookieProduct, priceID: PlanEssentials) => void;
    selectedPrice?: PlanEssentials;
    product: RookieProduct;
    billingInterval: StripeInterval;
    report?: PlanEssentials;
    showFeatures: boolean;
    licenceTypes?: LicenceType[];
}

const FEATURE_SHOW_LIMIT = 8;

const ProductItem = ({
    prices,
    product,
    selectedPrice,
    billingInterval,
    report,
    licenceTypes,
    showFeatures,
    activePrice,
    onPriceChange,
    onReportToggle,
}: Props) => {
    const [expandFeatures, setExpandFeatures] = useState(false);

    const productDetail = productDetails[product];

    const mainPrice = prices.find((price) => price.tier === report?.tier);

    const reportPriceDifference = useMemo(() => {
        if (report && mainPrice) {
            const hasReportDiscount = checkDiscount(
                report.sportID,
                report.rookieProduct
            );
            const diff = report.price - mainPrice.price;
            return hasReportDiscount && !isNullOrUndef(DISCOUNT_AMOUNT)
                ? diff - diff * DISCOUNT_AMOUNT
                : diff;
        }
    }, [mainPrice, report]);

    const hasMoreFeatures =
        selectedPrice?.productFeatures &&
        selectedPrice.productFeatures.length > FEATURE_SHOW_LIMIT;

    const features = useMemo(() => {
        if (!selectedPrice?.productFeatures) return [];

        return selectedPrice.productFeatures.length > FEATURE_SHOW_LIMIT &&
            !expandFeatures
            ? selectedPrice.productFeatures.slice(0, FEATURE_SHOW_LIMIT)
            : selectedPrice.productFeatures;
    }, [expandFeatures, selectedPrice]);

    return (
        <div className="product-item">
            <div className="product-item-header">
                <a
                    href={productDetail.salesUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Image
                        className="app-logo-img app-logo-brandmark"
                        src={productDetail.logo}
                        height="48px"
                        alt={productDetail.title}
                    />
                </a>
                <p className="product-item-description">
                    {selectedPrice?.description}
                </p>
            </div>

            <div className="product-item-body">
                <div className="product-item-block">
                    <h5 className="product-item-subheading">Select Plan</h5>
                    <p>
                        Price per month, billed{' '}
                        {billingInterval === 'year' ? 'yearly' : 'monthly'}
                    </p>
                    <div className="price-options">
                        {prices.map((price) => {
                            const isActive = activePrice(price);
                            const flag = licenceTypes?.find(
                                (lt) => lt.licenceTypeID === price.licenceTypeID
                            )?.licenceFlag;

                            const hasDiscount = checkDiscount(
                                price.sportID,
                                price.rookieProduct
                            );

                            const priceAmount =
                                hasDiscount && !isNullOrUndef(DISCOUNT_AMOUNT)
                                    ? price.price -
                                      price.price * DISCOUNT_AMOUNT
                                    : price.price;

                            return (
                                <PriceOption
                                    priceID={price.id}
                                    value={
                                        priceAmount === 0
                                            ? '$0'
                                            : formatPrice(
                                                  price.interval === 'year'
                                                      ? priceAmount / 12
                                                      : priceAmount
                                              )
                                    }
                                    discount={
                                        hasDiscount && price.price > 0
                                            ? formatPrice(
                                                  price.interval === 'year'
                                                      ? price.price / 12
                                                      : price.price
                                              )
                                            : undefined
                                    }
                                    label={price.title}
                                    selected={
                                        selectedPrice?.tier === price.tier
                                    }
                                    onChange={() =>
                                        onPriceChange(
                                            price.rookieProduct,
                                            price
                                        )
                                    }
                                    active={isActive}
                                    flag={flag}
                                />
                            );
                        })}
                    </div>
                </div>

                <div className="product-item-block">
                    <h5 className="product-item-subheading">Included:</h5>
                    <AddonOption
                        label="Desktop Access"
                        selected
                        disabled
                        price="Free"
                        description="Manage your team and games from your computer."
                    />
                </div>
                {report && !isNullOrUndef(reportPriceDifference) && (
                    <div className="product-item-block">
                        <h5 className="product-item-subheading">Addons:</h5>
                        <AddonOption
                            label={`${startCase(report.tier)} Reports`}
                            price={`+${formatPrice(
                                reportPriceDifference
                            )} per ${report.interval}`}
                            description="Get detailed insights into player participation, game stats and scores, rotation patterns, fair play averages, and more."
                            selected={selectedPrice?.id === report.id}
                            onChange={() => onReportToggle(product, report)}
                        />
                    </div>
                )}
                {showFeatures && features && features.length > 0 && (
                    <div className="product-item-block">
                        <h5 className="product-item-subheading">Features</h5>
                        <ul className="plan-features">
                            {features.map((feat, index) => (
                                <li key={`${product}-${index}`}>
                                    <Icon name="check" />
                                    <span>{feat.name}</span>
                                </li>
                            ))}
                        </ul>
                        {hasMoreFeatures && (
                            <RookieButton
                                link
                                size="small"
                                label={`Show ${
                                    expandFeatures ? 'Less' : 'More'
                                }`}
                                onClick={() =>
                                    setExpandFeatures((prev) => !prev)
                                }
                                iconPos="right"
                                icon={
                                    expandFeatures
                                        ? 'arrow_drop_up'
                                        : 'arrow_drop_down'
                                }
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

ProductItem.defaultProps = {
    showFeatures: true,
};

export default ProductItem;
